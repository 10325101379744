import { FC, useState } from 'react';
import { Button, Typography } from '@mui/material';
import * as filestack from 'filestack-js';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { PhotoPreview } from '@/Components/photo-preview/PhotoPreview';
import { getAppConfig } from '@/config/config';

const config = getAppConfig();
const client = filestack.init(config.FILESTACK.API_KEY);

export interface FileStackUpload {
    key: string;
    imageUrl: string;
    filename: string;
    mimeType: string;
    size: number;
}

type UploadFileProps = {
    defaultImageUrl: string | undefined;
    onUpload: (upload: FileStackUpload) => void;
    onDelete: () => void;
    fileLabel?: string;
};

export const SurveyUploadFile: FC<UploadFileProps> = ({ onUpload, defaultImageUrl, onDelete, fileLabel }) => {
    const { t } = useTranslation();
    const [imageUrl, setImageUrl] = useState<string>();
    const [openPhotoPreviewDialog, setOpenPhotoPreviewDialog] = useState<boolean>(false);
    const openFileStackDialog = () => {
        client
            .picker({
                fromSources: ['local_file_system'],
                accept: config.MIME_TYPES.IMAGES,
                onFileSelected: file => {
                    if (file.size > config.MAX_UPLOAD_FILE_SIZE) {
                        throw new Error('File too big, select something smaller than 10MB');
                    }
                },
                onFileUploadFinished: function (response) {
                    if (!response.key) {
                        return;
                    }
                    onUpload({
                        filename: response.filename,
                        imageUrl: response.url,
                        key: response.key,
                        mimeType: response.mimetype,
                        size: response.size,
                    });
                    setImageUrl(response.url);
                },
            })
            .open()
            .then(() => {
                // do nothing
            });
    };

    const openPreviewDialog = () => {
        setOpenPhotoPreviewDialog(true);
    };

    return (
        <>
            {!!defaultImageUrl && (
                <PhotoPreview
                    open={openPhotoPreviewDialog}
                    onClosePhotoPreview={() => {
                        setOpenPhotoPreviewDialog(false);
                    }}
                    imageUrl={defaultImageUrl}
                    onDeletePhoto={() => {
                        onDelete();
                    }}
                />
            )}

            <Stack direction='row' gap={1}>
                <Stack justifyContent={'center'}>
                    {fileLabel && <Typography variant={'body2'}>{fileLabel}</Typography>}

                    <Button onClick={openFileStackDialog}>{t('folders.upload')}</Button>
                </Stack>

                {!imageUrl && !!defaultImageUrl && (
                    <Stack
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => openPreviewDialog()}
                    >
                        <img width={60} height={60} key={defaultImageUrl} src={defaultImageUrl} alt={'defaultImage'} />
                    </Stack>
                )}

                {imageUrl && <img width={60} height={60} key={imageUrl} src={imageUrl} alt={'image'} />}
            </Stack>
        </>
    );
};
