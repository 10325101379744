// hide config inside a factory to prevent global imports

const config = {
    // Filestack configuration
    FILESTACK: {
        API_KEY: 'A7DSoUdxT4y9gSbCcK1iAz',
        AVATAR_IMAGE_WORKFLOW: 'a251cf0f-c288-4124-ac63-4278bdee0e3c',
    },
    // Bugsnag configuration
    BUGSNAG: {
        API_KEY: 'e036dcda7a6e8f0cf0a14b15f34c5242',
        DEV_API_KEY: 'd3e72152da14f97ce0f7b85ab37c83e7',
    },
    // Ag-Grid configuration
    AG_GRID: {
        LICENCE_KEY:
            'Using_this_{AG_Grid}_Enterprise_key_{AG-052566}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{RogerHR_SA}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{RogerHR}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{RogerHR}_need_to_be_licensed___{RogerHR}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{27_February_2025}____[v3]_[01]_MTc0MDYxNDQwMDAwMA==2440556764aeb0f00f8b2bfdd2ce7086',
    },
    FONTS: {
        OPEN_SANS_BOLD: 'https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4n.ttf',
        OPEN_SANS_MEDIUM: 'https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf',
    },
    DEBOUNCE: {
        TIMEOUT: 200,
    },
    MAX_UPLOAD_FILE_SIZE: 10 * 1024 * 1024, // max 10MB => all sizes are in bytes
    MAX_UPLOAD_FILES: 50,
    OFFICE_PREVIEW_URL: 'https://view.officeapps.live.com/op/embed.aspx?src=',
    ALLOWED_DOCUMENT_FORMATS: ['image/*', '.pdf', '.doc', '.docx', '.xlsx', '.xls', '.csv'],
    DEFAULT_LITERAL_DATE: 'eeee dd MMMM yyyy',
    DEFAULT_DATE_FORMAT: 'dd.MM.yyyy',
    DEFAULT_DAY_MONTH_FORMAT: 'd MMMM',
    DEFAULT_DATE_TIME_FORMAT: 'dd.MM.yyyy hh:mm',
    DEFAULT_DATE_WEEK_NAME: 'EEE dd LLL yyyy',
    DEFAULT_DATE_PICKER_FORMAT: 'EEEEEE dd.M.yy',
    DEFAULT_DAY_FORMAT: 'EEEE',
    DEFAULT_DATE_HOURS_SECONDS_FORMAT: 'yyyy-MM-dd HH:mm:ss',
    DEFAULT_DATE_MONTH_DAY_FORMAT: 'E  dd MMM',
    DEFAULT_HOURS_FORMAT: 'HH:mm',
    DEFAULT_TIMEZONE: 'Europe/Zurich',
    API_REQUEST_DATE_FORMAT: 'yyyy-MM-dd', // backend date format (ISO-8601)
    EXCEL_DATE_FORMAT: 'yyyy-MM-dd', // excel date format
    DEFAULT_END_OF_THE_YEAR: 2030,
    MINUTES_IN_HOUR: 60,
    HOURS_IN_DAY: 24,
    MIME_TYPES: {
        JSON: 'application/json',
        PDF: 'application/pdf',
        IMAGES: ['image/gif', 'image/png', 'image/jpeg', 'image/jpg', 'image/webp'],
        MICROSOFT: [
            'application/msword',
            'application/vnd.ms-excel',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ],
    },
};

export type ConfigType = typeof config;

export const getAppConfig = (): ConfigType => {
    return config;
};

export enum Environment {
    DEV = 'dev',
    STAGING = 'staging',
    TEST = 'test',
    PREPROD = 'preprod',
    PROD = 'prod',
}

export const getAppEnv = (): Environment | undefined => {
    const host = window.location.host;
    if (host.includes('localhost')) {
        return Environment.DEV;
    }
    const parts = host.split('.');
    const env = parts[1];
    switch (env) {
        case 'staging':
            return Environment.STAGING;
        case 'test':
            return Environment.TEST;
        case 'preprod':
            return Environment.PREPROD;
        case 'app':
            return Environment.PROD;
        default:
            return undefined;
    }
};

export const getApiBaseUrl = (): string => {
    const env = getAppEnv();

    switch (env) {
        case Environment.DEV:
            return 'http://localhost:8080';
        case Environment.STAGING:
        case Environment.TEST:
        case Environment.PREPROD:
            return `https://${env}.api.rogerhr.ch`;
        case Environment.PROD:
            return `https://api.rogerhr.ch`;
        default:
            return '';
    }
};

/**
 *
 * Get current app version set with env variable
 */
export const getCurrentAppVersion = (): string => {
    // eslint-disable-next-line no-console
    console.info('Current app version', import.meta.env.VITE_APP_VERSION);
    return import.meta.env.VITE_APP_VERSION;
};
