import { AsyncSelectFilter, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { getJobs } from '@/domain/job/Job.service';
import { getLabelTranslation } from '@/utils/language.util';
import { EmployeeFilterType, searchEmployees } from '@/domain/employee/Employee.service';
import { getLocations } from '@/domain/location/Location.service';
import { getDepartments } from '@/domain/department/Department.service';

export const useGetPayrollLeavesFilters = (): {
    filters: (SelectFilter | AsyncSelectFilter)[];
} => {
    const filters: (SelectFilter | AsyncSelectFilter)[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'LEAVE_TYPE_POLICY_LEAVE_TYPE_TITLE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLeaveTypes().then(leaveTypes => {
                    return leaveTypes?.map(leaveType => ({
                        label: leaveType.title,
                        value: leaveType.id,
                    }));
                });
            },
            key: 'leaveRequest.leaveType.id',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getJobs().then(jobs => {
                    return jobs?.map(job => ({
                        label: getLabelTranslation(job.name),
                        value: job.id,
                    }));
                });
            },
            key: 'JOB_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees();
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'MANAGER_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
    ];
    return { filters };
};
