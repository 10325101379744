import {
    addDays,
    addMonths,
    addYears,
    endOfDay,
    endOfMonth,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    subDays,
    subMilliseconds,
    subMonths,
    subYears,
} from 'date-fns';

import { DateRangePickerViewType } from '@/Components/date-range-picker/DateRangePicker';

export const WEEK_DURATION = 7;
export const calculateUpdatedDateRange = (selectedDate: Date, direction: 'prev' | 'next', calendarViewType: DateRangePickerViewType): [Date, Date] => {
    let start: Date;
    let end: Date;
    const isNext = direction === 'next';
    const addOrSub = isNext ? addDays : subDays;
    const addOrSubMonths = isNext ? addMonths : subMonths;
    const addOrSubYears = isNext ? addYears : subYears;

    switch (calendarViewType) {
        case 'YEAR':
            start = addOrSubYears(startOfYear(selectedDate), 1);
            end = endOfYear(start);
            break;
        case 'MONTH':
            start = addOrSubMonths(startOfMonth(selectedDate), 1);
            end = endOfMonth(start);
            break;
        case 'FOUR_WEEKS':
            start = addOrSub(startOfWeek(selectedDate, { weekStartsOn: 1 }), WEEK_DURATION * 4);
            end = addDays(start, WEEK_DURATION * 4 - 1);
            break;
        case 'TWO_WEEKS':
            start = addOrSub(startOfWeek(selectedDate, { weekStartsOn: 1 }), WEEK_DURATION * 2);
            end = addDays(start, WEEK_DURATION * 2 - 1);
            break;
        case 'WEEK':
            start = addOrSub(startOfWeek(selectedDate, { weekStartsOn: 1 }), WEEK_DURATION);
            end = addDays(start, WEEK_DURATION - 1);
            break;
        default:
            start = addOrSub(selectedDate, 1);
            end = start;
            break;
    }
    return [start, end];
};
export const getDateRange = (viewType: DateRangePickerViewType, date: Date = new Date()): [Date, Date] => {
    let start: Date;
    let end: Date;
    const today = startOfDay(date);

    switch (viewType) {
        case 'YEAR':
            start = startOfYear(today);
            end = endOfYear(today);
            break;
        case 'MONTH':
            start = startOfMonth(today);
            end = endOfMonth(today);
            break;
        case 'FOUR_WEEKS':
            start = startOfWeek(today, { weekStartsOn: 1 });
            end = subMilliseconds(addDays(start, WEEK_DURATION * 4), 1);
            break;
        case 'TWO_WEEKS':
            start = startOfWeek(today, { weekStartsOn: 1 });
            end = subMilliseconds(addDays(start, WEEK_DURATION * 2), 1);
            break;
        case 'WEEK':
            start = startOfWeek(today, { weekStartsOn: 1 });
            end = subMilliseconds(addDays(start, WEEK_DURATION), 1);
            break;
        case 'DAY':
        case 'RANGE':
            start = today;
            end = endOfDay(today);
            break;
    }

    return [start, end];
};
