import { DayPeriod, UnitType } from '@/domain/date/Date.model';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import {
    WorkingPatternTemplate,
    WorkingPatternTemplateCreationRequest,
    WorkingPatternTemplateUpdateRequest,
} from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { WorkingPatternTemplateForm, WorkingPatternTemplateWeekForm } from '@/page/setting/time-management/WorkingPatternFormPage';
import { convertDateToMinutes, isValidDate, setHoursMinutes } from '@/utils/datetime.util';
import { getHours, getMinutes } from 'date-fns';
import { t } from 'i18next';

export const getTotalMinutesPerPattern = (weeks: WorkingPatternTemplateWeekForm[]): number => {
    return weeks.reduce((totalPerPattern, week) => {
        return (
            totalPerPattern +
            week.workingPatternTemplateDaysForm.reduce((totalForWeek, day) => {
                if (isValidDate(day.amountWorked)) {
                    return totalForWeek + convertDateToMinutes(day.amountWorked);
                }
                return totalForWeek;
            }, 0)
        );
    }, 0);
};
export const getTotalDaysPerPattern = (weeks: WorkingPatternTemplateWeekForm[]): number => {
    const numberOfFullDays = weeks.reduce((totalPerPattern, week) => {
        return (
            totalPerPattern +
            week.workingPatternTemplateDaysForm.reduce((totalForWeek, day) => {
                const isAllDay = day.dayPeriod === DayPeriod.ALL_DAY;
                const minutes = getMinutes(day.amountWorked);
                const hours = getHours(day.amountWorked);
                const isAllDayWithWorkingTime = isAllDay && (minutes > 0 || hours > 0);
                return isAllDayWithWorkingTime ? totalForWeek + 1 : totalForWeek;
            }, 0)
        );
    }, 0);

    const numberOfHalfDays = weeks.reduce((totalPerPattern, week) => {
        return (
            totalPerPattern +
            week.workingPatternTemplateDaysForm.reduce((totalForWeek, day) => {
                const isMidDay = day.dayPeriod === DayPeriod.MORNING || day.dayPeriod === DayPeriod.AFTERNOON;
                const minutes = getMinutes(day.amountWorked);
                const hours = getHours(day.amountWorked);
                const isMidDayWithWorkingTime = isMidDay && (minutes > 0 || hours > 0);
                return isMidDayWithWorkingTime ? totalForWeek + 1 : totalForWeek;
            }, 0)
        );
    }, 0);
    return numberOfFullDays + numberOfHalfDays / 2;
};

export const getWeeklyWorkingTime = (workingPatternTemplate: WorkingPatternTemplateForm): number => {
    const totalMinutes = getTotalMinutesPerPattern(workingPatternTemplate.workingPatternTemplateWeeksForm);
    return totalMinutes / workingPatternTemplate.workingPatternTemplateWeeksForm.length;
};

export const getDailyWorkingTime = (workingPatternTemplate: WorkingPatternTemplateForm): number => {
    const daysWorkPerWeek = getDaysWorkPerWeek(workingPatternTemplate);
    const weeklyWorkingTime = getWeeklyWorkingTime(workingPatternTemplate);
    if (daysWorkPerWeek === 0) {
        return 0;
    }
    return weeklyWorkingTime / daysWorkPerWeek;
};

export const getDaysWorkPerWeek = (workingPatternTemplate: WorkingPatternTemplateForm): number => {
    const daysWorkPerWeek =
        getTotalDaysPerPattern(workingPatternTemplate.workingPatternTemplateWeeksForm) / workingPatternTemplate.workingPatternTemplateWeeksForm.length;

    return Math.round(daysWorkPerWeek * 10) / 10;
};

export const mapWorkingPatternTemplateToFormValues = (workingPatternTemplate: WorkingPatternTemplate): WorkingPatternTemplateForm => {
    return {
        name: workingPatternTemplate.name,
        type: workingPatternTemplate.workingPatternType,
        workingPatternTemplateWeeksForm: workingPatternTemplate.workingPatternTemplateWeekDays.map(week => {
            return {
                workingPatternTemplateDaysForm: week.workingPatternTemplateDays.map(day => {
                    return {
                        dayOfWeek: day.dayOfWeek,
                        amountWorked: setHoursMinutes(new Date(), day.amountWorked / 60, day.amountWorked % 60),
                        dayPeriod: day.dayPeriod,
                    };
                }),
            };
        }),
        weeklyWorkingTimeId: workingPatternTemplate.weeklyWorkingTime.id,
    };
};

export const mapFormValuesToCreationRequest = (formValues: WorkingPatternTemplateForm): WorkingPatternTemplateCreationRequest => {
    return {
        name: formValues.name,
        type: formValues.type,
        workingPatternTemplateWeekDays: formValues.workingPatternTemplateWeeksForm.map(week => {
            return {
                workingPatternTemplateDays: week.workingPatternTemplateDaysForm.map(day => {
                    return {
                        dayOfWeek: day.dayOfWeek,
                        amountWorked: convertDateToMinutes(day.amountWorked),
                        dayPeriod: day.dayPeriod,
                    };
                }),
            };
        }),
        weeklyWorkingTimeId: formValues.weeklyWorkingTimeId,
    };
};
export const mapFormValuesToUpdateRequest = (formValues: WorkingPatternTemplateForm): WorkingPatternTemplateUpdateRequest => {
    return mapFormValuesToCreationRequest(formValues);
};

export const getAverageWeeklyWorkingTimeDisplay = (
    workingPatternTemplate: WorkingPatternTemplateForm,
    weeklyWorkingTimes: WeeklyWorkingTime[],
    weeklyWorkingTimeId: number,
): string => {
    const weeklyWorkingTimeDisplay =
        t('contracts_settings_page.weekly') +
        ': ' +
        t('duration.formatDuration', {
            duration: getWeeklyWorkingTime(workingPatternTemplate) / 60,
            unit: UnitType.HOURS,
        });

    if (weeklyWorkingTimeId) {
        const selectedWeeklyWorkingTime = getSelectedWeeklyWorkingTime(weeklyWorkingTimes, weeklyWorkingTimeId);
        const workingRate = getWorkingRate(workingPatternTemplate, selectedWeeklyWorkingTime?.weeklyAmountWorked);
        return weeklyWorkingTimeDisplay + ' (' + workingRate + '%)';
    }

    return weeklyWorkingTimeDisplay;
};

export const getAverageDailyWorkingTimeDisplay = (workingPatternTemplate: WorkingPatternTemplateForm): string => {
    return (
        t('contracts_settings_page.daily') +
        ': ' +
        t('duration.formatDuration', {
            duration: getDailyWorkingTime(workingPatternTemplate) / 60,
            unit: UnitType.HOURS,
        })
    );
};

export const getDaysWorkPerWeekDisplay = (workingPatternTemplate: WorkingPatternTemplateForm): string => {
    return t('contracts_settings_page.days_week') + ': ' + getDaysWorkPerWeek(workingPatternTemplate) + ' ';
};
export const getWorkingRate = (workingPatternTemplate: WorkingPatternTemplateForm, selectedWeeklyWorkingTimeAmountWorked: number | undefined): number => {
    const weeklyWorkingTime = getWeeklyWorkingTime(workingPatternTemplate);
    if (selectedWeeklyWorkingTimeAmountWorked) {
        return Math.round((weeklyWorkingTime / selectedWeeklyWorkingTimeAmountWorked) * 10000) / 100;
    }
    return 100;
};

const getSelectedWeeklyWorkingTime = (weeklyWorkingTimes: WeeklyWorkingTime[], weeklyWorkingTimeId: number) => {
    return weeklyWorkingTimes.find(weeklyWorkingTime => weeklyWorkingTime.id === weeklyWorkingTimeId);
};
