import { EmployeeAvatarWithDetails } from '@/Components/employee-avatar/EmployeeAvatarWithDetails';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { getLabelTranslation } from '@/utils/language.util';
import { StatusIcon } from '@/assets/Icons/Icons';
import { EmployeeProfileChange, EmployeeProfileChangeRow, EmployeeProfileChangeSearch } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { Divider, List, ListItem, ListItemButton, Paper, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { EmployeeProfilePendingRequestDialog } from './employee-profile-pending-request-dialog/EmployeeProfilePendingRequestDialog';
import { useTranslation } from 'react-i18next';
import { FiltersBar, FilterType } from '@/Components/filters-bar/FiltersBar';
import { getSectionDefinitions } from '@/domain/section-setting/Section.service';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { SectionDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { UseQueryResult } from '@/page/Query.type';
import { searchEmployeeProfilePendingChanges } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { getSelectFilterNumberValues } from '@/Components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/Components/filters-bar/useFiltersStorage';

/**
 * For this page we want to un group the pending rows and show them as separate items
 */
type UnGroupedPendingRow = Omit<EmployeeProfileChange, 'pendingRows'> & {
    pendingRow: EmployeeProfileChangeRow;
};

export const ManageEmployeeProfilePendingRequestPage: FC = () => {
    const { t } = useTranslation();
    const [pendingRequest, setPendingRequest] = useState<UnGroupedPendingRow>();

    const [filters, setFilters] = useFiltersStorage('manage-employee-profile-pending-request-filters', [
        {
            key: 'sectionDefinitionIds',
            filterName: t('manage_people_pending_request_page.section_definition_filter'),
            type: 'multi-select',
            availableRules: [],
            rule: 'EQUALS',
            selectMode: 'ASYNC',
            defaultVisibility: 'visible',
            fetchOptions: async () =>
                getSectionDefinitions().then(sections =>
                    sections.filter(isSectionDefinitionAvailable).map(section => ({
                        label: getLabelTranslation(section.name),
                        value: section.id,
                    })),
                ),
        } satisfies FilterType,
    ]);

    const sectionDefinitionIds = getSelectFilterNumberValues(filters?.[0]);

    const {
        data: pendingRequests = [],
        isLoading,
        isError,
        error,
        refetch,
    } = useGetPendingRequests(
        {
            sectionDefinitionIds,
        },
        { enabled: !!filters?.length },
    );

    const handleRowClick = (pr: UnGroupedPendingRow) => async () => {
        setPendingRequest(pr);
    };

    // split pendingRows into separate items
    const unGroupedPendingRequests = pendingRequests?.reduce<UnGroupedPendingRow[]>((ungroupedRows, pr) => {
        return [
            ...ungroupedRows,
            ...pr.pendingRows.map(pendingRow => ({
                employee: pr.employee,
                employeeSection: pr.employeeSection,
                pendingRow,
            })),
        ];
    }, []);

    return (
        <Stack flex={1} component={Paper} gap={3} p={2.5}>
            <FiltersBar filters={filters} onFiltersChange={setFilters} clearable={false} />
            <StateHandler isLoading={isLoading} isError={isError} isEmpty={!pendingRequests?.length} error={error}>
                {/* Pending requests list */}
                <Stack component={List} flex={1} divider={<Divider />} disablePadding>
                    {unGroupedPendingRequests?.map(pr => (
                        <ListItem key={pr.pendingRow.id} disablePadding>
                            <Stack
                                component={ListItemButton}
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                alignSelf='stretch'
                                py={2}
                                onClick={handleRowClick(pr)}
                                flexWrap='wrap'
                                columnGap={3}
                                rowGap={1}
                                disableRipple
                            >
                                <Stack direction='row' alignItems='center' flexWrap='wrap' columnGap={3} rowGap={1}>
                                    <EmployeeAvatarWithDetails employee={pr.employee} width={180} />
                                    <Stack direction='row'>
                                        <StatusIcon htmlColor='purple' fontSize='small' viewBox='0 0 16 16' />
                                        <Typography>{getLabelTranslation(pr.employeeSection.sectionDefinition.name)}</Typography>
                                    </Stack>
                                </Stack>
                                <Typography variant='body1'>
                                    {t('manage_people_pending_request_page.request_tracking', {
                                        employee: pr.employee,
                                        requestedDate: pr.pendingRow.updatedAt,
                                    })}
                                </Typography>
                            </Stack>
                        </ListItem>
                    ))}
                    {pendingRequest && (
                        <EmployeeProfilePendingRequestDialog
                            open={true}
                            onClose={() => setPendingRequest(undefined)}
                            employee={pendingRequest.employee}
                            employeeSection={pendingRequest.employeeSection}
                            pendingRow={pendingRequest.pendingRow}
                            onSuccess={() => {
                                setPendingRequest(undefined);
                                refetch();
                            }}
                        />
                    )}
                </Stack>
            </StateHandler>
        </Stack>
    );
};

// TODO clean,move and rename this
const useGetPendingRequests = (
    search: EmployeeProfileChangeSearch = {},
    {
        enabled,
    }: {
        enabled: boolean;
    },
): UseQueryResult<EmployeeProfileChange[]> => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const [employeeProfileChanges, setEmployeeProfileChanges] = useState<EmployeeProfileChange[]>();

    const fetchPendingRequests = useCallback(async (search: EmployeeProfileChangeSearch) => {
        try {
            const data = await searchEmployeeProfilePendingChanges(search);
            setEmployeeProfileChanges(data);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useDeepCompareEffect(() => {
        if (enabled) {
            fetchPendingRequests(search);
        }
    }, [search, fetchPendingRequests, enabled]);

    return {
        data: employeeProfileChanges,
        setData: setEmployeeProfileChanges,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchPendingRequests(search),
    };
};

const SECTION_DEFINITIONS_AVAILABLE: SectionDefinition['type'][] = [
    SectionType.PERSONAL_INFO,
    SectionType.ADDRESS,
    SectionType.CUSTOM_SINGLE_ROW,
    SectionType.CUSTOM_MULTI_ROW,
];

const isSectionDefinitionAvailable = (section: SectionDefinition) => {
    return SECTION_DEFINITIONS_AVAILABLE.includes(section.type);
};
