import { Paper, Stack, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FC, SyntheticEvent, useState } from 'react';

import { Folder } from '@/domain/document/Document.model';
import { Folder01Icon } from 'hugeicons-react';
import { CompanyDocumentDetails } from './CompanyDocumentDetails';

type Props = {
    folders: Folder[];
};

export const CompanyDocumentContainer: FC<Props> = ({ folders }) => {
    const theme = useTheme();

    const [folderId, setFolderId] = useState<number>(0);

    const handleChange = (_event: SyntheticEvent, newValue: number) => {
        setFolderId(newValue);
    };

    const folder = folders[folderId];

    return (
        <Stack direction='row' gap={2} justifyContent='center' flex={1}>
            <Paper sx={{ minWidth: '230px' }}>
                <Tabs
                    scrollButtons={false}
                    orientation='vertical'
                    variant='scrollable'
                    value={folderId}
                    onChange={handleChange}
                    aria-label='Vertical tabs example'
                >
                    {folders?.map((folder, index) => (
                        <Tab
                            key={folder.id}
                            iconPosition={'start'}
                            icon={<Folder01Icon />}
                            label={folder.folderName}
                            sx={{
                                justifyContent: 'flex-start',
                                p: 2,
                                margin: 0,
                                fontSize: 14,
                                minHeight: 64,
                                minWidth: '100%',
                                textAlign: 'left',
                                '&.Mui-selected': {
                                    fontWeight: 400,
                                    color: theme.palette.primary.main,
                                },
                            }}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </Paper>

            {/* List documents in current folder */}
            <CompanyDocumentDetails folder={folder} />
        </Stack>
    );
};

const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
};
