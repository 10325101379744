import { FC } from 'react';
import { Box, Divider, Paper, Stack, Typography, useTheme } from '@mui/material';
import { getMonthTranslationKey } from '@/utils/datetime.util';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getTotalWorkedCount } from '../EmployeeTimesheet.util';
import { MonthlyTimesheetReport, TimesheetCycle } from '@/domain/timesheet/Timesheet.model';
import { AlertCircleIcon } from 'hugeicons-react';
import { getYearFromTimesheetCycle } from '@/domain/timesheet/Timesheet.service';

type MobileMonthlyViewProps = {
    timesheetsMonthly: MonthlyTimesheetReport;
    employeeId: number;
    selectedTimesheetCycle: TimesheetCycle;
};

export const MobileMonthlyView: FC<MobileMonthlyViewProps> = ({ timesheetsMonthly, employeeId, selectedTimesheetCycle }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <Paper elevation={0}>
            <Stack p={2} gap={1}>
                <Typography variant='body2bold'>{t('timesheets.timesheets')}</Typography>
                <Stack gap={1}>
                    {timesheetsMonthly?.monthTimesheets?.map(monthTimesheet => (
                        <Box
                            key={monthTimesheet.month}
                            onClick={() => {
                                navigate(
                                    `/profile/${employeeId}/timesheets/history/${getYearFromTimesheetCycle(monthTimesheet.month, selectedTimesheetCycle)}/${monthTimesheet.month}`,
                                );
                            }}
                        >
                            <Stack direction={'row'} justifyContent={'space-between'} marginBottom={1}>
                                <Stack gap={1}>
                                    <Typography variant={'body2'}>{t(getMonthTranslationKey(monthTimesheet.month))}</Typography>
                                    {monthTimesheet.missingCount > 0 && (
                                        <Stack direction={'row'} color={theme.palette.error.main} gap={1} alignItems={'center'}>
                                            <AlertCircleIcon />
                                            <Typography variant={'body2'}>
                                                {monthTimesheet.missingCount} {t('timesheets.table_headers.missing')}
                                            </Typography>
                                        </Stack>
                                    )}
                                </Stack>

                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography variant={'body2'}>{getTotalWorkedCount(monthTimesheet.totalWorkedCount)}</Typography>
                                </Stack>
                            </Stack>

                            <Divider />
                        </Box>
                    ))}
                </Stack>
            </Stack>
        </Paper>
    );
};
