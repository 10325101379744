import { FormControlLabel, Stack, TextField } from '@mui/material';
import { FC, useEffect } from 'react';
import { DialogContainer } from '@/Components/dialog-container/DialogContainer';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ObjectiveCategory } from '@/domain/objective/Objective.model';

type Props = {
    open: boolean;
    handleSave: (data: CategoryForm) => void;
    closeDialog: () => void;
    activeCategoryData: ObjectiveCategory | undefined;
    mode: 'edit' | 'create';
};

export interface CategoryForm {
    name: string;
}

export const AddCategoryDialog: FC<Props> = ({ open, handleSave, closeDialog, activeCategoryData, mode }) => {
    const { t } = useTranslation();
    const schema = yup.object().shape({
        name: yup.string().trim().required(),
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
        },
    });

    useEffect(() => {
        if (mode === 'edit') {
            reset({ name: activeCategoryData?.name });
        } else {
            reset();
        }
    }, [activeCategoryData, reset, mode]);

    const onSave = (data: CategoryForm) => {
        handleSave(data);
        reset();
    };

    const onClose = () => {
        reset();
        closeDialog();
    };

    return (
        <DialogContainer
            open={open}
            onClose={onClose}
            onSave={handleSubmit(onSave, console.error)}
            title={mode === 'create' ? t('reviews_settings_page.category_dialog.new_category') : t('reviews_settings_page.category_dialog.edit_category')}
        >
            <Stack>
                <FormControlLabel
                    label={t('reviews_settings_page.category_dialog.category_name')}
                    labelPlacement='top'
                    control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
                />
            </Stack>
        </DialogContainer>
    );
};
