import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Typography } from '@mui/material';
import { SurveyQuestion } from '@/domain/survey/Survey.model';
import Slide from '@mui/material/Slide';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';

type Props = {
    question: SurveyQuestion;
    selectedLanguage: UserLanguage;
};

const useStyles = makeStyles(theme => ({
    paperBackground: {
        padding: theme.spacing(6),
        maxWidth: 720,
        margin: 'auto',
    },
    title: {
        marginBottom: theme.spacing(4),
        display: 'block',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            marginBottom: 24,
            fontSize: 18,
            display: 'block',
        },
    },
    control: {
        width: '100%',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
        '& .MuiFormControlLabel-label': {
            color: '#C4C4C4',
            fontSize: 16,
        },
    },
}));

export const SurveySection: FC<Props> = ({ question, selectedLanguage }) => {
    const classes = useStyles();

    if (!question) {
        return <></>;
    }

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Paper elevation={0} className={classes.paperBackground}>
                <Grid item>
                    <Typography variant='h1' className={classes.title}>
                        {getLabelTranslation(question.question, selectedLanguage)}
                    </Typography>
                </Grid>
            </Paper>
        </Slide>
    );
};
