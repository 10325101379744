import { FormHelperText, Grid, Typography } from '@mui/material';
import { AlertCircleIcon } from 'hugeicons-react';
import { FC } from 'react';

type Props = {
    message: string;
};
export const ErrorMessage: FC<Props> = ({ message }) => {
    return (
        <Grid item>
            <FormHelperText component='div' error={true}>
                <Grid container direction='row' spacing={1} wrap='nowrap' alignItems='center'>
                    <Grid item>
                        <AlertCircleIcon size={16} />
                    </Grid>
                    <Grid item>
                        <Typography variant='body1' color='error'>
                            {message}
                        </Typography>
                    </Grid>
                </Grid>
            </FormHelperText>
        </Grid>
    );
};
