import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Cancel01Icon } from 'hugeicons-react';

export type DialogContainerProps = {
    open: boolean;
    onClose: () => void;
    onSave?: () => void;
    onCancel?: () => void;
    cancelEnabled?: boolean;
    title?: string;
    primaryButton?: ReactNode;
    customHeader?: ReactNode;
    cancelButtonText?: string;
    cancelButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    saveButtonText?: string;
    isActionButtonDisabled?: boolean;
    primaryActionDisabled?: boolean;
    showPrimaryActionButton?: boolean;
    showSecondaryActionButton?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md';
    fullScreen?: boolean;
} & DialogProps;

/** @deprecated
 * use dialogWrapper instead
 */
export const DialogContainer: FC<PropsWithChildren<DialogContainerProps>> = ({
    open,
    title,
    cancelButtonText,
    cancelButtonColor = 'error',
    saveButtonText,
    onClose,
    onCancel,
    children,
    onSave = () => {},
    isActionButtonDisabled = false,
    primaryActionDisabled = false,
    showPrimaryActionButton = true,
    customHeader,
    primaryButton,
    maxWidth = 'xs',
    fullScreen = false,
    ...props
}: PropsWithChildren<DialogContainerProps>) => {
    const theme = useTheme();
    const { t } = useTranslation();

    // All dialogs should be full screen on mobile
    const fullScreenOnMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = (reason: 'backdropClick' | 'escapeKeyDown') => {
        // we don't want to close the dialog when the user clicks outside of it
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    return (
        <Dialog open={open} fullScreen={fullScreen ? true : fullScreenOnMobile} onClose={(_, reason) => handleClose(reason)} maxWidth={maxWidth} {...props}>
            <DialogTitle display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={2}>
                {!customHeader ? title : customHeader}
                <IconButton aria-label='close' onClick={() => onClose()} size='large'>
                    <Cancel01Icon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    paddingBottom: isActionButtonDisabled || (!cancelButtonText && !showPrimaryActionButton) ? 3 : 1,
                }}
            >
                {children}
            </DialogContent>

            {!isActionButtonDisabled && (
                <DialogActions>
                    {cancelButtonText && (
                        <Button onClick={onCancel ?? onClose} variant='contained' color={cancelButtonColor} fullWidth={true}>
                            {cancelButtonText}
                        </Button>
                    )}
                    {showPrimaryActionButton &&
                        (primaryButton ?? (
                            <Button onClick={onSave} disabled={primaryActionDisabled} autoFocus fullWidth={true} name={saveButtonText ?? t('general.save')}>
                                {saveButtonText ?? t('general.save')}
                            </Button>
                        ))}
                </DialogActions>
            )}
        </Dialog>
    );
};
