import { EmployeeReviewFeedbackSkillFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { EmployeeReviewSkillReviewDialog } from '@/page/review/employee-review-skill-review-dialog/EmployeeReviewSkillReviewDialog';
import { EmployeeReviewStarsIcons } from '@/page/review/employee-review-stars-icons/EmployeeReviewStarsIcons';
import { Button, useTheme } from '@mui/material';
import { Add01Icon, MessageEdit02Icon, Tick02Icon } from 'hugeicons-react';
import { FC, useState } from 'react';

type EmployeeReviewFeedbackSkillFormProps = {
    skillState: SkillState;
    feedbackSkillItem: EmployeeReviewFeedbackSkillFormType;
    handleSkillChanged: (skillAndCategory: EmployeeReviewFeedbackSkillFormType) => void;
    disabled?: boolean;
};

export type SkillState = 'reviewed' | 'not_reviewed' | 'summary_meeting' | 'disabled';

export const EmployeeReviewFeedbackSkill: FC<EmployeeReviewFeedbackSkillFormProps> = ({
    skillState,
    feedbackSkillItem,
    handleSkillChanged,
    disabled = false,
}) => {
    const [activeSkill, setActiveSkill] = useState<EmployeeReviewFeedbackSkillFormType>();

    const handleSkillClicked = (skillAndCategory: EmployeeReviewFeedbackSkillFormType) => {
        setActiveSkill(skillAndCategory);
    };

    const onClose = () => {
        setActiveSkill(undefined);
    };

    const renderSkillItem = (skillState: SkillState, feedbackSkillItem: EmployeeReviewFeedbackSkillFormType) => {
        switch (skillState) {
            case 'reviewed':
                return (
                    <ReviewedSkillItem
                        skillScore={feedbackSkillItem.score}
                        skillName={feedbackSkillItem.skill.name ?? ''}
                        handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)}
                    />
                );
            case 'not_reviewed':
                return <NotReviewedSkillItem skillName={feedbackSkillItem.skill.name ?? ''} handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)} />;
            case 'summary_meeting':
                return (
                    <SummaryMeetingSkillItem skillName={feedbackSkillItem.skill.name ?? ''} handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)} />
                );
            case 'disabled':
                return <DisabledSkillItem skillName={feedbackSkillItem.skill.name ?? ''} handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)} />;
        }
    };

    return (
        <>
            {renderSkillItem(skillState, feedbackSkillItem)}
            {activeSkill && (
                <EmployeeReviewSkillReviewDialog skillAndCategory={activeSkill} onConfirm={handleSkillChanged} onClose={onClose} disabled={disabled} />
            )}
        </>
    );
};

type ReviewedSkillItemProps = {
    skillScore: number | undefined;
    skillName: string;
    handleSkillClicked: () => void;
};

export const ReviewedSkillItem: FC<ReviewedSkillItemProps> = ({ skillScore, skillName, handleSkillClicked }) => {
    const { palette } = useTheme();
    return (
        <Button
            startIcon={<Tick02Icon />}
            endIcon={<EmployeeReviewStarsIcons score={skillScore} htmlColor={palette.primary.main} />}
            onClick={() => handleSkillClicked()}
            variant='text'
            sx={{ backgroundColor: palette.primary.light, width: 'fit-content' }}
        >
            {skillName}
        </Button>
    );
};

type NotReviewedSkillItemProps = {
    skillName: string;
    handleSkillClicked: () => void;
};

export const NotReviewedSkillItem: FC<NotReviewedSkillItemProps> = ({ skillName, handleSkillClicked }) => {
    const { palette } = useTheme();

    return (
        <Button
            variant='text'
            startIcon={<Add01Icon />}
            onClick={() => handleSkillClicked()}
            sx={{
                backgroundColor: palette.grey['100'],
                color: palette.text.primary,
                width: 'fit-content',
            }}
        >
            {skillName}
        </Button>
    );
};

type SummaryMeetingSkillItemProps = {
    skillName: string;
    handleSkillClicked: () => void;
};

export const SummaryMeetingSkillItem: FC<SummaryMeetingSkillItemProps> = ({ skillName, handleSkillClicked }) => {
    const { palette } = useTheme();

    return (
        <Button
            variant='text'
            onClick={() => handleSkillClicked()}
            startIcon={<MessageEdit02Icon />}
            sx={{
                backgroundColor: palette.warning.light,
                color: palette.warning.dark,
                width: 'fit-content',
                '&:hover': {
                    backgroundColor: palette.warning.main,
                    color: palette.common.white,
                    stroke: palette.common.white,
                },
                stroke: palette.warning.dark,
            }}
        >
            {skillName}
        </Button>
    );
};

type DisabledSkillItemProps = {
    skillName: string;
    handleSkillClicked: () => void;
};

export const DisabledSkillItem: FC<DisabledSkillItemProps> = ({ skillName, handleSkillClicked }) => {
    const { palette } = useTheme();

    return (
        <Button
            disabled
            onClick={() => handleSkillClicked()}
            variant='text'
            startIcon={<Add01Icon />}
            sx={{
                backgroundColor: palette.grey['100'],
                color: palette.action.disabled,
                opacity: 0.3,
                width: 'fit-content',
            }}
        >
            {skillName}
        </Button>
    );
};
