import { FC, useState } from 'react';
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { DialogContainer } from '../dialog-container/DialogContainer';
import { DragDropHorizontalIcon } from 'hugeicons-react';

type Props = {
    open: boolean;
    onSave: (reorderItems: ReorderItem[]) => void;
    onClose: () => void;
    initialReorderItems: ReorderItem[];
};

export const ReorderModal: FC<Props> = ({ open, onSave, onClose, initialReorderItems }) => {
    const { t } = useTranslation();
    const [reorderItems, setReorderItems] = useState<ReorderItem[]>(initialReorderItems);

    const handleSave = () => {
        onSave(reorderItems);
    };

    const handleItemsReordered = (reorderItems: ReorderItem[]) => {
        setReorderItems(reorderItems);
    };

    return (
        <DialogContainer open={open} onClose={onClose} onSave={handleSave} saveButtonText={t('general.done')} title={t('general.reorder')} maxWidth='sm'>
            <Reorder reorderItems={reorderItems} handleItemsReordered={handleItemsReordered} />
        </DialogContainer>
    );
};

export interface ReorderItem {
    id: number | string;
    name: string;
    order: number;
    hierarchy?: 'primary' | 'secondary';
}

export interface ReorderProps {
    reorderItems: ReorderItem[];
    handleItemsReordered: (reorderItems: ReorderItem[]) => void;
}

export const Reorder: FC<ReorderProps> = ({ reorderItems, handleItemsReordered }) => {
    const onDragEnd = (result: DropResult) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        const isDestinationSameAsSource = destination.droppableId === source.droppableId && destination.index === source.index;
        if (isDestinationSameAsSource) {
            return;
        }

        const updatedReorderItems = [...reorderItems];
        updatedReorderItems.splice(source.index, 1);
        updatedReorderItems.splice(destination.index, 0, reorderItems[source.index]);

        const reorderedItemsWithOrder = updatedReorderItems.map((reorderItem, index) => {
            return {
                ...reorderItem,
                order: index,
            };
        });

        handleItemsReordered(reorderedItemsWithOrder);
    };

    const getItemsByOrder = (reorderItems: ReorderItem[]) => {
        return reorderItems.sort((a, b) => a.order - b.order);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'droppable'}>
                {provided => (
                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                        {getItemsByOrder(reorderItems)?.map((item, index) => (
                            <Draggable draggableId={item?.id?.toString() + item?.name} index={index} key={item?.id + item?.name}>
                                {provided => (
                                    <Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} py={1}>
                                        <Item item={item} />
                                    </Box>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    );
};

interface ItemProps {
    item: ReorderItem;
}

const Item: FC<ItemProps> = ({ item }) => {
    const theme = useTheme();
    const hierarchy = item.hierarchy ?? 'primary';
    return (
        <>
            {hierarchy === 'primary' && (
                <Paper
                    sx={{
                        border: `1px solid ${theme.palette.grey[300]}`,
                    }}
                >
                    <Box p={1}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <DragDropHorizontalIcon />
                            <Typography variant='body1bold'>{item.name}</Typography>
                        </Stack>
                    </Box>
                </Paper>
            )}

            {hierarchy === 'secondary' && (
                <Paper
                    sx={{
                        border: `1px solid ${theme.palette.grey[300]}`,
                        marginLeft: 2,
                    }}
                >
                    <Box p={1}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <DragDropHorizontalIcon />
                            <Typography variant='body1'>{item.name}</Typography>
                        </Stack>
                    </Box>
                </Paper>
            )}
        </>
    );
};
