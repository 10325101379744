import { Footer, FooterActions } from '@/page/layout/Footer';
import { Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImportedTable } from '../import-table/ImportTable';
import { ImportError, ImportResult, ImportType } from '@/domain/import/Import.model';

type ImportExcelProps = {
    importType: ImportType;
    fallbackUrl: string;
    sectionId?: string;
};

export const ImportExcel: FC<ImportExcelProps> = ({ importType, fallbackUrl, sectionId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const importResult = useLocation().state as ImportResult;

    const importAgain = () => {
        navigate(fallbackUrl + '?importType=' + importType + (sectionId ? `&sectionId=${sectionId}` : ''), { replace: true });
    };

    const displayError = !!importResult?.error?.code;
    return (
        <Stack gap={2}>
            {displayError && <ImportExcelErrors importError={importResult.error} />}
            <Paper elevation={1}>{importResult && !displayError && <ImportedTable importResult={importResult} importType={importType} />}</Paper>

            <Footer>
                <FooterActions
                    actions={[
                        {
                            onClick: () => importAgain(),
                            children: t('import_page.import_data'),
                            variant: 'outlined',
                        },
                        {
                            onClick: () => navigate(fallbackUrl),
                            children: t('general.done'),
                            variant: 'contained',
                        },
                    ]}
                />
            </Footer>
        </Stack>
    );
};

const ImportExcelErrors: FC<{ importError: ImportError }> = ({ importError }) => {
    const { t } = useTranslation();

    return (
        <Stack gap={2} p={2} component={Paper}>
            <Typography variant='h2bold' color='error' alignSelf={'center'}>
                {t(`import_page.error_codes.${importError.code.toLowerCase()}`)}
            </Typography>
            {importError.message && (
                <Typography variant='body2' alignSelf={'center'}>
                    {`(${importError.message})`}
                </Typography>
            )}
        </Stack>
    );
};
