import { Paper, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationLanguageSelector } from '@/Components/translation-language-selector/TranslationLanguageSelector';
import { getRealmLanguage } from '@/utils/language.util';
import { DepartmentsTree } from '@/page/setting/organization/department/department-tree/DepartmentsTree';

export const CompanySettingsDepartmentsPage: FC = () => {
    const { t } = useTranslation();
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());

    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
            }}
        >
            <Stack gap={2}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} flex={1} flexWrap='wrap'>
                    <Typography variant='h2' noWrap flex={1}>
                        {t('settings_organization.departments.page_title')}
                    </Typography>
                    <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />
                </Stack>

                <DepartmentsTree translationLanguage={translationLanguage} />
            </Stack>
        </Paper>
    );
};
