import { API_BASE_URL, client } from '@/api/common';
import { ThirdParty, ThirdPartyIntegrationLog, ThirdPartyMutation, ThirdPartyPublicApiIntegration } from '@/domain/third-party/ThirdParty.model';
import { AxiosResponse } from 'axios';

type ThirdPartyIntegrationLogsDTO = ThirdPartyIntegrationLog;
type ThirdPartyPublicApiIntegrationDTO = DateToString<ThirdPartyPublicApiIntegration>;

type ThirdPartyDTO = Overwrite<
    ThirdParty,
    {
        publicApiIntegration?: ThirdPartyPublicApiIntegrationDTO;
    }
>;

type ThirdPartyMutationDTO = ThirdPartyMutation;

const THIRD_PARTY_API_BASE_PATH = API_BASE_URL + `/third-parties`;

const searchThirdParties = async (): Promise<ThirdParty[]> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/search`;
    return (await client.post<ThirdPartyDTO[], AxiosResponse<ThirdPartyDTO[]>>(url, {})).data.map(convertToThirdParty);
};

const getThirdParty = async (thirdPartyId: number): Promise<ThirdParty> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}`;
    const { data } = await client.get<ThirdPartyDTO, AxiosResponse<ThirdPartyDTO>>(url);
    return convertToThirdParty(data);
};

const createThirdParty = async (request: ThirdPartyMutation): Promise<ThirdParty> => {
    const { data } = await client.post<ThirdPartyDTO, AxiosResponse<ThirdPartyDTO>, ThirdPartyMutationDTO>(THIRD_PARTY_API_BASE_PATH, request);
    return convertToThirdParty(data);
};

const updateThirdParty = async (thirdPartyId: number, request: ThirdPartyMutation): Promise<ThirdParty> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}`;
    const { data } = await client.put<ThirdPartyDTO, AxiosResponse<ThirdPartyDTO>, ThirdPartyMutationDTO>(url, request);
    return convertToThirdParty(data);
};

const deleteThirdParty = async (thirdPartyId: number): Promise<void> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}`;
    await client.delete(url);
};

const toggleThirdParty = async (thirdPartyId: number, enabled: boolean): Promise<ThirdParty> => {
    const action = enabled ? 'enable' : 'disable';
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}/${action}`;
    const { data } = await client.post<
        ThirdPartyDTO,
        AxiosResponse<ThirdPartyDTO>,
        {
            enabled: boolean;
        }
    >(url, { enabled });
    return convertToThirdParty(data);
};

const generateApiKey = async (thirdPartyId: number): Promise<string> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/${thirdPartyId}/api-key`;
    const { data } = await client.post<string, AxiosResponse<string>>(url);
    return data;
};

const convertToThirdParty = ({ publicApiIntegration, ...restDto }: ThirdPartyDTO): ThirdParty => {
    const publicApi: ThirdParty['publicApiIntegration'] = publicApiIntegration
        ? {
              ...publicApiIntegration,
              lastSignInAt: publicApiIntegration.lastSignInAt ? new Date(publicApiIntegration.lastSignInAt) : undefined,
              expireAt: publicApiIntegration.expireAt,
          }
        : undefined;
    return {
        ...restDto,
        publicApiIntegration: publicApi,
    };
};

const getThirdPartyIntegrationLogs = async (thirdPartyIntegrationId: number): Promise<ThirdPartyIntegrationLog[]> => {
    const url = THIRD_PARTY_API_BASE_PATH + `/integrations/${thirdPartyIntegrationId}/logs`;
    return (await client.get<ThirdPartyIntegrationLogsDTO[], AxiosResponse<ThirdPartyIntegrationLogsDTO[]>>(url)).data;
};

export const thirdPartyAPI = {
    searchThirdParties,
    getThirdParty,
    getThirdPartyIntegrationLogs,
    createThirdParty,
    updateThirdParty,
    deleteThirdParty,
    toggleThirdParty,
    generateApiKey,
};
