import { Chip } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { Survey, SurveyStatus } from '@/domain/survey/Survey.model';
import { FC } from 'react';

type Props = {
    survey: Survey;
};

const getNumberOfDaysForNotPublishedSurvey = (survey: Survey): string => {
    if (survey.status === SurveyStatus.NOT_PUBLISHED) {
        if (!survey.startDate) {
            return 'No start date';
        }
        return `Starts in ${differenceInDays(new Date(survey.startDate), new Date())} days`;
    } else {
        return survey.status;
    }
};

const getColorStatus = (status: SurveyStatus) => {
    if (status === SurveyStatus.CLOSED) {
        return 'error';
    }
    if (status === SurveyStatus.NOT_PUBLISHED) {
        return 'warning';
    }
    if (status === SurveyStatus.PUBLISHED) {
        return 'success';
    }
};

export const SurveyStatusChip: FC<Props> = ({ survey }) => {
    return <Chip color={getColorStatus(survey.status)} size='small' label={getNumberOfDaysForNotPublishedSurvey(survey)} />;
};
