import { useAppSelector } from '@/stores/store';

const DRAWER_DEFAULT_WIDTH = 56;
const DRAWER_WIDTH_EXPANDED = 220;

/**
 * The Drawer hook provides the drawer width based on the state of the expandMenu.
 * @returns drawerWidth
 */
export const useDrawer = (): { drawerWidth: number } => {
    const expandMenu = useAppSelector(state => state.ui.expandMenu);

    const drawerWidth = expandMenu ? DRAWER_WIDTH_EXPANDED : DRAWER_DEFAULT_WIDTH;

    return { drawerWidth };
};
