import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '@/stores/store';
import { useParams } from 'react-router-dom';
import { LeavesHistory } from './employee-profile-leave/LeavesHistory/LeavesHistory';
import { LeavesOverview } from './employee-profile-leave/LeavesOverview';

export const EmployeeProfileLeavesPage: FC = () => {
    const [page, setPage] = useState<'overview' | 'history'>('overview');
    const params = useParams();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const employeeId = params?.employeeId ? Number(params?.employeeId) : currentEmployee?.id;
    const userLeaveTypeId = params?.userLeaveTypeId;

    useEffect(() => {
        if (userLeaveTypeId) {
            setPage('history');
        } else {
            setPage('overview');
        }
    }, [userLeaveTypeId]);

    if (!employeeId) {
        return <></>;
    }

    switch (page) {
        case 'overview':
            return <LeavesOverview employeeId={employeeId} />;
        case 'history': //this page is managed by the useEffect above
            return <LeavesHistory employeeId={employeeId} />;
        default:
            return <></>;
    }
};
