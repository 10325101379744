import { getMobileNavigationItems, MAXIMUM_NUMBER_OF_ITEMS_IN_MOBILE_NAVIGATION } from '@/Components/mobile-navigation/Navigation.util';
import { useAppSelector } from '@/stores/store';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { More03Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const MobileNavigation: FC = () => {
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const realm = useAppSelector(state => state.ui.currentRealm);

    const location = useLocation();
    if (!realm || !currentEmployee) {
        throw new Error('Realm or current employee is not defined');
    }
    const mobileBottomItems = getMobileNavigationItems(realm.realmFeatures, policies, currentEmployee)?.slice(0, MAXIMUM_NUMBER_OF_ITEMS_IN_MOBILE_NAVIGATION);
    const defaultIndex = location.pathname === '/additional-pages' ? 5 : mobileBottomItems.findIndex(item => item.to === location.pathname);

    const [currentItemIndex, setCurrentItemIndex] = useState<number>(defaultIndex ?? 0);

    return (
        <BottomNavigation
            value={currentItemIndex}
            onChange={(_event, newValue) => {
                setCurrentItemIndex(newValue);
            }}
            sx={{
                px: 0,
                pb: 2,
                '& .MuiButtonBase-root': {
                    minWidth: 50,
                },
                display: { sm: 'none' },
            }}
        >
            {mobileBottomItems?.map(({ icon: Icon, to }) => <BottomNavigationAction key={to} component={Link} to={to} icon={<Icon />} />)}
            <BottomNavigationAction component={Link} to='/additional-pages' icon={<More03Icon />} />
        </BottomNavigation>
    );
};
