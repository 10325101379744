import { EditableStackSection } from '@/Components/section/StackSectionComponent/EditableStackSection';
import { StackSection } from '@/Components/section/StackSectionComponent/StackSection';
import { SectionActionButton, SectionField } from '@/Components/section/types';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { EmployeeSectionFieldProfileChange } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { getSectionAction } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { Gender, MaritalStatus } from '@/domain/employee/Employee.model';
import {
    createEmployeePersonalInfoPendingRequest,
    getGenderTranslationKey,
    getMaritalStatusTranslationKey,
    getSectionActionButton,
    updateEmployeePersonalInfo,
    updateEmployeePersonalInfoPendingRequest,
} from '@/domain/employee/Employee.service';
import { EmployeePersonalInfoMutation } from '@/domain/employee/EmployeePersonalInfo.model';
import { canManageEmployeePersonalInfos, canManagePendingEmployeePersonalInfos } from '@/domain/permission/Permission.service';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import {
    getAgeDisplay,
    useEmployeePersonalInfoSectionFields,
} from '@/page/employee-profile/employee-profile-info/EmployeePersonalInfoSection/EmployeePersonalInfoSection.hook';
import { useGetEmployeePersonalInfo } from '@/hooks/employee/EmployeePersonalInfo.hook';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Country, getCountry } from '@/utils/countries.util';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    getPersonalInfoSectionSchema,
    PersonalInfoSectionFormValues,
} from '@/page/employee-profile/employee-profile-info/EmployeePersonalInfoSection/EmployeePersonalInfoSection.schema';
import { PersonalInfoPageSectionSkeleton } from '@/page/employee-profile/EmployeeProfileInfoPage';

type Props = {
    employeeId: number;
    pendingFields: EmployeeSectionFieldProfileChange[];
};

export const EmployeePersonalInfoSection: FC<Props> = ({ employeeId, pendingFields: employeePendingFields }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [editable, setEditable] = useState(false);
    const dispatch = useAppDispatch();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const {
        data: employeePersonalInfo,
        isLoading: isEmployeePersonalInfoLoading,
        isError: isEmployeePersonalInfoError,
        error: employeePersonalInfoError,
        refetch: refetchEmployeePersonalInfo,
    } = useGetEmployeePersonalInfo(employeeId);

    const fields = useEmployeePersonalInfoSectionFields(employeePersonalInfo);

    const getStringValueFormatted = (pendingField: EmployeeSectionFieldProfileChange): string => {
        switch (pendingField.sectionFieldDefinition.fieldType) {
            case 'EMPLOYEE_BIRTH_DATE':
                return pendingField.dateValue ? getAgeDisplay(pendingField.dateValue) : '';
            case 'EMPLOYEE_GENDER':
                return pendingField?.stringValue ? t(getGenderTranslationKey(), { context: pendingField?.stringValue }) : '';
            case 'EMPLOYEE_MARITAL_STATUS':
                return pendingField?.stringValue ? t(getMaritalStatusTranslationKey(), { context: pendingField?.stringValue }) : '';
            default:
                return pendingField.stringValue ?? '';
        }
    };

    const getCountryValue = (pendingField: EmployeeSectionFieldProfileChange): Country | undefined => {
        if (pendingField?.sectionFieldDefinition?.type === SectionFieldType.COUNTRY) {
            return pendingField.stringValue ? getCountry(pendingField.stringValue) : undefined;
        }
    };

    const getPendingFieldValue = (pendingField: EmployeeSectionFieldProfileChange): string => {
        if (pendingField.sectionFieldDefinition.valueType === 'ENUM') {
            return pendingField.stringValue ?? '';
        } else {
            return '';
        }
    };

    const convertPendingFieldInSectionField = (): SectionField[] => {
        return fields
            ?.map(field => {
                const pendingField = employeePendingFields.find(pending => pending.sectionFieldDefinition.fieldType === field.fieldType);

                if (pendingField) {
                    const { sectionFieldDefinition: _sectionFieldDefinition, ...restPendingField } = pendingField;

                    return {
                        ...field,
                        ...restPendingField,
                        value: getPendingFieldValue(pendingField),
                        stringValue: getStringValueFormatted(pendingField),
                        countryValue: getCountryValue(pendingField),
                    };
                }
            })
            .filter(field => field !== undefined);
    };
    const pendingFields: SectionField[] = convertPendingFieldInSectionField();

    const mapEmployeePersonalInfoUpdateRequest = (formValues: PersonalInfoSectionFormValues): EmployeePersonalInfoMutation => {
        return {
            birthdate: formValues['birthdate'] ?? undefined,
            nationality: formValues['nationality']?.value,
            gender: (formValues['gender'] as Gender) ?? undefined,
            maritalStatus: (formValues['maritalStatus'] as MaritalStatus) ?? undefined,
            maritalStatusSince: formValues['maritalStatusSince'] ?? undefined,
            avsNumber: formValues['avsNumber'] ? formValues['avsNumber'] : undefined,
            personalEmail: formValues['personalEmail'] ? formValues['personalEmail'] : undefined,
            personalPhoneNumber: formValues['personalPhoneNumber'] ? formValues['personalPhoneNumber'] : undefined,
        };
    };

    /**
     * Update personal info or request approval for employee personal info
     */
    const handleSave = (formValues: PersonalInfoSectionFormValues): void => {
        const employeePersonalInfoUpdateRequest = mapEmployeePersonalInfoUpdateRequest(formValues);

        const handleSuccess = () => {
            refetchEmployeePersonalInfo().catch(handleError);
            // TODO: remove this when we have fetching library in place
            dispatch({
                type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
                refetchEmployee: true,
            });

            setEditable(false);
        };

        // The action is based on the user permissions
        // If the user can approve the employee personal info, we use the endpoint to bypass the approval process
        // in other case, we use the endpoint to request approval

        if (canManageEmployeePersonalInfos(policies, employeeId)) {
            updateEmployeePersonalInfo(employeeId, employeePersonalInfoUpdateRequest).then(handleSuccess);
        } else if (canManagePendingEmployeePersonalInfos(policies, employeeId)) {
            if (pendingFields?.length) {
                updateEmployeePersonalInfoPendingRequest(employeeId, employeePersonalInfoUpdateRequest).then(handleSuccess);
            } else {
                createEmployeePersonalInfoPendingRequest(employeeId, employeePersonalInfoUpdateRequest).then(handleSuccess);
            }
        }
    };

    const canApprove = canManageEmployeePersonalInfos(policies, employeeId);
    const canRequestApproval = canManagePendingEmployeePersonalInfos(policies, employeeId);
    const hasPendingChanges = !!pendingFields?.length;

    const action = getSectionAction({ canApprove, canRequestApproval, hasPendingChanges });
    const openMutationDialogButton = {
        title: t('general.edit'),
        onClick: () => {
            setEditable(true);
        },
    };
    const seePendingButton: SectionActionButton = {
        title: t('employee.sections.see_pending_changes'),
        onClick: () => {
            navigate('/people/employee-requests');
        },
    };

    const actionButton = getSectionActionButton(action, openMutationDialogButton, seePendingButton);

    return (
        <StateHandler
            isLoading={isEmployeePersonalInfoLoading}
            isError={isEmployeePersonalInfoError}
            loadingComponent={<PersonalInfoPageSectionSkeleton />}
            error={employeePersonalInfoError}
        >
            {editable ? (
                <EditableStackSection
                    sectionTitle={t('employee.sections.personal_info')}
                    fields={action === 'EDIT_PENDING' ? pendingFields : fields}
                    onSave={handleSave}
                    schema={getPersonalInfoSectionSchema()}
                    onCancel={() => setEditable(false)}
                />
            ) : (
                <StackSection
                    sectionTitle={t('employee.sections.personal_info')}
                    fields={fields}
                    pendingFields={pendingFields}
                    actionButtons={actionButton ? [actionButton] : []}
                />
            )}
        </StateHandler>
    );
};
