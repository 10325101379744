import { costCenterAPI } from '@/api/cost-center/CostCenter.api';
import { CostCenter, CostCenterMutation } from '@/domain/cost-center/CostCenter.model';

export const searchCostCenters = async (): Promise<CostCenter[]> => {
    return costCenterAPI.searchCostCenters();
};

export const createCostCenter = async (request: CostCenterMutation): Promise<CostCenter> => {
    return costCenterAPI.createCostCenter(request);
};

export const updateCostCenter = async (costCenterId: number, request: CostCenterMutation): Promise<CostCenter> => {
    return costCenterAPI.updateCostCenter(costCenterId, request);
};

export const deleteCostCenter = async (costCenterId: number): Promise<void> => {
    return costCenterAPI.deleteCostCenter(costCenterId);
};
