import {
    Survey,
    SurveyAnswer,
    SurveyAnswerMutation,
    SurveyCreationMutation,
    SurveyResults,
    SurveyUpdateMutation,
    UserSurvey,
} from '@/domain/survey/Survey.model';
import { API_BASE_URL, client } from '@/api/common';
import axios, { AxiosResponse } from 'axios';

type SurveyCreationRequestDTO = SurveyCreationMutation;
type SurveyDTO = Survey;
type UserSurveyDTO = UserSurvey;
type SurveyAnswerDTO = SurveyAnswer;
type SurveyAnswerRequestDTO = SurveyAnswerMutation;
type SurveyResultsDTO = SurveyResults;
type SurveyUpdateRequestDTO = SurveyUpdateMutation;

export const createSurvey = async (request: SurveyCreationMutation): Promise<Survey> => {
    const { data } = await client.post<SurveyDTO, AxiosResponse<SurveyDTO>, SurveyCreationRequestDTO>(API_BASE_URL + `/surveys`, request);
    return data;
};

export const deleteSurvey = async (surveyId: number): Promise<number> => {
    const { data } = await client.delete<number>(API_BASE_URL + `/surveys/${surveyId}`);
    return data;
};

export const updateSurvey = async (surveyId: number, request: SurveyUpdateMutation): Promise<Survey> => {
    const { data } = await client.patch<SurveyDTO, AxiosResponse<SurveyDTO>, SurveyUpdateRequestDTO>(API_BASE_URL + `/surveys/${surveyId}`, request);
    return data;
};

export const surveySendReminder = async (surveyId: number): Promise<Survey> => {
    const { data } = await client.post<SurveyDTO, AxiosResponse<SurveyDTO>>(API_BASE_URL + `/surveys/${surveyId}/send-reminder`, {});
    return data;
};

export const getSurveyAuthenticationToken = async (qsToken: string): Promise<string> => {
    const request = {
        token: qsToken,
    };
    const { data } = await client.post<string, AxiosResponse<string>>(API_BASE_URL + `/surveys/auth`, request);
    return data;
};

export const getUserSurvey = async (token: string): Promise<UserSurvey> => {
    const headers = {
        ['Authorization']: 'Bearer ' + token,
    };
    const { data } = await axios.get<UserSurveyDTO, AxiosResponse<UserSurveyDTO>, string>(API_BASE_URL + `/surveys/user`, {
        headers: headers,
    });
    return data;
};

export const createSurveyAnswer = async (request: SurveyAnswerMutation, token: string): Promise<SurveyAnswer> => {
    const headers = {
        ['Authorization']: 'Bearer ' + token,
    };
    const { data } = await axios.post<SurveyAnswerDTO, AxiosResponse<SurveyAnswerDTO>, SurveyAnswerRequestDTO>(
        API_BASE_URL + `/surveys/user/answers`,
        request,
        { headers: headers },
    );
    return data;
};

export const updateSurveyAnswer = async (surveyAnswerId: number, request: SurveyAnswerMutation, token: string): Promise<SurveyAnswer> => {
    const headers = {
        ['Authorization']: 'Bearer ' + token,
    };
    const { data } = await axios.patch<SurveyAnswerDTO, AxiosResponse<SurveyAnswerDTO>, SurveyAnswerRequestDTO>(
        API_BASE_URL + `/surveys/user/answers/${surveyAnswerId}`,
        request,
        { headers: headers },
    );
    return data;
};

export const getResults = async (surveyId: number): Promise<SurveyResults> => {
    const { data } = await client.get<SurveyResultsDTO, AxiosResponse<SurveyResultsDTO>, number>(API_BASE_URL + `/survey-results/${surveyId}`);
    return data;
};

export const getSurveys = async (): Promise<Survey[]> => {
    const { data } = await client.get<SurveyDTO[], AxiosResponse<SurveyDTO[], void>>(API_BASE_URL + `/surveys`);
    return data;
};

export const getSurveyById = async (surveyId: number): Promise<Survey> => {
    const { data } = await client.get<SurveyDTO, AxiosResponse<SurveyDTO>, number>(API_BASE_URL + `/surveys/${surveyId}`);
    return data;
};

export const surveyApi = {
    createSurvey,
    deleteSurvey,
    surveySendReminder,
    getSurveyAuthenticationToken,
    getUserSurvey,
    createSurveyAnswer,
    updateSurveyAnswer,
    getResults,
    getSurveys,
    getSurveyById,
    updateSurvey,
};
