import { API_BASE_URL, client } from '@/api/common';
import { LeaveType, LeaveTypeCreationMutation, LeaveTypeUpdateMutation } from '@/domain/leave-type/LeaveType.model';
import { AxiosResponse } from 'axios';
import { OrderMutation } from '@/domain/common';

export type LeaveTypeDTO = LeaveType;

const getLeaveTypeById = async (leaveTypeId: number): Promise<LeaveType> => {
    const url = API_BASE_URL + `/leave-types/${leaveTypeId}`;
    return (await client.get<LeaveTypeDTO>(url)).data;
};

const getLeaveTypes = async (): Promise<LeaveType[]> => {
    const url = API_BASE_URL + `/leave-types`;
    return (await client.get<LeaveTypeDTO[]>(url)).data;
};

export type LeaveTypeCreationRequestDTO = LeaveTypeCreationMutation;
export type LeaveTypeUpdateRequestDTO = LeaveTypeUpdateMutation;

const createNewLeaveType = async (mutation: LeaveTypeCreationMutation): Promise<LeaveType> => {
    const url = API_BASE_URL + `/leave-types`;
    return (await client.post<LeaveTypeDTO, AxiosResponse, LeaveTypeCreationRequestDTO>(url, mutation)).data;
};

const updateLeaveType = async (mutation: LeaveTypeUpdateMutation, leaveTypeId: number): Promise<LeaveType> => {
    const url = API_BASE_URL + `/leave-types/${leaveTypeId}`;
    return (await client.put<LeaveTypeDTO, AxiosResponse, LeaveTypeUpdateRequestDTO>(url, mutation)).data;
};

const updateLeavesTypeOrder = (mutation: OrderMutation[]): Promise<void> => {
    const url = API_BASE_URL + `/leave-types/order`;
    return client.put(url, mutation);
};

const deleteLeaveType = async (leaveTypeId: number): Promise<void> => {
    const url = API_BASE_URL + `/leave-types/${leaveTypeId}`;
    return client.delete(url);
};

export const leaveTypeApi = {
    getLeaveTypeById,
    getLeaveTypes,
    createNewLeaveType,
    updateLeaveType,
    updateLeavesTypeOrder,
    deleteLeaveType,
};
