import { RouteLeavingGuard } from '@/Components/route-leaving-guard/RouteLeavingGuard';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Folder, FolderType } from '@/domain/document/Document.model';
import { createFolder, deleteFolder, updateFolder } from '@/domain/document/Document.service';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { displayFormRouteLeavingGuard } from '@/Components/route-leaving-guard/RouteLeavingGuard.util';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { useGetFolderById } from '@/hooks/document/Document.hook';

const schema = yup.object().shape({
    folderName: yup.string().trim().required(),
    folderDescription: yup.string().trim(),
    folderType: yup
        .string()
        .required()
        .oneOf(['COMPANY', 'EMPLOYEE'] as FolderType[]),
    employeeAccess: yup.array().required(),
});

export const FolderSettingPage: FC = () => {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    const folderId = Number(params.folderId) || undefined;

    const { data: folder, isLoading, isError, error } = useGetFolderById(folderId);

    const { handleSubmit, control, formState } = useForm<Omit<Folder, 'order' | 'id'>>({
        resolver: yupResolver(schema),
        values: {
            folderName: folder?.folderName ?? '',
            folderDescription: folder?.folderDescription ?? '',
            folderType: folder?.folderType ?? 'COMPANY',
            employeeAccess: folder?.employeeAccess ?? [],
        },
    });

    const goToList = () => {
        navigate('/settings/documents/folders');
    };

    const handleSave = async (data: Omit<Folder, 'order' | 'id'>) => {
        if (!folderId) {
            try {
                await createFolder(data);
                showSnackbar(t('folder_settings_page.folder_created'), 'success');
                goToList();
            } catch (error) {
                handleError(error);
            }
        } else {
            try {
                await updateFolder(folderId, data);
                showSnackbar(t('folder_settings_page.folder_updated'), 'success');
                goToList();
            } catch (error) {
                handleError(error);
            }
        }
    };

    const handleDelete = async (folderId: number) => {
        try {
            await deleteFolder(folderId);
            showSnackbar(t('folder_settings_page.folder_deleted'), 'success');
            goToList();
        } catch {
            showSnackbar(t('folder_settings_page.folder_cant_be_deleted'), 'warning');
        }
    };

    const getFooterActions = (): FooterActionsProps['actions'] => {
        const footerActions: FooterActionsProps['actions'] = [];

        if (folderId) {
            footerActions.push({
                name: 'delete',
                children: t('general.delete'),
                variant: 'contained',
                color: 'error',
                onClick: () => handleDelete(folderId),
            });
        }
        footerActions.push({
            name: 'save',
            children: t(folderId ? 'general.update' : 'general.create'),
            variant: 'contained',
            onClick: () => {
                handleSubmit(handleSave, console.error)();
            },
        });
        return footerActions;
    };

    const footerActions = getFooterActions();

    return (
        <>
            <RouteLeavingGuard when={displayFormRouteLeavingGuard(formState)} />

            <ContentContainer>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    <Stack component={Paper} gap={2} flex={1} p={2}>
                        <Typography variant='body1bold'>{t('folder_settings_page.about_folder')}</Typography>
                        <Controller
                            name={'folderName'}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControlLabel
                                    label={t('folder_settings_page.table.folderName')}
                                    control={<TextField {...field} fullWidth error={!!error} helperText={error?.message} />}
                                />
                            )}
                        />

                        <Controller
                            name={'folderDescription'}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControlLabel
                                    label={t('folder_settings_page.table.folderDescription')}
                                    control={
                                        <TextField
                                            {...field}
                                            fullWidth
                                            InputProps={{ multiline: true, minRows: 4 }}
                                            error={!!error}
                                            helperText={error?.message}
                                        />
                                    }
                                />
                            )}
                        />
                        <Controller
                            name='folderType'
                            control={control}
                            render={({ field: { onChange, ...restField }, fieldState: { error } }) => (
                                <FormControlLabel
                                    label={t('folder_settings_page.table.folderType')}
                                    labelPlacement='top'
                                    disabled={!!folderId}
                                    control={
                                        <Autocomplete
                                            {...restField}
                                            fullWidth
                                            options={['COMPANY', 'EMPLOYEE']}
                                            onChange={(_, data) => onChange(data)}
                                            disableClearable
                                            getOptionLabel={option => t('folder_settings_page.folder_type', { context: option })}
                                            renderInput={params => <TextField {...params} error={!!error} helperText={error?.message} />}
                                        />
                                    }
                                />
                            )}
                        />
                    </Stack>
                </StateHandler>
            </ContentContainer>
            <Footer>
                <FooterActions actions={footerActions} />
            </Footer>
        </>
    );
};
