import { WordCloud } from '@/Components/word-cloud/WordCloud';
import { Label } from '@/domain/label/Label.model';
import { AnswerResult, SurveyResultFilterType } from '@/domain/survey/Survey.model';
import { renderGroupByWithFilters } from '@/domain/survey/Survey.service';
import { getLabelTranslation } from '@/utils/language.util';
import { Collapse, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Cancel01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextAnswers } from './TextAnswers';

type Props = {
    title: Label;
    answer: AnswerResult;
    groupBy: SurveyResultFilterType;
    activeGroupByFilters: string[];
    displayMessage?: boolean;
};

const useStyles = makeStyles(theme => ({
    instruction: {
        marginBottom: theme.spacing(2),
    },
    commentTitle: {
        marginBottom: theme.spacing(1),
        display: 'block',
    },
    cloudResultWords: {
        textAlign: 'center',
    },
    closeActiveWord: {
        color: 'red',
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1.5),
        width: 15,
        cursor: 'pointer',
    },
    commentsContainer: {
        maxHeight: 300,
        overflow: 'auto',
    },
    alightTextRight: {
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
        },
    },
    activeWord: {
        textTransform: 'capitalize',
    },
}));

export const TextResults: FC<Props> = ({ title, activeGroupByFilters, groupBy, answer, displayMessage = true }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectedWord, setSelectedWord] = useState<{ value: string; count: number }>();

    const removeSelectedWord = () => {
        setSelectedWord(undefined);
    };

    if (!answer?.details || !answer?.completions) {
        return <></>;
    }

    return (
        <Paper elevation={1} sx={{ p: 3 }}>
            <Grid container direction={'row'} justifyContent={'space-between'}>
                <Grid item sm={12} md={5} lg={5}>
                    <Typography variant='body1bold' className={classes.commentTitle}>
                        {getLabelTranslation(title)}
                    </Typography>
                </Grid>
                <Grid item sm={12} md={3} lg={2}>
                    <Typography variant='body2bold'>
                        {selectedWord && (
                            <>
                                <span className={classes.activeWord}>{selectedWord.value}</span> - {selectedWord.count} {t('survey_results.times')}
                                <Cancel01Icon className={classes.closeActiveWord} onClick={() => removeSelectedWord()} />
                            </>
                        )}
                    </Typography>
                </Grid>
                <Grid item sm={12} md={4} lg={5} className={classes.alightTextRight}>
                    <Typography variant='body1'>
                        {answer.completions?.ALL[0].answered} {t('survey_results.answered')} - {answer.completions?.ALL[0].skipped}{' '}
                        {t('survey_results.skipped')}
                    </Typography>
                </Grid>
            </Grid>
            <Collapse in={displayMessage}>
                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item sm={12} md={6} lg={4}>
                        <WordCloud
                            data={answer.details.ALL.flatMap(a => a.wordCounts).filter((w, index, array) => array.indexOf(w) === index)}
                            onWordSelected={word => {
                                setSelectedWord(word);
                            }}
                            selectedWord={selectedWord}
                        />
                    </Grid>
                    <Grid item sm={1} />
                    <Grid item sm={12} md={5} lg={7} className={classes.commentsContainer}>
                        {answer.details[groupBy] &&
                            renderGroupByWithFilters(activeGroupByFilters, answer.details[groupBy]).map(details => (
                                <TextAnswers key={details.key} selectedWord={selectedWord} details={details} />
                            ))}
                    </Grid>
                </Grid>
            </Collapse>
        </Paper>
    );
};
