import { PageContainer } from '@/routes/PageContainer';
import { RoutesConfig } from '@/routes/Routes.type';
import { SurveysHeader } from '@/page/survey/SurveysHeader';
import { SurveyResultsPage } from '@/page/survey/SurveyResultsPage';
import { SurveyTemplateDetailPage } from '@/page/survey/SurveyTemplateDetailPage';
import { SurveyTemplatesPage } from '@/page/survey/SurveyTemplatesPage';
import { SurveySetupFormPage } from '@/page/survey/survey-setup/SurveySetupFormPage';
import { SurveysPage } from '@/page/survey/SurveysPage';
import { Stack } from '@mui/material';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { RouterBoundary } from '@/routes/RouterBoundary';

const config: RoutesConfig[] = [
    {
        path: 'templates',
        breadCrumb: [{ nameKey: 'pages.survey_templates' }],
        component: <SurveyTemplatesPage />,
    },

    {
        path: 'surveys',
        breadCrumb: [{ nameKey: 'pages.surveys' }],
        component: <SurveysPage />,
    },
];

const subRoutes: RoutesConfig[] = [
    {
        path: 'surveys/new',
        breadCrumb: [{ nameKey: 'pages.new_survey' }],
        component: <SurveySetupFormPage />,
    },
    {
        path: 'surveys/:surveyId/results',
        breadCrumb: [{ nameKey: 'pages.surveys_results' }],
        component: <SurveyResultsPage />,
    },
    {
        path: 'templates/:surveyId',
        breadCrumb: [{ nameKey: 'pages.survey_templates' }],
        component: <SurveyTemplateDetailPage />,
    },
];

export const surveyRoutes: RouteObject[] = [
    {
        path: 'manage-surveys',
        children: [
            {
                path: '',
                element: (
                    <Stack flex='1' gap={2}>
                        <SurveysHeader />
                        <Outlet />
                    </Stack>
                ),
                errorElement: <RouterBoundary />,

                children: [
                    {
                        path: '',
                        element: <Navigate to='/manage-surveys/surveys' replace />,
                    },
                    ...config.map(config => ({
                        path: config.path,
                        element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                        errorElement: <RouterBoundary />,
                    })),
                ],
            },
            ...subRoutes.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
