import {
    categoryFormType,
    EmployeeReviewFeedbackSkillFormType,
    EmployeeReviewFeedbackSkillsFormType,
} from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import {
    EmployeeReviewFeedbackSummarySkillFormType,
    EmployeeReviewSummaryFeedbackSkillsFormType,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { SkillState } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSkill';
import { GroupedSkillsMap } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSkills';

export const getGroupedSkillsByCategoryId = <
    T extends {
        category: categoryFormType;
    },
>(
    skills: T[] | undefined,
): GroupedSkillsMap<T> => {
    return (skills ?? []).reduce(
        (map, skill) => {
            const categoryId = skill.category.id;
            const categoryName = skill.category.name;

            const existingCategory = map.get(categoryId);
            if (existingCategory) {
                existingCategory.groupedSkills.push(skill);
            } else {
                map.set(categoryId, {
                    categoryName: categoryName ?? '',
                    groupedSkills: [skill],
                });
            }
            return map;
        },
        new Map<
            number,
            {
                categoryName: string;
                groupedSkills: T[];
            }
        >(),
    );
};
export const getSkillState = (
    employeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSkillFormType | EmployeeReviewFeedbackSummarySkillFormType,
    skillsItem: EmployeeReviewFeedbackSkillsFormType | EmployeeReviewSummaryFeedbackSkillsFormType,
): SkillState => {
    const { maxSkills, allSkillsRequired } = skillsItem;

    if (employeeReviewFeedbackSkillForm.score && employeeReviewFeedbackSkillForm.score > 0) {
        return 'reviewed';
    }

    const skillsAlreadyReviewed = skillsItem.skills.filter(skill => skill.score && skill.score > 0).length;
    const disabled = !!maxSkills && skillsAlreadyReviewed >= maxSkills && !allSkillsRequired;
    if (disabled) {
        return 'disabled';
    }
    return 'not_reviewed';
};
