import { BasicMenu } from '@/Components/basic-menu/BasicMenu';
import { Chip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getReviewReportView } from '@/domain/report/Report.service';

export const ReviewReportMenu: FC = () => {
    const { t } = useTranslation();

    const [searchParams, setSetSearchParams] = useSearchParams();

    const currentView = getReviewReportView(searchParams.get('view'));

    const views = [
        {
            title: t('reviews.report.matrix_view'),
            selected: currentView === 'MATRIX',
            onClick: () => setSetSearchParams({ view: 'MATRIX' }),
        },
        {
            title: t('reviews.report.question_view'),
            selected: currentView === 'QUESTION',
            onClick: () => setSetSearchParams({ view: 'QUESTION' }),
        },
    ];

    const selectedViewItem = views.find(it => it.selected);
    return (
        <BasicMenu
            button={<Chip label={selectedViewItem?.title ?? ''} />}
            items={views}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        />
    );
};
