import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { handleError } from '@/utils/api.util';
import { Survey } from '@/domain/survey/Survey.model';
import { getSurveyById } from '@/domain/survey/Survey.service';

export const useGetSurvey = (surveyId: number): UseQueryResult<Survey> => {
    const [survey, setSurvey] = useState<Survey>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const getSurvey = useCallback(async () => {
        try {
            const survey = await getSurveyById(surveyId);
            setSurvey(survey);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [surveyId]);

    useEffect(() => {
        getSurvey().catch(handleError);
    }, [getSurvey]);

    return {
        data: survey,
        setData: setSurvey,
        isLoading,
        isError: !!error,
        error,
        refetch: () => getSurvey(),
    };
};
