import { EmployeeAvatarWithDetails } from '@/Components/employee-avatar/EmployeeAvatarWithDetails';
import { EditableSectionFieldComponent } from '@/Components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { getFieldValueProperty } from '@/Components/section/SectionFieldComponent/SectionField.util';
import { SectionFieldComponent } from '@/Components/section/SectionFieldComponent/SectionFieldComponent';
import { SectionField } from '@/Components/section/types';
import { DialogContainer, DialogContainerProps } from '@/Components/dialog-container/DialogContainer';
import { EmployeeProfileChangeRow } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { approvePendingChanges, cancelPendingChanges, convertPendingFieldToSectionField } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { EmployeeSection } from '@/domain/employee-section/EmployeeSection.model';
import { EmployeeAvatar as EmployeeAvatarType, EmployeeFieldType, Gender, MaritalStatus } from '@/domain/employee/Employee.model';
import {
    convertEmployeeSectionFieldsToSectionFields,
    getEmployeePersonalInfo,
    updateEmployeeAddressPendingRequest,
    updateEmployeePersonalInfoPendingRequest,
} from '@/domain/employee/Employee.service';
import { EmployeeAddressUpdateMutation } from '@/domain/employee/EmployeeAddress.model';
import { EmployeePersonalInfo, EmployeePersonalInfoMutation } from '@/domain/employee/EmployeePersonalInfo.model';
import { SectionDefinition, SectionFieldType, SectionType } from '@/domain/section-setting/Section.model';
import { useAddressColumns } from '@/page/employee-profile/employee-profile-info/EmployeeAddressSection/EmployeeAdressSection.hook';
import { convertFormValuesToFields } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSection/EmployeeCustomSection.util';
import {
    CustomSectionRowFormValues,
    useCustomSectionRowSchema,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeCustomSectionRowDialog.hook';
import { useEmployeePersonalInfoSectionFields } from '@/page/employee-profile/employee-profile-info/EmployeePersonalInfoSection/EmployeePersonalInfoSection.hook';
import { useGetEmployeeSection } from '@/hooks/employee/EmployeeSection.hook';
import { handleError } from '@/utils/api.util';
import { Country, getCountry } from '@/utils/countries.util';
import { formatToLocalDate, isValidDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Stack, StackProps, Typography } from '@mui/material';
import i18next from 'i18next';
import { FC, useEffect, useState } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
    getPersonalInfoSectionSchema,
    PersonalInfoSectionFormValues,
} from '@/page/employee-profile/employee-profile-info/EmployeePersonalInfoSection/EmployeePersonalInfoSection.schema';
import { ArrowRight01Icon } from 'hugeicons-react';
import { updateEmployeeSectionRowPendingRequest } from '@/domain/employee-section/EmployeeSection.service';

type EmployeeProfilePendingRequestDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    employee: EmployeeAvatarType;
    employeeSection: EmployeeSection;
    pendingRow: EmployeeProfileChangeRow;
    onSuccess: () => void;
};

/**
 * Displays a diff between the previous and the next value
 * Previous (the current value) is load by the dialog base on the section definition type
 */
export const EmployeeProfilePendingRequestDialog: FC<EmployeeProfilePendingRequestDialogProps> = ({
    open,
    onClose,
    onSuccess,
    employee,
    employeeSection,
    pendingRow,
    ...rest
}) => {
    const { t } = useTranslation();
    const sectionDefinition = employeeSection.sectionDefinition;

    const { schema } = useCustomSectionRowSchema({ sectionDefinition });

    type PendingRequestFormValues = CustomSectionRowFormValues | EmployeeAddressFormValues | PersonalInfoSectionFormValues;

    const getResolver = (): Resolver<PendingRequestFormValues> => {
        switch (employeeSection.sectionDefinition.type) {
            case SectionType.PERSONAL_INFO:
                return yupResolver<PendingRequestFormValues>(getPersonalInfoSectionSchema());
            case SectionType.ADDRESS:
                return yupResolver<PendingRequestFormValues>(employeeAddressSectionSchema);
            default:
                return yupResolver<PendingRequestFormValues>(schema);
        }
    };

    const addressCountry = pendingRow.fields.find(f => f.sectionFieldDefinition.fieldType === 'ADDRESS_COUNTRY')?.stringValue;

    const formMethods = useForm<CustomSectionRowFormValues | EmployeeAddressFormValues | PersonalInfoSectionFormValues>({
        resolver: getResolver(),
        // Workaround to set the default values for the address section
        defaultValues: addressCountry
            ? {
                  ADDRESS_COUNTRY: getCountry(addressCountry),
              }
            : undefined,
    });

    const {
        formState: { isDirty },
        handleSubmit,
    } = formMethods;

    const isTableSection = [SectionType.ADDRESS, SectionType.CUSTOM_MULTI_ROW, SectionType.WORK_PATTERN, SectionType.EMPLOYMENT].includes(
        sectionDefinition.type,
    );

    const getPendingId = () => {
        // For personal info we need to use the employee id
        if (sectionDefinition.type === 'PERSONAL_INFO') {
            return employee.id;
        }
        // For the rest of the sections we need to use the pending row id
        return pendingRow.id;
    };

    const handleConfirmClick = async (formValues: Record<string, unknown>) => {
        const updatePersonalInfo = async (formValues: PersonalInfoSectionFormValues) => {
            const personalInfo: EmployeePersonalInfoMutation = {
                birthdate: formValues.birthdate ?? undefined,
                gender: formValues.gender as Gender,
                maritalStatus: formValues.maritalStatus as MaritalStatus,
                maritalStatusSince: formValues.maritalStatusSince ?? undefined,
                nationality: formValues.nationality?.value,
            };

            await updateEmployeePersonalInfoPendingRequest(employee.id, personalInfo);
        };

        const updateAddress = async (formValues: EmployeeAddressFormValues) => {
            const address: EmployeeAddressUpdateMutation = {
                city: formValues.ADDRESS_CITY,
                postCode: formValues.ADDRESS_POST_CODE,
                country: formValues.ADDRESS_COUNTRY.value,
                addressLine1: formValues.ADDRESS_ADDRESS_LINE_1,
                addressLine2: formValues.ADDRESS_ADDRESS_LINE_2,
                startDate: formatToLocalDate(formValues.ADDRESS_START_DATE),
            };

            await updateEmployeeAddressPendingRequest(getPendingId(), address);
        };

        const updateSectionRow = async (formValues: CustomSectionRowFormValues) => {
            const row = {
                fields: convertFormValuesToFields(formValues, sectionDefinition),
                order: 0,
            };
            await updateEmployeeSectionRowPendingRequest(employeeSection.id, pendingRow.id, row);
        };

        const updatePendingRow = async (formValues: Record<string, unknown>) => {
            if (sectionDefinition.type === 'PERSONAL_INFO') {
                await updatePersonalInfo(formValues as PersonalInfoSectionFormValues);
            } else if (sectionDefinition.type === 'ADDRESS') {
                await updateAddress(formValues as EmployeeAddressFormValues);
            } else {
                await updateSectionRow(formValues as CustomSectionRowFormValues);
            }
        };

        try {
            // if the pending row is updated we have to edit the pending row before approving it
            if (isDirty) {
                await updatePendingRow(formValues);
            }

            await approvePendingChanges(getPendingId(), employeeSection);
            onSuccess();
        } catch (e) {
            handleError(e);
        }
    };

    const handleCancelClick = async () => {
        try {
            await cancelPendingChanges(getPendingId(), employeeSection);
            onSuccess();
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <FormProvider {...formMethods}>
            <DialogContainer
                maxWidth={'md'}
                title={t('manage_people_pending_request_page.title')}
                open={open}
                onClose={onClose}
                onSave={handleSubmit(handleConfirmClick, console.error)}
                onCancel={handleCancelClick}
                saveButtonText={t('manage_people_pending_request_page.approve')}
                cancelButtonText={t('manage_people_pending_request_page.cancel')}
                {...rest}
            >
                <Stack gap={3}>
                    <EmployeeAvatarWithDetails employee={employee} />

                    {/* pending fields from table section are not compatible with the diff */}
                    {isTableSection ? (
                        <PendingFieldsStack pendingRow={pendingRow} type={sectionDefinition.type} />
                    ) : (
                        <DiffPendingFieldsStack pendingRow={pendingRow} employeeId={employee.id} sectionDefinition={sectionDefinition} />
                    )}
                </Stack>
            </DialogContainer>
        </FormProvider>
    );
};

const PendingFieldsStack: FC<{ pendingRow: EmployeeProfileChangeRow; type: SectionType }> = ({ pendingRow, type }) => {
    const addressColumns = useAddressColumns();
    const pendingFields: (SectionField & { formValueName: string })[] = pendingRow.fields.map(f => {
        let title: string;
        let formValueName: string;

        if (type === 'ADDRESS') {
            const column = addressColumns.find(c => c.fieldType === f.sectionFieldDefinition.fieldType);
            title = column?.title ?? '';
            formValueName = f.sectionFieldDefinition.fieldType;
        } else {
            title = getLabelTranslation(f.sectionFieldDefinition?.name);
            formValueName = f.sectionFieldDefinition?.id.toString();
        }

        return {
            ...convertPendingFieldToSectionField(f),
            formValueName,
            title,
        };
    });

    return (
        <Stack gap={2}>
            {pendingFields.map(pendingField => (
                <FormControlLabel
                    key={pendingField.formValueName}
                    componentsProps={{ typography: { variant: 'body2bold' } }}
                    label={pendingField.title}
                    sx={{ flex: 1, alignItems: 'stretch' }}
                    htmlFor={pendingField.formValueName}
                    control={<EditableSectionFieldComponent field={pendingField} />}
                />
            ))}
        </Stack>
    );
};

const DiffPendingFieldsStack: FC<{
    pendingRow: EmployeeProfileChangeRow;
    sectionDefinition: SectionDefinition;
    employeeId: number;
}> = ({ pendingRow, employeeId, sectionDefinition }) => {
    const previousFields = useGetPreviousFields(employeeId, sectionDefinition);

    const hasPreviousFieldsLoad = !!previousFields.length;

    const getPendingFields = () => {
        return pendingRow.fields?.map<SectionField & { formValueName: string }>(pendingField => {
            const sectionFieldWithoutFormValueName = convertPendingFieldToSectionField(pendingField);

            const previous = previousFields?.find(
                p =>
                    p.fieldType === pendingField.sectionFieldDefinition.fieldType ||
                    // TODO workaround to get the previous field when the field is a custom section field
                    (pendingField.sectionFieldDefinition.fieldType === ('CUSTOM_SECTION_FIELD' as EmployeeFieldType) &&
                        p.fieldType === 'EMPLOYEE_CUSTOM_FIELD' &&
                        p.formValueName === pendingField.sectionFieldDefinition?.id.toString()),
            );

            if (!previous) {
                throw new Error('Previous field not found');
            }
            const formValueName = pendingField.sectionFieldDefinition?.id?.toString() ?? previous.formValueName;
            const title = pendingField.sectionFieldDefinition?.id ? getLabelTranslation(pendingField.sectionFieldDefinition.name) : previous.title;
            // TODO workaround to get the enum list when the field is an enum list item
            const enumList = sectionFieldWithoutFormValueName.type === SectionFieldType.ENUM_LIST_ITEM ? previous.enumList : undefined;

            return {
                ...sectionFieldWithoutFormValueName,
                formValueName,
                title,
                enumList,
            };
        });
    };

    const pendingFields: (SectionField & { formValueName: string })[] = hasPreviousFieldsLoad ? getPendingFields() : [];

    return (
        <Stack gap={2}>
            {pendingFields.map(pendingField => (
                <FormControlLabel
                    key={pendingField.formValueName}
                    componentsProps={{ typography: { variant: 'body2bold' } }}
                    label={pendingField.title}
                    sx={{ flex: 1, alignItems: 'stretch' }}
                    htmlFor={pendingField.formValueName}
                    control={
                        <DiffField
                            next={pendingField}
                            previous={previousFields?.find(p => p.formValueName === pendingField.formValueName)}
                            direction='row'
                            gap={2}
                            alignItems='center'
                        />
                    }
                />
            ))}
        </Stack>
    );
};

const UNkNOWN_VALUE_SYMBOL = '-';
const DiffField: FC<
    {
        previous: SectionField | undefined;
        next: SectionField & { formValueName: string };
    } & StackProps
> = ({ previous, next, ...rest }) => {
    return (
        <Stack {...rest}>
            <Stack flex={1}>
                {previous?.[getFieldValueProperty(previous.type)] ? (
                    <SectionFieldComponent field={previous} />
                ) : (
                    <Typography variant='body2bold'>{UNkNOWN_VALUE_SYMBOL}</Typography>
                )}
            </Stack>
            <ArrowRight01Icon />
            <Stack flex={1}>
                {next?.formValueName ? (
                    <EditableSectionFieldComponent
                        field={{
                            ...next,
                        }}
                    />
                ) : (
                    <Typography variant='body2bold'>{UNkNOWN_VALUE_SYMBOL}</Typography>
                )}
            </Stack>
        </Stack>
    );
};

/**
 * Hook to keep the dialog generic
 */
const useGetPreviousFields = (employeeId: number, sectionDefinition: SectionDefinition): SectionField[] => {
    const [personalInfo, setPersonalInfo] = useState<EmployeePersonalInfo>();
    const type = sectionDefinition.type;

    useEffect(() => {
        if (type === SectionType.PERSONAL_INFO) {
            // We need to call the API to get the current employee personal info
            getEmployeePersonalInfo(employeeId).then(data => {
                setPersonalInfo(data);
            });
        }
    }, [employeeId, type]);

    // TODO disable load if not needed
    const { data: employeeSections } = useGetEmployeeSection({ employeeId });

    const previousEmployeePersonalInfoFields = useEmployeePersonalInfoSectionFields(personalInfo);

    if (type === SectionType.PERSONAL_INFO) {
        return previousEmployeePersonalInfoFields;
    }

    if (type === SectionType.CUSTOM_SINGLE_ROW) {
        const employeeSection = employeeSections?.find(s => s.sectionDefinition.id === sectionDefinition.id);
        return employeeSection
            ? convertEmployeeSectionFieldsToSectionFields(employeeSection.id, sectionDefinition?.fields, employeeSection?.rows[0]?.fields)
            : [];
    }

    return [];
};

type EmployeeAddressFormValues = {
    ADDRESS_ADDRESS_LINE_1: string;
    ADDRESS_ADDRESS_LINE_2?: string;
    ADDRESS_POST_CODE: string;
    ADDRESS_CITY: string;
    ADDRESS_REGION?: string;
    ADDRESS_COUNTRY: Country;
    ADDRESS_START_DATE: Date;
};

const employeeAddressSectionSchema: yup.ObjectSchema<EmployeeAddressFormValues> = yup.object().shape({
    ADDRESS_ADDRESS_LINE_1: yup.string().required(),
    ADDRESS_ADDRESS_LINE_2: yup.string(),
    ADDRESS_POST_CODE: yup.string().required(),
    ADDRESS_CITY: yup.string().required(),
    ADDRESS_REGION: yup.string(),
    ADDRESS_COUNTRY: yup.object().default(undefined).required().shape({
        label: yup.string().required(),
        value: yup.string().required(),
    }),
    ADDRESS_START_DATE: yup
        .date()
        .test({
            message: i18next.t('general.validations.valid_date'),
            test: isValidDate,
        })
        .required(),
});
