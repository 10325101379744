import { useCallback, useEffect, useState } from 'react';
import { UseMutationResult, UseQueryResult } from '@/page/Query.type';
import { Announcement, AnnouncementSearch, CreateAnnouncementMutation, EditAnnouncementMutation } from '@/domain/announcement/Announcement.model';
import {
    createAnnouncement,
    deleteAnnouncementImage,
    editAnnouncement,
    editAnnouncementImage,
    getAnnouncements,
    getAnnouncementsAsEditor,
} from '@/domain/announcement/Announcement.service';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleError } from '@/utils/api.util';

export const useGetAnnouncements = (canSeeAnnouncements: boolean, search?: AnnouncementSearch): UseQueryResult<Announcement[]> => {
    const [announcements, setAnnouncements] = useState<Announcement[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<unknown>();

    const fetchAnnouncements = useCallback(async () => {
        try {
            if (canSeeAnnouncements) {
                const response = await getAnnouncements(search);
                setAnnouncements(response);
            } else {
                setAnnouncements([]);
            }
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, [search, canSeeAnnouncements]);

    useEffect(() => {
        fetchAnnouncements().catch(handleError);
    }, [fetchAnnouncements]);

    return { data: announcements, setData: setAnnouncements, isLoading, error, isError: !!error, refetch: fetchAnnouncements };
};

export const useGetAnnouncementsAsEditor = (canManageAnnouncements: boolean, search: AnnouncementSearch = {}): UseQueryResult<Announcement[]> => {
    const [announcements, setAnnouncements] = useState<Announcement[]>();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<unknown>();

    const fetchAnnouncementsAsEditor = useCallback(async (canManageAnnouncements: boolean, search: AnnouncementSearch = {}) => {
        try {
            if (canManageAnnouncements) {
                const response = await getAnnouncementsAsEditor(search);
                setAnnouncements(response);
            } else {
                setAnnouncements([]);
            }
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useDeepCompareEffect(() => {
        fetchAnnouncementsAsEditor(canManageAnnouncements, search).catch(handleError);
    }, [fetchAnnouncementsAsEditor, search, canManageAnnouncements]);

    return {
        data: announcements,
        setData: setAnnouncements,
        isLoading,
        error,
        isError: !!error,
        refetch: () => fetchAnnouncementsAsEditor(canManageAnnouncements, search),
    };
};

export const useCreateAnnouncement = (): UseMutationResult<Announcement, CreateAnnouncementMutation> => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const mutate = useCallback(async (mutationVariables: CreateAnnouncementMutation) => {
        setIsPending(true);
        const { image } = mutationVariables;
        try {
            const newAnnouncement = await createAnnouncement(mutationVariables);
            if (image?.key) {
                await editAnnouncementImage(newAnnouncement.id, image?.key);
            }
            return newAnnouncement;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setIsPending(false);
        }
    }, []);

    return {
        mutate,
        isPending,
        isError: !!error,
        error,
    };
};

export const useEditAnnouncement = (): UseMutationResult<Announcement, EditAnnouncementMutation> => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const mutate = useCallback(async (mutationVariables: EditAnnouncementMutation) => {
        const { id: announcementId, image } = mutationVariables;
        setIsPending(true);
        try {
            const updatedAnnouncement = await editAnnouncement(mutationVariables);
            if (image?.key) {
                const { imageUrl: newImageUrl } = await editAnnouncementImage(announcementId, image.key);
                updatedAnnouncement.imageUrl = newImageUrl;
            }
            // delete image if announcement had image and hasn't after edit
            if (!image && updatedAnnouncement.imageUrl) {
                await deleteAnnouncementImage(announcementId);
                updatedAnnouncement.imageUrl = undefined;
            }
            return updatedAnnouncement;
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setIsPending(false);
        }
    }, []);

    return {
        mutate,
        isPending,
        isError: !!error,
        error,
    };
};
