import { getContractTypeTranslationKey, getUserEmploymentStatusTranslationKey } from '@/domain/employee/Employee.service';
import { ContractType, EmploymentStatus } from '@/domain/employment/Employment.model';
import { getLocations } from '@/domain/location/Location.service';
import {
    FilteringCondition,
    FilteringConditionValue,
    PermissionFilteringConditionRule,
    PermissionFilteringConditionType,
} from '@/domain/permission/Permission.model';
import { getPermissionFilteringConditionDisplay } from '@/domain/permission/Permission.service';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation, useUserLanguage } from '@/utils/language.util';
import { Checkbox, FormControlLabel, IconButton, MenuItem, Select, Stack } from '@mui/material';
import { Delete02Icon } from 'hugeicons-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';

type ConditionPanelProps = {
    condition: FilteringCondition;
    availableRules: PermissionFilteringConditionRule[];
    // TODO: wrong output type used here, should be FilteringConditionRequest (without full object in values, like location, position etc..)
    //Formatted like this because we need PermissionSettingForm page needs show the information that is in the FilteringCondition object (names)
    onChange: (newCondition: FilteringCondition) => void;
    onDeleted: () => void;
    availableConditionType: string[];
    isDeleteConditionDisabled: boolean;
};

const mapConditionValues = (condition: FilteringCondition): string[] | number[] => {
    switch (condition.conditionType) {
        case PermissionFilteringConditionType.LOCATION:
            return condition.filterConditionValues.map(value => value.location?.id).filter(Boolean) as number[];
        case PermissionFilteringConditionType.DEPARTMENT:
            return condition.filterConditionValues.map(value => value.department?.id).filter(Boolean) as number[];
        case PermissionFilteringConditionType.JOB:
            return condition.filterConditionValues.map(value => value.job?.id).filter(Boolean) as number[];
        case PermissionFilteringConditionType.EMPLOYMENT_STATUS:
            return condition.filterConditionValues.map(value => value.employmentStatus).filter(Boolean) as string[];
        case PermissionFilteringConditionType.CONTRACT_TYPE:
            return condition.filterConditionValues.map(value => value.contractType).filter(Boolean) as string[];
        default:
            return [];
    }
};

const onChangeFilteringCondition = (
    possibleValues: ConditionValue[],
    selectedValues: string[] | number[],
    condition: FilteringCondition,
): FilteringCondition => {
    const conditionValues: FilteringConditionValue[] = selectedValues.map(selectedKey => {
        const value = possibleValues.find(value => value?.key === selectedKey) as ConditionValue;
        switch (condition.conditionType) {
            case PermissionFilteringConditionType.LOCATION:
                return {
                    id: 0,
                    location: {
                        id: value?.key,
                        name: value.displayName,
                        street: '',
                        zipCode: '',
                        city: '',
                        openingTime: '',
                        closingTime: '',
                    },
                } as FilteringConditionValue;
            case PermissionFilteringConditionType.DEPARTMENT:
                return {
                    id: 0,
                    department: {
                        id: value?.key,
                        name: {
                            translationEn: value.displayName,
                            translationFr: value.displayName,
                            translationDe: value.displayName,
                            translationIt: value.displayName,
                        },
                    },
                } as FilteringConditionValue;
            case PermissionFilteringConditionType.JOB:
                return {
                    id: 0,
                    job: {
                        id: value?.key,
                        name: {
                            translationEn: value.displayName,
                            translationFr: value.displayName,
                            translationDe: value.displayName,
                            translationIt: value.displayName,
                        },
                    },
                } as FilteringConditionValue;
            case PermissionFilteringConditionType.EMPLOYMENT_STATUS:
                return {
                    id: 0,
                    employmentStatus: value.key as EmploymentStatus,
                } as FilteringConditionValue;
            case PermissionFilteringConditionType.CONTRACT_TYPE:
                return {
                    id: 0,
                    contractType: value.key as ContractType,
                } as FilteringConditionValue;
        }
    }) as FilteringConditionValue[];
    return {
        ...condition,
        filterConditionValues: conditionValues,
    };
};

const isFilteringConditionValueChecked = (condition: FilteringCondition, possibleValue: ConditionValue): boolean => {
    return condition.filterConditionValues.some(value => conditionValueExists(value, condition.conditionType, possibleValue.key));
};

const conditionValueExists = (value: FilteringConditionValue, conditionType: PermissionFilteringConditionType | undefined, key: string | number) => {
    switch (conditionType) {
        case PermissionFilteringConditionType.LOCATION:
            return value.location?.id === key;
        case PermissionFilteringConditionType.DEPARTMENT:
            return value.department?.id === key;
        case PermissionFilteringConditionType.JOB:
            return value.job?.id === key;
        case PermissionFilteringConditionType.EMPLOYMENT_STATUS:
            return value.employmentStatus === key;
        case PermissionFilteringConditionType.CONTRACT_TYPE:
            return value.contractType === key;
        default:
            return false;
    }
};

const isConditionContractType = (condition: FilteringCondition) => {
    return condition.conditionType === PermissionFilteringConditionType.CONTRACT_TYPE;
};

const isConditionEmploymentStatus = (condition: FilteringCondition) => {
    return condition.conditionType === PermissionFilteringConditionType.EMPLOYMENT_STATUS;
};

type ConditionValue = {
    key: string | number;
    displayName: string;
};
export const ConditionPanel: FC<ConditionPanelProps> = ({
    availableConditionType,
    condition,
    onChange,
    onDeleted,
    availableRules,
    isDeleteConditionDisabled,
}) => {
    const { t } = useTranslation();
    const userLanguage = useUserLanguage();
    const [possibleValues, setPossibleValues] = useState<ConditionValue[]>();

    useEffect(() => {
        if (condition.conditionType) {
            switch (condition.conditionType) {
                case PermissionFilteringConditionType.LOCATION:
                    getLocations()
                        .then(response => {
                            const locations = response?.map(location => {
                                return {
                                    key: location?.id,
                                    displayName: location.name,
                                };
                            });
                            setPossibleValues(locations);
                        })
                        .catch(error => {
                            handleError(error);
                        });
                    break;
                case PermissionFilteringConditionType.DEPARTMENT:
                    getDepartments()
                        .then(data => {
                            const departments = data?.map(department => {
                                return {
                                    key: department?.id,
                                    displayName: getLabelTranslation(department.name),
                                };
                            });
                            setPossibleValues(departments);
                        })
                        .catch(error => {
                            handleError(error);
                        });
                    break;
                case PermissionFilteringConditionType.JOB:
                    getJobs()
                        .then(data => {
                            const jobs = data?.map(job => {
                                return {
                                    key: job?.id,
                                    displayName: getLabelTranslation(job.name),
                                };
                            });
                            setPossibleValues(jobs);
                        })
                        .catch(error => {
                            handleError(error);
                        });
                    break;
                case PermissionFilteringConditionType.EMPLOYMENT_STATUS: {
                    const employmentStatus = Object.values(EmploymentStatus).map(status => {
                        return {
                            key: status as string,
                            displayName: t(getUserEmploymentStatusTranslationKey(), { context: status }),
                        };
                    });
                    setPossibleValues(employmentStatus);
                    break;
                }
                case PermissionFilteringConditionType.CONTRACT_TYPE: {
                    const contractType = Object.values(ContractType).map(status => {
                        return {
                            key: status as string,
                            displayName: t(getContractTypeTranslationKey(), { context: status }),
                        };
                    });
                    setPossibleValues(contractType);
                    break;
                }
            }
        }
    }, [t, userLanguage, condition]);

    if (possibleValues === undefined) {
        return <></>;
    }

    return (
        <Stack direction='row' justifyContent='flex-start' alignItems='flex-end' spacing={2} width={'100%'}>
            <IconButton
                disabled={isDeleteConditionDisabled}
                onClick={() => onDeleted()}
                sx={{ color: isDeleteConditionDisabled ? 'text.disabled' : 'text.primary' }}
            >
                <Delete02Icon />
            </IconButton>
            <FormControlLabel
                sx={{ width: '100%', flex: 1 }}
                label={t('group_condition_dialog.reference_field')}
                control={
                    <Select
                        sx={{ flex: 1 }}
                        fullWidth
                        value={condition.conditionType}
                        onChange={event => {
                            const selected = event.target.value as PermissionFilteringConditionType;
                            const newCondition: FilteringCondition = {
                                ...condition,
                                filterConditionValues: [],
                                conditionType: selected,
                            };
                            onChange(newCondition);
                        }}
                        inputProps={{ 'aria-label': t('group_condition_dialog.reference_field') }}
                    >
                        {[condition.conditionType, ...availableConditionType]
                            .map(k => k as PermissionFilteringConditionType)
                            .map(type => {
                                return (
                                    <MenuItem key={type} value={type} disabled={false} sx={{ width: '100%', flex: 1 }}>
                                        {t('domain.employee_field_type', {
                                            context: type,
                                        })}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                }
            />
            <FormControlLabel
                sx={{ width: '100%', flex: 1 }}
                label={t('group_condition_dialog.rule')}
                control={
                    <Select
                        sx={{ flex: 1 }}
                        fullWidth
                        value={condition.conditionRule}
                        onChange={event => {
                            const selectedRule = event.target.value as PermissionFilteringConditionRule;
                            const newCondition: FilteringCondition =
                                selectedRule === PermissionFilteringConditionRule.SAME_AS_VIEWER
                                    ? {
                                          ...condition,
                                          filterConditionValues: [],
                                          conditionRule: selectedRule,
                                      }
                                    : {
                                          ...condition,
                                          conditionRule: selectedRule,
                                      };

                            onChange(newCondition);
                        }}
                    >
                        {availableRules
                            .filter(
                                rule =>
                                    !(
                                        rule === PermissionFilteringConditionRule.SAME_AS_VIEWER &&
                                        (isConditionContractType(condition) || isConditionEmploymentStatus(condition))
                                    ),
                            )
                            .map(rule => (
                                <MenuItem value={rule} key={rule} sx={{ width: '100%', flex: 1 }}>
                                    {t('domain.condition_rule_type', { context: getPermissionFilteringConditionDisplay(rule, condition.conditionType) })}
                                </MenuItem>
                            ))}
                    </Select>
                }
            />
            {(condition.conditionRule === PermissionFilteringConditionRule.EQUALS ||
                condition.conditionRule === PermissionFilteringConditionRule.NOT_EQUALS) && (
                <FormControlLabel
                    sx={{ width: '100%', flex: 2, overflow: 'auto' }}
                    label={t('group_condition_dialog.value')}
                    control={
                        <Select
                            sx={{ flex: 1 }}
                            fullWidth
                            value={mapConditionValues(condition)}
                            multiple
                            renderValue={(selectedValues: string[] | number[]) =>
                                selectedValues.map(v => possibleValues.find(value => value?.key === v)?.displayName).join(', ')
                            }
                            onChange={event => {
                                const selectedValues = event.target.value as string[] | number[];
                                onChange(onChangeFilteringCondition(possibleValues, selectedValues, condition));
                            }}
                            inputProps={{ 'aria-label': t('group_condition_dialog.value') }}
                        >
                            {possibleValues.map(possibleValue => {
                                return (
                                    <MenuItem value={possibleValue.key} key={possibleValue.key} sx={{ width: '100%', flex: 1 }}>
                                        <Checkbox checked={isFilteringConditionValueChecked(condition, possibleValue)} />
                                        {possibleValue.displayName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    }
                />
            )}
        </Stack>
    );
};
