import { FieldNumber } from '@/Components/form/field-number/FieldNumber';
import { FieldSwitch } from '@/Components/form/field-switch/FieldSwitch';
import { TimeFieldWrapper } from '@/Components/time-field-wrapper/TimeFieldWrapper';
import { getDayOfWeekTranslationKey } from '@/domain/date/Date.service';
import { TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { DayOfWeek, getDateFromTimeFormat, getTimeFormatFromDate, MONTHS } from '@/utils/datetime.util';
import { Autocomplete, Collapse, FormControlLabel, formHelperTextClasses, Paper, Radio, RadioGroup, Stack, Switch, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const TimeManagementConfiguration: FC = () => {
    const { t } = useTranslation();
    const { control, watch, setValue } = useFormContext<TimesheetSettingForm>();

    const [isCustomLifeCycle, setIsCustomLifeCycle] = useState(watch('cycleStartMonth') !== MONTHS.JANUARY);

    const isForceBreakToBeTakenEnabled = watch('isForceBreakToBeTakenEnabled');

    return (
        <Stack component={Paper} p={2} gap={2}>
            <Typography variant={'h1'}>{t('time_management_settings_page.time_management_configuration.time_management_configuration')}</Typography>

            <Stack gap={2}>
                <Stack width={'30em'}>
                    <Controller
                        name='name'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControlLabel
                                {...field}
                                label={t('time_management_settings_page.time_management_configuration.name')}
                                control={<TextField error={!!error} helperText={error?.message?.toString() ?? ''} variant='outlined' />}
                                sx={{ width: '100%' }}
                            />
                        )}
                    />
                </Stack>

                <Stack>
                    <Typography variant={'body1'}>{t('timesheets.timesheet_mode.timesheet_mode')}</Typography>

                    <Controller
                        render={({ field }) => (
                            <RadioGroup aria-label={t('timesheets.timesheet_mode.timesheet_mode')} {...field}>
                                <FormControlLabel
                                    value={TimesheetMode.NORMAL}
                                    control={<Radio />}
                                    label={t('timesheets.timesheet_mode.mode_normal')}
                                    labelPlacement={'end'}
                                />
                                <FormControlLabel
                                    value={TimesheetMode.SIMPLIFIED}
                                    control={<Radio />}
                                    label={t('timesheets.timesheet_mode.mode_simplified')}
                                    labelPlacement={'end'}
                                />
                            </RadioGroup>
                        )}
                        name={'timesheetMode'}
                        control={control}
                    />
                </Stack>

                <FormControlLabel
                    label={t('time_management_settings_page.time_management_configuration.auto_fill_timesheet')}
                    labelPlacement='end'
                    control={<FieldSwitch name='autofillTimesheet' control={control} />}
                    sx={{ mr: 0 }}
                />

                <FormControlLabel
                    label={t('time_management_settings_page.time_management_configuration.consider_missing_timesheets_unpaid_leave')}
                    labelPlacement='end'
                    control={<FieldSwitch name='considerMissingTimesheetsAsUnpaidLeave' control={control} />}
                    sx={{ mr: 0 }}
                />

                <TimeManagementBonus />

                <Stack direction={'column'}>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <FormControlLabel
                            label={t('time_management_settings_page.time_management_configuration.custom_life_cycle')}
                            labelPlacement='end'
                            control={
                                <Switch
                                    color='primary'
                                    checked={isCustomLifeCycle}
                                    onChange={value => {
                                        if (!value.target.checked) {
                                            setValue('cycleStartMonth', MONTHS.JANUARY, { shouldDirty: true });
                                        }
                                        setIsCustomLifeCycle(value.target.checked);
                                    }}
                                />
                            }
                            sx={{ mr: 0 }}
                        />
                    </Stack>
                    <Collapse in={isCustomLifeCycle}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={200} variant={'body1bold'}>
                                {t('time_management_settings_page.time_management_configuration.life_cycle_start_month')}
                            </Typography>
                            <Controller
                                name='cycleStartMonth'
                                control={control}
                                render={({ field: { onChange, ...restField }, fieldState }) => (
                                    <Autocomplete
                                        {...restField}
                                        onChange={(_, option) => onChange(option)}
                                        disableClearable
                                        options={Object.values(MONTHS)}
                                        style={{ width: '200px' }}
                                        getOptionLabel={option => option}
                                        renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                                    />
                                )}
                            />
                        </Stack>
                    </Collapse>
                </Stack>

                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FormControlLabel
                        label={t('time_management_settings_page.time_management_configuration.mandatory_comment_timesheet')}
                        labelPlacement='end'
                        control={<FieldSwitch name='mandatoryComment' control={control} />}
                        sx={{ mr: 0 }}
                    />
                </Stack>

                <Controller
                    name='breakDisplayEnabled'
                    control={control}
                    render={({ field: { onChange, value, ...field } }) => (
                        <FormControlLabel
                            disabled={isForceBreakToBeTakenEnabled}
                            label={t('time_management_settings_page.time_management_configuration.break_display_enabled')}
                            labelPlacement='end'
                            control={<Switch color='primary' checked={isForceBreakToBeTakenEnabled ? false : value} onChange={onChange} {...field} />}
                        />
                    )}
                />

                <Stack direction='row'>
                    <FormControlLabel
                        label={t('time_management_settings_page.time_management_configuration.maximum_weekly_additional_working_time')}
                        labelPlacement='start'
                        control={
                            <FieldNumber
                                name={'maximumWeeklyAdditionalWorkingTime'}
                                control={control}
                                min={0}
                                max={100}
                                step={5}
                                defaultValue={0}
                                sx={fieldNumberSX}
                            />
                        }
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

const TimeManagementBonus: FC = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();

    const NightBonusPercentage = watch('nightBonusPercentage');
    const disableNightOptions = NightBonusPercentage == 0;

    return (
        <Stack direction={'column'}>
            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <FormControlLabel
                    label={t('time_management_settings_page.time_management_configuration.bonus_night_holiday_weekend')}
                    labelPlacement='end'
                    control={<FieldSwitch name='isBonusEnabled' control={control} />}
                    sx={{ mr: 0 }}
                />
            </Stack>

            <Collapse in={watch('isBonusEnabled')}>
                <Stack gap={2}>
                    <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                        <Typography width={200} variant={'body1bold'}>
                            {t('time_management_settings_page.time_management_configuration.night_bonus')}
                        </Typography>

                        <FieldNumber name='nightBonusPercentage' control={control} min={0} step={1} prefix='%' sx={fieldNumberSX} />

                        <Typography variant={'body1'}>{t('general.from')}</Typography>
                        <Controller
                            name='nightBonusStartTime'
                            disabled={disableNightOptions}
                            control={control}
                            render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                <TimeFieldWrapper
                                    {...restField}
                                    value={getDateFromTimeFormat(value)}
                                    onChange={newValue => {
                                        if (newValue) {
                                            onChange(getTimeFormatFromDate(newValue));
                                        }
                                    }}
                                    sx={{
                                        width: '8em',
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />

                        <Typography variant={'body1'}>{t('general.to')}</Typography>

                        <Controller
                            name='nightBonusEndTime'
                            disabled={disableNightOptions}
                            control={control}
                            render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                <TimeFieldWrapper
                                    {...restField}
                                    value={getDateFromTimeFormat(value)}
                                    onChange={newValue => {
                                        if (newValue) {
                                            onChange(getTimeFormatFromDate(newValue));
                                        }
                                    }}
                                    sx={{
                                        width: error ? 'fit-content' : '8em',
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Stack>

                    <TimeManagementSaturdaySundayBonus />
                </Stack>
            </Collapse>
        </Stack>
    );
};

const TimeManagementSaturdaySundayBonus: FC = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();

    const DaysOfWeekForSaturdayFromDayOfWeek = [DayOfWeek.FRIDAY, DayOfWeek.SATURDAY];
    const DaysOfWeekForSaturdayToDayOfWeek = [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];
    const DaysOfWeekForSundayFromDayOfWeek = [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY];
    const DaysOfWeekForSundayToDayOfWeek = [DayOfWeek.SUNDAY, DayOfWeek.MONDAY];

    const saturdayBonusPercentage = watch('saturdayBonusPercentage');
    const disableSaturdayOptions = saturdayBonusPercentage == 0;

    const sundayBonusPercentage = watch('sundayBonusPercentage');
    const disableSundayOptions = sundayBonusPercentage == 0;

    return (
        <>
            <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                <Typography width={200} variant={'body1bold'}>
                    {t('time_management_settings_page.time_management_configuration.saturday_bonus')}
                </Typography>

                <FieldNumber name='saturdayBonusPercentage' control={control} min={0} step={1} prefix='%' sx={fieldNumberSX} />

                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.from')}</Typography>
                <Controller
                    name={`saturdayFromDayOfWeek`}
                    disabled={disableSaturdayOptions}
                    control={control}
                    render={({ field: { value, onChange, ...restField } }) => (
                        <Autocomplete
                            onChange={(_, value) => {
                                onChange(value);
                            }}
                            sx={autoCompleteSX}
                            disableClearable
                            value={value}
                            options={DaysOfWeekForSaturdayFromDayOfWeek}
                            renderInput={params => <TextField {...params} />}
                            getOptionLabel={option => t(getDayOfWeekTranslationKey(option))}
                            {...restField}
                        />
                    )}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.at')}</Typography>
                <Controller
                    name='saturdayBonusStartTime'
                    disabled={disableSaturdayOptions}
                    control={control}
                    render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                        <TimeFieldWrapper
                            {...restField}
                            value={getDateFromTimeFormat(value)}
                            onChange={newValue => {
                                if (newValue) {
                                    onChange(getTimeFormatFromDate(newValue));
                                }
                            }}
                            sx={{
                                width: error ? 'fit-content' : '8em',
                            }}
                            slotProps={{
                                textField: {
                                    variant: 'outlined',
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.to')}</Typography>
                <Controller
                    name={`saturdayToDayOfWeek`}
                    disabled={disableSaturdayOptions}
                    control={control}
                    render={({ field: { value, onChange, ...restField } }) => (
                        <Autocomplete
                            sx={autoCompleteSX}
                            onChange={(_, value) => {
                                onChange(value);
                            }}
                            disableClearable
                            value={value}
                            options={DaysOfWeekForSaturdayToDayOfWeek}
                            renderInput={params => <TextField {...params} />}
                            getOptionLabel={option => t(getDayOfWeekTranslationKey(option))}
                            {...restField}
                        />
                    )}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.at')}</Typography>
                <Controller
                    name='saturdayBonusEndTime'
                    disabled={disableSaturdayOptions}
                    control={control}
                    render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                        <TimeFieldWrapper
                            {...restField}
                            value={getDateFromTimeFormat(value)}
                            onChange={newValue => {
                                if (newValue) {
                                    onChange(getTimeFormatFromDate(newValue));
                                }
                            }}
                            sx={{
                                width: error ? 'fit-content' : '8em',
                            }}
                            slotProps={{
                                textField: {
                                    variant: 'outlined',
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
            </Stack>

            <Stack direction={'row'} gap={1} alignItems={'center'}>
                <Typography width={200} variant={'body1bold'}>
                    {t('time_management_settings_page.time_management_configuration.sunday_holiday_bonus')}
                </Typography>
                <FieldNumber name='sundayBonusPercentage' control={control} min={0} step={1} prefix='%' sx={fieldNumberSX} />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.from')}</Typography>
                <Controller
                    name={`sundayFromDayOfWeek`}
                    disabled={disableSundayOptions}
                    control={control}
                    render={({ field: { value, onChange, ...restField } }) => (
                        <Autocomplete
                            onChange={(_, value) => {
                                onChange(value);
                            }}
                            sx={autoCompleteSX}
                            disableClearable
                            value={value}
                            options={DaysOfWeekForSundayFromDayOfWeek}
                            renderInput={params => <TextField {...params} />}
                            getOptionLabel={option => t(getDayOfWeekTranslationKey(option))}
                            {...restField}
                        />
                    )}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.at')}</Typography>
                <Controller
                    name='sundayBonusStartTime'
                    disabled={disableSundayOptions}
                    control={control}
                    render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                        <TimeFieldWrapper
                            {...restField}
                            value={getDateFromTimeFormat(value)}
                            onChange={newValue => {
                                if (newValue) {
                                    onChange(getTimeFormatFromDate(newValue));
                                }
                            }}
                            sx={{
                                width: error ? 'fit-content' : '8em',
                            }}
                            slotProps={{
                                textField: {
                                    variant: 'outlined',
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.to')}</Typography>
                <Controller
                    name={`sundayToDayOfWeek`}
                    disabled={disableSundayOptions}
                    control={control}
                    render={({ field: { value, onChange, ...restField } }) => (
                        <Autocomplete
                            sx={{
                                width: '10em',
                            }}
                            disableClearable
                            onChange={(_, value) => {
                                onChange(value);
                            }}
                            value={value}
                            options={DaysOfWeekForSundayToDayOfWeek}
                            renderInput={params => <TextField {...params} />}
                            getOptionLabel={option => t(getDayOfWeekTranslationKey(option))}
                            {...restField}
                        />
                    )}
                />
                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.at')}</Typography>
                <Controller
                    name='sundayBonusEndTime'
                    disabled={disableSundayOptions}
                    control={control}
                    render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                        <TimeFieldWrapper
                            {...restField}
                            value={getDateFromTimeFormat(value)}
                            onChange={newValue => {
                                if (newValue) {
                                    onChange(getTimeFormatFromDate(newValue));
                                }
                            }}
                            sx={{
                                width: error ? 'fit-content' : '8em',
                            }}
                            slotProps={{
                                textField: {
                                    variant: 'outlined',
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
            </Stack>
        </>
    );
};

const fieldNumberSX = {
    width: '8em',
    [`& .${formHelperTextClasses.root}`]: {
        whiteSpace: 'nowrap',
    },
};

const autoCompleteSX = {
    alignSelf: 'normal',
    width: '10em',
};
