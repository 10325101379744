import { IconButton, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Employee } from '@/domain/employee/Employee.model';
import { getEmployeeReviewContributorType, getReviewStatusBarConfig, startEmployeeReviewDiscussion } from '@/domain/employee-review/EmployeeReview.service';
import { EmployeeReview, LabelContext } from '@/domain/employee-review/EmployeeReview.model';
import { isToday } from 'date-fns';
import { isPastDay, LocalDate } from '@/utils/datetime.util';
import { EmployeeReviewStartDiscussionDialog } from '@/page/review/employee-review-start-discussion-dialog/EmployeeReviewStartDiscussionDialog';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { ArrowRight01Icon } from 'hugeicons-react';

type EmployeeReviewStatusButtonProps = {
    employeeReview: EmployeeReview;
    currentEmployee: Employee;
} & StackProps;

export const EmployeeReviewStatusButton: FC<EmployeeReviewStatusButtonProps> = ({ employeeReview, currentEmployee, ...rest }) => {
    const currentEmployeeContributorType = getEmployeeReviewContributorType(employeeReview, currentEmployee);
    const [openStartDiscussionDialog, setOpenStartDiscussionDialog] = useState<boolean>(false);

    const { link, labelContext, deadlineDate } = getReviewStatusBarConfig({
        employeeReview,
        currentEmployeeContributorType,
        currentEmployee,
    });

    const theme = useTheme();

    const { t } = useTranslation();
    const reviewStateLabel = t('reviews.employee_review.state', {
        context: labelContext,
    });
    const navigate = useNavigate();

    if (!employeeReview.review) {
        return;
    }

    const getDateColor = (deadlineDate: LocalDate) => {
        if (isPastDay(deadlineDate)) {
            return theme.palette.error.main;
        }

        if (isToday(deadlineDate)) {
            return theme.palette.warning.main;
        }

        return theme.palette.text.primary;
    };

    const navigateToLink = (link: string) => {
        navigate(link);
    };

    const handleEmployeeReviewStatusButtonClick = (link: string, labelContext: LabelContext) => {
        if (labelContext === 'MANAGER_INPUT_NEEDED_START_DISCUSSION') {
            setOpenStartDiscussionDialog(true);
            return;
        }

        navigateToLink(link);
    };

    const onStartDiscussion = async (employeeReview: EmployeeReview, link: string) => {
        try {
            await startEmployeeReviewDiscussion(employeeReview.id);
            showSnackbar(t('reviews.review_summary.messages.discussion_started'), 'success');
            setOpenStartDiscussionDialog(false);
            navigate(link);
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack {...rest} direction='row' gap={1} alignItems='center'>
            <Stack alignItems={'flex-end'}>
                <Typography id={'action-label'} variant={'body1'}>
                    {reviewStateLabel}
                </Typography>
                {deadlineDate && (
                    <Typography variant={'body2'} id={'review_status_date'} color={getDateColor(deadlineDate)}>
                        {t('reviews.employee_review.employee_review_status_date', {
                            date: deadlineDate,
                            interpolationOptions: {
                                unit: 'day',
                            },
                        })}
                    </Typography>
                )}
            </Stack>

            <IconButton
                disabled={!link}
                onClick={() => handleEmployeeReviewStatusButtonClick(link, labelContext)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme => theme.palette.primary.main,
                    borderRadius: 1,
                    height: 32,
                    width: 32,
                    '&:hover': {
                        backgroundColor: theme => theme.palette.primary.dark,
                    },
                    '&:disabled': {
                        backgroundColor: theme => theme.palette.grey[200],
                    },
                }}
            >
                <ArrowRight01Icon color={link ? theme.palette.common.white : theme.palette.action.disabled} />
            </IconButton>

            {employeeReview && openStartDiscussionDialog && (
                <EmployeeReviewStartDiscussionDialog
                    employeeReview={employeeReview}
                    onStartDiscussion={() => onStartDiscussion(employeeReview, link)}
                    onClose={() => setOpenStartDiscussionDialog(false)}
                />
            )}
        </Stack>
    );
};
