import { DialogContainer, DialogContainerProps } from '@/Components/dialog-container/DialogContainer';
import { Autocomplete, FormControlLabel, Stack, TextField } from '@mui/material';
import { useState } from 'react';

type SelectResourceDialogProps<T> = {
    options: { label: string; value: T }[];
    onSelectValue: (selectedValue: T) => void;
    label: string;
} & Omit<DialogContainerProps, 'onSave'>;

export const SelectResourceDialog = <T,>({ options, onSelectValue, label, ...rest }: SelectResourceDialogProps<T>): JSX.Element => {
    const [selectedResource, setSelectedResource] = useState<T>();

    return (
        <DialogContainer onSave={() => (selectedResource ? onSelectValue(selectedResource) : undefined)} {...rest} primaryActionDisabled={!selectedResource}>
            <Stack gap={2}>
                <FormControlLabel
                    label={label}
                    control={
                        <Autocomplete
                            fullWidth
                            options={options}
                            getOptionLabel={option => option.label}
                            onChange={(_, selectedOption) => setSelectedResource(selectedOption?.value)}
                            renderInput={params => <TextField {...params} autoFocus />}
                        />
                    }
                />
            </Stack>
        </DialogContainer>
    );
};
