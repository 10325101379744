import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionField } from '@/Components/section/types';
import { EditableSectionFieldComponent } from '@/Components/section/SectionFieldComponent/EditableSectionFieldComponent';
import { SectionFieldContainer } from '../../SectionFieldContainer';
import { SectionContainer } from '../../SectionContainer';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { OnboardingProfileStepFormValues } from '@/page/people/on-boarding-form/EmployeeInformationsForm/EmployeeInformationForm';

type BasicInfosSectionProps = {
    baseProfileFormValues?: Partial<OnboardingProfileStepFormValues>;
};

export const BasicInfosSection: FC<BasicInfosSectionProps> = ({ baseProfileFormValues }) => {
    const { t } = useTranslation();

    const basicInfosSectionFields: (Omit<SectionField, 'formValueName' | 'title'> & {
        formValueName: keyof OnboardingProfileStepFormValues;
        title: string;
    })[] = [
        {
            formValueName: 'firstName',
            title: t('employee.first_name'),
            stringValue: baseProfileFormValues?.firstName,
            type: SectionFieldType.STRING,
            required: true,
            fieldType: 'EMPLOYEE_FIRSTNAME',
        },
        {
            formValueName: 'lastName',
            title: t('employee.last_name'),
            stringValue: baseProfileFormValues?.lastName,
            type: SectionFieldType.STRING,
            required: true,
            fieldType: 'EMPLOYEE_LASTNAME',
        },
        {
            formValueName: 'maidenName',
            title: t('employee.maiden_name'),
            stringValue: baseProfileFormValues?.maidenName,
            type: SectionFieldType.STRING,
            required: false,
            fieldType: 'EMPLOYEE_MAIDEN_NAME',
        },
        {
            formValueName: 'email',
            title: t('employee.email'),
            stringValue: baseProfileFormValues?.email,
            type: SectionFieldType.STRING,
            required: true,
            fieldType: 'EMPLOYEE_EMAIL',
        },
        {
            formValueName: 'phoneNumber',
            title: t('employee.phone_number'),
            stringValue: baseProfileFormValues?.phoneNumber,
            type: SectionFieldType.STRING,
            required: false,
            fieldType: 'EMPLOYEE_PHONE_NUMBER',
        },
        {
            formValueName: 'employeeCode',
            title: t('employee.employee_code'),
            stringValue: baseProfileFormValues?.employeeCode,
            type: SectionFieldType.STRING,
            required: false,
            fieldType: 'EMPLOYEE_CODE',
        },
    ];

    return (
        <SectionContainer title={t('employee.sections.basic_info')}>
            {!!basicInfosSectionFields?.length &&
                basicInfosSectionFields.map(basicInfosSectionField => {
                    return (
                        <SectionFieldContainer
                            key={basicInfosSectionField.title}
                            formValueName={basicInfosSectionField.formValueName ?? ''}
                            title={basicInfosSectionField.title}
                            required={basicInfosSectionField.required}
                        >
                            <EditableSectionFieldComponent field={basicInfosSectionField} />
                        </SectionFieldContainer>
                    );
                })}
        </SectionContainer>
    );
};
