import { CalendarDTO } from '@/api/calendar/Calendar.api';
import { DepartmentDTO } from '@/api/department/Department.api';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { JobDTO } from '@/api/job/Job.api';
import { LeaveRequestDTO } from '@/api/leave-request/LeaveRequest.api';
import { LocationDTO } from '@/api/location/Location.api';
import { CostCenterAssignmentMutation } from '@/domain/cost-center/CostCenter.model';
import {
    Employment,
    EmploymentCreationMutation,
    EmploymentsSearchRequest,
    EmploymentUpdateMutation,
    ModifyEmploymentTerminationMutation,
} from '@/domain/employment/Employment.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '../common';

export type EmploymentDTO = Overwrite<
    Employment,
    {
        location: LocationDTO;
        calendar?: CalendarDTO;
        job: JobDTO;
        department: DepartmentDTO;
        managers?: EmployeeDTO[];
    }
>;

type ModifyEmploymentTerminationRequestDTO = ModifyEmploymentTerminationMutation;

export const mapEmploymentDTO = (employment: EmploymentDTO): Employment => {
    return {
        ...employment,
        managers: employment.managers?.map(mapEmployeeDTO) ?? [],
    };
};

const searchEmployments = async (searchRequest: EmploymentsSearchRequest): Promise<Employment[]> => {
    const url = API_BASE_URL + '/employments/search';

    const data = (await client.post<LeaveRequestDTO, AxiosResponse<EmploymentDTO[]>, EmploymentsSearchRequest>(url, searchRequest)).data;
    return data.map(employment => mapEmploymentDTO(employment));
};

const getEmploymentById = (id: number): Promise<Employment> => {
    const url = API_BASE_URL + `/employments/${id}`;
    return client.get(url);
};

const cancelTermination = async (principalEmploymentId: number): Promise<Employment> => {
    const url = API_BASE_URL + `/employments/${principalEmploymentId}/termination/cancel`;
    return (await client.put<Employment, AxiosResponse<Employment>>(url)).data;
};

const modifyEmploymentTermination = async (principalEmploymentId: number, mutation: ModifyEmploymentTerminationMutation): Promise<Employment> => {
    const url = API_BASE_URL + `/employments/${principalEmploymentId}/termination`;
    const response = await client.put<EmploymentDTO, AxiosResponse<EmploymentDTO>, ModifyEmploymentTerminationRequestDTO>(url, mutation);
    return mapEmploymentDTO(response.data);
};

type EmploymentCreationDTO = Omit<EmploymentCreationMutation, 'items'> & {
    locationId: number;
    jobId: number;
    departmentId: number;
    managerIds: number[];
    percentage: number;
    employmentCostCenters: CostCenterAssignmentMutation[];
};

type EmploymentUpdateDTO = Omit<EmploymentUpdateMutation, 'items'> & {
    locationId: number;
    jobId: number;
    departmentId: number;
    managerIds: number[];
    percentage: number;
    employmentCostCenters: CostCenterAssignmentMutation[];
};

const createEmployment = async (mutation: EmploymentCreationMutation): Promise<Employment[]> => {
    const url = API_BASE_URL + `/employments`;
    const { items, ...restMutation } = mutation;
    const response = await client.post<EmploymentDTO[], AxiosResponse<EmploymentDTO[]>, EmploymentCreationDTO[]>(
        url,
        items.map(item => ({
            ...restMutation,
            // percentage is not used in FE, but it is required in BE
            percentage: 100 / items.length,
            principal: item.principal,
            locationId: item.locationId,
            jobId: item.jobId,
            departmentId: item.departmentId,
            managerIds: item.managerIds,
            employmentCostCenters: item.employmentCostCenters,
        })),
    );
    return response.data.map(mapEmploymentDTO);
};

const updateEmployment = async (id: number, mutation: EmploymentUpdateMutation): Promise<Employment[]> => {
    const url = API_BASE_URL + `/employments/${id}`;
    const { items, ...restMutation } = mutation;
    const response = await client.put<EmploymentDTO[], AxiosResponse<EmploymentDTO[]>, EmploymentUpdateDTO[]>(
        url,
        items.map(item => ({
            ...restMutation,
            id: item.id,
            // percentage is not used in FE, but it is required in BE
            percentage: 100 / items.length,
            principal: item.principal,
            locationId: item.locationId,
            jobId: item.jobId,
            departmentId: item.departmentId,
            managerIds: item.managerIds,
            employmentCostCenters: item.employmentCostCenters,
        })),
    );
    return response.data.map(mapEmploymentDTO);
};

const deleteEmployment = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/employments/${id}`;
    await client.delete(url);
};

export const employmentAPI = {
    searchEmployments,
    getEmploymentById,
    cancelTermination,
    modifyEmploymentTermination,
    createEmployment,
    updateEmployment,
    deleteEmployment,
};
