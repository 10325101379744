import { AsyncSelectFilter, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { getLocations } from '@/domain/location/Location.service';
import { getDepartments } from '@/domain/department/Department.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getJobs } from '@/domain/job/Job.service';
import { EmployeeFilterType, searchEmployees } from '@/domain/employee/Employee.service';
import { EmploymentStatus } from '@/domain/employment/Employment.model';

export type PayrollFilter = SelectFilter | AsyncSelectFilter;

/**
 * Hook to get the list of filter for employee payroll locks page
 */
export const useEmployeePayrollLocksPageFilters = (): { filters: PayrollFilter[] } => {
    const filters: PayrollFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees({ statuses: [EmploymentStatus.HIRED, EmploymentStatus.EMPLOYED, EmploymentStatus.ON_LONG_LEAVE] });
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'EMPLOYEE',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobs = await getJobs();
                return jobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                }));
            },
            key: 'JOB_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
