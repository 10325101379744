import { AgGridWrapper, AgGridWrapperColumnType, AgGridWrapperProps, RogerColDef } from '@/Components/ag-grid-wrapper/AgGridWrapper';
import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { ReportField, ReportFieldValueType, ReportRow, ReportSort } from '@/domain/report/Report.model';
import { getFieldName } from '@/domain/report/Report.service';
import { SectionFieldValue } from '@/domain/section-setting/Section.model';
import { isSameFieldDefinition } from '@/domain/section-setting/Section.service';
import { ReportColumn } from '@/page/report/report-columns-selector/ReportColumnsSelector';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { CellClassParams } from '@ag-grid-community/core';
import { useTheme } from '@mui/styles';
import { FC } from 'react';

type ReportGridProps = AgGridWrapperProps<ReportGridRow> & {
    rows: ReportRow[];
    columns: ReportColumn[];
    highlightRange: [Date, Date] | [];
    sorts: ReportSort[];
};

export type ReportGridType = AgGridWrapperColumnType;

export type ReportGridRow = Record<string, unknown> & {
    id: string;
    employeeId: number;
    resourceId: number;
};
/**
 * ReportGrid is a wrapper around AgGridWrapper that takes a ReportRows object as input
 * this component extracts the column definitions from the data itself
 */
export const ReportGrid: FC<ReportGridProps> = ({ initRef, rows, columns, highlightRange, sorts, ...rest }) => {
    const theme = useTheme();
    const columnDefs = getColumnDefs(columns, sorts).map(colDef => {
        return {
            ...colDef,

            cellStyle: ({ value, data }: CellClassParams<ReportGridRow>) => {
                const isNotValidRange = highlightRange.length !== 2 || !value;

                if (isNotValidRange) {
                    return;
                }
                // For each field we have a column with the updatedAt date
                const updatedAt = data?.[`${colDef.field}_UPDATED_AT`] as Date;
                const [from, to] = highlightRange;

                const isDateIncludedInHighlightRange = updatedAt >= from && updatedAt <= to;
                if (isDateIncludedInHighlightRange) {
                    return {
                        backgroundColor: theme.palette.secondary.light,
                    };
                }
            },
        };
    });

    const rowData = getRows(rows);

    return (
        <AgGridWrapper<ReportGridRow>
            columnDefs={columnDefs}
            rowData={rowData}
            {...rest}
            initRef={initRef}
            enableBrowserTooltips={false}
            tooltipShowDelay={0}
        />
    );
};

const getRows = (rows: ReportRow[]): ReportGridRow[] => {
    if (rows) {
        // Table rows are extracted from the rows
        return rows.map(row => {
            const gridRow: ReportGridRow = {
                id: `${row.employeeId}_${row.resourceId}`, // We need a unique id for each row, we use employeeId and resourceId
                employeeId: row.employeeId,
                resourceId: row.resourceId,
            };

            row.fields.forEach(field => {
                gridRow[getFieldName(field.fieldDefinition)] = getFieldValue(field);
                // foreach field we add a column with the updatedAt date for highlight feature and tooltip
                // An other solution could be to handle object for each field
                // { value: field.value, updatedAt: field.updatedAt, sectionId: field.sectionId, fieldId: field.fieldId, ...}
                // and update field value getter in getColumnDefs

                gridRow[getFieldName(field.fieldDefinition) + '_UPDATED_AT'] = field.updatedAt;
            });

            return gridRow;
        });
    }
    return [];
};

const getColumnDefs = (columns: ReportColumn[], sorts: ReportSort[]): RogerColDef<ReportGridRow>[] => {
    // Columns are sorted with the order property
    const orderedColumns = [...(columns ?? [])]?.sort((a, b) => a.order - b.order);
    return orderedColumns.map(column => {
        const reportSort = sorts.find(s =>
            isSameFieldDefinition(column.sectionFieldDefinition, {
                id: s.sectionFieldDefinitionId,
                fieldType: s.fieldType,
            }),
        );
        const getSort = (): 'asc' | 'desc' | undefined => {
            if (reportSort) {
                return reportSort.direction === 'ASC' ? 'asc' : 'desc';
            }
        };

        const sort = getSort();
        const sortIndex = reportSort?.order ?? undefined;

        return {
            headerName: getFieldDefinitionTranslation({
                id: column.sectionFieldDefinition?.id,
                fieldType: column.fieldType,
                name: column.sectionFieldDefinition?.name,
                valueType: column.valueType,
            }),
            field: getFieldName(column),
            type: getGridColumnType(column.valueType),
            hide: !column.visible,
            tooltipValueGetter: params => {
                const updateAt = params.data?.[`${getFieldName(column)}_UPDATED_AT`] as Date;
                return updateAt ? formatInDefaultDate(updateAt) : '';
            },

            // We dont used cellClass to avoid to override the cellClass of the column type
            cellClassRules: {
                'display-flex': () => true,
                'span-cell': () => true,
            },

            sort,
            sortIndex,
            pinned: column.fieldType === 'EMPLOYEE' ? 'left' : undefined,
        };
    });
};

const getGridColumnType = (reportField: ReportFieldValueType): ReportGridType => {
    switch (reportField) {
        case 'LABEL':
            return 'label';
        case 'DOCUMENT':
            return 'documents';
        case 'SECTION_FIELD_DOCUMENT':
            return 'sectionFieldDocuments';
        case 'EMPLOYEE':
            return 'stackedAvatars';
        case 'DATE':
            return 'date';
        default:
            return 'fieldValue';
    }
};

/**
 * Get the display value for a report field
 * @param field
 * @returns
 */
const getFieldValue = (field: ReportField): unknown => {
    if (!field.type) {
        return;
    }

    switch (field.type) {
        case 'EMPLOYEE':
            return field.employeeValues;
        case 'SECTION_FIELD_DOCUMENT':
            return { documents: field.sectionFieldDocumentValues ? field.sectionFieldDocumentValues : [] };
        case 'DOCUMENT':
            // Ag grid wrapper type documents expects an array of documents
            return { documents: field.documentValue ? [field.documentValue] : [] };
        case 'LABEL':
            return field.labelValue;
        case 'DATE':
            return field.dateValue;

        default:
            return mapToSectionFieldValues(field);
    }
};

const mapToSectionFieldValues = (field: ReportField): SectionFieldValue => {
    // This is a hack to fix the valueType of the field (Remove this when the backend is fixed)
    const valueType = field.fieldDefinition?.sectionFieldDefinition?.type === 'COUNTRY' ? 'COUNTRY' : field.fieldDefinition.valueType;

    return {
        documents: [],
        customListItemReferences: [],
        employeeReferences: [],
        employeeValues: [],
        ...field,
        sectionFieldDefinition: {
            ...field.fieldDefinition,
            valueType,
        },
    };
};
