import { ScaleProgressBar } from '@/Components/scale-progress-bar/ScaleProgressBar';
import { Label } from '@/domain/label/Label.model';
import { AnswerResultDetails, SurveyQuestion, SurveyQuestionType, SurveyResultFilterType } from '@/domain/survey/Survey.model';
import { getLabelTranslation } from '@/utils/language.util';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    answerDetails: AnswerResultDetails;
    question: SurveyQuestion;
    groupBy: SurveyResultFilterType;
};

const useStyles = makeStyles(theme => ({
    scaleProgressBar: {
        display: 'flex',
    },
    progressBarChild: {
        height: 30,
    },
    groupData: {
        marginBottom: theme.spacing(1),
    },
    name: {
        width: 160,
        textAlign: 'right',
        marginRight: theme.spacing(1),
        boxSizing: 'border-box',
        color: '#585869',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
    },
    totalNumber: {
        width: 120,
        display: 'block',
        textAlign: 'center',
    },
    totalValueAll: {
        fontSize: 20,
    },
    allGroupInfo: {
        marginTop: theme.spacing(2),
    },
}));

export const ScaleGroupBy: FC<Props> = ({ question, answerDetails, groupBy }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const renderTooltipTitle = (label: Label) => {
        if (question.type === SurveyQuestionType.OPINION_SCALE || question.type === SurveyQuestionType.NPS) {
            return `${t('survey_results.picked_the_score')} ${getLabelTranslation(label)} /10`;
        } else {
            return `${t('survey_results.answered')} ${getLabelTranslation(label)}`;
        }
    };
    if (!question || !answerDetails) {
        return <></>;
    }
    return (
        <div className={classes.allGroupInfo}>
            <Grid container direction={'row'} alignItems={'center'} className={classes.groupData}>
                <Grid item className={classes.name}>
                    <Typography component={'span'} variant='body1'>
                        {groupBy !== SurveyResultFilterType.ALL ? (
                            getLabelTranslation(answerDetails?.label)
                        ) : (
                            <b>{getLabelTranslation(answerDetails?.label)}</b>
                        )}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <ScaleProgressBar
                        boxHeight={24}
                        scales={question.items.map(item => {
                            const sum = answerDetails.items?.length > 0 ? answerDetails.items.map(i => i.count).reduce((a, b) => a + b, 0) : 0;
                            const answerItem = answerDetails.items.find(i => i.item.id === item.id);
                            const value = sum > 0 ? ((answerItem?.count ?? 0) * 100) / sum : 0;
                            return {
                                value: value,
                                count: answerItem?.count ?? 0,
                                title: renderTooltipTitle(item.label),
                                order: item.order,
                                score: item.score,
                            };
                        })}
                    />
                </Grid>
                <Grid item className={classes.totalNumber}>
                    <Typography variant='body1bold' className={classNames(groupBy === SurveyResultFilterType.ALL ? classes.totalValueAll : '')}>
                        {answerDetails.averageScore.toFixed(1)}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};
