import { Country, getCountryList } from '@/utils/countries.util';
import { Autocomplete, Box, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export type CountrySelectProps = {
    formValueName: string;
    countryValue: Country | null;
    disabled?: boolean;
};

export const CountrySelect: FC<CountrySelectProps> = ({ formValueName, countryValue, disabled }) => {
    const countryList = getCountryList();

    const { control, formState } = useFormContext<Record<string, CountrySelectProps['countryValue']>>();

    return (
        <Controller
            name={formValueName}
            control={control}
            defaultValue={countryValue}
            render={({ field: { value, onChange, ...restField } }) => (
                <Autocomplete
                    {...restField}
                    id={formValueName}
                    value={value}
                    isOptionEqualToValue={(option, anotherOption) => option.value === anotherOption.value}
                    onChange={(_, value) => {
                        onChange(value);
                    }}
                    fullWidth
                    options={countryList}
                    autoHighlight
                    getOptionLabel={option => option.label}
                    renderOption={(props, option) => (
                        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                loading='lazy'
                                width='20'
                                src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                                alt=''
                            />
                            {option.label} ({option.value})
                        </Box>
                    )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            error={!!formState.errors[formValueName]}
                            helperText={formState.errors[formValueName]?.message?.toString() ?? ''}
                            inputProps={{
                                ...params.inputProps,
                                'aria-label': formValueName,
                            }}
                        />
                    )}
                    disabled={disabled}
                />
            )}
        />
    );
};
