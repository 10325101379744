import { getLeaveBalance, getLeaveTypeHistories } from '@/domain/leave-type-history/LeaveTypeHistory.service';
import { LeaveBalanceSearchRequest, LeaveTypeHistory, UserLeaveTypeBalance } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { useCallback, useState } from 'react';
import { UseQueryResult } from '@/page/Query.type';
import { LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { LocalDate } from '@/utils/datetime.util';
import { handleError } from '@/utils/api.util';

export const useGetLeaveTypeHistories = (
    employeeId: number,
    filters: {
        leaveActivityTypes?: LeaveActivityType[];
        endDate?: LocalDate;
    } = {},
): UseQueryResult<LeaveTypeHistory[]> => {
    const [leaveTypeHistories, setLeaveTypeHistories] = useState<LeaveTypeHistory[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLeaveTypeHistories = useCallback(
        async (
            employeeId: number,
            filters: {
                leaveActivityTypes?: LeaveActivityType[];
                endDate?: LocalDate;
            },
        ) => {
            if (!employeeId) {
                return;
            }

            try {
                const leaveTypeHistories = await getLeaveTypeHistories(employeeId, filters);
                setLeaveTypeHistories(leaveTypeHistories);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [],
    );

    useDeepCompareEffect(() => {
        fetchLeaveTypeHistories(employeeId, filters).catch(handleError);
    }, [fetchLeaveTypeHistories, employeeId, filters]);

    return {
        data: leaveTypeHistories,
        setData: setLeaveTypeHistories,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchLeaveTypeHistories(employeeId, filters),
    };
};

export const useGetLeaveBalance = ({
    leaveTypeId,
    searchRequest,
    options = { enabled: true },
}: {
    leaveTypeId: number;
    searchRequest: LeaveBalanceSearchRequest;
    options?: { enabled: boolean };
}): UseQueryResult<UserLeaveTypeBalance[]> => {
    const [userLeaveTypeBalance, setUserLeaveTypeBalance] = useState<UserLeaveTypeBalance[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchUserLeaveTypeBalance = useCallback(
        async (leaveTypeId: number, searchRequest: LeaveBalanceSearchRequest) => {
            if (!leaveTypeId || !options.enabled) {
                return;
            }

            try {
                setIsFetching(true);
                const leaveTypeBalance = await getLeaveBalance(leaveTypeId, searchRequest);
                setUserLeaveTypeBalance(leaveTypeBalance);
            } catch (error) {
                setError(error);
            }
            setIsFetching(false);
            setIsLoading(false);
        },
        [options.enabled],
    );

    useDeepCompareEffect(() => {
        fetchUserLeaveTypeBalance(leaveTypeId, searchRequest).catch(handleError);
    }, [fetchUserLeaveTypeBalance, leaveTypeId, searchRequest]);

    return {
        data: userLeaveTypeBalance,
        setData: setUserLeaveTypeBalance,
        isLoading,
        isFetching,
        isError: !!error,
        error,
        refetch: () => fetchUserLeaveTypeBalance(leaveTypeId, searchRequest),
    };
};
