import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleError } from '@/utils/api.util';
import { formatToLocalDate } from '@/utils/datetime.util';
import { EmployeeShift, LeaveShiftSearchRequest, LeaveShiftSearchRequestWithLocalDate } from '@/domain/shift/Shift.model';
import { getLeaveShifts } from '@/domain/shift/Shift.service';

export const useGetLeaveShifts = (search: LeaveShiftSearchRequest, enabled = true): UseQueryResult<EmployeeShift[]> => {
    const [employeeShifts, setEmployeeShifts] = useState<EmployeeShift[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeShifts = useCallback(
        async (searchRequest: LeaveShiftSearchRequestWithLocalDate) => {
            if (!enabled) {
                setIsLoading(false);
                return;
            }
            const request: LeaveShiftSearchRequest = {
                ...searchRequest,
                startDate: new Date(searchRequest.startDate),
                endDate: new Date(searchRequest.endDate),
            };
            try {
                const employeeShifts = await getLeaveShifts(request);
                const withLeaves = employeeShifts.filter(s => s.shifts.length > 0);
                setEmployeeShifts(withLeaves);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [enabled],
    );

    const searchWithLocalDate: LeaveShiftSearchRequestWithLocalDate = {
        ...search,
        startDate: formatToLocalDate(search.startDate),
        endDate: formatToLocalDate(search.endDate),
    };

    useDeepCompareEffect(() => {
        fetchEmployeeShifts(searchWithLocalDate).catch(handleError);
    }, [fetchEmployeeShifts, searchWithLocalDate]);

    return {
        data: employeeShifts,
        setData: setEmployeeShifts,
        refetch: () => fetchEmployeeShifts(searchWithLocalDate),
        isLoading,
        isError: !!error,
        error,
    };
};
