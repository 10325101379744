import { FC, useState } from 'react';
import { Button, Paper, Stack, StackProps, Typography } from '@mui/material';
import { ObjectivesList } from '@/page/objective/objectives-list/ObjectivesList';
import { useTranslation } from 'react-i18next';
import { canCreateObjectives, canEditObjectives, canViewObjectives } from '@/domain/permission/Permission.service';
import { AddObjectiveDialog } from '@/page/objective/add-objective-dialog/AddObjectiveDialog';
import { useAppSelector } from '@/stores/store';
import { Objective } from '@/domain/objective/Objective.model';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { StateHandler } from '@/Components/state-handler/StateHandler';

type EmployeeReviewFeedbackObjectivesProps = {
    employeeId: number;
    disabled?: boolean;
    objectives: Objective[];
    refetchObjectives: () => void;
} & StackProps;

export const EmployeeReviewFeedbackObjectives: FC<EmployeeReviewFeedbackObjectivesProps> = ({
    employeeId,
    disabled,
    objectives,
    refetchObjectives,
    ...rest
}) => {
    const { t } = useTranslation();
    const [activeObjective, setActiveObjective] = useState<Objective>();
    const [objectiveDialogOpen, setObjectiveDialogOpen] = useState<boolean>(false);
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);

    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    if (!policies) {
        return;
    }

    const handleObjectiveEdit = (id: number) => {
        const editObjective = (objectives ?? []).find(objective => objective.id === id);
        setActiveObjective(editObjective);
        setObjectiveDialogOpen(true);
    };

    if (!realm) {
        throw new Error('Realm is not defined');
    }

    return (
        <Stack gap={2}>
            {canViewObjectives(realm.realmFeatures, policies, employeeId) && (
                <StateHandler isLoading={isLoadingObjectiveSetting} isError={isErrorObjectiveSetting} error={errorObjectiveSetting}>
                    <Stack component={Paper} gap={2} padding={1} {...rest}>
                        {objectives?.length !== 0 ? (
                            <ObjectivesList
                                objectives={objectives || []}
                                onSuccess={() => refetchObjectives()}
                                onEditObjective={handleObjectiveEdit}
                                editable={canEditObjectives(policies, employeeId)}
                                disabled={disabled}
                                displayWeight={objectiveSetting?.objectiveWeightEnabled ?? false}
                                displayAvatarsMobile={true}
                            />
                        ) : (
                            <Typography variant={'body1'}>{t('reviews.review_summary.no_open_objectives')}</Typography>
                        )}
                    </Stack>
                </StateHandler>
            )}
            {canCreateObjectives(policies, employeeId) && (
                <Button fullWidth onClick={() => setObjectiveDialogOpen(true)} disabled={disabled}>
                    {t('reviews.review_summary.add_objective')}
                </Button>
            )}

            {objectiveDialogOpen && (
                <AddObjectiveDialog
                    open={objectiveDialogOpen}
                    activeObjective={activeObjective}
                    onSaveObjective={() => refetchObjectives()}
                    employeeId={employeeId}
                    parentObjectiveEnabled={objectiveSetting?.parentObjectiveEnabled ?? false}
                    onClose={() => {
                        setActiveObjective(undefined);
                        setObjectiveDialogOpen(false);
                    }}
                />
            )}
        </Stack>
    );
};
