import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '../common';
import { LeaveType, UserLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';

const getEmployeeLeaveTypePolicies = async (employeeId: number): Promise<UserLeaveTypePolicy[]> => {
    return (await client.get<UserLeaveTypePolicy[]>(API_BASE_URL + `/leave-type-policies/${employeeId}`)).data;
};

const getCurrentEmployeeLeaveTypePolicies = async (): Promise<UserLeaveTypePolicy[]> => {
    return (await client.get<UserLeaveTypePolicy[]>(API_BASE_URL + `/leave-type-policies`)).data;
};

const getCurrentEmployeeWorkingLeaveTypePolicies = async (): Promise<UserLeaveTypePolicy[]> => {
    return (await client.get<UserLeaveTypePolicy[]>(API_BASE_URL + `/leave-type-policies/working`)).data;
};

type BatchAssignLeaveTypePolicyRequest = {
    employeeIds: number[];
    leaveTypeId: number;
    leaveTypePoliceId: number;
    applyFrom: Date;
};

type BatchUnassignLeaveTypePolicyRequest = {
    employeeIds: number[];
    leaveTypeId: number;
    endDate: Date;
};

const assignLeaveTypePolicy = async (employeeId: number, userLeaveType: UserLeaveTypePolicy): Promise<UserLeaveTypePolicy[]> => {
    const request = {
        employeeId: employeeId,
        applyFrom: userLeaveType.applyFrom,
        leaveTypePolicyAssignment: {
            leaveTypeId: userLeaveType.leaveType.id,
            leaveTypePolicyId: userLeaveType.leaveTypePolicy.id,
        },
    };

    return (await client.post<UserLeaveTypePolicy[]>(API_BASE_URL + `/leave-type-policies/assign`, request)).data;
};

const unassignLeaveTypePolicy = async (employeeId: number, leaveType: LeaveType, endDate: Date): Promise<UserLeaveTypePolicy[]> => {
    const request = {
        employeeId: employeeId,
        leaveTypeId: leaveType.id,
        endDate: endDate,
    };

    return (await client.post<UserLeaveTypePolicy[]>(API_BASE_URL + `/leave-type-policies/unassign`, request)).data;
};

const batchAssignLeaveTypePolicy = async (
    employeeIds: number[],
    leaveTypeId: number,
    leaveTypePoliceId: number,
    applyFrom: Date,
): Promise<UserLeaveTypePolicy> => {
    const request: BatchAssignLeaveTypePolicyRequest = {
        employeeIds,
        leaveTypeId,
        leaveTypePoliceId,
        applyFrom: applyFrom,
    };

    return (
        await client.post<UserLeaveTypePolicy, AxiosResponse<UserLeaveTypePolicy>, BatchAssignLeaveTypePolicyRequest>(
            API_BASE_URL + `/leave-type-policies/batch/assign`,
            request,
        )
    ).data;
};

const batchUnassignLeaveTypePolicy = async (employeeIds: number[], leaveTypeId: number, endDate: Date): Promise<UserLeaveTypePolicy> => {
    const request: BatchUnassignLeaveTypePolicyRequest = {
        employeeIds,
        leaveTypeId,
        endDate: endDate,
    };
    return (
        await client.post<UserLeaveTypePolicy, AxiosResponse<UserLeaveTypePolicy>, BatchUnassignLeaveTypePolicyRequest>(
            API_BASE_URL + `/leave-type-policies/batch/unassign`,
            request,
        )
    ).data;
};

export const leaveTypePolicyApi = {
    getCurrentEmployeeLeaveTypePolicies,
    getCurrentEmployeeWorkingLeaveTypePolicies,
    getEmployeeLeaveTypePolicies,
    assignLeaveTypePolicy,
    batchAssignLeaveTypePolicy,
    unassignLeaveTypePolicy,
    batchUnassignLeaveTypePolicy,
};
