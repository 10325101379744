import { leaveRequestApi } from '@/api/leave-request/LeaveRequest.api';
import { getAppConfig } from '@/config/config';
import { Document } from '@/domain/document/Document.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import {
    ConflictLeaveSearchRequest,
    LeaveCreationMutation,
    LeaveRequest,
    LeaveRequestPreview,
    LeaveRequestSearchRequest,
    LeaveUpdateMutation,
    OverlappingLeaveSearchRequest,
} from '@/domain/leave-request/LeaveRequest.model';
import { LeaveActivityType, LeaveType, RequestIncrement } from '@/domain/leave-type/LeaveType.model';
import { hasManageShiftsPolicy, hasViewShiftsPolicy } from '@/domain/permission/Permission.service';
import { EmployeeShift, Shift, ShiftReleaseRequest, ShiftSearchRequest, ShiftStatus } from '@/domain/shift/Shift.model';
import { handleError } from '@/utils/api.util';
import { formatTime, formatToLocalDate, getHoursMinutesFromMinutes, LocalDate, toDate } from '@/utils/datetime.util';
import { getRoundedNumber, RoundingType } from '@/utils/math.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { differenceInDays, format, isSameDay } from 'date-fns';
import i18next, { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { DayPeriod, UnitType } from '../date/Date.model';
import { getDayPeriodTranslationKey } from '../date/Date.service';
import { AllowanceType, CarryoverType, LeaveTypePolicy, RequestCalculationType } from '../leave-type/LeaveType.model';

import { getEmployeeShifts } from '@/domain/shift/Shift.service';

const config = getAppConfig();

export const createLeaveRequest = (mutation: LeaveCreationMutation): Promise<LeaveRequest[]> => {
    return leaveRequestApi.createLeaveRequest(mutation);
};

export const previewLeaveRequest = (mutation: LeaveCreationMutation): Promise<LeaveRequestPreview> => {
    return leaveRequestApi.previewLeaveRequest(mutation);
};

export const editLeaveRequest = (leaveRequestId: number, mutation: LeaveUpdateMutation): Promise<LeaveRequest> => {
    return leaveRequestApi.editLeaveRequest(leaveRequestId, mutation);
};

export const editPendingLeaveRequest = (leaveRequestId: number, mutation: LeaveUpdateMutation): Promise<LeaveRequest> => {
    return leaveRequestApi.editPendingLeaveRequest(leaveRequestId, mutation);
};

export const cancelApprovedLeaveRequest = (leaveRequestId: number): Promise<LeaveRequest> => {
    return leaveRequestApi.cancelApprovedLeaveRequest(leaveRequestId);
};

export const approvePendingLeaveRequest = (leaveRequestId: number): Promise<LeaveRequest> => {
    return leaveRequestApi.approvePendingLeaveRequest(leaveRequestId);
};

export const declinePendingLeaveRequest = (leaveRequestId: number, declineComment: string): Promise<LeaveRequest> => {
    return leaveRequestApi.declinePendingLeaveRequest(leaveRequestId, declineComment);
};

export const cancelPendingLeaveRequest = (leaveRequestId: number): Promise<LeaveRequest> => {
    return leaveRequestApi.cancelPendingLeaveRequest(leaveRequestId);
};

export const getPendingLeaveRequests = (): Promise<LeaveRequest[]> => {
    return leaveRequestApi.getPendingLeaveRequests();
};

export const getLeaveRequestById = (leaveRequestId: number): Promise<LeaveRequest> => {
    return leaveRequestApi.getLeaveRequestById(leaveRequestId);
};

export const searchLeaveRequests = (searchRequest: LeaveRequestSearchRequest): Promise<LeaveRequest[]> => {
    return leaveRequestApi.searchLeaveRequests(searchRequest);
};

export const searchConflictingRequests = (searchRequest: ConflictLeaveSearchRequest): Promise<LeaveRequest[]> => {
    return leaveRequestApi.searchConflictingRequests(searchRequest);
};

export const searchOverlappingRequests = (searchRequest: OverlappingLeaveSearchRequest): Promise<LeaveRequest[]> => {
    return leaveRequestApi.searchOverlappingRequests(searchRequest);
};

export const getUserLeaveRequests = (employeeId: number): Promise<LeaveRequest[]> => {
    return leaveRequestApi.getUserLeaveRequests(employeeId);
};

export const deleteLeaveRequest = (id: number): Promise<void> => {
    return leaveRequestApi.deleteLeaveRequest(id);
};

export const approvePendingLeaveRequestBatch = (leaveRequestIds: number[]): Promise<Document> => {
    return leaveRequestApi.approvePendingLeaveRequestBatch(leaveRequestIds);
};

export const countPendingLeaveRequests = async (): Promise<number> => {
    // TODO replace by a specific endpoint to get only the count : https://rogerhr.atlassian.net/browse/RP-3992
    const leaveRequests = await leaveRequestApi.getPendingLeaveRequests();
    return leaveRequests.length;
};

const getStartDateFormatted = (request: LeaveRequest, formatDate: string) =>
    `${format(new Date(request.startDate), formatDate)} ${
        request.startTimePeriod !== DayPeriod.ALL_DAY ? `(${i18next.t(getDayPeriodTranslationKey(request.startTimePeriod).toLowerCase())})` : ''
    }`;

const getEndDateFormatted = (request: LeaveRequest, formatDate: string) => {
    let endTimeFormatted = '';
    if (request.endTimePeriod !== DayPeriod.ALL_DAY) {
        endTimeFormatted = `(${i18next.t(getDayPeriodTranslationKey(request.endTimePeriod).toLowerCase())})`;
    }
    return request.endDate ? `${format(new Date(request.endDate), formatDate)} ${endTimeFormatted}` : i18next.t('leaves_page.to_be_confirmed');
};

export const getLeaveRequestPeriodAsString = (request: LeaveRequest, leaveType: LeaveType, includeTitle = false): string => {
    const differentYears = request.endDate && new Date(request.startDate).getFullYear() !== new Date(request.endDate).getFullYear();
    const formatDate = differentYears ? config.DEFAULT_DATE_WEEK_NAME : config.DEFAULT_DATE_FORMAT;

    const startDateFormatted = getStartDateFormatted(request, formatDate);
    const endDateFormatted = getEndDateFormatted(request, formatDate);

    let formattedRequest = startDateFormatted === endDateFormatted ? startDateFormatted : `${startDateFormatted} → ${endDateFormatted}`;

    if (leaveType?.unitType === UnitType.HOURS) {
        formattedRequest += ` (${formatTime(request.startTimeInMinutes ?? 0)} → ${formatTime(request.endTimeInMinutes ?? 0)})`;
    }

    if (includeTitle) {
        formattedRequest = `${t(getRequestStatusTranslationKey(request.requestStatus))} ${request.leaveType.title} ${formattedRequest}`;
    }

    return formattedRequest;
};

export const getRequestStatusTranslationKey = (requestStatus: string): string => {
    switch (requestStatus) {
        case 'APPROVED':
            return 'domain.leave_request.approved';
        case 'DECLINED':
            return 'domain.leave_request.declined';
        case 'PENDING':
            return 'domain.leave_request.pending';
        case 'CANCELLED':
            return 'domain.leave_request.cancelled';
        case 'SHIFT_TIMESHEET':
            return 'domain.timesheet.shift';
        case 'LOCKED':
            return 'domain.timesheet.locked';
        case 'AUTO_FILLED':
            return 'time_management_settings_page.auto_fill';
        default:
            return '';
    }
};

export const isMedicalType = (leaveType: LeaveType | undefined): boolean => {
    if (!leaveType) {
        return false;
    }
    return leaveType && leaveType?.leaveActivityType === LeaveActivityType.MEDICAL;
};

export const isLeaveMedicalTypeEndDateEmpty = (leaveType: LeaveType, endDate: Date | undefined): boolean => {
    return isMedicalType(leaveType) && !endDate;
};

export const getStartPeriodChoices = (startDate: Date | undefined, endDate: Date | undefined): DayPeriod[] => {
    //TODO: we might need to improve this for multiple dates
    if (startDate && !endDate) {
        return [DayPeriod.ALL_DAY, DayPeriod.AFTERNOON];
    }
    if (!startDate || !endDate) {
        return [DayPeriod.ALL_DAY, DayPeriod.MORNING, DayPeriod.AFTERNOON];
    }
    if (isSameDay(new Date(startDate), new Date(endDate))) {
        return [DayPeriod.ALL_DAY, DayPeriod.MORNING, DayPeriod.AFTERNOON];
    } else {
        return [DayPeriod.ALL_DAY, DayPeriod.AFTERNOON];
    }
};

export const getEndPeriodChoices = (startDate: Date | undefined, endDate: Date | undefined): DayPeriod[] => {
    //TODO: we might need to improve this for multiple dates
    if (!startDate || !endDate) {
        return [DayPeriod.ALL_DAY, DayPeriod.MORNING, DayPeriod.AFTERNOON];
    }
    if (isSameDay(new Date(startDate), new Date(endDate))) {
        return [DayPeriod.ALL_DAY, DayPeriod.MORNING, DayPeriod.AFTERNOON];
    } else {
        return [DayPeriod.ALL_DAY, DayPeriod.MORNING];
    }
};

type LeavesMinutesConversionConfig = {
    input: number;
    outputUnit: UnitType;
    roundingType?: RoundingType;
    useAbsoluteValue?: boolean;
};
export const convertLeavesMinutesToUnit = ({
    input,
    outputUnit,
    roundingType = RoundingType.NEAREST_1_DECIMAL,
    useAbsoluteValue = false,
}: LeavesMinutesConversionConfig): number => {
    switch (outputUnit) {
        case UnitType.DAYS:
            return minutesToDays(input, roundingType, useAbsoluteValue);
        case UnitType.HOURS:
            return minutesToHours(input, roundingType, useAbsoluteValue);
        case UnitType.MINUTES:
            return input;
    }

    function minutesToDays(inputDays: number, roundingType: RoundingType, useAbsoluteValue = false): number {
        if (inputDays === 0) {
            return 0;
        }
        if (Number.isNaN(inputDays)) {
            return 0;
        }
        const roundedNumber = getRoundedNumber(inputDays, roundingType);
        return useAbsoluteValue ? Math.abs(roundedNumber) : roundedNumber;
    }

    function minutesToHours(minutes: number, roundingType: RoundingType, useAbsoluteValue = false): number {
        const hours = minutes / 60;
        const roundedNumber = getRoundedNumber(hours, roundingType);
        return useAbsoluteValue ? Math.abs(roundedNumber) : roundedNumber;
    }
};
export const getLeaveRequestDurationFormatted = (leaveRequest: LeaveRequest): string => {
    return t('duration.formatDuration', {
        duration: convertLeavesMinutesToUnit({
            input: (leaveRequest.leaveType?.displayUnitType === UnitType.DAYS ? leaveRequest.durationInDays : leaveRequest.durationInMinutes) ?? 0,
            outputUnit: leaveRequest.leaveType?.displayUnitType,
            roundingType: leaveRequest.leaveType?.roundingType,
            useAbsoluteValue: true,
        }),
        unit: leaveRequest.leaveType?.displayUnitType,
    });
};

export const getLeaveRequestUsedAmountFormatted = (leaveRequest: LeaveRequest): string => {
    return t('duration.formatDuration', {
        duration: convertLeavesMinutesToUnit({
            input: (leaveRequest.leaveType?.displayUnitType === UnitType.DAYS ? leaveRequest.usedAmountInDays : leaveRequest.usedAmountInMinutes) ?? 0,
            outputUnit: leaveRequest.leaveType?.displayUnitType,
            roundingType: leaveRequest.leaveType?.roundingType,
            useAbsoluteValue: true,
        }),
        unit: leaveRequest.leaveType?.displayUnitType,
    });
};

export const getLeaveRequestBalanceAsString = (
    leaveType: LeaveType,
    availableAmountInDays: number | undefined,
    availableAmountInMinutes: number | undefined,
    type: RoundingType | undefined,
): string => {
    return t('duration.formatDuration', {
        duration: convertLeavesMinutesToUnit({
            input: (leaveType?.displayUnitType === UnitType.DAYS ? availableAmountInDays : availableAmountInMinutes) ?? 0,
            outputUnit: leaveType?.displayUnitType,
            roundingType: type,
        }),
        unit: leaveType?.displayUnitType,
    });
};

export const canApproveLeaveRequest = (leaveRequest: LeaveRequest): boolean => {
    return leaveRequest.requestStatus === 'PENDING';
};

export const canCancelLeaveRequest = (leaveRequest: LeaveRequest): boolean => {
    return leaveRequest.requestStatus === 'APPROVED' || leaveRequest.requestStatus === 'PENDING';
};

export const canDeclineLeaveRequest = (leaveRequest: LeaveRequest): boolean => {
    return leaveRequest.requestStatus === 'PENDING';
};

export const isLeaveTypeHours = (unitType: UnitType): boolean => {
    return unitType === UnitType.HOURS;
};

export const isLeaveTypeMedical = (leaveActivityType: LeaveActivityType | undefined): boolean => {
    return leaveActivityType === LeaveActivityType.MEDICAL;
};

const hasConflictingShifts = (employeeShifts: EmployeeShift[]): boolean => {
    const firstShift = employeeShifts[0]?.shifts;
    return firstShift?.length > 0;
};

const handleConflictingShifts = (
    shiftData: EmployeeShift,
    shiftSearchRequest: ShiftSearchRequest,
    approveLeaveRequest: LeaveRequest,
    setConflictingShifts: Dispatch<SetStateAction<Shift[]>>,
    setIsLeaveConflictDialogOpen: Dispatch<SetStateAction<boolean>>,
    setShiftReleaseRequest: Dispatch<SetStateAction<ShiftReleaseRequest | undefined>>,
    setActiveLeaveRequest: Dispatch<SetStateAction<LeaveRequest | undefined>>,
): void => {
    setConflictingShifts(shiftData.shifts);
    setIsLeaveConflictDialogOpen(true);
    const employeeId = shiftSearchRequest.employeeIds?.[0];
    if (!employeeId) {
        return;
    }
    const shiftReleaseObj: ShiftReleaseRequest = {
        employeeId: employeeId,
        rangeDates: shiftSearchRequest.rangeDates,
    };
    setShiftReleaseRequest(shiftReleaseObj);
    setActiveLeaveRequest(approveLeaveRequest);
};

const approvePendingLeaveRequestAndRefetch = (refetch: boolean, approveLeaveRequest: LeaveRequest, setRefetch: Dispatch<SetStateAction<boolean>>): void => {
    approvePendingLeaveRequest(approveLeaveRequest.id)
        .then(() => {
            setRefetch(!refetch);
        })
        .catch(error => {
            handleError(error);
        });
};

const couldHandleConflicts = (approveLeaveRequest: LeaveRequest, grantedPolicies: EmployeePolicy[]): boolean =>
    !isLeaveMedicalTypeEndDateEmpty(approveLeaveRequest.leaveType, toDate(approveLeaveRequest.endDate)) &&
    (hasManageShiftsPolicy(grantedPolicies) || hasViewShiftsPolicy(grantedPolicies));

export const createShiftSearchRequestFromLeaveRequest = (
    employeeId: number,
    planningShiftStatus: ShiftStatus[],
    leaveRequest: LeaveRequest,
): ShiftSearchRequest => {
    const { startDate: newStartDate, endDate: newEndDate } = calculateStartEndDateForLeaveRequest(leaveRequest);
    return shiftAPISearchRequest(formatToLocalDate(newStartDate), formatToLocalDate(newEndDate), employeeId, planningShiftStatus);
};

export const shiftAPISearchRequest = (startDate: LocalDate, endDate: LocalDate, employeeId: number, statuses?: ShiftStatus[]): ShiftSearchRequest => {
    return {
        rangeDates: [
            {
                start: new Date(format(startDate, config.DEFAULT_DATE_HOURS_SECONDS_FORMAT)),
                end: new Date(format(endDate, config.DEFAULT_DATE_HOURS_SECONDS_FORMAT)),
            },
        ],
        employeeIds: [employeeId],
        statuses: statuses ?? undefined,
    };
};

const calculateStartEndDateForLeaveRequest = (
    leaveRequest: LeaveRequest,
): {
    startDate: Date;
    endDate: Date;
} => {
    const startDate = new Date(leaveRequest.startDate);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const endDate = new Date(leaveRequest.endDate!);

    if (leaveRequest.leaveType.unitType === UnitType.HOURS) {
        setStartTimeAndEndTime(startDate, endDate, leaveRequest.startTimeInMinutes ?? 0, leaveRequest.endTimeInMinutes ?? 0);
    } else if (leaveRequest.leaveType.unitType === UnitType.DAYS) {
        setDayPeriodTime(startDate, endDate, leaveRequest.startTimePeriod, leaveRequest.endTimePeriod);
    }

    return { startDate, endDate };
};

export const setStartTimeAndEndTime = (startDate: Date, endDate: Date, startTimeInMinutes: number, endTimeInMinutes: number): void => {
    const { hours: startHours, minutes: startMinutes } = getHoursMinutesFromMinutes(startTimeInMinutes);
    const { hours: endHours, minutes: endMinutes } = getHoursMinutesFromMinutes(endTimeInMinutes);

    startDate.setHours(startHours, startMinutes);
    endDate.setHours(endHours, endMinutes);
};

export const setDayPeriodTime = (startDate: Date, endDate: Date, startTimePeriod: DayPeriod, endTimePeriod: DayPeriod): void => {
    const setStartTime = (date: Date) => {
        switch (startTimePeriod) {
            case DayPeriod.AFTERNOON:
                date.setHours(12, 0, 0, 0);
                break;
            case DayPeriod.MORNING:
            case DayPeriod.ALL_DAY:
                date.setHours(0, 0, 0, 0);
                break;
        }
    };

    const setEndTime = (date: Date) => {
        switch (endTimePeriod) {
            case DayPeriod.MORNING:
                date.setHours(12, 0, 0, 0);
                break;
            case DayPeriod.AFTERNOON:
            case DayPeriod.ALL_DAY:
                date.setDate(date.getDate() + 1);
                date.setHours(0, 0, 0, 0); // Set the time to midnight next day
                break;
        }
    };

    setStartTime(startDate);
    setEndTime(endDate);
};

type ApproveLeaveRequestParams = {
    leaveRequest: LeaveRequest;
    grantedPolicies: EmployeePolicy[];
    setConflictingShifts: Dispatch<SetStateAction<Shift[]>>;
    setShiftReleaseRequest: Dispatch<SetStateAction<ShiftReleaseRequest | undefined>>;
    setActiveLeaveRequest: Dispatch<SetStateAction<LeaveRequest | undefined>>;
    setRefetch: Dispatch<SetStateAction<boolean>>;
    refetch: boolean;
    setIsLeaveConflictDialogOpen?: Dispatch<SetStateAction<boolean>>;
};

// Update the function to use the parameters object
export const handleApproveLeaveRequest = ({
    leaveRequest,
    grantedPolicies,
    setConflictingShifts,
    setShiftReleaseRequest,
    setActiveLeaveRequest,
    setRefetch,
    refetch,
    setIsLeaveConflictDialogOpen = () => {
        return;
    },
}: ApproveLeaveRequestParams): void => {
    if (!setConflictingShifts) {
        return;
    }
    const planningShiftStatus = [ShiftStatus.SHIFT_DRAFT, ShiftStatus.SHIFT_PUBLISHED];
    const shiftSearchRequest = createShiftSearchRequestFromLeaveRequest(leaveRequest.employee.id, planningShiftStatus, leaveRequest);
    if (couldHandleConflicts(leaveRequest, grantedPolicies)) {
        const controller = new AbortController();
        getEmployeeShifts(shiftSearchRequest, controller.signal)
            .then(res => {
                if (hasConflictingShifts(res)) {
                    handleConflictingShifts(
                        res[0],
                        shiftSearchRequest,
                        leaveRequest,
                        setConflictingShifts,
                        setIsLeaveConflictDialogOpen,
                        setShiftReleaseRequest,
                        setActiveLeaveRequest,
                    );
                } else {
                    approvePendingLeaveRequestAndRefetch(refetch, leaveRequest, setRefetch);
                }
            })
            .catch(handleError);
    } else {
        approvePendingLeaveRequest(leaveRequest.id).then(() => {
            showSnackbar(t('request_leave_dialog.end_date_cant_be_empty'), 'error');
            setRefetch(!refetch);
        });
    }
};

export const onCancelMedicalLeaveType = (leaveRequest: LeaveRequest, reloadView?: () => void): void => {
    if (leaveRequest.requestStatus === 'APPROVED') {
        cancelApprovedLeaveRequest(leaveRequest.id)
            .catch(() => {
                showSnackbar(t('request_leave_dialog.messages.could_not_cancel_leave_request'), 'error');
            })
            .finally(() => {
                if (reloadView) {
                    reloadView();
                }
            });
        return;
    }
    cancelPendingLeaveRequest(leaveRequest.id)
        .catch(() => {
            showSnackbar(t('request_leave_dialog.messages.could_not_cancel_leave_request'), 'error');
        })
        .finally(() => {
            if (reloadView) {
                reloadView();
            }
        });
};

export const isOverlappingExceedsLimitInDays = (startDate: Date, endDate: Date): boolean => {
    const differencesInDays = differenceInDays(endDate, startDate);
    const maxDaysSupported = 30;
    return differencesInDays > maxDaysSupported;
};

/**
 * Determines whether we can fetch the employee shifts or not.
 * If there is not leaveType or the leave type is medical and the end date is empty, we should not fetch the employee shifts.
 * Also, if there is no permission to use the endpoint we should not fetch it
 *
 * @param {LeaveType} leaveType - The leave type.
 * @param {Date | undefined} endDate - The end date for the leave.
 * @param {EmployeePolicy[]} policies - An array of employee policies.
 * @returns {boolean} True if employee shifts should be fetched, false otherwise.
 */
export const shouldFetchEmployeeShifts = (leaveType: LeaveType, endDate: Date | undefined, policies: EmployeePolicy[]): boolean =>
    leaveType && !isLeaveMedicalTypeEndDateEmpty(leaveType, endDate) && (hasManageShiftsPolicy(policies) || hasViewShiftsPolicy(policies));

export const checkConflictsLeaveRequest = (
    policies: EmployeePolicy[],
    employeeId: number,
    leaveRequest: LeaveRequest,
): Promise<
    | {
          shifts: Shift[];
          shiftReleaseRequest: ShiftReleaseRequest;
      }
    | undefined
> => {
    return new Promise((resolve, reject) => {
        if (shouldFetchEmployeeShifts(leaveRequest.leaveType, toDate(leaveRequest?.endDate), policies)) {
            const shiftStatuses = [ShiftStatus.SHIFT_DRAFT, ShiftStatus.SHIFT_PUBLISHED];
            const shiftSearchRequest = createShiftSearchRequestFromLeaveRequest(employeeId, shiftStatuses, leaveRequest);
            getEmployeeShifts(shiftSearchRequest)
                .then(res => {
                    if (res[0]?.shifts?.length) {
                        const shiftReleaseRequest: ShiftReleaseRequest = {
                            employeeId: (shiftSearchRequest.employeeIds ?? [])[0],
                            rangeDates: shiftSearchRequest.rangeDates,
                        };
                        resolve({ shifts: res[0].shifts, shiftReleaseRequest: shiftReleaseRequest });
                    } else {
                        resolve(undefined);
                    }
                })
                .catch(reject);
        } else {
            resolve(undefined);
        }
    });
};
export const REQUEST_INCREMENT = [RequestIncrement.MIN_1, RequestIncrement.MIN_5, RequestIncrement.MIN_15, RequestIncrement.MIN_30, RequestIncrement.MIN_60];
export const defaultLeaveType: LeaveType = {
    id: 0,
    title: '',
    policies: [],
    employeeRequestMessage: '',
    attachmentRequiredAfterDays: undefined,
    requestIncrement: RequestIncrement.MIN_5,
    leaveActivityType: undefined as unknown as LeaveActivityType,
    unitType: UnitType.DAYS,
    displayUnitType: UnitType.DAYS,
    carryoverType: CarryoverType.YEARLY_WITH_CARRYOVER,
    prorateBasedOnContract: true,
    prorateBasedOnEmployeeStartDate: true,
    roundingType: RoundingType.NEAREST_1_DECIMAL,
    includeInPayroll: true,
    order: 0,
    displayCounter: 'ALWAYS',
    allowanceType: AllowanceType.NOT_UNLIMITED,
    requestCalculationType: RequestCalculationType.BASED_ON_CONTRACT_AND_PUBLIC_HOLIDAYS,
    maxDurationInMinutes: undefined,
    availableInSelfService: true,
    color: '#ffffff',
    allowOvertime: true,
    allowAttachments: true,
};

export function createDefaultLeaveTypePolicy(): LeaveTypePolicy {
    return {
        name: '',
        allowanceInMinutes: 0,
        leaveType: defaultLeaveType,
    };
}

export const isLeaveType = (status: ShiftStatus): boolean => {
    return isLeavePending(status) || isLeaveApproved(status);
};
export const isLeaveApproved = (status: ShiftStatus): boolean => {
    return status === ShiftStatus.LEAVE_APPROVED;
};

export const isShift = (status: ShiftStatus): boolean => {
    return status === ShiftStatus.SHIFT_DRAFT || status === ShiftStatus.SHIFT_PUBLISHED;
};

export const isShiftPublished = (status: ShiftStatus): boolean => {
    return status === ShiftStatus.SHIFT_PUBLISHED;
};

export const isLeavePending = (status: ShiftStatus): boolean => {
    return status === ShiftStatus.LEAVE_PENDING;
};
