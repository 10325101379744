import { DialogContainerProps } from '@/Components/dialog-container/DialogContainer';
import { Employee } from '@/domain/employee/Employee.model';
import { ReviewTemplate } from '@/domain/review-template/ReviewTemplate.model';
import { ReviewOneShotCreationMutation } from '@/domain/review/Review.model';
import { createOneShotReview } from '@/domain/review/Review.service';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { getLabelTranslation } from '@/utils/language.util';
import { useSearchReviewTemplates } from '@/hooks/review-template/ReviewTemplate.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { DialogWrapper } from '@/Components/dialog-wrapper/DialogWrapper';
import { StateHandler } from '@/Components/state-handler/StateHandler';

type OneShotReviewDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    onSuccess: (employeeReviewId: number | undefined, employeeId: number) => void;
    /** The employee to review */
    employeeId: number | undefined;
};

const schema = yup.object().shape({
    name: yup.string().required(),
    reviewTemplate: yup.object().default(undefined).required().shape({
        label: yup.string().required(),
        value: yup.number().required(),
    }),
    manager: yup.object().default(undefined).required().shape({
        label: yup.string().required(),
        value: yup.number().required(),
    }),
    employee: yup
        .object()
        .shape({
            label: yup.string().required(),
            value: yup.number().required(),
        })
        .required(),
});

type OneShotReviewFormValues = yup.InferType<typeof schema>;

export const OneShotReviewDialog: FC<OneShotReviewDialogProps> = ({ onSuccess, employeeId, onClose, ...rest }) => {
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const {
        data: reviewTemplates,
        isError: isReviewTemplatesError,
        error: reviewTemplatesError,
        isLoading: isReviewTemplatesLoading,
    } = useSearchReviewTemplates({ reviewType: 'ONE_SHOT' });
    const { data: employees, error: employeesError, isError: isEmployeesError, isLoading: isEmployeesLoading } = useGetEmployees();
    const employeeToReview = employees?.find(employee => employee.id === employeeId);

    const error = reviewTemplatesError || employeesError;
    const isLoading = isReviewTemplatesLoading || isEmployeesLoading;
    const isError = isReviewTemplatesError || isEmployeesError;

    return (
        <DialogWrapper {...rest} onClose={() => onClose()} open={true} header={t('reviews.new_review_one_shot.dialog_title')} maxWidth='sm'>
            <DialogContent>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    <OneShotReviewDialogForm
                        reviewTemplates={reviewTemplates ?? []}
                        employees={employees ?? []}
                        onSuccess={onSuccess}
                        manager={currentEmployee}
                        employeeToReview={employeeToReview}
                    />
                </StateHandler>
            </DialogContent>
            <DialogActions>
                <Button type='submit' form={'one-shot-review-form'}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type OneShotReviewDialogFormProps = {
    employees: Employee[];
    reviewTemplates: ReviewTemplate[];
    onSuccess: (employeeReviewId: number | undefined, employeeId: number) => void;
    employeeToReview: Employee | undefined;
    manager: Employee | undefined;
};

const OneShotReviewDialogForm: FC<OneShotReviewDialogFormProps> = ({ employees, reviewTemplates, manager, onSuccess, employeeToReview }) => {
    const reviewTemplateOptions = reviewTemplates.map(template => ({
        label: getLabelTranslation(template.name),
        value: template.id,
    }));
    const employeeOptions = employees
        .map(employee => ({
            label: employee.displayName,
            value: employee.id,
        }))
        .filter(employee => employee.value !== manager?.id);

    const getDefaultValues = (): Partial<OneShotReviewFormValues> => ({
        employee: employeeToReview ? { label: employeeToReview.displayName, value: employeeToReview.id } : undefined,
        manager: manager ? { label: manager.displayName, value: manager.id } : undefined,
    });

    const { handleSubmit, control } = useForm<OneShotReviewFormValues>({
        resolver: yupResolver(schema),
        defaultValues: getDefaultValues(),
    });

    const handleSave = async (values: OneShotReviewFormValues) => {
        const request: ReviewOneShotCreationMutation = {
            name: values.name,
            reviewTemplateId: values.reviewTemplate.value,
            managerIds: [values.manager.value],
            employeeId: values.employee.value,
        };

        try {
            const review = await createOneShotReview(request);
            onSuccess(review?.employeeReviews?.[0]?.id, values.employee.value);
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack gap={1} component='form' id='one-shot-review-form' onSubmit={handleSubmit(handleSave, console.error)}>
            <Controller
                name='employee'
                control={control}
                render={({ field: { onChange, ...restField }, fieldState }) => (
                    <FormControlLabel
                        label={t('general.employee')}
                        control={
                            <Autocomplete
                                {...restField}
                                disabled={!!employeeToReview}
                                onChange={(_, value) => onChange(value)}
                                fullWidth
                                options={employeeOptions}
                                getOptionLabel={option => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                            />
                        }
                    />
                )}
            />

            <Controller
                name='name'
                control={control}
                render={({ field, fieldState }) => (
                    <FormControlLabel
                        label={t('reviews.new_review_one_shot.name')}
                        control={<TextField {...field} fullWidth error={!!fieldState.error} helperText={fieldState.error?.message} />}
                    />
                )}
            />
            <Controller
                name='reviewTemplate'
                control={control}
                render={({ field: { onChange, ...restField }, fieldState }) => (
                    <FormControlLabel
                        label={t('reviews.new_review_one_shot.template')}
                        control={
                            <Autocomplete
                                {...restField}
                                onChange={(_, value) => onChange(value)}
                                fullWidth
                                options={reviewTemplateOptions}
                                getOptionLabel={option => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                            />
                        }
                    />
                )}
            />
            <Controller
                name='manager'
                control={control}
                render={({ field: { onChange, ...restField }, fieldState }) => (
                    <FormControlLabel
                        label={t('reviews.new_review_one_shot.manager')}
                        control={
                            <Autocomplete
                                {...restField}
                                onChange={(_, value) => onChange(value)}
                                fullWidth
                                options={employeeOptions}
                                getOptionLabel={option => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                            />
                        }
                    />
                )}
            />
        </Stack>
    );
};
