import { UnitType } from '@/domain/date/Date.model';
import { convertLeavesMinutesToUnit } from '@/domain/leave-request/LeaveRequest.service';
import { AllowanceType, LeaveActivityType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { shadows } from '@/theme/shadows';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type LeaveTypeCardProps = {
    employeeId: number;
    leaveType: LeaveType;
    availableAmountInMinutes: number;
    usedAmountInMinutes: number;
    allowanceInMinutes: number;
    availableAmountInDays: number;
    usedAmountInDays: number;
    allowanceInDays: number;
};
export const LeaveTypeCard: FC<LeaveTypeCardProps> = ({
    employeeId,
    leaveType,
    availableAmountInMinutes,
    usedAmountInMinutes,
    allowanceInMinutes,
    availableAmountInDays,
    usedAmountInDays,
    allowanceInDays,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getLimitedAmountText = (
        leaveType: LeaveType,
        allowanceInMinutes: number,
        availableAmountInMinutes: number,
        allowanceInDays: number,
        availableAmountInDays: number,
    ) => {
        const availableAmount = convertLeavesMinutesToUnit({
            input: leaveType.displayUnitType === UnitType.DAYS ? availableAmountInDays : availableAmountInMinutes,
            outputUnit: leaveType.displayUnitType,
            roundingType: leaveType.roundingType,
        });

        const allowance = convertLeavesMinutesToUnit({
            input: leaveType.displayUnitType === UnitType.DAYS ? allowanceInDays : allowanceInMinutes,
            outputUnit: leaveType.displayUnitType,
            roundingType: leaveType.roundingType,
        });

        return (
            <Typography variant='body2'>
                {t('duration.formatDuration', {
                    duration: availableAmount,
                    unit: leaveType.displayUnitType,
                })}
                {' / '}
                {t('duration.formatDuration', {
                    duration: allowance,
                    unit: leaveType.displayUnitType,
                })}
            </Typography>
        );
    };

    const getUnlimitedAmountText = (
        leaveType: LeaveType,
        availableAmountInMinutes: number,
        usedAmountInMinutes: number,
        availableAmountInDays: number,
        usedAmountInDays: number,
    ) => {
        const amountInMinutes = leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION ? availableAmountInMinutes : usedAmountInMinutes;
        const amountInDays = leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION ? availableAmountInDays : usedAmountInDays;
        const amount = convertLeavesMinutesToUnit({
            input: leaveType.displayUnitType === UnitType.DAYS ? amountInDays : amountInMinutes,
            outputUnit: leaveType.displayUnitType,
            roundingType: leaveType.roundingType,
        });
        return (
            <Typography variant='body2'>
                {t('duration.formatDuration', {
                    duration: amount,
                    unit: leaveType.displayUnitType,
                })}
            </Typography>
        );
    };

    return (
        <Card
            sx={{
                cursor: 'pointer',
                minWidth: 140,
                '&:hover': {
                    boxShadow: shadows[2],
                },
            }}
            onClick={() => {
                navigate(`/profile/${employeeId}/leaves/history/${leaveType.id}`);
            }}
        >
            <CardContent>
                <Stack direction='column' spacing={1}>
                    <Typography variant='body1bold' noWrap>
                        {leaveType.title}
                    </Typography>
                    <>
                        {leaveType.allowanceType !== AllowanceType.UNLIMITED &&
                            leaveType.leaveActivityType !== LeaveActivityType.TIMESHEET_COMPENSATION &&
                            getLimitedAmountText(leaveType, allowanceInMinutes, availableAmountInMinutes, allowanceInDays, availableAmountInDays)}
                        {(leaveType.allowanceType === AllowanceType.UNLIMITED || leaveType.leaveActivityType === LeaveActivityType.TIMESHEET_COMPENSATION) &&
                            getUnlimitedAmountText(leaveType, availableAmountInMinutes, usedAmountInMinutes, availableAmountInDays, usedAmountInDays)}
                    </>
                </Stack>
            </CardContent>
        </Card>
    );
};
