import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { FC } from 'react';
import { Document } from '@/domain/document/Document.model';
import { File01Icon, Link01Icon } from 'hugeicons-react';

type Props = {
    index: number;
    onClickItem: () => void;
    document: Document;
};

export const DocumentListItem: FC<Props> = ({ index, onClickItem, document }) => {
    return (
        <ListItemButton key={index} onClick={() => onClickItem()} divider sx={{ py: 2, px: 1, gap: 1 }}>
            <ListItemIcon sx={{ minWidth: 'auto', color: 'inherit' }}>
                {document.documentType === 'DOCUMENT' ? <File01Icon size={20} /> : <Link01Icon size={20} />}
            </ListItemIcon>
            <ListItemText
                primary={document.name}
                primaryTypographyProps={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                sx={{ m: 0 }}
            />
        </ListItemButton>
    );
};
