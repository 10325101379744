import { DialogContainer } from '@/Components/dialog-container/DialogContainer';
import { FieldLocalDate } from '@/Components/form/field-date/FieldDate';
import { CONTRACT_PROBATION_PERIOD_MONTHS, ContractType, Employment } from '@/domain/employment/Employment.model';
import { getEmploymentSchema } from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';
import { formatToLocalDate, getLocalDateTestConfig, LocalDate } from '@/utils/datetime.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, FormHelperText, MenuItem, Select, Stack } from '@mui/material';
import { addMonths, subDays } from 'date-fns';
import { t } from 'i18next';
import { FC, useEffect } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

type ModifyContractDialogProps = {
    setContractDialogOpen: (open: boolean) => void;
    onSaveContract: (modifyContractDialogForm: ModifyContractDialogFormValue) => void;
    contract: Employment;
};

export const ModifyContractDialog: FC<ModifyContractDialogProps> = ({ setContractDialogOpen, onSaveContract, contract }) => {
    const schema = getSchema();
    const formMethods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            startDate: contract.startDate,
            contractType: contract.contractType,
            probationEndDate: contract.probationEndDate,
            endDate: contract.endDate,
        },
    });
    const { setValue, watch, getFieldState, handleSubmit } = formMethods;

    const contractStartDate = watch('startDate');
    const contractType = watch('contractType');

    // When the contract start date is changed, the probation end date is automatically set to 3 months after the contract start date
    // unless the probation end date has been changed manually
    useEffect(() => {
        if (contractStartDate && !getFieldState('probationEndDate').isDirty && getFieldState('startDate').isDirty) {
            let probationEndDate = addMonths(contractStartDate, CONTRACT_PROBATION_PERIOD_MONTHS);
            // The probation end date not included the last day of the probation period
            probationEndDate = subDays(probationEndDate, 1);
            setValue('probationEndDate', formatToLocalDate(probationEndDate));
        }
    }, [contractStartDate, getFieldState, setValue]);

    const handleSave = handleSubmit(contractCreateRequest => {
        onSaveContract({
            ...contractCreateRequest,
            startDate: contractCreateRequest.startDate,
            probationEndDate: contractCreateRequest.probationEndDate,
        });
    }, console.error);

    return (
        <DialogContainer open={true} onClose={() => setContractDialogOpen(false)} onSave={handleSave} title={t('employee.employment.contract.modify_contract')}>
            <FormProvider {...formMethods}>
                <Stack direction='column' spacing={2}>
                    <FormControlLabel
                        label={t('employee.employment.start_date')}
                        control={<FieldLocalDate control={formMethods.control} name={'startDate'} />}
                    />
                    <FormControlLabel
                        label={t('employee.employment.probation_end_date')}
                        control={<FieldLocalDate control={formMethods.control} name={'probationEndDate'} />}
                    />
                    <EmploymentContractTypeField contractType={contract?.contractType} />
                    {contractType && contractType !== ContractType.PERMANENT && (
                        <FormControlLabel
                            label={t('employee.employment.contract_end_date')}
                            control={<FieldLocalDate control={formMethods.control} name={'endDate'} />}
                        />
                    )}
                </Stack>
            </FormProvider>
        </DialogContainer>
    );
};

const getSchema = () => {
    return yup
        .object()
        .shape({
            endDate: yup.string<LocalDate>().default(getNull()).nullable().test(getLocalDateTestConfig()),
        })
        .concat(getEmploymentSchema().pick(['startDate', 'probationEndDate', 'contractType']));
};

export type ModifyContractDialogFormValue = yup.InferType<ReturnType<typeof getSchema>>;

type ContractTypeFieldProps = {
    contractType?: ContractType;
};
const EmploymentContractTypeField: FC<ContractTypeFieldProps> = ({ contractType }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext<{
        contractType: ContractTypeFieldProps['contractType'];
    }>();

    const formValueName = 'contractType';

    const getContractType = () => {
        const contractTypeMap = new Map<string, string>();
        Object.values(ContractType).forEach(contractType => {
            contractTypeMap.set(contractType, t('employee.employment.contract_type', { context: contractType }));
        });
        return [...(contractTypeMap?.entries() ?? [])];
    };

    return (
        <FormControlLabel
            label={t('domain.employee_field_type.contract_type') + '*'}
            control={
                <>
                    <Controller
                        render={({ field: { ref, value, ...rest } }) => (
                            <Select {...rest} fullWidth value={value ?? getNull()} inputRef={ref} error={!!errors[formValueName]}>
                                {getContractType().map(([key, value]) => {
                                    return (
                                        <MenuItem key={key} value={key ?? ''}>
                                            {value}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                        name={formValueName}
                        control={control}
                        defaultValue={contractType}
                    />
                    {!!errors[formValueName] && <FormHelperText error={!!errors[formValueName]}>{errors[formValueName].message?.toString()}</FormHelperText>}
                </>
            }
        />
    );
};
