import { LeaveType, UserLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';

export enum LeavesActionType {
    LEAVE_TYPES_LOADED = 'LEAVE_TYPES_LOADED',
    LEAVE_TYPE_CREATED = 'LEAVE_TYPE_CREATED',
    LEAVE_TYPE_UPDATED = 'LEAVE_TYPE_UPDATED',
    MY_LEAVE_REQUESTS_LOADED = 'MY_LEAVE_REQUESTS_LOADED',
    USER_LEAVE_TYPE_POLICY_LOADED = 'USER_LEAVE_TYPE_POLICY_LOADED',
}

export interface UserLeaveTypePolicyLoaded {
    type: LeavesActionType.USER_LEAVE_TYPE_POLICY_LOADED;
    userLeaveTypePolicies: UserLeaveTypePolicy[];
}

export interface LeaveTypesLoaded {
    type: LeavesActionType.LEAVE_TYPES_LOADED;
    leaveTypes: LeaveType[];
}

export interface LeaveTypeCreated {
    type: LeavesActionType.LEAVE_TYPE_CREATED;
    leaveType: LeaveType;
}

export interface LeaveTypeUpdated {
    type: LeavesActionType.LEAVE_TYPE_UPDATED;
    leaveType: LeaveType;
}

export interface MyLeaveRequestsLoaded {
    type: LeavesActionType.MY_LEAVE_REQUESTS_LOADED;
    leaveRequests: LeaveRequest[];
}

export type LeavesActions = LeaveTypesLoaded | LeaveTypeCreated | LeaveTypeUpdated | MyLeaveRequestsLoaded | UserLeaveTypePolicyLoaded;
