import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ReviewTemplatesPage } from '@/page/setting/review/template/ReviewTemplatesPage';
import { ReviewSkillSettingPage } from '@/page/setting/review/skill/ReviewSkillSettingPage';
import { ReviewSkillCategorySettingPage } from '@/page/setting/review/skill-category/ReviewSkillCategorySettingPage';
import { ReviewRatingScaleSettingPage } from '@/page/setting/review/rating-scale/ReviewRatingScaleSettingPage';
import { ReviewSettingsTabs } from './ReviewSettingPageTab';
import { Stack } from '@mui/material';

enum ReviewsTabs {
    TEMPLATES_TAB = 'templates',
    SKILLS_TAB = 'skills',
    CATEGORIES_TAB = 'categories',
    SCALES_TAB = 'scales',
}

export const ReviewSettingPage: FC = () => {
    return (
        <Stack gap={2} flex={1}>
            <ReviewSettingsTabs />
            <ActiveTab />
        </Stack>
    );
};

const ActiveTab: FC = () => {
    const { tab } = useParams();

    if (tab === ReviewsTabs.TEMPLATES_TAB) {
        return <ReviewTemplatesPage />;
    } else if (tab === ReviewsTabs.SKILLS_TAB) {
        return <ReviewSkillSettingPage />;
    } else if (tab === ReviewsTabs.CATEGORIES_TAB) {
        return <ReviewSkillCategorySettingPage />;
    } else if (tab === ReviewsTabs.SCALES_TAB) {
        return <ReviewRatingScaleSettingPage />;
    } else {
        return <ReviewTemplatesPage />;
    }
};
