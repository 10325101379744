import { TableSection } from '@/Components/section/TableSection/TableSection';
import { SectionActionButton, SectionColumn, SectionRow } from '@/Components/section/types';
import {
    EmployeeCustomSectionRowDialog,
    EmployeeSectionRowFormValues,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeCustomSectionRowDialog';
import { canManageEmployeeCustomSections, canManagePendingEmployeeCustomSections } from '@/domain/permission/Permission.service';
import { getLabelTranslation } from '@/utils/language.util';
import { EmployeeSection } from '@/domain/employee-section/EmployeeSection.model';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { ICellRendererParams } from '@ag-grid-community/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/stores/store';
import { EmployeeFieldMoreButton } from '../EmployeeFieldMoreButton/EmployeeFieldMoreButton';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { useNavigate } from 'react-router-dom';
import { getSectionAction } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { EmployeeProfileChangeRow } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { getSectionActionButton } from '@/domain/employee/Employee.service';
import { CustomSectionRowFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeCustomSectionRowDialog.hook';
import { ConfirmDialog } from '@/Components/confirmation-dialog/ConfirmDialog';

export const EmployeeCustomTableSection: FC<{
    section: EmployeeSection;
    pendingRows: EmployeeProfileChangeRow[];
    sectionDefinition: SectionDefinition;
    onSave(rowId?: number): (values: CustomSectionRowFormValues) => Promise<void>;
    onDelete?(rowId: number, isPending: boolean): Promise<void>;
}> = ({ section, pendingRows, sectionDefinition, onSave, onDelete = () => Promise.resolve() }) => {
    const { rows: sectionRows } = section;
    const navigate = useNavigate();
    const [currentSectionRow, setCurrentSectionRow] = useState<EmployeeSectionRowFormValues>();
    const [rowToDelete, setRowToDelete] = useState<SectionRow>();
    const { t } = useTranslation();

    const policies: EmployeePolicy[] = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const canApprove = canManageEmployeeCustomSections(policies, section.employeeId, section.sectionDefinition.id);
    const canRequestApproval = canManagePendingEmployeeCustomSections(policies, section.employeeId, section.sectionDefinition.id);

    const columns: SectionColumn[] = sectionDefinition.fields.map(field => {
        return {
            title: getLabelTranslation(field.name),
            type: field.type,
        };
    });

    let rows: SectionRow[] = sectionRows?.map(row => {
        return {
            id: row.id,
            isPending: false,
            fields: row.fields?.map(field => ({
                title: getLabelTranslation(field.sectionFieldDefinition.name),
                required: field.sectionFieldDefinition.mandatory,
                type: field.sectionFieldDefinition.type,
                stringValue: field.stringValue,
                numberValue: field.numberValue,
                employeeSectionId: section?.id,
                dateValue: field.dateValue,
                employeeReferences: field.employeeReferences,
                customListItemReferences: field.customListItemReferences,
                documents: field.documents,
                fieldType: 'EMPLOYEE_CUSTOM_FIELD',
            })),
        };
    });

    if (pendingRows.length) {
        const pendingRowToAppend: SectionRow[] = pendingRows.map(row => {
            return {
                id: row.id,
                isPending: true,
                fields: row.fields?.map(field => ({
                    title: getLabelTranslation(field.sectionFieldDefinition.name),
                    required: field.sectionFieldDefinition.mandatory,
                    type: field.sectionFieldDefinition.type,
                    stringValue: field.stringValue,
                    numberValue: field.numberValue,
                    employeeSectionId: section?.id,
                    dateValue: field.dateValue,
                    employeeReferences: field.employeeReferences,
                    customListItemReferences: field.customListItemReferences,
                    documents: field.documents,
                    fieldType: 'EMPLOYEE_CUSTOM_FIELD',
                })),
            };
        });
        rows = [...(rows ?? []), ...pendingRowToAppend];
    }
    const handleDelete = async (rowId: number, isPending: boolean) => {
        await onDelete(rowId, isPending);
        setRowToDelete(undefined);
    };

    const handleSave = (rowId?: number) => async (values: CustomSectionRowFormValues) => {
        await onSave(rowId)(values);
        setCurrentSectionRow(undefined);
    };

    const handleEditClicked = (row: SectionRow) => () => {
        if (!row.isPending && canApprove) {
            setCurrentSectionRow(sectionRows?.find(r => r.id === row.id));
        }
        if (row.isPending && canRequestApproval) {
            const currentPendingRow = pendingRows.find(r => r.id === row.id);
            if (!currentPendingRow) {
                return;
            }
            setCurrentSectionRow({
                id: currentPendingRow.id,
                fields: currentPendingRow.fields,
                // todo: this is a temporary solution, we need to find a better way to handle the order
                order: 0,
            });
        }
    };

    const handleDeleteClicked = (row: SectionRow) => () => {
        setRowToDelete(row);
    };

    const openMutationDialogButton: SectionActionButton = {
        title: t('general.add'),
        onClick: () => {
            setCurrentSectionRow({
                fields: [],
                order: sectionRows?.length,
            });
        },
    };

    const seePendingButton: SectionActionButton = {
        title: t('employee.sections.see_pending_changes'),
        onClick: () => {
            navigate('/people/employee-requests');
        },
    };

    const action = getSectionAction({ canApprove, canRequestApproval });
    const actionButton = getSectionActionButton(action, openMutationDialogButton, seePendingButton);

    const actionMenuCellRenderer =
        canApprove || canRequestApproval
            ? (params: ICellRendererParams<SectionRow>) => {
                  const mutationEnabled = params.data?.isPending ? canRequestApproval : canApprove;
                  const data = params.data;
                  if (!data) {
                      return;
                  }
                  return (
                      <EmployeeFieldMoreButton
                          onEditClicked={canApprove && data.isPending ? seePendingButton.onClick : handleEditClicked(data)}
                          onDeleteClicked={handleDeleteClicked(data)}
                          disabled={false}
                          editDisabled={!mutationEnabled}
                          deleteEnabled={mutationEnabled}
                          cancelEnabled={false}
                      />
                  );
              }
            : undefined;

    return (
        <>
            <TableSection
                sectionTitle={getLabelTranslation(sectionDefinition.name)}
                columns={columns}
                rows={rows}
                actionButton={actionButton}
                actionMenuCellRenderer={actionMenuCellRenderer}
            />
            {currentSectionRow && (
                <EmployeeCustomSectionRowDialog
                    sectionId={section.id}
                    currentSectionRow={currentSectionRow}
                    sectionDefinition={sectionDefinition}
                    onSave={handleSave(currentSectionRow?.id)}
                    onClose={() => setCurrentSectionRow(undefined)}
                />
            )}
            {rowToDelete && (
                <ConfirmDialog
                    open
                    title={t('general.confirmation_dialog.default_title')}
                    content={t('general.confirmation_dialog.default_content')}
                    onConfirm={() => handleDelete(rowToDelete.id, rowToDelete.isPending)}
                    onClose={() => setRowToDelete(undefined)}
                />
            )}
        </>
    );
};
