import { RouteObject } from 'react-router-dom';
import { PageContainer } from '@/routes/PageContainer';
import { CircularProgress } from '@mui/material';
import { lazy, Suspense } from 'react';
import { RouterBoundary } from '@/routes/RouterBoundary';

// eslint-disable-next-line react-refresh/only-export-components
const PlanningPage = lazy(() => import('./PlanningPage'));

export const planningRoutes: RouteObject[] = [
    {
        path: 'planning',
        element: (
            <PageContainer
                breadCrumb={[
                    {
                        nameKey: 'pages.planning',
                    },
                ]}
            >
                <Suspense fallback={<CircularProgress />}>
                    <PlanningPage />
                </Suspense>
            </PageContainer>
        ),
        errorElement: <RouterBoundary />,
    },
];
