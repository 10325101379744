import { UseMutationResult } from '@/page/Query.type';
import { Document, EmployeeDocumentCreationMutation } from '@/domain/document/Document.model';
import { useCallback, useState } from 'react';
import { createEmployeeDocuments } from '@/domain/document/Document.service';

export const useCreateEmployeeDocuments = (): UseMutationResult<Document[], EmployeeDocumentCreationMutation[]> => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const mutate = useCallback(async (mutationVariables: EmployeeDocumentCreationMutation[]) => {
        setIsPending(true);
        try {
            const documents = await createEmployeeDocuments(mutationVariables);
            setIsPending(false);
            return documents;
        } catch (error) {
            setError(error);
        }
    }, []);

    return {
        mutate: mutate as (mutationVariables: EmployeeDocumentCreationMutation[]) => Promise<Document[]>,
        isPending,
        isError: !!error,
        error,
    };
};
