import { Label } from '../label/Label.model';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { CustomList } from '@/domain/custom-list/CustomList.model';

export type ThirdPartyMapping = {
    id: number;
    mappingType: ThirdPartyMappingType;
    customList: CustomList | undefined;
    sectionDefinition: SectionDefinition | undefined;
};

export enum ThirdPartyMappingType {
    EMPLOYEE_CODE = 'EMPLOYEE_CODE',
    EMPLOYEE_GENDER = 'EMPLOYEE_GENDER',
    EMPLOYEE_MARITAL_STATUS = 'EMPLOYEE_MARITAL_STATUS',
    EMPLOYMENT_STATUS = 'EMPLOYMENT_STATUS',
    EMPLOYMENT_CONTRACT_TYPE = 'EMPLOYMENT_CONTRACT_TYPE',
    EMPLOYMENT_TERMINATION_TYPE = 'EMPLOYMENT_TERMINATION_TYPE',
    EMPLOYMENT_TERMINATION_REASON = 'EMPLOYMENT_TERMINATION_REASON',
    LANGUAGE_CODE = 'LANGUAGE_CODE',
    COUNTRY_CODE = 'COUNTRY_CODE',
    LOCATION_ID = 'LOCATION_ID',
    DEPARTMENT_ID = 'DEPARTMENT_ID',
    COST_CENTER_ID = 'COST_CENTER_ID',
    CALENDAR_ID = 'CALENDAR_ID',
    JOB_ID = 'JOB_ID',
    JOB_FAMILY_ID = 'JOB_FAMILY_ID',
    AREA_ID = 'AREA_ID',
    TIMESHEET_SETTING_ID = 'TIMESHEET_SETTING_ID',
    WEEKLY_WORKING_TIME_ID = 'WEEKLY_WORKING_TIME_ID',
    WORKING_PATTERN_TEMPLATE_ID = 'WORKING_PATTERN_TEMPLATE_ID',
    CUSTOM_LIST = 'CUSTOM_LIST',
    SECTION_DEFINITION = 'SECTION_DEFINITION',
    LEAVE_TYPE_ID = 'LEAVE_TYPE_ID',
}

export type ThirdPartyMappingMutation = {
    mappingType: ThirdPartyMappingType;
    customListId: number | undefined;
    sectionDefinitionId: number | undefined;
};

export type ThirdPartyMappingValue = {
    id: number;
    mappingId: number;
    mappingType: ThirdPartyMappingType;
    label: Label;
    referenceId: string;
    thirdPartyIdentifiers: ThirdPartyIdentifier[];
};

export type ThirdPartyIdentifier = {
    value: string;
    thirdPartyId: number;
    thirdPartyName: string;
};

export type ThirdPartyMappingValueRequest = {
    value: string;
    thirdPartyId: number;
    referenceId: string;
};
