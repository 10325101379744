import { DayPeriod, UnitType } from '@/domain/date/Date.model';
import { previewLeaveRequest, searchConflictingRequests, searchOverlappingRequests } from '@/domain/leave-request/LeaveRequest.service';
import {
    ConflictLeaveSearchRequest,
    LeaveCreationMutation,
    LeaveRequest,
    LeaveRequestPreview,
    OverlappingLeaveSearchRequest,
} from '@/domain/leave-request/LeaveRequest.model';
import { LeaveRequestFormValues } from '@/page/leave/leave-request-dialog/LeaveRequestForm.schema';

export const hasPartialConflicts = (conflictingRequests: LeaveRequest[]): boolean =>
    !!conflictingRequests?.length && getTotalConflictingRequestsLeavePercentage(conflictingRequests) < 100;

export const hasConflicts = (conflictingRequests: LeaveRequest[]): boolean =>
    !!conflictingRequests?.length && getTotalConflictingRequestsLeavePercentage(conflictingRequests) >= 100;

export const getTotalConflictingRequestsLeavePercentage = (conflictingRequests: LeaveRequest[]): number => {
    return conflictingRequests.reduce((acc, conflictingRequest) => {
        return acc + conflictingRequest.leavePercentage;
    }, 0);
};

export const getLeaveRemainingTimePercentage = (
    conflictingRequests: LeaveRequest[],
    leaveRequestUnitType: UnitType,
    leaveRequestPercentage: number,
): number => {
    const hasConflictingRequests = !!conflictingRequests?.length;
    const isUnitTypeDays = leaveRequestUnitType === UnitType.DAYS;
    if (hasConflictingRequests && isUnitTypeDays && getTotalConflictingRequestsLeavePercentage(conflictingRequests) < 100) {
        return 100 - getTotalConflictingRequestsLeavePercentage(conflictingRequests);
    }
    return leaveRequestPercentage;
};

export const handleSearchOverlapping = (formValues: LeaveRequestFormValues, employeeId: number): Promise<LeaveRequest[]> => {
    const searchRequest: OverlappingLeaveSearchRequest = {
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        dates: formValues.dates ?? [],
        employeeId: employeeId,
        startTimePeriod: formValues.startTimePeriod != DayPeriod.ALL_DAY ? formValues.startTimePeriod : undefined,
        endTimePeriod: formValues.endTimePeriod != DayPeriod.ALL_DAY ? formValues.endTimePeriod : undefined,
        statuses: ['APPROVED'],
    };
    return searchOverlappingRequests({
        ...searchRequest,
    });
};

export const handleSearchConflicting = (updatedLeave: LeaveRequestFormValues, employeeId: number): Promise<LeaveRequest[]> => {
    const searchRequest: ConflictLeaveSearchRequest = {
        startDate: updatedLeave.startDate,
        endDate: updatedLeave.endDate,
        dates: updatedLeave.dates ?? [],
        employeeId: employeeId,
        startTimePeriod: updatedLeave.startTimePeriod != DayPeriod.ALL_DAY ? updatedLeave.startTimePeriod : undefined,
        endTimePeriod: updatedLeave.endTimePeriod != DayPeriod.ALL_DAY ? updatedLeave.endTimePeriod : undefined,
        leavePercentage: updatedLeave.leavePercentage,
        leaveTypeIds: updatedLeave.leaveType?.id ? [updatedLeave.leaveType.id] : [],
    };
    return searchConflictingRequests({
        ...searchRequest,
    });
};

export const handleSearchPreview = (
    formValues: LeaveRequestFormValues,
    conflictingRequests: LeaveRequest[],
    employeeId: number,
    leaveRequestPercentage: number,
): Promise<LeaveRequestPreview> => {
    const request: LeaveCreationMutation = {
        employeeId: employeeId,
        leaveTypeId: formValues.leaveType.id,
        startDate: formValues.startDate,
        endDate: formValues.endDate,
        dates: formValues.dates,
        startTimeInMinutes: formValues.startTimeInMinutes,
        endTimeInMinutes: formValues.endTimeInMinutes,
        comment: formValues.comment,
        startTimePeriod: formValues.startTimePeriod,
        endTimePeriod: formValues.endTimePeriod,
        leavePercentage: getLeaveRemainingTimePercentage(conflictingRequests, formValues?.leaveType.unitType, leaveRequestPercentage),
    };

    return previewLeaveRequest(request);
};
