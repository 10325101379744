import { createCustomList, deleteCustomListById, updateCustomList } from '@/domain/custom-list/CustomList.service';
import { SectionDefinitionDialog } from '@/page/setting/section/SectionDefinitionDialog';
import { SectionDefinitionDraggable } from '@/page/setting/section/SectionDefinitionDraggable';
import { SectionFieldDefinitionDialog } from '@/page/setting/section/SectionFieldDefinitionDialog';
import { showSnackbar } from '@/utils/snackbar.util';
import { Box, Button, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult, OnDragEndResponder } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { LabelListItemFormValues } from '@/Components/label-input-list/LabelInputList';
import {
    SectionDefinition,
    SectionDefinitionUpdateRequest,
    SectionFieldDefinition,
    SectionFieldDefinitionRequest,
} from '@/domain/section-setting/Section.model';
import { handleError } from '@/utils/api.util';
import {
    createSectionDefinition,
    deleteSectionDefinition,
    getSectionDefinitions,
    updateSectionDefinition,
    updateSectionDefinitionsOrder,
} from '@/domain/section-setting/Section.service';
import { CustomList, CustomListCreateMutation, CustomListUpdateMutation } from '@/domain/custom-list/CustomList.model';
import { ConfirmDialog } from '@/Components/confirmation-dialog/ConfirmDialog';

export type SectionDefinitionFormValues = Omit<SectionDefinition, 'id' | 'order' | 'fields'>;
export type SectionFieldDefinitionFormValues = Omit<SectionFieldDefinition, 'id' | 'order' | 'customList'> & {
    customList?: LabelListItemFormValues[];
};

type SectionDialogState = {
    open: boolean;
    section?: SectionDefinition;
    mode?: 'CREATE' | 'UPDATE' | 'DELETE' | 'CREATE_FIELD' | 'UPDATE_FIELD' | 'DELETE_FIELD';
    field?: SectionFieldDefinition;
};

/**
 * In this page we manage the section definitions (custom fields) of the employee
 * This page is accessible from the company settings page and only for the admin
 * @returns Page to manage the section definitions of the employee
 */
export const CompanySettingsSectionDefinitionsPage: FC = () => {
    const { t } = useTranslation();

    // The list of section definitions displayed in the page
    const [sectionDefinitions, setSectionDefinitions] = useState<SectionDefinition[]>([]);

    // Common state to manage dialogs in the page
    const [sectionDialogState, setSectionDialogState] = useState<SectionDialogState>({ open: false });

    const fetchSectionDefinitions = useCallback(async () => {
        const data = await getSectionDefinitions();
        setSectionDefinitions(data);
    }, []);

    // Fetch the section definitions on mount
    useEffect(() => {
        fetchSectionDefinitions().then(() => {
            // do nothing
        });
    }, [fetchSectionDefinitions]);

    const handleDragEnd = async (result: DropResult) => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (!sectionDefinitions) {
            return;
        }

        // the new order of the section definitions
        let sectionDefinitionOrdered = [...sectionDefinitions];
        sectionDefinitionOrdered.splice(source.index, 1);
        sectionDefinitionOrdered.splice(destination.index, 0, sectionDefinitions[source.index]);
        sectionDefinitionOrdered = sectionDefinitionOrdered.map((item, index) => ({
            ...item,
            order: index,
        }));

        setSectionDefinitions(sectionDefinitionOrdered);

        try {
            const payload = sectionDefinitionOrdered.map(sectionDefinition => ({
                resourceId: sectionDefinition.id,
                order: sectionDefinition.order,
            }));
            await updateSectionDefinitionsOrder(payload);
        } catch {
            // if the api call fails we reset the section definitions
            await fetchSectionDefinitions();
        }
    };

    // TODO Migrate to library https://rogerhr.atlassian.net/browse/RP-4101
    const onDragEnd: OnDragEndResponder = result => {
        handleDragEnd(result).catch(handleError);
    };

    /**
     * Handle the api call to create a new section or update an existing one
     * @param section the section to save (new or update)
     * @param previousSection
     */
    const handleSectionSave = async (section: SectionDefinitionFormValues, previousSection?: SectionDefinition) => {
        const isEdit = !!previousSection?.id;
        try {
            // if the previous section is defined we update the section
            if (isEdit) {
                const updatedSection = await handleUpdateSectionDefinition({
                    ...previousSection,
                    ...section,
                });

                // we replace the old section by the new one
                replaceSection(updatedSection);
            } else {
                // else we create a new section
                const newSection = await createSectionDefinition({
                    ...section,
                    // we add the new section at the end of the list
                    order: sectionDefinitions.length,
                });
                setSectionDefinitions([...sectionDefinitions, newSection]);
            }

            handleCloseDialog();
            showSnackbar(isEdit ? t('employee_fields_page.messages.section_updated') : t('employee_fields_page.messages.new_section_added'), 'success');
        } catch {
            showSnackbar(isEdit ? t('employee_fields_page.messages.section_update_error') : t('employee_fields_page.messages.new_section_add_error'), 'error');
        }
    };

    const handleSectionDelete = async (section: SectionDefinition) => {
        try {
            await deleteSectionDefinition(section.id);
            // Remove the section from the list
            setSectionDefinitions([...sectionDefinitions.filter(sectionDefinition => sectionDefinition.id !== section.id)]);
            setSectionDialogState({ open: false });
            showSnackbar(t('employee_fields_page.messages.section_deleted'), 'success');
        } catch {
            showSnackbar(t('employee_fields_page.messages.section_delete_error'), 'error');
        }
    };

    const handleCustomListSave = async (field: SectionFieldDefinitionFormValues, previousField?: SectionFieldDefinition): Promise<CustomList | undefined> => {
        const isEdit = !!previousField?.id;
        // if the section field is a custom list we must create/update the list before creating/updating the field

        if (isEdit) {
            const payload: CustomListUpdateMutation = {
                name: field.name,
                items: field.customList ?? [],
            };

            if (!previousField.customList?.id) {
                return;
            }

            return await updateCustomList(previousField.customList.id, payload);
        } else {
            // we create the list
            const payload: CustomListCreateMutation = {
                name: field.name,
                items: field.customList ?? [],
            };

            return await createCustomList(payload);
        }
    };

    const handleCustomListDelete = async (customListId: number) => {
        try {
            await deleteCustomListById(customListId);
        } catch {
            showSnackbar(t('employee_fields_page.messages.section_delete_error'), 'error');
        }
    };

    /**
     * Handle the api call to create a new field or update an existing one
     * @param customList
     * @param field the field to save (new or update)
     * @param previousField field value before the update (undefined if it's a new field) and the id is defined
     */
    const handleFieldSave = async ({ customList, ...field }: SectionFieldDefinitionFormValues, previousField?: SectionFieldDefinition) => {
        const isEdit = !!previousField?.id;

        // if the section field is a custom list we must create/update the list before creating/updating the field
        let savedCustomList: CustomList | undefined;

        const valueType = field.valueType;
        const isList = valueType === 'CUSTOM_LIST_ITEM' || valueType === 'CUSTOM_MULTI_LIST_ITEM';
        if (isList) {
            savedCustomList = await handleCustomListSave({ customList, ...field }, previousField);
        }

        const section = sectionDialogState?.section;
        if (!section) {
            return;
        }
        try {
            // To create a new field in section we must update the section, the API doesn't expose a route to create a field

            const currentField: Omit<SectionFieldDefinition, 'id'> & { id?: number } = isEdit
                ? {
                      ...previousField,
                      ...field,
                      customList: savedCustomList,
                  }
                : {
                      id: undefined,
                      ...field,
                      customList: savedCustomList,
                      order: section.fields.length,
                  };

            let fields: SectionFieldDefinition[];
            if (isEdit) {
                fields = section.fields.map(f => {
                    if (f.id === previousField.id) {
                        return {
                            ...f,
                            ...currentField,
                        };
                    }
                    return f;
                });
            } else {
                fields = [...section.fields, currentField] as SectionFieldDefinition[];
            }

            const payload: SectionDefinition = {
                // the rest of the section is the same
                ...section,
                fields,
            };

            const updatedSection = await handleUpdateSectionDefinition(payload);
            replaceSection(updatedSection);
            handleCloseDialog();
        } catch {
            showSnackbar(t('employee_fields_page.messages.section_update_error'), 'error');
        }
    };

    const replaceSection = (updatedSection: SectionDefinition) => {
        setSectionDefinitions(sectionDefinitions.map(sectionDefinition => (sectionDefinition.id === updatedSection.id ? updatedSection : sectionDefinition)));
    };

    const handleFieldDelete = async () => {
        // To delete a field in section we must update the section, the API doesn't expose a route to delete a field

        const fieldId = sectionDialogState?.field?.id;
        const section = sectionDialogState?.section;
        const valueType = sectionDialogState?.field?.valueType;
        const isList = valueType === 'CUSTOM_LIST_ITEM' || valueType === 'CUSTOM_MULTI_LIST_ITEM';

        if (!fieldId || !section?.id) {
            return;
        }

        try {
            const updatedSection = await handleUpdateSectionDefinition({
                ...section,
                // Remove the field from the list
                fields: section.fields?.filter(sectionField => sectionField.id !== fieldId) ?? [],
            });

            if (isList) {
                const customListId = sectionDialogState?.field?.customList?.id;
                if (customListId !== undefined) {
                    await handleCustomListDelete(customListId);
                } else {
                    console.error('Custom List ID is undefined');
                }
            }

            replaceSection(updatedSection);
            handleCloseDialog();
        } catch {
            showSnackbar(t('employee_fields_page.messages.section_update_error'), 'error');
        }
    };

    /**
     * Handle the api call to update the fields order in a section
     * @param section the section to update
     */
    const handleFieldsOrderChange = (section: SectionDefinition) => (fields: SectionFieldDefinition[]) => {
        const updatedSection = {
            ...section,
            // set the new fields order
            fields,
        };
        // we replace the old section by the new one to not wait for the API response
        replaceSection(updatedSection);

        // To update the fields order in section we must update the section, the API doesn't expose a route to update the fields order
        handleUpdateSectionDefinition(updatedSection).then(() => {
            // do nothing
        });
    };

    /**
     * Convert the section definition to the request format and call the api
     * @param section the section to update
     */
    const handleUpdateSectionDefinition = ({ id, fields, ...restSection }: SectionDefinition) => {
        const requestFields: SectionFieldDefinitionRequest[] =
            fields?.map(({ customList, ...f }) => ({
                ...f,
                customListId: customList?.id,
            })) ?? [];
        const payload: SectionDefinitionUpdateRequest = { fields: requestFields, ...restSection };

        return updateSectionDefinition(id, payload);
    };

    const handleCloseDialog = () => setSectionDialogState({ open: false });

    return (
        <Stack gap={2}>
            <Button
                sx={{ alignSelf: 'flex-end' }}
                onClick={() =>
                    setSectionDialogState({
                        open: true,
                        mode: 'CREATE',
                    })
                }
            >
                {t('employee_fields_page.add_section')}
            </Button>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='section-definition-order'>
                    {provided => (
                        <Stack ref={provided.innerRef} {...provided.droppableProps}>
                            {sectionDefinitions?.map((sectionDefinition, index) => (
                                <DraggableSection
                                    sectionDefinition={sectionDefinition}
                                    index={index}
                                    key={sectionDefinition.id}
                                    onAction={setSectionDialogState}
                                    onFieldsOrderChange={handleFieldsOrderChange}
                                />
                            ))}
                            {provided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>

            {/* <DialogContainer */}
            {sectionDialogState?.open && (sectionDialogState?.mode === 'CREATE' || sectionDialogState?.mode === 'UPDATE') && (
                <SectionDefinitionDialog onClose={handleCloseDialog} onSave={handleSectionSave} defaultSection={sectionDialogState?.section} />
            )}
            {sectionDialogState?.open && sectionDialogState?.mode === 'DELETE' && (
                <ConfirmDialog
                    open
                    title={t('employee_fields_page.delete_section')}
                    content={t('employee_fields_page.messages.are_you_sure_you_want_to_delete_this_section')}
                    onConfirm={() => !!sectionDialogState?.section && handleSectionDelete(sectionDialogState?.section)}
                    onClose={handleCloseDialog}
                />
            )}
            {/* Dialogs for the fields section */}
            {sectionDialogState?.open && (sectionDialogState.mode === 'CREATE_FIELD' || sectionDialogState.mode === 'UPDATE_FIELD') && (
                <SectionFieldDefinitionDialog onClose={handleCloseDialog} onSave={handleFieldSave} defaultSectionField={sectionDialogState?.field} />
            )}
            {sectionDialogState?.open && sectionDialogState.mode === 'DELETE_FIELD' && (
                <ConfirmDialog
                    open
                    title={t('employee_fields_page.delete_field')}
                    content={t('employee_fields_page.messages.are_you_sure_you_want_to_delete_this_field')}
                    onConfirm={handleFieldDelete}
                    onClose={handleCloseDialog}
                />
            )}
        </Stack>
    );
};

const DraggableSection: FC<{
    sectionDefinition: SectionDefinition;
    index: number;
    onAction: (action: SectionDialogState) => void;
    onFieldsOrderChange: (section: SectionDefinition) => (fields: SectionFieldDefinition[]) => void;
}> = ({ sectionDefinition, index, onAction, onFieldsOrderChange }) => {
    return (
        <Draggable draggableId={sectionDefinition.id.toString()} index={index} key={sectionDefinition.id}>
            {provided => (
                // Gap is not supported by DND yet, we need to add margin to the draggable element
                // see issue https://github.com/hello-pangea/dnd/issues/432
                <Box paddingBottom={2} {...provided.draggableProps} ref={provided.innerRef}>
                    <SectionDefinitionDraggable
                        section={sectionDefinition}
                        provided={provided}
                        onDelete={() =>
                            onAction({
                                open: true,
                                mode: 'DELETE',
                                section: sectionDefinition,
                            })
                        }
                        onUpdate={() =>
                            onAction({
                                open: true,
                                mode: 'UPDATE',
                                section: sectionDefinition,
                            })
                        }
                        onFieldAdd={() =>
                            onAction({
                                open: true,
                                mode: 'CREATE_FIELD',
                                section: sectionDefinition,
                            })
                        }
                        onFieldUpdate={field =>
                            onAction({
                                open: true,
                                mode: 'UPDATE_FIELD',
                                section: sectionDefinition,
                                field,
                            })
                        }
                        onFieldDelete={field =>
                            onAction({
                                open: true,
                                mode: 'DELETE_FIELD',
                                section: sectionDefinition,
                                field,
                            })
                        }
                        onFieldsOrderChange={onFieldsOrderChange(sectionDefinition)}
                    />
                </Box>
            )}
        </Draggable>
    );
};
