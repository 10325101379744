import { OffboardingMutation } from '@/domain/offboarding/Offboarding.model';
import { offboardingApi } from '@/api/offboarding/Offboarding.api';
import { OffboardingAllStepFormValues } from '@/page/people/OffboardingPage';
import { Employee } from '@/domain/employee/Employee.model';
import { formatToLocalDate } from '@/utils/datetime.util';

export const mapOffboardingFormValuesToMutation = (offboardingFormValues: OffboardingAllStepFormValues): OffboardingMutation => {
    const { assignments, effectiveDate: reassignmentEffectiveDate, assignNewManagerReviews, review, organizeMeeting, ...restValues } = offboardingFormValues;

    type Assignment = {
        employee: Employee;
        manager: Employee;
    };
    const definedManagers: Assignment[] = assignments?.filter(assignment => !!assignment.manager) as Assignment[];

    return {
        ...restValues,
        ongoingReviewIds: offboardingFormValues.ongoingReviewIds,
        reassignment: definedManagers?.length
            ? {
                  assignments: definedManagers.map(assignment => ({
                      employeeId: assignment.employee.id,
                      managerId: assignment.manager.id,
                  })),
                  effectiveDate: reassignmentEffectiveDate,
                  assignNewManagerReviews,
              }
            : undefined,
        reviews: organizeMeeting
            ? [
                  {
                      reviewTemplateId: review?.reviewTemplate?.id,
                      startDate: formatToLocalDate(review?.startDate),
                      endDate: formatToLocalDate(review?.endDate),
                      managerIds: [review?.manager?.id],
                  } satisfies OffboardingMutation['reviews'][number],
              ]
            : [],
    };
};
export const offboardEmployee = (mutation: OffboardingMutation): Promise<void> => {
    return offboardingApi.offboardEmployee(mutation);
};
