import { RouteLeavingGuard } from '@/Components/route-leaving-guard/RouteLeavingGuard';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { ReorderItem, ReorderModal } from '@/Components/reorder-modal/ReorderModal';
import { EmployeeSurveyStepQuestions } from '@/page/survey/EmployeeSurvey/EmployeeSurveyStepQuestions';
import { handleError } from '@/utils/api.util';
import { maxOrderNumber } from '@/utils/collections.util';
import { getLabelTranslation, getRealmLanguage, isLabelContainLanguageTranslation, UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Label } from '@/domain/label/Label.model';
import { SurveyQuestionItem, SurveyQuestionType } from '@/domain/survey/Survey.model';
import { Employee } from '@/domain/employee/Employee.model';
import { SurveyInfoSection } from '@/page/survey/survey/SurveyInfoSection';
import { Button, IconButton, Paper, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SurveyTemplateQuestionPage } from './survey/SurveyTemplateQuestionPage';
import { Cancel01Icon } from 'hugeicons-react';
import {
    SurveyTemplate,
    SurveyTemplateCreationMutation,
    SurveyTemplateQuestion,
    SurveyTemplateUpdateMutation,
} from '@/domain/survey-template/Survey-template.model';
import { createSurveyTemplate, getSurveyTemplate, updateSurveyTemplate } from '@/domain/survey-template/Survey-template.service';
import { ContentContainer } from '@/page/layout/ContentContainer'; // Todo: use React hook form

// Todo: use React hook form
export const SurveyTemplateDetailPage: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const isPreviewMode = searchParams.get('preview') === 'true';
    const { surveyId } = useParams();
    const { t } = useTranslation();
    const defaultLanguage = getRealmLanguage();

    const [mode, setMode] = useState<'create' | 'edit'>('create');
    // Could be great to add a component to split loading and form, useful to avoid undefined survey template state
    type SurveyTemplateState = Omit<SurveyTemplate, 'createdBy'> & {
        createdBy?: Employee;
    };
    const [surveyTemplate, setSurveyTemplate] = useState<SurveyTemplateState>();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [reorderModalOpen, setReorderModalOpen] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [translationLanguage, setTranslationLanguage] = useState<UserLanguage>(defaultLanguage);
    const availableLanguages = Object.keys(UserLanguage).map(k => k as UserLanguage);

    const getDefaultLabel = useCallback(() => {
        return {
            id: 0,
            translationDe: '',
            translationFr: '',
            translationIt: '',
            translationEn: '',
        };
    }, []);

    const getDefaultItem = useCallback(() => {
        return {
            id: 0,
            label: getDefaultLabel(),
            order: 0,
            score: 0,
            imageUrl: '',
        };
    }, [getDefaultLabel]);

    const getDefaultQuestion = useCallback(() => {
        return {
            question: getDefaultLabel(),
            order: 0,
            items: [getDefaultItem(), getDefaultItem()],
            allowComment: false,
            type: SurveyQuestionType.TEXT,
        };
    }, [getDefaultLabel, getDefaultItem]);

    const createDefaultSurveyTemplate = useCallback((): Omit<SurveyTemplate, 'createdBy'> => {
        return {
            name: getDefaultLabel(),
            id: 0,
            description: getDefaultLabel(),
            questions: [getDefaultQuestion() as SurveyTemplateQuestion],
        };
    }, [getDefaultLabel, getDefaultQuestion]);

    useEffect(() => {
        if (surveyId === 'new') {
            setMode('create');
            setSurveyTemplate(createDefaultSurveyTemplate());
        } else {
            setMode('edit');
            getSurveyTemplate(Number(surveyId))
                .then(data => {
                    setSurveyTemplate(data);
                })
                .catch(error => {
                    handleError(error);
                });
        }
    }, [defaultLanguage, dispatch, surveyId, createDefaultSurveyTemplate]);

    const onSave = () => {
        if (!surveyTemplate) {
            return;
        }

        setIsSubmitting(true);

        if (mode === 'create') {
            const request: SurveyTemplateCreationMutation = {
                name: surveyTemplate.name,
                description: surveyTemplate.description,
                languages: availableLanguages,
                questions: surveyTemplate.questions.map(q => ({
                    question: q.question,
                    type: q.type,
                    category: q.category,
                    comment: q.comment,
                    allowComment: q.allowComment,
                    order: q.order,
                    items: getQuestionItem(q),
                })),
            };
            createSurveyTemplate(request)
                .then(() => {
                    showSnackbar(t('survey_templates_page.survey_created'), 'success');
                    navigate('/manage-surveys/templates');
                })
                .catch(() => {
                    showSnackbar(t('survey_templates_page.survey_create_error'), 'error');
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        } else if (mode === 'edit') {
            const request: SurveyTemplateUpdateMutation = {
                name: surveyTemplate.name,
                description: surveyTemplate.description,
                languages: availableLanguages,
                questions: surveyTemplate.questions.map(q => {
                    return {
                        id: q.id ? q.id : undefined,
                        question: q.question,
                        type: q.type,
                        category: q.category,
                        comment: q.comment,
                        allowComment: q.allowComment,
                        order: q.order,
                        items:
                            q.type === SurveyQuestionType.TEXT || q.type === SurveyQuestionType.SECTION
                                ? []
                                : q.items.map((i, index) => {
                                      return {
                                          id: i.id ? i.id : undefined,
                                          label: i.label,
                                          order: index,
                                          imageUrl: i.imageUrl,
                                      };
                                  }),
                    };
                }),
            };

            updateSurveyTemplate(Number(surveyId), request)
                .then(data => {
                    setSurveyTemplate(data);
                    showSnackbar(t('survey_templates_page.survey_updated'), 'success');
                    setIsSubmitting(false);
                })
                .catch(() => {
                    showSnackbar(t('survey_templates_page.survey_update_error'), 'error');
                })
                .finally(() => {
                    setIsSubmitting(false);
                    navigate('/manage-surveys/templates');
                });
        }
    };

    const scrollIntoView = (elementId: string) => {
        setTimeout(() => {
            const element = document.getElementById(elementId);
            element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }, 100);
    };
    const addQuestion = () => {
        if (!surveyTemplate) {
            return;
        }
        const newQuestion = {
            ...(getDefaultQuestion() as SurveyTemplateQuestion),
            order: maxOrderNumber(surveyTemplate?.questions ?? []) + 1,
        };
        setSurveyTemplate({
            ...surveyTemplate,
            questions: [...surveyTemplate.questions, newQuestion],
        });
        scrollIntoView('end_page');
        setIsDirty(true);
    };

    const duplicateQuestion = (questionIndex: number) => {
        if (!surveyTemplate) {
            return;
        }
        const newQuestion = {
            ...surveyTemplate.questions[questionIndex],
            question: {
                ...surveyTemplate.questions[questionIndex].question,
                translationDe: surveyTemplate.questions[questionIndex].question.translationDe
                    ? `${surveyTemplate.questions[questionIndex].question.translationDe} ${t('survey_templates_page.copy')}`
                    : '',
                translationFr: surveyTemplate.questions[questionIndex].question.translationFr
                    ? `${surveyTemplate.questions[questionIndex].question.translationFr} ${t('survey_templates_page.copy')}`
                    : '',
                translationIt: surveyTemplate.questions[questionIndex].question.translationIt
                    ? `${surveyTemplate.questions[questionIndex].question.translationIt} ${t('survey_templates_page.copy')}`
                    : '',
                translationEn: surveyTemplate.questions[questionIndex].question.translationEn
                    ? `${surveyTemplate.questions[questionIndex].question.translationEn} ${t('survey_templates_page.copy')}`
                    : '',
            },
            id: undefined,
        };
        setSurveyTemplate({
            ...surveyTemplate,
            questions: [...surveyTemplate.questions, newQuestion as unknown as SurveyTemplateQuestion],
        });
        const elementId = `question-${questionIndex + 1}`;
        setIsDirty(true);
        scrollIntoView(elementId);
    };

    if (!surveyTemplate) {
        return <></>;
    }
    const onChangeQuestionType = (questionType: SurveyQuestionType, index: number) => {
        setSurveyTemplate({
            ...surveyTemplate,
            questions: [
                ...surveyTemplate.questions.slice(0, index),
                {
                    ...surveyTemplate.questions[index],
                    type: questionType,
                },
                ...surveyTemplate.questions.slice(index + 1),
            ],
        });
    };

    const addScaleOptionToQuestion = (option: SurveyQuestionItem, index: number, scaleOptionsLength: number) => {
        const surveyDetails = surveyTemplate;
        if (!surveyDetails) {
            return;
        }
        if (scaleOptionsLength <= 5) {
            surveyDetails.questions[index].items?.push(option);
        } else if (scaleOptionsLength === 11) {
            const newOptionValue = option;
            newOptionValue.label.translationEn = newOptionValue.score.toString();
            surveyDetails.questions[index].items?.push(newOptionValue);
        }
        setSurveyTemplate({ ...surveyDetails });
    };

    const addOptionToQuestion = (option: SurveyQuestionItem, index: number) => {
        setSurveyTemplate({
            ...surveyTemplate,
            questions: [
                ...surveyTemplate.questions.slice(0, index),
                {
                    ...surveyTemplate.questions[index],
                    items: [...surveyTemplate.questions[index].items, option],
                },
                ...surveyTemplate.questions.slice(index + 1),
            ],
        });
    };

    const questionFieldsValid = () => {
        const findQuestionWithEmptyTitle = surveyTemplate.questions.find(question => !isLabelContainLanguageTranslation(question.question, defaultLanguage));
        const questionOptionsAdded = surveyTemplate.questions.map(question => {
            let activeFields = undefined;
            if (
                question.type === SurveyQuestionType.SINGLE_CHOICE ||
                question.type === SurveyQuestionType.MULTI_CHOICE ||
                question.type === SurveyQuestionType.LICKERT_SCALE ||
                question.type === SurveyQuestionType.OPINION_SCALE ||
                question.type === SurveyQuestionType.NPS
            ) {
                activeFields = question.items?.find(item => !isLabelContainLanguageTranslation(item.label, defaultLanguage));
            }
            return activeFields;
        });
        const checkIfOptionsAreEmpty = questionOptionsAdded.find(option => !option);
        return !(findQuestionWithEmptyTitle || checkIfOptionsAreEmpty);
    };

    const isFormValid = () => {
        return (
            surveyTemplate &&
            isLabelContainLanguageTranslation(surveyTemplate.name, defaultLanguage) &&
            isLabelContainLanguageTranslation(surveyTemplate.description, defaultLanguage) &&
            (!surveyTemplate.questions || questionFieldsValid())
        );
    };

    const onClosePreviewMode = () => {
        navigate(`/manage-surveys/templates/${surveyId}`);
    };

    const footerActions: FooterActionsProps['actions'] = [
        {
            name: 'preview',
            onClick: () => navigate(`/manage-surveys/templates/${surveyId}?preview=true`),
            variant: 'text',
            children: t('general.preview'),
        },
        {
            name: 'reorder',
            onClick: () => setReorderModalOpen(true),
            children: t('survey_templates_page.reorder'),
            variant: 'outlined',
        },
        {
            name: 'save',
            onClick: onSave,
            disabled: !isFormValid(),
            children: mode === 'create' ? t('survey_templates_page.create_survey') : t('survey_templates_page.save_survey'),
            variant: 'contained',
        },
    ];

    const initOpinionScale = (index: number) => {
        setSurveyTemplate({
            ...surveyTemplate,
            questions: [
                ...surveyTemplate.questions.slice(0, index),
                {
                    ...surveyTemplate.questions[index],
                    items: generateOpinionScaleItems(),
                },
                ...surveyTemplate.questions.slice(index + 1),
            ],
        });

        function generateOpinionScaleItems() {
            const opinionScaleItems: SurveyQuestionItem[] = [];
            const OPINION_SCALE_NUMBER_ITEMS = 11;
            for (let i = 0; i < OPINION_SCALE_NUMBER_ITEMS; i++) {
                opinionScaleItems.push({
                    label: {
                        translationEn: i.toString(),
                        translationDe: i.toString(),
                        translationFr: i.toString(),
                        translationIt: i.toString(),
                    },
                    score: i,
                    order: i,
                    id: i,
                    imageUrl: '',
                });
            }
            return opinionScaleItems;
        }
    };

    const getReorderItems = (questions: SurveyTemplateQuestion[]) => {
        return questions.map((question, index) => {
            return {
                id: question.id,
                name: getLabelTranslation(question.question, defaultLanguage),
                order: index,
            };
        });
    };

    const getQuestionsFromReorderItems = (reorderItems: ReorderItem[]): SurveyTemplateQuestion[] => {
        // TODO I think we have some bugs when reorder a list with new item not saved
        // Add an issue
        return reorderItems.map<SurveyTemplateQuestion>((reorderItem, index) => {
            // params should have the same size and each id from reorderItems param should be contain in survey template questions
            const question = surveyTemplate.questions.find(question => question.id === reorderItem.id);

            if (!question) {
                throw new Error('Question not found');
            }
            return {
                ...question,
                order: index,
            };
        });
    };

    const onSaveReorder = (reorderItems: ReorderItem[]) => {
        const reorderedQuestions = getQuestionsFromReorderItems(reorderItems);
        setSurveyTemplate({
            ...surveyTemplate,
            questions: reorderedQuestions,
        });
        setReorderModalOpen(false);
    };

    return (
        <>
            <ContentContainer>
                <RouteLeavingGuard when={isDirty && !isSubmitting} />
                <Stack gap={2}>
                    <Paper elevation={1}>
                        <SurveyInfoSection
                            surveyTemplate={surveyTemplate}
                            onChangeName={(name: Label) => {
                                setSurveyTemplate({
                                    ...surveyTemplate,
                                    name: name,
                                });
                                setIsDirty(true);
                            }}
                            onChangeDescription={(description: Label) => {
                                setSurveyTemplate({
                                    ...surveyTemplate,
                                    description: description,
                                });
                                setIsDirty(true);
                            }}
                            translationLanguage={translationLanguage}
                            handleLanguageChange={(language: UserLanguage) => {
                                setTranslationLanguage(language);
                            }}
                        />
                    </Paper>

                    {surveyTemplate &&
                        [...surveyTemplate.questions]
                            .sort((a, b) => a.order - b.order)
                            .map((question: SurveyTemplateQuestion, index: number) => {
                                return (
                                    <SurveyTemplateQuestionPage
                                        id={`question-${index}`}
                                        initOpinionScale={() => initOpinionScale(index)}
                                        key={question?.order + '_' + index}
                                        translationLanguage={translationLanguage}
                                        isTranslationMode={defaultLanguage !== translationLanguage}
                                        question={question}
                                        mode={mode}
                                        onChangeQuestionType={onChangeQuestionType}
                                        onQuestionItemAdded={option => {
                                            addOptionToQuestion(option, index);
                                        }}
                                        onQuestionScaleItemAdded={(option, scaleOptionLength) => {
                                            addScaleOptionToQuestion(option, index, scaleOptionLength);
                                        }}
                                        index={index}
                                        onChangeQuestionItemTitle={(title, i) => {
                                            setSurveyTemplate({
                                                ...surveyTemplate,
                                                questions: [
                                                    ...surveyTemplate.questions.slice(0, index),
                                                    {
                                                        ...surveyTemplate.questions[index],
                                                        items: [
                                                            ...surveyTemplate.questions[index].items.slice(0, i),
                                                            {
                                                                ...surveyTemplate.questions[index].items[i],
                                                                label: title,
                                                            },
                                                            ...surveyTemplate.questions[index].items.slice(i + 1),
                                                        ],
                                                    },
                                                    ...surveyTemplate.questions.slice(index + 1),
                                                ],
                                            });
                                            setIsDirty(true);
                                        }}
                                        onChangeQuestionItemImage={(imageUrl, i) => {
                                            setSurveyTemplate({
                                                ...surveyTemplate,
                                                questions: [
                                                    ...surveyTemplate.questions.slice(0, index),
                                                    {
                                                        ...surveyTemplate.questions[index],
                                                        items: [
                                                            ...surveyTemplate.questions[index].items.slice(0, i),
                                                            {
                                                                ...surveyTemplate.questions[index].items[i],
                                                                imageUrl: imageUrl,
                                                            },
                                                            ...surveyTemplate.questions[index].items.slice(i + 1),
                                                        ],
                                                    },
                                                    ...surveyTemplate.questions.slice(index + 1),
                                                ],
                                            });
                                            setIsDirty(true);
                                        }}
                                        onChangeQuestionItemScore={(score, i) => {
                                            setSurveyTemplate({
                                                ...surveyTemplate,
                                                questions: [
                                                    ...surveyTemplate.questions.slice(0, index),
                                                    {
                                                        ...surveyTemplate.questions[index],
                                                        items: [
                                                            ...surveyTemplate.questions[index].items.slice(0, i),
                                                            {
                                                                ...surveyTemplate.questions[index].items[i],
                                                                score: score,
                                                            },
                                                            ...surveyTemplate.questions[index].items.slice(i + 1),
                                                        ],
                                                    },
                                                    ...surveyTemplate.questions.slice(index + 1),
                                                ],
                                            });
                                            setIsDirty(true);
                                        }}
                                        onChangeQuestion={label => {
                                            setSurveyTemplate({
                                                ...surveyTemplate,
                                                questions: [
                                                    ...surveyTemplate.questions.slice(0, index),
                                                    {
                                                        ...surveyTemplate.questions[index],
                                                        question: label,
                                                    },
                                                    ...surveyTemplate.questions.slice(index + 1),
                                                ],
                                            });
                                        }}
                                        onSelectCategory={category => {
                                            setSurveyTemplate({
                                                ...surveyTemplate,
                                                questions: [
                                                    ...surveyTemplate.questions.slice(0, index),
                                                    {
                                                        ...surveyTemplate.questions[index],
                                                        category: category,
                                                    },
                                                    ...surveyTemplate.questions.slice(index + 1),
                                                ],
                                            });
                                            setIsDirty(true);
                                        }}
                                        onDeleteQuestionItem={i => {
                                            setSurveyTemplate({
                                                ...surveyTemplate,
                                                questions: [
                                                    ...surveyTemplate.questions.slice(0, index),
                                                    {
                                                        ...surveyTemplate.questions[index],
                                                        items: [
                                                            ...surveyTemplate.questions[index].items.slice(0, i),
                                                            ...surveyTemplate.questions[index].items.slice(i + 1),
                                                        ],
                                                    },
                                                    ...surveyTemplate.questions.slice(index + 1),
                                                ],
                                            });
                                            setIsDirty(true);
                                        }}
                                        deleteQuestion={
                                            surveyTemplate.questions?.length > 1
                                                ? () => {
                                                      setSurveyTemplate({
                                                          ...surveyTemplate,
                                                          questions: [
                                                              ...surveyTemplate.questions.slice(0, index),
                                                              ...surveyTemplate.questions.slice(index + 1),
                                                          ],
                                                      });
                                                      setIsDirty(true);
                                                  }
                                                : undefined
                                        }
                                        duplicateQuestion={() => {
                                            duplicateQuestion(index);
                                        }}
                                    />
                                );
                            })}
                    {defaultLanguage === translationLanguage && (
                        <Button sx={{ width: 200, alignSelf: 'center', mb: 2 }} onClick={addQuestion}>
                            {t('survey_templates_page.add_question')}
                        </Button>
                    )}
                </Stack>
                <Dialog onClose={onClosePreviewMode} fullScreen maxWidth='lg' aria-labelledby='customized-dialog-title' open={isPreviewMode}>
                    <DialogTitle id='customized-dialog-title'>
                        <>
                            {getLabelTranslation(surveyTemplate?.name, defaultLanguage)}
                            <IconButton aria-label='close' onClick={() => onClosePreviewMode()} size='large'>
                                <Cancel01Icon />
                            </IconButton>
                        </>
                    </DialogTitle>
                    <DialogContent dividers>
                        <EmployeeSurveyStepQuestions
                            editingSurveyTemplate={surveyTemplate as SurveyTemplate}
                            token={undefined}
                            selectedLanguage={defaultLanguage}
                        />
                    </DialogContent>
                </Dialog>
                {reorderModalOpen && (
                    <ReorderModal
                        open={reorderModalOpen}
                        // TODO reorder modal should handle optional id
                        // Add a ticket when I have signal
                        initialReorderItems={getReorderItems(surveyTemplate.questions) as ReorderItem[]}
                        onSave={onSaveReorder}
                        onClose={() => setReorderModalOpen(false)}
                    />
                )}

                <div id={'end_page'} />
            </ContentContainer>
            <Footer>
                <FooterActions actions={footerActions} />
            </Footer>
        </>
    );
};

const getOpinionScaleItems = (q: SurveyTemplateQuestion) => {
    const opinionScaleItems = [];
    for (let i = 0; i < 11; i++) {
        if (q.items[i]) {
            opinionScaleItems.push(q.items[i]);
        } else {
            opinionScaleItems.push({
                id: 0,
                label: {
                    id: 0,
                    translationDe: i.toString(),
                    translationFr: i.toString(),
                    translationIt: i.toString(),
                    translationEn: i.toString(),
                },
                order: i,
                score: i,
                imageUrl: '',
            });
        }
    }
    return opinionScaleItems;
};

const getQuestionItem = (q: SurveyTemplateQuestion) => {
    if (q.type === SurveyQuestionType.TEXT || q.type === SurveyQuestionType.SECTION) {
        return [];
    }

    if (q.type === SurveyQuestionType.OPINION_SCALE || q.type === SurveyQuestionType.NPS) {
        return getOpinionScaleItems(q);
    }
    return q.items.map((i, index) => {
        return {
            label: i.label,
            order: index,
            imageUrl: i.imageUrl,
        };
    });
};
