import { EmployeeRole, EmployeeRoleCreationMutation, EmployeeRoleSearch, EmployeeRoleUpdateMutation } from '@/domain/employee-role/EmployeeRole.model';
import { employeeRoleApi } from '@/api/employee-role/EmployeeRole.api';

export const searchEmployeeRoles = (roleSearchMutation: EmployeeRoleSearch): Promise<EmployeeRole[]> => {
    return employeeRoleApi.searchEmployeeRoles(roleSearchMutation);
};

export const createEmployeeRole = (employeeRoleCreationMutation: EmployeeRoleCreationMutation): Promise<EmployeeRole> => {
    return employeeRoleApi.createEmployeeRole(employeeRoleCreationMutation);
};

export const updateEmployeeRole = (id: number, employeeRoleUpdateMutation: EmployeeRoleUpdateMutation): Promise<EmployeeRole> => {
    return employeeRoleApi.updateEmployeeRole(id, employeeRoleUpdateMutation);
};

export const deleteEmployeeRole = (id: number): Promise<void> => {
    return employeeRoleApi.deleteEmployeeRole(id);
};
