import { UnitType } from '@/domain/date/Date.model';
import { leaveTypeSchema } from '@/domain/leave-type/LeaveType.schema';
import { hoursMinutesSchema } from '@/utils/datetime.schema';
import { getLocalDateTestConfig, getMinutesFromHoursMinutes, HoursMinutes, LocalDate } from '@/utils/datetime.util';
import i18next from 'i18next';
import * as yup from 'yup';

export enum LeaveCorrectionDialogAction {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
    TRANSFER = 'TRANSFER',
}

export const leaveCorrectionFormSchema = yup
    .object()
    .shape({
        leaveType: leaveTypeSchema.required(),
        actionType: yup.string().required().oneOf(Object.values(LeaveCorrectionDialogAction)),
        unitType: yup.string().required().oneOf(Object.values(UnitType)),
        amountInDays: yup.number().moreThan(0).required(),
        hoursMinutes: hoursMinutesSchema.required().test({
            message: i18next.t('general.validations.required'),
            test: (value: HoursMinutes) => getMinutesFromHoursMinutes(value) > 0,
        }),
        effectiveDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
        comment: yup.string().default(''),
        transferToTimesheetAdjustment: yup.boolean().default(false).required(),
    })
    .required();

export type LeaveCorrectionFormValues = yup.InferType<typeof leaveCorrectionFormSchema>;
