import { Department, DepartmentNode } from '@/domain/department/Department.model';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import { FlatTreeNode } from '@/Components/autocomplete-wrapper/TreeAutoComplete/useTreeAutoComplete';

/**
 * Maps a department node to a list of tree nodes (the department and its children)
 * @param department
 * @param parent
 * @param translationLanguage
 */
export const mapDepartmentNodeToTreeNodes = (
    department: DepartmentNode,
    parent: DepartmentNode | undefined,
    translationLanguage: UserLanguage,
): FlatTreeNode[] => {
    if (department.children.length === 0) {
        return [
            {
                id: department.id,
                name: getLabelTranslation(department.name, translationLanguage),
                parentId: parent?.id,
                hasChildren: false,
            },
        ];
    }
    return [
        {
            id: department.id,
            name: getLabelTranslation(department.name, translationLanguage),
            parentId: parent?.id,
            hasChildren: true,
        },
        ...department.children.flatMap(d => mapDepartmentNodeToTreeNodes(d, department, translationLanguage)),
    ];
};

/**
 * Maps a department node to a list of departments (the department and its children)
 * @param department
 * @param parent
 */
export const mapDepartmentNodeToDepartment = (department: DepartmentNode, parent: DepartmentNode | undefined): Department[] => {
    if (department.children.length === 0) {
        return [mapDepartmentNodeToSingleDepartment(department, parent)];
    }
    return [mapDepartmentNodeToSingleDepartment(department, parent), ...department.children.flatMap(d => mapDepartmentNodeToDepartment(d, department))];
};

const mapDepartmentNodeToSingleDepartment = (department: DepartmentNode, parent: DepartmentNode | undefined): Department => {
    return {
        id: department.id,
        name: department.name,
        parentId: parent?.id,
        order: department.order,
        managers: department.managers,
        departmentCostCenters: department.departmentCostCenters,
    };
};

/**
 * Maps a list of department nodes to a list of tree nodes (all departments at the root and their children)
 * @param departments
 * @param translationLanguage
 */
export const mapDepartmentNodesListToTreeNodes = (departments: DepartmentNode[], translationLanguage: UserLanguage): FlatTreeNode[] => {
    return departments.flatMap(department => mapDepartmentNodeToTreeNodes(department, undefined, translationLanguage));
};

/**
 * Maps a list of department nodes to a list of departments (all departments at the root and their children)
 * @param departments
 */
export const mapDepartmentNodesListToDepartments = (departments: DepartmentNode[]): Department[] => {
    return departments.flatMap(department => mapDepartmentNodeToDepartment(department, undefined));
};

export const mapDepartmentToTreeNode = (department: Department, translationLanguage?: UserLanguage): FlatTreeNode => ({
    id: department.id,
    name: getLabelTranslation(department.name, translationLanguage),
    parentId: department.parentId ?? undefined,
    hasChildren: false,
});

export const mapDepartmentsToTreeNodes = (departments: Department[], translationLanguage?: UserLanguage): FlatTreeNode[] => {
    const departmentIdsWithChildren = new Set<Department['id']>();
    return departments
        .map((department): FlatTreeNode => {
            if (department.parentId) {
                departmentIdsWithChildren.add(department.parentId);
            }
            return mapDepartmentToTreeNode(department, translationLanguage);
        })
        .map(departmentTreeNode => ({
            ...departmentTreeNode,
            hasChildren: departmentIdsWithChildren.has(departmentTreeNode.id),
        }));
};
