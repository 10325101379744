import { TimesheetPayment, TimesheetPaymentSearchRequest } from '@/domain/timesheet-payment/TimesheetPayment.model';
import { timesheetPaymentsSearch } from '@/domain/timesheet-payment/TimesheetPayment.service';
import {
    DailyTimesheetReport,
    MonthlyTimesheetReport,
    PendingDayTimesheet,
    Timesheet,
    TimesheetPendingSearch,
    TimesheetSearch,
} from '@/domain/timesheet/Timesheet.model';
import {
    countPendingTimesheets,
    getClockOutForceBreakDuration,
    searchEmployeeMonthlyTimesheets,
    searchEmployeeTimesheets,
    searchPendingTimesheets,
    searchTimesheets,
} from '@/domain/timesheet/Timesheet.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployeeTimesheets = (
    timesheetSearch: TimesheetSearch,
    options: { enabled: boolean } = { enabled: true },
): UseQueryResult<DailyTimesheetReport[]> => {
    const [timesheets, setTimesheets] = useState<DailyTimesheetReport[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeTimesheets = useCallback(async (search: TimesheetSearch) => {
        try {
            setIsFetching(true);
            const employeeTimesheets = await searchEmployeeTimesheets(search);
            setTimesheets(employeeTimesheets);
        } catch (error) {
            setError(error);
        }

        setIsFetching(false);
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        if (!options.enabled) {
            setTimesheets([]);
            setIsLoading(false);
            setIsFetching(false);
            return;
        }
        fetchEmployeeTimesheets(timesheetSearch).catch(handleError);
    }, [fetchEmployeeTimesheets, timesheetSearch]);

    return {
        data: timesheets,
        setData: setTimesheets,
        isLoading,
        isFetching,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeTimesheets(timesheetSearch),
    };
};

export const useGetPendingTimesheets = (timesheetPendingSearch: TimesheetPendingSearch = {}): UseQueryResult<PendingDayTimesheet[]> => {
    const [pendingDayTimesheets, setPendingDayTimesheets] = useState<PendingDayTimesheet[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeTimesheets = useCallback(async (timesheetPendingSearch: TimesheetPendingSearch) => {
        try {
            setIsFetching(true);
            const dayTimesheets = await searchPendingTimesheets(timesheetPendingSearch);
            setPendingDayTimesheets(dayTimesheets);
        } catch (error) {
            setError(error);
        }
        setIsFetching(false);
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchEmployeeTimesheets(timesheetPendingSearch).catch(handleError);
    }, [fetchEmployeeTimesheets, timesheetPendingSearch]);

    return {
        data: pendingDayTimesheets,
        setData: setPendingDayTimesheets,
        isLoading,
        isFetching,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeTimesheets(timesheetPendingSearch),
    };
};

export const useCountTimesheetPending = (request: TimesheetPendingSearch = {}, enabled = true): UseQueryResult<number> => {
    const [count, setCount] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchCount = useCallback(async (request: TimesheetPendingSearch = {}, enabled: boolean) => {
        if (!enabled) {
            setCount(0);
            setIsLoading(false);
            return;
        }
        try {
            const response = await countPendingTimesheets(request);
            setCount(response);
        } catch (e) {
            setError(e);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchCount(request, enabled).catch(handleError);
    }, [fetchCount, enabled, request]);

    return {
        data: count,
        setData: setCount,
        isLoading,
        error,
        isError: !!error,
        refetch: () => fetchCount(request, enabled),
    };
};

export const useGetTimesheets = (
    timesheetSearch: TimesheetSearch,
    { enabled = true } = {
        enabled: true,
    },
): UseQueryResult<Timesheet[]> => {
    const [timesheets, setTimesheets] = useState<Timesheet[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeTimesheets = useCallback(async (timesheetSearch: TimesheetSearch) => {
        try {
            setIsLoading(true);
            setIsFetching(true);
            const allTimesheets = await searchTimesheets(timesheetSearch);
            setTimesheets(allTimesheets);
        } catch (error) {
            setError(error);
        }

        setIsFetching(false);
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        if (!enabled) {
            setIsLoading(false);
            setIsFetching(false);
            return;
        }
        fetchEmployeeTimesheets(timesheetSearch).catch(handleError);
    }, [fetchEmployeeTimesheets, timesheetSearch]);

    return {
        data: timesheets,
        setData: setTimesheets,
        isLoading,
        isFetching,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeTimesheets(timesheetSearch),
    };
};

export const useGetClockOutForceBreakDuration = ({ employeeId }: { employeeId?: number }): UseQueryResult<number> => {
    const [breakDuration, setBreakDuration] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchClockOutForceBreakDuration = useCallback(async (employeeId?: number) => {
        try {
            if (!employeeId) {
                return;
            }
            const breakDuration = await getClockOutForceBreakDuration(employeeId);
            setBreakDuration(breakDuration);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);
    useEffect(() => {
        fetchClockOutForceBreakDuration(employeeId).catch(handleError);
    }, [fetchClockOutForceBreakDuration, employeeId]);

    return {
        data: breakDuration,
        setData: setBreakDuration,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchClockOutForceBreakDuration,
    };
};

export const useGetEmployeeMonthlyTimesheets = ({
    timesheetSearch,
    options = { enabled: true },
}: {
    timesheetSearch: TimesheetSearch;
    options?: { enabled: boolean };
}): UseQueryResult<MonthlyTimesheetReport[]> => {
    const [timesheets, setTimesheets] = useState<MonthlyTimesheetReport[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeMonthlyTimesheets = useCallback(
        async (timesheetSearch: TimesheetSearch) => {
            if (!options.enabled) {
                return;
            }
            try {
                setIsFetching(true);
                const employeeTimesheets = await searchEmployeeMonthlyTimesheets(timesheetSearch);
                setTimesheets(employeeTimesheets);
            } catch (error) {
                setError(error);
            }

            setIsFetching(false);
            setIsLoading(false);
        },
        [options.enabled],
    );

    useDeepCompareEffect(() => {
        fetchEmployeeMonthlyTimesheets(timesheetSearch).catch(handleError);
    }, [fetchEmployeeMonthlyTimesheets, timesheetSearch]);

    return {
        data: timesheets,
        setData: setTimesheets,
        isLoading,
        isFetching,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeMonthlyTimesheets(timesheetSearch),
    };
};

export const useTimesheetPaymentsSearch = (search: TimesheetPaymentSearchRequest): UseQueryResult<TimesheetPayment[]> => {
    const [timesheetPayments, setTimesheetPayments] = useState<TimesheetPayment[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchTimesheetPayments = useCallback(async () => {
        setIsLoading(true);
        try {
            const timesheetPayments = await timesheetPaymentsSearch(search);
            setTimesheetPayments(timesheetPayments);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [search]);

    useEffect(() => {
        fetchTimesheetPayments().catch(handleError);
    }, [fetchTimesheetPayments]);

    return {
        data: timesheetPayments,
        setData: setTimesheetPayments,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchTimesheetPayments,
    };
};
