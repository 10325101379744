import { FC } from 'react';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { Cancel01Icon, Tick02Icon } from 'hugeicons-react';

type Props = {
    text: string;
    isValid: boolean;
};

export const PasswordHint: FC<Props> = ({ text, isValid }) => {
    return (
        <FormHelperText component='div' color={isValid ? 'success' : 'error'}>
            <Grid container direction='row' spacing={1} wrap='nowrap' alignItems='center'>
                <Grid item>
                    {isValid && <Tick02Icon size={16} />}
                    {!isValid && <Cancel01Icon size={16} />}
                </Grid>
                <Grid item>
                    <Typography>{text}</Typography>
                </Grid>
            </Grid>
        </FormHelperText>
    );
};
