import { DateRangePickerViewType } from '@/Components/date-range-picker/DateRangePicker';
import { getDateRange } from '@/Components/date-range-picker/DateRangePicker.util';
import { useLocalStorage } from '@/hooks/Storage.hook';
import { formatToLocalDate, LocalDate, toDate } from '@/utils/datetime.util';
import { isValid } from 'date-fns';
import { useState } from 'react';

export const useDateRangeStorage = ({
    storageKey,
    initialViewType = 'MONTH',
}: {
    storageKey: string;
    initialViewType?: DateRangePickerViewType;
}): {
    dateRange: [Date, Date];
    dateRangeViewType: DateRangePickerViewType;
    onDateRangeChange: (newDateRange: [Date, Date], newViewType: DateRangePickerViewType) => void;
} => {
    const [viewType, setViewType] = useLocalStorage<DateRangePickerViewType>(`${storageKey}_viewType`, initialViewType);

    // fallback to default dates depends on view type
    const defaultDates = getDateRange(viewType);
    const defaultDatesAsString: [LocalDate, LocalDate] = [formatToLocalDate(defaultDates[0]), formatToLocalDate(defaultDates[1])];
    const [dateRangeStored, setDateRangeStored] = useLocalStorage<[LocalDate, LocalDate]>(`${storageKey}_dateRange`, defaultDatesAsString);

    // Safety check to ensure that the returned date range is always valid
    const getSafetyDateRange = (range: [LocalDate | undefined, LocalDate | undefined]): [Date, Date] => {
        // return default dates if one date is undefined
        if (!range[0] || !range[1]) {
            return defaultDates;
        }

        // return default dates if one date is not valid or the start date is after the end date
        const rangeAsDate: [Date, Date] = [toDate(range[0], 'UTC'), toDate(range[1], 'UTC')];
        if (!isValid(rangeAsDate[0]) || !isValid(rangeAsDate[1]) || rangeAsDate[0] > rangeAsDate[1]) {
            return defaultDates;
        }

        return rangeAsDate;
    };

    const [dateRange, setDateRange] = useState<[Date, Date]>(getSafetyDateRange(dateRangeStored));

    const handleDateRangeChange = (dates: [Date, Date], viewType: DateRangePickerViewType) => {
        setDateRange(dates);
        setViewType(viewType);

        const datesAsString: [LocalDate, LocalDate] = [formatToLocalDate(dates[0]), formatToLocalDate(dates[1])];
        setDateRangeStored(datesAsString);
    };

    return {
        dateRange: dateRange,
        dateRangeViewType: viewType,
        onDateRangeChange: handleDateRangeChange,
    };
};
