import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { Survey } from '@/domain/survey/Survey.model';
import { calculatePercentage } from '@/utils/math.util';

type Props = {
    survey: Survey;
};

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
}));

export const Participation: FC<Props> = ({ survey }) => {
    const classes = useStyles();

    return (
        <Grid container direction='column' className={classes.root}>
            <Grid item>
                <Typography>
                    {calculatePercentage(survey.participationCount, survey.recipientsCount)}% ({survey.participationCount} / {survey.recipientsCount})
                </Typography>
            </Grid>
            <Grid item>
                <LinearProgress variant='determinate' value={calculatePercentage(survey.participationCount, survey.recipientsCount)} />
            </Grid>
        </Grid>
    );
};
