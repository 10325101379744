import { TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { DayOfWeek, getMinutesFromTimeFormat, MONTHS } from '@/utils/datetime.util';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import i18next from 'i18next';

export const getTimesheetSettingSchema = (): ObjectSchema<TimesheetSettingForm> => {
    return yup.object().shape({
        name: yup.string().required(),
        timesheetMode: yup
            .string()
            .oneOf(Object.values(TimesheetMode) as TimesheetMode[])
            .required(),
        autofillTimesheet: yup.boolean().required(),
        isBonusEnabled: yup.boolean().required(),
        considerMissingTimesheetsAsUnpaidLeave: yup.boolean().required(),
        nightBonusPercentage: yup
            .number()
            .when('isBonusEnabled', {
                is: (isBonusEnabled: boolean) => !isBonusEnabled,
                then: schema => schema,
                otherwise: schema => schema.required().min(0, i18next.t('time_management_settings_page.time_management_configuration.percentage_validation')),
            })
            .default(0),
        nightBonusStartTime: yup.string().when('nightBonusPercentage', {
            is: 0,
            then: schema => schema,
            otherwise: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.start_time_validation'),
                    test: time => validateBonusStartTime(time),
                }),
        }),
        nightBonusEndTime: yup.string().when('nightBonusPercentage', {
            is: 0,
            then: schema => schema,
            otherwise: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.end_time_validation'),
                    test: (time, meta) => validateBonusEndTime(meta.parent.nightBonusStartTime, time),
                }),
        }),
        saturdayBonusPercentage: yup
            .number()
            .when('isBonusEnabled', {
                is: false,
                then: schema => schema,
                otherwise: schema => schema.required().min(0, i18next.t('time_management_settings_page.time_management_configuration.percentage_validation')),
            })
            .default(0),
        saturdayBonusStartTime: yup.string().when(['isBonusEnabled', 'saturdayBonusPercentage'], {
            is: (isBonusEnabled: boolean, saturdayBonusPercentage: number) => {
                return isBonusEnabled && saturdayBonusPercentage !== 0;
            },
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
        saturdayBonusEndTime: yup.string().when(['isBonusEnabled', 'saturdayBonusPercentage'], {
            is: (isBonusEnabled: boolean, saturdayBonusPercentage: number) => {
                return isBonusEnabled && saturdayBonusPercentage !== 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.end_time_bonus_validation'),
                    test: function (saturdayBonusEndTime) {
                        const { saturdayBonusStartTime, saturdayFromDayOfWeek, saturdayToDayOfWeek, saturdayBonusPercentage } = this.parent;
                        if (saturdayBonusPercentage === 0) {
                            return true;
                        }
                        if (saturdayBonusEndTime) {
                            return validateBonusDates(saturdayBonusStartTime, saturdayBonusEndTime, saturdayFromDayOfWeek, saturdayToDayOfWeek);
                        }
                        return false;
                    },
                }),
            otherwise: schema => schema,
        }),
        saturdayFromDayOfWeek: yup
            .string()
            .oneOf(Object.values(DayOfWeek) as DayOfWeek[])
            .when(['isBonusEnabled', 'saturdayBonusPercentage'], {
                is: (isBonusEnabled: boolean, saturdayBonusPercentage: number) => {
                    return isBonusEnabled && saturdayBonusPercentage !== 0;
                },
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        saturdayToDayOfWeek: yup
            .string()
            .oneOf(Object.values(DayOfWeek) as DayOfWeek[])
            .when(['isBonusEnabled', 'saturdayBonusPercentage'], {
                is: (isBonusEnabled: boolean, saturdayBonusPercentage: number) => {
                    return isBonusEnabled && saturdayBonusPercentage !== 0;
                },
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        sundayBonusPercentage: yup.number().when('isBonusEnabled', {
            is: false,
            then: schema => schema,
            otherwise: schema => schema.min(0, i18next.t('time_management_settings_page.time_management_configuration.percentage_validation')).required(),
        }),
        sundayBonusStartTime: yup.string().when(['isBonusEnabled', 'sundayBonusPercentage'], {
            is: (isBonusEnabled: boolean, sundayBonusPercentage: number) => {
                return isBonusEnabled && sundayBonusPercentage !== 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.start_time_bonus_validation'),
                    test: function (sundayBonusStartTime) {
                        const { saturdayToDayOfWeek, saturdayBonusEndTime, sundayFromDayOfWeek, saturdayBonusPercentage, sundayBonusPercentage } = this.parent;
                        if (saturdayBonusPercentage === 0 || sundayBonusPercentage === 0) {
                            return true;
                        }
                        if (saturdayToDayOfWeek === DayOfWeek.SUNDAY && sundayFromDayOfWeek === DayOfWeek.SATURDAY) {
                            return false;
                        }
                        if (sundayBonusStartTime) {
                            return validateBonusDates(saturdayBonusEndTime, sundayBonusStartTime, saturdayToDayOfWeek, sundayFromDayOfWeek);
                        }
                        return false;
                    },
                }),
            otherwise: schema => schema,
        }),
        sundayBonusEndTime: yup.string().when(['isBonusEnabled', 'sundayBonusPercentage'], {
            is: (isBonusEnabled: boolean, sundayBonusPercentage: number) => {
                return isBonusEnabled && sundayBonusPercentage !== 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.end_time_bonus_validation'),
                    test: function (sundayBonusEndTime) {
                        const { sundayFromDayOfWeek, sundayBonusStartTime, sundayToDayOfWeek, sundayBonusPercentage } = this.parent;
                        if (sundayBonusPercentage === 0) {
                            return true;
                        }
                        if (sundayBonusEndTime) {
                            return validateBonusDates(sundayBonusStartTime, sundayBonusEndTime, sundayFromDayOfWeek, sundayToDayOfWeek);
                        }
                        return false;
                    },
                }),
            otherwise: schema => schema,
        }),
        sundayFromDayOfWeek: yup
            .string()
            .oneOf(Object.values(DayOfWeek) as DayOfWeek[])
            .when(['isBonusEnabled', 'sundayBonusPercentage'], {
                is: (isBonusEnabled: boolean, sundayBonusPercentage: number) => {
                    return isBonusEnabled && sundayBonusPercentage !== 0;
                },
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        sundayToDayOfWeek: yup
            .string()
            .oneOf(Object.values(DayOfWeek) as DayOfWeek[])
            .when(['isBonusEnabled', 'sundayBonusPercentage'], {
                is: (isBonusEnabled: boolean, sundayBonusPercentage: number) => {
                    return isBonusEnabled && sundayBonusPercentage !== 0;
                },
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        cycleStartMonth: yup
            .string()
            .oneOf(Object.values(MONTHS) as MONTHS[])
            .required(),
        mandatoryComment: yup.boolean().required(),
        breakDisplayEnabled: yup.boolean().required(),
        mobileClockInOut: yup.boolean().required(),
        allowClockInOutOutsideWorkHours: yup.boolean().required(),
        allowClockInOutOnSundayAndPublicHolidays: yup.boolean().required(),
        forceBreakClockInOut: yup.boolean().required(),
        forceSmallBreakDurationInMinutes: yup
            .number()
            .required()
            .when('forceBreakClockInOut', {
                is: true,
                then: schema => schema.min(5, i18next.t('time_management_settings_page.time_management_configuration.force_break_clock_in_out_not_empty')),
                otherwise: schema => schema.min(0),
            }),
        forceSmallBreakAfterXHours: yup
            .number()
            .when('forceBreakClockInOut', {
                is: true,
                then: schema =>
                    schema.required().min(0.5, i18next.t('time_management_settings_page.time_management_configuration.force_break_clock_in_out_not_empty')),
                otherwise: schema => schema.min(0),
            })
            .default(0),
        forceBigBreakDurationInMinutes: yup
            .number()
            .required()
            .min(0)
            .test({
                message: i18next.t('time_management_settings_page.time_management_configuration.force_break_rule_2_must_be_bigger'),
                test: function (forceBigBreakDurationInMinutes) {
                    const { forceSmallBreakDurationInMinutes } = this.parent;
                    if (forceBigBreakDurationInMinutes === 0) {
                        return true;
                    }
                    return forceBigBreakDurationInMinutes >= forceSmallBreakDurationInMinutes;
                },
            }),
        forceBigBreakAfterXHours: yup
            .number()
            .when(['forceBreakClockInOut', 'forceBigBreakDurationInMinutes'], {
                is: (forceBreakClockInOut: boolean, forceBigBreakDurationInMinutes: number) => {
                    return forceBreakClockInOut && forceBigBreakDurationInMinutes > 0;
                },
                then: schema =>
                    schema
                        .required()
                        .min(0.5, i18next.t('time_management_settings_page.time_management_configuration.force_break_clock_in_out_not_empty'))
                        .test({
                            message: i18next.t('time_management_settings_page.time_management_configuration.force_break_rule_2_must_be_bigger'),
                            test: function (forceBigBreakAfterXHours) {
                                const { forceSmallBreakAfterXHours } = this.parent;
                                return forceBigBreakAfterXHours > forceSmallBreakAfterXHours;
                            },
                        }),
                otherwise: schema => schema.min(0),
            })
            .default(0),
        isForceBreakToBeTakenEnabled: yup.boolean().required(),
        forceBreakToBeTakenFrom: yup.string().when(['isForceBreakToBeTakenEnabled', 'forceBreakClockInOut'], {
            is: (isForceBreakToBeTakenEnabled: boolean, forceBreakClockInOut: boolean) => {
                return isForceBreakToBeTakenEnabled && forceBreakClockInOut;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.start_time_validation'),
                    test: time => validateStartTime(time),
                }),
            otherwise: schema => schema.nullable(),
        }),
        forceBreakToBeTakenTo: yup.string().when(['isForceBreakToBeTakenEnabled', 'forceBreakClockInOut'], {
            is: (isForceBreakToBeTakenEnabled: boolean, forceBreakClockInOut: boolean) => {
                return isForceBreakToBeTakenEnabled && forceBreakClockInOut;
            },
            then: schema =>
                schema
                    .required()
                    .test({
                        message: i18next.t('time_management_settings_page.time_management_configuration.end_time_validation'),
                        test: (time, meta) => validateEndTime(meta.parent.forceBreakToBeTakenFrom, time),
                    })
                    .test({
                        message: i18next.t('time_management_settings_page.time_management_configuration.force_break_to_be_taken_conflict_with_rules'),
                        test: (time, meta) =>
                            validateNoOverlapping(
                                meta.parent.rule1PaidBreaksMaximumDurationInMinutes,
                                meta.parent.rule1PaidBreaksFrom,
                                meta.parent.rule1PaidBreaksTo,
                                meta.parent.forceBreakToBeTakenFrom,
                                time,
                            ) &&
                            validateNoOverlapping(
                                meta.parent.rule2PaidBreaksMaximumDurationInMinutes,
                                meta.parent.rule2PaidBreaksFrom,
                                meta.parent.rule2PaidBreaksTo,
                                meta.parent.forceBreakToBeTakenFrom,
                                time,
                            ),
                    }),
            otherwise: schema => schema.nullable(),
        }),
        paidBreaksSundayPublicHolidays: yup.boolean().required(),
        paidBreaksSundayPublicHolidaysMaximumDurationInMinutes: yup
            .number()
            .max(1120)
            .default(0)
            .required()
            .when('paidBreaksSundayPublicHolidays', {
                is: true,
                then: schema => schema.min(1),
                otherwise: schema => schema.min(0),
            }),
        paidBreaksSaturday: yup.boolean().required(),
        paidBreaksSaturdayMaximumDurationInMinutes: yup
            .number()
            .max(1120)
            .default(0)
            .required()
            .when('paidBreaksSaturday', {
                is: true,
                then: schema => schema.min(1),
                otherwise: schema => schema.min(0),
            }),
        isPaidBreaksEnabled: yup.boolean().required(),
        rule1PaidBreaksFrom: yup.string().when(['isPaidBreaksEnabled', 'rule1PaidBreaksMaximumDurationInMinutes'], {
            is: (isPaidBreaksEnabled: boolean, rule1PaidBreaksMaximumDurationInMinutes: number) => {
                return isPaidBreaksEnabled && rule1PaidBreaksMaximumDurationInMinutes > 0;
            },
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
        rule1PaidBreaksTo: yup.string().when(['isPaidBreaksEnabled', 'rule1PaidBreaksMaximumDurationInMinutes'], {
            is: (isPaidBreaksEnabled: boolean, rule1PaidBreaksMaximumDurationInMinutes: number) => {
                return isPaidBreaksEnabled && rule1PaidBreaksMaximumDurationInMinutes > 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.rule_paid_breaks_overlapping'),
                    test: (time, meta) =>
                        validateNoOverlapping(
                            meta.parent.rule2PaidBreaksMaximumDurationInMinutes,
                            meta.parent.rule2PaidBreaksFrom,
                            meta.parent.rule2PaidBreaksTo,
                            meta.parent.rule1PaidBreaksFrom,
                            time,
                        ),
                }),
            otherwise: schema => schema,
        }),
        rule1PaidBreaksMaximumDurationInMinutes: yup.number().min(0).max(1120).default(0).required(),
        rule2PaidBreaksFrom: yup.string().when(['isPaidBreaksEnabled', 'rule2PaidBreaksMaximumDurationInMinutes'], {
            is: (isPaidBreaksEnabled: boolean, rule2PaidBreaksMaximumDurationInMinutes: number) => {
                return isPaidBreaksEnabled && rule2PaidBreaksMaximumDurationInMinutes > 0;
            },
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
        rule2PaidBreaksTo: yup.string().when(['isPaidBreaksEnabled', 'rule2PaidBreaksMaximumDurationInMinutes'], {
            is: (isPaidBreaksEnabled: boolean, rule2PaidBreaksMaximumDurationInMinutes: number) => {
                return isPaidBreaksEnabled && rule2PaidBreaksMaximumDurationInMinutes > 0;
            },
            then: schema =>
                schema.required().test({
                    message: i18next.t('time_management_settings_page.time_management_configuration.rule_paid_breaks_overlapping'),
                    test: (time, meta) =>
                        validateNoOverlapping(
                            meta.parent.rule1PaidBreaksMaximumDurationInMinutes,
                            meta.parent.rule1PaidBreaksFrom,
                            meta.parent.rule1PaidBreaksTo,
                            meta.parent.rule2PaidBreaksFrom,
                            time,
                        ),
                }),
            otherwise: schema => schema,
        }),
        rule2PaidBreaksMaximumDurationInMinutes: yup.number().min(0).max(1120).default(0).required(),
        forceShiftStartTimeOnClockIn: yup.boolean().required(),
        forceShiftStartTimeBeforeInMinutes: yup
            .number()
            .when('forceShiftStartTimeOnClockIn', {
                is: true,
                then: schema => schema.min(0).max(240),
                otherwise: schema => schema.min(0),
            })
            .default(0),
        maximumWeeklyAdditionalWorkingTime: yup.number().min(0).max(100).default(45).required(),
    });
};

const validateBonusEndTime = (startTime: string, endTime: string) => {
    const MINUTES_IN_17_HOURS = 1020;
    const MINUTES_IN_9_HOURS = 540;
    const start = getMinutesFromTimeFormat(startTime);
    const end = getMinutesFromTimeFormat(endTime);

    if (start == undefined || end == undefined) {
        return false;
    }

    if (start < MINUTES_IN_17_HOURS && end < start) {
        return false;
    }

    return 0 < end && end <= MINUTES_IN_9_HOURS;
};

const validateBonusStartTime = (startTime: string) => {
    const MINUTES_IN_17_HOURS = 1020;
    const MINUTES_IN_24_HOURS = 1440;
    const MINUTES_IN_2_HOURS = 120;

    const start = getMinutesFromTimeFormat(startTime);
    if (start == undefined) {
        return false;
    }

    return (MINUTES_IN_17_HOURS <= start && start < MINUTES_IN_24_HOURS) || start <= MINUTES_IN_2_HOURS;
};

const validateEndTime = (startTime: string, endTime: string) => {
    const start = getMinutesFromTimeFormat(startTime);
    const end = getMinutesFromTimeFormat(endTime);

    if (start == undefined || end == undefined) {
        return false;
    }

    return end >= start;
};

const validateNoOverlapping = (maximumDuration: number, ruleStartTime: string, ruleEndTime: string, startTime: string, endTime: string) => {
    if (maximumDuration === 0) {
        //there is no need to validate the overlapping
        return true;
    }
    const start = getMinutesFromTimeFormat(startTime);
    const end = getMinutesFromTimeFormat(endTime);
    const ruleStart = getMinutesFromTimeFormat(ruleStartTime);
    const ruleEnd = getMinutesFromTimeFormat(ruleEndTime);

    if (start == undefined || end == undefined || ruleStart == undefined || ruleEnd == undefined) {
        return false;
    }

    //we need to check if the rules is over the night, if so we need to split into 2
    if (ruleStart > ruleEnd) {
        return isOverlapping(ruleStart, 1440, start, end) && isOverlapping(0, ruleEnd, start, end);
    }

    return isOverlapping(ruleStart, ruleEnd, start, end);
};

const isOverlapping = (ruleStartTime: number, ruleEndTime: number, start: number, end: number) => {
    return end <= ruleStartTime || start >= ruleEndTime;
};

const validateStartTime = (startTime: string) => {
    return !!getMinutesFromTimeFormat(startTime);
};

const validateBonusDates = (bonusStartTime: string, bonusEndTime: string, bonusFromDayOfWeek: DayOfWeek, bonusToDayOfWeek: DayOfWeek) => {
    if (bonusFromDayOfWeek === bonusToDayOfWeek) {
        const startMinutes = getMinutesFromTimeFormat(bonusStartTime);
        const endMinutes = getMinutesFromTimeFormat(bonusEndTime);
        if (startMinutes == undefined || endMinutes == undefined || startMinutes > endMinutes) {
            return false;
        }
    }
    return true;
};
