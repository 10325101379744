import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CostCenter } from '@/domain/cost-center/CostCenter.model';
import { DialogWrapper } from '@/Components/dialog-wrapper/DialogWrapper';

type Props = {
    open: boolean;
    onSave: (data: CostCenterFormValues) => void;
    closeDialog: () => void;
    costCenter: CostCenter | undefined;
};

const costCenterSchema = yup.object().shape({
    name: yup.string().trim().required(),
});

export type CostCenterFormValues = yup.InferType<typeof costCenterSchema>;

export const CostCenterSettingsDialog: FC<Props> = ({ open, onSave, closeDialog, costCenter }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<CostCenterFormValues>({
        resolver: yupResolver(costCenterSchema),
        defaultValues: {
            name: costCenter?.name ?? '',
        },
    });

    const handleOnSave = (data: CostCenterFormValues) => {
        onSave(data);
    };
    const onClose = () => {
        closeDialog();
    };

    const title = costCenter ? t('cost_center_setting_page.dialog.edit_title') : t('cost_center_setting_page.dialog.add_title');

    return (
        <DialogWrapper header={title} open={open} onClose={onClose}>
            <Stack component={DialogContent}>
                <Controller
                    name='name'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControlLabel
                            aria-label={'cost-center-name'}
                            label={t('cost_center_setting_page.dialog.name_input')}
                            labelPlacement='top'
                            control={<TextField fullWidth {...field} error={!!error} helperText={error?.message} />}
                        />
                    )}
                />
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleOnSave, console.error)} variant='contained'>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
