import { API_BASE_URL, client } from '@/api/common';
import { EmploymentDTO, mapEmploymentDTO } from '@/api/employment/Employment.api';
import {
    Employee,
    EmployeeAnniversary,
    EmployeeAnniversaryRequest,
    EmployeeAuthentication,
    EmployeeAvatar,
    EmployeeBasicInfo,
    EmployeeBasicInfoUpdateMutation,
    EmployeeEmailUpdateMutation,
    EmployeeLoginMethod,
    EmployeeLoginMethodUpdateMutation,
    EmployeeLoginMutation,
    EmployeeSearch,
    EmployeeSearchLoginMethodRequest,
    EmployeeSettings,
    EmployeeSettingsUpdateMutation,
    EmployeeShiftSettings,
    EmployeeShiftSettingsUpdateMutation,
} from '@/domain/employee/Employee.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { LoginMethod } from '@/domain/realm/Realm.model';
import { AxiosResponse } from 'axios';

type EmployeeAuthenticationDTO = EmployeeAuthentication;

export type EmployeeDTO = Overwrite<Employee, { currentEmployments: EmploymentDTO[] }>;
export type EmployeeAvatarDTO = EmployeeAvatar;

type EmployeeLoginMethodDTO = EmployeeLoginMethod;

export const mapEmployeeDTO = ({ currentEmployments, ...employee }: EmployeeDTO): Employee => {
    return {
        ...employee,
        currentEmployments: currentEmployments?.map(mapEmploymentDTO) ?? [],
    };
};

type EmployeeAnniversaryRequestDTO = DateToString<EmployeeAnniversaryRequest>;
export type EmployeeSearchRequestDTO = EmployeeSearch;
export type EmployeeAnniversaryDTO = EmployeeAnniversary;

const getCurrentEmployee = async (): Promise<EmployeeAuthenticationDTO> => {
    return (await client.get<EmployeeAuthenticationDTO>(API_BASE_URL + '/employees/me')).data;
};

const getEmployeeById = async (employeeId: number): Promise<Employee> => {
    const { data } = await client.get<EmployeeDTO>(API_BASE_URL + '/employees/' + employeeId);
    return mapEmployeeDTO(data);
};

const searchEmployees = async (request: EmployeeSearch = {}): Promise<Employee[]> => {
    const { data } = await client.post<EmployeeDTO[], AxiosResponse<EmployeeDTO[]>, EmployeeSearchRequestDTO>(API_BASE_URL + '/employees/search', request);

    return data.map(mapEmployeeDTO);
};

const searchEmployeeBirthdays = async (request: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    const url = API_BASE_URL + '/employees/birthday';

    return (await client.post<EmployeeAnniversaryDTO[], AxiosResponse<EmployeeAnniversaryDTO[]>, EmployeeAnniversaryRequestDTO>(url, request)).data;
};

const searchEmployeeWorkAnniversaries = async (request: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    const url = API_BASE_URL + '/employees/work-anniversary';
    return (await client.post<EmployeeAnniversaryDTO[], AxiosResponse<EmployeeAnniversaryDTO[]>, EmployeeAnniversaryRequestDTO>(url, request)).data;
};

const deleteEmployee = async (employeeId: number, email: string): Promise<void> => {
    await client.delete(API_BASE_URL + '/employees/' + employeeId + '/' + email);
};

const searchCurrentEmployeeLoginMethod = async (request: EmployeeSearchLoginMethodRequest): Promise<EmployeeLoginMethodDTO> => {
    return (
        await client.post<EmployeeLoginMethodDTO, AxiosResponse<EmployeeLoginMethodDTO>, EmployeeSearchLoginMethodRequest>(
            API_BASE_URL + `/employees/login-method`,
            request,
        )
    ).data;
};

const getEmployeeLoginMethod = async (employeeId: number): Promise<LoginMethod> => {
    return (await client.get<LoginMethod>(API_BASE_URL + `/employees/${employeeId}/login-method`)).data;
};

const updateLoginMethod = async (employeeId: number, mutation: EmployeeLoginMethodUpdateMutation): Promise<Employee> => {
    return (
        await client.put<Employee, AxiosResponse<Employee>, EmployeeLoginMethodUpdateMutation>(API_BASE_URL + `/employees/${employeeId}/login-method`, mutation)
    ).data;
};

const updateEmployeeLogin = async (mutation: EmployeeLoginMutation): Promise<void> => {
    return client.post(API_BASE_URL + `/employees/login`, mutation);
};

const updateEmployeeBasicInfo = async (employeeId: number, mutation: EmployeeBasicInfoUpdateMutation): Promise<EmployeeBasicInfo> => {
    return (
        await client.put<EmployeeBasicInfo, AxiosResponse<EmployeeBasicInfo>, EmployeeBasicInfoUpdateMutation>(
            API_BASE_URL + `/employees/${employeeId}/basic-info`,
            mutation,
        )
    ).data;
};

const updateEmail = async (employeeId: number, mutation: EmployeeEmailUpdateMutation): Promise<void> => {
    return (await client.put(API_BASE_URL + `/employees/${employeeId}/email`, mutation)).data;
};

const deactivateEmployee = async (employeeId: number): Promise<Employee> => {
    const data = (await client.put<EmployeeDTO>(API_BASE_URL + `/employees/${employeeId}/deactivate`, {})).data;
    return mapEmployeeDTO(data);
};

const activateEmployee = async (employeeId: number): Promise<Employee> => {
    const data = (await client.put<EmployeeDTO>(API_BASE_URL + `/employees/${employeeId}/activate`, {})).data;
    return mapEmployeeDTO(data);
};

const uploadAvatar = async (employeeId: number, key: string): Promise<Employee> => {
    const data = (await client.put<EmployeeDTO, AxiosResponse<EmployeeDTO>>(API_BASE_URL + `/employees/${employeeId}/avatar?key=${key}`, {})).data;
    return mapEmployeeDTO(data);
};

const updateEmployeeShiftSettings = async (userId: number, mutation: EmployeeShiftSettingsUpdateMutation): Promise<EmployeeShiftSettings> => {
    return (
        await client.put<EmployeeShiftSettings, AxiosResponse<EmployeeShiftSettings>, EmployeeShiftSettingsUpdateMutation>(
            API_BASE_URL + `/employees/${userId}/shift-settings`,
            mutation,
        )
    ).data;
};

const updateEmployeeSettings = async (mutation: EmployeeSettingsUpdateMutation): Promise<EmployeeSettings> => {
    return (
        await client.put<EmployeeSettings, AxiosResponse<EmployeeSettings>, EmployeeSettingsUpdateMutation>(API_BASE_URL + `/employees/me/settings`, mutation)
    ).data;
};

const importEmployees = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/employees/import`, request)).data;
};

const importEmployeeRoles = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/employee/roles/import`, request)).data;
};

export const employeeAPI = {
    getCurrentEmployee,
    getEmployeeById,
    searchEmployees,
    searchEmployeeBirthdays,
    searchEmployeeWorkAnniversaries,
    deleteEmployee,
    searchCurrentEmployeeLoginMethod,
    getEmployeeLoginMethod,
    updateLoginMethod,
    updateEmployeeLogin,
    updateEmployeeBasicInfo,
    updateEmail,
    deactivateEmployee,
    activateEmployee,
    uploadAvatar,
    updateEmployeeShiftSettings,
    updateEmployeeSettings,
    importEmployees,
    importEmployeeRoles,
};
