import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { handleError } from '@/utils/api.util';
import { Document, PreviewData } from '@/domain/document/Document.model';
import { getEmployeeDocumentDownloadUrl, getEmployeeDocuments, getPreviewURLLink } from '@/domain/document/Document.service';
import List from '@mui/material/List';
import { DocumentListItem } from '@/page/document/document-list-item/DocumentListItem';
import { Paper, Stack } from '@mui/material';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { useAppDispatch } from '@/stores/store';

export const EmployeeDocumentsPage: FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<PreviewData>();
    const userId = Number(params.userId) || undefined;
    const folderId = Number(params.folderId) || undefined;
    const folderName = params.folderName;

    const [folderDocuments, setFolderDocuments] = useState<Document[]>([]);

    useEffect(() => {
        if (folderName) {
            dispatch({ type: UiActionType.BREADCRUMB, breadCrumb: [{ label: folderName }] });
        }
    }, [dispatch, folderName]);

    useEffect(() => {
        if (folderId && userId) {
            getEmployeeDocuments(Number(folderId), Number(userId))
                .then(documents => setFolderDocuments(documents))
                .catch(handleError);
        }
    }, [folderId, userId]);

    const onClosePreviewDialog = () => {
        setDialogOpen(false);
    };

    const onDocumentClick = (document: Document) => {
        getEmployeeDocumentDownloadUrl(document.id, 'INLINE')
            .then(documentDownloadUrl => {
                if (document.documentType === 'DOCUMENT') {
                    setPreviewData({
                        url: getPreviewURLLink(document, documentDownloadUrl),
                        document,
                    });
                    setDialogOpen(true);
                } else {
                    window.open(documentDownloadUrl, '_blank');
                }
            })
            .catch(handleError);
    };

    const onDownloadClick = (documentData: Document) => {
        getEmployeeDocumentDownloadUrl(documentData.id, 'ATTACHMENT')
            .then(documentDownloadUrl => {
                // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
                setTimeout(() => {
                    window.open(documentDownloadUrl, '_blank');
                });
            })
            .catch(handleError);
    };

    return (
        <>
            <Stack component={Paper} flex={1} p={1}>
                <List component='nav' aria-label='main mailbox folders' disablePadding>
                    {folderDocuments?.map((document, index) => {
                        return <DocumentListItem key={document.id} index={index} document={document} onClickItem={() => onDocumentClick(document)} />;
                    })}
                </List>
            </Stack>
            {dialogOpen && <DocumentPreviewDialog onClose={onClosePreviewDialog} previewData={previewData} onDownloadClick={onDownloadClick} />}
        </>
    );
};
