import { FC, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ResponsivePie } from '@nivo/pie';
import { calculatePercentage } from '@/utils/math.util';

type Props = {
    data: PieChartData;
};

const useStyles = makeStyles(() => ({
    totalPie: {
        width: 310,
        height: 160,
        position: 'relative',
        '& svg': {
            width: 350,
            height: 300,
        },
    },
    progressStatus: {
        position: 'absolute',
        display: 'flex',
        width: 80,
        height: 80,
        top: 0,
        left: 47,
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 30,
        textAlign: 'center',
        bottom: 0,
        margin: 'auto',
    },
    totalPercent: {
        fontSize: 20,
        color: '#01153C',
    },
    average: {
        fontSize: 12,
        color: '#585869',
    },
}));

interface PieChartData {
    title: string;
    average: PieChartValue;
    total: PieChartValue;
}

interface PieResults {
    id: string;
    label: string;
    value: number;
}

interface PieChartValue {
    title: string;
    value: number;
}

export const TotalPieChart: FC<Props> = ({ data }) => {
    const classes = useStyles();
    const [pieResults, setPieResults] = useState<PieResults[]>();

    useEffect(() => {
        const result = [];
        const averageNumber = {
            id: data.average.title,
            label: `${calculatePercentage(data.average.value, data.total.value)}% ${data.average.title}`,
            value: data.average.value,
        };
        const totalNumber = {
            id: data.total.title,
            label: `${calculatePercentage(data.total.value - data.average.value, data.total.value)}% ${data.total.title}`,
            value: data.total.value - data.average.value,
        };
        result[0] = averageNumber;
        result[1] = totalNumber;
        setPieResults(result);
    }, [data]);
    return (
        <div className={classes.totalPie} style={{ fontFamily: 'sans-serif' }}>
            <ResponsivePie
                data={pieResults ?? []}
                theme={{
                    text: {
                        fontFamily: 'Arial',
                    },
                    axis: {
                        legend: {
                            text: {
                                fontSize: 120,
                                fill: '#333333',
                            },
                        },
                    },
                }}
                margin={{ top: 10, right: 140, bottom: 0, left: 0 }}
                innerRadius={0.85}
                padAngle={0.9}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={0}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor='#333333'
                colors={['#01CDCD', '#01153C', '#EAF8F9']}
                // keys={['earnings', 'bonus']}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                }}
                defs={[
                    {
                        id: 'dotss',
                        type: 'patternDots',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        size: 4,
                        padding: 1,
                        stagger: true,
                    },
                    {
                        id: 'liness',
                        type: 'patternLines',
                        background: 'inherit',
                        color: 'rgba(255, 255, 255, 0.3)',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                    },
                ]}
                fill={[
                    {
                        match: {
                            id: 'ruby',
                        },
                        id: 'dotss',
                    },
                    {
                        match: {
                            id: 'c',
                        },
                        id: 'dotss',
                    },
                    {
                        match: {
                            id: 'go',
                        },
                        id: 'dotss',
                    },
                    {
                        match: {
                            id: 'python',
                        },
                        id: 'dotss',
                    },
                    {
                        match: {
                            id: 'scala',
                        },
                        id: 'liness',
                    },
                    {
                        match: {
                            id: 'lisp',
                        },
                        id: 'liness',
                    },
                    {
                        match: {
                            id: 'elixir',
                        },
                        id: 'liness',
                    },
                    {
                        match: {
                            id: 'javascript',
                        },
                        id: 'liness',
                    },
                ]}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 60,
                        translateY: 0,
                        itemsSpacing: 12,
                        itemWidth: 50,
                        itemHeight: 22,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000',
                                },
                            },
                        ],
                    },
                ]}
            />
            <div className={classes.progressStatus}>
                <Grid item>
                    <Typography className={classes.totalPercent}>{calculatePercentage(data.average.value, data.total.value)}%</Typography>
                    <Typography className={classes.average}>{data.title}</Typography>
                </Grid>
            </div>
        </div>
    );
};
