import { HomePage } from '@/page/home/HomePage';
import { Events } from '@/page/home/events/Events';
import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RouteObject } from 'react-router-dom';

export const homeRoutes: RouteObject[] = [
    {
        path: '/',
        element: (
            <PageContainer
                breadCrumb={[
                    {
                        nameKey: 'pages.home',
                    },
                ]}
            >
                <HomePage />
            </PageContainer>
        ),
        errorElement: <RouterBoundary />,
    },
    {
        path: '/events',
        element: <PageContainer breadCrumb={[{ nameKey: 'pages.events' }]}>{<Events />}</PageContainer>,
        errorElement: <RouterBoundary />,
    },
];
