import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { Alert02Icon, AlertCircleIcon, InformationCircleIcon, MoreVerticalIcon as HugeMoreVerticalIcon, Tick02Icon } from 'hugeicons-react';

export const SuccessIcon: FC<SvgIconProps> = props => (
    <SvgIcon {...props}>
        <Tick02Icon />
    </SvgIcon>
);
export const ErrorIcon: FC<SvgIconProps> = props => (
    <SvgIcon {...props}>
        <AlertCircleIcon />
    </SvgIcon>
);

export const WarningIcon: FC<SvgIconProps> = props => (
    <SvgIcon {...props}>
        <Alert02Icon />
    </SvgIcon>
);

export const InfoIcon: FC<SvgIconProps> = props => (
    <SvgIcon {...props}>
        <InformationCircleIcon />
    </SvgIcon>
);

export const StatusIcon: FC<SvgIconProps> = props => (
    <SvgIcon {...props}>
        <ellipse cx='8' cy='8' rx='3' ry='3' />{' '}
    </SvgIcon>
);

export const BadProgressIcon: FC<SvgIconProps> = props => (
    <SvgIcon {...props} color={'error'}>
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g>
                <rect width='24' height='24' rx='5' fill='#FEE5E5' />
                <path
                    d='M19.6427 20.407C18.6172 20.407 17.7853 19.5762 17.7853 18.5497V5.6884C17.7853 4.66194 18.6172 3.83008 19.6427 3.83008C20.6681 3.83008 21.5 4.66194 21.5 5.6884V18.5497C21.5 19.5762 20.6681 20.407 19.6427 20.407Z'
                    stroke='#B80F10'
                />
                <path
                    d='M12.4999 20.4034C11.4744 20.4034 10.6426 19.5725 10.6426 18.546V11.2606C10.6426 10.2342 11.4744 9.40234 12.4999 9.40234C13.5254 9.40234 14.3573 10.2342 14.3573 11.2606V18.546C14.3573 19.5725 13.5254 20.4034 12.4999 20.4034Z'
                    stroke='#B80F10'
                />
                <path
                    d='M5.35734 12.3828C3.91791 12.3828 2.75 13.5492 2.75 14.9901V18.5491C2.75 19.9901 3.91788 21.1565 5.35734 21.1565C6.79679 21.1565 7.96469 19.9901 7.96469 18.5491V14.9901C7.96469 13.5492 6.79677 12.3828 5.35734 12.3828Z'
                    fill='#B80F10'
                />
            </g>
        </svg>
    </SvgIcon>
);

export const ModerateProgressIcon: FC<SvgIconProps> = props => (
    <SvgIcon {...props} color={'warning'}>
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g>
                <rect width='24' height='24' rx='5' fill='#FCEDE1' />
                <path
                    d='M19.6427 20.407C18.6172 20.407 17.7853 19.5762 17.7853 18.5497V5.6884C17.7853 4.66194 18.6172 3.83008 19.6427 3.83008C20.6681 3.83008 21.5 4.66194 21.5 5.6884V18.5497C21.5 19.5762 20.6681 20.407 19.6427 20.407Z'
                    stroke='#CF6611'
                />
                <path
                    d='M5.35734 12.3828C3.91791 12.3828 2.75 13.5492 2.75 14.9901V18.5491C2.75 19.9901 3.91788 21.1565 5.35734 21.1565C6.79679 21.1565 7.96469 19.9901 7.96469 18.5491V14.9901C7.96469 13.5492 6.79677 12.3828 5.35734 12.3828Z'
                    fill='#CF6611'
                />
                <path
                    d='M12.4999 8.65234C11.0599 8.65234 9.89258 9.82029 9.89258 11.2606V18.546C9.89258 19.9869 11.0604 21.1534 12.4999 21.1534C13.9393 21.1534 15.1073 19.987 15.1073 18.546V11.2606C15.1073 9.82024 13.9398 8.65234 12.4999 8.65234Z'
                    fill='#CF6611'
                />
            </g>
        </svg>
    </SvgIcon>
);

export const GoodProgressIcon: FC<SvgIconProps> = props => (
    <SvgIcon {...props} color={'success'}>
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g>
                <rect width='24' height='24' rx='5' fill='#DAF7F2' />
                <path
                    d='M5.35734 12.3828C3.91791 12.3828 2.75 13.5492 2.75 14.9901V18.5491C2.75 19.9901 3.91788 21.1565 5.35734 21.1565C6.79679 21.1565 7.96469 19.9901 7.96469 18.5491V14.9901C7.96469 13.5492 6.79677 12.3828 5.35734 12.3828Z'
                    fill='#008069'
                />
                <path
                    d='M12.4999 8.65234C11.0599 8.65234 9.89258 9.82029 9.89258 11.2606V18.546C9.89258 19.9869 11.0604 21.1534 12.4999 21.1534C13.9393 21.1534 15.1073 19.987 15.1073 18.546V11.2606C15.1073 9.82024 13.9398 8.65234 12.4999 8.65234Z'
                    fill='#008069'
                />
                <path
                    d='M19.6426 3.08008C18.2026 3.08008 17.0352 4.24796 17.0352 5.6884V18.5497C17.0352 19.9907 18.2032 21.157 19.6426 21.157C21.0819 21.157 22.2499 19.9907 22.2499 18.5497V5.6884C22.2499 4.24798 21.0824 3.08008 19.6426 3.08008Z'
                    fill='#008069'
                />
            </g>
        </svg>
    </SvgIcon>
);

export const MoreVerticalIcon: FC = () => <HugeMoreVerticalIcon strokeWidth={3} />;
