import { EmptyState } from '@/Components/empty-state/EmptyState';
import { EmptyStateIcon, ErrorEmptyStateIcon } from '@/Components/empty-state/EmptyStateIcons';
import { sendErrorInBugsnag } from '@/utils/api.util';
import { Box, CircularProgress, Paper } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type StateHandleProps = {
    isLoading: boolean;
    isError: boolean;
    isEmpty?: boolean;
    emptyStateComponent?: ReactNode;
    errorComponent?: ReactNode;
    loadingComponent?: ReactNode;
    children?: (() => ReactNode) | ReactNode;
    error: unknown;
};
export const StateHandler: FC<StateHandleProps> = ({ isLoading, isEmpty, isError, emptyStateComponent, loadingComponent, errorComponent, children, error }) => {
    const { t } = useTranslation();

    if (!isLoading && isError) {
        if (error) {
            sendErrorInBugsnag(error);
        }
        return (
            errorComponent ?? (
                <Paper sx={{ display: 'flex', flex: '1' }}>
                    <EmptyState
                        flex={1}
                        icon={<ErrorEmptyStateIcon />}
                        title={t('general.errors.default_title')}
                        subTitle={t('general.errors.default_sub_title')}
                    />
                </Paper>
            )
        );
    }

    if (isLoading) {
        return (
            loadingComponent ?? (
                <Paper sx={{ display: 'flex', flex: '1' }}>
                    <Box display='flex' flex='1' justifyContent='center' alignItems='center'>
                        <CircularProgress />
                    </Box>
                </Paper>
            )
        );
    }

    if (!isLoading && !isError && isEmpty) {
        return (
            <Paper sx={{ display: 'flex', flex: '1' }}>
                {emptyStateComponent ?? <EmptyState flex='1' justifyContent='center' alignItems='center' icon={<EmptyStateIcon />} />}
            </Paper>
        );
    }

    if (!isLoading && !isError && !isEmpty) {
        if (typeof children === 'function') {
            return children();
        } else {
            return children;
        }
    }
};
