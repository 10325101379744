import { SurveyAnswer, SurveyQuestion } from '@/domain/survey/Survey.model';
import { Grid, Paper, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';
import { ChangeEvent, FC } from 'react';

import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import Slide from '@mui/material/Slide';

type Props = {
    onSelect: (value: number) => void;
    question: SurveyQuestion;
    answer: SurveyAnswer;
    selectedLanguage: UserLanguage;
};

const useStyles = makeStyles(theme => ({
    root: {},
    paperBackground: {
        padding: theme.spacing(6),
        maxWidth: 960,
        margin: 'auto',
    },
    title: {
        marginBottom: theme.spacing(3),
        display: 'block',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            marginBottom: 24,
            fontSize: 18,
        },
    },
    radioSelect: {
        '& .selectOptionBox': {
            width: '20%!important',
            [theme.breakpoints.down('lg')]: {
                width: '100%!important',
            },
        },
        '& .MuiGrid-root': {
            padding: theme.spacing(1),
            textAlign: 'center',
            display: 'flex',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
            '& .MuiFormControlLabel-root': {
                border: '1px solid #01CDCD',
                borderRadius: 5,
                width: '100%',
                boxSizing: 'border-box',
                padding: theme.spacing(2),
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                [theme.breakpoints.down('lg')]: {
                    padding: theme.spacing(2),
                },
                '& .MuiFormControlLabel-label': {
                    color: '#000A1D',
                    fontSize: 14,
                },
            },
            '&:hover': {
                '& .MuiFormControlLabel-root': {
                    background: 'linear-gradient(0deg, #EAF8F9, #EAF8F9), #FFFFFF',
                    border: '1px solid #01CDCD',
                    '& .MuiFormControlLabel-label': {
                        color: '#01CDCD',
                    },
                },
            },
            '&.active': {
                '& .MuiFormControlLabel-root': {
                    background: 'linear-gradient(0deg, #EAF8F9, #EAF8F9), #FFFFFF',
                    border: '1px solid #01CDCD',
                    '& .MuiFormControlLabel-label': {
                        color: '#01CDCD',
                    },
                },
            },
        },

        '& .MuiFormControlLabel-root': {
            margin: 0,
            padding: theme.spacing(1),
            '& .MuiButtonBase-root': {
                opacity: 0,
                position: 'absolute',
            },
        },
    },
    optionImage: {
        display: 'block',
        margin: 'auto',
        width: 'auto',
        marginBottom: theme.spacing(2),
        height: 108,
        objectFit: 'contain',
        maxWidth: '100%',
    },
}));

export const SurveyAnswerSingleChoice: FC<Props> = ({ onSelect, question, answer, selectedLanguage }) => {
    const classes = useStyles();

    getLabelTranslation(question.question, selectedLanguage);
    getLabelTranslation(question.question, selectedLanguage);
    const onChangeSingleChoice = (event: ChangeEvent<HTMLInputElement>) => {
        onSelect(parseInt((event.target as HTMLInputElement).value));
    };

    const isSingleChoiceSelected = (option: number | undefined) => {
        if (!question && !answer) {
            return true;
        }
        return answer?.items?.some(item => item.id === option);
    };

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Paper elevation={0} className={classes.paperBackground}>
                <Grid item>
                    <Typography variant='h1' className={classes.title}>
                        {getLabelTranslation(question.question, selectedLanguage)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction='row' className={classes.radioSelect} justifyContent={'center'}>
                        {question.items?.map(item => (
                            <Grid key={item.id} item className={`${isSingleChoiceSelected(item.id) ? 'active' : ''} selectOptionBox`}>
                                <FormControlLabel
                                    value={item.id}
                                    control={
                                        <Radio
                                            checked={isSingleChoiceSelected(item.id)}
                                            onChange={onChangeSingleChoice}
                                            value={item.id}
                                            name='radio-button-demo'
                                            inputProps={{ 'aria-label': 'A' }}
                                        />
                                    }
                                    label={
                                        <>
                                            {item.imageUrl && (
                                                <img
                                                    src={item.imageUrl}
                                                    key={item.id}
                                                    alt={'Icon'}
                                                    className={classes.optionImage}
                                                    width='40px'
                                                    height='auto'
                                                />
                                            )}
                                            {getLabelTranslation(item.label, selectedLanguage)}
                                        </>
                                    }
                                    labelPlacement='end'
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </Slide>
    );
};
