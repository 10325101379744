import { FileMetadata } from '@/Components/file-picker-wrapper/FilePickerWrapper';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';
import {
    createLeaveRequestAttachment,
    deleteLeaveRequestAttachment,
    getLeaveRequestAttachments,
} from '@/domain/leave-request-attachment/LeaveRequestAttachment.service';

export const useLeaveRequestAttachments = (
    leaveRequestId?: number,
): {
    filesMetadata: FileMetadata[];
    handleFileRemoved: (fileMetadata: FileMetadata) => Promise<void>;
    handleFileUploaded: (fileMetadata: FileMetadata) => Promise<void>;
    handleFileRenamed: (fileMetadata: FileMetadata) => void;
} => {
    const mode = leaveRequestId ? 'edit' : 'create';
    const [filesMetadata, setFilesMetadata] = useState<FileMetadata[]>([]);

    const handleFileRemoved = async (fileMetadata: FileMetadata) => {
        const removeAttachmentFromUploaderList = () => setFilesMetadata(filesMetadata.filter(file => file.itemId !== fileMetadata.itemId));
        try {
            if (mode === 'edit' && fileMetadata.itemId) {
                await deleteLeaveRequestAttachment(fileMetadata.itemId);
            }
            removeAttachmentFromUploaderList();
        } catch (error) {
            handleError(error);
        }
    };

    const handleFileUploaded = async (fileMetadata: FileMetadata) => {
        const addFileToUploadList = () => setFilesMetadata(currentFilesMetadata => [...currentFilesMetadata, fileMetadata]);
        try {
            // In creation mode  we call the backend only after the leave request is created
            if (mode === 'edit' && leaveRequestId) {
                if (!fileMetadata.key) {
                    throw new Error('File key is missing');
                }
                const request = {
                    leaveRequestId: leaveRequestId,
                    name: fileMetadata.filename,
                    mimeType: fileMetadata.mimetype,
                    s3Key: fileMetadata.key,
                };
                await createLeaveRequestAttachment(request);
                fetchAttachments();
            }

            addFileToUploadList();
        } catch (error) {
            handleError(error);
        }
    };

    const handleFileRenamed = (fileMetadata: FileMetadata) => {
        setFilesMetadata(filesMetadata.map(file => (file.key === fileMetadata.key ? fileMetadata : file)));
    };

    const fetchAttachments = useCallback(() => {
        if (leaveRequestId) {
            getLeaveRequestAttachments(leaveRequestId).then(attachments => {
                if (attachments?.length) {
                    setFilesMetadata(
                        attachments.map(attachment => {
                            return {
                                itemId: attachment.id,
                                filename: attachment.name,
                                mimetype: attachment.mimeType,
                            };
                        }),
                    );
                }
            });
        }
    }, [leaveRequestId]);

    useEffect(() => {
        fetchAttachments();
    }, [fetchAttachments]);

    return {
        filesMetadata,
        handleFileRemoved,
        handleFileUploaded,
        handleFileRenamed,
    } as const;
};
