import { FC } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import logo from '@/assets/Images/logo.svg';
import AnonymousImg from '@/assets/Images/anonymousLockScreen.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    logoImage: {
        width: 45,
        height: 49,
    },
    anonymousImg: {
        width: 60,
        [theme.breakpoints.down('lg')]: {
            width: 38,
            position: 'relative',
            top: 3,
        },
    },
    anonymousAnswer: {
        maxWidth: 324,
        boxShadow: '0px 16px 31px rgb(0 0 0 / 3%)',
        borderRadius: 7,
        background: 'white',
        padding: '12px 16px 12px 22px',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '100%',
            background: 'transparent',
            boxShadow: 'none',
            borderRadius: 7,
            width: '100%',
            justifyContent: 'space-between',
            padding: 0,
            alignItems: 'center',
        },
    },
    anonymousHeader: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('lg')]: {
            padding: '12px 16px',
            marginBottom: 0,
            background: theme.palette.background.default,
        },
    },
    fullWidthMobile: {
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
    },
}));

export const EmployeeSurveyHeader: FC<unknown> = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container direction='row' justifyContent='space-between' className={classes.anonymousHeader}>
            <Grid item className={classes.fullWidthMobile} sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Grid container direction='row' spacing={1} wrap='nowrap' alignItems='center'>
                    <Grid item>
                        <img src={logo} className={classes.logoImage} alt='Anonymous' />
                    </Grid>
                    <Grid item>
                        <Typography align='center' color='textPrimary'>
                            {t('general.brand.title')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.fullWidthMobile}>
                <Grid container direction='row' spacing={0} wrap='nowrap' alignItems='center' className={classes.anonymousAnswer}>
                    <Grid item>
                        <Typography
                            variant='body1'
                            sx={{
                                display: { xs: 'none', md: 'block', lg: 'none' },
                            }}
                        >
                            {t('anonymous_surveys.your_answers_are_anonymous')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src={AnonymousImg} className={classes.anonymousImg} alt='Anonymous' />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
