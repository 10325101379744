import { Stack, SvgIconProps } from '@mui/material';
import { StarIcon } from 'hugeicons-react';
import { FC } from 'react';

type EmployeeReviewStarsIconsProps = {
    score: number | undefined;
} & SvgIconProps;

export const EmployeeReviewStarsIcons: FC<EmployeeReviewStarsIconsProps> = ({ score, htmlColor, ...rest }) => {
    if (!score) {
        return <></>;
    }

    return (
        <Stack direction={'row'}>
            {[...Array(score)].map((_, index) => (
                <StarIcon key={index} size={12} {...rest} fill={htmlColor} />
            ))}
        </Stack>
    );
};
