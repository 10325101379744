import { SectionActionButton, SectionField } from '@/Components/section/types';
import { employeeAPI } from '@/api/employee/Employee.api';
import { employeeAddressAPI } from '@/api/employee/EmployeeAddress.api';
import { employeePersonalInfoAPI } from '@/api/employee/EmployeePersonalInfo.api';
import { EmployeeSectionAction, EmployeeSectionField } from '@/domain/employee-section/EmployeeSection.model';
import {
    Employee,
    EmployeeAnniversary,
    EmployeeAnniversaryRequest,
    EmployeeAuthentication,
    EmployeeBasicInfo,
    EmployeeBasicInfoUpdateMutation,
    EmployeeEmailUpdateMutation,
    EmployeeLoginMethod,
    EmployeeLoginMethodUpdateMutation,
    EmployeeLoginMutation,
    EmployeeSearch,
    EmployeeSearchLoginMethodRequest,
    EmployeeSettings,
    EmployeeSettingsUpdateMutation,
    EmployeeShiftSettings,
    EmployeeShiftSettingsUpdateMutation,
} from '@/domain/employee/Employee.model';
import { LoginMethod } from '@/domain/realm/Realm.model';
import { SectionFieldDefinition, SectionFieldType } from '@/domain/section-setting/Section.model';
import { getCountry } from '@/utils/countries.util';
import { getLabelTranslation } from '@/utils/language.util';
import { removeAccents } from '@/utils/strings.util';
import { compareAsc, differenceInCalendarYears, differenceInDays, isSameDay } from 'date-fns';
import { EmployeeAddress, EmployeeAddressCreateMutation, EmployeeAddressUpdateMutation } from './EmployeeAddress.model';
import { EmployeePersonalInfo, EmployeePersonalInfoMutation } from './EmployeePersonalInfo.model';

export const getCurrentEmployee = (): Promise<EmployeeAuthentication> => {
    return employeeAPI.getCurrentEmployee();
};

export const getEmployeeById = (employeeId: number): Promise<Employee> => {
    return employeeAPI.getEmployeeById(employeeId);
};

export const searchEmployees = (
    search: EmployeeSearch & {
        offset?: number;
        limit?: number;
        sort?: string;
        sortDirection?: string;
    } = {},
): Promise<Employee[]> => {
    return employeeAPI.searchEmployees(search);
};

export const searchEmployeeBirthdays = (searchRequest: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    return employeeAPI.searchEmployeeBirthdays(searchRequest);
};

export const searchEmployeeWorkAnniversaries = (searchRequest: EmployeeAnniversaryRequest): Promise<EmployeeAnniversary[]> => {
    return employeeAPI.searchEmployeeWorkAnniversaries(searchRequest);
};

export const deleteEmployee = (employeeId: number, email: string): Promise<void> => {
    return employeeAPI.deleteEmployee(employeeId, email);
};

/**
 * Returns the next birthdate of an employee
 * @param birthday in format MM-DD
 */
export const getNextBirthdate = (birthday: string): Date | undefined => {
    const today = new Date();
    const birthdateSplit = birthday.split('-');
    if (!birthdateSplit?.length) {
        return undefined;
    }
    const birthdateMonth = birthdateSplit[1];
    const birthdateDay = birthdateSplit[2];
    const birthDateThisYear = new Date(today.getFullYear(), Number(birthdateMonth) - 1, Number(birthdateDay));

    const isBirthdateInThePast = birthDateThisYear < today && !isSameDay(birthDateThisYear, today);
    if (isBirthdateInThePast) {
        return new Date(today.getFullYear() + 1, Number(birthdateMonth) - 1, Number(birthdateDay));
    }
    return birthDateThisYear;
};

export const getDaysUntilNextBirthday = (nextBirthdate: Date): number => {
    const today = new Date();
    const diffInDays = differenceInDays(nextBirthdate, today);
    if (diffInDays === 0 && isSameDay(nextBirthdate, today)) {
        return 0;
    }
    return diffInDays + 1;
};

export const getNewJoiners = (employeeWorkAnniversaries: EmployeeAnniversary[]): EmployeeAnniversary[] => {
    const newJoiners = employeeWorkAnniversaries?.filter(anniversary => differenceInCalendarYears(new Date(), anniversary.anniversaryDate) === 0);
    return [...newJoiners].sort((newJoiner1, newJoiner2) => compareAsc(newJoiner1.anniversaryDate, newJoiner2.anniversaryDate));
};

export const getEmployeePersonalInfo = (employeeId: number): Promise<EmployeePersonalInfo> => {
    return employeePersonalInfoAPI.getEmployeePersonalInfo(employeeId);
};

export const updateEmployeePersonalInfo = (employeeId: number, request: EmployeePersonalInfoMutation): Promise<EmployeePersonalInfo> => {
    return employeePersonalInfoAPI.updateEmployeePersonalInfo(employeeId, request);
};

export const createEmployeePersonalInfoPendingRequest = (employeeId: number, request: EmployeePersonalInfoMutation): Promise<void> => {
    return employeePersonalInfoAPI.createEmployeePersonalInfoPendingRequest(employeeId, request);
};

export const updateEmployeePersonalInfoPendingRequest = (employeeId: number, request: EmployeePersonalInfoMutation): Promise<void> => {
    return employeePersonalInfoAPI.createEmployeePersonalInfoPendingRequest(employeeId, request);
};

export const getEmployeeAddresses = ({ employeeId }: { employeeId: number }): Promise<EmployeeAddress[]> => {
    return employeeAddressAPI.searchEmployeeAddresses({ employeeId: employeeId });
};

export const createEmployeeAddressPendingRequest = (request: EmployeeAddressCreateMutation): Promise<void> => {
    return employeeAddressAPI.createEmployeeAddressPendingRequest(request);
};

export const updateEmployeeAddressPendingRequest = (addressId: number, request: EmployeeAddressUpdateMutation): Promise<void> => {
    return employeeAddressAPI.updateEmployeeAddressPendingRequest(addressId, request);
};

export const deleteEmployeeAddressPendingRequest = (addressId: number): Promise<void> => {
    return employeeAddressAPI.deleteEmployeeAddressPendingRequest(addressId);
};

export const searchCurrentEmployeeLoginMethod = (request: EmployeeSearchLoginMethodRequest): Promise<EmployeeLoginMethod> => {
    return employeeAPI.searchCurrentEmployeeLoginMethod(request);
};

export const getEmployeeLoginMethod = (employeeId: number): Promise<LoginMethod> => {
    return employeeAPI.getEmployeeLoginMethod(employeeId);
};

export const updateLoginMethod = (employeeId: number, mutation: EmployeeLoginMethodUpdateMutation): Promise<Employee> => {
    return employeeAPI.updateLoginMethod(employeeId, mutation);
};

export const updateEmployeeLogin = (request: EmployeeLoginMutation): Promise<void> => {
    return employeeAPI.updateEmployeeLogin(request);
};

export const updateEmployeeBasicInfo = (employeeId: number, mutation: EmployeeBasicInfoUpdateMutation): Promise<EmployeeBasicInfo> => {
    return employeeAPI.updateEmployeeBasicInfo(employeeId, mutation);
};

export const updateEmail = (employeeId: number, mutation: EmployeeEmailUpdateMutation): Promise<void> => {
    return employeeAPI.updateEmail(employeeId, mutation);
};

export const deactivateEmployee = (employeeId: number): Promise<Employee> => {
    return employeeAPI.deactivateEmployee(employeeId);
};

export const activateEmployee = (employeeId: number): Promise<Employee> => {
    return employeeAPI.activateEmployee(employeeId);
};

export const uploadAvatar = (employeeId: number, key: string): Promise<Employee> => {
    return employeeAPI.uploadAvatar(employeeId, key);
};

export const updateEmployeeShiftSettings = (userId: number, mutation: EmployeeShiftSettingsUpdateMutation): Promise<EmployeeShiftSettings> => {
    return employeeAPI.updateEmployeeShiftSettings(userId, mutation);
};

export const updateEmployeeSettings = (mutation: EmployeeSettingsUpdateMutation): Promise<EmployeeSettings> => {
    return employeeAPI.updateEmployeeSettings(mutation);
};

export const filterEmployeesByIds = (ids: number[], employees: Employee[]): Employee[] => {
    return employees.filter(employee => ids.includes(employee.id));
};

export const buildEmployeeFilterPredicate =
    (
        name: string,
        {
            checkEmployeeCode,
            checkFirstName,
            checkLastName,
        }: {
            checkEmployeeCode: boolean;
            checkFirstName: boolean;
            checkLastName: boolean;
        } = {
            checkFirstName: true,
            checkLastName: true,
            checkEmployeeCode: true,
        },
    ) =>
    ({ firstName, lastName, employeeCode }: Pick<Employee, 'firstName' | 'lastName' | 'employeeCode'>): boolean => {
        return (
            (!checkFirstName || removeAccents(name.toLowerCase()).includes(removeAccents(firstName.toLowerCase()))) &&
            (!checkLastName || removeAccents(name.toLowerCase()).includes(removeAccents(lastName.toLowerCase()))) &&
            (!checkEmployeeCode || (!!employeeCode && name.toLowerCase().includes(employeeCode?.toLowerCase())))
        );
    };
export const getSectionActionButton = (
    action: EmployeeSectionAction,
    mutationButton: SectionActionButton,
    navigationButton: SectionActionButton,
): SectionActionButton | undefined => {
    switch (action) {
        case 'EDIT':
        case 'CREATE_PENDING':
        case 'EDIT_PENDING':
            return mutationButton;
        case 'NAVIGATE_ON_REQUEST_PAGE':
            return navigationButton;
        default:
            return undefined;
    }
};
/**
 * Merge the employee section fields with the section definition fields to create fields use by the form
 * @param employeeSectionId
 * @param sectionFieldDefinitions
 * @param employeeSectionFields
 * @returns fields use by the form
 */
export const convertEmployeeSectionFieldsToSectionFields = (
    employeeSectionId: number,
    sectionFieldDefinitions: SectionFieldDefinition[],
    employeeSectionFields: EmployeeSectionField[],
): SectionField[] => {
    // Merge the section definition fields with the employee fields
    return sectionFieldDefinitions?.map(sectionFieldDefinition => {
        const employeeField = getEmployeeField(sectionFieldDefinition.id, employeeSectionFields);

        const sectionField: SectionField = {
            employeeSectionId,
            title: getLabelTranslation(sectionFieldDefinition.name),
            type: sectionFieldDefinition.type,
            required: sectionFieldDefinition.mandatory,
            // we store the field id in the form value name
            formValueName: sectionFieldDefinition.id.toString(),
            customList: sectionFieldDefinition?.customList,
            countryValue:
                sectionFieldDefinition?.type === SectionFieldType.COUNTRY && !!employeeField?.stringValue ? getCountry(employeeField?.stringValue) : undefined,
            ...employeeField,
            fieldType: 'EMPLOYEE_CUSTOM_FIELD',
        };

        return sectionField;
    });
};

const getEmployeeField = (sectionFieldDefinitionId: number, employeeSectionFields: EmployeeSectionField[]) => {
    return employeeSectionFields?.find(field => field.sectionFieldDefinition?.id === sectionFieldDefinitionId);
};
export const getUserEmploymentStatusTranslationKey = (): string => {
    return 'employee.employment.employment_status_enum';
};

export const getContractTypeTranslationKey = (): string => {
    return 'employee.employment.contract_type';
};

export const getGenderTranslationKey = (): string => {
    return 'employee.genders_enum';
};

export const getMaritalStatusTranslationKey = (): string => {
    return 'employee.marital_statuses_enum';
};
export const getUserStatusTranslationKey = (): string => {
    return 'domain.user_status_enum';
};

export const sortEmployeesByDisplayName = (employees: Employee[]): Employee[] => {
    return employees.sort((a, b) => a.displayName.localeCompare(b.displayName));
};

export const filterEmployeesByDisplayName = (employees: Employee[], searchName: string): Employee[] => {
    return employees.filter(employee => {
        return employee.displayName.toLowerCase().includes(searchName.toLowerCase());
    });
};

const FILTERING_FIELD_TYPES = ['LOCATION_IDS', 'DEPARTMENT_IDS', 'JOB_IDS', 'ROLE_IDS', 'MANAGER_IDS'] as const;
export type EmployeeFilterType = (typeof FILTERING_FIELD_TYPES)[number];
export const isValidEmployeeFilterType = (filterName: string): filterName is EmployeeFilterType => {
    return FILTERING_FIELD_TYPES.includes(filterName as EmployeeFilterType);
};

export const doesEmployeeMatchFilter = (employee: Employee, filteringIds: number[], filteringFieldType: EmployeeFilterType): boolean => {
    const getRelevantIds = () => {
        switch (filteringFieldType) {
            case 'LOCATION_IDS':
                return employee.currentEmployments.flatMap(employment => employment.location?.id);
            case 'DEPARTMENT_IDS':
                return employee.currentEmployments.flatMap(employment => employment.department?.id);
            case 'JOB_IDS':
                return employee.currentEmployments.flatMap(employment => employment.job?.id);
            case 'MANAGER_IDS':
                return employee.currentEmployments?.flatMap(employment => employment.managers.map(manager => manager.id));
            case 'ROLE_IDS':
                return employee.roles?.flatMap(role => role.id);
        }
    };

    return filteringIds.some(filteringId => getRelevantIds()?.some(id => id === filteringId));
};
