import {
    EmployeePayrollLock,
    EmployeePayrollLockCreationMutation,
    EmployeePayrollLockCreationValidation,
    EmployeePayrollLockLatestSearchRequest,
    EmployeePayrollLockPreview,
    EmployeePayrollLockSearchRequest,
    EmployeePayrollLockUnlockMutation,
    LatestPayrollLocks,
} from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { toDate } from 'date-fns';

type EmployeePayrollLockDTO = EmployeePayrollLock;
type LatestPayrollLocksDTO = LatestPayrollLocks;
type EmployeePayrollLockSearchRequestDTO = EmployeePayrollLockSearchRequest;
type EmployeePayrollLockLatestSearchRequestDTO = EmployeePayrollLockLatestSearchRequest;
type EmployeePayrollLockPreviewDTO = EmployeePayrollLockPreview;

const createEmployeePayrollLock = async (mutation: EmployeePayrollLockCreationMutation): Promise<EmployeePayrollLock[]> => {
    const url = API_BASE_URL + `/employee/payroll-locks`;
    return (await client.post<EmployeePayrollLockDTO, AxiosResponse<EmployeePayrollLockDTO[]>>(url, mutation)).data;
};

const searchEmployeePayrollLock = async (search: EmployeePayrollLockSearchRequest): Promise<EmployeePayrollLock[]> => {
    const url = API_BASE_URL + `/employee/payroll-locks/search`;
    return (await client.post<EmployeePayrollLockDTO[], AxiosResponse<EmployeePayrollLockDTO[]>, EmployeePayrollLockSearchRequestDTO>(url, search)).data;
};

const searchLatestEmployeePayrollLock = async (search: EmployeePayrollLockLatestSearchRequest): Promise<LatestPayrollLocks[]> => {
    const url = API_BASE_URL + `/employee/payroll-locks/search/latest`;
    return (await client.post<LatestPayrollLocksDTO[], AxiosResponse<LatestPayrollLocksDTO[]>, EmployeePayrollLockLatestSearchRequestDTO>(url, search)).data;
};

const unlockEmployeePayrollLock = (mutation: EmployeePayrollLockUnlockMutation): Promise<void> => {
    const url = API_BASE_URL + `/employee/payroll-locks/unlock`;
    return client.post(url, mutation);
};

type EmployeePayrollLockCreationValidationDTO = Overwrite<EmployeePayrollLockCreationValidation, { lockedAt: Date }>;

const checkEmployeePayrollLockCreationFeasibility = async (request: EmployeePayrollLockCreationValidation): Promise<EmployeePayrollLockPreview[]> => {
    const url = API_BASE_URL + `/employee/payroll-locks/check`;
    return (
        await client.post<EmployeePayrollLockPreviewDTO[], AxiosResponse<EmployeePayrollLockPreviewDTO[]>, EmployeePayrollLockCreationValidationDTO>(url, {
            ...request,
            lockedAt: toDate(request.lockedAt),
        })
    ).data;
};

export const employeePayrollLockAPI = {
    createEmployeePayrollLock,
    searchEmployeePayrollLock,
    searchLatestEmployeePayrollLock,
    unlockEmployeePayrollLock,
    checkEmployeePayrollLockCreationFeasibility,
};
