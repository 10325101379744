import { FC, useState } from 'react';
import { CompanySettingsLayout } from '../../CompanySettingsLayout';
import { ConfigType } from '../../types';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { handleError } from '@/utils/api.util';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { useGetCostCenters } from '@/hooks/cost-center/CostCenter.hook';
import { createCostCenter, deleteCostCenter, updateCostCenter } from '@/domain/cost-center/CostCenter.service';
import { CostCenterFormValues, CostCenterSettingsDialog } from '@/page/setting/organization/cost-center/cost-center-dialog/CostCenterSettingsDialog';
import { CostCenter } from '@/domain/cost-center/CostCenter.model';
import { BasicMenu } from '@/Components/basic-menu/BasicMenu';

import { useAppSelector } from '@/stores/store';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { MoreVerticalIcon } from '@/assets/Icons/Icons';

export const CostCenterSettingsPage: FC = () => {
    const { t } = useTranslation();

    const [costCenterDialogOpen, setCostCenterDialogOpen] = useState<boolean>(false);
    const [costCenterToEdit, setCostCenterToEdit] = useState<CostCenter>();

    const { data: costCenters = [], isLoading, isError, error: errorCostCenters, refetch: refetchCostCenters } = useGetCostCenters();
    const realm = useAppSelector(state => state.ui.currentRealm);

    if (!realm || !hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.COST_CENTERS)) {
        return;
    }

    const handleDelete = async (id: number) => {
        try {
            await deleteCostCenter(id);
            await refetchCostCenters();
        } catch (e) {
            handleError(e);
        }
    };

    const handleOnClose = () => {
        setCostCenterDialogOpen(false);
        setCostCenterToEdit(undefined);
    };

    const handleOnSave = async (data: CostCenterFormValues) => {
        try {
            if (costCenterToEdit?.id) {
                await updateCostCenter(costCenterToEdit.id, data);
            } else {
                await createCostCenter(data);
            }
            await refetchCostCenters();
            handleOnClose();
        } catch (e) {
            handleError(e);
        }
    };

    const getMenuItems = (params: ICellRendererParams<CostCenter>) => {
        return [
            {
                title: t('general.edit'),
                onClick: () => {
                    setCostCenterToEdit(params.data);
                    setCostCenterDialogOpen(true);
                },
            },
            {
                title: t('general.delete'),
                onClick: () => params.data?.id && handleDelete(params.data.id),
            },
        ];
    };

    const cellActionRenderer = (params: ICellRendererParams<CostCenter>) => {
        return <BasicMenu items={getMenuItems(params)} endIcon={<MoreVerticalIcon />} />;
    };

    const config: ConfigType<CostCenter> = {
        type: 'table',
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => {
                setCostCenterDialogOpen(true);
            },
        },
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<CostCenter>) => {
                    setCostCenterToEdit(params.data);
                    setCostCenterDialogOpen(true);
                },
            },
            columns: [
                {
                    field: 'name',
                    headerName: t('cost_center_setting_page.table_headers.name'),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={errorCostCenters}>
            <CompanySettingsLayout options={config} data={costCenters} />
            {costCenterDialogOpen && (
                <CostCenterSettingsDialog open={costCenterDialogOpen} onSave={handleOnSave} closeDialog={handleOnClose} costCenter={costCenterToEdit} />
            )}
        </StateHandler>
    );
};
