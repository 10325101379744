import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/stores/store';
import {
    canAccessEmployeeReviews,
    canManageLeaves,
    canViewEmployeeDocuments,
    canViewObjectives,
    canViewProfilePersonalInfoTab,
    canViewShifts,
    canViewTimesheets,
} from '@/domain/permission/Permission.service';
import { RealmFeature } from '@/domain/realm/Realm.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';

type MenuItems = {
    label: string;
    path: string;
    condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => boolean;
};
type Props = {
    employeeId: number;
    open: boolean;
    onClose: () => void;
};
export const MobileProfileTabsNavigation: FC<Props> = ({ employeeId, open, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);

    const menuItems: MenuItems[] = [
        {
            label: 'my_profile.tabs.personal_info',
            path: 'personal-info',
            condition: (_, policies: EmployeePolicy[]) => canViewProfilePersonalInfoTab(policies, employeeId),
        },
        {
            label: 'my_profile.tabs.leaves',
            path: 'leaves',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canManageLeaves(realmFeatures, policies, employeeId),
        },
        {
            label: 'my_profile.tabs.timesheets',
            path: 'timesheets',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewTimesheets(realmFeatures, policies, employeeId),
        },
        {
            label: 'my_profile.tabs.planning',
            path: 'planning',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewShifts(realmFeatures, policies),
        },
        {
            label: 'my_profile.tabs.reviews',
            path: 'reviews',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canAccessEmployeeReviews(realmFeatures, policies, employeeId),
        },
        {
            label: 'my_profile.tabs.objectives',
            path: 'objectives',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewObjectives(realmFeatures, policies, employeeId),
        },
        {
            label: t('my_profile.tabs.roles'),
            path: 'roles',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canAccessEmployeeReviews(realmFeatures, policies, employeeId),
        },
        {
            label: 'my_profile.tabs.documents',
            path: 'documents',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewEmployeeDocuments(realmFeatures, policies, employeeId),
        },
    ];

    if (!realm) {
        throw new Error('Realm not defined');
    }

    return (
        <Drawer anchor={'bottom'} open={open} onClose={onClose}>
            <Box role='presentation' onClick={onClose} onKeyDown={onClose}>
                <List>
                    {menuItems.map(item => {
                        if (item.condition(realm.realmFeatures, policies)) {
                            return (
                                <ListItem key={item.label} disablePadding>
                                    <ListItemButton
                                        onClick={event => {
                                            event.stopPropagation();
                                            onClose();
                                            navigate(`/profile/${employeeId}/${item.path}`);
                                        }}
                                    >
                                        <ListItemText primary={t(item.label)} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        }
                    })}
                </List>
            </Box>
        </Drawer>
    );
};
