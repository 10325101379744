import { TimesheetAdjustmentCreateMutation, TimesheetAdjustmentUpdateMutation } from '@/domain/timesheet-adjustment/TimesheetAdjustment.model';
import { TimesheetPayment } from '@/domain/timesheet-payment/TimesheetPayment.model';
import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';

const timesheetAdjustmentRequest = async (request: TimesheetAdjustmentCreateMutation): Promise<TimesheetPayment> => {
    const url = API_BASE_URL + '/timesheet-adjustments';
    return (await client.post<TimesheetPaymentDTO, AxiosResponse<TimesheetPaymentDTO>, TimesheetAdjustmentCreateMutationDTO>(url, request)).data;
};

const timesheetAdjustmentUpdateRequest = async (request: TimesheetAdjustmentUpdateMutation, id: number): Promise<TimesheetPayment> => {
    const url = API_BASE_URL + `/timesheet-adjustments/${id}`;
    return (await client.put<TimesheetPaymentDTO, AxiosResponse<TimesheetPaymentDTO>, TimesheetAdjustmentUpdateMutationDTO>(url, request)).data;
};

const timesheetAdjustmentDelete = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/timesheet-adjustments/${id}`;
    return (await client.delete(url)).data;
};

const importTimesheetsAdjustments = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/timesheet-adjustments/import`, request)).data;
};

export const timesheetAdjustmentAPI = {
    timesheetAdjustmentRequest,
    timesheetAdjustmentUpdateRequest,
    timesheetAdjustmentDelete,
    importTimesheetsAdjustments,
};

type TimesheetAdjustmentCreateMutationDTO = TimesheetAdjustmentCreateMutation;
type TimesheetAdjustmentUpdateMutationDTO = TimesheetAdjustmentUpdateMutation;
type TimesheetPaymentDTO = TimesheetPayment;
