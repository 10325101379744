import { StatusIcon } from '@/assets/Icons/Icons';
import { CircularProgress, Divider, Grid, Icon, Paper, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { FC, Fragment } from 'react';
import { BasicMenu, BasicMenuItem } from '../../basic-menu/BasicMenu';
import { SectionActionButton, SectionField } from '../types';
import { SectionFieldComponent } from '../SectionFieldComponent/SectionFieldComponent';
import { isEqualSectionFieldValue } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import { getFieldValueProperty } from '@/Components/section/SectionFieldComponent/SectionField.util';
import { ArrowDown01Icon } from 'hugeicons-react';

export type StackSectionProps = {
    sectionTitle: string;
    fields: SectionField[];
    pendingFields?: SectionField[];
    actionButtons?: SectionActionButton[];
    dropdownActionButtons?: BasicMenuItem[];
    dropdownTitle?: string;
};

export const StackSection: FC<StackSectionProps> = ({ sectionTitle, fields, pendingFields, actionButtons, dropdownActionButtons, dropdownTitle }) => {
    if (!sectionTitle || !fields) {
        return (
            <Stack justifyContent='center' alignItems='center'>
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <Paper elevation={0} sx={{ p: 2 }}>
            <Stack justifyContent='center' alignItems='flex-start' spacing={1}>
                <Stack direction='row' justifyContent='space-between' alignItems='flex-start' spacing={1} sx={{ width: '100%' }}>
                    <Typography variant='h1' pl={1}>
                        {sectionTitle}
                    </Typography>
                    <Stack direction={'row'}>
                        {!!actionButtons?.length &&
                            actionButtons.map(actionButton => (
                                <Button key={actionButton.title} variant='text' onClick={actionButton.onClick} disabled={actionButton?.disabled}>
                                    {actionButton.title}
                                </Button>
                            ))}
                    </Stack>
                    {!!dropdownActionButtons?.length && <BasicMenu items={dropdownActionButtons} dropdownTitle={dropdownTitle} endIcon={<ArrowDown01Icon />} />}
                </Stack>
                {!!fields?.length &&
                    fields.map(field => {
                        const pendingField = pendingFields?.find(pendingField => {
                            // For EMPLOYEE_CUSTOM_FIELD, we use formValueName to compare
                            // formValueName is based on sectionFieldId
                            return pendingField.formValueName === field.formValueName && !isEqualSectionFieldValue(field, pendingField);
                        });
                        return (
                            <Fragment key={field.title}>
                                <Grid container spacing={1} justifyContent='flex-start' direction='row'>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <Stack direction='row' alignItems='center'>
                                            <Typography variant='body1bold'>{field.title}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={5}>
                                        <Stack direction='row' alignItems='center'>
                                            <SectionFieldComponent field={field} />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5}>
                                        {!!pendingField && <PendingField field={pendingField} />}
                                    </Grid>
                                </Grid>
                                <Divider flexItem sx={{ opacity: 0.6 }} />
                            </Fragment>
                        );
                    })}
            </Stack>
        </Paper>
    );
};

const PendingField: FC<{ field: SectionField }> = ({ field }) => {
    if (!field) {
        return;
    }

    const property = getFieldValueProperty(field.type);
    const value = field[property];

    return (
        <Stack direction='row' alignItems='center' gap={0.5}>
            <Icon color='warning' sx={{ width: 20, height: 20, lineHeight: '20px' }}>
                <StatusIcon viewBox='0 0 16 16' sx={{ width: 20, height: 20 }} />
            </Icon>
            {value ? <SectionFieldComponent field={field} /> : <Typography variant='body1bold'>-</Typography>}
        </Stack>
    );
};
