import { FilePickerWrapper, FileStackWrapperProps } from '@/Components/file-picker-wrapper/FilePickerWrapper';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { StackProps } from '@mui/material';
import { FC } from 'react';
import { useLeaveRequestAttachments } from './UseLeaveRequestAttachments';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { getLeaveRequestAttachmentUrl } from '@/domain/leave-request-attachment/LeaveRequestAttachment.service';

type Props = FileStackWrapperProps & {
    leaveType: LeaveType;
};

export const LeaveRequestAttachmentsUploader: FC<Props> = ({ leaveType, ...rest }) => {
    if (!leaveType?.allowAttachments) {
        return;
    }
    return <FilePickerWrapper {...rest} />;
};

export const LeaveRequestAttachmentsUploaderUncontrolled: FC<
    {
        leaveRequest: LeaveRequest;
    } & StackProps
> = ({ leaveRequest, ...rest }) => {
    const { filesMetadata, handleFileRemoved, handleFileUploaded } = useLeaveRequestAttachments(leaveRequest?.id);

    return (
        <LeaveRequestAttachmentsUploader
            containerId={`leave-request-attachments-container-${leaveRequest.id}`}
            leaveType={leaveRequest?.leaveType}
            filesMetadata={filesMetadata}
            onFileRemoved={handleFileRemoved}
            onFileUploaded={handleFileUploaded}
            fetchDocumentUrl={(itemId: number) => getLeaveRequestAttachmentUrl(itemId, 'ATTACHMENT').then(res => res)}
            onFileRenamed={undefined}
            confirmOnRemove
            {...rest}
        />
    );
};
