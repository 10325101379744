import { Employee, EmployeePolicy } from '@/domain/employee/Employee.model';
import {
    canAccessEmployeeReviews,
    canManageLeaves,
    canViewCompanyDocuments,
    canViewEmployeeDocuments,
    canViewObjectives,
    canViewShifts,
    canViewTimesheets,
} from '@/domain/permission/Permission.service';
import { RealmFeature } from '@/domain/realm/Realm.model';
import {
    Briefcase02Icon,
    Calendar03Icon,
    Clock01Icon,
    DashboardSquare02Icon,
    File01Icon,
    Home03Icon,
    StarIcon,
    Target01Icon,
    UserMultiple02Icon,
} from 'hugeicons-react';
import { FunctionComponent, SVGProps } from 'react';

type Item = {
    icon: SVGIcon;
    to: string;
    labelKey: string;
};

type SVGIcon = FunctionComponent<
    SVGProps<SVGSVGElement> & {
        title?: string;
    }
>;

export const getMobileNavigationItems = (realmFeatures: RealmFeature[], policies: EmployeePolicy[], employee: Employee): Item[] => {
    return [
        {
            icon: Home03Icon,
            to: '/',
            labelKey: 'pages.home',
        },
        canViewShifts(realmFeatures, policies) && {
            icon: DashboardSquare02Icon,
            className: '',
            to: '/profile/planning',
            labelKey: 'additional_pages.planning',
        },
        canManageLeaves(realmFeatures, policies, employee?.id) && {
            icon: Calendar03Icon,
            to: '/profile/leaves',
            labelKey: 'additional_pages.leaves',
        },
        {
            icon: UserMultiple02Icon,
            to: '/people',
            labelKey: 'additional_pages.colleagues',
        },
        canViewTimesheets(realmFeatures, policies, employee?.id) && {
            icon: Clock01Icon,
            to: '/profile/timesheets',
            labelKey: 'additional_pages.timesheets',
        },
        canAccessEmployeeReviews(realmFeatures, policies, employee?.id) && {
            icon: StarIcon,
            to: '/profile/reviews',
            labelKey: 'additional_pages.reviews',
        },
        canAccessEmployeeReviews(realmFeatures, policies, employee?.id) && {
            icon: Briefcase02Icon,
            to: '/profile/roles',
            labelKey: 'additional_pages.roles',
        },
        canViewObjectives(realmFeatures, policies, employee?.id) && {
            icon: Target01Icon,
            to: '/profile/objectives',
            labelKey: 'additional_pages.objectives',
        },
        (canViewEmployeeDocuments(realmFeatures, policies, employee?.id) || canViewCompanyDocuments(realmFeatures, policies)) && {
            icon: File01Icon,
            to: '/documents/all-documents',
            labelKey: 'additional_pages.documents',
        },
    ].filter((i): i is Item => !!i);
};
/**
 * This is the maximum number of items that can be displayed in the mobile navigation.
 */
export const MAXIMUM_NUMBER_OF_ITEMS_IN_MOBILE_NAVIGATION = 5;
