import { CompanyDocumentsFolderPage } from '@/page/document/CompanyDocumentsFolderPage';
import { AllDocumentsPage } from '@/page/document/AllDocumentsPage';
import { EmployeeDocumentsPage } from '@/page/document/EmployeeDocumentsFolderPage';
import { CompanyDocumentsPage } from '@/page/document/CompanyDocumentsPage';
import { PageContainer } from '@/routes/PageContainer';
import { RoutesConfig } from '@/routes/Routes.type';
import { Navigate, RouteObject } from 'react-router-dom';
import { RouterBoundary } from '@/routes/RouterBoundary';

const config: RoutesConfig[] = [
    {
        path: 'company-documents',
        breadCrumb: [{ nameKey: 'pages.company_documents' }],
        component: <CompanyDocumentsPage />,
    },
    {
        path: 'all-documents',
        breadCrumb: [{ nameKey: 'pages.all_documents' }],
        component: <AllDocumentsPage />,
    },
    {
        path: 'all-documents/:folderName/:folderId/:userId',
        breadCrumb: [],
        component: <EmployeeDocumentsPage />,
    },
    {
        path: 'all-documents/:folderName/:folderId/',
        breadCrumb: [],
        component: <CompanyDocumentsFolderPage />,
    },
];

export const documentRoutes: RouteObject[] = [
    {
        path: 'documents',
        children: [
            { path: '', element: <Navigate to='/documents/company-documents' replace /> },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}>{config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
