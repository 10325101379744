import { departmentAPI } from '@/api/department/Department.api';
import {
    Department,
    DepartmentCreationMutation,
    DepartmentNode,
    DepartmentNodesSearchRequest,
    DepartmentUpdateMutation,
} from '@/domain/department/Department.model';

export function getDepartments(): Promise<Department[]> {
    return departmentAPI.getDepartments();
}

export const getDepartmentNodes = (search: DepartmentNodesSearchRequest = {}): Promise<DepartmentNode[]> => {
    return departmentAPI.getDepartmentNodes(search);
};

export const createDepartment = (mutation: DepartmentCreationMutation): Promise<Department> => {
    return departmentAPI.createDepartment(mutation);
};

export const updateDepartment = (departmentId: number, mutation: DepartmentUpdateMutation): Promise<Department> => {
    return departmentAPI.updateDepartment(departmentId, mutation);
};

export const deleteDepartment = async (departmentId: number): Promise<void> => {
    await departmentAPI.deleteDepartment(departmentId);
};
