import { FC, useEffect, useState } from 'react';
import { DialogContainer } from '@/Components/dialog-container/DialogContainer';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import { ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { getAppConfig } from '@/config/config';
import { format } from 'date-fns';
import { PlanningSetting } from '@/domain/planning-setting/PlanningSetting.model';
import { handleError } from '@/utils/api.util';
import { getPlanningSettings } from '@/domain/planning-setting/PlanningSetting.service';
import { Shift } from '@/domain/shift/Shift.model';
import { InformationCircleIcon } from 'hugeicons-react';

const config = getAppConfig();

type Props = {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    saveAndKeepConflicts: () => void;
    shifts: Shift[];
};

export const LeavesConflictsDialog: FC<Props> = ({ open, onClose, onSave, shifts, saveAndKeepConflicts }) => {
    const { t } = useTranslation();
    const [planningSettings, setPlanningSettings] = useState<PlanningSetting>();

    useEffect(() => {
        getPlanningSettings()
            .then(settings => setPlanningSettings(settings))
            .catch(handleError);
    }, []);

    return (
        <DialogContainer
            open={open}
            onClose={onClose}
            onSave={() => onSave()}
            onCancel={saveAndKeepConflicts}
            title={t('leaves_page.what_would_you_like_to_do')}
            saveButtonText={t(planningSettings?.deleteConflictOnEmployeeRelease ? 'leaves_page.delete_conflict' : 'leaves_page.release_employee')}
            cancelButtonText={t('leaves_page.keep_shifts')}
            cancelButtonColor='inherit'
        >
            <Stack gap={1}>
                <Typography variant='body1bold' style={{ marginBottom: 8 }}>
                    {t('leaves_page.you_have_conflicting_shifts')}
                </Typography>
                <List>
                    {shifts?.map(shift => {
                        return (
                            <ListItem key={shift.id}>
                                <Stack direction='row' gap={1} alignItems='center'>
                                    <InformationCircleIcon />
                                    <ListItemText
                                        primary={shift?.area?.name}
                                        secondary={`${format(new Date(shift?.startDate), config.DEFAULT_DATE_WEEK_NAME)} - (${format(
                                            new Date(shift?.startDate),
                                            config.DEFAULT_HOURS_FORMAT,
                                        )} - ${format(new Date(shift?.endDate), config.DEFAULT_HOURS_FORMAT)})`}
                                        disableTypography={false}
                                    />
                                </Stack>
                            </ListItem>
                        );
                    })}
                </List>
            </Stack>
        </DialogContainer>
    );
};
