import { FC, Ref } from 'react';
import { Alert, Paper, Stack, Typography, useTheme } from '@mui/material';
import { EmployeeReviewFeedbackSkill } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSkill';
import { FieldError } from 'react-hook-form';
import {
    EmployeeReviewFeedbackSkillFormType,
    EmployeeReviewFeedbackSkillsFormType,
} from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { getGroupedSkillsByCategoryId, getSkillState } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSkills.utils';
import { useTranslation } from 'react-i18next';

type EmployeeReviewFeedbackSkillsProps = {
    disabled: boolean;
    skillsItem: EmployeeReviewFeedbackSkillsFormType;
    handleSkillChanged: (employeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSkillFormType) => void;
    error: FieldError | undefined;
    innerRef: Ref<HTMLDivElement>;
};

export type GroupedSkillsMap<T> = Map<
    number,
    {
        categoryName: string;
        groupedSkills: T[];
    }
>;

export const EmployeeReviewFeedbackSkills: FC<EmployeeReviewFeedbackSkillsProps> = ({ innerRef, handleSkillChanged, skillsItem, error, disabled }) => {
    const { t } = useTranslation();
    const { minSkills, maxSkills, skills } = skillsItem;
    const theme = useTheme();
    const groupedSkillsByCategoryId = getGroupedSkillsByCategoryId(skills);

    if (skillsItem.skills.length === 0) {
        return <></>;
    }

    return (
        <Stack
            component={Paper}
            p={2}
            gap={2}
            ref={innerRef}
            sx={{
                border: error ? `1px solid ${theme.palette.error.main}` : 'none',
            }}
        >
            {!!error && error.message && (
                <Alert severity='error' elevation={0}>
                    <Typography variant='body2'>{error.message}</Typography>
                </Alert>
            )}
            <Typography variant='h2'>{t('reviews.write_feedback.review_skills')}</Typography>

            <Typography variant='body1'>
                {minSkills === maxSkills
                    ? t('reviews.write_feedback.review_skills_description_min_equal_max', {
                          value: minSkills,
                      })
                    : t('reviews.write_feedback.review_skills_description', {
                          min: minSkills,
                          max: maxSkills,
                      })}
            </Typography>

            {Array.from(groupedSkillsByCategoryId.values()).map(({ categoryName, groupedSkills }) => (
                <SkillCategoryBlock
                    disabled={disabled}
                    key={categoryName}
                    categoryName={categoryName}
                    groupedEmployeeReviewFeedbackSkillForm={groupedSkills}
                    skillsItem={skillsItem}
                    handleSkillChanged={handleSkillChanged}
                />
            ))}
        </Stack>
    );
};

type SkillCategoryBlockProps = {
    categoryName: string;
    groupedEmployeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSkillFormType[];
    handleSkillChanged: (employeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSkillFormType) => void;
    skillsItem: EmployeeReviewFeedbackSkillsFormType;
    disabled: boolean;
};

const SkillCategoryBlock: FC<SkillCategoryBlockProps> = ({
    categoryName,
    groupedEmployeeReviewFeedbackSkillForm,
    handleSkillChanged,
    skillsItem,
    disabled,
}) => {
    return (
        <Stack gap={2}>
            <Typography variant={'body1bold'}>{categoryName}</Typography>
            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                {groupedEmployeeReviewFeedbackSkillForm.map(employeeReviewFeedbackSkillForm => (
                    <EmployeeReviewFeedbackSkill
                        disabled={disabled}
                        key={employeeReviewFeedbackSkillForm.skill.id}
                        skillState={getSkillState(employeeReviewFeedbackSkillForm, skillsItem)}
                        feedbackSkillItem={employeeReviewFeedbackSkillForm}
                        handleSkillChanged={handleSkillChanged}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
