import { Location } from '@/domain/location/Location.model';
import { deleteLocation } from '@/domain/location/Location.service';
import { EmployeeFieldMoreButton } from '@/page/employee-profile/employee-profile-info/EmployeeFieldMoreButton/EmployeeFieldMoreButton';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams } from '@ag-grid-community/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type LocationGridProps = {
    legalUnitId: number;
    locations: Location[];
    onRefetch: () => void;
};
export const LocationGrid: FC<LocationGridProps> = ({ legalUnitId, locations, onRefetch }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleDelete = async (params: ICellRendererParams<Location>) => {
        if (!params?.data?.id) {
            return;
        }
        try {
            await deleteLocation(params.data.id);
            onRefetch();
        } catch (e) {
            handleError(e);
        }
    };

    const getEmployeeFieldMoreButton = (params: ICellRendererParams<Location>) => (
        <EmployeeFieldMoreButton
            approveEnabled={false}
            declineEnabled={false}
            disabled={false}
            deleteEnabled={true}
            editDisabled={true}
            cancelEnabled={false}
            onDeleteClicked={() => handleDelete(params)}
        />
    );

    const config: ConfigType<Location> = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('general.add')} ${t('locations_settings_page.location')}`,
            primaryButtonAction: () => {
                navigate(`/settings/organization/legal-units/${legalUnitId}/locations/new`);
            },
        },
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    field: 'name',
                    headerName: t('locations_page.name'),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: getEmployeeFieldMoreButton,
                },
            ],
            agGridProps: {
                onRowClicked: params => {
                    if (!params?.data?.id) {
                        return;
                    }
                    navigate(`/settings/organization/legal-units/${legalUnitId}/locations/${params.data.id}`);
                },
            },
        },
    };

    return <CompanySettingsLayout options={config} data={locations} isSearchAvailable={false} />;
};
