import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { EmptyState } from '@/Components/empty-state/EmptyState';
import { ClipboardEmptyStateIcon } from '@/Components/empty-state/EmptyStateIcons';
import { FiltersBar, FiltersBarProps, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { Footer } from '@/page/layout/Footer';
import { Matrix } from '@/Components/matrix/Matrix';
import { StackedAvatars } from '@/Components/stacked-avatar/StackedAvatars';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { canManageReports } from '@/domain/permission/Permission.service';
import { Report, ReportField, ReportFieldEmployees, ReportFieldType, ReportGroupBy, ReportRow } from '@/domain/report/Report.model';
import { getPropsForMatrixMode, isGroupingFilter } from '@/domain/report/Report.service';
import { getEnumTranslation } from '@/domain/section-setting/Section.service';
import { ReportEditorBar, ReportFilterItemBar } from '@/page/report/report-editor-bar/ReportEditorBar';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { Share08Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContentContainer } from '@/page/layout/ContentContainer';

type ReportMatrixModePageProps = {
    report: Report;
    onSaveReport: ({ groupByFields }: { groupByFields: [ReportGroupBy | undefined, ReportGroupBy | undefined] }) => void;
    rows: ReportRow[];
    filtersBarProps: FiltersBarProps<ReportFilterItemBar>;
    availableGroupingFilters: ReportFieldType[];
    onOpenShareDialog: () => void;
};

export const ReportMatrixModePage: FC<ReportMatrixModePageProps> = ({
    report,
    onSaveReport,
    rows,
    filtersBarProps,
    availableGroupingFilters,
    onOpenShareDialog,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const isReportManager = canManageReports(grantedPolicies);

    const convertToGroupingFiltersOptions = (fieldType: ReportFieldType) => ({
        label: getFieldDefinitionTranslation({ fieldType }),
        value: fieldType,
    });
    const availableGroupingFiltersOptions: SelectFilter['options'] = availableGroupingFilters.map(convertToGroupingFiltersOptions);

    const groupXDefaultValue = report.groupByFields?.[0]?.fieldType ? convertToGroupingFiltersOptions(report.groupByFields?.[0].fieldType) : undefined;
    const groupYDefaultValue = report.groupByFields?.[1]?.fieldType ? convertToGroupingFiltersOptions(report.groupByFields?.[1].fieldType) : undefined;

    const [groupingFilters, setGroupingFilters] = useState<[SelectFilter, SelectFilter]>([
        {
            key: 'groupX',
            filterName: t('reports.group_by_x_label'),
            type: 'select',
            availableRules: [],
            rule: 'EQUALS',
            selectMode: 'SYNC',
            defaultVisibility: 'visible',
            options: availableGroupingFiltersOptions,
            value: groupXDefaultValue ? [groupXDefaultValue] : undefined,
        },
        {
            key: 'groupY',
            filterName: t('reports.group_by_y_label'),
            type: 'select',
            availableRules: [],
            rule: 'EQUALS',
            selectMode: 'SYNC',
            defaultVisibility: 'visible',
            options: availableGroupingFiltersOptions,
            value: groupYDefaultValue ? [groupYDefaultValue] : undefined,
        },
    ]);

    const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);

    /**
     * Override the filters change to trigger the editor change when the filters change
     * This will trigger an api call to get the preview rows
     */
    const handleFiltersChange = (filters: ReportFilterItemBar[]) => {
        filtersBarProps.onFiltersChange(filters);
    };

    const handleSave = () => async () => {
        try {
            setSaveIsLoading(true);

            onSaveReport({
                groupByFields: [
                    groupByFieldTypeX
                        ? {
                              fieldType: groupByFieldTypeX,
                              // TODO handle custom field
                              sectionFieldDefinitionId: undefined as unknown as number,
                          }
                        : undefined,
                    groupByFieldTypeY
                        ? {
                              fieldType: groupByFieldTypeY,
                              sectionFieldDefinitionId: undefined as unknown as number,
                          }
                        : undefined,
                ],
            });
        } catch (error) {
            handleError(error);
        }
        setSaveIsLoading(false);
    };

    const groupByFieldTypeX = groupingFilters[0].value?.[0]?.value as ReportFieldType;
    const groupByFieldTypeY = groupingFilters[1].value?.[0]?.value as ReportFieldType;

    const hasGroupBy = !!groupByFieldTypeX;

    const isReportFieldEmployee = (field: ReportField): field is ReportFieldEmployees => {
        return field.type === 'EMPLOYEE';
    };

    const convertToEmployeeAvatar = (row: ReportRow): EmployeeAvatar => {
        const field = row.fields.find(isReportFieldEmployee);

        if (!field) {
            throw new Error('Employee field not found');
        }
        return field.employeeValues[0];
    };

    const getEmployeeAvatars = (rows: ReportRow[]): EmployeeAvatar[] => {
        return rows.map(row => convertToEmployeeAvatar(row));
    };

    const rowsFlatten = rows.flatMap(row => ({
        ...row,
        groupByFieldX: getValueKey(row.fields.find(field => field.fieldDefinition.fieldType === groupByFieldTypeX)),
        groupByFieldY: getValueKey(row.fields.find(field => field.fieldDefinition.fieldType === groupByFieldTypeY)),
    }));

    const groupingFiltersBar = (
        <FiltersBar
            filters={groupingFilters || []}
            onFiltersChange={filters => {
                if (!isGroupingFilter(filters)) {
                    return;
                }
                setGroupingFilters(filters);
            }}
            wrapperProps={{ color: 'secondary' }}
            clearable={false}
        />
    );

    const noMatrixState = (
        <EmptyState
            icon={<ClipboardEmptyStateIcon />}
            flex={1}
            title={t('reports.no_matrix')}
            subTitle={t('reports.no_matrix_onboarding_text')}
            action={groupingFiltersBar}
        />
    );

    return (
        <>
            <ContentContainer>
                <Stack
                    gap={2}
                    flex={1}
                    // This is to disable the scroll from the main layout
                    overflow='hidden'
                >
                    <Paper sx={{ p: 1 }}>
                        <ReportEditorBar
                            filtersBarProps={{
                                ...filtersBarProps,
                                onFiltersChange: handleFiltersChange,
                            }}
                            readOnly={!isReportManager}
                            groupingFiltersProps={{
                                groupingFilters,
                                onGroupingFiltersChange: setGroupingFilters,
                            }}
                        />
                    </Paper>

                    <Stack component={Paper} flex={1} overflow='auto'>
                        <StateHandler
                            isLoading={false}
                            isError={false}
                            error={undefined}
                            isEmpty={!groupByFieldTypeX}
                            emptyStateComponent={rows.length ? noMatrixState : undefined}
                        >
                            {hasGroupBy && (
                                <Matrix
                                    rows={rowsFlatten ?? []}
                                    groupBy={{
                                        xAxis: it => it.groupByFieldX,
                                        yAxis: groupByFieldTypeY ? it => it.groupByFieldY : undefined,
                                    }}
                                >
                                    {({ items, mode }) => (
                                        <Stack {...getPropsForMatrixMode(mode)}>
                                            <StackedAvatars
                                                employeeAvatars={getEmployeeAvatars(items)}
                                                max={mode === 'STACK' ? 10 : undefined}
                                                orientation={mode === 'STACK' ? 'vertical' : undefined}
                                                spacing={mode === 'STACK' ? 1 : undefined}
                                                size='medium'
                                                grid={mode === 'MATRIX'}
                                            />
                                        </Stack>
                                    )}
                                </Matrix>
                            )}
                        </StateHandler>
                    </Stack>
                </Stack>
            </ContentContainer>
            <Footer justifyContent='space-between'>
                {report && (
                    <>
                        <Typography variant='body1bold'>{getLabelTranslation(report?.title)}</Typography>

                        <Stack direction='row' gap={1} alignItems='center'>
                            <Typography variant='body1bold'>{t('reports.share.visible_to')}</Typography>
                            <StackedAvatars employeeAvatars={report.viewers} size='small' />
                            {/* Todo factorize this with grid mode */}
                            {isReportManager && (
                                <Button variant='text' onClick={() => onOpenShareDialog()}>
                                    {t('general.share')} <Share08Icon />
                                </Button>
                            )}
                            {isReportManager && (
                                <LoadingButton onClick={handleSave()} variant='contained' loading={saveIsLoading} disabled={saveIsLoading}>
                                    {t('general.save')}
                                </LoadingButton>
                            )}
                            {!isReportManager && <Button onClick={() => navigate('/reports')}>{t('general.back')}</Button>}
                        </Stack>
                    </>
                )}
            </Footer>
        </>
    );
};

const getValueKey = (field?: ReportField): string => {
    if (!field) {
        return '';
    }
    // TODO find a way to make this function more generic and reusable with ReportGrid Component
    switch (field.type) {
        case 'STRING':
            return field.stringValue;
        case 'ENUM':
            return getEnumTranslation(field.fieldDefinition.fieldType, field.stringValue);
        case 'EMPLOYEE':
            return field.employeeValues[0]?.displayName;
        case 'LABEL':
            return getLabelTranslation(field.labelValue);
        case 'DATE':
            return field.dateValue ? formatInDefaultDate(new Date(field.dateValue)) : '';
        case 'NUMBER':
            return field.numberValue.toString();
        case 'REFERENCE':
            return field.stringValue;
        case 'DOCUMENT':
            return field.documentValue.id.toString();
        case 'SECTION_FIELD_DOCUMENT':
            return field.sectionFieldDocumentValues[0].id.toString(); // TODO: not sure
        default:
            console.error('Field type not supported', field);
            return '';
    }
};
