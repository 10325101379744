import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { RogerColDef } from '@/Components/ag-grid-wrapper/AgGridWrapper';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { useGetJobs } from '@/hooks/job/Job.hook';
import { Job } from '@/domain/job/Job.model';
import { getLabelTranslation } from '@/utils/language.util';
import { useAppSelector } from '@/stores/store';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { ICellRendererParams } from '@ag-grid-community/core';
import { deleteJob } from '@/domain/job/Job.service';
import { handleError } from '@/utils/api.util';
import { BasicMenu, BasicMenuItem } from '@/Components/basic-menu/BasicMenu';
import { IconButton } from '@mui/material';
import { MoreVerticalIcon } from '@/assets/Icons/Icons';

export const JobSettingsPage: FC = () => {
    const { t } = useTranslation();

    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const { data: jobs = [], isError, isLoading, error, refetch: refetchJobs } = useGetJobs();

    const handleDelete = async (params: ICellRendererParams<Job>) => {
        if (!params.data?.id) {
            return;
        }
        try {
            await deleteJob(params.data.id);
            await refetchJobs();
        } catch (e) {
            handleError(e);
        }
    };

    const items = (params: ICellRendererParams<Job>): BasicMenuItem[] => {
        return [
            {
                title: t('general.delete'),
                onClick: () => handleDelete(params),
            },
        ];
    };

    const getMoreVerticalButtons = (params: ICellRendererParams<Job>) => (
        <BasicMenu
            items={items(params)}
            button={
                <IconButton role='button' aria-label='more'>
                    <MoreVerticalIcon />
                </IconButton>
            }
        />
    );

    const config: ConfigType<Job> = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('general.add')} ${t('job_settings_page.job')}`,
        },
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    field: 'name',
                    valueGetter: ({ data }) => {
                        return getLabelTranslation(data?.name, currentEmployee?.language);
                    },
                },
            ] satisfies RogerColDef<Job>[],
        },
    };

    if (hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.JOB_FAMILIES)) {
        config.table?.columns?.push({
            field: 'jobFamily.name',
        });
    }
    config.table?.columns?.push({
        type: 'actionMenu',
        cellRenderer: getMoreVerticalButtons,
    });

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <CompanySettingsLayout options={config} data={jobs} isSearchAvailable={false} />
        </StateHandler>
    );
};
