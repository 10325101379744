import i18next, { t } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getAppConfig } from '@/config/config';
import { handleError } from '@/utils/api.util';
import { format as formatDate, formatDistance, formatRelative, intlFormatDistance, isDate, minutesToHours } from 'date-fns';
import resourcesToBackend from 'i18next-resources-to-backend';
import { setLocale } from 'yup';

export const DEFAULT_I18N_LANGUAGE = 'fr';

const handleDateFormats = (value: Date, format: string) => {
    if (format === 'short') {
        return formatDate(value, getAppConfig().DEFAULT_DATE_FORMAT);
    }
    if (format === 'long') {
        return formatDate(value, getAppConfig().DEFAULT_LITERAL_DATE);
    }
    if (format === 'relative') {
        return formatRelative(value, new Date());
    }
    if (format === 'ago') {
        return formatDistance(value, new Date(), {
            addSuffix: true,
        });
    }

    if (format === 'time') {
        return formatDate(value, getAppConfig().DEFAULT_HOURS_FORMAT);
    }

    return formatDate(value, format);
};

export enum DurationUnit {
    DAYS = 'DAYS',
    HOURS = 'HOURS',
    MINUTES = 'MINUTES',
}

const formatDuration = (duration: number, unit: DurationUnit): string => {
    switch (unit) {
        case DurationUnit.DAYS:
            return t('duration.days', { count: duration });
        case DurationUnit.HOURS:
            return formatHourDuration(duration);
        case DurationUnit.MINUTES:
            return t('duration.minutes', { count: duration });
    }

    return '';

    // Example usage:
    // const duration = 2.10;
    // Output: "2 hours 06 minutes" or "2h06" depending on the format
    function formatHourDuration(duration: number) {
        const isNegative = duration < 0;
        const sign = isNegative ? '-' : '';
        const absoluteDuration = Math.abs(duration);

        const hours = Math.floor(absoluteDuration);
        const minutes = Math.round((absoluteDuration - hours) * 60);

        const formattedMinutes = minutes === 0 ? '' : getFormattedMinutes(minutes);
        return sign + t('duration.hours', { count: hours }) + formattedMinutes;
    }

    function getFormattedMinutes(minutes: number) {
        if (minutes < 10) {
            return '0' + minutes;
        }
        return minutes;
    }
};

export async function initI18next(): Promise<void> {
    await i18next
        .use(initReactI18next)
        .use(resourcesToBackend((language: string, namespace: string) => import(`../translations/${namespace}_${language}.json`)))
        .init({
            lng: DEFAULT_I18N_LANGUAGE,
            fallbackLng: DEFAULT_I18N_LANGUAGE,

            ns: ['translation', 'ag_grid'],
            defaultNS: 'translation',
            interpolation: {
                skipOnVariables: false,
                escapeValue: false, // This is useful for this usage <Trans i18nKey=''components={{ bold: <strong /> }} values={{ var }} />
            },
        })
        .catch(error => {
            console.error('I18n initialization failure => ' + error);
        });

    if (i18next?.isInitialized && i18next?.services?.formatter) {
        i18next.services.formatter.add('datetime', (value, _lng, options) => {
            if (isDate(value) && options) {
                return handleDateFormats(value, options.dateFormat);
            }
            return value;
        });
        i18next.services.formatter.add('formatDuration', (value, _lng, options) => {
            return formatDuration(value, options.unit);
        });

        i18next.services.formatter.add('formatHourDuration', (value, _lng, options) => {
            return formatDuration(value / 60, options.unit);
        });

        i18next.services.formatter.add('minutesToHours', (value, _lng, _options) => {
            return minutesToHours(value)?.toString();
        });

        i18next.services.formatter.add('humanReadableDistance', (value, _lng, options) => {
            const baseDate = options?.baseDate || new Date();
            return intlFormatDistance(new Date(value), baseDate, {
                ...options?.interpolationOptions,
                locale: _lng,
            });
        });
    }
}

initI18next().catch(handleError);

// Yup validation messages
setLocale({
    mixed: {
        notType: ({ type }) => {
            return i18next.t('general.validations.not_type', { context: type });
        },
        required: () => i18next.t('general.validations.required'),
    },
    string: {
        trim: () => i18next.t('general.validations.required'),
    },
    number: {
        min: ({ min }) => i18next.t('general.validations.min', { min }),
        max: ({ max }) => i18next.t('general.validations.max', { max }),
    },
});

export default i18next;
