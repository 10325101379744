import { Paper, Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { canApproveRejectProfileUpdatePendingRequest, hasAccessOrganizationChartPolicy } from '@/domain/permission/Permission.service';
import { useAppSelector } from '@/stores/store';
import { useLocation, useNavigate } from 'react-router-dom';

export const PeopleHeader: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const realm = useAppSelector(state => state.ui.currentRealm);

    if (!realm || !currentEmployee) {
        return undefined;
    }

    const tabOptions: { name: string; value: string }[] = [];

    tabOptions.push({
        name: t('people.tabs.directory'),
        value: '/people',
    });

    if (hasAccessOrganizationChartPolicy(grantedPolicies)) {
        tabOptions.push({
            name: t('people.tabs.organization_chart'),
            value: '/people/organization-chart',
        });
    }

    if (canApproveRejectProfileUpdatePendingRequest(grantedPolicies, currentEmployee?.id)) {
        tabOptions.push({
            name: t('people.tabs.profile_update_pending_request'),
            value: '/people/employee-requests',
        });
    }

    const isHeaderVisible = !!tabOptions.filter(item => item.value === location.pathname).length;

    if (!isHeaderVisible) {
        return;
    }

    return (
        <Paper
            sx={{
                borderRadius: 1,
                display: { xs: 'none', md: 'block' },
            }}
        >
            <Tabs value={location.pathname} onChange={(_e, value) => navigate(value)} aria-label='People Tabs Control'>
                {tabOptions.map(item => (
                    <Tab key={item.name} label={item.name} value={item.value} />
                ))}
            </Tabs>
        </Paper>
    );
};
