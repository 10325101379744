import { MoreVerticalIcon } from '@/assets/Icons/Icons';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

type Props = {
    onEditClicked: () => void;
    onDeleteClicked: () => void;
    onDuplicateClicked: () => void;
};

export const SurveyTemplatesActionButtons: FC<Props> = ({ onEditClicked, onDeleteClicked, onDuplicateClicked }) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const { t } = useTranslation();

    return (
        <>
            <Menu
                open={popupVisible}
                id='user-menu'
                anchorEl={anchorEl}
                keepMounted
                onClose={() => setPopupVisible(false)}
                anchorOrigin={{ vertical: 30, horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem
                    dense={true}
                    onClick={event => {
                        event.stopPropagation();
                        setPopupVisible(false);
                        onEditClicked();
                    }}
                >
                    {t('general.edit')}
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={event => {
                        event.stopPropagation();
                        setPopupVisible(false);
                        onDeleteClicked();
                    }}
                >
                    {t('general.delete')}
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={event => {
                        event.stopPropagation();
                        setPopupVisible(false);
                        onDuplicateClicked();
                    }}
                >
                    {t('general.duplicate')}
                </MenuItem>
            </Menu>
            <IconButton
                size='small'
                onClick={event => {
                    setAnchorEl(event.currentTarget);
                    event.preventDefault();
                    event.stopPropagation();
                    setPopupVisible(true);
                }}
            >
                <MoreVerticalIcon />
            </IconButton>
        </>
    );
};
