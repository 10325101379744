export const getAvatarSizeProps = <T extends keyof typeof sizePropsMap>(size: T): (typeof sizePropsMap)[T] => {
    return sizePropsMap[size];
};

const sizePropsMap = {
    small: {
        width: 24,
        height: 24,
        fontSize: 10,
        fontWeight: 'bold',
    },
    medium: {
        width: 32,
        height: 32,
        fontSize: 13,
        fontWeight: 'bold',
    },
    large: {
        width: 40,
        height: 40,
        fontSize: 14,
        fontWeight: 'bold',
    },
    xl: {
        width: 48,
        height: 48,
        fontSize: 16,
        fontWeight: 'bold',
    },
    xxl: {
        width: 80,
        height: 80,
        fontSize: 24,
        fontWeight: 'bold',
    },
    xxxl: {
        width: 120,
        height: 120,
        fontSize: 32,
        fontWeight: 'bold',
    },
} as const;
