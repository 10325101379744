import { SectionFieldType } from '@/domain/section-setting/Section.model';

type FieldTypeToPropertyMap = {
    [SectionFieldType.EMPLOYEE_REFERENCE]: 'employeeReferences';
    [SectionFieldType.CUSTOM_LIST_ITEM]: 'customListItemReferences';
    [SectionFieldType.CUSTOM_MULTI_LIST_ITEM]: 'customListItemReferences';
    [SectionFieldType.ENUM_LIST_ITEM]: 'stringValue';
    [SectionFieldType.DATE]: 'dateValue';
    [SectionFieldType.BIRTHDAY]: 'dateValue';
    [SectionFieldType.NUMBER]: 'numberValue';
    [SectionFieldType.STRING]: 'stringValue';
    [SectionFieldType.PHONE_NUMBER]: 'stringValue';
    [SectionFieldType.DOT_STRING]: 'stringValue';
    [SectionFieldType.COUNTRY]: 'countryValue';
    [SectionFieldType.SECTION_FIELD_DOCUMENT]: 'documents';
    [SectionFieldType.IBAN_NUMBER]: 'stringValue';
    [SectionFieldType.AVS_NUMBER]: 'stringValue';
};

export const getFieldValueProperty = <T extends keyof FieldTypeToPropertyMap>(fieldType: T): FieldTypeToPropertyMap[T] => {
    switch (fieldType) {
        case SectionFieldType.EMPLOYEE_REFERENCE:
            return 'employeeReferences' as FieldTypeToPropertyMap[T];
        case SectionFieldType.CUSTOM_LIST_ITEM:
        case SectionFieldType.CUSTOM_MULTI_LIST_ITEM:
            return 'customListItemReferences' as FieldTypeToPropertyMap[T];
        case SectionFieldType.DATE:
        case SectionFieldType.BIRTHDAY:
            return 'dateValue' as FieldTypeToPropertyMap[T];
        case SectionFieldType.NUMBER:
            return 'numberValue' as FieldTypeToPropertyMap[T];
        case SectionFieldType.ENUM_LIST_ITEM:
        case SectionFieldType.STRING:
        case SectionFieldType.PHONE_NUMBER:
        case SectionFieldType.DOT_STRING:
        case SectionFieldType.IBAN_NUMBER:
        case SectionFieldType.AVS_NUMBER:
            return 'stringValue' as FieldTypeToPropertyMap[T];
        case SectionFieldType.COUNTRY:
            return 'countryValue' as FieldTypeToPropertyMap[T];
        case SectionFieldType.SECTION_FIELD_DOCUMENT:
            return 'documents' as FieldTypeToPropertyMap[T];
        default:
            return 'stringValue' as FieldTypeToPropertyMap[T];
    }
};
