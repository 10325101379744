import { FC } from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack/Stack';
import { EmployeeAvatar } from '@/Components/employee-avatar/EmployeeAvatar';
import Typography from '@mui/material/Typography';
import { Announcement } from '@/domain/announcement/Announcement.model';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { useTranslation } from 'react-i18next';
import { BasicMenu, BasicMenuItem } from '@/Components/basic-menu/BasicMenu';
import { Box, IconButton, Tooltip } from '@mui/material';
import { AnnouncementImageBox } from '@/page/announcement/AnnouncementImageBox';
import { getLabelTranslation } from '@/utils/language.util';
import { Globe02Icon, UserMultiple02Icon } from 'hugeicons-react';
import { MoreVerticalIcon } from '@/assets/Icons/Icons';

type AnnouncementProps = {
    announcement: Announcement;
    displayMenu?: boolean;
    onEdit?: () => void;
    onDelete?: () => void;
};
export const AnnouncementItem: FC<AnnouncementProps> = ({ announcement, displayMenu, onEdit, onDelete }) => {
    const { createdBy, title, content, imageUrl, startDate, departments, locations } = announcement;
    const { t } = useTranslation();
    if (displayMenu && (!onEdit || !onDelete)) {
        console.error('onEdit and onDelete are required when displayMenu is true');
    }

    const items: BasicMenuItem[] = [
        {
            title: t('general.edit'),
            onClick: onEdit,
        },
        {
            title: t('general.delete'),
            onClick: onDelete,
        },
    ];

    const publishedToAll = !departments.length && !locations.length;

    const renderTooltipTitle = (): JSX.Element => {
        if (publishedToAll) {
            return <Box>{t('home_page.announcements.published_to_all')}</Box>;
        }
        return (
            <>
                {!!departments.length && (
                    <Box>
                        {t('home_page.announcements.published_to_departments', { departments: departments.map(d => getLabelTranslation(d.name)).join(', ') })}
                    </Box>
                )}
                {!!locations.length && <Box>{t('home_page.announcements.published_to_locations', { locations: locations.map(l => l.name).join(', ') })}</Box>}
            </>
        );
    };

    return (
        <Stack gap={2} component={Paper} p={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} gap={2}>
                    <EmployeeAvatar employeeAvatar={createdBy} />
                    <Stack>
                        <Typography variant={'body1bold'}>{createdBy.displayName}</Typography>
                        <Stack direction={'row'} alignItems={'flex-start'} gap={1}>
                            <Typography variant={'body3'}>{formatInDefaultDate(startDate)}</Typography>
                            <Tooltip title={renderTooltipTitle()} aria-label={'tooltip'}>
                                <Box> {publishedToAll ? <Globe02Icon size={14} /> : <UserMultiple02Icon size={16} />}</Box>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Stack>
                {displayMenu && (
                    <BasicMenu
                        items={items}
                        button={
                            <IconButton role='button' aria-label='more' sx={{ mr: -1.5 }}>
                                <MoreVerticalIcon />
                            </IconButton>
                        }
                    />
                )}
            </Stack>
            <Stack gap={1}>
                <Typography variant='h2'>{title}</Typography>
                <Typography
                    className={'rich_text'}
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            </Stack>
            {!!imageUrl && (
                <Stack>
                    <AnnouncementImageBox src={imageUrl} alt={title} />
                </Stack>
            )}
        </Stack>
    );
};
