import { EmployeeAvatar } from '@/Components/employee-avatar/EmployeeAvatar';
import { Employee, EmployeeStatus } from '@/domain/employee/Employee.model';
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { createDefaultLabel } from '@/domain/label/Label.service';

type Props = {
    text: string;
    selectedWord?: string;
    user?: Employee;
};

const useStyles = makeStyles(theme => ({
    imageSmall: {
        width: 24,
        height: 24,
        fontSize: 16,
        borderRadius: 64,
        backgroundColor: theme.palette.grey[200],
        '& svg': {
            fill: theme.palette.text.primary,
        },
    },
    comment: {
        marginBottom: theme.spacing(2),
    },
    user: {
        width: 34,
    },
    commentText: {},
    groupByKey: {
        marginBottom: theme.spacing(1),
        display: 'block',
    },
    userInfo: {
        display: '-webkit-box',
        overflow: 'hidden',
        maxWidth: 105,
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
    },
}));

export const Comment: FC<Props> = ({ text, selectedWord, user }) => {
    const classes = useStyles();

    const defaultUser = {
        id: 0,
        firstName: 'John',
        lastName: 'Doe',
        email: '',
        userStatus: EmployeeStatus.INACTIVE,
        username: '',
        avatarImageUrl: '',
        jobTitle: createDefaultLabel(),
    };

    const makeBold = (text: string, selectedWord: string) => {
        return text.replace(new RegExp('(\\b)(' + selectedWord + ')(\\b)', 'ig'), '$1<b>$2</b>$3');
    };

    if (!text) {
        return <></>;
    }

    return (
        <Grid container spacing={2} alignItems={'top'} className={classes.comment}>
            <Grid item>
                <Grid container direction='row' spacing={1} className={classes.user} alignItems={'center'}>
                    <Tooltip placement='left' title={user ? `${user.displayName}` : ''}>
                        <Grid item>
                            {!user ? (
                                <Avatar
                                    alt={`${defaultUser.firstName} ${defaultUser.lastName}`}
                                    src={defaultUser.avatarImageUrl}
                                    className={classes.imageSmall}
                                />
                            ) : (
                                <EmployeeAvatar employeeAvatar={user} />
                            )}
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>

            {text && (
                <Grid item xs={12} sm>
                    {selectedWord ? (
                        <Typography variant='body2' className={classes.commentText} dangerouslySetInnerHTML={{ __html: makeBold(text, selectedWord) }} />
                    ) : (
                        <Typography variant='body2' className={classes.commentText}>
                            {text}
                        </Typography>
                    )}
                </Grid>
            )}
        </Grid>
    );
};
