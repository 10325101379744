import { DayPeriod, UnitType } from './Date.model';
import { DayOfWeek } from '@/utils/datetime.util';

export const getDayPeriodTranslationKey = (dayPeriod: DayPeriod | undefined): string => {
    switch (dayPeriod) {
        case DayPeriod.ALL_DAY:
            return 'domain.day_period.all_day';
        case DayPeriod.MORNING:
            return 'domain.day_period.morning';
        case DayPeriod.AFTERNOON:
            return 'domain.day_period.afternoon';
        default:
            return '';
    }
};

export const getUnitTypeTranslationKey = (unitType: UnitType): string => {
    switch (unitType) {
        case UnitType.DAYS:
            return 'domain.unit_type.days';
        case UnitType.HOURS:
            return 'domain.unit_type.hours';
        default:
            return '';
    }
};

export const getDayOfWeekTranslationKey = (dayOfWeek: DayOfWeek): string => {
    switch (dayOfWeek) {
        case DayOfWeek.MONDAY:
            return 'domain.day_of_week.monday';
        case DayOfWeek.TUESDAY:
            return 'domain.day_of_week.tuesday';
        case DayOfWeek.WEDNESDAY:
            return 'domain.day_of_week.wednesday';
        case DayOfWeek.THURSDAY:
            return 'domain.day_of_week.thursday';
        case DayOfWeek.FRIDAY:
            return 'domain.day_of_week.friday';
        case DayOfWeek.SATURDAY:
            return 'domain.day_of_week.saturday';
        case DayOfWeek.SUNDAY:
            return 'domain.day_of_week.sunday';
        default:
            return '';
    }
};

export const getDayOfWeekShortTranslationKey = (dayOfWeek: DayOfWeek): string => {
    switch (dayOfWeek) {
        case DayOfWeek.MONDAY:
            return 'domain.day_of_week.monday_short';
        case DayOfWeek.TUESDAY:
            return 'domain.day_of_week.tuesday_short';
        case DayOfWeek.WEDNESDAY:
            return 'domain.day_of_week.wednesday_short';
        case DayOfWeek.THURSDAY:
            return 'domain.day_of_week.thursday_short';
        case DayOfWeek.FRIDAY:
            return 'domain.day_of_week.friday_short';
        case DayOfWeek.SATURDAY:
            return 'domain.day_of_week.saturday_short';
        case DayOfWeek.SUNDAY:
            return 'domain.day_of_week.sunday_short';
        default:
            return '';
    }
};
