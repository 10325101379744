import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { AsyncSelectFilter, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { PendingDayTimesheet } from '@/domain/timesheet/Timesheet.model';
import { getLabelTranslation } from '@/utils/language.util';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';
import { searchAreas } from '@/domain/area/Area.service';

export type ManageTimesheetsPendingPageFilters = 'JOB' | 'LOCATION' | 'DEPARTMENT' | 'AREA';

export const useTimesheetsPendingPageFilters = (
    pendingDayTimesheets: PendingDayTimesheet[],
): {
    filters: (SelectFilter | AsyncSelectFilter)[];
} => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<(SelectFilter | AsyncSelectFilter)[]>([]);

    function uniqSortBy<T>(array: (T | undefined)[] | null | undefined, sortByFunctions: ((item: T) => string)[]): T[] {
        return sortBy(
            uniqBy(
                array?.filter((item): item is T => !!item),
                'id',
            ),
            sortByFunctions.map((_, index) => `0.${index}`),
        );
    }

    useEffect(() => {
        const uniqueLocations = uniqSortBy(
            pendingDayTimesheets.flatMap(dayTimesheet => dayTimesheet.employee?.currentEmployments?.map(employment => employment.location) || []),
            [o => o?.name],
        );

        const uniqueDepartments = uniqSortBy(
            pendingDayTimesheets.flatMap(dayTimesheet => dayTimesheet.employee?.currentEmployments?.map(employment => employment.department) || []),
            [o => getLabelTranslation(o.name)],
        );

        const uniqueJobs = uniqSortBy(
            pendingDayTimesheets.flatMap(dayTimesheet => dayTimesheet.employee?.currentEmployments?.map(employment => employment.job) || []),
            [o => getLabelTranslation(o.name)],
        );

        const newFilters: (SelectFilter | AsyncSelectFilter)[] = [
            {
                filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
                type: 'multi-select',
                selectMode: 'SYNC',
                options: uniqueLocations?.map(location => ({
                    label: location.name,
                    value: location.id,
                })),
                key: 'LOCATION' as ManageTimesheetsPendingPageFilters,
                rule: 'EQUALS',
                availableRules: [],
            },
            {
                filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
                type: 'multi-select',
                selectMode: 'SYNC',
                options: uniqueDepartments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                })),
                key: 'DEPARTMENT' as ManageTimesheetsPendingPageFilters,
                rule: 'EQUALS',
                availableRules: [],
            },
            {
                filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
                type: 'multi-select',
                selectMode: 'SYNC',
                options: uniqueJobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                })),
                key: 'JOB' as ManageTimesheetsPendingPageFilters,
                rule: 'EQUALS',
                availableRules: [],
            },
            {
                filterName: 'Areas',
                type: 'multi-select',
                selectMode: 'ASYNC',
                fetchOptions: async () => {
                    const areas = await searchAreas();
                    return areas?.map(area => ({
                        label: area.name,
                        value: area.id,
                    }));
                },
                key: 'AREA',
                rule: 'EQUALS',
                availableRules: [],
            },
        ];

        setFilters(newFilters);
    }, [pendingDayTimesheets, t]);

    return {
        filters,
    };
};
