import { FC, useState } from 'react';
import { Button, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EmployeeFieldMoreButton } from '@/page/employee-profile/employee-profile-info/EmployeeFieldMoreButton/EmployeeFieldMoreButton';
import { useNavigate } from 'react-router-dom';
import { handleError } from '@/utils/api.util';
import { deleteTimesheetSettings } from '@/domain/timesheet-setting/TimesheetSetting.service';
import { TimesheetMode, TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { useSearchTimesheetSettings } from '@/page/setting/time-management/TimesheetSettings.hook';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { MoreVerticalCircle01Icon, Tick02Icon } from 'hugeicons-react';
import { ConfirmDialog } from '@/Components/confirmation-dialog/ConfirmDialog';

export const TimeManagementAllSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [timesheetSettingToDelete, setTimesheetSettingToDelete] = useState<TimesheetSetting>();

    const navigate = useNavigate();

    const { data: timesheetSettings, isLoading, isError, error, refetch: refetchTimesheetSettings } = useSearchTimesheetSettings();

    const getNightWorkWordings = (timesheetSetting: TimesheetSetting) => {
        const { nightBonusStartTime, nightBonusEndTime, nightBonusPercentage } = timesheetSetting;

        const nightBonusEndTimeString = `${nightBonusStartTime ? ' - ' : ''}${nightBonusEndTime}`;
        const sentences = [
            nightBonusStartTime ? nightBonusStartTime : '',
            nightBonusEndTime ? nightBonusEndTimeString : '',
            nightBonusPercentage ? ` (${t('time_management_settings_page.bonus')} ${nightBonusPercentage}%)` : '',
        ];

        return sentences.join('');
    };

    const handleDeleteTimesheetSettings = async (timesheetSettingsId: number) => {
        try {
            await deleteTimesheetSettings(timesheetSettingsId);
        } catch (error) {
            handleError(error);
        } finally {
            refetchTimesheetSettings().catch(handleError);
        }
    };

    return (
        <Stack maxWidth='100vw' minHeight='100vh' gap={2}>
            <Button sx={{ alignSelf: 'flex-end' }} onClick={() => navigate(`new`, { state: {} })}>
                {t('time_management_settings_page.add_working_time')}
            </Button>
            <StateHandler isLoading={isLoading} isError={isError} error={error}>
                <Paper>
                    <Stack p={2} gap={1} divider={<Divider />}>
                        <Stack direction='row' justifyContent='space-between' paddingX={1} gap={1} alignItems={'center'}>
                            <Typography flex='2' variant='body1bold'>
                                {t('time_management_settings_page.setting_name')}
                            </Typography>
                            <Typography flex='2' variant='body1bold'>
                                {t('time_management_settings_page.timesheet_mode')}
                            </Typography>
                            <Typography flex='1' variant='body1bold'>
                                {t('time_management_settings_page.auto_fill')}
                            </Typography>
                            <Typography flex='1' variant='body1bold'>
                                {t('time_management_settings_page.requires_comment')}
                            </Typography>
                            <Typography flex='2' variant='body1bold'>
                                {t('time_management_settings_page.night_work')}
                            </Typography>
                            <Typography flex='1' variant='body1bold'>
                                {t('time_management_settings_page.saturday')}
                            </Typography>
                            <Typography flex='1' variant='body1bold'>
                                {t('time_management_settings_page.sunday_holidays')}
                            </Typography>
                            {/* this is a hack to align header */}
                            <IconButton>
                                <MoreVerticalCircle01Icon visibility='hidden' />
                            </IconButton>
                        </Stack>

                        {timesheetSettings?.map(timesheetSetting => (
                            <Stack
                                key={timesheetSetting.id}
                                direction='row'
                                justifyContent='space-between'
                                paddingX={1}
                                gap={1}
                                alignItems={'center'}
                                divider={<Divider />}
                            >
                                <Typography flex='2'>{timesheetSetting?.name}</Typography>
                                <Typography flex='2'>
                                    {timesheetSetting?.timesheetMode === TimesheetMode.NORMAL
                                        ? t('timesheets.timesheet_mode.mode_normal')
                                        : t('timesheets.timesheet_mode.mode_simplified')}
                                </Typography>
                                <Typography flex='1'>{timesheetSetting?.autofillTimesheet && <Tick02Icon />}</Typography>
                                <Typography flex='1'>{timesheetSetting?.mandatoryComment && <Tick02Icon />}</Typography>
                                <Typography flex='2'>{getNightWorkWordings(timesheetSetting)}</Typography>
                                <Typography flex='1'>{timesheetSetting?.saturdayBonusPercentage}%</Typography>
                                <Typography flex='1'>{timesheetSetting?.sundayBonusPercentage}%</Typography>

                                <EmployeeFieldMoreButton
                                    onDeleteClicked={() => setTimesheetSettingToDelete(timesheetSetting)}
                                    cancelEnabled={false}
                                    onEditClicked={() => navigate(timesheetSetting?.id.toString(), { state: { timesheetSetting } })}
                                />
                            </Stack>
                        ))}
                    </Stack>
                </Paper>
            </StateHandler>

            <ConfirmDialog
                title={t('time_management_settings_page.are_you_sure_delete_setting')}
                content={t('general.action_irreversible')}
                open={!!timesheetSettingToDelete?.id}
                onClose={() => {
                    setTimesheetSettingToDelete(undefined);
                }}
                onConfirm={() => {
                    if (timesheetSettingToDelete?.id) {
                        handleDeleteTimesheetSettings(timesheetSettingToDelete.id)
                            .then(() => {
                                setTimesheetSettingToDelete(undefined);
                            })
                            .catch(e => {
                                handleError(e);
                            });
                    }
                }}
                // Override default confirm button props
                confirmButtonProps={{
                    children: t('general.delete'),
                    color: 'error',
                }}
            />
        </Stack>
    );
};
