import { Button, DialogActions, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { FC } from 'react';

import { DialogWrapper } from '@/Components/dialog-wrapper/DialogWrapper';
import { FieldDate } from '@/Components/form/field-date/FieldDate';
import { FieldSwitch } from '@/Components/form/field-switch/FieldSwitch';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { TimeFieldWrapper } from '@/Components/time-field-wrapper/TimeFieldWrapper';
import { Survey } from '@/domain/survey/Survey.model';
import { updateSurvey } from '@/domain/survey/Survey.service';
import { useGetSurvey } from '@/hooks/survey/Survey.hook';
import { EditSurveySetupFormSchemaType, getEditSurveySetupFormSchema } from '@/page/survey/edit-survey-setup-dialog/EditSurveySetupFormDialog.schema';
import { getDateTimeFromDateAndTime } from '@/utils/datetime.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type EditSurveySetupFormDialogProps = {
    surveyId: number;
    onClose: () => void;
};

export const EditSurveySetupFormDialog: FC<EditSurveySetupFormDialogProps> = ({ surveyId, onClose }) => {
    const { t } = useTranslation();
    const { data: survey, isLoading: isSurveyLoading, isError: isSurveyError, error: surveyError } = useGetSurvey(surveyId);

    return (
        <DialogWrapper onClose={onClose} open={true} maxWidth={'sm'} header={t('edit_survey_form_dialog.title')}>
            <DialogContent>
                <StateHandler isLoading={isSurveyLoading} isError={isSurveyError} error={surveyError}>
                    {survey && <EditSurveySetupForm survey={survey} onClose={onClose} />}
                </StateHandler>
            </DialogContent>
            <DialogActions>
                <Button type={'submit'} form={'editSurveyForm'} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

type EditSurveySetupFormType = {
    survey: Survey;
    onClose: () => void;
};

const EditSurveySetupForm: FC<EditSurveySetupFormType> = ({ survey, onClose }) => {
    const { t } = useTranslation();

    const getDefaultValue = (survey: Survey): Partial<EditSurveySetupFormSchemaType> => {
        return {
            sendNotificationNow: false,
            startDate: survey.startDate,
            endDate: survey.endDate,
            startTime: survey.startDate,
            endTime: survey.endDate,
        };
    };

    const { handleSubmit, watch, setValue, control } = useForm<EditSurveySetupFormSchemaType>({
        resolver: yupResolver(getEditSurveySetupFormSchema()),
        defaultValues: getDefaultValue(survey),
    });

    const onSave = async (survey: Survey, data: EditSurveySetupFormSchemaType) => {
        if (!survey.id) {
            return;
        }

        const startDateTime =
            !data.sendNotificationNow && data.startDate && data.startTime ? getDateTimeFromDateAndTime(data.startDate, data.startTime) : undefined;
        const endDateTime = data.endDate && data.endTime ? getDateTimeFromDateAndTime(data.endDate, data.endTime) : undefined;

        const mutation = {
            startDate: startDateTime,
            endDate: endDateTime,
        };
        try {
            await updateSurvey(survey.id, mutation);
            onClose();
            showSnackbar(t('edit_survey_form_dialog.survey_edit_success_toast'), 'success');
        } catch {
            showSnackbar(t('edit_survey_form_dialog.survey_edit_error_toast'), 'error');
        }
    };

    return (
        <Stack
            gap={2}
            component={'form'}
            id={'editSurveyForm'}
            onSubmit={handleSubmit(
                data => onSave(survey, data),
                err => console.error(err),
            )}
        >
            <FormControlLabel
                label={t('survey_templates_page.cycles.send_now')}
                labelPlacement='end'
                control={
                    <FieldSwitch
                        name='sendNotificationNow'
                        control={control}
                        onChange={() => {
                            // if we don't reset the values, the validation will fail if startTime or endTime is not a valid date
                            setValue('startTime', undefined);
                            setValue('startDate', undefined);
                        }}
                    />
                }
            />
            <Stack justifyContent={'flex-start'} alignItems={'flex-start'}>
                {!watch('sendNotificationNow') && (
                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                        <Stack>
                            <FormControlLabel
                                sx={{ width: '320px' }}
                                label={t('survey_templates_page.cycles.send_on')}
                                control={<FieldDate name='startDate' control={control} />}
                            />
                        </Stack>

                        <Controller
                            name={`startTime`}
                            control={control}
                            render={({ field: { ...restField }, fieldState }) => (
                                <FormControlLabel
                                    label={t('general.time')}
                                    control={<TimeFieldWrapper {...restField} helperText={fieldState.error?.message ?? ' '} />}
                                />
                            )}
                        />
                    </Stack>
                )}

                <Stack direction={'row'} gap={2} alignItems={'center'}>
                    <Stack>
                        <FormControlLabel
                            sx={{ width: '320px' }}
                            label={t('survey_templates_page.cycles.end_on')}
                            control={<FieldDate name='endDate' control={control} />}
                        />
                    </Stack>

                    <Controller
                        name={`endTime`}
                        control={control}
                        render={({ field: { ...restField }, fieldState }) => (
                            <FormControlLabel
                                label={t('general.time')}
                                labelPlacement='top'
                                control={<TimeFieldWrapper {...restField} helperText={fieldState.error?.message ?? ' '} />}
                            />
                        )}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
