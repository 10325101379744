import LoadingButton from '@mui/lab/LoadingButton';
import { FormControl, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordHint } from './PasswordHint';
import { ViewIcon } from 'hugeicons-react';

type Props = {
    title: string;
    buttonLabel: string;
    loading: boolean;
    onSubmitPassword: (password: string) => void;
};
export const PasswordForm: FC<Props> = ({ title, buttonLabel, loading, onSubmitPassword }) => {
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState<string>('');
    const [displayPassword, setDisplayPassword] = useState<boolean>(false);

    const isFormDisabled = () => {
        return !newPassword || !has8Characters(newPassword) || !hasUppercaseLetter(newPassword) || !hasLowercaseLetter(newPassword) || !hasNumber(newPassword);
    };

    return (
        <Grid container direction='column' spacing={2} alignItems='stretch'>
            <Grid item>
                <Typography variant='body2bold'>{title}</Typography>
            </Grid>
            <Grid item>
                <FormControl fullWidth={true} variant='outlined' size='small'>
                    <Typography variant='body2'>{t('login_form.password')}</Typography>
                    <TextField
                        id='password-input'
                        type={!displayPassword ? 'password' : 'text'}
                        autoFocus
                        value={newPassword}
                        InputProps={{
                            endAdornment: (
                                <IconButton size='small' onClick={() => setDisplayPassword(!displayPassword)}>
                                    <ViewIcon />
                                </IconButton>
                            ),
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <PasswordHint text={t('login_form.at_least_8_characters')} isValid={has8Characters(newPassword)} />

                <PasswordHint text={t('login_form.contains_lowercase_letter')} isValid={hasLowercaseLetter(newPassword)} />

                <PasswordHint text={t('login_form.contains_uppercase_letter')} isValid={hasUppercaseLetter(newPassword)} />

                <PasswordHint text={t('login_form.contains_number')} isValid={hasNumber(newPassword)} />
            </Grid>
            <Grid item>
                <LoadingButton
                    fullWidth={true}
                    color='primary'
                    variant='contained'
                    loading={loading}
                    loadingPosition={'start'}
                    startIcon={<></>}
                    disabled={isFormDisabled() || loading}
                    onClick={() => onSubmitPassword(newPassword)}
                >
                    {buttonLabel}
                </LoadingButton>
            </Grid>
        </Grid>
    );
};

const REGEX_SPECIAL_CHARACTERS = /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[]\\\/]/gi;

const has8Characters = (value: string): boolean => {
    return value?.length >= 8;
};

const hasLowercaseLetter = (value: string): boolean => {
    for (let i = 0; i < value.length; i++) {
        const char = value.charAt(i);
        const withoutSpecialCharacters = char.replace(REGEX_SPECIAL_CHARACTERS, '');
        if (!isNumber(char) && char === char.toLowerCase() && withoutSpecialCharacters) {
            return true;
        }
    }
    return false;
};

const hasUppercaseLetter = (value: string): boolean => {
    for (let i = 0; i < value.length; i++) {
        const char = value.charAt(i);
        const withoutSpecialCharacters = char.replace(REGEX_SPECIAL_CHARACTERS, '');
        if (!isNumber(char) && char === char.toUpperCase() && withoutSpecialCharacters) {
            return true;
        }
    }
    return false;
};

const isNumber = (ch: string): boolean => ch.length === 1 && ch >= '0' && ch <= '9';

const hasNumber = (value: string): boolean => {
    for (let i = 0; i < value.length; i++) {
        const char = value.charAt(i);
        if (isNumber(char)) {
            return true;
        }
    }
    return false;
};
