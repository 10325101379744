import { FC } from 'react';
import { Survey } from '@/domain/survey/Survey.model';
import { Paper, Typography } from '@mui/material';
import SurveyQuestionImage from '@/assets/Images/surveyQuestions.png';

import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import { makeStyles } from '@mui/styles';
import { SurveyTemplate } from '@/domain/survey-template/Survey-template.model';

type Props = {
    survey?: Survey;
    isPreviewMode: boolean;
    selectedLanguage: UserLanguage;
    surveyTemplate?: SurveyTemplate;
};

const useStyles = makeStyles(theme => ({
    root: {},
    paperBackground: {
        padding: theme.spacing(6),
        textAlign: 'center',
        maxWidth: 720,
        margin: 'auto',
    },
    title: {
        marginBottom: theme.spacing(4),
    },
}));

export const WelcomePage: FC<Props> = ({ survey, selectedLanguage, isPreviewMode, surveyTemplate }) => {
    const classes = useStyles();

    if (!survey && !isPreviewMode) {
        return <></>;
    }
    return (
        <Paper elevation={0} className={classes.paperBackground}>
            <img src={SurveyQuestionImage} alt='questions' />
            <Typography variant='h1' className={classes.title}>
                {getLabelTranslation(isPreviewMode ? surveyTemplate?.name : survey?.name, selectedLanguage)}
            </Typography>
            <Typography variant='body1'>{getLabelTranslation(isPreviewMode ? surveyTemplate?.description : survey?.description, selectedLanguage)}</Typography>
        </Paper>
    );
};
