import {
    EmployeeAddress,
    EmployeeAddressCreateMutation,
    EmployeeAddressSearchRequest,
    EmployeeAddressUpdateMutation,
} from '@/domain/employee/EmployeeAddress.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '../common';

type EmployeeAddressSearchRequestDTO = {
    employeeId: number;
};

type EmployeeAddressDTO = EmployeeAddress;

type EmployeeAddressCreateRequestDTO = DateToString<EmployeeAddressCreateMutation>;

type EmployeeAddressUpdateRequestDTO = DateToString<EmployeeAddressUpdateMutation>;

const searchEmployeeAddresses = async (request: EmployeeAddressSearchRequest): Promise<EmployeeAddress[]> => {
    return (
        await client.post<EmployeeAddressDTO[], AxiosResponse<EmployeeAddressDTO[]>, EmployeeAddressSearchRequestDTO>(
            API_BASE_URL + '/employee/addresses/search',
            request,
        )
    ).data;
};

const getEmployeeAddressById = async (id: number): Promise<EmployeeAddress> => {
    return (await client.get<EmployeeAddressDTO>(API_BASE_URL + `/employee/addresses/${id}`)).data;
};

const createEmployeeAddress = async (request: EmployeeAddressCreateMutation): Promise<EmployeeAddress> => {
    return (await client.post<EmployeeAddress, AxiosResponse<EmployeeAddress>, EmployeeAddressCreateRequestDTO>(API_BASE_URL + `/employee/addresses`, request))
        .data;
};

const updateEmployeeAddress = async (id: number, request: EmployeeAddressUpdateMutation): Promise<EmployeeAddress> => {
    return (
        await client.put<EmployeeAddressDTO, AxiosResponse<EmployeeAddressDTO>, EmployeeAddressUpdateRequestDTO>(
            API_BASE_URL + `/employee/addresses/${id}`,
            request,
        )
    ).data;
};

const deleteEmployeeAddress = async (id: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/employee/addresses/${id}`);
};

const createEmployeeAddressPendingRequest = async (request: EmployeeAddressCreateMutation): Promise<void> => {
    await client.post(API_BASE_URL + `/employee/addresses/pending`, request);
};

const updateEmployeeAddressPendingRequest = async (id: number, request: EmployeeAddressUpdateMutation): Promise<void> => {
    await client.put(API_BASE_URL + `/employee/addresses/${id}/pending`, request);
};

const deleteEmployeeAddressPendingRequest = async (id: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/employee/addresses/${id}/cancel`);
};

const approveEmployeeAddressRequest = async (id: number): Promise<void> => {
    await client.post(API_BASE_URL + `/employee/addresses/${id}/approve`);
};

const importAddresses = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/employee/addresses/import`, request)).data;
};

export const employeeAddressAPI = {
    searchEmployeeAddresses,
    getEmployeeAddressById,
    createEmployeeAddress,
    updateEmployeeAddress,
    deleteEmployeeAddress,
    createEmployeeAddressPendingRequest,
    updateEmployeeAddressPendingRequest,
    deleteEmployeeAddressPendingRequest,
    approveEmployeeAddressRequest,
    importAddresses,
};
