import { DialogContainer } from '@/Components/dialog-container/DialogContainer';
import { SelectResource } from '@/Components/select-ressource/SelectResource';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { TimeFieldWrapper } from '@/Components/time-field-wrapper/TimeFieldWrapper';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { ClockInOutCreationMutation, EmployeeTimesheetMutation, Timesheet, TimesheetMutation } from '@/domain/timesheet/Timesheet.model';
import { useGetAreas } from '@/hooks/area/Area.hook';
import { useGetClockOutForceBreakDuration } from '@/hooks/timesheet/Timesheet.hook';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { formatMinutesToHours, getTimeFormatFromDate, isValidDate, setHoursMinutes, splitHoursMinutes } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import { addDays, differenceInMinutes, startOfDay, subDays } from 'date-fns';
import { ArrowRight01Icon } from 'hugeicons-react';
import i18next from 'i18next';
import { FC, useEffect } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type TimesheetDialogClockOutProps = {
    timesheet: Timesheet;
    onClose: () => void;
    onSave: (mutation: ClockInOutCreationMutation, employeeTimesheetMutation: EmployeeTimesheetMutation | undefined) => void;
    timesheetSetting?: TimesheetSetting;
};

const FETCH_FORCE_BREAK_SECONDS = 30;

export const TimesheetClockOutDialog: FC<TimesheetDialogClockOutProps> = ({ timesheet, onSave, onClose, timesheetSetting }) => {
    const { t } = useTranslation();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);

    const endDate = new Date();
    const startDate = timesheet?.startAt ? new Date(timesheet?.startAt) : undefined;

    const {
        data: areas = [],
        isLoading: isLoadingAreas,
        isError: isErrorAreas,
        error: errorAreas,
    } = useGetAreas({
        locationIds: currentEmployee?.currentEmployments.flatMap(employment => employment.location.id) ?? [],
    });

    const {
        data: breakDuration,
        isLoading: isLoadingBreakDuration,
        isError: isErrorBreakDuration,
        error: errorBreakDuration,
        refetch,
    } = useGetClockOutForceBreakDuration({
        employeeId: currentEmployee?.id,
    });

    useEffect(
        function refetchForceBreakDuration() {
            const fetchForceBreakInterval = setInterval(() => {
                refetch().catch(handleError);
            }, FETCH_FORCE_BREAK_SECONDS * 1000);
            // Clean up the interval to avoid memory leaks
            return () => clearInterval(fetchForceBreakInterval);
        },
        [refetch],
    );

    const isBreakRequired = (): boolean => {
        return !(!breakDuration || breakDuration === 0 || !startDate || !endDate);
    };

    const schema = getTimesheetClockOutFormSchema({
        mandatoryComment: timesheetSetting?.mandatoryComment || false,
        breakRequired: isBreakRequired(),
        startDate: startDate,
        endDate: endDate,
    });

    const formMethods = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            areaId: timesheet?.area?.id,
        },
    });

    const { control, handleSubmit } = formMethods;

    if (!currentEmployee) {
        return;
    }
    const onSaveDialog = (formValues: TimesheetClockOutFormValues) => {
        if (!startDate) {
            return;
        }
        const mutation: ClockInOutCreationMutation = {
            employeeId: currentEmployee?.id,
            dateTime: endDate,
            areaId: formValues.areaId,
            comment: formValues.comment,
        };

        const employeeTimesheetMutation = createEmployeeTimesheetMutation(
            startDate,
            mutation,
            formValues.breakStart,
            formValues.breakEnd,
            breakDuration,
            timesheet,
        );

        onSave(mutation, employeeTimesheetMutation);
    };

    const areaResourceOptions = areas.map(area => ({ id: area.id, name: area.name }));
    const isLoading = isLoadingAreas || isLoadingBreakDuration;
    const error = errorAreas || errorBreakDuration;
    const isError = isErrorAreas || isErrorBreakDuration;

    return (
        <DialogContainer
            open={!!timesheet}
            onClose={onClose}
            onSave={() => handleSubmit(onSaveDialog, console.error)()}
            saveButtonText={t('timesheet_clock_out_dialog.confirm_button')}
            title={t('timesheet_clock_out_dialog.dialog_title')}
        >
            <FormProvider {...formMethods}>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    <Stack spacing={2}>
                        {!!startDate && !!endDate && <DisplayHoursAndDuration startAt={startDate} endAt={endDate} isBreakRequired={isBreakRequired()} />}
                        {!!areaResourceOptions.length && (
                            <Controller
                                name={'areaId'}
                                control={control}
                                render={({ field: { onChange, ...restField }, fieldState }) => (
                                    <SelectResource
                                        {...restField}
                                        label={t('timesheet_clock_out_dialog.area')}
                                        placeHolder={t('timesheet_clock_out_dialog.area_placeholder')}
                                        isError={!!fieldState.error}
                                        clearButton={true}
                                        options={areaResourceOptions}
                                        onUpdate={value => {
                                            onChange(value);
                                        }}
                                    />
                                )}
                            />
                        )}
                        <Controller
                            name={`comment`}
                            control={control}
                            render={({ field, fieldState }) => (
                                <FormControlLabel
                                    label={t('timesheet_clock_out_dialog.comment')}
                                    style={{ width: '100%' }}
                                    control={
                                        <TextField
                                            {...field}
                                            placeholder={t('timesheet_clock_out_dialog.comment_placeholder')}
                                            InputProps={{
                                                multiline: true,
                                                minRows: 1,
                                            }}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                            fullWidth
                                        />
                                    }
                                />
                            )}
                        />
                    </Stack>
                </StateHandler>
            </FormProvider>
        </DialogContainer>
    );
};

type DisplayHoursAndDurationProps = {
    startAt: Date;
    endAt: Date;
    isBreakRequired: boolean;
};

const DisplayHoursAndDuration: FC<DisplayHoursAndDurationProps> = ({ startAt, endAt, isBreakRequired }) => {
    const { t } = useTranslation();

    const {
        control,
        formState: { errors },
    } = useFormContext<TimesheetClockOutFormValues>();

    const getNewEndDate = (oldEndDate: Date) => {
        const endTime = getTimeFormatFromDate(oldEndDate);
        if (!endTime) {
            return;
        }
        const { hours, minutes } = splitHoursMinutes(endTime);
        const endDate = setHoursMinutes(startOfDay(new Date(startAt)), hours, minutes);

        const isEndDateOnSameDay = differenceInMinutes(new Date(endDate), new Date(startAt)) >= 0;
        if (isEndDateOnSameDay) {
            return endDate;
        } else {
            return setHoursMinutes(startOfDay(addDays(new Date(startAt), 1)), hours, minutes);
        }
    };

    const getNewStartDate = (oldStartDate: Date) => {
        const startTime = getTimeFormatFromDate(oldStartDate);
        if (!startTime) {
            return;
        }
        const { hours, minutes } = splitHoursMinutes(startTime);
        const startDate = setHoursMinutes(startOfDay(new Date(endAt)), hours, minutes);

        const isStartDateOnSameDay = differenceInMinutes(new Date(endAt), new Date(startDate)) >= 0;
        if (isStartDateOnSameDay) {
            return startDate;
        } else {
            return setHoursMinutes(startOfDay(subDays(new Date(endAt), 1)), hours, minutes);
        }
    };

    const getDuration = (): string => {
        const diffInMinutes = differenceInMinutes(endAt, startAt);
        return formatMinutesToHours(diffInMinutes);
    };

    const bgColor = isBreakRequired ? 'warning.light' : 'primary.light';
    const color = isBreakRequired ? 'warning.dark' : 'primary.dark';

    return (
        <Stack bgcolor={bgColor} borderRadius={1} justifyContent='center' alignItems='stretch' direction='column' display={'flex'} spacing={1} p={2}>
            <Stack direction='row' component={Typography} variant='body2bold' fontSize={30} color={color} alignItems='center' justifyContent='center' gap={2}>
                {getTimeFormatFromDate(startAt)}
                <ArrowRight01Icon strokeWidth={3} />
                {getTimeFormatFromDate(endAt)}
            </Stack>
            <Typography textAlign={'center'} fontSize={16} color={color}>
                {t('timesheet_clock_out_dialog.duration')} {getDuration()}
            </Typography>
            {isBreakRequired && (
                <>
                    <Typography textAlign={'center'} fontSize={16}>
                        {t('timesheet_clock_out_dialog.mandatory_break_duration')}
                    </Typography>
                    <Stack spacing={2} direction={'row'}>
                        <Controller
                            name={`breakStart`}
                            control={control}
                            render={({ field: { onChange, ...restField }, fieldState }) => (
                                <FormControlLabel
                                    label={t('timesheet_clock_out_dialog.break_start')}
                                    labelPlacement='top'
                                    control={
                                        <TimeFieldWrapper
                                            {...restField}
                                            onChange={endTime => {
                                                const newEndDate = endTime ? getNewEndDate(endTime) : endTime;
                                                onChange(newEndDate);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    variant: 'outlined',
                                                    error: !!fieldState.error,
                                                },
                                            }}
                                        />
                                    }
                                />
                            )}
                        />
                        <Controller
                            name={`breakEnd`}
                            control={control}
                            render={({ field: { onChange, ...restField }, fieldState }) => (
                                <FormControlLabel
                                    label={t('timesheet_clock_out_dialog.break_end')}
                                    labelPlacement='top'
                                    control={
                                        <TimeFieldWrapper
                                            {...restField}
                                            onChange={startTime => {
                                                const newStartDate = startTime ? getNewStartDate(startTime) : startTime;
                                                onChange(newStartDate);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    variant: 'outlined',
                                                    error: !!fieldState.error,
                                                },
                                            }}
                                        />
                                    }
                                />
                            )}
                        />
                    </Stack>
                    {(!!errors?.breakStart || !!errors?.breakEnd) && (
                        <Alert severity='error' elevation={0} sx={{ alignItems: 'center' }}>
                            {!!errors?.breakStart && <Typography variant='body2'>{errors?.breakStart?.message}</Typography>}
                            {!!errors?.breakEnd && <Typography variant='body2'>{errors?.breakEnd?.message}</Typography>}
                        </Alert>
                    )}
                </>
            )}
        </Stack>
    );
};

const createEmployeeTimesheetMutation = (
    startDate: Date,
    clockInOutCreationMutation: ClockInOutCreationMutation,
    breakStart: Date | undefined,
    breakEnd: Date | undefined,
    breakDuration: number | undefined,
    timesheet: Timesheet,
): EmployeeTimesheetMutation | undefined => {
    const doNotCreateMutation =
        breakDuration === 0 ||
        !clockInOutCreationMutation ||
        !timesheet?.referenceDate ||
        !isValidDate(startDate) ||
        !isValidDate(clockInOutCreationMutation.dateTime) ||
        !isValidDate(breakStart) ||
        !isValidDate(breakEnd);

    if (doNotCreateMutation) {
        return undefined;
    }

    const firstTimesheet: TimesheetMutation = {
        id: timesheet.id,
        startTime: startDate,
        endTime: breakStart,
        comment: clockInOutCreationMutation.comment,
        areaId: clockInOutCreationMutation.areaId,
    };
    const secondTimesheet: TimesheetMutation = {
        startTime: breakEnd,
        endTime: clockInOutCreationMutation.dateTime,
        comment: clockInOutCreationMutation.comment,
        areaId: clockInOutCreationMutation.areaId,
    };

    return {
        employeeId: clockInOutCreationMutation.employeeId,
        timesheets: [firstTimesheet, secondTimesheet],
        referenceDate: new Date(timesheet.referenceDate),
    };
};

const getTimesheetClockOutFormSchema = ({
    mandatoryComment,
    breakRequired,
    startDate,
    endDate,
}: {
    mandatoryComment: boolean;
    breakRequired: boolean;
    startDate?: Date;
    endDate?: Date;
}) => {
    return yup.object().shape({
        areaId: yup.number(),
        comment: yup
            .string()
            .trim()
            .when({
                is: () => mandatoryComment,
                then: schema => schema.required(),
                otherwise: schema => schema,
            }),
        breakStart: yup.date().when({
            is: () => !breakRequired,
            then: schema => schema,
            otherwise: schema =>
                schema.test({
                    message: i18next.t('timesheet_clock_out_dialog.break_start_error'),
                    test: (breakStart, meta) => {
                        if (isValidDate(breakStart) && isValidDate(meta.parent.breakEnd) && startDate && isValidDate(startDate)) {
                            return differenceInMinutes(breakStart, startDate) >= 0 && differenceInMinutes(meta.parent.breakEnd, breakStart) >= 0;
                        }
                        return false;
                    },
                }),
        }),
        breakEnd: yup.date().when({
            is: () => !breakRequired,
            then: () => yup.date(),
            otherwise: () =>
                yup
                    .date()
                    .required()
                    .test({
                        message: i18next.t('timesheet_clock_out_dialog.break_end_error'),
                        test: (breakEnd, meta) => {
                            if (isValidDate(breakEnd) && isValidDate(meta.parent.breakStart) && isValidDate(endDate)) {
                                return differenceInMinutes(endDate, breakEnd) >= 0 && differenceInMinutes(breakEnd, meta.parent.breakStart) >= 0;
                            }
                            return false;
                        },
                    }),
        }),
    });
};

type TimesheetClockOutFormValues = yup.InferType<ReturnType<typeof getTimesheetClockOutFormSchema>>;
