import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { formatToLocalDate } from '@/utils/datetime.util';
import { getEmployeesAvailability } from '@/domain/employee-availability/EmployeeAvailability.service';
import { EmployeeAvailability, EmployeeAvailabilitySearchRequest } from '@/domain/employee-availability/EmployeeAvailability.model';

type EmployeeAvailabilitySearchRequestWithLocalDate = DateToString<EmployeeAvailabilitySearchRequest>;

export const useGetEmployeeAvailabilities = (search: EmployeeAvailabilitySearchRequest): UseQueryResult<EmployeeAvailability[]> => {
    const [employeeAvailabilities, setEmployeeAvailabilities] = useState<EmployeeAvailability[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeAvailabilities = useCallback(async (search: EmployeeAvailabilitySearchRequestWithLocalDate) => {
        try {
            const searchRequest: EmployeeAvailabilitySearchRequest = {
                ...search,
                atDateTime: new Date(search.atDateTime),
            };

            const employeeAvailabilities = await getEmployeesAvailability(searchRequest);
            setEmployeeAvailabilities(employeeAvailabilities);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    const searchWithLocalDate: EmployeeAvailabilitySearchRequestWithLocalDate = {
        ...search,
        atDateTime: formatToLocalDate(search.atDateTime),
    };

    useDeepCompareEffect(() => {
        fetchEmployeeAvailabilities(searchWithLocalDate).catch(handleError);
    }, [fetchEmployeeAvailabilities, searchWithLocalDate]);

    return {
        data: employeeAvailabilities,
        setData: setEmployeeAvailabilities,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeAvailabilities(searchWithLocalDate),
    };
};
