import { TimeFieldWrapper } from '@/Components/time-field-wrapper/TimeFieldWrapper';
import { DialogContainer } from '@/Components/dialog-container/DialogContainer';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { durationToNumber, formatTime, getDateFromTimeFormat, getTimeFormatFromDate } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FieldNumber } from '@/Components/form/field-number/FieldNumber';
import { TimeFieldProps } from '@mui/x-date-pickers';

export type WeeklyWorkingTimeFormValues = Omit<WeeklyWorkingTime, 'id' | 'weeklyAmountWorked'> & {
    weeklyAmountWorked: string;
};

type Props = {
    onSave: (weekly: WeeklyWorkingTimeFormValues) => void;
    onClose: () => void;
    /**
     * @property defaultWeeklyWorkingTime if undefined, it means we are creating a new one
     */
    defaultWeeklyWorkingTime?: WeeklyWorkingTime;
};

const schema: yup.ObjectSchema<WeeklyWorkingTimeFormValues> = yup
    .object()
    .shape({
        name: yup.string().required(),
        weeklyAmountWorked: yup.string().required(),
        endTime: yup.string().required(),
        startTime: yup.string().required(),
        breakStartTime: yup.string().required(),
        breakDuration: yup.number().required(),
    })
    .required();

export const WeeklyWorkingTimeDialog: FC<Props> = ({ onSave, onClose, defaultWeeklyWorkingTime }) => {
    const isEdit = !!defaultWeeklyWorkingTime?.id;
    const { t } = useTranslation();

    const { handleSubmit, control, watch, setValue } = useForm<WeeklyWorkingTimeFormValues>({
        resolver: yupResolver(schema),
        defaultValues: convertToFormValues(defaultWeeklyWorkingTime) ?? {
            name: '',
            weeklyAmountWorked: undefined,
            breakStartTime: '12:00',
            breakDuration: 60,
            startTime: '08:30',
            endTime: '17:30',
        },
    });

    const updateEndTime = (startTime: string, breakDuration: number) => {
        const startTimeInMinutes = durationToNumber(startTime);
        const weeklyAmountWorkedInMinutes = durationToNumber(watch('weeklyAmountWorked'));
        const endTimeInMinutes = startTimeInMinutes + Number(breakDuration) + weeklyAmountWorkedInMinutes / 5;

        if (startTimeInMinutes < endTimeInMinutes) {
            setValue('endTime', formatTime(endTimeInMinutes));
        } else {
            setValue('endTime', startTime);
        }
    };

    const keyPrefix = 'settings_time_management.weekly_working_times.';

    const slotProps = (error?: FieldError): TimeFieldProps<Date>['slotProps'] => {
        return {
            textField: {
                variant: 'outlined',
                error: !!error,
                helperText: error?.message,
            },
        };
    };

    return (
        <DialogContainer
            maxWidth='sm'
            open={true}
            onClose={onClose}
            onSave={handleSubmit(onSave, console.error)}
            title={isEdit ? t(keyPrefix + 'update_dialog_title') : t(keyPrefix + 'create_dialog_title')}
        >
            <Stack gap={2}>
                <FormControlLabel
                    label={t(keyPrefix + 'fields.name')}
                    control={
                        <Controller
                            name={`name`}
                            control={control}
                            render={({ field, fieldState: { error } }) => <TextField {...field} fullWidth error={!!error} helperText={error?.message} />}
                        />
                    }
                />

                <FormControlLabel
                    label={t(keyPrefix + 'fields.weeklyAmountWorked')}
                    control={
                        <Controller
                            name={`weeklyAmountWorked`}
                            control={control}
                            render={({ field, fieldState: { error } }) => <TextField {...field} fullWidth error={!!error} helperText={error?.message} />}
                        />
                    }
                />
                <Typography variant='h2'>{t(keyPrefix + 'base_schedule')}</Typography>
                <Stack direction='row' gap={2} alignItems='flex-start'>
                    <FormControlLabel
                        label={t(keyPrefix + 'fields.startTime')}
                        control={
                            <Controller
                                name={`startTime`}
                                control={control}
                                render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                    <TimeFieldWrapper
                                        {...restField}
                                        value={getDateFromTimeFormat(value)}
                                        onChange={newValue => {
                                            const newStartTime = getTimeFormatFromDate(newValue ?? undefined);
                                            onChange(newStartTime);
                                            updateEndTime(newStartTime as string, watch('breakDuration'));
                                        }}
                                        slotProps={slotProps(error)}
                                    />
                                )}
                            />
                        }
                    />
                    <FormControlLabel
                        label={t(keyPrefix + 'fields.endTime')}
                        control={
                            <Controller
                                name={`endTime`}
                                control={control}
                                render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                    <TimeFieldWrapper
                                        {...restField}
                                        value={getDateFromTimeFormat(value)}
                                        onChange={newValue => {
                                            onChange(getTimeFormatFromDate(newValue ?? undefined));
                                        }}
                                        slotProps={slotProps(error)}
                                    />
                                )}
                            />
                        }
                    />
                    <FormControlLabel
                        label={t(keyPrefix + 'fields.breakStartTime')}
                        control={
                            <Controller
                                name={`breakStartTime`}
                                control={control}
                                render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                    <TimeFieldWrapper
                                        {...restField}
                                        value={getDateFromTimeFormat(value)}
                                        onChange={newValue => {
                                            onChange(getTimeFormatFromDate(newValue ?? undefined));
                                        }}
                                        slotProps={slotProps(error)}
                                    />
                                )}
                            />
                        }
                    />
                    <FormControlLabel
                        label={t(keyPrefix + 'fields.breakTime')}
                        control={
                            <FieldNumber
                                name='breakDuration'
                                control={control}
                                onChange={newBreakDuration => {
                                    if (newBreakDuration) {
                                        updateEndTime(watch('startTime'), newBreakDuration);
                                    }
                                }}
                            />
                        }
                    />
                </Stack>
            </Stack>
        </DialogContainer>
    );
};

const convertToFormValues = (weekly: WeeklyWorkingTime | undefined): WeeklyWorkingTimeFormValues | undefined => {
    if (!weekly) {
        return undefined;
    }
    return {
        name: weekly.name,
        weeklyAmountWorked: formatTime(weekly.weeklyAmountWorked),
        breakStartTime: weekly.breakStartTime,
        breakDuration: weekly.breakDuration,
        startTime: weekly.startTime,
        endTime: weekly.endTime,
    };
};
