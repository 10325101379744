import { FC } from 'react';
import { Chip, Tooltip } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { formatRelativeDate, LocalDate } from '@/utils/datetime.util';
import { useTranslation } from 'react-i18next';
import { getLocale, useUserLanguage } from '@/utils/language.util';
import { Calendar03Icon } from 'hugeicons-react';
import { DayPeriod } from '@/domain/date/Date.model';

export type WorkingAreaOrLeaveType = 'LEAVE' | 'TIMESHEET';
export type WorkingAreaOrLeave = {
    displayText: string | undefined;
    color: string | undefined;
    leaveEndDate: LocalDate | undefined;
    leaveReturnDayPeriod: DayPeriod | undefined;
    type: WorkingAreaOrLeaveType;
};

type CurrentLocationInfoProps = {
    currentWorkingAreaLeave: WorkingAreaOrLeave | undefined;
};

const getTextColor = (currentWorkingArea: WorkingAreaOrLeave) => {
    if (currentWorkingArea.type === 'LEAVE') {
        return 'white';
    } else {
        return currentWorkingArea.color;
    }
};

export const EmployeeWorkingAreaOrLeaveChip: FC<CurrentLocationInfoProps> = ({ currentWorkingAreaLeave }) => {
    const theme = createTheme({});
    const { t } = useTranslation();
    const userLanguage = useUserLanguage();
    const locale = getLocale(userLanguage);
    if (!currentWorkingAreaLeave) {
        return;
    }

    const getDisplayText = (currentWorkingArea: WorkingAreaOrLeave) => {
        if (currentWorkingArea.type === 'LEAVE') {
            const employeeLeaveTypeDisplay = currentWorkingArea.displayText ?? t('my_colleagues.away');
            const returningDate = !currentWorkingArea?.leaveEndDate ? new Date() : new Date(currentWorkingArea?.leaveEndDate);
            const today = new Date();
            return (
                employeeLeaveTypeDisplay +
                t('my_colleagues.returns') +
                formatRelativeDate(returningDate, {
                    dayPeriod: currentWorkingArea?.leaveReturnDayPeriod,
                    baseDate: today,
                    locale,
                }).toLowerCase()
            );
        }
        return currentWorkingArea.displayText;
    };

    const isLeave = currentWorkingAreaLeave.type === 'LEAVE';
    const textColor = getTextColor(currentWorkingAreaLeave);
    const displayColor = currentWorkingAreaLeave.color ?? theme.palette.warning.light;

    const displayText = getDisplayText(currentWorkingAreaLeave);

    if (!displayText) {
        return;
    }

    return (
        <Tooltip title={displayText} placement='top'>
            <Chip
                variant='outlined'
                label={displayText}
                sx={{
                    height: 28,
                    justifyContent: 'flex-start',
                    color: textColor,
                    borderColor: displayColor,
                    backgroundColor: isLeave ? displayColor : undefined,
                }}
                icon={isLeave ? <Calendar03Icon size={14} color='white' /> : undefined}
            />
        </Tooltip>
    );
};
