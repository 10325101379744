import { getAppConfig } from '@/config/config';
import { DayPeriod } from '@/domain/date/Date.model';
import { DurationUnit } from '@/I18n/i18n';
import { getLocale, getUserLanguage } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { format, getHours, getMinutes, getYear, isAfter, isBefore, isValid, Locale, setHours, setMinutes, startOfDay } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import i18next, { t } from 'i18next';
import { TestConfig } from 'yup';

const config = getAppConfig();

export enum MONTHS {
    JANUARY = 'JANUARY',
    FEBRUARY = 'FEBRUARY',
    MARCH = 'MARCH',
    APRIL = 'APRIL',
    MAY = 'MAY',
    JUNE = 'JUNE',
    JULY = 'JULY',
    AUGUST = 'AUGUST',
    SEPTEMBER = 'SEPTEMBER',
    OCTOBER = 'OCTOBER',
    NOVEMBER = 'NOVEMBER',
    DECEMBER = 'DECEMBER',
}

export const getMonthTranslationKey = (month: MONTHS): string => {
    switch (month) {
        case MONTHS.JANUARY:
            return 'general.month.january';
        case MONTHS.FEBRUARY:
            return 'general.month.february';
        case MONTHS.MARCH:
            return 'general.month.march';
        case MONTHS.APRIL:
            return 'general.month.april';
        case MONTHS.MAY:
            return 'general.month.may';
        case MONTHS.JUNE:
            return 'general.month.june';
        case MONTHS.JULY:
            return 'general.month.july';
        case MONTHS.AUGUST:
            return 'general.month.august';
        case MONTHS.SEPTEMBER:
            return 'general.month.september';
        case MONTHS.OCTOBER:
            return 'general.month.october';
        case MONTHS.NOVEMBER:
            return 'general.month.november';
        case MONTHS.DECEMBER:
            return 'general.month.december';
        default:
            return '';
    }
};

export const MONTH_TRANSLATION_KEY = [
    'general.month.january',
    'general.month.february',
    'general.month.march',
    'general.month.april',
    'general.month.may',
    'general.month.june',
    'general.month.july',
    'general.month.august',
    'general.month.september',
    'general.month.october',
    'general.month.november',
    'general.month.december',
];

export const getHoursMinutes = (totalMinutes: number): string => {
    if (!totalMinutes || isNaN(totalMinutes)) {
        return '';
    }

    let isNegativeNumber = false;
    if (totalMinutes < 0) {
        isNegativeNumber = true;
    }
    const nonRoundedMinutes = isNegativeNumber ? (totalMinutes * -1) % 60 : totalMinutes % 60;
    const minutes = Math.round(nonRoundedMinutes * 100) / 100; // round to 2 decimal places to avoid floating point errors
    const hours = Math.floor(isNegativeNumber ? (totalMinutes * -1) / 60 : totalMinutes / 60);

    if (hours > 0 && minutes > 0) {
        return `${isNegativeNumber ? '-' : ''}${hours}h${padTo2Digits(minutes)}`;
    } else if (minutes === 0) {
        return `${isNegativeNumber ? '-' : ''}${hours}h`;
    } else {
        return `${isNegativeNumber ? '-' : ''}${padTo2Digits(minutes)}m`;
    }
};

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

/**
 * use to transform minutes to time format.
 * ex: 140min => '2h20'
 */
export function formatTime(timeInMinutes: number): string {
    const hours = Math.floor(timeInMinutes / 60);
    const minutes = timeInMinutes % 60;
    let hoursSS = '' + hours;
    let minutesSS = '' + minutes;
    if (hoursSS.length < 2) {
        hoursSS = '0' + hoursSS;
    }
    if (minutesSS.length < 2) {
        minutesSS = '0' + minutesSS;
    }
    return hoursSS + ':' + minutesSS;
}

export const getDateTimeFromDateAndTime = (date: Date, time: Date): Date => {
    const newDate = new Date(date);
    newDate.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
    return newDate;
};

export const isPastDay = (date: Date | LocalDate, dateToCompare: Date | LocalDate = getCurrentLocalDate()): boolean => {
    return isBefore(date, dateToCompare);
};

/**
 * @param time : ex: '09:30'
 */
export const getDateFromTimeFormat = (time: string | undefined): Date | null => {
    if (!time) {
        return getNull();
    }

    const [hoursStr, minutesStr] = time.split(':');
    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        return getNull();
    }

    return new Date(new Date().setHours(hours, minutes, 0, 0));
};

type FormatedHoursMinutes<T> = T extends undefined | null ? undefined : string;
export const getTimeFormatFromDate = <T extends Date | undefined | null>(date: T): FormatedHoursMinutes<T> => {
    if (!date || !isValid(date)) {
        return undefined as FormatedHoursMinutes<T>;
    }

    return format(date, 'HH:mm') as FormatedHoursMinutes<T>;
};

/**
 * @param time : ex: '09:30'
 */
export const getMinutesFromTimeFormat = (time: string): number | undefined => {
    if (!time) {
        return;
    }

    const [hoursStr, minutesStr] = time.split(':');
    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        return;
    }
    return hours * 60 + minutes;
};

export const convertDateToMinutes = (date: Date | undefined): number => {
    if (!date) {
        return 0;
    }
    return getHours(new Date(date)) * 60 + getMinutes(new Date(date));
};

export const updateTime = (value: string, startDate: Date): [time: string, date: Date] => {
    const newTime = value.replace(/-/g, ':');
    const time = newTime.substring(0, 5);
    const { hours, minutes } = splitHoursMinutes(newTime);
    const newDate = setHoursMinutes(startOfDay(startDate), hours, minutes);
    return [time, newDate];
};

export const setHoursMinutes = (date: Date, hour: number, minutes: number): Date => {
    date = setHours(date, hour);
    date = setMinutes(date, minutes);
    return date;
};

export type HoursMinutes = {
    hours: number;
    minutes: number;
};

export const getHoursMinutesFromMinutes = (totalMinutes: number): HoursMinutes => {
    const hours = Math.trunc(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
};

export const getMinutesFromHoursMinutes = (hoursMinutes: HoursMinutes): number => {
    return hoursMinutes.hours * 60 + hoursMinutes.minutes;
};
export const splitHoursMinutes = (hoursMinutes: string): HoursMinutes => {
    return { hours: parseInt(hoursMinutes?.split(':')[0]), minutes: parseInt(hoursMinutes?.split(':')[1]) };
};

export const clearSeconds = (time: string): string => time.split(':').slice(0, 2).join(':');

/**
 * Convert a string of the form hh:mm to the number of minutes
 * @param time a string of the form hh:mm
 * @returns number of minutes
 */
export const durationToNumber = (time: string | undefined): number => {
    if (!time) {
        return 0;
    }
    const [h, m] = time.split(':');
    return Number(h ?? 0) * 60 + Number(m ?? 0);
};

/**
 *  Verify if a date is after 1900. This prevents wrong date format like '25.12.2, 25.12.21 and 25.12.021'
 *  null or undefined are valid date
 */
export const isValidDate = (date: Nullable<Date> | Nullable<LocalDate>): date is Date => {
    if (!date) {
        return false;
    }
    if (typeof date === 'string') {
        return isInValidRange(toDate(date));
    }
    return isInValidRange(date);
};

export const MIN_DATE = new Date(1900, 0, 1);
export const MAX_DATE = new Date(2100, 11, 31);

export const isInValidRange = (currentDate: Nullable<Date>): currentDate is Date => {
    return !currentDate || (currentDate >= MIN_DATE && currentDate <= MAX_DATE);
};

export const isValidOrEmptyDate = (date: Nullable<Date> | Nullable<LocalDate>): date is Date => {
    return !date || isValidDate(date);
};

export const formatMinutesToHours = (durationInMinutes: number): string => {
    return i18next.t('duration.formatDuration', {
        duration: durationInMinutes / 60,
        unit: DurationUnit.HOURS,
    });
};

type ApiDate<T> = T extends undefined ? undefined : string;
export const formatInApiDate = <T extends Date | undefined>(date: T): ApiDate<T> => {
    if (!date || !isValid(date)) {
        return undefined as ApiDate<T>;
    }
    return format(date, config.API_REQUEST_DATE_FORMAT) as ApiDate<T>;
};

export type LocalDate = `${number}-${number}-${number}`;

export const formatToLocalDate = <T extends Date | undefined | null>(date: T): T extends undefined ? undefined : LocalDate => {
    //TODO: RP-5202 - we should refactor leaves and timesheet to have the correct date format (LocalDate) and not Date and after we can remove the 'new Date(date)'
    if (!date || !isValid(new Date(date))) {
        return undefined as T extends undefined ? undefined : LocalDate;
    }
    return format(date, config.API_REQUEST_DATE_FORMAT) as T extends undefined ? undefined : LocalDate;
};

export const formatRelativeDate = (
    endDate: Date | undefined,
    {
        dayPeriod = undefined,
        baseDate = new Date(),
        locale = getLocale(getUserLanguage()),
    }: {
        dayPeriod?: DayPeriod;
        baseDate?: Date;
        locale?: Locale;
    } = {},
): string => {
    if (!endDate || !isValid(endDate)) {
        return '';
    }

    const diffInDays = endDate.getDate() - baseDate.getDate();
    const isToday = diffInDays === 0;
    const isTomorrow = diffInDays === 1;
    const isThisWeek = diffInDays > 0 && diffInDays < 7;

    if (isToday) {
        return i18next.t('duration.today', { context: dayPeriod });
    }
    if (isTomorrow) {
        return i18next.t('duration.tomorrow', { context: dayPeriod });
    } else if (isThisWeek) {
        return format(endDate, config.DEFAULT_DAY_FORMAT, { locale });
    } else {
        return format(endDate, config.DEFAULT_DATE_MONTH_DAY_FORMAT, { locale });
    }
};

export const toDate = <T extends LocalDate | undefined | null>(date: T, timezone?: string): T extends undefined ? undefined : Date => {
    if (!date) {
        return undefined as T extends undefined ? undefined : Date;
    }
    return toZonedTime(new Date(date), timezone ?? config.DEFAULT_TIMEZONE) as T extends undefined ? undefined : Date;
};

export const formatInDefaultDate = (date?: Date | LocalDate): string => {
    if (!date) {
        return '';
    }

    if (typeof date === 'string') {
        return isValid(new Date(date)) ? format(toDate(date), config.DEFAULT_DATE_FORMAT) : '';
    }
    return isValid(date) ? format(date, config.DEFAULT_DATE_FORMAT) : '';
};

export const formatInDayMonthYear = (date?: Date): string => (date && isValid(date) ? format(date, config.DEFAULT_DATE_MONTH_DAY_FORMAT) : '');

export const formatInDefaultHours = (date?: Date): string => (date && isValid(date) ? format(date, config.DEFAULT_HOURS_FORMAT) : '');

export const formatInDefaultWeekName = (date?: Date): string => (date && isValid(date) ? format(date, config.DEFAULT_DATE_WEEK_NAME) : '');

export const formatInDefaultLiteral = (date: Date, locale: Locale): string =>
    date && isValid(date) ? format(new Date(date), getAppConfig().DEFAULT_LITERAL_DATE, { locale }) : '';

export const getAllYears = (start = 2021): number[] => {
    const end = getYear(new Date()) + 1;
    return Array(end - start + 1)
        .fill(1)
        .map((_, idx) => start + idx);
};
/*
    From minutes to days
    If it's a whole number, return the number of days
    If it's a decimal, return the number of days with 2 decimal places
 */
export const formatMinutesIntoDays = (minutes: number): number => {
    const days = minutes / 60 / 24;
    return Number.isInteger(days) ? days : Number(days.toFixed(2));
};

export enum DayOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export const DAY_OF_WEEK_SORT = Object.values(DayOfWeek);

export const convertToUTCStartOfDay = (date: Date): Date => fromZonedTime(startOfDay(date), 'utc');

export const getLocalDateTestConfig = (): TestConfig<Nullable<LocalDate>> => {
    return {
        message: t('general.validations.valid_date'),
        test: isValidOrEmptyDate,
    };
};

export const getLocalDateMinTestConfig = (minDate: LocalDate, message?: string): TestConfig<Nullable<LocalDate>> => {
    return {
        name: 'end_date_after_start_date',
        message: message ?? t('general.validations.min_date'),
        test: endDate => {
            if (!endDate) {
                return true;
            }
            return isAfter(toDate(endDate), toDate(minDate));
        },
    };
};

export const getLocalDateMaxTestConfig = (maxDate: LocalDate, message?: string): TestConfig<Nullable<LocalDate>> => {
    return {
        name: 'end_date_before_max_date',
        message: message ?? t('general.validations.max_date'),
        test: endDate => {
            if (!endDate) {
                return true;
            }
            return isAfter(toDate(maxDate), toDate(endDate));
        },
    };
};

export const getCurrentLocalDate = (): LocalDate => {
    return formatToLocalDate(new Date());
};
