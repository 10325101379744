import { employmentAPI } from '@/api/employment/Employment.api';
import { Calendar, CalendarDay, CalendarDayType } from '@/domain/calendar/Calendar.model';
import { EmploymentStatus } from './Employment.model';
import {
    Employment,
    EmploymentCreateReason,
    EmploymentCreationMutation,
    EmploymentUpdateMutation,
    EmploymentsSearchRequest,
    ModifyEmploymentTerminationMutation,
    TerminationType,
} from '@/domain/employment/Employment.model';
import { Palette } from '@mui/material';
import {
    FormatDurationOptions,
    compareAsc,
    compareDesc,
    differenceInDays,
    formatDuration,
    intervalToDuration,
    isAfter,
    isBefore,
    isSameDay,
    isToday,
    subDays,
} from 'date-fns';
import { t } from 'i18next';
import { LongLeave } from '@/domain/long-leave/LongLeave.model';
import { Employee } from '@/domain/employee/Employee.model';
import { getCurrentLocalDate, LocalDate, toDate } from '@/utils/datetime.util';

export const getEmployments = (employmentsSearchMutation: EmploymentsSearchRequest): Promise<Employment[]> => {
    return employmentAPI.searchEmployments(employmentsSearchMutation);
};

export const cancelTermination = (id: number): Promise<Employment> => {
    return employmentAPI.cancelTermination(id);
};

export const modifyEmploymentTermination = (id: number, mutation: ModifyEmploymentTerminationMutation): Promise<Employment> => {
    return employmentAPI.modifyEmploymentTermination(id, mutation);
};

export const createEmployment = (mutation: EmploymentCreationMutation): Promise<Employment[]> => {
    return employmentAPI.createEmployment(mutation);
};

export const updateEmployment = (id: number, mutation: EmploymentUpdateMutation): Promise<Employment[]> => {
    return employmentAPI.updateEmployment(id, mutation);
};

export const deleteEmployment = (id: number): Promise<void> => {
    return employmentAPI.deleteEmployment(id);
};

export const getDaysUntilProbationEnd = (probationEndDate: LocalDate): number => {
    const today = getCurrentLocalDate();
    const diffInDays = differenceInDays(probationEndDate, today);
    if (diffInDays === 0 && isSameDay(probationEndDate, today)) {
        return 0;
    }
    return diffInDays;
};

export const getEmploymentStatusDotColor = (palette: Palette, employmentStatus?: EmploymentStatus): string => {
    switch (employmentStatus) {
        case EmploymentStatus.HIRED:
            return palette.grey[700];
        case EmploymentStatus.EMPLOYED:
            return palette.success.main;
        case EmploymentStatus.ON_LONG_LEAVE:
            return palette.warning.main;
        case EmploymentStatus.TERMINATED:
            return palette.error.main;
        default:
            return '';
    }
};

export const getCurrentContract = (employments: Employment[]): Employment | undefined => {
    // group employment into contracts
    const contracts = getContracts(employments);

    const getCurrentEmployment = (employments: Employment[]) => {
        return employments.find(
            employment =>
                employment.startDate &&
                isBefore(new Date(employment.startDate), Date.now()) &&
                (!employment.endDate || isAfter(new Date(employment.endDate), Date.now())),
        );
    };

    const getPreviousEmployment = (employments: Employment[]) => {
        const pastEmployments = employments.filter(employment => employment.endDate && isBefore(new Date(employment.endDate), Date.now()));

        if (pastEmployments?.length === 0) {
            return undefined;
        }

        pastEmployments.sort((a, b) => compareAsc(a.startDate ?? new Date(0), b.startDate ?? new Date(0)));

        return pastEmployments[0];
    };

    const getNextEmployment = (employments: Employment[]) => {
        const futureEmployments = employments.filter(employment => employment.startDate && isAfter(new Date(employment.startDate), Date.now()));

        if (futureEmployments?.length === 0) {
            return undefined;
        }

        futureEmployments.sort((a, b) => compareDesc(a.startDate ?? new Date(0), b.startDate ?? new Date(0)));

        return futureEmployments[0];
    };

    return (
        getCurrentEmployment(contracts) || // current between start - end
        getNextEmployment(contracts) || // future employment
        getPreviousEmployment(contracts) // left company > last employment
    );
};

const getContracts = (employments: Employment[]): Employment[] => {
    const sortedEmployments = [...employments].sort((a, b) => compareAsc(a.startDate ?? new Date(0), b.startDate ?? new Date(0)));
    return sortedEmployments.reduce((acc, employment) => {
        switch (employment.employmentCreateReason) {
            case EmploymentCreateReason.NEW_EMPLOYEE:
            case EmploymentCreateReason.REHIRED:
                acc.push(employment);
                return acc;
            default:
                acc[acc.length - 1] = {
                    ...acc[acc.length - 1],
                    endDate: employment.endDate,
                    contractType:
                        employment.startDate && isBefore(new Date(employment.startDate), new Date())
                            ? employment.contractType
                            : acc[acc.length - 1].contractType,
                    terminationNoticeDate: employment.terminationNoticeDate,
                    terminationLastDayAtWork: employment.terminationLastDayAtWork,
                    terminationType: employment.terminationType,
                    terminationReason: employment.terminationReason,
                    terminationComment: employment.terminationComment,
                };

                return acc;
        }
    }, [] as Employment[]);
};

export const formatDurationForDates = (start: Date, end: Date): string => {
    const formatDurationOptions: FormatDurationOptions = {
        format: ['years', 'months', 'weeks', 'days'],
        delimiter: ', ',
    };
    return formatDuration(intervalToDuration({ start, end }), formatDurationOptions);
};

const getEmployedStatusDisplay = (contract: Employment, defaultStartDay: Date): string => {
    const endDate = toDate(contract.endDate);
    const startDate = toDate(contract.startDate);
    if (endDate) {
        if (isToday(endDate)) {
            return t('employee.employment.employment_status_full.future_former_employee_today');
        }
        return t('employee.employment.employment_status_full.future_former_employee', {
            duration: formatDurationForDates(defaultStartDay, endDate),
        });
    }

    return t('employee.employment.employment_status_full.employee', {
        duration: formatDurationForDates(startDate ? subDays(startDate, 1) : new Date(), new Date()),
    });
};

export const getCurrentEmploymentStatusLabelDisplay = (employmentStatus: EmploymentStatus, contract: Employment, longLeave?: LongLeave): string => {
    const defaultStartDay = subDays(new Date(), 1);
    const endDate = toDate(contract?.endDate) ?? new Date();

    const statusDisplay = {
        [EmploymentStatus.HIRED]: t('employee.employment.employment_status_full.hired', {
            duration: formatDurationForDates(defaultStartDay, toDate(contract?.startDate) ?? new Date()),
        }),
        [EmploymentStatus.EMPLOYED]: getEmployedStatusDisplay(contract, defaultStartDay),
        [EmploymentStatus.ON_LONG_LEAVE]: t('employee.employment.employment_status_full.on_long_leave', {
            duration: formatDurationForDates(defaultStartDay, toDate(longLeave?.endDate) ?? new Date()),
        }),
        [EmploymentStatus.TERMINATED]: isSameDay(endDate, defaultStartDay)
            ? t('employee.employment.employment_status_full.former_yesterday')
            : t('employee.employment.employment_status_full.former', {
                  duration: formatDurationForDates(endDate, new Date()),
              }),
    };

    return statusDisplay[employmentStatus] || '';
};
// TODO: use I18n context and remove this function
export const getTerminationTypeTranslationKey = (terminationType: TerminationType): string => {
    switch (terminationType) {
        case TerminationType.DISMISSED:
            return 'employee.employment.termination_type.dismissed';
        case TerminationType.END_OF_CONTRACT:
            return 'employee.employment.termination_type.end_of_contract';
        case TerminationType.RETIRED:
            return 'employee.employment.termination_type.retired';
        case TerminationType.OTHER:
            return 'employee.employment.termination_type.other';
        case TerminationType.MUTUAL:
            return 'employee.employment.termination_type.mutual';
        case TerminationType.RESIGNED:
            return 'employee.employment.termination_type.resigned';
        default:
            return '';
    }
};

export const formatEmploymentCostCenters = (employmentCostCenters: Employment['employmentCostCenters'] = []): string => {
    return employmentCostCenters.map(ecc => `${ecc.percentage}% ${ecc.costCenter.name}`).join(', ');
};

export const getCurrentPrincipalEmployment = (employee: Employee): Employment | undefined =>
    employee?.currentEmployments?.find(employment => employment.principal);

const getPublicHolidays = (calendar: Calendar, min?: Date, max?: Date): CalendarDay[] => {
    const calendarDays = calendar?.days || [];
    // return a copy because sort function sort the original array
    return [...calendarDays]
        .filter(day => {
            const isHoliday = day.dayType === CalendarDayType.HOLIDAY;
            const isInRange = (!min || isAfter(day.date, min)) && (!max || isBefore(day.date, max));
            return isHoliday && isInRange;
        })
        .sort((calendarDay1, calendarDay2) => compareAsc(calendarDay1.date, calendarDay2.date));
};

export const employmentService = {
    getPublicHolidays,
};
