import { CheckboxAutocompleteOption } from '@/Components/autocomplete-wrapper/CheckboxAutocompleteOption';
import { DialogContainer } from '@/Components/dialog-container/DialogContainer';
import { FieldDate } from '@/Components/form/field-date/FieldDate';
import { SelectResource } from '@/Components/select-ressource/SelectResource';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { TimeFieldWrapper } from '@/Components/time-field-wrapper/TimeFieldWrapper';
import i18next, { DurationUnit } from '@/I18n/i18n';
import { getAppConfig } from '@/config/config';
import { Employee } from '@/domain/employee/Employee.model';
import { canApproveRejectTimesheets } from '@/domain/permission/Permission.service';
import { TimesheetMode, TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import {
    EmployeeTimesheetMutation,
    Timesheet,
    TimesheetAction,
    TimesheetMutation,
    TimesheetsRequestStatus,
    TimesheetType,
} from '@/domain/timesheet/Timesheet.model';
import { createTimesheets, updatePendingTimesheet, updateTimesheet } from '@/domain/timesheet/Timesheet.service';
import { useGetAreas } from '@/hooks/area/Area.hook';
import { useGetEmployeeTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import { TIMESHEET_DEFAULT_END_TIME_HOUR, TIMESHEET_DEFAULT_START_TIME_HOUR } from '@/page/employee-timesheet/EmployeeTimesheet.util';
import { SelectOption } from '@/page/planning/scheduler-calendar/types';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import {
    formatInDefaultDate,
    formatMinutesToHours,
    formatToLocalDate,
    getTimeFormatFromDate,
    isValidDate,
    setHoursMinutes,
    splitHoursMinutes,
} from '@/utils/datetime.util';
import { defaultToNull, getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Autocomplete, Button, Divider, FormControlLabel, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import {
    add,
    addDays,
    addHours,
    addMinutes,
    differenceInMinutes,
    format as formatDate,
    intervalToDuration,
    isValid,
    startOfDay,
    subDays,
    subHours,
    subMinutes,
} from 'date-fns';
import { Add01Icon, Album02Icon, Delete02Icon } from 'hugeicons-react';
import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useFieldArray, UseFieldArrayInsert, UseFieldArrayRemove, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type TimesheetDialogProps = {
    open: boolean;
    onClose: () => void;
    mode: TimesheetAction;
    onSave: () => void;
    employee: Employee;
    defaultReferenceDate: Date;
    missingEntriesDates?: string[]; //used when we want to complete multiple missing timesheets at once
};

const MAX_TIME_PER_DAY_IN_MINUTES = 1080;
const MAX_TIME_BETWEEN_TIMESHEETS = 480;
const TIMESHEET_DEFAULT_DURATION = 60;
const SHOW_BREAK_DURATION_BETWEEN_TIMESHEETS_FROM_X_MINUTES = 1;

export type EmployeeTimesheetFormValues = {
    missingDates: string[];
    employeeId: number;
    referenceDate: Date;
    timesheets: TimesheetFormValues[];
};

type TimesheetFormValues = {
    id?: number | null; // for the update request if we do not send the id it will create a new timesheet, if we send the id we will update the existing timesheet
    startTime: Date;
    endTime: Date;
    comment?: string;
    areaId?: number | null;
    breakDuration: number;
};

function mapTimesheetDialogData(timesheets: Timesheet[]): (Omit<TimesheetFormValues, 'endTime'> & {
    endTime?: Date;
})[] {
    return timesheets.map(timesheet => {
        return {
            id: timesheet.id,
            startTime: new Date(timesheet.startAt),
            //endTime can be null in case of a missing clock out
            endTime: timesheet.endAt ? new Date(timesheet.endAt) : undefined,
            comment: timesheet.comment,
            areaId: timesheet?.area?.id,
            breakDuration: timesheet.breakDuration,
        };
    });
}

function shouldCheckForceBreaks(
    timesheets: TimesheetFormValues[],
    forceBreakAfterXHours: number | undefined,
    forceBreakDurationInMinutes: number | undefined,
    forceBreakToBeTakenFrom: string | undefined,
    forceBreakToBeTakenTo: string | undefined,
): boolean {
    if (!timesheets || !forceBreakAfterXHours || !forceBreakDurationInMinutes) {
        return false;
    }
    //if we have a force break to be taken at a certain time, we dont do the validation in the FE we do it in the BE for now
    if (forceBreakToBeTakenFrom && forceBreakToBeTakenTo) {
        return false;
    }

    const areTimesheetsInvalid = timesheets.some(timesheet => {
        return !isValid(timesheet.endTime);
    });

    return !areTimesheetsInvalid;
}

function calculateBreakDuration(timesheets: TimesheetFormValues[]): number {
    return timesheets.reduce((totalInterval, currentTimesheet, index, array) => {
        // Add break duration of current timesheet
        totalInterval += currentTimesheet.breakDuration ?? 0;
        // Calculate interval to previous timesheet (if it exists)
        if (index > 0) {
            const previousTimesheet = array[index - 1];
            const intervalBetweenTimesheets = differenceInMinutes(new Date(currentTimesheet.startTime), new Date(previousTimesheet.endTime));
            totalInterval += intervalBetweenTimesheets;
        }

        return totalInterval;
    }, 0);
}

export const TimesheetDialog: FC<TimesheetDialogProps> = ({ defaultReferenceDate, open, onClose, onSave, employee, mode, missingEntriesDates }) => {
    const { t } = useTranslation();

    const [referenceDate, setReferenceDate] = useState<Date>(defaultReferenceDate);
    const refTimesheetErrorMessage = useRef<HTMLDivElement>(getNull());
    const timesheetSetting = employee.currentWorkingPattern?.timesheetSetting;
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const {
        data: dailyTimesheetReports = [],
        isLoading,
        isError,
        error,
    } = useGetEmployeeTimesheets({
        employeeIds: [employee.id],
        startDate: formatToLocalDate(referenceDate),
        endDate: formatToLocalDate(referenceDate),
    });

    const originalTimesheets = useMemo(() => {
        return dailyTimesheetReports?.[0]?.dayTimesheets[0]?.timesheets.filter(
            timesheet =>
                timesheet.type === TimesheetType.TIMESHEET ||
                timesheet.type === TimesheetType.SHIFT_TIMESHEET ||
                timesheet.type === TimesheetType.MISSING ||
                timesheet.type === TimesheetType.AUTOFILL,
        );
    }, [dailyTimesheetReports]);

    const createDefaultTimesheetFormValues = (date: Date): TimesheetFormValues[] => {
        const startTime = date ? new Date(date) : new Date();
        startTime.setHours(TIMESHEET_DEFAULT_START_TIME_HOUR, 0, 0, 0);
        const endTime = date ? new Date(date) : new Date();
        endTime.setHours(TIMESHEET_DEFAULT_END_TIME_HOUR, 0, 0, 0);
        return [
            {
                startTime: startTime,
                endTime: endTime,
                comment: '',
                areaId: undefined,
                breakDuration: 0,
            },
        ];
    };

    const isSimplifiedMode = timesheetSetting?.timesheetMode === TimesheetMode.SIMPLIFIED;

    const timesheetSchema: yup.ObjectSchema<TimesheetFormValues> = yup.object().shape({
        id: yup.number().nullable(),
        startTime: yup
            .date()
            .required()
            .test((startDate, meta) => {
                if (!isSimplifiedMode) {
                    //https://github.com/jquense/yup/issues/204
                    //how to get the index from
                    const timesheetsWatch = watch('timesheets');
                    const index = parseInt(meta.path.split('[')[1].split(']')[0]);

                    if (index === 0) {
                        return true;
                    }

                    const previousArrayEndDate: Date = timesheetsWatch[index - 1]?.endTime;

                    const endDate = meta.parent.endTime;
                    return !isStartTimeFieldInError(startDate, previousArrayEndDate, endDate);
                }
                return true;
            }),
        endTime: yup
            .date()
            .required()
            .test((endDate, meta) => {
                if (isSimplifiedMode) {
                    return isValidDurationInSimplifiedMode(meta.parent.startTime, endDate);
                }
                return !isEndTimeFieldInError(meta.parent.startTime, endDate);
            }),
        breakDuration: yup
            .number()
            .required()
            .test((breakDuration, meta) => {
                const endDate = meta.parent.endTime;
                const startDate = meta.parent.startTime;
                return !isBreakFieldInError(breakDuration, startDate, endDate);
            }),
        comment: yup.string().when({
            is: () => !timesheetSetting?.mandatoryComment,
            then: schema => schema.nullable(),
            otherwise: schema => schema.required(),
        }),
        areaId: yup.number().nullable(),
    });

    const schema = yup.object().shape({
        missingDates: yup.array().required().default([]),
        employeeId: yup.number().required(),
        referenceDate: yup.date().required(),
        timesheets: yup
            .array()
            .required()
            .of(timesheetSchema)
            .test({
                message: t('timesheets.error_missing_end_date'),
                test: timesheets => {
                    return timesheets?.every(timesheet => {
                        return isValid(timesheet.endTime);
                    });
                },
            })
            .test({
                message: t('timesheets.error_shifts_too_long', { maxHours: formatMinutesToHours(MAX_TIME_PER_DAY_IN_MINUTES) }),
                test: timesheets => {
                    if (!timesheets) {
                        return false;
                    }
                    const totalTime = timesheets.reduce((accumulator, timesheet) => {
                        return accumulator + differenceInMinutes(new Date(timesheet.endTime), new Date(timesheet.startTime)) - (timesheet.breakDuration ?? 0);
                    }, 0);
                    return totalTime <= MAX_TIME_PER_DAY_IN_MINUTES;
                },
            })
            .test({
                message: t('timesheets.error_missing_break', {
                    minutes: timesheetSetting?.forceSmallBreakDurationInMinutes,
                    hours: formatMinutesToHours((timesheetSetting?.forceSmallBreakAfterXHours ?? 0) * 60),
                }),
                test: timesheets => {
                    if (
                        !shouldCheckForceBreaks(
                            timesheets as TimesheetFormValues[],
                            timesheetSetting?.forceSmallBreakAfterXHours,
                            timesheetSetting?.forceSmallBreakDurationInMinutes,
                            timesheetSetting?.forceBreakToBeTakenTo,
                            timesheetSetting?.forceBreakToBeTakenFrom,
                        )
                    ) {
                        return true;
                    }

                    const totalTimeMinutes =
                        timesheets?.reduce((accumulator, timesheet) => {
                            return (
                                accumulator + differenceInMinutes(new Date(timesheet.endTime), new Date(timesheet.startTime)) - (timesheet.breakDuration ?? 0)
                            );
                        }, 0) ?? 0;

                    const breakDurationMinutes = calculateBreakDuration(timesheets as TimesheetFormValues[]);

                    if (
                        totalTimeMinutes > (timesheetSetting?.forceSmallBreakAfterXHours ?? 0) * 60 &&
                        breakDurationMinutes < (timesheetSetting?.forceSmallBreakDurationInMinutes ?? 0)
                    ) {
                        //in case the duration is bigger than the big break, we dont want to display the message for small break
                        return !!timesheetSetting?.forceBigBreakAfterXHours && totalTimeMinutes > timesheetSetting?.forceBigBreakAfterXHours * 60;
                    }

                    return true;
                },
            })
            .test({
                message: t('timesheets.error_missing_break', {
                    minutes: timesheetSetting?.forceBigBreakDurationInMinutes,
                    hours: formatMinutesToHours((timesheetSetting?.forceBigBreakAfterXHours ?? 0) * 60),
                }),
                test: timesheets => {
                    if (
                        !shouldCheckForceBreaks(
                            timesheets as TimesheetFormValues[],
                            timesheetSetting?.forceBigBreakAfterXHours,
                            timesheetSetting?.forceBigBreakDurationInMinutes,
                            timesheetSetting?.forceBreakToBeTakenTo,
                            timesheetSetting?.forceBreakToBeTakenFrom,
                        )
                    ) {
                        return true;
                    }

                    const totalTimeMinutes =
                        timesheets?.reduce((accumulator, timesheet) => {
                            return (
                                accumulator + differenceInMinutes(new Date(timesheet.endTime), new Date(timesheet.startTime)) - (timesheet.breakDuration ?? 0)
                            );
                        }, 0) ?? 0;

                    const breakDurationMinutes = calculateBreakDuration(timesheets as TimesheetFormValues[]);

                    return !(
                        totalTimeMinutes > (timesheetSetting?.forceBigBreakAfterXHours ?? 0) * 60 &&
                        breakDurationMinutes < (timesheetSetting?.forceBigBreakDurationInMinutes ?? 0)
                    );
                },
            }),
    });

    const mapTimesheetDefaultValues = useMemo(() => {
        if (!originalTimesheets) {
            return createDefaultTimesheetFormValues(referenceDate);
        }
        return originalTimesheets?.filter(
            timesheet =>
                timesheet.type === TimesheetType.TIMESHEET ||
                timesheet.type === TimesheetType.SHIFT_TIMESHEET ||
                timesheet.type === TimesheetType.MISSING ||
                timesheet.type === TimesheetType.AUTOFILL,
        )?.length > 0
            ? mapTimesheetDialogData(originalTimesheets)
            : createDefaultTimesheetFormValues(referenceDate);
    }, [originalTimesheets, referenceDate]);

    const formMethods = useForm<EmployeeTimesheetFormValues>({
        //TODO dont use the 'all' and use validate start end and break
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            missingDates: missingEntriesDates,
            employeeId: employee.id,
            referenceDate: new Date(referenceDate),
            timesheets: mapTimesheetDefaultValues,
        },
    });

    const {
        formState: { errors },
        control,
        handleSubmit,
        watch,
        reset,
    } = formMethods;

    const {
        fields: timesheetsFields,
        append: addTimesheet,
        insert: insertTimesheet,
        remove: removeTimesheet,
    } = useFieldArray({
        control,
        name: 'timesheets',
    });

    const timesheets = watch('timesheets');

    useEffect(() => {
        reset({
            missingDates: missingEntriesDates,
            employeeId: employee.id,
            referenceDate: new Date(referenceDate),
            timesheets: mapTimesheetDefaultValues,
        });
    }, [employee.id, mapTimesheetDefaultValues, missingEntriesDates, originalTimesheets, referenceDate, reset]);

    const { data: areas } = useGetAreas({
        locationIds: employee.currentEmployments.flatMap(employment => employment.location.id),
    });

    const getAreaResourceOptions = () => {
        const allAreas = areas?.map(area => {
            return {
                id: area.id,
                name: area.name,
            };
        });
        return allAreas ?? [];
    };

    const hasApprovedTimesheets = () => {
        //To validate if we have approved timesheets in the day to check for deletion
        return (
            originalTimesheets &&
            originalTimesheets?.length > 0 &&
            originalTimesheets.some(timesheet => {
                return timesheet.status === TimesheetsRequestStatus.APPROVED;
            })
        );
    };

    const handleTimesheetEditing = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        if (hasApprovedTimesheets()) {
            await handleUpdateTimesheets(employeeTimesheetMutation);
        } else {
            await handleUpdatePendingTimesheets(employeeTimesheetMutation);
        }
    };

    const handleUpdateTimesheets = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        try {
            await updateTimesheet(employeeTimesheetMutation, { withApproval: canApproveRejectTimesheets(policies, employeeTimesheetMutation.employeeId) });
            onSave();
        } catch (error) {
            handleErrorOnSave(error);
        }
    };

    const handleUpdatePendingTimesheets = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        try {
            await updatePendingTimesheet(employeeTimesheetMutation, {
                withApproval: canApproveRejectTimesheets(policies, employeeTimesheetMutation.employeeId),
            });
            onSave();
        } catch (error) {
            handleErrorOnSave(error);
        }
    };

    const handleTimesheetCreation = async (employeeTimesheetMutation: EmployeeTimesheetMutation) => {
        try {
            await createTimesheets(employeeTimesheetMutation, { withApproval: canApproveRejectTimesheets(policies, employeeTimesheetMutation.employeeId) });
            onSave();
        } catch (error) {
            handleErrorOnSave(error);
        }
    };

    const handleErrorOnSave = (error: unknown) => {
        if (axios.isAxiosError(error) && error.response?.status === 409) {
            showSnackbar(t('timesheets.error_overlapping_timesheets'), 'error');
        } else {
            handleError(error);
        }
    };

    const saveTimesheetsForMissingTimesheets = (formValues: EmployeeTimesheetFormValues) => {
        const missingDatesFromForm = watch('missingDates');
        if (!missingEntriesDates || !missingDatesFromForm) {
            return;
        }

        //search with the dates!
        const missingDates = missingEntriesDates.filter(date => {
            return missingDatesFromForm.some(missingDate => {
                return missingDate === date;
            });
        });

        missingDates.forEach(missingDate => {
            const missingEmployeeTimesheet: EmployeeTimesheetMutation = {
                employeeId: employee.id,
                referenceDate: new Date(missingDate),
                timesheets: buildTimesheetsBasedOnNewDate(formValues.timesheets, new Date(missingDate)),
            };

            handleTimesheetEditing(missingEmployeeTimesheet).catch(handleError);
        });
    };

    const buildTimesheetsBasedOnNewDate = (timesheetsFormValues: TimesheetFormValues[], newDate: Date): TimesheetMutation[] => {
        const date = new Date(referenceDate);
        const newTimesheets = timesheetsFormValues.map(timesheet => {
            const timesheetStartDate = new Date(timesheet.startTime);
            const timesheetEndDate = new Date(timesheet.endTime);
            //in case the days are different, it means we are in the next day and we need to add 1 day
            const startDate = updateDateTimeFromOtherDate(timesheetStartDate, new Date(newDate));
            const newStartDate = timesheetStartDate.getDay() === date.getDay() ? startDate : addDays(startDate, 1);
            const endDate = updateDateTimeFromOtherDate(timesheetEndDate, new Date(newDate));
            const newEndDate = timesheetEndDate.getDay() === date.getDay() ? endDate : addDays(endDate, 1);
            return {
                ...timesheet,
                id: timesheet.id ?? undefined,
                startTime: newStartDate,
                endTime: newEndDate,
                areaId: timesheet.areaId ?? undefined,
            };
        });
        return newTimesheets || [];
    };

    const updateDateTimeFromOtherDate = (oldDate: Date, newDate: Date): Date => {
        newDate.setHours(oldDate.getHours());
        newDate.setMinutes(oldDate.getMinutes());
        return newDate;
    };

    const onSaveTimesheet = (formValues: EmployeeTimesheetFormValues) => {
        if (mode === TimesheetAction.MISSING_TIMESHEET) {
            saveTimesheetsForMissingTimesheets(formValues);
        } else {
            createOrEditTimesheets(formValues);
        }
    };

    const createOrEditTimesheets = (formValues: EmployeeTimesheetFormValues) => {
        const payload: EmployeeTimesheetMutation = {
            ...formValues,
            timesheets: formValues.timesheets.map(timesheet => {
                return {
                    ...timesheet,
                    id: timesheet.id ?? undefined,
                    areaId: timesheet.areaId ?? undefined,
                };
            }),
        };

        const orderedEmployeeTimesheetMutation = isSimplifiedMode ? reorderTimesheet(payload) : payload;

        const isForceCreation =
            (mode === TimesheetAction.CREATE && !hasExistingTimesheetsInCurrentDate) ||
            mode === TimesheetAction.SHIFT_TIMESHEET ||
            mode === TimesheetAction.AUTO_FILL_TIMESHEET;

        if (isForceCreation) {
            handleTimesheetCreation(orderedEmployeeTimesheetMutation).catch(handleError);
        } else {
            handleTimesheetEditing(orderedEmployeeTimesheetMutation).catch(handleError);
        }
    };

    /**
     * rearranges the timesheets in the correct order when in simplified mode,
     * in order to send consecutive timesheets to the backend. For example, 00:00 => 02:00, 02:00 => 04:00, 04:00 => 08:00.
     */
    const reorderTimesheet = (timesheetsToReorder: EmployeeTimesheetMutation) => {
        const updatedTimesheet = timesheetsToReorder?.timesheets?.reduce((accumulator, timesheet, index) => {
            let newStartDate = new Date(referenceDate);
            if (index === 0) {
                newStartDate.setHours(TIMESHEET_DEFAULT_START_TIME_HOUR, 0, 0);
            } else {
                newStartDate = accumulator[index - 1]?.endTime;
            }
            const newEndDate = add(
                newStartDate,
                intervalToDuration({
                    start: new Date(timesheet?.startTime),
                    end: new Date(timesheet?.endTime),
                }),
            );

            const updatedTimesheet = {
                ...timesheet,
                startTime: newStartDate,
                endTime: newEndDate,
            };
            accumulator.push(updatedTimesheet);

            return accumulator;
        }, [] as TimesheetMutation[]);
        return { ...timesheetsToReorder, timesheets: updatedTimesheet };
    };

    const addNewTimesheet = () => {
        const currentTimesheet = timesheets[timesheets.length - 1];
        const newStartTime = currentTimesheet.endTime;
        const newEndTime = addMinutes(new Date(newStartTime), TIMESHEET_DEFAULT_DURATION);

        const newTimesheetFormValues: TimesheetFormValues = {
            startTime: newStartTime,
            endTime: newEndTime,
            breakDuration: 0,
            comment: '',
        };

        addTimesheet(newTimesheetFormValues);
    };

    const hasAtLeastOneTimesheetType = (timesheets: Timesheet[]) => {
        return timesheets.some(timesheet => {
            return timesheet.type === TimesheetType.TIMESHEET;
        });
    };

    const hasExistingTimesheetsInCurrentDate = !!originalTimesheets && !!hasAtLeastOneTimesheetType(originalTimesheets);

    const isStartTimeFieldInError = (newStartDate: Date, previousArrayEndDate: Date, endDate: Date): boolean => {
        if (newStartDate && previousArrayEndDate) {
            //if the previous end time is greater than the current start time
            const isStartDateBeforeEndDate = differenceInMinutes(new Date(newStartDate), new Date(previousArrayEndDate)) < 0;
            if (isStartDateBeforeEndDate) {
                return true;
            }

            const isSpaceBetweenTimesheetsBiggerThanAllowedTime =
                differenceInMinutes(new Date(previousArrayEndDate), new Date(newStartDate)) > MAX_TIME_BETWEEN_TIMESHEETS;
            if (isSpaceBetweenTimesheetsBiggerThanAllowedTime) {
                return true;
            }
        }

        return differenceInMinutes(new Date(endDate), new Date(newStartDate)) >= MAX_TIME_PER_DAY_IN_MINUTES;
    };

    const isEndTimeFieldInError = (startTime: Date, endTime: Date) => {
        if (!endTime) {
            return true;
        }

        return differenceInMinutes(new Date(endTime), new Date(startTime)) < 0;
    };

    const isBreakFieldInError = (breakDuration: number, startTime: Date, endTime: Date) => {
        const duration = differenceInMinutes(new Date(endTime), new Date(startTime));
        return breakDuration > duration && !isEndTimeFieldInError(startTime, endTime);
    };

    const isValidDurationInSimplifiedMode = (startTime: Date, endTime: Date) => {
        return differenceInMinutes(new Date(endTime), new Date(startTime)) < MAX_TIME_PER_DAY_IN_MINUTES;
    };

    const getDialogContainerTitle = (): string => {
        if (mode === TimesheetAction.MISSING_TIMESHEET) {
            return t('timesheets.complete_missing_entries');
        }
        if (hasExistingTimesheetsInCurrentDate) {
            return t('timesheets.update_timesheets');
        }
        return t('timesheets.create_timesheets');
    };

    const isAddTimesheetButtonDisabled = () => {
        return watch('timesheets').some(timesheet => {
            return !timesheet.startTime || !timesheet.endTime || !isValidDate(timesheet.startTime) || !isValidDate(timesheet.endTime);
        });
    };

    const elementRef = refTimesheetErrorMessage?.current;

    useEffect(() => {
        if (!!errors?.timesheets?.message && elementRef) {
            elementRef?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [elementRef, errors]);

    const displayNextDayDate = (timesheetIndex: number): boolean => {
        const previousTimesheetIndex = timesheetIndex - 1;
        const previousPreviousTimesheetIndex = timesheetIndex - 2;

        const isFirstAppearanceNextDay =
            previousTimesheetIndex >= 0 && timesheets[timesheetIndex]?.startTime?.getDay() !== timesheets[previousTimesheetIndex]?.startTime?.getDay();

        const isAppearanceDisplayInOtherTimesheet =
            previousPreviousTimesheetIndex >= 0 &&
            timesheets[previousTimesheetIndex]?.startTime?.getDay() !== timesheets[previousPreviousTimesheetIndex]?.startTime?.getDay();

        return isFirstAppearanceNextDay && !isAppearanceDisplayInOtherTimesheet;
    };

    return (
        <DialogContainer open={open} onClose={onClose} onSave={() => handleSubmit(onSaveTimesheet, console.error)()} title={getDialogContainerTitle()}>
            <FormProvider {...formMethods}>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    <Stack alignItems={'start'} gap={2}>
                        {mode === TimesheetAction.MISSING_TIMESHEET && missingEntriesDates ? (
                            <Controller
                                name={`missingDates`}
                                control={control}
                                render={({ field: { value, onChange, ...restField } }) => (
                                    <Autocomplete
                                        onChange={(_, value) => {
                                            onChange(value);
                                        }}
                                        value={value}
                                        multiple
                                        options={missingEntriesDates}
                                        fullWidth
                                        renderInput={params => <TextField {...params} />}
                                        getOptionLabel={option => formatInDefaultDate(new Date(option))}
                                        renderOption={(props, option, { selected }) => (
                                            <CheckboxAutocompleteOption {...props} selected={selected} label={formatInDefaultDate(new Date(option))} />
                                        )}
                                        {...restField}
                                    />
                                )}
                            />
                        ) : (
                            <Stack spacing={2} alignItems={'start'} sx={{ width: '100%' }}>
                                <FieldDate
                                    name='referenceDate'
                                    disabled={mode === TimesheetAction.EDIT || mode === TimesheetAction.SHIFT_TIMESHEET}
                                    onChange={newDate => {
                                        if (newDate) {
                                            // when the date changes we need to fetch the new data to update the timesheets
                                            setReferenceDate(newDate);
                                        }
                                    }}
                                    mobileOnly
                                />
                            </Stack>
                        )}

                        {timesheetsFields?.map((timesheetCreationFormat, timesheetIndex) => {
                            return (
                                <Fragment key={timesheetCreationFormat.id}>
                                    {displayNextDayDate(timesheetIndex) ? (
                                        <DivideTimesheetNewDay startTime={timesheetCreationFormat.startTime} />
                                    ) : (
                                        <DivideTimesheet timesheets={timesheets} timesheetIndex={timesheetIndex} addTimesheet={insertTimesheet} />
                                    )}
                                    <TimesheetDetails
                                        timesheetSetting={timesheetSetting}
                                        timesheetIndex={timesheetIndex}
                                        areaOptions={getAreaResourceOptions()}
                                        removeTimesheet={removeTimesheet}
                                        isError={isError}
                                    />
                                </Fragment>
                            );
                        })}
                        <Button
                            onClick={() => {
                                addNewTimesheet();
                            }}
                            disabled={isAddTimesheetButtonDisabled()}
                            startIcon={<Add01Icon />}
                            color='primary'
                            variant={'text'}
                            fullWidth
                        >
                            {t('timesheets.add_another_timeslot')}
                        </Button>

                        <Stack ref={refTimesheetErrorMessage} sx={{ width: '100%' }}>
                            {(!!errors?.timesheets?.message || !!errors?.timesheets?.root?.message) && (
                                <Alert severity='error' elevation={0} sx={{ alignItems: 'center' }}>
                                    <Typography variant='body2'>{errors?.timesheets?.message ?? errors?.timesheets?.root?.message}</Typography>
                                </Alert>
                            )}
                        </Stack>
                    </Stack>
                </StateHandler>
            </FormProvider>
        </DialogContainer>
    );
};

type DivideTimesheetNewDayProps = {
    startTime: Date;
};

const DivideTimesheetNewDay: FC<DivideTimesheetNewDayProps> = ({ startTime }) => {
    const theme = useTheme();
    const displayText = isValidDate(startTime)
        ? i18next.t('timesheets.next_day', {
              requestDate: formatDate(startTime, getAppConfig().DEFAULT_DATE_FORMAT),
          })
        : '';

    return (
        <Stack alignItems={'center'} sx={{ width: '100%' }} spacing={1} textAlign={'center'} paddingBottom={2}>
            <Divider flexItem>
                <Stack direction={'row'} gap={1}>
                    <Typography variant='body1'>{displayText}</Typography>
                    <Album02Icon color={theme.palette.warning.main} />
                </Stack>
            </Divider>
        </Stack>
    );
};

type DivideTimesheetProps = {
    timesheetIndex: number;
    timesheets: TimesheetFormValues[];
    addTimesheet: UseFieldArrayInsert<EmployeeTimesheetFormValues>;
};

const DivideTimesheet: FC<DivideTimesheetProps> = ({ timesheets, timesheetIndex, addTimesheet }) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const getInformation = (timesheetIndex: number) => {
        if (timesheetIndex === 0) {
            const endTime = timesheets[timesheetIndex]?.startTime;

            if (!isValidDate(endTime)) {
                return;
            }

            const startTime = subHours(endTime, 1);

            return { displayText: t('timesheets.start_of_day'), startTime: startTime, endTime: endTime };
        }

        const previousPosition = timesheetIndex - 1;
        if (previousPosition < 0) {
            return;
        }

        const startTime = timesheets[timesheetIndex]?.startTime;
        const previousEndTime = timesheets[previousPosition]?.endTime;

        const showBreakDuration =
            isValidDate(startTime) &&
            isValidDate(previousEndTime) &&
            differenceInMinutes(startTime, previousEndTime) > SHOW_BREAK_DURATION_BETWEEN_TIMESHEETS_FROM_X_MINUTES;

        if (!showBreakDuration) {
            return;
        }

        const differenceBetweenTimesheetsInMinutes = differenceInMinutes(startTime, previousEndTime);

        const formatDuration = i18next.t('duration.formatDuration', {
            duration: differenceBetweenTimesheetsInMinutes / 60,
            unit: DurationUnit.HOURS,
        });

        return {
            displayText: t('timesheets.break_duration', { breakDuration: formatDuration }),
            startTime: previousEndTime,
            endTime: startTime,
        };
    };

    const information = getInformation(timesheetIndex);

    if (!information) {
        return;
    }

    const addNewTimesheet = () => {
        const newTimesheetFormValues: TimesheetFormValues = {
            startTime: information.startTime,
            endTime: information.endTime,
            breakDuration: 0,
            comment: '',
            areaId: undefined,
        };

        addTimesheet(timesheetIndex, newTimesheetFormValues);
    };

    return (
        <Button
            startIcon={<Add01Icon />}
            onClick={addNewTimesheet}
            variant='text'
            fullWidth
            sx={{
                '&:not(:hover)': {
                    '& .MuiButton-startIcon': {
                        display: 'none',
                    },
                    color: palette.text.primary,
                },
                '&:hover': {
                    '& .MuiDivider-root': {
                        display: 'none',
                    },
                    '&::after': {
                        content: `'${t('timesheets.add_another_timeslot')}'`,
                    },
                },
            }}
        >
            <Divider sx={{ width: '100%' }}>{information.displayText}</Divider>
        </Button>
    );
};

type TimesheetDetailsProps = {
    timesheetSetting?: TimesheetSetting;
    timesheetIndex: number;
    areaOptions: SelectOption[];
    removeTimesheet: UseFieldArrayRemove;
    isError: boolean;
};

const TimesheetDetails: FC<TimesheetDetailsProps> = ({ timesheetSetting, timesheetIndex, areaOptions, removeTimesheet, isError }) => {
    const { control, watch } = useFormContext<EmployeeTimesheetFormValues>();
    const { t } = useTranslation();
    const timesheets = watch('timesheets');

    return (
        <Stack gap={0.5} sx={{ width: '100%' }}>
            <Stack direction={'row'} alignItems={'flex-end'} sx={{ width: '100%' }} flexGrow={1}>
                {timesheetSetting?.timesheetMode === TimesheetMode.NORMAL ? (
                    <NormalWorkingTimePicker timesheetSetting={timesheetSetting} index={timesheetIndex} />
                ) : (
                    <SimplifiedWorkingTimepicker index={timesheetIndex} />
                )}
                {timesheets?.length > 1 && (
                    <IconButton onClick={() => removeTimesheet(timesheetIndex)} sx={{ color: 'text.primary' }}>
                        <Delete02Icon />
                    </IconButton>
                )}
            </Stack>
            {!!areaOptions?.length && (
                <Controller
                    name={`timesheets.${timesheetIndex}.areaId`}
                    control={control}
                    render={({ field: { onChange, value, ...restField } }) => (
                        <FormControlLabel
                            label={''}
                            labelPlacement='top'
                            sx={{ width: '100%' }}
                            control={
                                <Autocomplete
                                    {...restField}
                                    id='area-select'
                                    value={areaOptions.find(option => (option.id as number) === value) ?? getNull()}
                                    options={areaOptions ?? []}
                                    disabled={false}
                                    disableClearable={false}
                                    disableListWrap={true}
                                    fullWidth
                                    getOptionLabel={option => option.name}
                                    onChange={(_, newValue) => {
                                        onChange(defaultToNull(newValue?.id));
                                    }}
                                    renderInput={params => <TextField error={isError} {...params} placeholder={t('planning.area')} />}
                                    isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue?.id}
                                />
                            }
                        />
                    )}
                />
            )}
            <Controller
                name={`timesheets.${timesheetIndex}.comment`}
                control={control}
                render={({ field, fieldState }) => (
                    <TextField
                        {...field}
                        fullWidth
                        error={!!fieldState.error}
                        helperText={fieldState.error ? t('timesheets.mandatory_comment') : ''}
                        InputProps={{ multiline: true, minRows: 1 }}
                        placeholder={t('general.comment')}
                    />
                )}
            />
        </Stack>
    );
};

type NormalWorkingTimePickerProps = {
    index: number;
    timesheetSetting: TimesheetSetting;
};

const NormalWorkingTimePicker: FC<NormalWorkingTimePickerProps> = ({ index, timesheetSetting }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const AVAILABLE_BREAK_OPTIONS: number[] = Array.from(Array(121).keys());

    const { control, watch, setValue } = useFormContext<EmployeeTimesheetFormValues>();
    const timesheets = watch('timesheets');
    const referenceDate = watch('referenceDate');
    const currentTimesheet = timesheets[index];

    if (!timesheetSetting.breakDisplayEnabled && currentTimesheet.breakDuration !== 0) {
        setValue(`timesheets.${index}.breakDuration`, 0);
    }

    const getBreaks = (): SelectOption[] => {
        return AVAILABLE_BREAK_OPTIONS.map((pause: number) => {
            return {
                id: pause,
                name: pause !== 0 ? `${pause} ${t('general.minutes')}` : t('general.none'),
            };
        });
    };

    const getTotalWorkingTime = () => {
        const diffInMinutes = currentTimesheet.endTime
            ? differenceInMinutes(currentTimesheet.endTime, addMinutes(currentTimesheet.startTime, currentTimesheet.breakDuration))
            : 0;
        return diffInMinutes >= 0 ? formatMinutesToHours(diffInMinutes) : formatMinutesToHours(0);
    };

    const getStartDate = ({ index, startDate, hours, minutes }: { index: number; startDate: Date; hours: number; minutes: number }): Date => {
        const endDate = watch(`timesheets.${index}.endTime`);

        if (differenceInMinutes(startDate, endDate) > 0) {
            //startDate > endDate
            setValue(`timesheets.${index}.endTime`, addDays(new Date(endDate), 1));
        }

        if (index >= 1) {
            //when we have more than 1 timesheets we need to check the previous end time to check if we are on a new day
            const previousEndDate = timesheets[index - 1]?.endTime;
            const isStartDateOnSameDay = differenceInMinutes(new Date(startDate), new Date(previousEndDate)) >= 0;
            if (isStartDateOnSameDay) {
                return startDate;
            } else {
                return setHoursMinutes(startOfDay(addDays(new Date(referenceDate), 1)), hours, minutes);
            }
        }

        const dayInMinutes = 1440;
        if (endDate && isValidDate(endDate) && differenceInMinutes(new Date(endDate), new Date(startDate)) > dayInMinutes) {
            setValue(`timesheets.${index}.endTime`, subDays(new Date(endDate), 1));
        }
        return startDate;
    };
    const getNewStartDate = (oldStartDate: Date) => {
        const startTime = getTimeFormatFromDate(oldStartDate);
        const { hours, minutes } = splitHoursMinutes(startTime);
        const startDate = setHoursMinutes(startOfDay(new Date(referenceDate)), hours, minutes);

        return getStartDate({ index, startDate, hours, minutes });
    };

    const getEndTime = ({ startDate, endDate, hours, minutes }: { startDate: Date; endDate: Date; hours: number; minutes: number }): Date => {
        let newEndTime;

        const isEndDateInSameDay = differenceInMinutes(new Date(endDate), new Date(startDate)) > 0;
        if (isEndDateInSameDay) {
            newEndTime = endDate;
        } else {
            newEndTime = setHoursMinutes(startOfDay(addDays(new Date(referenceDate), 1)), hours, minutes);
        }

        const isEndDateSmallerThanStartDate = differenceInMinutes(new Date(newEndTime), new Date(startDate)) < 0;
        if (isEndDateSmallerThanStartDate) {
            newEndTime = startDate;
        }
        return newEndTime;
    };

    const getNewEndDate = (oldEndDate: Date) => {
        const endTime = getTimeFormatFromDate(oldEndDate);

        const startDate = new Date(currentTimesheet.startTime);
        const { hours, minutes } = splitHoursMinutes(endTime);
        const endDate = setHoursMinutes(startOfDay(new Date(referenceDate)), hours, minutes);

        return getEndTime({ startDate, endDate, hours, minutes });
    };

    return (
        <Stack direction='row' alignItems='flex-end' gap={1}>
            <Stack direction={'row'} gap={1} flexGrow={1}>
                <Controller
                    name={`timesheets.${index}.startTime`}
                    control={control}
                    render={({ field: { onChange, ...restField }, fieldState }) => (
                        <FormControlLabel
                            label={t('general.start')}
                            labelPlacement='top'
                            control={
                                <TimeFieldWrapper
                                    {...restField}
                                    onChange={startDate => {
                                        if (!isValidDate(startDate)) {
                                            return;
                                        }
                                        const newStartTime = getNewStartDate(startDate);
                                        onChange(newStartTime);
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            error: !!fieldState.error,
                                        },
                                    }}
                                />
                            }
                        />
                    )}
                />

                <Controller
                    name={`timesheets.${index}.endTime`}
                    control={control}
                    render={({ field: { onChange, ...restField }, fieldState }) => (
                        <FormControlLabel
                            label={t('general.end')}
                            labelPlacement='top'
                            control={
                                <TimeFieldWrapper
                                    {...restField}
                                    onChange={endDate => {
                                        if (!isValidDate(endDate)) {
                                            return;
                                        }
                                        const newEndDate = getNewEndDate(endDate);
                                        onChange(newEndDate);
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'outlined',
                                            error: !!fieldState.error,
                                        },
                                    }}
                                />
                            }
                        />
                    )}
                />

                {timesheetSetting?.breakDisplayEnabled && (
                    <Controller
                        name={`timesheets.${index}.breakDuration`}
                        control={control}
                        render={({ field: { onChange, ...restField }, fieldState }) => (
                            <SelectResource {...restField} onUpdate={onChange} label={t('general.pause')} options={getBreaks()} isError={!!fieldState.error} />
                        )}
                    />
                )}
            </Stack>

            <Typography bgcolor={theme.palette.grey[100]} padding={1.2} borderRadius={1} variant='body2bold'>
                {getTotalWorkingTime()}
            </Typography>
        </Stack>
    );
};

type SimplifiedWorkingTimepickerProps = {
    index: number;
};

const SimplifiedWorkingTimepicker: FC<SimplifiedWorkingTimepickerProps> = ({ index }) => {
    const { t } = useTranslation();

    const { control, watch, setValue } = useFormContext<EmployeeTimesheetFormValues>();
    const timesheets = watch('timesheets');
    const referenceDate = watch('referenceDate');
    const currentTimesheet = timesheets[index];

    const calculateEndDate = (): Date | undefined => {
        return currentTimesheet?.endTime && isValidDate(currentTimesheet?.endTime)
            ? subMinutes(
                  add(
                      startOfDay(referenceDate),
                      intervalToDuration({
                          start: currentTimesheet.startTime,
                          end: currentTimesheet?.endTime,
                      }),
                  ),
                  currentTimesheet.breakDuration,
              )
            : undefined;
    };

    return (
        <Stack sx={{ width: '100%' }}>
            <Controller
                name={`timesheets.${index}.endTime`}
                control={control}
                render={({ field: { onChange }, fieldState }) => (
                    <FormControlLabel
                        label={t('general.duration')}
                        labelPlacement='top'
                        sx={{ width: '100%' }}
                        control={
                            <TimeFieldWrapper
                                value={calculateEndDate()}
                                fullWidth
                                onChange={newValue => {
                                    if (!isValidDate(newValue)) {
                                        return;
                                    }
                                    // when we change the duration we need to update the start time and the end time to match the duration
                                    setValue(`timesheets.${index}.startTime`, addHours(startOfDay(referenceDate), TIMESHEET_DEFAULT_START_TIME_HOUR));
                                    setValue(`timesheets.${index}.breakDuration`, 0); // in case of an update to an existing timesheet we also need to place the break duration to 0

                                    onChange(addHours(newValue, TIMESHEET_DEFAULT_START_TIME_HOUR));
                                }}
                                slotProps={{
                                    textField: {
                                        variant: 'outlined',
                                        error: !!fieldState.error,
                                    },
                                }}
                            />
                        }
                    />
                )}
            />
        </Stack>
    );
};
