import { API_BASE_URL, client } from '@/api/common';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { LeaveCorrection, LeaveCorrectionCreateMutation, LeaveCorrectionUpdateMutation } from '@/domain/leave-correction/LeaveCorrection.model';
import { AxiosResponse } from 'axios';

type LeaveCorrectionDTO = LeaveCorrection;
type LeaveCorrectionCreationRequestDTO = Omit<LeaveCorrectionCreateMutation, 'effectiveDate'> & {
    effectiveDate: string;
};
type LeaveCorrectionUpdateRequestDTO = Omit<LeaveCorrectionUpdateMutation, 'effectiveDate'> & {
    effectiveDate: string;
};

const createLeaveCorrection = async (mutation: LeaveCorrectionCreateMutation): Promise<LeaveCorrection> => {
    return (
        await client.post<LeaveCorrectionDTO, AxiosResponse<LeaveCorrectionDTO>, LeaveCorrectionCreationRequestDTO>(
            API_BASE_URL + `/leave-corrections`,
            mutation,
        )
    ).data;
};

const updateLeaveCorrection = async (leaveCorrectionId: LeaveCorrection['id'], mutation: LeaveCorrectionUpdateMutation): Promise<LeaveCorrection> => {
    return (
        await client.put<LeaveCorrectionDTO, AxiosResponse<LeaveCorrectionDTO>, LeaveCorrectionUpdateRequestDTO>(
            API_BASE_URL + `/leave-corrections/${leaveCorrectionId}`,
            mutation,
        )
    ).data;
};

const deleteLeaveCorrection = async (leaveCorrectionId: LeaveCorrection['id']): Promise<void> => {
    await client.delete(API_BASE_URL + `/leave-corrections/${leaveCorrectionId}`);
};

const importLeavesCorrections = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/leave-corrections/import`, request)).data;
};

export const leaveCorrectionApi = {
    createLeaveCorrection,
    updateLeaveCorrection,
    deleteLeaveCorrection,
    importLeavesCorrections,
};
