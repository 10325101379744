import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DayPeriod, UnitType } from '@/domain/date/Date.model';
import {
    approvePendingLeaveRequest,
    approvePendingLeaveRequestBatch,
    checkConflictsLeaveRequest,
    convertLeavesMinutesToUnit,
    getLeaveRequestBalanceAsString,
    getLeaveRequestById,
    getLeaveRequestPeriodAsString,
    getLeaveRequestUsedAmountFormatted,
    isMedicalType,
    isOverlappingExceedsLimitInDays,
    onCancelMedicalLeaveType,
    searchConflictingRequests,
    searchOverlappingRequests,
} from '@/domain/leave-request/LeaveRequest.service';
import { LeavesActionType } from '@/stores/reducers/leavesActions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { LeaveRequest, OverlappingLeaveSearchRequest } from '@/domain/leave-request/LeaveRequest.model';
import { LeaveActivityType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { Shift, ShiftReleaseRequest } from '@/domain/shift/Shift.model';
import { ConfirmNegativeBalanceDialog } from '@/page/leave/confirm-negative-balance-dialog/ConfirmNegativeBalanceDialog';
import { LeaveRequestAttachmentsUploaderUncontrolled } from '@/page/leave/leave-request-dialog/LeaveRequestAttachmentsUploader';
import { LeavesConflictsDialog } from '@/page/leave/leaves-conflicts-dialog/LeavesConflictsDialog';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';

import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { ConfirmDialog } from '@/Components/confirmation-dialog/ConfirmDialog';
import { EmployeeAvatarWithDetails } from '@/Components/employee-avatar/EmployeeAvatarWithDetails';
import { AsyncSelectFilter, FiltersBar } from '@/Components/filters-bar/FiltersBar';
import { useFiltersStorage } from '@/Components/filters-bar/useFiltersStorage';
import { getDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';
import { getLocations } from '@/domain/location/Location.service';
import { shiftRelease } from '@/domain/shift/Shift.service';
import { desktopVisible } from '@/theme/responsive';
import { getLabelTranslation } from '@/utils/language.util';
import { AlertCircleIcon, ArrowRight01Icon, ArrowUp01Icon, AttachmentIcon, Calendar02Icon, Cancel01Icon, Tick02Icon } from 'hugeicons-react';
import { AbsentEmployeesTabPanel } from '../AbsentEmployeesTabPanel';
import { DeclineLeaveRequestDialog } from '../DeclineLeaveRequestDialog';
import { getNestedValueByPath, getSelectFilterNumberValues } from '@/Components/filters-bar/FiltersBar.util';
import { EmployeeFilterType, isValidEmployeeFilterType, doesEmployeeMatchFilter } from '@/domain/employee/Employee.service';

type Props = {
    defaultLeaveRequests: LeaveRequest[];
    leaveTypes?: LeaveType[];
    reloadView: () => void;
};

const useStyles = makeStyles(theme => ({
    row: {
        borderRadius: 5,
    },
    grow: {
        flexGrow: 1,
    },
    secondColumn: {
        width: 220,
        [theme.breakpoints.up('md')]: {
            width: 340,
        },
    },
    firstColumn: {
        width: 170,
    },

    conflictLabel: {
        color: '#585869',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: 18,
            marginLeft: 4,
            color: theme.palette.warning.main,
        },
    },
    fullNameSide: {
        flexFlow: 'inherit',
    },
    moreBtnContainer: {
        textAlign: 'right',
    },
    fixWidthSizeTitle: {
        width: 304,
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
}));

export const PendingLeaveRequestsTable: FC<Props> = ({ defaultLeaveRequests, leaveTypes, reloadView }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [rowExpanded, setRowExpanded] = useState<boolean[]>([]);
    const [leaveRequests, setLeaveRequests] = useState<LeaveRequest[]>(defaultLeaveRequests);

    const [maxNumber, setMaxNumber] = useState<number>(10);

    const [isLeaveConflictsDialogOpen, setIsLeaveConflictsDialogOpen] = useState<boolean>(false);
    const [conflictingShifts, setConflictingShifts] = useState<Shift[]>();
    const [shiftReleaseRequest, setShiftReleaseRequest] = useState<ShiftReleaseRequest>();
    const [activeLeaveRequest, setActiveLeaveRequest] = useState<LeaveRequest>();
    const [currentDeclineLeaveRequest, setCurrentDeclineLeaveRequest] = useState<LeaveRequest>();
    const [leaveRequestWithNegativeBalance, setLeaveRequestWithNegativeBalance] = useState<LeaveRequest>();
    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));

    // FILTERS
    const { filters: availableFilters } = usePendingLeaveRequestsFilters();

    const [filters, setFilters] = useFiltersStorage('pending-leave-requests', availableFilters);

    useEffect(() => {
        setLeaveRequests(defaultLeaveRequests);
    }, [defaultLeaveRequests]);

    useEffect(() => {
        if (leaveTypes === undefined) {
            getLeaveTypes()
                .then(leaveTypes => {
                    dispatch({ type: LeavesActionType.LEAVE_TYPES_LOADED, leaveTypes });
                })
                .catch(error => {
                    handleError(error);
                });
        }
    }, [leaveTypes, dispatch]);

    const onApproveRequest = (leaveRequestId: number) => {
        approvePendingLeaveRequest(leaveRequestId)
            .then(() => {
                reloadView();
                showSnackbar(t('leaves_page.messages.leave_request_approved'), 'success');
            })
            .catch(error => {
                console.error(error);
                showSnackbar(t('request_leave_dialog.end_date_cant_be_empty'), 'error');
            });
    };

    const onApproveCheckConflicts = async (request: LeaveRequest) => {
        if (!request?.id) {
            return;
        }
        setLeaveRequestWithNegativeBalance(undefined);
        const conflicts = await checkConflictsLeaveRequest(grantedPolicies, request.employee.id, request);
        if (conflicts) {
            setIsLeaveConflictsDialogOpen(true);
            setConflictingShifts(conflicts.shifts);
            setShiftReleaseRequest(conflicts.shiftReleaseRequest);
            setActiveLeaveRequest(request);
        } else {
            onApproveRequest(request.id);
        }
    };

    const fetchLeaveRequestDetail = async (request: LeaveRequest) => {
        try {
            const leaveRequest = await getLeaveRequestById(request.id);
            const leaveRequestIndex = leaveRequests.findIndex(lr => lr.id === request.id);
            if (leaveRequestIndex >= 0) {
                const searchRequest: OverlappingLeaveSearchRequest = {
                    startDate: leaveRequest.startDate,
                    endDate: leaveRequest.endDate,
                    employeeId: request.employee.id,
                    startTimePeriod: leaveRequest.startTimePeriod != DayPeriod.ALL_DAY ? leaveRequest.startTimePeriod : undefined,
                    endTimePeriod: leaveRequest.endTimePeriod != DayPeriod.ALL_DAY ? leaveRequest.endTimePeriod : undefined,
                };

                // FE should not make the overlapping and conflicts requests when no return date is set
                if (!leaveRequest.endDate) {
                    return;
                }

                const promises = getPromises(searchRequest, leaveRequest);
                const responses = await Promise.all(promises);
                if (responses) {
                    leaveRequest.conflictingRequests = responses[0]?.filter(leaveRequest => leaveRequest.id !== request.id);

                    leaveRequest.approvedOverlappingLeaveRequests = responses[1]?.filter(leaveRequest => leaveRequest.id !== request.id);
                    leaveRequest.pendingOverlappingLeaveRequests = responses[2]?.filter(leaveRequest => leaveRequest.id !== request.id);

                    leaveRequests[leaveRequestIndex] = leaveRequest;
                    setLeaveRequests([...leaveRequests]);
                }
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleOnApprove = async (request: LeaveRequest) => {
        if (!request?.id) {
            return;
        }

        try {
            const leaveRequest = await getLeaveRequestById(request.id);
            if (leaveRequest) {
                const balance = convertLeavesMinutesToUnit({
                    input:
                        (leaveRequest.leaveType?.displayUnitType === UnitType.DAYS
                            ? leaveRequest.availableAmountInDays
                            : leaveRequest.availableAmountInMinutes) ?? 0,
                    outputUnit: leaveRequest.leaveType?.displayUnitType,
                    roundingType: leaveRequest.leaveType?.roundingType,
                });
                if (balance < 0) {
                    setLeaveRequestWithNegativeBalance(leaveRequest);
                    return;
                }
            }
        } catch (error) {
            handleError(error);
        }
        onApproveCheckConflicts(request);
    };

    const renderButtons = (request: LeaveRequest) => {
        if (!request?.id) {
            return;
        }
        return (
            <Stack direction='row' alignItems='center' gap={1}>
                {!!request?.attachments?.length && (
                    <IconButton disabled={true}>
                        <AttachmentIcon />
                    </IconButton>
                )}
                {/* it needs a confirmation dialog for medical leave */}
                {request.leaveType.leaveActivityType === LeaveActivityType.MEDICAL ? (
                    <ConfirmDialog
                        title={t('request_leave_dialog.messages.cancel_leave_request_confirmation')}
                        onConfirm={() => {
                            onCancelMedicalLeaveType(request, reloadView);
                        }}
                    >
                        <IconButton>
                            <Cancel01Icon color={theme.palette.text.primary} />
                        </IconButton>
                    </ConfirmDialog>
                ) : (
                    <IconButton onClick={() => setCurrentDeclineLeaveRequest(request)}>
                        <Cancel01Icon color={theme.palette.text.primary} />
                    </IconButton>
                )}
                <Stack>
                    <Tooltip title={!request.endDate ? t('leaves_page.approve_leave_request.no_end_date') : undefined}>
                        {/* This is a hack to keep the tooltip when the button is disabled */}
                        <span>
                            <Button
                                sx={{ p: 0.5, minWidth: 'auto' }}
                                disabled={!request.endDate}
                                onClick={event => {
                                    event.stopPropagation();
                                    handleOnApprove(request);
                                }}
                            >
                                <Tick02Icon />
                            </Button>
                        </span>
                    </Tooltip>
                </Stack>
            </Stack>
        );
    };

    const hasMorePages = () => {
        const count = leaveRequests.length - maxNumber;
        if (count >= 0) {
            return count;
        } else {
            return 0;
        }
    };

    const onRowExpanded = (idx: number) => (request: LeaveRequest, newExpanded: boolean) => {
        try {
            const newExpandedData = [...rowExpanded];
            newExpandedData[idx] = newExpanded;
            setRowExpanded(newExpandedData);

            if (!newExpandedData[idx] || !request.id) {
                return;
            }
            fetchLeaveRequestDetail(request);
        } catch (error) {
            handleError(error);
        }
    };

    const getPromises = (searchRequest: OverlappingLeaveSearchRequest, updatedLeaveRequest: LeaveRequest) => {
        const conflictingRequests = searchConflictingRequests({
            ...searchRequest,
            leavePercentage: updatedLeaveRequest.leavePercentage,
        });

        const promises = [conflictingRequests];

        if (
            !isOverlappingExceedsLimitInDays(
                searchRequest?.startDate ? new Date(searchRequest.startDate) : new Date(),
                searchRequest?.endDate ? new Date(searchRequest.endDate) : new Date(),
            )
        ) {
            const overlappingApprovedRequests = searchOverlappingRequests({
                ...searchRequest,
                statuses: ['APPROVED'],
            });

            const overlappingPendingRequests = searchOverlappingRequests({
                ...searchRequest,
                statuses: ['PENDING'],
            });
            promises.push(overlappingApprovedRequests);
            promises.push(overlappingPendingRequests);
        }
        return promises;
    };

    const renderRow = (request: LeaveRequest, expanded: boolean, onExpandStateChanged: (request: LeaveRequest, expanded: boolean) => void) => {
        return (
            <Accordion expanded={expanded} onChange={(_, expandedRow) => onExpandStateChanged(request, expandedRow)}>
                <AccordionSummary>
                    <Grid container direction='column' className={classes.row} spacing={2} display={{ xs: 'flex', md: 'none' }}>
                        <Grid item>
                            <Stack direction='row' pl={1}>
                                <Grid container direction='row' className={classes.fullNameSide} spacing={1} alignItems='center'>
                                    <EmployeeAvatarWithDetails employee={request.employee} />
                                </Grid>
                                {renderButtons(request)}
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Grid container direction='column' wrap='nowrap'>
                                <Grid item>
                                    <Stack direction='row' gap={1} alignItems='center'>
                                        <Calendar02Icon size={16} color={theme.palette.primary.main} />
                                        <Typography variant='body2' color='textSecondary'>
                                            {request.leaveType.title}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body2bold' color='textPrimary'>
                                        {getLeaveRequestPeriodAsString(request, request.leaveType)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' className={classes.row} spacing={2} alignItems='center' display={{ xs: 'none', md: 'flex' }}>
                        <Grid item>
                            {expanded && <ArrowUp01Icon />}
                            {!expanded && <ArrowRight01Icon />}
                        </Grid>
                        <Grid item className={classes.firstColumn}>
                            <Grid container direction='row' className={classes.fullNameSide} spacing={1} alignItems='center'>
                                <EmployeeAvatarWithDetails employee={request.employee} />
                            </Grid>
                        </Grid>
                        <Grid item className={classes.secondColumn}>
                            <Grid container direction='column' wrap='nowrap'>
                                <Grid item>
                                    <Stack direction='row' gap={1} alignItems='center'>
                                        <Calendar02Icon size={16} color={theme.palette.primary.main} />
                                        <Typography variant='body2' color='textSecondary'>
                                            {request.leaveType.title}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body2bold' color='textPrimary' className={classes.fixWidthSizeTitle}>
                                        {getLeaveRequestPeriodAsString(request, request.leaveType)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>{t('general.duration')}</Typography>
                            <Typography variant='body2bold'>{getLeaveRequestUsedAmountFormatted(request)}</Typography>
                        </Grid>
                        <Grid item className={classes.grow} />
                        <Grid item>{renderButtons(request)}</Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <Grid container direction='row' spacing={isSmallDevice ? 2 : 5}>
                                <Grid item>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Typography variant='body2'>{t('leaves_page.table_headers.remaining_balance')}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body2bold' color='textPrimary'>
                                                {getLeaveRequestBalanceAsString(
                                                    request.leaveType,
                                                    request.availableAmountInDays,
                                                    request.availableAmountInMinutes,
                                                    undefined,
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {request.comment && (
                                    <Grid item>
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography variant='body2'>{t('general.comment')}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body2bold' color='textPrimary'>
                                                    {request.comment}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item display={{ xs: 'block', sm: 'none' }}>
                                    <Typography variant='body2'>{t('general.duration')}</Typography>
                                    <Typography variant='body2bold'>{getLeaveRequestUsedAmountFormatted(request)}</Typography>
                                </Grid>

                                {!!request.leavePercentage && isMedicalType(request.leaveType) && (
                                    <Grid item>
                                        <Typography variant='body2'>{t('general.incapacity')}</Typography>
                                        <Typography variant='body2bold' color='textPrimary'>
                                            {`${request.leavePercentage}%`}
                                        </Typography>
                                    </Grid>
                                )}
                                {!!request.conflictingRequests?.length && (
                                    <Grid item>
                                        <Grid container direction='column'>
                                            <Grid item>
                                                <Typography variant='body2' className={classes.conflictLabel}>
                                                    {t('general.conflict')} <AlertCircleIcon />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction='column' spacing={0}>
                                                    {request.conflictingRequests.map(cr => {
                                                        return (
                                                            <Grid key={cr.id} item>
                                                                <Typography variant='body2bold' color='textPrimary'>
                                                                    {getLeaveRequestPeriodAsString(cr, request.leaveType, true)}
                                                                </Typography>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {(!!request.approvedOverlappingLeaveRequests?.length || !!request.pendingOverlappingLeaveRequests?.length) && (
                            <Grid item>
                                <AbsentEmployeesTabPanel request={request} />
                            </Grid>
                        )}
                    </Grid>
                    {request.leaveType?.allowAttachments && expanded && <LeaveRequestAttachmentsUploaderUncontrolled leaveRequest={request} p={1} />}
                </AccordionDetails>
            </Accordion>
        );
    };

    const getLeaveRequestsFiltered = () => {
        // if one of the filters is filled, we have to apply a client side filtering
        const filtersFilled = filters?.filter(filter => !!filter.value?.length);
        return !filtersFilled?.length
            ? leaveRequests
            : leaveRequests.filter(row =>
                  // if one of the filter is not matching, we don't want to display the row
                  filtersFilled.every(filter => isFilterMatched(filter, row)),
              );
    };

    const isFilterMatched = (filter: AsyncSelectFilter, row: LeaveRequest) => {
        const key = filter.key;
        if (isValidEmployeeFilterType(key)) {
            const ids = getSelectFilterNumberValues(filter);
            return doesEmployeeMatchFilter(row.employee, ids, key);
        } else {
            const valueFromRow = getNestedValueByPath(row, filter.key);
            return filter.value?.find(option => option.value === valueFromRow);
        }
    };

    const leaveRequestsFiltered = getLeaveRequestsFiltered();

    const validLeaveRequests = leaveRequestsFiltered?.filter(l => !!l.endDate);

    const handleConfirmApproveAll = () => {
        approvePendingLeaveRequestBatch(validLeaveRequests.flatMap(r => r.id)).then(() => {
            reloadView();
            showSnackbar(t('request_leave_dialog.leave_request_approved'), 'success');
        });
    };

    return (
        <Stack gap={2}>
            <Stack direction='row' component={Paper} gap={1} p={1} alignItems='center' justifyContent='space-between' sx={desktopVisible}>
                <FiltersBar filters={filters} onFiltersChange={setFilters} />
                {!!leaveRequestsFiltered?.length && (
                    <Stack direction='row' gap={1} alignItems='center'>
                        {leaveRequests.every(r => !r.endDate) && (
                            <Tooltip title={t('leaves_page.approve_all_leave_requests.no_end_date')}>
                                <AlertCircleIcon color='error' />
                            </Tooltip>
                        )}
                        {!!leaveRequests?.length && (
                            <ConfirmDialog
                                title={t('domain.leave_request.approve_all_leave_requests')}
                                content={`${validLeaveRequests.length} ${t('domain.leave_request.will_be_approved')}`}
                                onConfirm={handleConfirmApproveAll}
                            >
                                <Button disabled={leaveRequests.every(r => !r.endDate)}>{t('domain.leave_request.approve_all_leave_requests')}</Button>
                            </ConfirmDialog>
                        )}
                    </Stack>
                )}
            </Stack>
            <Grid container direction='column' wrap='nowrap' spacing={1}>
                {leaveRequestsFiltered
                    .slice(0, maxNumber)
                    .sort((a, b) => (b?.startDate && a?.startDate ? new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf() : 0))
                    .map((r, idx) => {
                        return (
                            <Grid item key={r.id}>
                                {renderRow(r, rowExpanded[idx], onRowExpanded(idx))}
                            </Grid>
                        );
                    })}
                {hasMorePages() >= 1 && (
                    <Grid item className={classes.moreBtnContainer}>
                        <Button
                            variant='text'
                            size='small'
                            onClick={() => {
                                setMaxNumber(maxNumber + 10);
                            }}
                        >
                            {t('leaves_page.show_more_rows', {
                                numberOfRows: hasMorePages(),
                            })}
                        </Button>
                    </Grid>
                )}
            </Grid>

            {isLeaveConflictsDialogOpen && shiftReleaseRequest && activeLeaveRequest && (
                <LeavesConflictsDialog
                    open={isLeaveConflictsDialogOpen}
                    onClose={() => setIsLeaveConflictsDialogOpen(false)}
                    onSave={() => {
                        shiftRelease(shiftReleaseRequest).then(() => {
                            if (activeLeaveRequest.id) {
                                onApproveRequest(activeLeaveRequest.id);
                            }
                            setIsLeaveConflictsDialogOpen(false);
                        });
                    }}
                    saveAndKeepConflicts={() => {
                        if (activeLeaveRequest.id) {
                            onApproveRequest(activeLeaveRequest.id);
                        }
                        setIsLeaveConflictsDialogOpen(false);
                    }}
                    shifts={conflictingShifts ?? []}
                />
            )}

            {!!currentDeclineLeaveRequest && (
                <DeclineLeaveRequestDialog
                    leaveRequestId={currentDeclineLeaveRequest.id}
                    onCancel={() => setCurrentDeclineLeaveRequest(undefined)}
                    onDeclineRequest={() => {
                        setCurrentDeclineLeaveRequest(undefined);
                        reloadView();
                    }}
                />
            )}

            {leaveRequestWithNegativeBalance && (
                <ConfirmNegativeBalanceDialog
                    onSave={() => {
                        onApproveCheckConflicts(leaveRequestWithNegativeBalance);
                    }}
                    onClose={() => setLeaveRequestWithNegativeBalance(undefined)}
                    leaveType={leaveRequestWithNegativeBalance.leaveType}
                    availableAmountInDays={leaveRequestWithNegativeBalance.availableAmountInDays}
                    availableAmountInMinutes={leaveRequestWithNegativeBalance.availableAmountInMinutes}
                />
            )}
        </Stack>
    );
};

const usePendingLeaveRequestsFilters = () => {
    const filters: AsyncSelectFilter[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'LEAVE_TYPE_POLICY_LEAVE_TYPE_TITLE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const leaveTypes = await getLeaveTypes();
                return leaveTypes?.map(leaveType => ({
                    label: leaveType.title,
                    value: leaveType.id,
                }));
            },
            key: 'leaveType.id',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobs = await getJobs();
                return jobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                }));
            },
            key: 'JOB_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
