import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { SurveyTemplate, SurveyTemplateCreationMutation, SurveyTemplateUpdateMutation } from '@/domain/survey-template/Survey-template.model';
import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';

type SurveyTemplateDTO = Omit<SurveyTemplate, 'createdBy'> & {
    createdBy: EmployeeDTO;
};

type SurveyTemplateCreationRequestDTO = SurveyTemplateCreationMutation;
type SurveyTemplateUpdateRequestDTO = SurveyTemplateUpdateMutation;

const mapSurveyTemplateDTO = (survey: SurveyTemplateDTO): SurveyTemplate => ({
    ...survey,
    createdBy: mapEmployeeDTO(survey.createdBy),
});

export const getSurveyTemplates = async (): Promise<SurveyTemplate[]> => {
    const { data } = await client.get<SurveyTemplateDTO[]>(API_BASE_URL + `/survey-templates`);

    return data.map(mapSurveyTemplateDTO);
};

export const createSurveyTemplate = async (request: SurveyTemplateCreationRequestDTO): Promise<SurveyTemplate> => {
    const { data } = await client.post<SurveyTemplateDTO, AxiosResponse<SurveyTemplateDTO>, SurveyTemplateCreationRequestDTO>(
        API_BASE_URL + `/survey-templates`,
        request,
    );

    return mapSurveyTemplateDTO(data);
};

export const getSurveyTemplate = async (surveyId: number): Promise<SurveyTemplate> => {
    const { data } = await client.get<SurveyTemplateDTO>(API_BASE_URL + `/survey-templates/${surveyId}`);

    return mapSurveyTemplateDTO(data);
};

export const deleteSurveyTemplate = async (surveyId: number): Promise<number> => {
    const { data } = await client.delete<number>(API_BASE_URL + `/survey-templates/${surveyId}`);

    return data;
};

export const updateSurveyTemplate = async (surveyId: number, request: SurveyTemplateUpdateRequestDTO): Promise<SurveyTemplate> => {
    const { data } = await client.put<SurveyTemplateDTO, AxiosResponse<SurveyTemplateDTO>, SurveyTemplateUpdateRequestDTO>(
        API_BASE_URL + `/survey-templates/${surveyId}`,
        request,
    );

    return mapSurveyTemplateDTO(data);
};

export const cloneSurveyTemplate = async (surveyId: number): Promise<SurveyTemplate> => {
    const { data } = await client.post<SurveyTemplateDTO, AxiosResponse<SurveyTemplateDTO>>(API_BASE_URL + `/survey-templates/${surveyId}/clone`, {});

    return mapSurveyTemplateDTO(data);
};

export const surveyTemplateApi = {
    getSurveyTemplates,
    createSurveyTemplate,
    getSurveyTemplate,
    deleteSurveyTemplate,
    updateSurveyTemplate,
    cloneSurveyTemplate,
};
