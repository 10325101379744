import {
    EmployeeSection as EmployeeSectionDomain,
    EmployeeSectionFieldDocument as EmployeeSectionFieldDocumentDomain,
    EmployeeSectionImportRequest,
    EmployeeSectionRow as EmployeeSectionRowDomain,
    EmployeeSectionRowCreationMutation,
    EmployeeSectionRowUpdateMutation,
    EmployeeSectionSearch,
} from '@/domain/employee-section/EmployeeSection.model';
import { AxiosResponse } from 'axios';
import { EmployeeSectionFieldProfileChange } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { DocumentContentDisposition } from '@/domain/document/Document.model';
import { API_BASE_URL, client } from '@/api/common';
import { ImportResult } from '@/domain/import/Import.model';

export type EmployeeSectionDTO = EmployeeSectionDomain;
export type EmployeeSectionRowDTO = EmployeeSectionRowDomain;
export type EmployeeSectionFieldDocumentDTO = EmployeeSectionFieldDocumentDomain;
export type EmployeeSectionFieldDTO = EmployeeSectionFieldProfileChange;
type EmployeeSectionRowCreationRequestDTO = EmployeeSectionRowCreationMutation;
type EmployeeSectionSearchRequestDTO = EmployeeSectionSearch;
type EmployeeSectionRowUpdateRequestDTO = EmployeeSectionRowUpdateMutation;

const searchEmployeeSections = async (request: EmployeeSectionSearch): Promise<EmployeeSectionDomain[]> => {
    return (
        await client.post<EmployeeSectionDTO[], AxiosResponse<EmployeeSectionDTO[]>, EmployeeSectionSearchRequestDTO>(
            API_BASE_URL + '/employee/sections/search',
            request,
        )
    ).data;
};

const addEmployeeSectionRow = async (employeeSectionId: number, request: EmployeeSectionRowCreationMutation): Promise<EmployeeSectionRowDomain> => {
    return (
        await client.post<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, EmployeeSectionRowCreationRequestDTO>(
            API_BASE_URL + `/employee/sections/${employeeSectionId}/row`,
            request,
        )
    ).data;
};

const createEmployeeSectionRowPendingRequest = async (
    employeeSectionId: number,
    request: EmployeeSectionRowCreationMutation,
): Promise<EmployeeSectionRowDomain> => {
    return (
        await client.post<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, EmployeeSectionRowCreationRequestDTO>(
            API_BASE_URL + `/employee/sections/${employeeSectionId}/row/pending`,
            request,
        )
    ).data;
};

const updateEmployeeSectionRowPendingRequest = async (
    employeeSectionId: number,
    pendingRowId: number,
    request: EmployeeSectionRowUpdateMutation,
): Promise<EmployeeSectionRowDomain> => {
    return (
        await client.put<EmployeeSectionRowDTO, AxiosResponse<EmployeeSectionRowDTO>, EmployeeSectionRowUpdateMutation>(
            API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${pendingRowId}/pending`,
            request,
        )
    ).data;
};

const updateEmployeeSectionRow = async (
    employeeSectionId: number,
    employeeSectionRowId: number,
    request: EmployeeSectionRowUpdateRequestDTO,
): Promise<EmployeeSectionRowDomain> => {
    return (await client.put(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}`, request)).data;
};

const deleteEmployeeSectionRow = async (employeeSectionId: number, employeeSectionRowId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}`);
};

const deleteEmployeeSectionRowPendingRequest = async (employeeSectionId: number, employeeSectionRowId: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${employeeSectionRowId}/cancel`);
};

const approveEmployeeCustomSectionRowPendingRequest = async (employeeSectionId: number, pendingRowId: number): Promise<void> => {
    await client.post(API_BASE_URL + `/employee/sections/${employeeSectionId}/row/${pendingRowId}/approve`);
};

const getEmployeeSectionFieldDocumentUrl = async (
    employeeSectionFieldDocumentId: number,
    documentContentDisposition: DocumentContentDisposition,
): Promise<string> => {
    return (await client.get<string>(API_BASE_URL + `/employee/sections/document/${employeeSectionFieldDocumentId}/url/${documentContentDisposition}`)).data;
};

const importEmployeeSection = async (employeeSectionImportRequest: EmployeeSectionImportRequest, sectionDefinitionId: number): Promise<ImportResult> => {
    return (await client.post(API_BASE_URL + `/employee/sections/${sectionDefinitionId}/import`, employeeSectionImportRequest)).data;
};

export const employeeSectionApi = {
    searchEmployeeSections,
    addEmployeeSectionRow,
    updateEmployeeSectionRow,
    deleteEmployeeSectionRow,
    getEmployeeSectionFieldDocumentUrl,
    createEmployeeSectionRowPendingRequest,
    updateEmployeeSectionRowPendingRequest,
    deleteEmployeeSectionRowPendingRequest,
    approveEmployeeCustomSectionRowPendingRequest,
    importEmployeeSection,
};
