/**
 * Checks if two arrays contain the same elements.
 *
 */
export function equals<T>(arr1: T[], arr2: T[]): boolean {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    return arr1?.every(item => set2.has(item)) && arr2?.every(item => set1.has(item));
}

export function sort<T extends { order: number }>(array: T[]): T[] {
    return array.sort((a, b) => a.order - b.order);
}

export function reorder<T extends { order: number }>(array: T[]): T[] {
    return array.map((item, index) => ({
        ...item,
        order: index + 1,
    }));
}

export function remove<T extends { order: number }>(array: T[], index: number): T[] {
    return array.filter((_, i) => i !== index);
}

export function duplicate<T extends { order: number }>(array: T[], index: number): T[] {
    const itemToDuplicate = array[index];
    const duplicatedItem = { ...itemToDuplicate, order: index + 1 };
    return [
        ...array.slice(0, index + 1),
        duplicatedItem,
        ...array.slice(index + 1).map(item => ({
            ...item,
            order: item.order + 1,
        })),
    ];
}

export function maxOrderNumber<T extends { order: number }>(fields: T[]): number {
    return fields.map(field => field.order).reduce((a, b) => Math.max(a, b), 0);
}

/**
 * Returns the first item of the array if it's the only element, otherwise returns undefined.
 * @param array
 */
export const getSingleItemIfExists = <T>(array: T[]): T | undefined => {
    if (array.length === 1) {
        return array[0];
    }
    return undefined;
};
