import { FC, Ref } from 'react';
import { Alert, Paper, Stack, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import { SkillState } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSkill';
import { FieldError } from 'react-hook-form';
import {
    EmployeeReviewFeedbackSummarySkillFormType,
    EmployeeReviewSummaryFeedbackSkillsFormType,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { EmployeeReviewFeedbackSummarySkill } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewManagerFeedbackSkill';
import { getGroupedSkillsByCategoryId } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSkills.utils';

type EmployeeReviewFeedbackSummarySkillsProps = {
    disabled: boolean;
    skillsItem: EmployeeReviewSummaryFeedbackSkillsFormType;
    handleSkillChanged: (employeeReviewSummaryFeedbackSkillForm: EmployeeReviewFeedbackSummarySkillFormType) => void;
    error: FieldError | undefined;
    innerRef: Ref<HTMLDivElement>;
};

export const EmployeeReviewFeedbackSummarySkills: FC<EmployeeReviewFeedbackSummarySkillsProps> = ({
    innerRef,
    handleSkillChanged,
    skillsItem,
    error,
    disabled,
}) => {
    const { minSkills, maxSkills, skills } = skillsItem;
    const theme = useTheme();
    const groupedSkillsByCategoryId = getGroupedSkillsByCategoryId(skills);

    if (skillsItem.skills.length === 0) {
        return <></>;
    }

    return (
        <Stack component={Paper} sx={{ p: 2, border: error ? `1px solid ${theme.palette.error.main}` : 'none' }} gap={2} ref={innerRef}>
            {!!error && error.message && (
                <Alert severity='error' elevation={0}>
                    <Typography variant='body2'>{error.message}</Typography>
                </Alert>
            )}
            <Typography variant='h2'>{t('reviews.write_feedback.review_skills')}</Typography>

            <Typography variant='body1'>
                {minSkills === maxSkills
                    ? t('reviews.write_feedback.review_skills_description_min_equal_max', {
                          value: minSkills,
                      })
                    : t('reviews.write_feedback.review_skills_description', {
                          min: minSkills,
                          max: maxSkills,
                      })}
            </Typography>

            {Array.from(groupedSkillsByCategoryId.values()).map(({ categoryName, groupedSkills }) => (
                <SkillCategoryBlock
                    disabled={disabled}
                    key={categoryName}
                    categoryName={categoryName}
                    groupedEmployeeReviewFeedbackSkillForm={groupedSkills}
                    handleSkillChanged={handleSkillChanged}
                />
            ))}
        </Stack>
    );
};

type SkillCategoryBlockProps = {
    categoryName: string;
    groupedEmployeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSummarySkillFormType[];
    handleSkillChanged: (employeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSummarySkillFormType) => void;
    disabled: boolean;
};

const SkillCategoryBlock: FC<SkillCategoryBlockProps> = ({ categoryName, groupedEmployeeReviewFeedbackSkillForm, handleSkillChanged, disabled }) => {
    const isAtLeastOneFeedback = (employeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSummarySkillFormType): boolean => {
        const isAtLeastOneSelfFeedback =
            !!employeeReviewFeedbackSkillForm?.selfFeedbackQuestion?.score && employeeReviewFeedbackSkillForm.selfFeedbackQuestion.score > 0;

        const isAtLeastOneManagerFeedback = (employeeReviewFeedbackSkillForm.managerFeedbackQuestion ?? []).some(
            managerFeedbackSkill => managerFeedbackSkill?.score && managerFeedbackSkill.score > 0,
        );

        const isAtLeastOnePeerFeedback = (employeeReviewFeedbackSkillForm.peerFeedbackQuestion ?? []).some(
            peerFeedbackSkill => peerFeedbackSkill?.score && peerFeedbackSkill.score > 0,
        );

        const isAtLeastOneUpwardFeedback = (employeeReviewFeedbackSkillForm.upwardFeedbackQuestion ?? []).some(
            upwardFeedbackSkill => upwardFeedbackSkill?.score && upwardFeedbackSkill.score > 0,
        );

        return isAtLeastOneSelfFeedback || isAtLeastOneManagerFeedback || isAtLeastOnePeerFeedback || isAtLeastOneUpwardFeedback;
    };

    const getSummarySkillState = (employeeReviewFeedbackSkillForm: EmployeeReviewFeedbackSummarySkillFormType): SkillState => {
        if (employeeReviewFeedbackSkillForm.score && employeeReviewFeedbackSkillForm.score > 0) {
            return 'reviewed';
        }

        if (isAtLeastOneFeedback(employeeReviewFeedbackSkillForm)) {
            return 'summary_meeting';
        }

        return 'not_reviewed';
    };

    return (
        <Stack gap={2}>
            <Typography variant={'body1bold'}>{categoryName}</Typography>
            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                {groupedEmployeeReviewFeedbackSkillForm.map(employeeReviewFeedbackSkillForm => (
                    <EmployeeReviewFeedbackSummarySkill
                        disabled={disabled}
                        key={employeeReviewFeedbackSkillForm.skill.id}
                        skillState={getSummarySkillState(employeeReviewFeedbackSkillForm)}
                        feedbackSkillItem={employeeReviewFeedbackSkillForm}
                        handleSkillChanged={handleSkillChanged}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
