import { TimesheetPayment } from '@/domain/timesheet-payment/TimesheetPayment.model';
import { TimesheetAdjustmentCreateMutation, TimesheetAdjustmentUpdateMutation } from './TimesheetAdjustment.model';
import { timesheetAdjustmentAPI } from '@/api/timesheet-adjustment/TimesheetAdjustment.api';

export const timesheetAdjustmentRequest = (request: TimesheetAdjustmentCreateMutation): Promise<TimesheetPayment> => {
    return timesheetAdjustmentAPI.timesheetAdjustmentRequest(request);
};

export const timesheetAdjustmentUpdateRequest = (request: TimesheetAdjustmentUpdateMutation, id: number): Promise<TimesheetPayment> => {
    return timesheetAdjustmentAPI.timesheetAdjustmentUpdateRequest(request, id);
};

export const timesheetAdjustmentDelete = (id: number): Promise<void> => {
    return timesheetAdjustmentAPI.timesheetAdjustmentDelete(id);
};
