import { CompanySettingsLayout } from '../../CompanySettingsLayout';
import { FC, useEffect, useState } from 'react';
import { AddCategoryDialog, CategoryForm } from './AddCategoryDialog';
import { useTranslation } from 'react-i18next';
import { showSnackbar } from '@/utils/snackbar.util';
import { ConfigType } from '../../types';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { ObjectiveCategory } from '@/domain/objective/Objective.model';
import {
    createObjectiveCategory,
    deleteObjectiveCategory,
    searchObjectiveCategories,
    updateObjectiveCategory,
} from '@/domain/objective-category/ObjectiveCategory.service';
import { BasicMenu } from '@/Components/basic-menu/BasicMenu';
import { MoreVerticalIcon } from '@/assets/Icons/Icons';

export const ObjectiveCategories: FC = () => {
    const { t } = useTranslation();
    const [objectiveCategories, setObjectiveCategories] = useState<ObjectiveCategory[]>([]);
    const [sectionDialogMode, setSectionDialogMode] = useState<'create' | 'edit'>('create');
    const [categoryDialogOpen, setCategoryDialogOpen] = useState<boolean>(false);
    const [activeCategoryData, setActiveCategoryData] = useState<ObjectiveCategory>();

    useEffect(() => {
        searchObjectiveCategories({ name: '' })
            .then(data => setObjectiveCategories(data))
            .catch(handleError);
    }, []);

    const onClickCategoriesRow = (category: ObjectiveCategory) => {
        setSectionDialogMode('edit');
        setActiveCategoryData(category);
        setCategoryDialogOpen(true);
    };
    const onCloseCategoryDialog = () => {
        setCategoryDialogOpen(false);
        setActiveCategoryData(undefined);
    };
    const onDeleteCategory = (category: ObjectiveCategory) => {
        deleteObjectiveCategory(category.id)
            .then(() => {
                showSnackbar(t('objectives_settings_page.messages.objective_category_deleted'), 'success');
                const categories = [...objectiveCategories];
                const newCategories = categories.filter(item => item.id !== category.id);
                setObjectiveCategories(newCategories);
            })
            .catch(handleError);
    };
    const onSaveCategory = (category: CategoryForm) => {
        setCategoryDialogOpen(false);
        setActiveCategoryData(undefined);

        const request = {
            name: category.name,
            order: objectiveCategories.length,
        };
        if (sectionDialogMode === 'create') {
            createObjectiveCategory(request)
                .then(data => {
                    const categories = [...objectiveCategories];
                    categories.push(data);
                    setObjectiveCategories(categories);
                    showSnackbar(t('objectives_settings_page.messages.objective_category_created'), 'success');
                })
                .catch(error => {
                    handleError(error);
                });
        } else if (activeCategoryData?.id) {
            updateObjectiveCategory(activeCategoryData?.id, request)
                .then(data => {
                    const categories = [...objectiveCategories];
                    const findCategoryIndex = categories.findIndex(category => category.id === data.id);
                    categories[findCategoryIndex] = data;
                    setObjectiveCategories(categories);
                    showSnackbar(t('objectives_settings_page.messages.objective_category_updated'), 'success');
                })
                .catch(error => {
                    handleError(error);
                });
        }
    };

    const primaryAction = () => {
        setSectionDialogMode('create');
        setCategoryDialogOpen(true);
        setActiveCategoryData(undefined);
    };

    const cellActionRenderer = (params: ICellRendererParams<ObjectiveCategory>) => {
        return (
            <BasicMenu
                items={[
                    {
                        title: t('general.edit'),
                        onClick: () => (params.data ? onClickCategoriesRow(params.data) : undefined),
                    },
                    {
                        title: t('general.delete'),
                        onClick: () => (params.data ? onDeleteCategory(params.data) : undefined),
                    },
                ]}
                endIcon={<MoreVerticalIcon />}
            />
        );
    };

    const config: ConfigType<ObjectiveCategory> = {
        type: 'table',
        isOnRowClickActive: true,
        header: {
            primaryButtonCaption: t('objectives_settings_page.category'),
            primaryButtonAction: primaryAction,
        },
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<ObjectiveCategory>) => (params.data ? onClickCategoriesRow(params.data) : undefined),
            },
            columns: [
                {
                    field: 'name',
                    headerName: t('objectives_settings_page.categories'),
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    return (
        <>
            <CompanySettingsLayout options={config} data={objectiveCategories} isSearchAvailable />
            <AddCategoryDialog
                open={categoryDialogOpen}
                handleSave={onSaveCategory}
                closeDialog={onCloseCategoryDialog}
                activeCategoryData={activeCategoryData}
                mode={sectionDialogMode}
            />
        </>
    );
};
