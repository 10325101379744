import i18next from 'i18next';
import * as yup from 'yup';

export const reviewSkillSettingFormSchema = yup.object().shape({
    name: yup.string().trim().required(),
    description: yup.string().default(''),
    categoryId: yup.number().required(),
    allRolesSelected: yup.boolean().default(false),
    availableForRoles: yup
        .array()
        .of(yup.number().required())
        .default([])
        .when('allRolesSelected', {
            is: false,
            then: schema => schema.min(1, i18next.t('performance_settings_page.skill_form.messages.missing_roles')).required(),
        }),
});

export type ReviewSkillSettingFormValues = yup.InferType<typeof reviewSkillSettingFormSchema>;
