import { makeStyles } from '@mui/styles';
import { FC } from 'react';

import category1 from '@/assets/Icons/SurveyCategories/icon1.svg';
import category2 from '@/assets/Icons/SurveyCategories/icon2.svg';
import category3 from '@/assets/Icons/SurveyCategories/icon3.svg';
import category4 from '@/assets/Icons/SurveyCategories/icon4.svg';
import category5 from '@/assets/Icons/SurveyCategories/icon5.svg';
import { AnswerResult, getSurveyCategoryTranslationKey, SurveyQuestionCategory, SurveyResultFilterType, SurveyResults } from '@/domain/survey/Survey.model';
import { Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
    categories: AnswerResult[];
    setCategory: (category: SurveyQuestionCategory | undefined) => void;
    activeCategory: SurveyQuestionCategory | undefined;
    results: SurveyResults;
    groupBy: SurveyResultFilterType;
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: 38,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
    },
    card: {
        width: 144,
        cursor: 'pointer',
        '&.active': {
            background: theme.palette.primary.main,
            '& .MuiTypography-root': {
                color: '#fff',
            },
        },
        '& .MuiCardContent-root': {
            paddingBottom: theme.spacing(2),
        },
    },
    icon: {
        display: 'block',
        margin: 'auto',
    },
    categoryIcon: {
        background: theme.palette.background.default,
        width: 40,
        margin: 'auto',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '50%',
        marginBottom: theme.spacing(1),
    },
    categoryBox: {
        display: 'flex',
        paddingTop: '0!important',
        marginBottom: theme.spacing(1),
    },
}));
export const SurveyCategories: FC<Props> = ({ results, categories, setCategory, activeCategory }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const showCategoryIcon = (category: SurveyQuestionCategory | undefined) => {
        switch (category) {
            case SurveyQuestionCategory.FULFILLING_ROLE:
                return category1;
            case SurveyQuestionCategory.PERSONAL_GROWTH:
                return category2;
            case SurveyQuestionCategory.STRONG_LEADERSHIP:
                return category3;
            case SurveyQuestionCategory.POSITIVE_RELATIONSHIPS:
                return category4;
            case SurveyQuestionCategory.MISSION_VALUES:
                return category5;
            case SurveyQuestionCategory.HEALTH_WELL_BEING:
                // TODO: add missing icons
                return category5;
            default:
                break;
        }
    };

    const sortByType = (x: AnswerResult, y: AnswerResult) => {
        if (!x.question.category || !y.question.category) {
            return 0;
        }
        // TODO use better compare function
        if (x.question.category < y.question.category) {
            return -1;
        }
        if (x.question.category > y.question.category) {
            return 1;
        }
        return 0;
    };
    return (
        <Grid container direction={'row'} className={classes.root} spacing={2} justifyContent={'center'}>
            {categories
                ? [...categories].sort(sortByType).map(category => (
                      <Grid key={category.question.id} item className={classes.categoryBox}>
                          <Card className={`${classNames(classes.card)} ${activeCategory === category.question.category ? 'active' : ''}`} variant='outlined'>
                              <CardContent onClick={() => setCategory(category.question.category)}>
                                  <div className={classes.categoryIcon}>
                                      <img src={showCategoryIcon(category.question.category)} alt={'Icon'} className={classes.icon} />
                                  </div>
                                  <Typography variant='body1bold'>
                                      {results?.categories['ALL'][0]?.categoryAverageScore?.[
                                          // TODO fix domaine type
                                          category.question.category ?? ('' as SurveyQuestionCategory)
                                      ]?.toFixed(1)}
                                  </Typography>
                                  <Typography variant='body1'>{t(getSurveyCategoryTranslationKey(category.question.category))}</Typography>
                              </CardContent>
                          </Card>
                      </Grid>
                  ))
                : undefined}
        </Grid>
    );
};
