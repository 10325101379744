import { API_BASE_URL, client } from '@/api/common';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import {
    AreaShift,
    EmployeeShift,
    LeaveShiftSearchRequest,
    Shift,
    ShiftCoverage,
    ShiftCoverageCreationRequest,
    ShiftCoverageSearchRequest,
    ShiftCoverageUpdateRequest,
    ShiftCreationRequest,
    ShiftPublishRequest,
    ShiftReleaseRequest,
    ShiftSearchRequest,
    ShiftUpdateRequest,
    TagShiftCoverage,
} from '@/domain/shift/Shift.model';
import { convertToUTCStartOfDay } from '@/utils/datetime.util';
import { AxiosResponse, GenericAbortSignal } from 'axios';
import { subMilliseconds } from 'date-fns';

const getShift = async (id: number): Promise<Shift> => {
    const url = API_BASE_URL + `/shifts/${id}`;
    const { data } = await client.get<ShiftDTO>(url);
    return data;
};

const mapEmployeeShift = (employeeShift: EmployeeShiftDTO): EmployeeShift => {
    return {
        ...employeeShift,
        assignee: mapEmployeeDTO(employeeShift.assignee),
    };
};

const getLeaveShifts = async (request: LeaveShiftSearchRequest): Promise<EmployeeShift[]> => {
    const url = API_BASE_URL + '/shifts/leaves/search';
    const { data } = await client.post<EmployeeShiftDTO[], AxiosResponse<EmployeeShiftDTO[]>, LeaveShiftSearchRequestDTO>(url, {
        ...request,
        startDate: convertToUTCStartOfDay(request.startDate).toISOString(),
        endDate: subMilliseconds(convertToUTCStartOfDay(request.endDate), 1).toISOString(),
    });
    return data.map(mapEmployeeShift);
};

const getPendingLeaveShifts = async (request: LeaveShiftSearchRequest): Promise<EmployeeShift[]> => {
    const url = API_BASE_URL + '/shifts/leaves/pending/search';
    const { data } = await client.post<EmployeeShiftDTO[], AxiosResponse<EmployeeShiftDTO[]>, LeaveShiftSearchRequestDTO>(url, {
        ...request,
        startDate: convertToUTCStartOfDay(request.startDate).toISOString(),
        endDate: subMilliseconds(convertToUTCStartOfDay(request.endDate), 1).toISOString(),
    });
    return data.map(mapEmployeeShift);
};

const getEmployeeShifts = async (request: ShiftSearchRequest, signal?: GenericAbortSignal): Promise<EmployeeShift[]> => {
    const url = API_BASE_URL + '/shifts/employees/search';
    const { data } = await client.post<EmployeeShiftDTO[], AxiosResponse<EmployeeShiftDTO[]>, ShiftSearchRequestDTO>(
        url,
        {
            ...request,
            rangeDates: mapRangeDates(request.rangeDates),
        },
        { signal },
    );
    return data.map(mapEmployeeShift);
};

const mapRangeDates = (rangeDates: { start: Date; end: Date }[]): { start: string; end: string }[] => {
    return rangeDates.map(({ start, end }) => ({
        // Range type doesn't match with real type so we need to adjust it
        start: convertToUTCStartOfDay(start).toISOString(),
        end: subMilliseconds(convertToUTCStartOfDay(end), 1).toISOString(),
    }));
};

const getAreasShifts = async (request: ShiftSearchRequest, signal?: GenericAbortSignal): Promise<AreaShift[]> => {
    const url = API_BASE_URL + '/shifts/areas/search';
    const { data } = await client.post<AreaShiftDTO[], AxiosResponse<AreaShiftDTO[]>, ShiftSearchRequestDTO>(
        url,
        {
            ...request,
            rangeDates: mapRangeDates(request.rangeDates),
        },
        { signal },
    );
    return data;
};

const createShift = async (newShift: ShiftCreationRequest): Promise<Shift> => {
    const url = API_BASE_URL + '/shifts';
    const { data } = await client.post<ShiftDTO, AxiosResponse<ShiftDTO>, ShiftCreationRequestDTO>(url, newShift);
    return data;
};

const shiftRelease = async (request: ShiftReleaseRequest): Promise<void> => {
    const url = API_BASE_URL + '/shifts/release';
    const { data } = await client.post(url, request);
    return data;
};

const updateShift = async (updatedShift: ShiftUpdateRequest, id: number): Promise<Shift> => {
    const url = API_BASE_URL + `/shifts/${id}`;
    const { data } = await client.put<ShiftDTO, AxiosResponse<ShiftDTO>, ShiftUpdateRequestDTO>(url, updatedShift);
    return data;
};

const deleteShift = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/shifts/${id}`;
    await client.delete(url);
};

const publishShifts = async (request: ShiftPublishRequest): Promise<void> => {
    const url = API_BASE_URL + '/shifts/publish';
    const { data } = await client.post(url, request);
    return data;
};

const createShiftCoverage = async (newShift: ShiftCoverageCreationRequest): Promise<ShiftCoverage> => {
    const url = API_BASE_URL + '/shift-coverages';
    const { data } = await client.post<ShiftCoverageDTO, AxiosResponse<ShiftCoverageDTO>, ShiftCoverageCreationRequestDTO>(url, newShift);
    return data;
};

const getTagShiftCoverages = async (request: ShiftCoverageSearchRequest, signal?: GenericAbortSignal): Promise<TagShiftCoverage[]> => {
    const url = API_BASE_URL + '/shift-coverages/search';
    const { data } = await client.post<TagShiftCoverageDTO[], AxiosResponse<TagShiftCoverageDTO[]>, ShiftCoverageSearchRequestDTO>(url, request, { signal });
    return data;
};

const updateShiftCoverage = async (id: number, updatedShift: ShiftCoverageUpdateRequest): Promise<ShiftCoverage> => {
    const url = API_BASE_URL + `/shift-coverages/${id}`;
    const { data } = await client.put<ShiftCoverageDTO, AxiosResponse<ShiftCoverageDTO>, ShiftCoverageUpdateRequestDTO>(url, updatedShift);
    return data;
};

const deleteShiftCoverage = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/shift-coverages/${id}`;
    await client.delete(url);
};

export type ShiftDTO = Shift;
export type EmployeeShiftDTO = Overwrite<EmployeeShift, { assignee: EmployeeDTO }>;
export type LeaveShiftSearchRequestDTO = Overwrite<LeaveShiftSearchRequest, { startDate: string; endDate: string }>;
export type ShiftSearchRequestDTO = Overwrite<ShiftSearchRequest, { rangeDates: { start: string; end: string }[] }>;
export type ShiftCreationRequestDTO = Omit<ShiftCreationRequest, 'employeeId'> & { employeeIds: number[] };
export type ShiftUpdateRequestDTO = ShiftUpdateRequest;
export type AreaShiftDTO = AreaShift;
export type ShiftCoverageDTO = ShiftCoverage;
export type ShiftCoverageCreationRequestDTO = ShiftCoverageCreationRequest;
export type ShiftCoverageSearchRequestDTO = ShiftCoverageSearchRequest;
export type TagShiftCoverageDTO = TagShiftCoverage;
export type ShiftCoverageUpdateRequestDTO = ShiftCoverageUpdateRequest;

export const shiftApi = {
    getShift,
    getLeaveShifts,
    getPendingLeaveShifts,
    getEmployeeShifts,
    getAreasShifts,
    createShift,
    shiftRelease,
    updateShift,
    deleteShift,
    publishShifts,
    createShiftCoverage,
    getTagShiftCoverages,
    updateShiftCoverage,
    deleteShiftCoverage,
};
