import { FC, useCallback, useEffect } from 'react';
import * as filestack from 'filestack-js';
import { PickerFileMetadata } from 'filestack-js';
import { getAppConfig } from '@/config/config';
import { Employee } from '@/domain/employee/Employee.model';

const ASPECT_RATION_CROP = 1;
const config = getAppConfig();
const client = filestack.init(config.FILESTACK.API_KEY);

type UploadUserPhotoProps = {
    employee?: Employee;
    handleClose: () => void;

    onUploadFinished?: (fileKey: string) => void;
};

// Todo: use filePickerWrapper
export const UploadUserPhoto: FC<UploadUserPhotoProps> = ({ employee, handleClose, onUploadFinished = () => {} }) => {
    const onUploadPhoto = useCallback(() => {
        if (!employee) {
            return;
        }
        client
            .picker({
                fromSources: ['local_file_system'],
                accept: config.MIME_TYPES.IMAGES,
                storeTo: {
                    workflows: [config.FILESTACK.AVATAR_IMAGE_WORKFLOW],
                },
                transformations: {
                    crop: {
                        aspectRatio: ASPECT_RATION_CROP,
                        force: true,
                    },
                },
                onClose: handleClose,
                onFileSelected: file => {
                    if (file.size > config.MAX_UPLOAD_FILE_SIZE) {
                        throw new Error('File too big, select something smaller than 10MB');
                    }
                },
                onFileUploadFinished: function (response: PickerFileMetadata) {
                    if (!response.key) {
                        return;
                    }
                    onUploadFinished(response.key);
                },
            })
            .open();
    }, [employee, handleClose, onUploadFinished]);

    useEffect(() => {
        onUploadPhoto();
    }, [onUploadPhoto]);

    return <></>;
};
