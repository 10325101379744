import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { CostCenter, CostCenterMutation } from '@/domain/cost-center/CostCenter.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';

type CostCenterDTO = CostCenter;
type CostCenterMutationDTO = CostCenterMutation;

const searchCostCenters = async (): Promise<CostCenter[]> => {
    const url = API_BASE_URL + `/cost-centers/search`;
    return (await client.post<CostCenterDTO[], AxiosResponse<CostCenterDTO[]>>(url, {})).data;
};

const createCostCenter = async (request: CostCenterMutation): Promise<CostCenter> => {
    const url = API_BASE_URL + `/cost-centers`;
    return (await client.post<CostCenterDTO, AxiosResponse<CostCenterDTO>, CostCenterMutationDTO>(url, request)).data;
};

const updateCostCenter = async (costCenterId: number, request: CostCenterMutation): Promise<CostCenter> => {
    const url = API_BASE_URL + `/cost-centers/${costCenterId}`;
    return (await client.put<CostCenterDTO, AxiosResponse<CostCenterDTO>, CostCenterMutationDTO>(url, request)).data;
};

const deleteCostCenter = async (costCenterId: number): Promise<void> => {
    const url = API_BASE_URL + `/cost-centers/${costCenterId}`;
    await client.delete(url);
};

const importCostCenters = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/cost-centers/import`, request)).data;
};

export const costCenterAPI = {
    searchCostCenters,
    createCostCenter,
    updateCostCenter,
    deleteCostCenter,
    importCostCenters,
};
