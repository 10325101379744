import { ReorderItem } from '@/Components/reorder-modal/ReorderModal';

export type OrderMutation = {
    resourceId: number | string;
    order: number;
};

export const mapToReorderRequest = (reorderItems: ReorderItem[]): OrderMutation[] => {
    return reorderItems.map(item => ({
        resourceId: item.id,
        order: item.order,
    }));
};
