import { useAgGridWrapper } from '@/Components/ag-grid-wrapper/useAgGridWrapper';
import { DateFilter, DateSimpleFilterType, FiltersBarProps } from '@/Components/filters-bar/FiltersBar';
import { Footer } from '@/page/layout/Footer';
import { StackedAvatars } from '@/Components/stacked-avatar/StackedAvatars';
import { canManageReports } from '@/domain/permission/Permission.service';
import { AvailableReportGroupedField, Report, ReportFieldType, ReportRow, ReportSort } from '@/domain/report/Report.model';
import { getCustomFieldId, matchCustomFieldColumn } from '@/domain/report/Report.service';
import { ReportColumn, ReportGroupedColumn } from '@/page/report/report-columns-selector/ReportColumnsSelector';
import { ReportEditorBar, ReportFilterItemBar } from '@/page/report/report-editor-bar/ReportEditorBar';
import { ReportGrid, ReportGridRow } from '@/page/report/report-grid/ReportGrid';
import { useReportGroupedColumns } from '@/hooks/report/ReportColumns.hook';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { addDays, subMonths } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useDebounce from '@/hooks/Debounce.hook';
import { Share08Icon } from 'hugeicons-react';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { ColDef } from '@ag-grid-community/core';
import { toDate } from '@/utils/datetime.util';

type ReportGridPageProps = {
    report: Report;
    onSaveReport: (config: {
        filters: ReportFilterItemBar[];
        columns: ReportColumn[];
        highlightRange: [DateFilter, DateFilter] | [];
        sorts: ReportSort[];
    }) => void;
    rows: ReportRow[];
    onConfigChange: (filers: ReportFilterItemBar[], columns: ReportColumn[]) => void;
    filtersBarProps: FiltersBarProps<ReportFilterItemBar>;
    availableGroupedFields: AvailableReportGroupedField[];
    onOpenShareDialog: () => void;
};

export const ReportGridModePage: FC<ReportGridPageProps> = ({
    report,
    onSaveReport,
    rows,
    onConfigChange,
    filtersBarProps,
    availableGroupedFields,
    onOpenShareDialog,
}) => {
    const debounce = useDebounce();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const agGridWrapper = useAgGridWrapper<ReportGridRow>();

    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const isReportManager = canManageReports(grantedPolicies);

    const [groupedColumns, setGroupedColumns] = useReportGroupedColumns({
        availableGroups: availableGroupedFields ?? [],
        defaultColumns: report.fieldDefinitions ?? [],
    });

    const DEFAULT_HIGHLIGHT_MONTHS_AGO = 1;

    const [highlightRange, setHighlightRange] = useState<[DateSimpleFilterType, DateSimpleFilterType]>([
        {
            key: 'highlightStartDate',
            filterName: t('reports.highlight_filter_label'),
            type: 'date',
            dateType: 'WITHIN_THE_LAST',
            // one month ago by default
            value: toDate(report.historyStartDate) ?? subMonths(new Date(), DEFAULT_HIGHLIGHT_MONTHS_AGO),
            availableRules: ['WITHIN_THE_LAST'],
        },
        // V1 we don't display a range filter but a single date filter
        // So we set the end date to now
        {
            key: 'highlightEndDate',
            filterName: t('reports.highlight_filter_label'),
            type: 'date',
            dateType: 'MORE_THAN',
            // v1 we set the end date to tomorrow every time we load the report
            value: addDays(new Date(), 1),
            availableRules: ['MORE_THAN'],
        },
    ]);

    const handleHighlightRangeChange = ([from, to]: [DateSimpleFilterType, DateSimpleFilterType]) => {
        setHighlightRange([from, to]);
    };

    const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);

    /**
     * Override the filters change to trigger the editor change when the filters change
     * This will trigger an api call to get the preview rows
     */
    const handleFiltersChange = (filters: ReportFilterItemBar[]) => {
        filtersBarProps.onFiltersChange(filters);

        handleEditorChange(filters, groupedColumns);
    };

    const handleGroupedColumnsChange = (group: ReportGroupedColumn) => {
        // we don't have to change the columns state here, because the order will be changed by the handleEditorChange
        const updatedGroupedColumns = groupedColumns.map(g => (g.id === group.id ? group : g));
        handleEditorChange(filtersBarProps.filters, updatedGroupedColumns);
    };

    const handleEditorChange = (filters: ReportFilterItemBar[], groupedColumns: ReportGroupedColumn[]) => {
        const columnDefs = agGridWrapper.gridRef.current?.api?.getColumnDefs();
        // when we ask for a preview we have to update the columns order to keep the same order as the grid
        const groupsWithOrderSet = resetGroupedColumnsOrder(groupedColumns, columnDefs);
        setGroupedColumns(groupsWithOrderSet);
        // we have to debounce the call to avoid multiple calls when the user change the filters or the columns
        debounce(() => {
            onConfigChange(
                filters,
                groupedColumns.flatMap(g => g.columns),
            );
        });
    };

    const getSorts = (): ReportSort[] => {
        const columnsStateWithEnabledSort = agGridWrapper.gridRef.current?.api?.getColumnState().filter(columnState => !!columnState.sort) ?? [];

        return columnsStateWithEnabledSort.map<ReportSort>(columnState => {
            const colId = columnState.colId;

            const sectionFieldDefinitionId = getCustomFieldId(colId);
            return {
                direction: columnState.sort === 'asc' ? 'ASC' : 'DESC',
                fieldType: sectionFieldDefinitionId ? 'EMPLOYEE_CUSTOM_FIELD' : (columnState.colId as ReportFieldType),
                order: columnState.sortIndex ?? 0,
                sectionFieldDefinitionId: sectionFieldDefinitionId,
            };
        });
    };

    const handleSave = () => async () => {
        try {
            setSaveIsLoading(true);

            const columnDefs = agGridWrapper.gridRef.current?.api?.getColumnDefs();
            // We have to reset the order of the columns before saving to handle the case when the user change only the order of the columns
            const groupsWithOrderSet = resetGroupedColumnsOrder(groupedColumns, columnDefs);

            onSaveReport({
                filters: filtersBarProps.filters,
                columns: groupsWithOrderSet.flatMap(g => g.columns),
                highlightRange,
                sorts: getSorts(),
            });
        } catch (error) {
            handleError(error);
        }
        setSaveIsLoading(false);
    };

    const handleExport = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel();
    };

    return (
        <>
            <ContentContainer>
                <Stack gap={2} flex={1}>
                    <Paper sx={{ p: 1 }}>
                        {/* TODO split this component One for graph one for grid */}
                        <ReportEditorBar
                            filtersBarProps={{ ...filtersBarProps, onFiltersChange: handleFiltersChange }}
                            onSearch={agGridWrapper?.quickFilter}
                            readOnly={!isReportManager}
                            columnsSelectorProps={{
                                groups: groupedColumns,
                                onGroupChange: handleGroupedColumnsChange,
                            }}
                            highlightRangeProps={
                                report?.includeHistory
                                    ? {
                                          range: highlightRange,
                                          onRangeChange: handleHighlightRangeChange,
                                      }
                                    : undefined
                            }
                        />
                    </Paper>

                    <Stack flex={1}>
                        <ReportGrid
                            initRef={agGridWrapper.setGridRef}
                            gridId={'report' + report.id?.toString()}
                            columns={groupedColumns?.flatMap(g => g.columns) ?? []}
                            rows={rows ?? []}
                            compact
                            highlightRange={
                                report.includeHistory && highlightRange[0].value && highlightRange[1].value
                                    ? [highlightRange[0].value, highlightRange[1].value]
                                    : []
                            }
                            sorts={report.sorts ?? []}
                        />
                    </Stack>
                </Stack>
            </ContentContainer>
            <Footer justifyContent='space-between'>
                {report && (
                    <>
                        <Typography variant='body1bold'>{getLabelTranslation(report.title)}</Typography>

                        <Stack direction='row' gap={1} alignItems='center'>
                            <Typography variant='body1bold'>{t('reports.share.visible_to')}</Typography>
                            <StackedAvatars employeeAvatars={report.viewers} size='small' />
                            {isReportManager && (
                                <Button variant='text' onClick={() => onOpenShareDialog()}>
                                    {t('general.share')} <Share08Icon />
                                </Button>
                            )}

                            <Button variant='text' onClick={handleExport} disabled={!rows?.length}>
                                {t('general.download')}
                            </Button>
                            {isReportManager && (
                                <LoadingButton onClick={handleSave()} variant='contained' loading={saveIsLoading} disabled={saveIsLoading}>
                                    {t('general.save')}
                                </LoadingButton>
                            )}
                            {!isReportManager && <Button onClick={() => navigate('/reports')}>{t('general.back')}</Button>}
                        </Stack>
                    </>
                )}
            </Footer>
        </>
    );
};

/**
 * Resets the order of grouped columns based on the provided column definitions.
 *
 */
const resetGroupedColumnsOrder = (groups: ReportGroupedColumn[], columnDefs?: ColDef<ReportGridRow>[]): ReportGroupedColumn[] => {
    const resetColumnsOrder = (columns: ReportColumn[]): ReportColumn[] => {
        return columns.map(column => {
            const matchedColumnDef = columnDefs?.find(c => matchCustomFieldColumn(column, c));

            if (!matchedColumnDef) {
                throw new Error(`ColumnDef not found for column ${column.fieldType}`);
            }

            const order = columnDefs?.indexOf(matchedColumnDef);

            return {
                ...column,
                order: order ?? column.order,
            };
        });
    };

    return groups.map(group => ({
        ...group,
        columns: resetColumnsOrder(group.columns),
    }));
};
