import { Autocomplete, Stack, TextField } from '@mui/material';
import { SectionFieldContainer } from '@/page/people/on-boarding-form/SectionFieldContainer';
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SectionContainer } from '../SectionContainer';
import { PlanningPosition } from '@/domain/planning-position/PlanningPosition.model';
import { Planning } from '@/domain/planning/Planning.model';

export type PlanningFormValues = {
    planningPosition: { value: number; label: string };
    managerPlannings: { value: number; label: string }[];
    memberPlannings: { value: number; label: string }[];
};

type FormProps = {
    onSubmitForm: (data: PlanningFormValues) => void;
    plannings: Planning[];
    planningPositions: PlanningPosition[];
};
export const PlanningForm: FC<FormProps> = ({ onSubmitForm, plannings, planningPositions }) => {
    const { t } = useTranslation();

    const form = useForm<PlanningFormValues>({
        defaultValues: {
            planningPosition: undefined,
            managerPlannings: [],
            memberPlannings: [],
        },
    });

    const { control, handleSubmit } = form;

    const planningOptions = plannings?.map(p => ({ label: p.name, value: p.id }));
    const planningPositionsOptions = planningPositions?.map(p => ({ label: p.name, value: p.id }));

    return (
        <SectionContainer title={t('onboarding_form.planning_access')}>
            <form id='planning-form' onSubmit={handleSubmit(onSubmitForm, console.error)}>
                <FormProvider {...form}>
                    <Stack gap={2}>
                        {!!planningPositionsOptions && (
                            <SectionFieldContainer title={t('onboarding_form.planning_position')} formValueName='planningPosition'>
                                <Controller
                                    name='planningPosition'
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            value={field.value}
                                            onChange={(_, options) => {
                                                field.onChange(options);
                                            }}
                                            fullWidth
                                            options={planningPositionsOptions}
                                            getOptionLabel={option => option.label}
                                            renderInput={params => <TextField {...params} />}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                        />
                                    )}
                                />
                            </SectionFieldContainer>
                        )}
                        <SectionFieldContainer title={t('onboarding_form.manager_plannings')} formValueName='managerPlannings'>
                            <Controller
                                name='managerPlannings'
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        value={field.value}
                                        onChange={(_, options) => {
                                            field.onChange(options);
                                        }}
                                        fullWidth
                                        multiple
                                        options={planningOptions}
                                        getOptionLabel={option => option.label}
                                        renderInput={params => <TextField {...params} />}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                    />
                                )}
                            />
                        </SectionFieldContainer>
                        <SectionFieldContainer title={t('onboarding_form.member_plannings')} formValueName='memberPlannings'>
                            <Controller
                                name='memberPlannings'
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        value={field.value}
                                        onChange={(_, options) => {
                                            field.onChange(options);
                                        }}
                                        fullWidth
                                        multiple
                                        options={planningOptions}
                                        getOptionLabel={option => option.label}
                                        renderInput={params => <TextField {...params} />}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                    />
                                )}
                            />
                        </SectionFieldContainer>
                    </Stack>
                </FormProvider>
            </form>
        </SectionContainer>
    );
};
