import { FieldSwitch } from '@/Components/form/field-switch/FieldSwitch';
import { LoginMethod, LoginMethodType } from '@/domain/realm/Realm.model';
import { SectionContainer } from '@/page/people/on-boarding-form/SectionContainer';
import { SectionFieldContainer } from '@/page/people/on-boarding-form/SectionFieldContainer';
import { useAppSelector } from '@/stores/store';
import { UserLanguage } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Autocomplete, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type InviteFormValues = {
    sendInvitation: boolean;
    language: UserLanguage;
    loginMethod?: LoginMethod;
};

type OnBoardingInviteProps = {
    onSubmitInviteForm: (data: InviteFormValues) => void;
};

export const InviteForm: FC<OnBoardingInviteProps> = ({ onSubmitInviteForm }) => {
    const { t } = useTranslation();
    const realm = useAppSelector(state => state.ui.currentRealm);

    const schema = yup.object().shape({
        sendInvitation: yup.boolean().default(false),
        language: yup.string().required().oneOf(Object.values(UserLanguage)),

        loginMethod: yup
            .object()
            .shape({
                id: yup.number().required(),
                name: yup.string().required(),
                type: yup.string().required().oneOf(Object.values(LoginMethodType)),
            })
            .optional()
            .default(undefined)
            .test({
                test: value => {
                    return realm?.availableLoginMethods?.length === 1 || !!value;
                },
            }),
    });

    const form = useForm<InviteFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            sendInvitation: false,
            language: realm?.defaultLanguage,
            loginMethod: realm?.availableLoginMethods?.length === 1 ? realm.availableLoginMethods[0] : undefined,
        },
    });

    const isLoginMethodUnique = () => {
        return realm?.availableLoginMethods?.length === 1;
    };

    const { setValue, control, watch, handleSubmit } = form;

    const watchInviteUser = watch('sendInvitation');
    const watchLoginMethod = watch('loginMethod');

    useEffect(() => {
        if (watchLoginMethod && watchLoginMethod?.type !== LoginMethodType.STANDARD) {
            setValue('sendInvitation', false);
        }
    }, [setValue, watchLoginMethod]);

    if (!realm) {
        throw new Error('Realm is not defined');
    }

    const loginOptions = realm?.availableLoginMethods;
    const languages = Object.values(UserLanguage);
    const url = window.location.origin;

    return (
        <SectionContainer title={t('onboarding_form.access')}>
            <form id='invite-form' onSubmit={handleSubmit(onSubmitInviteForm, console.error)}>
                <FormProvider {...form}>
                    {isLoginMethodUnique() && realm.availableLoginMethods?.[0].type === LoginMethodType.STANDARD && (
                        <>
                            <FormControlLabel
                                label={t('onboarding_form.invite_your_employee_to_connect')}
                                labelPlacement='end'
                                control={<FieldSwitch name='sendInvitation' control={control} />}
                            />
                            {!watchInviteUser && <Alert severity='warning'>{t('onboarding_form.employee_invite_warning_message')}</Alert>}
                        </>
                    )}
                    {/* If the realm is mixed, we need to ask the user to choose a login method */}
                    {!isLoginMethodUnique() && (
                        <Stack gap={2}>
                            <SectionFieldContainer title={t('onboarding_form.login_method')} formValueName='loginMethod' required={!isLoginMethodUnique()}>
                                <Controller
                                    name='loginMethod'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Autocomplete
                                            disableClearable
                                            getOptionLabel={login => login?.name}
                                            value={field.value}
                                            onChange={(_, options) => {
                                                field.onChange(options);
                                            }}
                                            fullWidth
                                            options={loginOptions}
                                            renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                                        />
                                    )}
                                />
                            </SectionFieldContainer>

                            {!!watchLoginMethod &&
                                (watchLoginMethod?.type === LoginMethodType.STANDARD ? (
                                    <>
                                        <FormControlLabel
                                            control={<FieldSwitch name='sendInvitation' control={control} />}
                                            label={t('onboarding_form.invite_your_employee_to_connect')}
                                            labelPlacement='end'
                                        />
                                        {!watchInviteUser && <Alert severity='warning'>{t('onboarding_form.employee_invite_warning_message')}</Alert>}
                                    </>
                                ) : (
                                    <EmployeeInviteInfoMessage url={url} />
                                ))}
                        </Stack>
                    )}

                    <Stack paddingTop={2}>
                        <SectionFieldContainer title={t('onboarding_form.language')} formValueName='language' required={!isLoginMethodUnique()}>
                            <Controller
                                name='language'
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disableClearable
                                        getOptionLabel={lang => lang}
                                        value={field.value}
                                        onChange={(_, options) => {
                                            field.onChange(options);
                                        }}
                                        fullWidth
                                        options={languages}
                                        renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                                    />
                                )}
                            />
                        </SectionFieldContainer>
                    </Stack>
                    {isLoginMethodUnique() && realm?.availableLoginMethods?.[0].type !== LoginMethodType.STANDARD && <EmployeeInviteInfoMessage url={url} />}
                </FormProvider>
            </form>
        </SectionContainer>
    );
};

const EmployeeInviteInfoMessage: FC<{ url: string }> = ({ url }) => {
    return (
        <Alert severity='info'>
            <Trans i18nKey='onboarding_form.employee_invite_info_message' components={{ bold: <strong /> }} values={{ url }} />
        </Alert>
    );
};
