import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { getLanguageTranslationKey, UserLanguage } from '@/utils/language.util';
import { useTranslation } from 'react-i18next';
import { Globe02Icon } from 'hugeicons-react';

type Props = {
    onLanguageSelected: (lang: UserLanguage) => void;
    languages: UserLanguage[];
};

export const LanguageDropdown: FC<Props> = ({ onLanguageSelected, languages }) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const { t } = useTranslation();
    return (
        <>
            <Menu
                open={popupVisible}
                id='user-menu'
                anchorEl={anchorEl}
                keepMounted
                onClose={(event: Event) => {
                    event.stopPropagation();
                    setPopupVisible(false);
                }}
                anchorOrigin={{ vertical: 30, horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {!!languages?.length &&
                    languages.map((language: UserLanguage, index: number) => (
                        <MenuItem
                            key={index}
                            dense={true}
                            onClick={event => {
                                event.stopPropagation();
                                setPopupVisible(false);
                                onLanguageSelected(language);
                            }}
                        >
                            {t(getLanguageTranslationKey(language))}
                        </MenuItem>
                    ))}
            </Menu>
            <IconButton
                size='small'
                onClick={event => {
                    setAnchorEl(event.currentTarget);
                    event.stopPropagation();
                    setPopupVisible(true);
                }}
            >
                <Globe02Icon />
            </IconButton>
        </>
    );
};
