import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useEffect, useState } from 'react';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { getSectionDefinitions } from '@/domain/section-setting/Section.service';
import { handleError } from '@/utils/api.util';

export const useGetSectionDefinitions = (): UseQueryResult<SectionDefinition[]> => {
    const [sectionDefinitions, setSectionDefinitions] = useState<SectionDefinition[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchSectionDefinitions = useCallback(async () => {
        try {
            const sectionDefinitions = await getSectionDefinitions();
            setSectionDefinitions(sectionDefinitions);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchSectionDefinitions().catch(handleError);
    }, [fetchSectionDefinitions]);

    return {
        data: sectionDefinitions,
        setData: setSectionDefinitions,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchSectionDefinitions,
    };
};
