import { DialogContainer, DialogContainerProps } from '@/Components/dialog-container/DialogContainer';
import { Label, LabelRequest } from '@/domain/label/Label.model';
import { Report } from '@/domain/report/Report.model';
import { getLabelTranslation } from '@/utils/language.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type RenameReportDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    onSave: (title: Label) => void;
    report: Pick<Report, 'title'>;
};

type RenameReportFormValues = {
    title: string;
};

const schema = yup.object().shape({
    title: yup.string().required(),
});

export const RenameReportDialog: FC<RenameReportDialogProps> = ({ onSave, ...rest }) => {
    const { t } = useTranslation();
    const { handleSubmit, control } = useForm<RenameReportFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            title: getLabelTranslation(rest.report.title),
        },
    });

    const handleSave = (values: RenameReportFormValues) => {
        const title: LabelRequest = {
            translationEn: values.title,
            translationFr: values.title,
            translationDe: values.title,
            translationIt: values.title,
        };
        onSave(title);
    };

    return (
        <DialogContainer onSave={handleSubmit(handleSave, console.error)} title={t('reports.rename_report_dialog.title')} {...rest}>
            <Stack gap={2}>
                <Controller
                    name='title'
                    control={control}
                    defaultValue=''
                    render={({ field, fieldState }) => (
                        <FormControlLabel
                            label={t('general.title')}
                            control={<TextField autoFocus {...field} fullWidth error={!!fieldState.error} helperText={fieldState.error?.message} />}
                        />
                    )}
                />
            </Stack>
        </DialogContainer>
    );
};
