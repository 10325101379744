import { FC } from 'react';

import { Paper, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { canManageSurveys, canSeeSurveyResults } from '@/domain/permission/Permission.service';
import { useAppSelector } from '@/stores/store';

export const SurveysHeader: FC = () => {
    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const location = useLocation();

    return (
        <Paper
            sx={{
                borderRadius: 1,
            }}
        >
            <Tabs
                variant='scrollable'
                value={location.pathname}
                scrollButtons={false}
                onChange={(_event, newValue) => {
                    navigate(newValue);
                }}
            >
                {realm && canSeeSurveyResults(realm.realmFeatures, grantedPolicies) && (
                    <Tab label={t('survey_templates_page.surveys')} value={'/manage-surveys/surveys'} />
                )}
                {canManageSurveys(grantedPolicies) && <Tab label={t('survey_templates_page.templates')} value={'/manage-surveys/templates'} />}
            </Tabs>
        </Paper>
    );
};
