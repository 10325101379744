import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { AsyncSelectFilter, DateFilter, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { EmployeeFilterType, searchEmployees } from '@/domain/employee/Employee.service';
import { getLocations } from '@/domain/location/Location.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';
import { useTranslation } from 'react-i18next';
import { endOfYear } from 'date-fns';

export type BalanceTimesheetFilter = SelectFilter | AsyncSelectFilter | DateFilter;
export const useBalanceTimesheetPageFilters = (): { filters: BalanceTimesheetFilter[] } => {
    const { t } = useTranslation();
    const filters: BalanceTimesheetFilter[] = [
        {
            key: 'endDate',
            filterName: t('timesheets.period'),
            type: 'date',
            value: endOfYear(new Date()),
            dateType: 'MORE_THAN',
            availableRules: ['MORE_THAN'],
            clearable: false,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLocations().then(locations => {
                    return locations?.map(location => ({
                        label: location.name,
                        value: location.id,
                    }));
                });
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getDepartments().then(departments => {
                    return departments?.map(department => ({
                        label: getLabelTranslation(department.name),
                        value: department.id,
                    }));
                });
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getJobs().then(jobs => {
                    return jobs?.map(job => ({
                        label: getLabelTranslation(job.name),
                        value: job.id,
                    }));
                });
            },
            key: 'JOB_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'MANAGER_IDS' satisfies EmployeeFilterType,
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employees = await searchEmployees();
                return employees?.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
