import { UseQueryResult } from '@/page/Query.type';
import { useCallback, useState } from 'react';
import { Role, RoleSearch } from '@/domain/role/Role.model';
import { searchRoles } from '@/domain/role/Role.service';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { handleError } from '@/utils/api.util';

export const useGetRoles = (roleSearch: RoleSearch = {}): UseQueryResult<Role[]> => {
    const [roles, setRoles] = useState<Role[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchRoles = useCallback(async (roleSearch: RoleSearch) => {
        try {
            const rolesData = await searchRoles(roleSearch);
            setRoles(rolesData);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchRoles(roleSearch).catch(handleError);
    }, [roleSearch]);

    return {
        data: roles,
        setData: setRoles,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchRoles(roleSearch),
    };
};
