import {
    Autocomplete,
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Stack,
    StackProps,
    Switch,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { getAvailableContributorTypes } from '@/domain/review-template/ReviewTemplate.service';
import { useTranslation } from 'react-i18next';

import { FieldNumber } from '@/Components/form/field-number/FieldNumber';
import { FieldSwitch } from '@/Components/form/field-switch/FieldSwitch';
import { TranslatableLabelInput } from '@/Components/translatable-label-input/TranslatableLabelInput';
import { TranslatableRichTextEditor } from '@/Components/translatable-rich-text-editor/TranslatableRichTextEditor';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { ReviewTemplateItemType } from '@/domain/review-template/ReviewTemplate.model';
import { ContributorType } from '@/domain/review/Review.model';
import { ReviewBlockForm, ReviewTemplateFormType } from '@/page/setting/review/template/ReviewTemplateFormPage.schema';
import { duplicate, maxOrderNumber, remove, reorder, sort } from '@/utils/collections.util';
import { getLabelTranslation, getLocalizedErrorMessage, UserLanguage } from '@/utils/language.util';
import { Copy01Icon, Delete02Icon } from 'hugeicons-react';

type FormStepProps = {
    ratingsScale: ReviewRatingScale[] | undefined;
    translationLanguage: UserLanguage;
};

type CommonBlockProps = {
    onDelete: () => void;
    onDuplicate: () => void;
};

export const FormStep: FC<FormStepProps> = ({ ratingsScale, translationLanguage }) => {
    const {
        watch,
        control,
        formState: { errors },
    } = useFormContext<ReviewTemplateFormType>();

    const { fields, replace } = useFieldArray<ReviewTemplateFormType>({
        control,
        name: 'reviewBlocksForm',
    });

    const reviewBlocks = watch('reviewBlocksForm');

    const handleDelete = (index: number) => () => {
        const fieldsWithRemovedIndex = remove(reviewBlocks, index);
        const fieldsSorted = sort(fieldsWithRemovedIndex);
        const fieldsReordered = reorder(fieldsSorted);
        replace(fieldsReordered);
    };

    const handleDuplicate = (index: number) => () => {
        const fieldsDuplicated = duplicate(reviewBlocks, index);
        const fieldsDuplicatedWithID = fieldsDuplicated.map((field, i) => {
            if (i === index + 1) {
                return { ...field, temporaryId: uuidv4() };
            }
            return field;
        });
        const fieldsSorted = sort(fieldsDuplicatedWithID);
        const fieldsReordered = reorder(fieldsSorted);
        replace(fieldsReordered);
    };

    // todo: create a common variable for the block type, also create a function for the switch to improve readability, infer also the return type in this function
    const addBlock = (blockType: ReviewTemplateItemType) => {
        const blockOrder = maxOrderNumber(reviewBlocks) + 1;
        let reviewBlock: ReviewBlockForm | undefined = undefined;
        switch (blockType) {
            case 'SECTION':
                reviewBlock = {
                    selfReview: false,
                    managerPreparation: false,
                    peerFeedback: false,
                    upwardFeedback: false,
                    temporaryId: uuidv4(),
                    order: blockOrder,
                    blockType: 'SECTION',
                    sectionName: createDefaultLabel(),
                };
                break;
            case 'QUESTION':
                reviewBlock = {
                    temporaryId: uuidv4(),
                    order: blockOrder,
                    blockType: 'QUESTION',
                    questionName: createDefaultLabel(),
                    instructionEnabled: false,
                    instruction: createDefaultLabel(),
                    selfReview: false,
                    managerPreparation: false,
                    peerFeedback: false,
                    upwardFeedback: false,
                    required: false,
                    reviewScaleEnabled: false,
                    reviewRatingScaleId: undefined,
                };
                break;
            case 'OBJECTIVES':
                reviewBlock = {
                    temporaryId: uuidv4(),
                    order: blockOrder,
                    blockType: 'OBJECTIVES',
                    selfReview: false,
                    managerPreparation: false,
                    peerFeedback: false,
                    upwardFeedback: false,
                };
                break;
            case 'SKILLS':
                reviewBlock = {
                    temporaryId: uuidv4(),
                    order: blockOrder,
                    blockType: 'SKILLS',
                    selfReview: false,
                    managerPreparation: false,
                    peerFeedback: false,
                    upwardFeedback: false,
                    allSkillsRequired: true,
                    rangeSkillsRequired: false,
                    minSkills: undefined,
                    maxSkills: undefined,
                };
                break;
        }
        const newFields = [...reviewBlocks, reviewBlock].filter(Boolean) as ReviewBlockForm[];
        const fieldsSorted = sort(newFields);
        const reorderedFields = reorder(fieldsSorted);
        replace(reorderedFields);
    };

    return (
        <Stack gap={2}>
            {[...fields]
                .sort((a, b) => a.order - b.order)
                .map((item, index) => {
                    switch (item?.blockType) {
                        case 'SECTION':
                            return (
                                <SectionBlock
                                    controllerIndex={index}
                                    commonBlockProps={{
                                        onDelete: handleDelete(index),
                                        onDuplicate: handleDuplicate(index),
                                    }}
                                    key={item?.id}
                                    translationLanguage={translationLanguage}
                                />
                            );
                        case 'QUESTION':
                            return (
                                <QuestionBlock
                                    translationLanguage={translationLanguage}
                                    controllerIndex={index}
                                    key={item?.id}
                                    commonBlockProps={{
                                        onDelete: handleDelete(index),
                                        onDuplicate: handleDuplicate(index),
                                    }}
                                    ratingsScale={ratingsScale}
                                />
                            );
                        case 'OBJECTIVES':
                            return (
                                <ObjectiveBlock
                                    controllerIndex={index}
                                    key={item?.id}
                                    commonBlockProps={{
                                        onDelete: handleDelete(index),
                                        onDuplicate: handleDuplicate(index),
                                    }}
                                />
                            );
                        case 'SKILLS':
                            return (
                                <SkillsBlock
                                    controllerIndex={index}
                                    key={item?.id}
                                    commonBlockProps={{
                                        onDelete: handleDelete(index),
                                        onDuplicate: handleDuplicate(index),
                                    }}
                                />
                            );
                    }
                })}
            <Stack>
                <AddBlock onAddBlock={addBlock} reviewBlocks={reviewBlocks} />
                {errors.reviewBlocksForm && (
                    <FormHelperText sx={{ pl: 1 }} error>
                        {errors.reviewBlocksForm?.root?.message ?? errors.reviewBlocksForm?.message}
                    </FormHelperText>
                )}
            </Stack>
        </Stack>
    );
};

type AddBlockProps = {
    onAddBlock: (blockType: ReviewTemplateItemType) => void;
    reviewBlocks: ReviewBlockForm[];
};
export const AddBlock: FC<AddBlockProps> = ({ onAddBlock, reviewBlocks }) => {
    const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>();
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleClickItem = (event: MouseEvent) => {
        const value = event.currentTarget.getAttribute('value');
        onAddBlock(value as ReviewTemplateItemType);
        handleClose();
    };

    return (
        <>
            <Button sx={{ width: 'fit-content' }} onClick={handleClick}>
                {t('reviews_settings_page.review_template_form.add_block_button_title')}
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem value={'SECTION'} onClick={handleClickItem}>
                    {t('reviews_settings_page.review_template_form.section_block_title')}
                </MenuItem>
                <MenuItem value={'QUESTION'} onClick={handleClickItem}>
                    {t('reviews_settings_page.review_template_form.question_block_title')}
                </MenuItem>
                <MenuItem value={'OBJECTIVES'} onClick={handleClickItem} disabled={isAddBlockTypeDisabled(reviewBlocks, 'OBJECTIVES')}>
                    {t('reviews_settings_page.review_template_form.objectives_block_title')}
                </MenuItem>
                <MenuItem value={'SKILLS'} onClick={handleClickItem} disabled={isAddBlockTypeDisabled(reviewBlocks, 'SKILLS')}>
                    {t('reviews_settings_page.review_template_form.skills_block_title')}
                </MenuItem>
            </Menu>
        </>
    );
};

type SectionBlockProps = {
    commonBlockProps: CommonBlockProps;
    controllerIndex: number;
    translationLanguage: UserLanguage;
};
export const SectionBlock: FC<SectionBlockProps> = ({ commonBlockProps, controllerIndex, translationLanguage }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<ReviewTemplateFormType>();
    const controllerName = `reviewBlocksForm.${controllerIndex}` as const;
    const nameControl = `${controllerName}.sectionName` as const;
    const reviewTemplateFormValues = watch();
    const selfReviewStepName = `${controllerName}.selfReview` as const;
    const managerPreparationStepName = `${controllerName}.managerPreparation` as const;
    const peerFeedbackStepName = `${controllerName}.peerFeedback` as const;
    const upwardFeedbackStepName = `${controllerName}.upwardFeedback` as const;

    return (
        <Stack justifyContent={'space-between'} p={2} gap={1} component={Paper} elevation={1}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Contributors
                    title={t('reviews_settings_page.review_template_form.section')}
                    selfReviewControllerName={selfReviewStepName}
                    managerPreparationControllerName={managerPreparationStepName}
                    peerFeedbackControllerName={peerFeedbackStepName}
                    upwardFeedbackControllerName={upwardFeedbackStepName}
                    contributorsToShow={getAvailableContributorTypes(reviewTemplateFormValues)}
                    includePreparationStep={reviewTemplateFormValues?.includePreparationStep}
                />
                <DeleteAndDuplicate commonBlockProps={commonBlockProps} blockType={'SECTION'} blockName={controllerName} />
            </Stack>

            <Controller
                name={nameControl}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <TranslatableLabelInput
                        translationLanguage={translationLanguage}
                        onChange={value => {
                            field.onChange(value);
                        }}
                        value={field.value}
                        fullWidth
                        error={!!error}
                        helperText={getLocalizedErrorMessage(error, translationLanguage)}
                        placeholder={t('reviews_settings_page.review_template_form.section_editable_name')}
                        autoFocus
                        name={nameControl}
                    />
                )}
            />
        </Stack>
    );
};
type DeleteAndDuplicateProps = {
    commonBlockProps: CommonBlockProps;
    blockType: ReviewTemplateItemType;
    blockName: string;
};
export const DeleteAndDuplicate: FC<DeleteAndDuplicateProps> = ({ blockName, commonBlockProps, blockType }) => {
    const { watch } = useFormContext<ReviewTemplateFormType>();
    const reviewBlocks = watch('reviewBlocksForm');

    const { onDelete, onDuplicate } = commonBlockProps;
    const theme = useTheme();

    // Todo: when the icon button is the new one, instead of not showing the icon button if disabled, use disabled props to disable the button
    // Todo: when we remove the skill block, remove the condition for the skill block
    return (
        <Stack direction={'row'}>
            {!isAddBlockTypeDisabled(reviewBlocks, blockType) && (
                <IconButton name={`${blockName}_duplicate`} onClick={() => onDuplicate()} disabled={isAddBlockTypeDisabled(reviewBlocks, blockType)}>
                    <Copy01Icon />
                </IconButton>
            )}

            <IconButton name={`${blockName}_delete`} onClick={() => onDelete()}>
                <Delete02Icon color={theme.palette.text.primary} />
            </IconButton>
        </Stack>
    );
};

type QuestionBlockProps = {
    controllerIndex: number;
    commonBlockProps: CommonBlockProps;
    ratingsScale: ReviewRatingScale[] | undefined;
    translationLanguage: UserLanguage;
};
export const QuestionBlock: FC<QuestionBlockProps> = ({ controllerIndex, translationLanguage, commonBlockProps, ratingsScale }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<ReviewTemplateFormType>();
    const controllerName = `reviewBlocksForm.${controllerIndex}` as const;
    const selfReviewStepName = `${controllerName}.selfReview` as const;
    const managerPreparationStepName = `${controllerName}.managerPreparation` as const;
    const peerFeedbackStepName = `${controllerName}.peerFeedback` as const;
    const upwardFeedbackStepName = `${controllerName}.upwardFeedback` as const;
    const questionBlockControlName = `${controllerName}.questionName` as const;
    const reviewBlocksFormRequired = `${controllerName}.required` as const;
    const instructionEnabledName = `${controllerName}.instructionEnabled` as const;
    const instructionName = `${controllerName}.instruction` as const;
    const reviewTemplateFormValues = watch();
    const instructionEnabled = watch(instructionEnabledName);

    return (
        <Stack p={2} gap={1} component={Paper} elevation={1}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Contributors
                    title={t('reviews_settings_page.review_template_form.question_block_title')}
                    selfReviewControllerName={selfReviewStepName}
                    managerPreparationControllerName={managerPreparationStepName}
                    peerFeedbackControllerName={peerFeedbackStepName}
                    upwardFeedbackControllerName={upwardFeedbackStepName}
                    contributorsToShow={getAvailableContributorTypes(reviewTemplateFormValues)}
                    includePreparationStep={reviewTemplateFormValues?.includePreparationStep}
                />
                <DeleteAndDuplicate commonBlockProps={commonBlockProps} blockType={'QUESTION'} blockName={controllerName} />
            </Stack>

            <Stack gap={2}>
                <Controller
                    name={questionBlockControlName}
                    control={control}
                    render={({ field: { ref, value, onChange, ...restField }, fieldState: { error } }) => (
                        <TranslatableLabelInput
                            {...restField}
                            translationLanguage={translationLanguage}
                            onChange={value => {
                                onChange(value);
                            }}
                            value={value}
                            inputRef={ref}
                            InputProps={{ multiline: true }}
                            placeholder={t('reviews_settings_page.review_template_form.what_would_you_like_to_ask')}
                            fullWidth
                            error={!!error}
                            helperText={getLocalizedErrorMessage(error, translationLanguage)}
                        />
                    )}
                />

                {instructionEnabled && (
                    <Controller
                        name={instructionName}
                        control={control}
                        render={({ field, fieldState }) => {
                            return (
                                <TranslatableRichTextEditor
                                    minHeight={'120px'}
                                    onUpdate={field.onChange}
                                    value={field.value ?? createDefaultLabel()}
                                    translationLanguage={translationLanguage}
                                    name={'instructionName'}
                                    errorMessage={getLocalizedErrorMessage(fieldState.error, translationLanguage)}
                                />
                            );
                        }}
                    />
                )}

                <Stack direction={'row'} gap={1}>
                    <FormControlLabel
                        labelPlacement='end'
                        label={t('reviews_settings_page.review_template_form.switch_instruction')}
                        control={<FieldSwitch control={control} name={instructionEnabledName} />}
                    />

                    <FormControlLabel
                        labelPlacement='end'
                        label={t('reviews_settings_page.review_template_form.switch_required_question')}
                        control={<FieldSwitch control={control} name={reviewBlocksFormRequired} />}
                    />
                    <ReviewScale controllerName={controllerName} ratingsScale={ratingsScale} />
                </Stack>
            </Stack>
        </Stack>
    );
};

type PrivateQuestionBlockProps = {
    privateType: 'MANAGER' | 'EMPLOYEE';
    controllerIndex: number;
    commonBlockProps: CommonBlockProps;
    ratingsScale: ReviewRatingScale[] | undefined;
    translationLanguage: UserLanguage;
};

export const PrivateQuestionBlock: FC<PrivateQuestionBlockProps> = ({ controllerIndex, privateType, ratingsScale, commonBlockProps, translationLanguage }) => {
    const { control, watch } = useFormContext<ReviewTemplateFormType>();
    const { t } = useTranslation();
    const privateName = privateType === 'MANAGER' ? 'privateManagerQuestions' : 'privateEmployeeQuestions';
    const controllerName = `${privateName}.${controllerIndex}` as const;
    const questionBlockControlName = `${controllerName}.questionName` as const;
    const requiredControlName = `${controllerName}.required` as const;
    const instructionEnabledName = `${controllerName}.instructionEnabled` as const;
    const instructionName = `${controllerName}.instruction` as const;
    const instructionEnabled = watch(instructionEnabledName);

    return (
        <Paper elevation={1}>
            <Stack p={2} gap={1}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant={'h2'}>{t('reviews_settings_page.review_template_form.question_block_title')}</Typography>

                    <DeleteAndDuplicate commonBlockProps={commonBlockProps} blockType={'QUESTION'} blockName={controllerName} />
                </Stack>

                <Stack gap={2}>
                    <Controller
                        name={questionBlockControlName}
                        control={control}
                        render={({ field: { ref, onChange, value, ...restField }, fieldState: { error } }) => (
                            <TranslatableLabelInput
                                {...restField}
                                translationLanguage={translationLanguage}
                                onChange={value => {
                                    onChange(value);
                                }}
                                value={value}
                                inputRef={ref}
                                InputProps={{ multiline: true }}
                                helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                placeholder={t('reviews_settings_page.review_template_form.what_would_you_like_to_ask')}
                                fullWidth
                                error={!!error}
                            />
                        )}
                    />

                    {instructionEnabled && (
                        <Controller
                            name={instructionName}
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <TranslatableRichTextEditor
                                        minHeight={'120px'}
                                        onUpdate={field.onChange}
                                        value={field.value}
                                        translationLanguage={translationLanguage}
                                        name={instructionName}
                                        errorMessage={getLocalizedErrorMessage(fieldState.error, translationLanguage)}
                                    />
                                );
                            }}
                        />
                    )}
                    <Stack direction={'row'}>
                        <FormControlLabel
                            labelPlacement='end'
                            label={t('reviews_settings_page.review_template_form.switch_instruction')}
                            control={<FieldSwitch control={control} name={instructionEnabledName} />}
                        />

                        <FormControlLabel
                            labelPlacement='end'
                            label={t('reviews_settings_page.review_template_form.switch_required_question')}
                            control={<FieldSwitch control={control} name={requiredControlName} />}
                        />
                        <ReviewScale controllerName={controllerName} ratingsScale={ratingsScale} />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};

type ReviewScaleProps = {
    controllerName: `reviewBlocksForm.${number}` | `privateManagerQuestions.${number}` | `privateEmployeeQuestions.${number}`;
    ratingsScale: ReviewRatingScale[] | undefined;
};
export const ReviewScale: FC<ReviewScaleProps> = ({ controllerName, ratingsScale }) => {
    const { control, watch } = useFormContext<ReviewTemplateFormType>();
    const { t } = useTranslation();
    const reviewScaleEnabledName = `${controllerName}.reviewScaleEnabled` as const;
    const reviewScaleIdName = `${controllerName}.reviewRatingScaleId` as const;
    const isReviewScaleEnabled = watch(reviewScaleEnabledName);

    return (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
            <Controller
                name={reviewScaleEnabledName}
                control={control}
                render={({ field }) => (
                    <FormControlLabel
                        labelPlacement='end'
                        label={t('reviews_settings_page.review_template_form.switch_review_scale')}
                        control={<Switch {...field} color='primary' checked={field.value} />}
                    />
                )}
            />
            {isReviewScaleEnabled && (
                <Controller
                    name={reviewScaleIdName}
                    control={control}
                    render={({ field: { ref, value, ...restField }, fieldState }) => (
                        <Autocomplete
                            {...restField}
                            value={value as number} // todo fix type inference
                            sx={{ width: '430px' }}
                            onChange={(_, value) => restField.onChange(value)}
                            disableClearable={true}
                            fullWidth
                            options={(ratingsScale ?? []).map(item => item.id)}
                            getOptionLabel={item => {
                                const ratingScale = ratingsScale?.find(ratingScale => ratingScale.id === item);
                                return getLabelTranslation(ratingScale?.name) ?? '';
                            }}
                            isOptionEqualToValue={(reviewTypeOption, reviewTypeValue) => reviewTypeOption === reviewTypeValue}
                            renderInput={params => (
                                <TextField {...params} inputRef={ref} error={!!fieldState.error} helperText={fieldState.error?.message?.toString()} />
                            )}
                        />
                    )}
                />
            )}
        </Stack>
    );
};
type ContributorsProps = {
    title: string;
    selfReviewControllerName: `reviewBlocksForm.${number}.selfReview`;
    managerPreparationControllerName: `reviewBlocksForm.${number}.managerPreparation`;
    peerFeedbackControllerName: `reviewBlocksForm.${number}.peerFeedback`;
    upwardFeedbackControllerName: `reviewBlocksForm.${number}.upwardFeedback`;
    contributorsToShow: ContributorType[];
    includePreparationStep: boolean;
} & StackProps;
export const Contributors: FC<ContributorsProps> = ({
    title,
    selfReviewControllerName,
    managerPreparationControllerName,
    peerFeedbackControllerName,
    upwardFeedbackControllerName,
    contributorsToShow,
    includePreparationStep,
    ...rest
}) => {
    const { control } = useFormContext<ReviewTemplateFormType>();

    const { t } = useTranslation();

    // Todo: use context for labels
    const getTypeLabel = (type: ContributorType) => {
        switch (type) {
            case 'SELF':
                return t('reviews_settings_page.review_template_form.square_text_checkbox_employee_title');
            case 'MANAGER':
                return t('reviews_settings_page.review_template_form.square_text_checkbox_manager_title');
            case 'PEER':
                return t('reviews_settings_page.review_template_form.square_text_checkbox_peers_title');
            case 'UPWARD':
                return t('reviews_settings_page.review_template_form.square_text_checkbox_direct_reports_title');
        }
    };

    const showSelf = contributorsToShow.includes('SELF');
    const showManager = contributorsToShow.includes('MANAGER') && contributorsToShow.includes('SELF');
    const showPeer = contributorsToShow.includes('PEER');
    const showUpward = contributorsToShow.includes('UPWARD');

    return (
        <Stack direction={'row'} gap={2} alignItems={'center'} {...rest}>
            <Typography variant={'h2'}>{title}</Typography>
            {includePreparationStep && (
                <Stack gap={1} direction={'row'}>
                    {showSelf && (
                        <Controller
                            name={selfReviewControllerName}
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    label={getTypeLabel('SELF')}
                                    labelPlacement='end'
                                    name={selfReviewControllerName}
                                    control={<Checkbox {...field} color='primary' checked={field.value} />}
                                />
                            )}
                        />
                    )}
                    {showManager && (
                        <Controller
                            name={managerPreparationControllerName}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    label={getTypeLabel('MANAGER')}
                                    labelPlacement='end'
                                    name={managerPreparationControllerName}
                                    control={<Checkbox color='primary' checked={value} onChange={onChange} />}
                                />
                            )}
                        />
                    )}

                    {showPeer && (
                        <Controller
                            name={peerFeedbackControllerName}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    label={getTypeLabel('PEER')}
                                    labelPlacement='end'
                                    name={peerFeedbackControllerName}
                                    control={<Checkbox color='primary' checked={value} onChange={onChange} />}
                                />
                            )}
                        />
                    )}

                    {showUpward && (
                        <Controller
                            name={upwardFeedbackControllerName}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    label={getTypeLabel('UPWARD')}
                                    labelPlacement='end'
                                    name={upwardFeedbackControllerName}
                                    control={<Checkbox color='primary' checked={value} onChange={onChange} />}
                                />
                            )}
                        />
                    )}
                </Stack>
            )}
        </Stack>
    );
};

type SkillsBlockProps = {
    commonBlockProps: CommonBlockProps;
    controllerIndex: number;
};
export const SkillsBlock: FC<SkillsBlockProps> = ({ commonBlockProps, controllerIndex }) => {
    const { control, watch, setValue } = useFormContext<ReviewTemplateFormType>();
    const { t } = useTranslation();
    const controllerName = `reviewBlocksForm.${controllerIndex}` as const;
    const selfReviewStepName = `${controllerName}.selfReview` as const;
    const managerPreparationStepName = `${controllerName}.managerPreparation` as const;
    const peerFeedbackStepName = `${controllerName}.peerFeedback` as const;
    const upwardFeedbackStepName = `${controllerName}.upwardFeedback` as const;
    const allSkillsRequiredName = `${controllerName}.allSkillsRequired` as const;
    const rangeSkillsRequiredName = `${controllerName}.rangeSkillsRequired` as const;
    const minSkillsName = `${controllerName}.minSkills` as const;
    const maxSkillsName = `${controllerName}.maxSkills` as const;
    const reviewTemplateFormValues = watch();
    const reviewBlocksFormRequiredValue = watch(allSkillsRequiredName);
    const reviewRequiredForRangeValue = watch(rangeSkillsRequiredName);

    return (
        <Stack p={2} gap={1} component={Paper} elevation={1}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Contributors
                    title={t('reviews_settings_page.review_template_form.skills_block_title')}
                    selfReviewControllerName={selfReviewStepName}
                    managerPreparationControllerName={managerPreparationStepName}
                    peerFeedbackControllerName={peerFeedbackStepName}
                    upwardFeedbackControllerName={upwardFeedbackStepName}
                    contributorsToShow={getAvailableContributorTypes(reviewTemplateFormValues)}
                    includePreparationStep={reviewTemplateFormValues?.includePreparationStep}
                />
                <DeleteAndDuplicate commonBlockProps={commonBlockProps} blockType={'SKILLS'} blockName={controllerName} />
            </Stack>

            <FormControlLabel
                labelPlacement='end'
                label={t('reviews_settings_page.review_template_form.switch_required_all_skills')}
                control={<FieldSwitch control={control} name={allSkillsRequiredName} />}
            />

            {!reviewBlocksFormRequiredValue && (
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FormControlLabel
                        labelPlacement='end'
                        label={t('reviews_settings_page.review_template_form.switch_required_range')}
                        control={
                            <FieldSwitch
                                control={control}
                                name={rangeSkillsRequiredName}
                                onChange={() => {
                                    setValue(minSkillsName, undefined);
                                    setValue(maxSkillsName, undefined);
                                }}
                            />
                        }
                    />

                    <FieldNumber name={minSkillsName} disabled={!reviewRequiredForRangeValue} control={control} min={1} max={6} sx={{ minWidth: '80px' }} />

                    <Typography>{t('reviews_settings_page.review_template_form.at_least_after_text')}</Typography>

                    <FieldNumber name={maxSkillsName} disabled={!reviewRequiredForRangeValue} control={control} min={1} max={6} sx={{ minWidth: '80px' }} />

                    <Typography>{t('reviews_settings_page.review_template_form.at_most_after_text')}</Typography>
                </Stack>
            )}
        </Stack>
    );
};

type ObjectiveBlockProps = {
    controllerIndex: number;
    commonBlockProps: CommonBlockProps;
};
export const ObjectiveBlock: FC<ObjectiveBlockProps> = ({ controllerIndex, commonBlockProps }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext<ReviewTemplateFormType>();
    const controllerName = `reviewBlocksForm.${controllerIndex}` as const;
    const selfReviewStepName = `${controllerName}.selfReview` as const;
    const managerPreparationStepName = `${controllerName}.managerPreparation` as const;
    const peerFeedbackStepName = `${controllerName}.peerFeedback` as const;
    const upwardFeedbackStepName = `${controllerName}.upwardFeedback` as const;
    const reviewTemplateFormValues = watch();

    return (
        <Stack p={2} gap={1} component={Paper} elevation={1}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Contributors
                    title={t('reviews_settings_page.review_template_form.objectives_block_title')}
                    selfReviewControllerName={selfReviewStepName}
                    managerPreparationControllerName={managerPreparationStepName}
                    peerFeedbackControllerName={peerFeedbackStepName}
                    upwardFeedbackControllerName={upwardFeedbackStepName}
                    contributorsToShow={getAvailableContributorTypes(reviewTemplateFormValues)}
                    includePreparationStep={reviewTemplateFormValues?.includePreparationStep}
                />
                <DeleteAndDuplicate commonBlockProps={commonBlockProps} blockType={'OBJECTIVES'} blockName={controllerName} />
            </Stack>
        </Stack>
    );
};

const isAddBlockTypeDisabled = (reviewBlocks: ReviewBlockForm[], blockType: ReviewTemplateItemType) => {
    switch (blockType) {
        case 'OBJECTIVES':
            return reviewBlocks.some(block => block.blockType === 'OBJECTIVES');
        case 'SKILLS':
            return reviewBlocks.some(block => block.blockType === 'SKILLS');
    }
};
