import { EmployeeWorkingPatternType } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { RealmFeature, RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { WorkingPatternTemplate, WorkingPatternType } from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { DayOfWeek, getLocalDateTestConfig, LocalDate } from '@/utils/datetime.util';
import { getNull } from '@/utils/object.util';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getWorkPatternSchema = (realmFeatures: RealmFeature[]) => {
    return yup.object().shape({
        startDate: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
        type: yup.string().required().oneOf(Object.values(EmployeeWorkingPatternType)),
        rate: yup.number().when('type', {
            is: (type: EmployeeWorkingPatternType) => type === EmployeeWorkingPatternType.RATE,
            then: schema => schema.required().max(100).min(1),
            otherwise: schema => schema.nullable(),
        }),
        timesheetSettingId: yup.number().when({
            is: () => isTimesheetSettingIdRequired(realmFeatures),
            then: schema => schema.required(),
            otherwise: schema => schema,
        }),
        weeklyWorkingTimeId: yup.number(),
        // if type is template, then workingPatternTemplate is required
        workingPatternTemplate: workingPatternTemplateSchema.when('type', {
            is: (type: EmployeeWorkingPatternType) => type === EmployeeWorkingPatternType.TEMPLATE,
            then: schema => schema.required(),
            otherwise: schema => schema.nullable(),
        }),
        morningWorkingDays: yup
            .array()
            .of(yup.string().oneOf(Object.values(DayOfWeek)).required())
            .required(),
        afternoonWorkingDays: yup
            .array()
            .required()
            .of(yup.string().oneOf(Object.values(DayOfWeek)).required()),
        calendarId: yup.number().required(),
    });
};

export type EmployeeWorkingPatternFormValues = yup.InferType<ReturnType<typeof getWorkPatternSchema>>;

const workingPatternTemplateSchema = yup
    .object()
    .default(getNull())
    .shape({
        id: yup.number().required(),
        name: yup.string().required(),
        workingPatternType: yup.string().required().oneOf(Object.values(WorkingPatternType)),
        workingPatternTemplateWeekDays: yup.array().required() as unknown as yup.Schema<WorkingPatternTemplate['workingPatternTemplateWeekDays']>,
        weeklyWorkingTime: yup.object().required() as unknown as yup.Schema<WorkingPatternTemplate['weeklyWorkingTime']>,
    });

export const isTimesheetSettingIdRequired = (realmFeatures: RealmFeature[]): boolean => {
    return hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.TIMESHEET) || hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.LEAVES);
};
