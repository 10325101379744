import { DisplayCounter, LeaveActivityType, LeaveTypePolicy, RequestIncrement } from '@/domain/leave-type/LeaveType.model';
import { RoundingType } from '@/utils/math.util';
import * as yup from 'yup';
import { UnitType } from '../date/Date.model';
import { AllowanceType, CarryoverType, RequestCalculationType } from './LeaveType.model';

export const leaveTypeSchema = yup.object().shape({
    id: yup.number().required(),
    unitType: yup.mixed<UnitType>().required(),
    allowanceType: yup.mixed<AllowanceType>().required(),
    leaveActivityType: yup.mixed<LeaveActivityType>().required(),
    employeeRequestMessage: yup.string(),
    requestIncrement: yup.mixed<RequestIncrement>().when(['unitType'], {
        is: (unitType: UnitType) => unitType === UnitType.HOURS,
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
    }),
    maxDurationInMinutes: yup.number(),
    displayUnitType: yup.mixed<UnitType>().required(),
    roundingType: yup.mixed<RoundingType>().required(),
    title: yup.string().required(),
    attachmentRequiredAfterDays: yup.number(),
    color: yup.string(),
    requestCalculationType: yup.mixed<RequestCalculationType>().required(),
    policies: yup.array().of(yup.mixed<LeaveTypePolicy>().required()).required(),
    displayCounter: yup.mixed<DisplayCounter>().required(),
    includeInPayroll: yup.boolean().required(),
    order: yup.number().required(),
    carryoverType: yup.mixed<CarryoverType>().required(),
    prorateBasedOnContract: yup.boolean().required(),
    prorateBasedOnEmployeeStartDate: yup.boolean().required(),
    allowAttachments: yup.boolean().required(),
    availableInSelfService: yup.boolean().required(),
    allowOvertime: yup.boolean().required(),
});
