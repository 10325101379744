import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { AsyncSelectFilter, SelectFilter as SelectFilterType, SelectFilterOption } from '@/Components/filters-bar/FiltersBar';
import { useTranslation } from 'react-i18next';
import { usePopperlessAutocomplete } from '@/Components/autocomplete-wrapper/usePopperlessAutocomplete';
import { Autocomplete, FormControlLabel, List, ListItem, Radio, RadioGroup, Stack } from '@mui/material';
import { CheckboxAutocompleteOption } from '@/Components/autocomplete-wrapper/CheckboxAutocompleteOption';

export const SelectFilter: FC<{
    filter: SelectFilterType | AsyncSelectFilter;
    onFilterUpdated: (filter: SelectFilterType | AsyncSelectFilter) => void;
}> = ({ filter, onFilterUpdated }) => {
    const { t } = useTranslation();

    const availableRulesOptions: {
        type: SelectFilterType['rule'];
        label: string;
    }[] = filter.availableRules?.map(rule => ({
        type: rule,
        label: t('filters_bar.rules.enum', { context: rule }),
    }));

    const autocompleteProps = usePopperlessAutocomplete();

    const [selectOptions, setSelectOptions] = useState<SelectFilterOption[]>([]);
    const [selectOptionsLoading, setSelectOptionsLoading] = useState(true);

    const isOptionChecked = (option: SelectFilterOption, values: SelectFilterOption[] = []) => {
        return values?.some(value => value.value?.toString() === option.value.toString()) ?? false;
    };

    const handleMultiSelectChange = (filter: SelectFilterType | AsyncSelectFilter, option: SelectFilterOption) => {
        const value = isOptionChecked(option, filter.value)
            ? (filter.value ?? []).filter(value => value.value !== option.value)
            : [...(filter.value ?? []), option];
        const updatedFilter = {
            ...filter,
            value: value.length ? value : undefined,
        };
        onFilterUpdated(updatedFilter);
    };

    const handleSingleSelectChange = (filter: SelectFilterType | AsyncSelectFilter, option: SelectFilterOption) => {
        const updatedFilter = { ...filter, value: isOptionChecked(option, filter.value) ? undefined : [option] };
        onFilterUpdated(updatedFilter);
    };
    const handleSelectChange = (option: SelectFilterOption) => {
        const numberOfSelectedValues = filter.value?.length ?? 0;
        const isCurrentOptionSelected = isOptionChecked(option, filter.value);
        //dont allow to unset the last value in case the filter is not clearable
        if (filter.clearable === false && isCurrentOptionSelected && numberOfSelectedValues === 1) {
            return;
        }
        switch (filter.type) {
            case 'select':
                return handleSingleSelectChange(filter, option);
            case 'multi-select':
                return handleMultiSelectChange(filter, option);
        }
    };

    useEffect(() => {
        if (!selectOptions?.length && selectOptionsLoading) {
            if (filter.selectMode === 'SYNC') {
                setSelectOptionsLoading(false);
                setSelectOptions(filter.options ?? []);
            }
            if (filter.selectMode === 'ASYNC') {
                filter.fetchOptions().then(options => {
                    setSelectOptions(options);
                    setSelectOptionsLoading(false);
                });
            }
        }
    }, [filter, filter.type, onFilterUpdated, selectOptions?.length, selectOptionsLoading]);

    const handleRuleChange = (rule: SelectFilterType['rule']) => {
        const updatedFilter = { ...filter, rule };
        onFilterUpdated(updatedFilter);
    };

    return (
        <Stack gap={0.5} p={1}>
            {!!availableRulesOptions?.length && (
                <RadioGroup value={filter.rule ?? ''}>
                    <List disablePadding dense>
                        {availableRulesOptions.map(option => (
                            <ListItem key={option.type}>
                                <FormControlLabel
                                    value={option.type}
                                    control={
                                        <Radio
                                            edge='start'
                                            disableRipple
                                            size='small'
                                            onChange={e => {
                                                handleRuleChange(e.target.value as SelectFilterType['rule']);
                                            }}
                                            sx={{
                                                py: 0,
                                            }}
                                        />
                                    }
                                    labelPlacement='end'
                                    label={option.label}
                                    sx={{
                                        m: 0,
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </RadioGroup>
            )}
            <Autocomplete
                value={selectOptions.filter(option => isOptionChecked(option, filter.value))}
                multiple
                loading={selectOptionsLoading}
                options={selectOptions}
                getOptionLabel={option => option.label}
                onChange={(event, _option, _reason, detail) => {
                    if (event.type === 'keydown') {
                        const e = event as KeyboardEvent<HTMLDivElement>;
                        if (e.code === 'Backspace') {
                            return;
                        }
                    }
                    if (detail?.option) {
                        handleSelectChange(detail.option);
                    }
                }}
                getOptionKey={option => option.value}
                fullWidth
                renderOption={(props, option, { selected }) => <CheckboxAutocompleteOption {...props} selected={selected} label={option.label} />}
                {...autocompleteProps}
            />
        </Stack>
    );
};
