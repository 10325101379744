import { StateHandler } from '@/Components/state-handler/StateHandler';
import { Calendar } from '@/domain/calendar/Calendar.model';
import { EmployeeWorkingPatternType } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import {
    AfternoonWorkingDayField,
    EmployeeWorkingPatternCalendarField,
    EmployeeWorkingPatternField,
    EmployeeWorkingPatternRateField,
    EmployeeWorkingPatternTypeField,
    HoursPerWeek,
    MorningWorkingDayField,
    RateInfos,
    WeeklyWorkingTimeField,
} from '@/page/employee-profile/employee-profile-info/EmpoyeeWorkPatternSection/Components/EmployeeWorkingPatternDialog';
import { SectionContainer } from '@/page/people/on-boarding-form/SectionContainer';
import { SectionFieldContainer } from '@/page/people/on-boarding-form/SectionFieldContainer';
import { useSearchTimesheetSettings } from '@/page/setting/time-management/TimesheetSettings.hook';
import { defaultToNull } from '@/utils/object.util';
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const EmployeeWorkingPatternSection: FC<{
    weeklyWorkingTimes: WeeklyWorkingTime[];
    calendars: Calendar[];
}> = ({ weeklyWorkingTimes, calendars }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();

    const selectedEmployeeWorkingPatternType = watch('type');
    const selectedWeeklyWorkingTimeId = watch('weeklyWorkingTimeId');

    return (
        <SectionContainer title={t('employee.sections.work_pattern')}>
            <EmployeeWorkingPatternTypeField />
            {selectedEmployeeWorkingPatternType === EmployeeWorkingPatternType.TEMPLATE && (
                <Stack key={'workingPattern'} spacing={1} flex={1} justifyContent='space-between' alignItems={'center'} direction='row'>
                    <Typography variant='body1bold' noWrap flex={{ xs: 6, sm: 4, md: 2 }}>
                        {t('employee.work_pattern.dialog.template')}
                    </Typography>

                    <Box display={'flex'} flex={{ xs: 6, sm: 8, md: 10 }}>
                        <EmployeeWorkingPatternField />
                    </Box>
                </Stack>
            )}

            <SectionFieldContainer formValueName={'calendar'} title={t('employee.work_pattern.calendar')}>
                <EmployeeWorkingPatternCalendarField calendars={calendars} />
            </SectionFieldContainer>

            {selectedEmployeeWorkingPatternType === EmployeeWorkingPatternType.FIXED && (
                <>
                    {weeklyWorkingTimes.length > 1 && (
                        <SectionFieldContainer formValueName={'weeklyWorkingTimeId'} title={t('employee.work_pattern.dialog.weekly_working_time')}>
                            <WeeklyWorkingTimeField weeklyWorkingTimes={weeklyWorkingTimes} />
                        </SectionFieldContainer>
                    )}

                    {selectedWeeklyWorkingTimeId && (
                        <Stack direction='column'>
                            <SectionFieldContainer formValueName={'morningWorkingDays'} title={t('domain.day_period.morning')} alignItems={'flex-end'}>
                                <Stack direction='row' display={'flex'} gap={2} alignItems={'flex-end'}>
                                    <MorningWorkingDayField />
                                    <RateInfos />
                                </Stack>
                            </SectionFieldContainer>

                            <SectionFieldContainer formValueName={'afternoonWorkingDays'} title={t('domain.day_period.afternoon')} alignItems={'flex-end'}>
                                <Stack direction='row' display={'flex'} gap={2} alignItems={'flex-end'} marginTop={-2.5}>
                                    <AfternoonWorkingDayField />
                                    <HoursPerWeek weeklyWorkingTimes={weeklyWorkingTimes} />
                                </Stack>
                            </SectionFieldContainer>
                        </Stack>
                    )}
                </>
            )}
            {selectedEmployeeWorkingPatternType === EmployeeWorkingPatternType.RATE && (
                <>
                    {weeklyWorkingTimes.length > 1 && (
                        <SectionFieldContainer formValueName={'weeklyWorkingTimeId'} title={t('employee.work_pattern.dialog.weekly_working_time')}>
                            <WeeklyWorkingTimeField weeklyWorkingTimes={weeklyWorkingTimes} />
                        </SectionFieldContainer>
                    )}
                    <SectionFieldContainer formValueName={'rate'} title={t('employee.work_pattern.dialog.rate')}>
                        <EmployeeWorkingPatternRateField />
                    </SectionFieldContainer>
                </>
            )}
            <EmployeeTimesheetSetting />
        </SectionContainer>
    );
};

const EmployeeTimesheetSetting: FC = () => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext<{
        timesheetSettingId: number;
    }>();

    const { data: timesheetSettings, isLoading, isError, error } = useSearchTimesheetSettings();

    useEffect(() => {
        if (timesheetSettings?.length === 1) {
            setValue('timesheetSettingId', timesheetSettings[0].id);
        }
    }, [setValue, timesheetSettings]);

    if (timesheetSettings?.length === 1) {
        return undefined;
    }

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <Stack direction='row' spacing={1}>
                <SectionFieldContainer formValueName={'timesheetSettingId'} title={`${t('employee.timesheet_setting')}*`}>
                    <Controller
                        name={`timesheetSettingId`}
                        control={control}
                        render={({ field: { onChange, ...field }, fieldState }) => (
                            <Autocomplete
                                onChange={(_, newValue) => {
                                    onChange?.(defaultToNull(newValue?.id));
                                }}
                                getOptionLabel={option => option.name}
                                value={defaultToNull(timesheetSettings?.find(option => option.id === field.value))}
                                options={timesheetSettings ?? []}
                                fullWidth
                                renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        )}
                    />
                </SectionFieldContainer>
            </Stack>
        </StateHandler>
    );
};
