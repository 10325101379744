import { UnitType } from '@/domain/date/Date.model';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import { Paper, Stack } from '@mui/material';
import { FC } from 'react';

import { AgGridWrapper, RogerColDef } from '@/Components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/Components/ag-grid-wrapper/useAgGridWrapper';
import { DatatableAdditionalAction } from '@/Components/datatable-additional-action/DatatableAdditionalAction';
import { DateRangePicker } from '@/Components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/Components/date-range-picker/DateRangePicker.hook';
import { AsyncSelectFilter, FiltersBar, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { getNestedValueByPath, getSelectFilterNumberValues } from '@/Components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/Components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { isValidEmployeeFilterType, doesEmployeeMatchFilter } from '@/domain/employee/Employee.service';
import { Employment } from '@/domain/employment/Employment.model';
import { convertLeavesMinutesToUnit } from '@/domain/leave-request/LeaveRequest.service';
import { useGetPayrollTransactions } from '@/hooks/payroll/Payroll.hook';
import { useGetPayrollLeavesFilters } from '@/page/payroll/PayrollLeavesFilters.hook';
import { MONTH_TRANSLATION_KEY } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getMonth, startOfMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const PayrollLeavesPage: FC = () => {
    const { t } = useTranslation();

    const { filters: availableFilters } = useGetPayrollLeavesFilters();
    const [filters, setFilters] = useFiltersStorage('payroll-filters', availableFilters);

    const { dateRangeViewType, dateRange, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'payroll-date-range',
    });

    const {
        data: payrollTransactions = [],
        isLoading,
        isError,
        error,
    } = useGetPayrollTransactions({
        startDate: startOfMonth(dateRange[0]),
    });

    const getTransactionsAfterFilters = () => {
        const clientSideFilters = filters?.filter(filter => filter.key !== 'cycleYear' && filter.key !== 'cycleMonth');
        // if one of the filters is filled, we need to filter the employees
        const filtersFilled = clientSideFilters?.filter(filter => !!filter.value?.length);

        return !filtersFilled?.length
            ? payrollTransactions
            : payrollTransactions.filter(transaction => filtersFilled.every(f => isFilterMatched(f, transaction)));
    };

    const isFilterMatched = (filter: SelectFilter | AsyncSelectFilter, transaction: LeaveTransaction): boolean => {
        const key = filter.key;

        if (isValidEmployeeFilterType(key)) {
            const ids = getSelectFilterNumberValues(filter);
            return !!transaction.leaveRequest?.employee && doesEmployeeMatchFilter(transaction.leaveRequest.employee, ids, key);
        } else {
            const nestedValue = getNestedValueByPath(transaction, key);
            return !!filter.value?.find(option => option.value === nestedValue);
        }
    };

    const agGridWrapper = useAgGridWrapper<LeaveTransaction>();

    const columnDefs: RogerColDef<LeaveTransaction>[] = [
        {
            field: 'leaveRequest.employee.email',
            headerName: 'Email',
            hide: true,
        },
        {
            headerName: t('payroll.firstName'),
            field: 'leaveRequest.employee.firstName',
        },
        {
            headerName: t('payroll.lastName'),
            field: 'leaveRequest.employee.lastName',
        },
        {
            field: 'leaveRequest.employee.currentEmployments',
            headerName: t('payroll.jobTitle'),
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => getLabelTranslation(employment.job.name)).join(', '),
        },
        {
            headerName: t('payroll.location'),
            field: 'leaveRequest.employee.currentEmployments',
            valueFormatter: ({ value }: { value: Employment[] }) => value.flatMap(employment => employment.location.name).join(', '),
        },
        {
            headerName: t('payroll.leaveType'),
            field: 'leaveRequest.leaveType.title',
        },
        {
            field: 'leaveRequest.startDate',
            headerName: t('payroll.start_date'),
            type: 'date',
        },
        {
            field: 'leaveRequest.endDate',
            headerName: t('payroll.end_date'),
            type: 'date',
        },
        {
            headerName: t('general.duration'),
            valueGetter: ({ data }) => {
                if (!data?.durationInDays) {
                    // can be empty in case of medical leave type
                    return '';
                }
                return convertLeavesMinutesToUnit({
                    input: data.durationInDays,
                    outputUnit: UnitType.DAYS,
                }).toString();
            },
        },
        {
            field: 'leaveRequest.leavePercentage',
            headerName: t('payroll.work_capacity'),
            valueFormatter: ({ value }) => (value ? `${value}%` : ''),
        },
        {
            headerName: t('payroll.month'),
            valueGetter: () => t(MONTH_TRANSLATION_KEY[getMonth(dateRange[0])]),
        },
        {
            headerName: t('payroll.created_at'),
            field: 'createdAt',
            type: 'date',
        },
        {
            field: 'leaveRequest.employee.employeeCode',
            headerName: t('payroll.id'),
            hide: true,
        },
    ];

    const rows = getTransactionsAfterFilters();

    const onBtnExport = () => {
        agGridWrapper.gridRef.current?.api?.exportDataAsExcel({
            allColumns: true,
        });
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack component={Paper} gap={1} p={1} direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction={'row'} alignItems={'flex-start'} gap={1} flexWrap={'wrap'}>
                    <DateRangePicker dates={dateRange} onDatesChanged={onDateRangeChange} defaultViewType={dateRangeViewType} availableViews={['MONTH']} />
                    <FiltersBar filters={filters} onFiltersChange={setFilters} clearable={false} flex={1} />
                </Stack>

                <DatatableAdditionalAction quickFilter={agGridWrapper.quickFilter} onBtnExport={onBtnExport} />
            </Stack>
            <StateHandler isLoading={isLoading} isEmpty={!rows?.length} isError={isError} error={error}>
                <Stack flex={1}>
                    <AgGridWrapper<LeaveTransaction> columnDefs={columnDefs} rowData={rows} initRef={agGridWrapper.setGridRef} />
                </Stack>
            </StateHandler>
        </Stack>
    );
};
