import { FC } from 'react';
import { EditorContent } from '@tiptap/react';
import { Box, FormHelperText, Stack, StackProps, SxProps } from '@mui/material';
import { EditorMenuBar } from '@/Components/rich-text-editor/EditorMenuBar';
import { RichTextEditorWrapper as RichTextEditorType, useRichTextEditorWrapper } from '@/Components/rich-text-editor/RichTextEditor.hook';
import { Editor } from '@tiptap/core';
import { Theme } from '@mui/material/styles';

export type RichTextEditorProps = {
    editorWrapper?: RichTextEditorType;
    onUpdate: (content: string) => void;
    minHeight?: string | number;
    defaultValue?: string;
    name?: string;
    errorMessage?: string;
    enabledEditorDependency?: boolean;
} & StackProps;

export type StyleProps = {
    minHeight: string | number;
    isError: boolean;
};

const getSxStyle = ({ minHeight, isError }: StyleProps): SxProps<Theme> => {
    return theme => ({
        '& .editor-content': {
            margin: 0,
            boxSizing: 'border-box',
            fontSize: 14,
            display: 'flex',

            '& .ProseMirror': {
                wordBreak: 'break-word',
                width: '100%',
                minHeight: minHeight,
                padding: '12px 10px',
                fontFamily: 'Inter',
                outline: 0,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                // if focus
                '&.ProseMirror-focused': {
                    // this margin is to avoid the element to move when the border is added
                    margin: '-1px',
                    marginTop: 0,
                    borderBottom: isError ? `2px solid ${theme.palette.error.main}` : `2px solid ${theme.palette.primary.main}`,
                    borderLeft: isError ? `2px solid ${theme.palette.error.main}` : `2px solid ${theme.palette.primary.main}`,
                    borderRight: isError ? `2px solid ${theme.palette.error.main}` : `2px solid ${theme.palette.primary.main}`,
                },
                border: '2px solid transparent',
                '&:not(.ProseMirror-focused)': {
                    borderBottom: isError ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.grey[300]}`,
                    borderLeft: isError ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.grey[300]}`,
                    borderRight: isError ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.grey[300]}`,
                },
                '& > p': {
                    margin: 0,
                    lineHeight: 1.4,
                },
                '& > ul, ol': {
                    margin: 0,
                    paddingInlineStart: theme.spacing(3),
                    '& > li p': {
                        margin: 0,
                        lineHeight: 1.4,
                    },
                },
                '& a': {
                    color: theme.palette.primary.main,
                },
            },
        },
    });
};

const formatEditorContent = (editor: Editor): string => {
    const content = editor.getHTML();
    // tip tap leaves <p></p> when the editor is empty
    // see https://github.com/ueberdosis/tiptap/issues/154
    if (content === '<p></p>') {
        return '';
    }
    return content;
};

export const RichTextEditor: FC<RichTextEditorProps> = ({
    editorWrapper: customEditorWrapper,
    onUpdate,
    minHeight = '120px',
    defaultValue,
    name,
    errorMessage,
    enabledEditorDependency = true,
    ...rest
}) => {
    const ownEditorWrapper = useRichTextEditorWrapper(defaultValue ?? '', enabledEditorDependency);
    const editorWrapper = customEditorWrapper ?? ownEditorWrapper;
    if (!editorWrapper) {
        return <></>;
    }

    editorWrapper.editor.on('update', ({ editor }) => {
        const formattedContent = formatEditorContent(editor);
        if (formattedContent) {
            onUpdate(formattedContent);
        }
    });

    const isFocused = editorWrapper.editor.isFocused;
    const isError = !!errorMessage;
    const sxStyle = getSxStyle({ minHeight, isError });

    return (
        <Stack direction='column' {...rest}>
            <EditorMenuBar editor={editorWrapper.editor} error={isError} isFocused={isFocused} />
            <Box sx={sxStyle}>
                <EditorContent name={name} editor={editorWrapper.editor} maxLength={10} autoFocus={true} className={'editor-content'} />
            </Box>
            <FormHelperText error={isError} sx={{ pl: 2, pt: 0.25 }}>
                {errorMessage}
            </FormHelperText>
        </Stack>
    );
};
