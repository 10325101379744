import { DialogWrapper, DialogWrapperProps } from '@/Components/dialog-wrapper/DialogWrapper';
import { generateThirdPartyApiKey } from '@/domain/third-party/ThirdParty.service';
import { Alert, DialogContent, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Copy01Icon } from 'hugeicons-react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ThirdPartyApiKeyDialog: FC<DialogWrapperProps & { thirdPartyId: number }> = ({ thirdPartyId, ...rest }) => {
    const { t } = useTranslation();

    const [thirdPartyApiKey, setThirdPartyApiKey] = useState<string>();

    useEffect(() => {
        const generateApiKey = async () => {
            // WARNING: Don't log the API key in the console or anywhere else (Bugsnag, etc.)
            const thirdPartyApiKey = await generateThirdPartyApiKey(thirdPartyId);

            setThirdPartyApiKey(thirdPartyApiKey);
        };
        generateApiKey();
    }, [thirdPartyId]);

    if (!thirdPartyApiKey) {
        return;
    }

    return (
        <DialogWrapper header={t('third_party.api_key_dialog.title')} maxWidth='sm' {...rest}>
            <Stack component={DialogContent} gap={2} pb={2}>
                <Typography variant='body1'>{t('third_party.api_key_dialog.description')}</Typography>

                <Alert severity='warning'>{t('third_party.api_key_dialog.warning')}</Alert>

                <Stack direction='column' gap={0.5}>
                    <Typography variant='body1bold'>{t('third_party.api_key_dialog.api_key')}</Typography>

                    <Stack direction='row' gap={1} alignItems='center'>
                        <TextField fullWidth value={thirdPartyApiKey} disabled />
                        <IconButton onClick={() => navigator.clipboard.writeText(thirdPartyApiKey)}>
                            <Copy01Icon />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
        </DialogWrapper>
    );
};
