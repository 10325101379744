import {
    AnswerResultDetails,
    Survey,
    SurveyAnswer,
    SurveyAnswerMutation,
    SurveyCreationMutation,
    SurveyQuestionType,
    SurveyResults,
    SurveyUpdateMutation,
    UserSurvey,
} from './Survey.model';
import { surveyApi } from '@/api/survey/Survey.api';

export const isRenderAsSection = (fieldType: SurveyQuestionType): boolean => {
    return fieldType === SurveyQuestionType.SECTION;
};

export const isRenderAsText = (fieldType: SurveyQuestionType): boolean => {
    return fieldType === SurveyQuestionType.TEXT;
};

export const isRenderAsSingleChoice = (fieldType: SurveyQuestionType): boolean => {
    return fieldType === SurveyQuestionType.SINGLE_CHOICE;
};

export const isRenderAsMultipleChoice = (fieldType: SurveyQuestionType): boolean => {
    return fieldType === SurveyQuestionType.MULTI_CHOICE;
};

export const isLickertScale = (fieldType: SurveyQuestionType): boolean => {
    return fieldType === SurveyQuestionType.LICKERT_SCALE;
};

export const isOpinionScale = (fieldType: SurveyQuestionType): boolean => {
    return fieldType === SurveyQuestionType.OPINION_SCALE;
};

export const isNPS = (fieldType: SurveyQuestionType): boolean => {
    return fieldType === SurveyQuestionType.NPS;
};

export const renderGroupByWithFilters = (activeGroupByFilters: string[], details: AnswerResultDetails[]): AnswerResultDetails[] => {
    const withoutOtherGroup = details.filter(item => {
        return item.key !== 'OTHER';
    });
    if (activeGroupByFilters?.length) {
        return withoutOtherGroup.filter(item => item.label.translationEn && activeGroupByFilters.includes(item.label.translationEn));
    } else {
        return withoutOtherGroup;
    }
};
export const COLORS_SCHEME = [
    {
        color: '#02BBBB',
    },
    {
        color: '#000A1D',
    },
    {
        color: '#01CDCD',
    },
    {
        color: '#01153C',
    },
    {
        color: 'rgba(1, 205, 205, 0.5)',
    },
    {
        color: '#7E92B9',
    },
    {
        color: '#5DEAEA',
    },
    {
        color: 'rgba(1, 21, 60, 0.5)',
    },
    {
        color: 'linear-gradient(0deg, #EAF8F9, #EAF8F9), #FFFFFF',
    },
    {
        color: 'rgba(1, 21, 60, 0.08)',
    },
];
export const SCALE_COLOR_SCHEME = [
    {
        color: '#E32A23',
    },
    {
        color: '#DC0800',
    },
    {
        color: '#F1490F',
    },
    {
        color: '#FF7629',
    },
    {
        color: '#FF8E15',
    },
    {
        color: '#FFB547',
    },
    {
        color: '#FFD362',
    },
    {
        color: '#C8D03D',
    },
    {
        color: '#37CC24',
    },
    {
        color: '#0AB904',
    },
    {
        color: '#0AB904',
    },
];

export const createSurvey = async (request: SurveyCreationMutation): Promise<Survey> => surveyApi.createSurvey(request);

export const deleteSurvey = async (surveyId: number): Promise<number> => surveyApi.deleteSurvey(surveyId);

export const surveySendReminder = async (surveyId: number): Promise<Survey> => surveyApi.surveySendReminder(surveyId);

export const getSurveyAuthenticationToken = async (qsToken: string): Promise<string> => surveyApi.getSurveyAuthenticationToken(qsToken);

export const getUserSurvey = async (token: string): Promise<UserSurvey> => surveyApi.getUserSurvey(token);

export const createSurveyAnswer = async (request: SurveyAnswerMutation, token: string): Promise<SurveyAnswer> => surveyApi.createSurveyAnswer(request, token);

export const updateSurvey = async (surveyId: number, request: SurveyUpdateMutation): Promise<Survey> => surveyApi.updateSurvey(surveyId, request);

export const updateSurveyAnswer = async (surveyAnswerId: number, request: SurveyAnswerMutation, token: string): Promise<SurveyAnswer> =>
    surveyApi.updateSurveyAnswer(surveyAnswerId, request, token);

export const getResults = async (surveyId: number): Promise<SurveyResults> => surveyApi.getResults(surveyId);

export const getSurveys = async (): Promise<Survey[]> => surveyApi.getSurveys();

export const getSurveyById = async (surveyId: number): Promise<Survey> => surveyApi.getSurveyById(surveyId);
