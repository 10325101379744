import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Stack, Typography } from '@mui/material';
import { useUserLanguage } from '@/utils/language.util';
import { EmployeeAvatarWithDetails } from '@/Components/employee-avatar/EmployeeAvatarWithDetails';
import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import {
    getEmployeeReviewManagerSummaryComment,
    getEmployeeReviewManagerSummaryDate,
    getEmployeeReviewManagerSummaryReviewerEmployee,
} from '@/domain/employee-review/EmployeeReview.service';

type EmployeeReviewSummaryCompletedProps = {
    employeeReviewManagerSummary: EmployeeReviewManagerSummary;
};
export const EmployeeReviewSummaryCompleted: FC<EmployeeReviewSummaryCompletedProps> = ({ employeeReviewManagerSummary }) => {
    const { t } = useTranslation();
    const userLanguage = useUserLanguage();

    return (
        <Paper sx={{ padding: 2 }}>
            <Stack gap={2}>
                <Typography variant={'h1'}>{t('reviews.summary.evaluation', { context: employeeReviewManagerSummary?.employeeReviewStatus })}</Typography>
                <Stack gap={1} direction={{ xs: 'column', md: 'row' }} alignItems={{ sx: 'flex-start', md: 'center' }}>
                    <Typography variant={'body1bold'}>{t('reviews.summary.by', { context: employeeReviewManagerSummary?.employeeReviewStatus })}: </Typography>
                    <EmployeeAvatarWithDetails employee={getEmployeeReviewManagerSummaryReviewerEmployee(employeeReviewManagerSummary)} />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'flex-start'} gap={1}>
                    <Typography variant={'body1bold'}>
                        {t('reviews.summary.date', { context: employeeReviewManagerSummary?.employeeReviewStatus })}:{' '}
                    </Typography>
                    <Typography variant={'body1'}>{getEmployeeReviewManagerSummaryDate(employeeReviewManagerSummary, userLanguage)}</Typography>
                </Stack>
                {getEmployeeReviewManagerSummaryComment(employeeReviewManagerSummary) && (
                    <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'flex-start'} gap={1} width={'100%'}>
                        <Typography whiteSpace={'nowrap'} variant={'body1bold'}>
                            {t('reviews.summary.final_comment')}:{' '}
                        </Typography>
                        <Typography variant={'body1'}>{getEmployeeReviewManagerSummaryComment(employeeReviewManagerSummary)}</Typography>
                    </Stack>
                )}
            </Stack>
        </Paper>
    );
};
