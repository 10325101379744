import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Slide from '@mui/material/Slide';
import thankYouImg from '@/assets/Images/survey_trankYou.svg';

const useStyles = makeStyles(theme => ({
    paperBackground: {
        padding: theme.spacing(6),
        textAlign: 'center',
        maxWidth: 450,
        margin: 'auto',
    },
    questionTitle: {
        margin: '32px auto',
        fontSize: 18,
        [theme.breakpoints.down('md')]: {
            marginBottom: 24,

            display: 'block',
        },
    },
}));

export const SurveyCompleted: FC<unknown> = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Slide direction='left' in={true} mountOnEnter unmountOnExit>
            <Paper elevation={0} className={classes.paperBackground}>
                <img src={thankYouImg} alt='questions' />
                <Typography variant='h1' className={classes.questionTitle}>
                    {t('anonymous_surveys.you_have_completed_this_survey')}
                </Typography>
            </Paper>
        </Slide>
    );
};
