import { LeaveBalanceSearchRequest, LeaveTypeHistory, UserLeaveTypeBalance } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { API_BASE_URL, client } from '@/api/common';
import { LeaveActivityType } from '@/domain/leave-type/LeaveType.model';
import { AxiosResponse } from 'axios';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';

type LeaveTypeHistoryDTO = LeaveTypeHistory;
type UserLeaveTypeBalanceDTO = Overwrite<UserLeaveTypeBalance, { employee: EmployeeDTO }>;
type LeaveBalanceSearchRequestDTO = LeaveBalanceSearchRequest;

async function getLeaveTypeHistories(
    userId: number,
    filters: {
        leaveActivityTypes?: LeaveActivityType[];
        endDate?: string;
    },
): Promise<LeaveTypeHistory[]> {
    const url = API_BASE_URL + `/user/${userId}/leave-types/history`;
    const { data } = await client.get<LeaveTypeHistoryDTO[]>(url, { params: filters });
    return data;
}

async function getLeaveTypeHistory(userId: number, userLeaveTypeId: number): Promise<LeaveTypeHistory> {
    const url = API_BASE_URL + `/user/${userId}/leave-types/${userLeaveTypeId}/history`;
    const { data } = await client.get<LeaveTypeHistoryDTO>(url);
    return data;
}

const mapLeaveBalanceDTO = (dto: UserLeaveTypeBalanceDTO): UserLeaveTypeBalance => {
    const { employee, ...restUserLeaveTypeBalanceDTO } = dto;
    return {
        ...restUserLeaveTypeBalanceDTO,
        employee: mapEmployeeDTO(employee),
    };
};

async function getLeaveBalance(leaveTypeId: number, searchRequest: LeaveBalanceSearchRequest): Promise<UserLeaveTypeBalance[]> {
    const url = API_BASE_URL + `/leave-types/${leaveTypeId}/balance`;
    const { data } = await client.post<UserLeaveTypeBalanceDTO[], AxiosResponse<UserLeaveTypeBalanceDTO[]>, LeaveBalanceSearchRequestDTO>(url, searchRequest);
    return data.map(mapLeaveBalanceDTO);
}

export const leaveTypeHistoryApi = {
    getLeaveTypeHistories,
    getLeaveTypeHistory,
    getLeaveBalance,
};
