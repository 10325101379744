import { Chip, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { StackedAvatars } from '@/Components/stacked-avatar/StackedAvatars';
import { FilteringCondition, PermissionFilterType } from '@/domain/permission/Permission.model';
import { ICellRendererParams } from '@ag-grid-community/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnabledStatus } from '@/Components/enabled-status/EnabledStatus';
import { handleError } from '@/utils/api.util';
import { Employee } from '@/domain/employee/Employee.model';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { RenderCondition } from '@/page/setting/permission/condition/Components/RenderCondition';
import { ConfigType } from '@/page/setting/types';
import { getPermissionGroups } from '@/domain/permission-group/PermissionGroup.service';
import { PermissionGroup, PermissionGroupType } from '@/domain/permission-group/PermissionGroup.model';

export const PermissionsSettings: FC = () => {
    const { t } = useTranslation();
    const [permissionGroups, setPermissionGroups] = useState<PermissionGroup[]>([]);

    const renderPermissionGroupMembers = (
        members: Employee[],
        filterType: PermissionFilterType,
        conditions: FilteringCondition[],
        groupType: PermissionGroupType,
    ) => {
        //done in: RP-4597 - hardcoded value for the self group to display all employees, to be changed when we allow the editing of the self group
        if (groupType === PermissionGroupType.SELF && filterType === PermissionFilterType.DYNAMIC) {
            return <Chip label={t('permissions_setting_page.all_employees')} size='small' />;
        }
        switch (filterType) {
            case PermissionFilterType.DYNAMIC:
                return <RenderConditionTooltip conditions={conditions} groupType={groupType} />;

            case PermissionFilterType.EMPLOYEE_LIST:
                return <StackedAvatars employeeAvatars={members} />;

            case PermissionFilterType.ALL_EMPLOYEES:
                return <Chip label={t('permissions_setting_page.all_employees')} size='small' />;

            case PermissionFilterType.SELF:
                return <Chip label={t('permissions_setting_page.self')} size='small' />;

            case PermissionFilterType.MANAGER:
                return <Chip label={t('permissions_setting_page.manager')} size='small' />;

            default:
                return '';
        }
    };

    useEffect(() => {
        getPermissionGroups()
            .then(data => setPermissionGroups(data))
            .catch(handleError);
    }, []);

    const cellStatusRenderer = ({ data }: ICellRendererParams<PermissionGroup>) => <EnabledStatus enabled={data?.notificationsEnabled} />;

    const config: ConfigType = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('general.add')} ${t('permissions_setting_page.permission_group')}`,
        },
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    field: 'groupName',
                    headerName: t('permissions_setting_page.group_name'),
                },
                {
                    field: 'description',
                    headerName: t('permissions_setting_page.description'),
                },
                {
                    field: 'groupMembers',
                    headerName: t('permissions_setting_page.group_members'),
                    type: 'stackedAvatars',
                    cellRenderer: ({ data }: ICellRendererParams<PermissionGroup>) =>
                        data ? renderPermissionGroupMembers(data.groupMembers, data.filterType, data.groupMemberConditions, data.groupType) : '',
                    cellClass: ['display-flex'],
                },
                {
                    field: 'targetMembers',
                    headerName: t('permissions_setting_page.target_members'),
                    type: 'stackedAvatars',
                    cellRenderer: ({ data }: ICellRendererParams<PermissionGroup>) =>
                        data ? renderPermissionGroupMembers(data.targetMembers, data.targetMembersFilterType, data.targetMemberConditions, data.groupType) : '',
                    cellClass: ['display-flex'],
                },
                {
                    field: 'notificationsEnabled',
                    headerName: t('permissions_setting_page.notifications_enabled'),
                    cellRenderer: cellStatusRenderer,
                    cellClass: ['display-flex'],
                },
            ],
        },
    };

    return <CompanySettingsLayout options={config} data={permissionGroups} />;
};

type RenderConditionTooltipProps = {
    conditions: FilteringCondition[];
    groupType: PermissionGroupType;
};

const RenderConditionTooltip: FC<RenderConditionTooltipProps> = ({ conditions, groupType }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    //because these groups are the default groups we want a different label for them even if they are conditional
    const isCurrentEmployees = groupType === PermissionGroupType.ALL_EMPLOYEES || groupType === PermissionGroupType.SELF;

    const label = isCurrentEmployees ? t('permissions_setting_page.current_employees') : t('permissions_setting_page.conditional');

    return (
        <Tooltip
            title={
                <Stack direction={'row'} flexWrap={'wrap'}>
                    {conditions?.map((item: FilteringCondition, index: number) => (
                        <Stack direction={'row'} alignItems={'center'} key={item.id}>
                            <RenderCondition item={item} isTooltip={true} />
                            {index < conditions.length - 1 && (
                                <Typography color={theme.palette.info.light} variant={'body2'}>
                                    {t('general.and')}
                                </Typography>
                            )}
                        </Stack>
                    ))}
                </Stack>
            }
        >
            <Chip label={label} size='small' />
        </Tooltip>
    );
};
