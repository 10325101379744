import { Fragment, HTMLAttributes, SyntheticEvent, useCallback, useState } from 'react';
import { TreeOption } from '@/Components/autocomplete-wrapper/TreeAutoComplete/TreeOption';
import { AutocompleteChangeReason, FilterOptionsState, InputAdornment, TextFieldProps, Tooltip } from '@mui/material';
import { getNull } from '@/utils/object.util';
import { MoreHorizontalIcon } from 'hugeicons-react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export type FlatTreeNode = {
    id: number;
    name: string;
    parentId?: FlatTreeNode['id'];
    hasChildren: boolean;
    disabled?: boolean;
};

// TODO improve type to get the same type type as useAutocomplete from mui with generic types
export type UseTreeAutoCompleteReturnValue = {
    autocompleteProps: {
        value: FlatTreeNode | null | undefined;
        options: readonly FlatTreeNode[];
        getOptionLabel: (option: FlatTreeNode) => string;
        getOptionKey: (option: FlatTreeNode) => number;
        isOptionEqualToValue: (option: FlatTreeNode, value: FlatTreeNode) => boolean;
        onChange: (event: SyntheticEvent<Element, Event>, option: FlatTreeNode | null, reason: AutocompleteChangeReason) => void;
        filterOptions: (options: FlatTreeNode[], state: FilterOptionsState<FlatTreeNode>) => FlatTreeNode[];
        renderOption: (props: HTMLAttributes<HTMLLIElement>, option: FlatTreeNode) => JSX.Element;
    };
    textFieldInputProps: TextFieldProps['InputProps'];
};

export type UseTreeAutoCompleteProps = {
    options: readonly FlatTreeNode[];
    defaultValue?: FlatTreeNode;
    canSelectNode?: boolean;
};

/**
 * useTreeAutoComplete is a hook that provides the necessary props to use the Autocomplete component to display
 * options as a tree and enable navigation in the tree.
 * It receives the options to display in the autocomplete and returns the props to use in the Autocomplete component.
 * @param props
 */
export const useTreeAutoComplete = (props: UseTreeAutoCompleteProps): UseTreeAutoCompleteReturnValue => {
    const { options = [], defaultValue, canSelectNode = true } = props;
    // state to keep track of the current parent to filter options during navigation in the three
    const defaultParent = options.find(d => d.id === defaultValue?.parentId);
    const [currentParent, setCurrentParent] = useState<FlatTreeNode | undefined>(defaultParent);
    const [currentValue, setCurrentValue] = useState<FlatTreeNode | null>(defaultValue ?? getNull());

    // this useEffect is used to update currentValue and CurrentParent state when the default value become defined
    // because the initial value of states does not change when the defaultValue prop changes
    useDeepCompareEffect(() => {
        setCurrentValue(defaultValue ?? getNull());
        const parent = options.find(d => d.id === defaultValue?.parentId);
        setCurrentParent(parent);
    }, [defaultValue, options]);

    // go to children
    const handleNavigateToChildren = useCallback((node: FlatTreeNode) => {
        setCurrentParent(node);
    }, []);

    // go back to parent
    const handleNavigateToParentFrom = useCallback(
        (nodeId: number) => {
            const fromNode = options.find(node => node.id === nodeId);
            const parent = options.find(node => node.id === fromNode?.parentId);
            setCurrentParent(parent);
        },
        [options],
    );

    // reset to go back to the root
    const resetCurrentParent = () => {
        setCurrentParent(undefined);
    };

    const onChange = useCallback(
        (_: SyntheticEvent<Element, Event>, option: FlatTreeNode | null, reason: AutocompleteChangeReason) => {
            // set the current value by finding the node in the original list
            // because we update it on search
            setCurrentValue(options.find(node => node.id === option?.id) ?? getNull());
            if (reason === 'clear') {
                // reset current parent on clear to display nodes at the top level
                resetCurrentParent();
            }
        },
        [options],
    );

    const isRoot = currentParent === undefined;
    const renderOption = useCallback(
        (props: HTMLAttributes<HTMLLIElement>, option: FlatTreeNode) => {
            const isChildOfTheCurrentParent = option.parentId === currentParent?.id;
            const optionIsTheCurrentParent = option.id === currentParent?.id && !isRoot;

            // only render the option if it is a child of the current parent
            // or if it is the current parent to display it with the back button
            return isChildOfTheCurrentParent || optionIsTheCurrentParent ? (
                <TreeOption
                    {...props}
                    key={option.id}
                    node={option}
                    parentId={currentParent?.id}
                    onNavigateToChildren={handleNavigateToChildren}
                    onGoBack={handleNavigateToParentFrom}
                    canSelectNode={canSelectNode}
                />
            ) : (
                <Fragment key={option.id} />
            );
        },
        [currentParent?.id, handleNavigateToChildren, handleNavigateToParentFrom, isRoot, canSelectNode],
    );

    // function to filter the options on search
    const filterOptions = useCallback(
        (options: FlatTreeNode[], state: FilterOptionsState<FlatTreeNode>) => {
            const { inputValue } = state;
            if (inputValue === '') {
                return options;
            }
            // return all options if the user starts to navigate to children to avoid filtering on children
            if (currentParent) {
                return options;
            }
            return options
                .filter(node => node.name.toLowerCase().includes(inputValue.toLowerCase()))
                .map(node => ({
                    ...node,
                    // set parentId to undefined to display all matching node
                    parentId: undefined,
                }));
        },
        [currentParent],
    );

    const getOptionLabel = (option: FlatTreeNode) => option.name;
    const getOptionKey = (option: FlatTreeNode) => option.id;
    const isOptionEqualToValue = (option: FlatTreeNode, value: FlatTreeNode | null) => option.id === value?.id;

    const textFieldInputProps: TextFieldProps['InputProps'] = {
        startAdornment: currentValue && (
            <Tooltip title={getPath(options, currentValue)}>
                <InputAdornment position='start'>
                    <MoreHorizontalIcon />
                </InputAdornment>
            </Tooltip>
        ),
    };

    return {
        autocompleteProps: {
            value: currentValue,
            options: sortOptions(options),
            getOptionLabel,
            getOptionKey,
            isOptionEqualToValue,
            onChange,
            filterOptions,
            renderOption,
        },
        textFieldInputProps,
    };
};

const getPath = (options: readonly FlatTreeNode[], node: FlatTreeNode | undefined) => {
    if (!node) {
        return;
    }
    const path = [node.name];
    const parent = options.find(n => n.id === node.parentId);
    const parentPath = getPath(options, parent);
    if (parentPath) {
        path.unshift(parentPath);
    }
    return path.join(' > ');
};

// sort the options to display the parent nodes first
// useful to display the parent nodes first in the list for the go back button
const sortOptions = (options: readonly FlatTreeNode[]): FlatTreeNode[] => {
    return [...options].sort((optionA, optionB) => {
        // if optionA is the parent of optionB, it should be displayed first
        if (optionA.id === optionB.parentId) {
            return -1;
        }
        // if both at root, keep the order
        if (!optionA.parentId && !optionB.parentId) {
            return 0;
        }
        // if optionA has no parent, it should be displayed first
        if (optionA.parentId === undefined) {
            return -1;
        }

        if (optionB.parentId === undefined) {
            return 1;
        }

        // if both have a parent, sort by parentId
        return optionA.parentId - optionB.parentId;
    });
};
