import { getApiBaseUrl, getAppConfig } from '@/config/config';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { store } from '@/stores/store';
import { Auth } from 'aws-amplify';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const config = getAppConfig();
const API_VERSION = 'v1';
export const API_BASE_URL = getApiBaseUrl() + '/' + API_VERSION;
export const client = axios.create();

let requestCounter = 0;

// Spring security doesn't allow to send the Authorization header in public routes
// see: https://github.com/spring-projects/spring-security/issues/12599
const UNSECURE_ROUTES = ['/v1/realms/', '/v1/realms/search', '/v1/surveys/auth', '/v1/employees/login-method'];

const updateLoadingState = (requestCount: number) => {
    store.dispatch({ type: UiActionType.ACTIVE_REQUEST_COUNTER, requestCount: requestCount });
};

const decrementCounter = (response?: AxiosResponse, error?: AxiosError) => {
    requestCounter--;
    updateLoadingState(requestCounter);
    if (response) {
        return response;
    }
    if (error) {
        return Promise.reject(error);
    }
};

const onRequest = async (requestConfig: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    requestCounter++;
    updateLoadingState(requestCounter);
    try {
        const session = await Auth.currentSession();
        const jwt = session.getIdToken().getJwtToken();
        if (!UNSECURE_ROUTES.some(route => requestConfig.url?.includes(route))) {
            requestConfig.headers['Authorization'] = `Bearer ${jwt}`;
        }
        requestConfig.headers['Content-Type'] = config.MIME_TYPES.JSON;
    } catch (error) {
        // if the user is not authenticated don't block the client call, just log the error
        console.error('Authentication error => ', error);
    }
    return requestConfig;
};

client.interceptors.request.use(onRequest, error => {
    return decrementCounter(undefined, error);
});

// Response interceptor for API calls
client.interceptors.response.use(
    response => {
        return decrementCounter(response) as AxiosResponse;
    },
    async function (error) {
        return decrementCounter(undefined, error);
    },
);
