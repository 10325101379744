import { FormHelperText, Stack, Switch } from '@mui/material';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

export type FieldSwitchProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    onChange?: (value: boolean) => void;
} & Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldSwitch = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldSwitchProps<TFieldValues, TName>,
): JSX.Element => {
    const { disabled, ...controllerProps } = props;

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState }) => (
                <Stack>
                    <Switch
                        checked={field.value}
                        {...field}
                        onChange={(e, checked) => {
                            field.onChange(e, checked);
                            props.onChange?.(checked);
                        }}
                        disabled={disabled}
                    />
                    {!!fieldState.error && (
                        <FormHelperText sx={{ pl: 2 }} error>
                            {fieldState?.error?.message}
                        </FormHelperText>
                    )}
                </Stack>
            )}
        />
    );
};
