import { Button, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AgGridWrapper, RogerColDef } from '@/Components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/Components/ag-grid-wrapper/useAgGridWrapper';
import { canSeeSurveyResults } from '@/domain/permission/Permission.service';
import { Survey } from '@/domain/survey/Survey.model';
import { SurveyActionButtons } from '@/page/survey/survey/SurveyActionButtons';
import { SurveyResultsButton } from '@/page/survey/survey/SurveyResultsButton';
import { SurveyStatusChip } from '@/page/survey/survey/SurveyStatusChip';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { Add01Icon } from 'hugeicons-react';
import { useNavigate } from 'react-router-dom';

import { deleteSurvey, getSurveys, surveySendReminder } from '@/domain/survey/Survey.service';
import { EditSurveySetupFormDialog } from '@/page/survey/edit-survey-setup-dialog/EditSurveySetupFormDialog';
import { isFuture } from 'date-fns';
import { Participation } from '@/page/survey/participation/Participation';

export const SurveysPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [surveyIdToEdit, setSurveyIdToEdit] = useState<number | undefined>(undefined);

    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const realm = useAppSelector(state => state.ui.currentRealm);

    const agGridWrapper = useAgGridWrapper<Survey>();

    const [surveys, setSurveys] = useState<Survey[]>();
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        getSurveys()
            .then(data => {
                setSurveys(data);
            })
            .catch(handleError);
    }, [refetch]);

    if (!realm) {
        throw new Error('Realm is not defined');
    }

    if (!surveys) {
        return <></>;
    }

    if (!canSeeSurveyResults(realm.realmFeatures, policies)) {
        return <></>;
    }

    const handleEditClicked = (surveyId: number) => {
        setSurveyIdToEdit(surveyId);
    };

    const isSurveyEditable = (survey: Survey): boolean => {
        return !!survey.startDate && isFuture(survey.startDate);
    };

    const handleDeleteClicked = (surveyId: number) => {
        deleteSurvey(surveyId)
            .then(() => {
                showSnackbar(t('survey_templates_page.survey_deleted'), 'success');
                setRefetch(true);
            })
            .catch(handleError);
    };

    const menuRenderer = (data: ICellRendererParams<Survey>) => {
        if (!data?.data?.id) {
            return;
        }

        const surveyId = data.data.id;

        return (
            <SurveyActionButtons
                onEditClicked={() => handleEditClicked(surveyId)}
                editDisabled={!isSurveyEditable(data.data)}
                onDeleteClicked={() => handleDeleteClicked(surveyId)}
                onSendReminderClicked={() => {
                    surveySendReminder(surveyId)
                        .then(() => {
                            showSnackbar(t('survey_templates_page.cycles.reminder_sent_successfully'), 'success');
                            setRefetch(true);
                        })
                        .catch(() => {
                            showSnackbar(t('survey_templates_page.cycles.reminder_sent_error'), 'error');
                        });
                }}
            />
        );
    };

    const columnDefs: RogerColDef<Survey>[] = [
        {
            field: 'name',
            headerName: t('survey_templates_page.cycles.cycle_table.survey_name'),
            type: 'label',
        },
        {
            field: 'startDate',
            headerName: t('survey_templates_page.cycles.cycle_table.start_date'),
            type: 'date',
        },
        {
            field: 'endDate',
            headerName: t('survey_templates_page.cycles.cycle_table.end_date'),
            type: 'date',
        },
        {
            field: 'lastReminderSentAt',
            headerName: t('survey_templates_page.cycles.cycle_table.sent_on'),
            type: 'date',
        },
        {
            headerName: t('survey_templates_page.cycles.cycle_table.status'),
            cellRenderer: StatusCell,
        },
        {
            headerName: t('survey_templates_page.cycles.cycle_table.participation'),
            cellRenderer: ParticipationCell,
            cellClass: ['display-flex'],
            sortable: false,
        },
        {
            cellRenderer: ResultCell,
            sortable: false,
        },
        {
            type: 'actionMenu',
            cellRenderer: menuRenderer,
        },
    ];

    return (
        <Stack gap={2} flex={1}>
            <Stack direction='row-reverse' alignItems='center' justifyContent='space-between'>
                <Button
                    startIcon={<Add01Icon />}
                    onClick={() => {
                        navigate('/manage-surveys/surveys/new');
                    }}
                >
                    {t('survey_templates_page.new_survey')}
                </Button>
            </Stack>
            <Stack flex={1}>
                <AgGridWrapper<Survey> rowData={surveys} initRef={agGridWrapper.setGridRef} columnDefs={columnDefs} />
            </Stack>

            {!!surveyIdToEdit && <EditSurveySetupFormDialog surveyId={surveyIdToEdit} onClose={() => setSurveyIdToEdit(undefined)} />}
        </Stack>
    );
};

export const ResultCell = ({ data }: ICellRendererParams<Survey>): ColDef['cellRenderer'] => (data ? <SurveyResultsButton survey={data} /> : undefined);
export const ParticipationCell = ({ data }: ICellRendererParams<Survey>): ColDef['cellRenderer'] => (data ? <Participation survey={data} /> : undefined);
export const StatusCell = ({ data }: ICellRendererParams<Survey>): ColDef['cellRenderer'] => (data ? <SurveyStatusChip survey={data} /> : undefined);
