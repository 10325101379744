import { handleError } from '@/utils/api.util';
import { TimesheetSetting, TimesheetSettingMutation } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { deleteTimesheetSettings, postTimesheetSettings, putTimesheetSettings } from '@/domain/timesheet-setting/TimesheetSetting.service';

import { useGetTimesheetSettingById } from '@/page/setting/time-management/TimesheetSettings.hook';
import { TimeManagementSettingForm, TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { ConfirmDialog } from '@/Components/confirmation-dialog/ConfirmDialog';

const convertFormValueToMutation = (timesheetSettingForm: TimesheetSettingForm): TimesheetSettingMutation => {
    const timesheetSettingMutation: TimesheetSettingMutation = { ...timesheetSettingForm };

    if (!timesheetSettingForm.isBonusEnabled) {
        timesheetSettingMutation.nightBonusPercentage = 0;
        timesheetSettingMutation.nightBonusStartTime = undefined;
        timesheetSettingMutation.nightBonusEndTime = undefined;
        timesheetSettingMutation.saturdayBonusPercentage = 0;
        timesheetSettingMutation.saturdayBonusStartTime = undefined;
        timesheetSettingMutation.saturdayBonusEndTime = undefined;
        timesheetSettingMutation.sundayBonusPercentage = 0;
        timesheetSettingMutation.sundayBonusStartTime = undefined;
        timesheetSettingMutation.sundayBonusEndTime = undefined;
    }

    if (!timesheetSettingForm.isPaidBreaksEnabled) {
        timesheetSettingMutation.rule1PaidBreaksFrom = undefined;
        timesheetSettingMutation.rule1PaidBreaksTo = undefined;
        timesheetSettingMutation.rule1PaidBreaksMaximumDurationInMinutes = 0;
        timesheetSettingMutation.rule2PaidBreaksFrom = undefined;
        timesheetSettingMutation.rule2PaidBreaksTo = undefined;
        timesheetSettingMutation.rule2PaidBreaksMaximumDurationInMinutes = 0;
    }

    if (timesheetSettingMutation.rule1PaidBreaksMaximumDurationInMinutes === 0) {
        timesheetSettingMutation.rule1PaidBreaksFrom = undefined;
        timesheetSettingMutation.rule1PaidBreaksTo = undefined;
    }

    if (timesheetSettingMutation.rule2PaidBreaksMaximumDurationInMinutes === 0) {
        timesheetSettingMutation.rule2PaidBreaksFrom = undefined;
        timesheetSettingMutation.rule2PaidBreaksTo = undefined;
    }

    if (!timesheetSettingForm.isForceBreakToBeTakenEnabled || !timesheetSettingForm.forceBreakClockInOut) {
        timesheetSettingMutation.forceBreakToBeTakenFrom = undefined;
        timesheetSettingMutation.forceBreakToBeTakenTo = undefined;
    } else {
        timesheetSettingMutation.breakDisplayEnabled = false;
    }

    if (!timesheetSettingForm.forceBreakClockInOut) {
        timesheetSettingMutation.forceSmallBreakAfterXHours = 0;
        timesheetSettingMutation.forceSmallBreakDurationInMinutes = 0;
        timesheetSettingMutation.forceSmallBreakAfterXHours = 0;
        timesheetSettingMutation.forceBigBreakDurationInMinutes = 0;
    }

    timesheetSettingMutation.maximumWeeklyAdditionalWorkingTime = timesheetSettingForm.maximumWeeklyAdditionalWorkingTime * 60;

    return timesheetSettingMutation;
};

export const TimeManagementSettingPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const isEdit = !!params?.timeManagementId && params?.timeManagementId !== 'new';

    const timeManagementId = isEdit && params?.timeManagementId ? Number(params.timeManagementId) : undefined;

    const [timesheetSettingToDelete, setTimesheetSettingToDelete] = useState<TimesheetSetting>();

    const { data: timesheetSetting, isLoading, isError, error } = useGetTimesheetSettingById(timeManagementId);

    const handleDeleteTimesheetSettings = async (timesheetSettingsId: number) => {
        try {
            await deleteTimesheetSettings(timesheetSettingsId);
            navigate('/settings/time-management/settings');
        } catch (error) {
            handleError(error);
        } finally {
            setTimesheetSettingToDelete(undefined);
        }
    };
    const onSave = async (timesheetSettingForm: TimesheetSettingForm) => {
        const timesheetSettingMutation = convertFormValueToMutation(timesheetSettingForm);
        if (isEdit && timesheetSetting?.id) {
            try {
                await putTimesheetSettings(timesheetSettingMutation, timesheetSetting?.id);
                navigate('/settings/time-management/settings');
            } catch (error) {
                handleError(error);
            }
        } else {
            try {
                await postTimesheetSettings(timesheetSettingMutation);
                navigate('/settings/time-management/settings');
            } catch (error) {
                handleError(error);
            }
        }
    };

    const onDeleteTimesheetSetting = () => {
        setTimesheetSettingToDelete(timesheetSetting);
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <TimeManagementSettingForm timesheetSetting={timesheetSetting} isEdit={isEdit} onSave={onSave} onDelete={onDeleteTimesheetSetting} />

            <ConfirmDialog
                open={!!timesheetSettingToDelete?.id}
                title={t('time_management_settings_page.are_you_sure_delete_setting')}
                content={t('general.action_irreversible')}
                onClose={() => {
                    setTimesheetSettingToDelete(undefined);
                }}
                onConfirm={() => {
                    if (timesheetSettingToDelete?.id) {
                        handleDeleteTimesheetSettings(timesheetSettingToDelete?.id).catch(handleError);
                    }
                }}
                // Override default confirm button props
                confirmButtonProps={{
                    children: t('general.delete'),
                    color: 'error',
                }}
            />
        </StateHandler>
    );
};
