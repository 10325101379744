import {
    EmployeePayrollLock,
    EmployeePayrollLockLatestSearchRequest,
    EmployeePayrollLockSearchRequest,
    LatestPayrollLocks,
} from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { searchEmployeePayrollLock, searchLatestEmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.service';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import { EmployeePayrollProfileChange, PayrollProfileChangeSearch } from '@/domain/payroll/Payroll.model';
import { getPayrollEmployeeProfileChanges, getPayrollTransactions } from '@/domain/payroll/Payroll.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { formatToLocalDate, LocalDate } from '@/utils/datetime.util';
import { useCallback, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetLatestEmployeePayrollLock = (search: EmployeePayrollLockLatestSearchRequest): UseQueryResult<LatestPayrollLocks[]> => {
    const [latestEmployeePayrollLocks, setLatestEmployeePayrollLocks] = useState<LatestPayrollLocks[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeePayrollLocks = useCallback(async (search: EmployeePayrollLockLatestSearchRequest) => {
        try {
            const employeePayrollLocks = await searchLatestEmployeePayrollLock(search);
            setLatestEmployeePayrollLocks(employeePayrollLocks);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchEmployeePayrollLocks(search).catch(handleError);
    }, [fetchEmployeePayrollLocks, search]);

    return {
        data: latestEmployeePayrollLocks,
        setData: setLatestEmployeePayrollLocks,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeePayrollLocks(search),
    };
};

export const useGetEmployeePayrollLock = (search: EmployeePayrollLockSearchRequest): UseQueryResult<EmployeePayrollLock[]> => {
    const [employeePayrollLocks, setEmployeePayrollLocks] = useState<EmployeePayrollLock[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeePayrollLocks = useCallback(async (search: EmployeePayrollLockSearchRequest) => {
        try {
            const employeePayrollLocks = await searchEmployeePayrollLock(search);
            setEmployeePayrollLocks(employeePayrollLocks);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchEmployeePayrollLocks(search).catch(handleError);
    }, [fetchEmployeePayrollLocks, search]);

    return {
        data: employeePayrollLocks,
        setData: setEmployeePayrollLocks,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeePayrollLocks(search),
    };
};

export const useGetPayrollProfileChanges = ({
    departmentIds,
    employeeIds,
    endDate,
    locationIds,
    startDate,
}: PayrollProfileChangeSearch): UseQueryResult<EmployeePayrollProfileChange[]> => {
    const [changes, setChanges] = useState<EmployeePayrollProfileChange[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchPayrollEmployeeProfileChanges = useCallback(
        async ({
            startDate,
            endDate,
            ...restSearch
        }: Omit<PayrollProfileChangeSearch, 'startDate' | 'endDate'> & {
            startDate: LocalDate;
            endDate: LocalDate;
        }) => {
            try {
                const profileChanges = await getPayrollEmployeeProfileChanges({
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    ...restSearch,
                });
                setChanges(profileChanges);
            } catch (error) {
                setError(error);
            }

            setIsLoading(false);
        },
        [],
    );

    // Use string format to avoid unnecessary re-renders when the date changes but the value is the same
    const startDateAsLocalDate = formatToLocalDate(startDate);
    const endDateAsLocalDate = formatToLocalDate(endDate);

    const search = {
        departmentIds,
        employeeIds,
        locationIds,
        startDate: startDateAsLocalDate,
        endDate: endDateAsLocalDate,
    };

    useDeepCompareEffect(() => {
        fetchPayrollEmployeeProfileChanges(search).catch(handleError);
    }, [fetchPayrollEmployeeProfileChanges, startDateAsLocalDate, endDateAsLocalDate, departmentIds, employeeIds, locationIds]);

    return {
        data: changes,
        setData: setChanges,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchPayrollEmployeeProfileChanges(search),
    };
};

export const useGetPayrollTransactions = ({ startDate }: { startDate: Date }): UseQueryResult<LeaveTransaction[]> => {
    const [transactions, setTransactions] = useState<LeaveTransaction[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchPayrollTransactions = useCallback(async ({ startDate }: { startDate: LocalDate }) => {
        try {
            const transactions = await getPayrollTransactions({ startDate: new Date(startDate) });
            setTransactions(transactions);
        } catch (error) {
            setError(error);
        }

        setIsLoading(false);
    }, []);

    // Use string format to avoid unnecessary re-renders when the date changes but the value is the same
    const startDateAsLocalDate = formatToLocalDate(startDate);

    useEffect(() => {
        fetchPayrollTransactions({ startDate: startDateAsLocalDate }).catch(handleError);
    }, [fetchPayrollTransactions, startDateAsLocalDate]);

    return {
        data: transactions,
        setData: setTransactions,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchPayrollTransactions({ startDate: startDateAsLocalDate }),
    };
};
