import { AvatarCell, EmployeeCell, StackedAvatarsCell } from '@/Components/ag-grid-wrapper/column-types/Employee';
import { EmployeeSectionDocumentLink } from '@/Components/section/EmployeeSectionDocumentLink/EmployeeSectionDocumentLink';
import { SectionField } from '@/Components/section/types';
import { getEmployeeDocumentDownloadUrl } from '@/domain/document/Document.service';
import { getEmployeeSectionFieldDocumentUrl } from '@/domain/employee-section/EmployeeSection.service';
import { EmployeeAvatar as EmployeeAvatarType } from '@/domain/employee/Employee.model';
import { Label } from '@/domain/label/Label.model';
import { FieldDefinition, SectionFieldValue } from '@/domain/section-setting/Section.model';
import { getEnumTranslation } from '@/domain/section-setting/Section.service';
import { getCountry } from '@/utils/countries.util';
import { formatInDefaultDate, formatMinutesToHours, LocalDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getRoundedNumber, RoundingType } from '@/utils/math.util';
import { ColDef } from '@ag-grid-community/core';
import { Stack } from '@mui/material';
import i18next from 'i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getColumnTypes = (compact: boolean) => {
    const date: ColDef<unknown, LocalDate> = {
        valueFormatter: ({ value }) => {
            if (!value) {
                return '';
            }
            return formatInDefaultDate(value);
        },
        cellClass: ['dateCH'],
    };

    const documents: ColDef<unknown, SectionField> = {
        autoHeight: true,
        // use for export
        valueFormatter: params => {
            if (!params?.value?.documents) {
                return ' ';
            }
            return params?.value?.documents?.map(document => document.name).join(', ');
        },
        cellRenderer: ({ value }: { value: SectionField }) => {
            return (
                <Stack direction={'row'} columnGap={1} rowGap={0} flexWrap={'wrap'}>
                    {value?.documents?.map(document => {
                        return (
                            <EmployeeSectionDocumentLink
                                document={document}
                                onDownload={contentDisposition => getEmployeeDocumentDownloadUrl(document.id, contentDisposition)}
                                key={document.id}
                            />
                        );
                    })}
                </Stack>
            );
        },
        cellClass: ['display-flex'],
    };

    const sectionFieldDocuments: ColDef<unknown, SectionField> = {
        // use for export
        valueFormatter: params => {
            if (!params?.value?.documents) {
                return ' ';
            }
            return params?.value?.documents?.map(document => document.name).join(', ');
        },
        cellRenderer: ({ value }: { value: SectionField }) => {
            return (
                <Stack direction={'row'} columnGap={1} rowGap={0} flexWrap={'wrap'}>
                    {value?.documents?.map(document => {
                        return (
                            <EmployeeSectionDocumentLink
                                document={document}
                                onDownload={contentDisposition => getEmployeeSectionFieldDocumentUrl(document.id, contentDisposition)}
                                key={document.id}
                            />
                        );
                    })}
                </Stack>
            );
        },
        autoHeight: true,
        cellClass: ['display-flex'],
    };

    const label: ColDef<unknown, Label> = {
        valueFormatter: ({ value }) => (value ? getLabelTranslation(value) : ''),
    };

    const employee: ColDef<unknown, EmployeeAvatarType> = {
        cellRenderer: ({ value, cellNavDisabled = false }: { cellNavDisabled: boolean; value: EmployeeAvatarType }) => (
            <EmployeeCell value={value} compact={compact} navDisabled={cellNavDisabled} />
        ),
        valueFormatter: ({ value }) => value?.displayName ?? '',
        cellClass: ['display-flex'],
    };

    const stackedAvatars: ColDef<unknown, EmployeeAvatarType[]> = {
        cellRenderer: ({ value, cellNavDisabled }: { cellNavDisabled: boolean; value: EmployeeAvatarType[] }) => (
            <StackedAvatarsCell value={value} compact={compact} cellNavDisabled={cellNavDisabled} />
        ),
        // use for export and sorting
        valueFormatter: ({ value }) => value?.map(e => e.displayName).join(', ') ?? '',
        // We have to defined comparator because valueFormatter sorting doesn't work when field is not defined (ex in review caused by flatmap )
        comparator: (a, b) => {
            const aDisplayName = a?.[0]?.displayName ?? '';
            const bDisplayName = b?.[0]?.displayName ?? '';
            return aDisplayName.localeCompare(bDisplayName);
        },
        cellClass: ['display-flex', 'full-width'],
    };

    const selection: ColDef = {
        width: 56,
        resizable: false,
        sortable: false,
        showDisabledCheckboxes: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        pinned: 'left',
        lockPinned: true,
        suppressSizeToFit: true,
        suppressMovable: true,
        checkboxSelection: true,
        // Use to allow the user to click outside the checkbox to select the row
        onCellClicked: ({ node }) => {
            node.setSelected(!node.isSelected());
        },
    };

    const avatar: ColDef<unknown, EmployeeAvatarType> = {
        cellRenderer: ({ value }: { value: EmployeeAvatarType }) => (value ? <AvatarCell value={value} /> : ''),
        cellClass: ['display-flex'],
        resizable: false,
        sortable: false,
        width: 60,
    };

    const actionMenu: ColDef = {
        width: 50,
        lockPinned: true,
        pinned: 'right',
        suppressSizeToFit: true,
        resizable: false,
        sortable: false,
    };

    /**
     * @deprecated
     * This column type is deprecated and should not be used anymore.
     * Use the `hours` column type instead.
     **/
    const minutesToHours: ColDef<unknown, number> = {
        // Convert the value from minutes to hours to fix the export issue with number values
        // This is the value used in the export function from ag grid
        valueGetter: ({ data, colDef }) => {
            if (data && colDef.field) {
                return getRoundedNumber((data[colDef.field] as number) / 60, RoundingType.NEAREST_2_DECIMALS);
            }
            return 0;
        },
        // format the number to display in ag grid (this value wont be used in the export)
        valueFormatter: ({ data, colDef }) => {
            if (!data || !colDef.field || !data[colDef.field]) {
                return '-';
            }
            return formatMinutesToHours(data[colDef.field] as number);
        },
    };

    /**
     * Column type to display the hours in the grid
     * Value is expected to be in minutes
     **/
    const hours: ColDef<unknown, number> = {
        valueFormatter: ({ value }) => (value ? formatMinutesToHours(value) : '-'),
    };

    const fieldDefinition: ColDef<unknown, Partial<FieldDefinition>> = {
        valueFormatter: ({ value: sectionFieldDefinition }) => {
            if (!sectionFieldDefinition) {
                return '';
            }

            // TODO : We can use yup to validate the value because with ag-grid we cannot be sure that the value is correct
            return getFieldDefinitionTranslation(sectionFieldDefinition);
        },
    };

    const fieldValue: ColDef<unknown, SectionFieldValue> = {
        valueFormatter: ({ value }) => {
            // TODO add yup validation
            if (!value?.sectionFieldDefinition) {
                return '-';
            }
            const fieldValue = getFieldValue(value);
            if (fieldValue === undefined) {
                return '-';
            }
            // This is a hack to fix excel export issue with empty string
            // If empty ag grid will use valueGetter to get the value and it will return [object Object]
            if (fieldValue === '') {
                return ' ';
            }
            return fieldValue;
        },
    };

    return {
        selection,
        actionMenu,
        // Label
        label,
        // Employee
        employee,
        avatar,
        stackedAvatars,
        // Documents
        sectionFieldDocuments,
        documents,
        // Date
        date,
        minutesToHours,
        hours,
        // Custom column type to display data structure based on field definition
        fieldDefinition,
        fieldValue,
    };
};

export const getFieldDefinitionTranslation = (sectionFieldDefinition: Partial<FieldDefinition>): string => {
    if (sectionFieldDefinition?.id) {
        return getLabelTranslation(sectionFieldDefinition.name);
    }

    if (!sectionFieldDefinition?.fieldType) {
        return '';
    }

    return i18next.t('field_definition_type.enum', {
        context: sectionFieldDefinition.fieldType.replace('CURRENT_', ''),
        defaultValue: sectionFieldDefinition.fieldType,
    });
};

const getFieldValue = (field: SectionFieldValue): string => {
    switch (field.sectionFieldDefinition.valueType) {
        case 'DATE':
            return formatInDefaultDate(field.dateValue);
        case 'NUMBER':
            return field.numberValue?.toString() ?? '';
        case 'PHONE_NUMBER':
        case 'STRING':
        case 'IBAN_NUMBER':
        case 'AVS_NUMBER':
            return field.stringValue ?? '';
        case 'SECTION_FIELD_DOCUMENT':
            return field.documents.map(d => d.name).join(', ');
        case 'COUNTRY':
            return field.stringValue ? getCountry(field.stringValue).label : '';
        case 'LABEL':
            return field.labelValue ? getLabelTranslation(field.labelValue) : '';
        case 'ENUM':
            return field.stringValue ? getEnumTranslation(field.sectionFieldDefinition.fieldType, field.stringValue) : '';
        case 'CUSTOM_LIST_ITEM':
        case 'CUSTOM_MULTI_LIST_ITEM':
            return field.customListItemReferences?.map(c => getLabelTranslation(c.label)).join(', ');
        case 'EMPLOYEE':
            if (field.employeeReferences.length) {
                return field.employeeReferences?.map(e => e.displayName).join(', ');
            }

            // Type doesn't accept null or undefined but api can return null instead of empty array
            return field.employeeValues?.map(e => e.displayName).join(', ');
        default:
            return field.sectionFieldDefinition.valueType;
    }
};
