import { Components, Theme } from '@mui/material';

import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@/assets/Icons/Icons';

export const MuiAlert: Components<Omit<Theme, 'components'>>['MuiAlert'] = {
    defaultProps: {
        elevation: 1,
        iconMapping: {
            success: SuccessIcon({}),
            error: ErrorIcon({}),
            warning: WarningIcon({}),
            info: InfoIcon({}),
        },
    },
    styleOverrides: {
        standardInfo: ({ theme }) => ({
            backgroundColor: theme.palette.info.light,
        }),
        standardSuccess: ({ theme }) => ({
            backgroundColor: theme.palette.success.light,
        }),
        standardWarning: ({ theme }) => ({
            backgroundColor: theme.palette.warning.light,
        }),
        standardError: ({ theme }) => ({
            backgroundColor: theme.palette.error.light,
        }),
    },
};

export const MuiAlertTitle: Components<Omit<Theme, 'components'>>['MuiAlertTitle'] = {
    styleOverrides: {
        root: {
            fontWeight: 700,
            marginTop: 0,
        },
    },
};
