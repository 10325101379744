import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { AsyncSelectFilter, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { getLocations } from '@/domain/location/Location.service';
import { getLabelTranslation } from '@/utils/language.util';
import { useTranslation } from 'react-i18next';
import { getDepartments } from '@/domain/department/Department.service';
import { searchRoles } from '@/domain/role/Role.service';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeFilterType } from '@/domain/employee/Employee.service';

/**
 * Hook to get the list of filter for employee review
 */
export const useEmployeeReviewFilters = (): { filters: (SelectFilter | AsyncSelectFilter)[] } => {
    const { t } = useTranslation();

    const filters: (SelectFilter | AsyncSelectFilter)[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'LOCATION_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            key: 'DEPARTMENT_IDS' satisfies EmployeeFilterType,
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE_ROLE_TITLE' }),
            type: 'multi-select',
            key: 'ROLE_IDS' satisfies EmployeeFilterType,
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const roles = await searchRoles();
                return roles?.map(role => ({
                    label: role.name,
                    value: role.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE_REVIEW_STATUS' }),
            type: 'multi-select',
            key: 'status',
            selectMode: 'SYNC',
            options: [
                {
                    label: t('reviews.employee_review_status.input_needed'),
                    value: EmployeeReviewStatus.INPUT_NEEDED,
                },
                {
                    label: t('reviews.employee_review_status.discussion_started'),
                    value: EmployeeReviewStatus.DISCUSSION_STARTED,
                },
                {
                    label: t('reviews.employee_review_status.discussion_submitted'),
                    value: EmployeeReviewStatus.DISCUSSION_SUBMITTED,
                },
                {
                    label: t('reviews.employee_review_status.employee_approved'),
                    value: EmployeeReviewStatus.EMPLOYEE_APPROVED,
                },
                {
                    label: t('reviews.employee_review_status.cancelled'),
                    value: EmployeeReviewStatus.CANCELLED,
                },
                {
                    label: t('reviews.employee_review_status.employee_review_closed'),
                    value: EmployeeReviewStatus.CLOSED,
                },
            ],
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
