import { FC, lazy, Suspense } from 'react';

import { ForgotPassword } from '@/auth/ForgotPassword/ForgotPassword';
import { Login } from '@/auth/Login/Login';
import { ResetPassword } from '@/auth/ResetPassword/ResetPassword';
import { bugsnagOptions } from '@/index';
import { ActivateAccountPage } from '@/page/account/ActivateAccountPage';
import { documentRoutes } from '@/page/document/DocumentRoutes';
import { employeeProfileRoutes } from '@/page/employee-profile/EmployeeProfileRoutes';
import { employeeSettingsRoutes } from '@/page/employee-setting/EmployeeSettingsRoutes';
import { homeRoutes } from '@/page/home/HomeRoutes';
import { Layout } from '@/page/layout/Layout';
import { leavesRoutes } from '@/page/leave/LeaveRoutes';
import { objectivesRoutes } from '@/page/objective/ObjectiveRoutes';
import { payrollRoutes } from '@/page/payroll/PayrollRoutes';
import { peopleRoutes } from '@/page/people/PeopleRoutes';
import { planningRoutes } from '@/page/planning/PlanningRoutes';
import { PrivacyPolicyPage } from '@/page/privacy-policy/PrivacyPolicyPage';
import { reportRoutes } from '@/page/report/ReportRoutes';
import { reviewRoutes } from '@/page/review/ReviewsRoutes';
import { companySettingRoutes } from '@/page/setting/CompanySettingsRoutes';
import { surveyRoutes } from '@/page/survey/SurveyRoutes';
import { timesheetRoutes } from '@/page/timesheet/TimesheetRoutes';
import { useAppSelector } from '@/stores/store';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { ReactRouterRoutingProvider } from '@bugsnag/react-router-performance';
import { CircularProgress } from '@mui/material';
import { createBrowserRouter, Navigate, Outlet, RouteObject, RouterProvider } from 'react-router-dom';
import { PageContainer } from './PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';

const MobileAdditionalPages = lazy(() => import('@/page/MobileAdditionalPages'));
const EmployeeSurvey = lazy(() => import('@/page/survey/AnonymousEmployeeSurveyPage'));

const publicRoutes: RouteObject[] = [
    {
        path: '/activate-account',
        element: <ActivateAccountPage />,
        errorElement: <RouterBoundary />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
        errorElement: <RouterBoundary />,
    },

    {
        path: '/reset-password',
        element: <ResetPassword />,
        errorElement: <RouterBoundary />,
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicyPage />,
        errorElement: <RouterBoundary />,
    },
    {
        path: '/anonymous-survey',
        element: (
            <Suspense fallback={<CircularProgress />}>
                <EmployeeSurvey />
            </Suspense>
        ),
        errorElement: <RouterBoundary />,
    },
];

const privateRouter: RouteObject = {
    path: '',
    element: (
        <Layout>
            <Outlet />
        </Layout>
    ),
    errorElement: <RouterBoundary />,
    children: [
        ...homeRoutes,
        ...employeeProfileRoutes,
        ...peopleRoutes,
        ...leavesRoutes,
        ...planningRoutes,
        ...timesheetRoutes,
        ...documentRoutes,
        ...payrollRoutes,
        ...surveyRoutes,
        ...reportRoutes,
        ...reviewRoutes,
        ...objectivesRoutes,
        ...companySettingRoutes,
        ...employeeSettingsRoutes,
        {
            path: '/additional-pages',
            element: (
                <PageContainer breadCrumb={[{ nameKey: 'pages.additional_pages' }]}>
                    <Suspense fallback={<CircularProgress />}>
                        <MobileAdditionalPages />
                    </Suspense>
                </PageContainer>
            ),
        },
        ...publicRoutes,
        {
            path: '*',
            // TODO replace by 404 page
            element: <Navigate to='/' replace />,
        },
    ],
};

export const Router: FC = () => {
    const authenticated = useAppSelector(state => state.currentEmployee.authenticated);
    const authenticatedRoutes =
        authenticated && location.pathname !== '/anonymous-survey' && location.pathname !== '/privacy-policy' && location.pathname !== '/reset-password';

    const routes = authenticatedRoutes
        ? [privateRouter]
        : [
              ...publicRoutes,
              {
                  path: '*',
                  element: <Login />,
                  errorElement: <RouterBoundary />,
              },
          ];

    BugsnagPerformance.start({
        ...bugsnagOptions,
        logger: console,
        routingProvider: new ReactRouterRoutingProvider(routes, '/'),
    });

    const router = createBrowserRouter(routes, { basename: '/' });

    return <RouterProvider router={router} />;
};
