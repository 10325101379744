import { SplitButtonGroup } from '@/Components/button-group/SplitButtonGroup';
import { createTimesheetPayment } from '@/api/timesheet-payment/TimesheetPayment.api';
import { Employee } from '@/domain/employee/Employee.model';
import { canManagePendingTimesheets, canManageTimesheetAdjustments, canManageTimesheetPayments } from '@/domain/permission/Permission.service';
import { timesheetAdjustmentRequest } from '@/domain/timesheet-adjustment/TimesheetAdjustment.service';
import { TimesheetAction } from '@/domain/timesheet/Timesheet.model';
import { AddPaymentAdjustmentDialog, PaymentAdjustmentDialogData } from '@/page/employee-profile/employee-profile-overtime/AddPaymentAdjustmentDialog';
import { TimesheetDialog } from '@/page/timesheet/timesheet-dialog/TimesheetDialog';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Add01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    employee: Employee;
    onExportClick: () => void;
    onSuccess: () => void;
};

export const TimesheetsHistoryActionsButtons: FC<Props> = ({ employee, onExportClick, onSuccess }) => {
    const { t } = useTranslation();

    const [addTimesheetDialogOpen, setAddTimesheetDialogOpen] = useState(false);
    const [addPaymentDialogOpen, setAddPaymentDialogOpen] = useState(false);
    const [addAdjustmentDialogOpen, setAddAdjustmentDialogOpen] = useState(false);

    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    // TODO improve this with TS 5.5 and filter the buttons based on the policies
    const buildMenuItems = () => {
        const menuItemsProps = [];

        if (canManagePendingTimesheets(policies, employee.id)) {
            menuItemsProps.push({
                title: t('timesheets.timesheet'),
                onClick: () => setAddTimesheetDialogOpen(true),
                key: 'addTimesheetDialogButton',
            });
        }
        if (canManageTimesheetPayments(policies, employee.id)) {
            menuItemsProps.push({
                title: t('timesheets.history_page.add_payment'),
                onClick: () => setAddPaymentDialogOpen(true),
                key: 'addPaymentsDialogButton',
            });
        }

        if (canManageTimesheetAdjustments(policies, employee.id)) {
            menuItemsProps.push({
                title: t('timesheets.history_page.add_adjustment'),
                onClick: () => setAddAdjustmentDialogOpen(true),
                key: 'addAdjustmentsDialogButton',
            });
        }

        menuItemsProps.push({
            title: t('timesheets.history_page.export_excel'),
            onClick: onExportClick,
            key: 'exportAsPDFDialogButton',
        });

        return menuItemsProps;
    };

    const menuItemsProps = buildMenuItems();

    const handleAddTimesheetDialogSave = () => {
        setAddTimesheetDialogOpen(false);
        onSuccess();
    };

    // Possible improvement: Put service call in dialog component
    const handlePaymentDialogSave = async (data: PaymentAdjustmentDialogData) => {
        try {
            await createTimesheetPayment({
                ...data,
                employeeId: employee.id,
            });
            setAddPaymentDialogOpen(false);
            onSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    const handleAdjustmentDialogSave = async (data: PaymentAdjustmentDialogData) => {
        try {
            await timesheetAdjustmentRequest({
                ...data,
                employeeId: employee.id,
            });
            setAddAdjustmentDialogOpen(false);
            onSuccess();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <>
            <SplitButtonGroup
                buttonProps={{
                    variant: 'contained',
                    startIcon: <Add01Icon size={20} />,
                    size: 'small',
                }}
                menuItemsProps={menuItemsProps}
                ariaLabel={'Timesheet histories actions buttons'}
            />

            {addTimesheetDialogOpen && (
                <TimesheetDialog
                    defaultReferenceDate={new Date()}
                    mode={TimesheetAction.CREATE}
                    open={true}
                    employee={employee}
                    onClose={() => setAddTimesheetDialogOpen(false)}
                    onSave={handleAddTimesheetDialogSave}
                />
            )}

            {addPaymentDialogOpen && (
                <AddPaymentAdjustmentDialog open={true} onClose={() => setAddPaymentDialogOpen(false)} isAdjustment={false} onSave={handlePaymentDialogSave} />
            )}

            {addAdjustmentDialogOpen && (
                <AddPaymentAdjustmentDialog
                    open={true}
                    onClose={() => setAddAdjustmentDialogOpen(false)}
                    isAdjustment={true}
                    onSave={handleAdjustmentDialogSave}
                />
            )}
        </>
    );
};
