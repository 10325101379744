import {
    ManageReviewCycle,
    Review,
    ReviewCycleCreationMutation,
    ReviewOffboardingCreationMutation,
    ReviewOnboardingCreationMutation,
    ReviewOneShotCreationMutation,
    ReviewPatchMutation,
    ReviewSearch,
    ReviewSendReminderMutation,
    ReviewStartMutation,
    ReviewStatus,
    ReviewUpdateMutation,
} from '@/domain/review/Review.model';
import { reviewApi } from '@/api/review/Review.api';
import { StepWorkflow } from '@/Components/stepper-workflow/StepperWorkflow';

export const isSelfReviewRequired = (review: Review): boolean => {
    return review?.includePreparationStep && review?.contributorTypes?.includes('SELF');
};

export const isManagerReviewRequired = (review: Review): boolean => {
    return review?.includePreparationStep && review?.contributorTypes?.includes('MANAGER');
};

export const isUpwardReviewAsked = (review: Review): boolean => {
    return review?.includePreparationStep && review?.contributorTypes?.includes('UPWARD');
};

export const isPeerReviewAsked = (review: Review): boolean => {
    return review?.includePreparationStep && review?.contributorTypes?.includes('PEER');
};

export const isEmployeeValidationRequired = (review: Review): boolean => {
    return review?.includeValidationStep;
};

export const isReviewStarted = (review: Review): boolean => review?.reviewStatus === ReviewStatus.STARTED;
export const isReviewDraft = (review: Review): boolean => review?.reviewStatus === ReviewStatus.DRAFT;
export const isReviewClosed = (review: Review): boolean => review?.reviewStatus === ReviewStatus.CLOSED;

export const getReview = (reviewId: number | string): Promise<Review> => {
    return reviewApi.getReview(reviewId);
};

export const updateReview = (reviewId: number | string, mutation: ReviewUpdateMutation): Promise<Review> => {
    return reviewApi.updateReview(reviewId, mutation);
};

export const deleteReview = (reviewId: number): Promise<void> => {
    return reviewApi.deleteReview(reviewId);
};

export const createReviewCycle = (mutation: ReviewCycleCreationMutation): Promise<Review> => {
    return reviewApi.createReviewCycle(mutation);
};

export const createOneShotReview = (mutation: ReviewOneShotCreationMutation): Promise<Review> => {
    return reviewApi.createOneShotReview(mutation);
};

export const createOnboardingReview = (mutation: ReviewOnboardingCreationMutation): Promise<Review> => {
    return reviewApi.createOnboardingReview(mutation);
};

export const createOffboardingReview = (mutation: ReviewOffboardingCreationMutation): Promise<Review> => {
    return reviewApi.createOffboardingReview(mutation);
};

export const startReview = (reviewId: number, mutation: ReviewStartMutation): Promise<Review> => {
    return reviewApi.startReview(reviewId, mutation);
};

export const closeReview = (reviewId: number): Promise<Review> => {
    return reviewApi.closeReview(reviewId);
};

export const reopenReview = (reviewId: number): Promise<Review> => {
    return reviewApi.reopenReview(reviewId);
};

export const searchReviews = (search: ReviewSearch = {}): Promise<Review[]> => {
    return reviewApi.searchReviews(search);
};

export const sendReminder = (reviewId: number, request: ReviewSendReminderMutation): Promise<void> => {
    return reviewApi.sendReminder(reviewId, request);
};

export const patchReview = (reviewId: number, mutation: ReviewPatchMutation): Promise<Review> => {
    return reviewApi.patchReview(reviewId, mutation);
};

export const getSetupStep = (): {
    steps: StepWorkflow[];
    getStep: (step: ManageReviewCycle) => StepWorkflow;
} => {
    const wizardStep = { stepName: 'SETUP', stepTranslationKey: 'reviews.wizard.setup' };
    const reviewersAndPreviewStep = {
        stepName: 'REVIEWERS_AND_PREVIEW',
        stepTranslationKey: 'reviews.wizard.add_reviewers_and_preview',
    };
    const invitationsStep = { stepName: 'INVITATIONS', stepTranslationKey: 'reviews.wizard.invitations' };
    const steps = [wizardStep, reviewersAndPreviewStep, invitationsStep];

    const getStep = (step: ManageReviewCycle) => {
        const findStep = steps.find(s => s.stepName === step);
        if (!findStep) {
            throw new Error('Step not found');
        }
        return findStep;
    };

    return {
        steps: [wizardStep, reviewersAndPreviewStep, invitationsStep],
        getStep,
    };
};
