import { BasicMenu } from '@/Components/basic-menu/BasicMenu';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { ThirdParty } from '@/domain/third-party/ThirdParty.model';
import { deleteThirdParty, toggleThirdParty } from '@/domain/third-party/ThirdParty.service';
import { useGetThirdParties } from '@/hooks/third-party/ThirdParty.hook';
import { handleError } from '@/utils/api.util';
import { ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { RogerColDef } from '@/Components/ag-grid-wrapper/AgGridWrapper';
import { EmptyState } from '@/Components/empty-state/EmptyState';
import { EmptyStateIcon } from '@/Components/empty-state/icons/EmptyStateIcon';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { useAppSelector } from '@/stores/store';
import { MoreVerticalIcon } from '@/assets/Icons/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

export const ThirdPartySettingsPage: FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const route = useLocation();
    const theme = useTheme();

    const {
        data: thirdParties = [],
        isLoading,
        isError,
        error: errorThirdParties,
        refetch: refetchThirdParties,
        setData: setThirdParties,
    } = useGetThirdParties();

    const realm = useAppSelector(state => state.ui.currentRealm);

    if (!realm) {
        return;
    }

    const handleDelete = async (id: number) => {
        try {
            await deleteThirdParty(id);
            await refetchThirdParties();
        } catch (e) {
            handleError(e);
        }
    };

    const goToThirdPartySetting = (thirdParty?: ThirdParty) => {
        if (thirdParty) {
            navigate(`${route.pathname}/${thirdParty.id}`);
        }
    };

    const handleToggleClick = async (id: number, enable: boolean) => {
        await toggleThirdParty(id, enable);
        setThirdParties(thirdParties.map(thirdParty => (thirdParty.id === id ? { ...thirdParty, enabled: enable } : thirdParty)));
    };

    const getMenuItems = (params: ICellRendererParams<ThirdParty>) => {
        const id = params.data?.id;
        if (!id) {
            return [];
        }
        return [
            {
                title: t('general.edit'),
                onClick: () => goToThirdPartySetting(params.data),
            },
            {
                title: params.data?.enabled ? t('general.disable') : t('general.enable'),
                onClick: () => handleToggleClick(id, !params.data?.enabled),
            },
            {
                title: t('general.delete'),
                onClick: () => handleDelete(id),
            },
        ];
    };

    const cellActionRenderer = (params: ICellRendererParams<ThirdParty>) => <BasicMenu items={getMenuItems(params)} endIcon={<MoreVerticalIcon />} />;

    const columns: RogerColDef<ThirdParty>[] = [
        {
            field: 'name',
            headerName: t('third_party_setting_page.table_headers.name'),
        },
        {
            field: 'enabled',
            headerName: t('third_party_setting_page.table_headers.enabled'),
            cellStyle: {
                'padding-left': theme.spacing(2),
            },
        },
        {
            // integrations and public api are merged in the same column
            headerName: t('third_party_setting_page.table_headers.connectors'),
            colId: 'connectors',
            valueGetter: ({ data }) => {
                const hasPublicApi = !!data?.publicApiIntegration?.id;
                return [hasPublicApi ? 'PUBLIC_API' : undefined, ...(data?.integrations?.map(connector => connector.integrationType) ?? [])].filter(
                    connectors => connectors !== undefined,
                );
            },
            valueFormatter: ({ value }: { value: string[] }) => {
                return value.map(v => t('third_party_setting_page.connectors_type', { context: v, defaultValue: v }))?.join(', ') ?? '';
            },
        },
        {
            type: 'actionMenu',
            cellRenderer: cellActionRenderer,
        },
    ];

    const config: ConfigType<ThirdParty> = {
        type: 'table',
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => navigate(`${route.pathname}/new`),
        },
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<ThirdParty>) => goToThirdPartySetting(params.data),
                disableAutoSize: true,
            },
            columns,
        },
    };

    const emptyState = <EmptyState icon={<EmptyStateIcon />} flex={1} title={t('third_party_setting_page.empty')} />;

    return (
        <StateHandler
            isLoading={isLoading}
            isError={isError}
            error={errorThirdParties}
            // We cannot use empty state here because button to create new third party is in child component
            // TODO: Improve CompanySettingsLayout to handle this case
            isEmpty={false}
            emptyStateComponent={emptyState}
        >
            <CompanySettingsLayout options={config} data={thirdParties} isSearchAvailable={false} />
        </StateHandler>
    );
};
