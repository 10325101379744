import { EmployeeReviewFeedbackQuestionFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { EmployeeReviewFeedbackQuestion } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackQuestion';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import { FC, Ref, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SquareLock02Icon, ViewIcon, ViewOffIcon } from 'hugeicons-react';
import { getLabelTranslation } from '@/utils/language.util';

export type EmployeeReviewSummaryPrivateQuestion = {
    employeeReviewFeedbackSummaryQuestionForm: EmployeeReviewFeedbackQuestionFormType;
    onUpdate: (item: EmployeeReviewFeedbackQuestionFormType) => void;
    innerRef: Ref<HTMLDivElement>;
    disabled: boolean;
    error: FieldError | undefined;
};

export const EmployeeReviewFeedbackSummaryPrivateQuestion: FC<EmployeeReviewSummaryPrivateQuestion> = ({
    employeeReviewFeedbackSummaryQuestionForm,
    onUpdate,
    innerRef,
    disabled,
    error,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Stack
            component={Paper}
            ref={innerRef}
            sx={{
                border: error ? `1px solid ${theme.palette.error.main}` : 'none',
            }}
        >
            <Accordion
                expanded={expanded}
                disableGutters
                defaultExpanded
                sx={{
                    backgroundColor: theme.palette.warning.light,
                }}
            >
                <AccordionSummary onClick={() => setExpanded(!expanded)}>
                    <Stack justifyContent='space-between' direction={'row'} flex={1} gap={1}>
                        <Stack alignItems='center' direction={'row'} gap={1}>
                            <SquareLock02Icon color={theme.palette.warning.dark} />
                            <Stack>
                                <Typography variant='body1bold'>{t('reviews.review_summary.private_manager_assessment')}</Typography>
                                <Typography variant='body2'>{t('reviews.review_summary.private_manager_assessment_description')}</Typography>
                            </Stack>
                        </Stack>

                        <IconButton disabled>
                            {expanded ? <ViewOffIcon color={theme.palette.warning.dark} /> : <ViewIcon color={theme.palette.warning.dark} />}
                        </IconButton>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 0 }}>
                    <Typography variant='body2bold' sx={{ whiteSpace: 'pre-wrap' }}>
                        {getLabelTranslation(employeeReviewFeedbackSummaryQuestionForm.title)} {employeeReviewFeedbackSummaryQuestionForm.required && '*'}
                    </Typography>

                    {employeeReviewFeedbackSummaryQuestionForm.instruction && (
                        <Typography
                            variant='body2'
                            className={'rich_text'}
                            dangerouslySetInnerHTML={{
                                __html: getLabelTranslation(employeeReviewFeedbackSummaryQuestionForm.instruction),
                            }}
                        />
                    )}

                    <EmployeeReviewFeedbackQuestion
                        sx={{
                            backgroundColor: theme.palette.warning.light,
                            border: error ? `1px solid ${theme.palette.error.main}` : 'none',
                        }}
                        questionItem={employeeReviewFeedbackSummaryQuestionForm}
                        onUpdate={item => {
                            // Todo: improve typing and use never to avoid inferring the type
                            if (item.type !== 'SKILL') {
                                onUpdate(item);
                            }
                        }}
                        disabled={disabled}
                        // have to cast the error because the type of the error in the output of fieldState error is not the right one
                        error={
                            error as {
                                comment?: FieldError;
                                score?: FieldError;
                            }
                        }
                        innerRef={innerRef}
                        isSummary={true}
                    />
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};
