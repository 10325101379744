import { employeeAddressAPI } from '@/api/employee/EmployeeAddress.api';
import { SectionLoading } from '@/Components/section/SectionLoading';
import { TableSection } from '@/Components/section/TableSection/TableSection';
import { SectionActionButton, SectionField, SectionRow } from '@/Components/section/types';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { EmployeeProfileChange } from '@/domain/employee-pending-change/EmployeePendingChange.model';
import { getSectionAction } from '@/domain/employee-pending-change/EmployeePendingChange.service';
import {
    createEmployeeAddressPendingRequest,
    deleteEmployeeAddressPendingRequest,
    getSectionActionButton,
    updateEmployeeAddressPendingRequest,
} from '@/domain/employee/Employee.service';
import { EmployeeAddress, EmployeeAddressCreateMutation, EmployeeAddressUpdateMutation } from '@/domain/employee/EmployeeAddress.model';
import { canManageEmployeeAddresses, canManagePendingEmployeeAddresses } from '@/domain/permission/Permission.service';
import { useGetEmployeeAddresses } from '@/hooks/employee-address/EmployeeAddress.hook';
import { EmployeeAddressSectionFormValues } from '@/page/employee-profile/employee-profile-info/EmployeeAddressSection/Components/EmployeeAddressDialog.util';
import { useAddressColumns } from '@/page/employee-profile/employee-profile-info/EmployeeAddressSection/EmployeeAdressSection.hook';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getCountry } from '@/utils/countries.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { ICellRendererParams } from '@ag-grid-community/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmployeeFieldMoreButton } from '../EmployeeFieldMoreButton/EmployeeFieldMoreButton';
import { EmployeeAddressDialog } from './Components/EmployeeAddressDialog';

type Props = {
    employeeId: number;
    pendingRows: EmployeeProfileChange['pendingRows'];
};

export const EmployeeAddressSection: FC<Props> = ({ employeeId, pendingRows }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const [selectedEmployeeAddress, setSelectedEmployeeAddress] = useState<EmployeeAddress>();
    const [employeeAddressDialogOpen, setEmployeeAddressDialogOpen] = useState<boolean>(false);
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const {
        data: employeeAddresses = [],
        setData: setEmployeeAddresses,
        refetch: fetchEmployeeAddresses,
        isLoading,
        isError,
        isFetching = true,
        error,
    } = useGetEmployeeAddresses(employeeId);

    const columns = useAddressColumns();

    const convertEmployeeAddressToSectionRow = (address: EmployeeAddress): SectionRow => {
        return {
            id: address.id,
            isPending: false,
            fields: [
                {
                    ...columns[0],
                    dateValue: address.startDate,
                },
                {
                    ...columns[1],
                    stringValue: address.addressLine1,
                },
                {
                    ...columns[2],
                    stringValue: address.addressLine2,
                },
                {
                    ...columns[3],
                    stringValue: address.postCode,
                },
                {
                    ...columns[4],
                    stringValue: address.city,
                },
                {
                    ...columns[5],
                    stringValue: address.region,
                },
                {
                    ...columns[6],
                    stringValue: address.country,
                },
            ],
        };
    };

    const convertSectionRowToEmployeeAddress = (row: SectionRow): EmployeeAddress => {
        const startDate = row.fields[0].dateValue;
        if (!startDate) {
            throw new Error('Date is required');
        }
        return {
            id: row.id,
            employeeId: employeeId,
            startDate: startDate,
            addressLine1: row.fields[1].stringValue ?? '',
            addressLine2: row.fields[2].stringValue ?? '',
            postCode: row.fields[3].stringValue ?? '',
            city: row.fields[4].stringValue ?? '',
            region: row.fields[5].stringValue ?? '',
            country: row.fields[6].stringValue ?? '',
            endDate: undefined,
        };
    };

    let rows: SectionRow[] = employeeAddresses.map(convertEmployeeAddressToSectionRow);

    if (pendingRows?.length) {
        const pendingRowToAppend: SectionRow[] = pendingRows.map(({ fields, id }) => ({
            id,
            isPending: true,
            fields: fields
                .map<SectionField>(pendingField => ({
                    ...pendingField,
                    title: columns.find(column => column.fieldType === pendingField.sectionFieldDefinition.fieldType)?.title,
                    type: pendingField.sectionFieldDefinition.type,
                    sectionFieldDefinitionId: pendingField.sectionFieldDefinition.id,
                    countryValue:
                        pendingField.sectionFieldDefinition.fieldType === 'ADDRESS_COUNTRY' && pendingField.stringValue
                            ? getCountry(pendingField.stringValue)
                            : undefined,
                    fieldType: pendingField.sectionFieldDefinition.fieldType,
                }))
                // This field is not displayed in the table
                .filter(field => field.fieldType !== 'ADDRESS_REGION'),
        }));

        rows = [...(rows ?? []), ...pendingRowToAppend];
    }

    const canApprove = canManageEmployeeAddresses(policies, employeeId);
    const canRequestApproval = canManagePendingEmployeeAddresses(policies, employeeId);

    const openMutationDialogButton: SectionActionButton = {
        title: t('employee.address.add_address'),
        onClick: () => {
            setEmployeeAddressDialogOpen(true);
        },
    };

    const seePendingButton: SectionActionButton = {
        title: t('employee.sections.see_pending_changes'),
        onClick: () => {
            navigate('/people/employee-requests');
        },
    };

    const handleUpdate = (employeeAddressFormValues: EmployeeAddressSectionFormValues) => {
        if (!employeeAddressFormValues.country?.value || !selectedEmployeeAddress?.id) {
            return;
        }
        const request: EmployeeAddressUpdateMutation = {
            ...employeeAddressFormValues,
            country: employeeAddressFormValues?.country?.value,
        };

        if (canApprove) {
            employeeAddressAPI
                .updateEmployeeAddress(selectedEmployeeAddress.id, request)
                .then(() => {
                    fetchEmployeeAddresses().catch(handleError);
                })
                .catch(error => {
                    showSnackbar(error.response?.data?.message, 'error');
                })
                .finally(() => {
                    setEmployeeAddressDialogOpen(false);
                    setSelectedEmployeeAddress(undefined);
                });
        } else if (canRequestApproval) {
            updateEmployeeAddressPendingRequest(selectedEmployeeAddress.id, request)
                .then(() => {
                    setEmployeeAddressDialogOpen(false);
                    setSelectedEmployeeAddress(undefined);
                    refetchProfile();
                })
                .catch(error => {
                    showSnackbar(error.response?.data?.message, 'error');
                });
        }
    };

    const handleCreate = (employeeAddressFormValues: EmployeeAddressSectionFormValues) => {
        if (!employeeAddressFormValues.country?.value) {
            return;
        }
        const employeeAddressCreateRequest: EmployeeAddressCreateMutation = {
            ...employeeAddressFormValues,
            employeeId: employeeId,
            country: employeeAddressFormValues?.country?.value,
        };
        if (canApprove) {
            employeeAddressAPI
                .createEmployeeAddress(employeeAddressCreateRequest)
                .then(data => {
                    setEmployeeAddresses([...employeeAddresses, data]);
                })
                .catch(error => {
                    showSnackbar(error.response?.data?.message, 'error');
                })
                .finally(() => {
                    setEmployeeAddressDialogOpen(false);
                    setSelectedEmployeeAddress(undefined);
                });
        } else if (canRequestApproval) {
            createEmployeeAddressPendingRequest(employeeAddressCreateRequest)
                .then(() => {
                    setEmployeeAddressDialogOpen(false);
                    setSelectedEmployeeAddress(undefined);
                    refetchProfile();
                })
                .catch(error => {
                    showSnackbar(error.response?.data?.message, 'error');
                });
        }
    };

    const refetchProfile = () => {
        // TODO: remove this when we have fetching library in place
        dispatch({
            type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
            refetchEmployee: true,
        });
    };

    const handleSave = (employeeAddressFormValues: EmployeeAddressSectionFormValues) => {
        if (selectedEmployeeAddress?.id) {
            handleUpdate(employeeAddressFormValues);
        } else {
            handleCreate(employeeAddressFormValues);
        }
    };

    const onClose = () => {
        setEmployeeAddressDialogOpen(false);
        setSelectedEmployeeAddress(undefined);
    };

    const handleDelete = (addressId: number) => {
        return employeeAddressAPI
            .deleteEmployeeAddress(addressId)
            .then(() => {
                setEmployeeAddresses(employeeAddresses.filter(employeeAddress => employeeAddress.id !== addressId));
            })
            .catch(error => {
                showSnackbar(error.response?.data?.message, 'error');
            });
    };

    const handleDeletePendingRequest = async (addressId: number) => {
        try {
            await deleteEmployeeAddressPendingRequest(addressId);
            refetchProfile();
        } catch (error) {
            handleError(error);
        }
    };

    const handleDeleteClicked = (row: SectionRow) => async () => {
        try {
            if (row.isPending) {
                await handleDeletePendingRequest(row.id);
            } else {
                await handleDelete(row.id);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleEditClicked = (row: SectionRow) => () => {
        setSelectedEmployeeAddress(convertSectionRowToEmployeeAddress(row));
        setEmployeeAddressDialogOpen(true);
    };

    const action = getSectionAction({ canApprove, canRequestApproval });
    const actionButton = getSectionActionButton(action, openMutationDialogButton, seePendingButton);

    const canHaveActionMenu = canApprove || canRequestApproval;

    const actionMenuCellRenderer = canHaveActionMenu
        ? (params: ICellRendererParams<SectionRow>) => {
              const mutationEnabled = params.data?.isPending ? canRequestApproval : canApprove;
              const data = params.data;
              if (!data) {
                  return;
              }
              return (
                  <EmployeeFieldMoreButton
                      onEditClicked={canApprove && params.data?.isPending ? seePendingButton.onClick : handleEditClicked(data)}
                      onDeleteClicked={handleDeleteClicked(data)}
                      editDisabled={!mutationEnabled}
                      deleteEnabled={mutationEnabled}
                      disabled={false}
                      cancelEnabled={false}
                  />
              );
          }
        : undefined;

    return (
        <StateHandler
            isLoading={isLoading || isFetching}
            isError={isError}
            error={error}
            loadingComponent={<SectionLoading sectionTitle={'employee.sections.address'} />}
        >
            <TableSection
                sectionTitle={t('employee.sections.address')}
                columns={columns}
                rows={rows}
                actionButton={actionButton}
                actionMenuCellRenderer={actionMenuCellRenderer}
            />
            {employeeAddressDialogOpen && (
                <EmployeeAddressDialog
                    open={employeeAddressDialogOpen}
                    employeeId={employeeId}
                    employeeAddress={selectedEmployeeAddress}
                    onSave={employeeAddressFormValues => {
                        handleSave(employeeAddressFormValues);
                    }}
                    onClose={() => {
                        onClose();
                    }}
                />
            )}
        </StateHandler>
    );
};
