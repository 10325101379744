import { DatePickerProps, DatePickerWrapper } from '@/Components/date-picker/DatePickerWrapper';
import { getAppConfig } from '@/config/config';
import { formatToLocalDate, LocalDate, toDate } from '@/utils/datetime.util';
import { Controller, ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';

type DatePickerRootProps = Pick<DatePickerProps, 'disabled' | 'minDate' | 'maxDate' | 'mobileOnly' | 'sx' | 'onChange'>;

export type FieldDateProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    datePickerProps?: RemoveFromType<RemoveFromType<DatePickerProps, DatePickerRootProps>, ControllerRenderProps>;
} & DatePickerRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldDate = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldDateProps<TFieldValues, TName>,
): JSX.Element => {
    const { disabled, mobileOnly, minDate, maxDate, sx, onChange, ...controllerProps } = props;

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState: { error } }) => (
                <DatePickerWrapper
                    timezone={getAppConfig().DEFAULT_TIMEZONE}
                    disabled={disabled}
                    slotProps={{
                        textField: {
                            error: !!error,
                            helperText: error?.message,
                        },
                    }}
                    mobileOnly={mobileOnly}
                    minDate={minDate}
                    maxDate={maxDate}
                    sx={sx}
                    {...field}
                    value={field.value}
                    onChange={(date, context) => {
                        // This a workaround, to handle timezone issue with component DatePickerWrapper
                        // when value is not defined before picking a date from the date picker the date format is like this
                        // date Sun Jan 07 2024 01:00:00 GMT+0100 (Central European Standard Time)
                        // when value is defined before picking a date from the date picker the date format is like this
                        // date Sun Feb 04 2024 00:00:00 GMT+0100 (Central European Standard Time)
                        // I think this should be handled directly with timezone support https://mui.com/x/react-date-pickers/timezone/#supported-timezones

                        // This hack forces to keep the same format in both cases

                        const isValid = !context.validationError;
                        field.onChange(date && isValid ? new Date(formatToLocalDate(date)) : date);
                        onChange?.(date, context);
                    }}
                />
            )}
        />
    );
};

type LocalDatePickerRootProps = Omit<DatePickerRootProps, 'onChange'> & {
    onChange?: (date: LocalDate) => void;
};

export type FieldLocalDateProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    datePickerProps?: RemoveFromType<RemoveFromType<DatePickerProps, LocalDatePickerRootProps>, ControllerRenderProps>;
} & LocalDatePickerRootProps &
    Omit<ControllerProps<TFieldValues, TName>, 'render'>;

export const FieldLocalDate = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    props: FieldLocalDateProps<TFieldValues, TName>,
): JSX.Element => {
    const { disabled, mobileOnly, minDate, maxDate, sx, onChange, ...controllerProps } = props;

    return (
        <Controller
            {...controllerProps}
            render={({ field, fieldState: { error } }) => (
                <DatePickerWrapper
                    timezone={getAppConfig().DEFAULT_TIMEZONE}
                    disabled={disabled}
                    slotProps={{
                        textField: {
                            error: !!error,
                            helperText: error?.message,
                        },
                    }}
                    mobileOnly={mobileOnly}
                    minDate={minDate}
                    maxDate={maxDate}
                    sx={sx}
                    {...field}
                    value={toDate(field.value)}
                    onChange={date => {
                        const localDate = formatToLocalDate(date);
                        field.onChange(localDate);
                        onChange?.(localDate);
                    }}
                />
            )}
        />
    );
};
