import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getReviewingContributorsExceptManager } from '@/domain/employee-review/EmployeeReview.service';
import { StackedAvatars } from '@/Components/stacked-avatar/StackedAvatars';

type EmployeeReviewFeedbackViewFeedbacksProps = {
    employeeReview: EmployeeReview;
};

export const EmployeeReviewFeedbackViewFeedbacks: FC<EmployeeReviewFeedbackViewFeedbacksProps> = ({ employeeReview }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    // we are excluding the manager from the list of contributors because it's not necessary for the manager to see his own feedback again
    const reviewingContributorsExceptManager = getReviewingContributorsExceptManager(employeeReview);
    const hasFeedbacks = reviewingContributorsExceptManager.length > 0;
    return (
        <Stack direction={'row'} alignItems={'center'}>
            <Tooltip title={!hasFeedbacks ? t('reviews.write_feedback.tooltip_button_disabled_label') : ''}>
                {/*
                workaround to use a tooltip on a disabled button
                https://stackoverflow.com/questions/61115913/is-it-possible-to-render-a-tooltip-on-a-disabled-material-ui-button-within-a-but
                */}
                <Box>
                    <Button
                        disabled={!hasFeedbacks}
                        variant={'text'}
                        sx={{
                            backgroundColor: !hasFeedbacks ? theme.palette.grey[100] : 'none',
                        }}
                        onClick={() => navigate(`/reviews/${employeeReview.id}/manager-summary`)}
                    >
                        <Typography color={hasFeedbacks ? theme.palette.primary.main : theme.palette.action.disabled}>
                            {t('reviews.write_feedback.view_feedbacks_button_label')}
                        </Typography>
                    </Button>
                </Box>
            </Tooltip>

            <StackedAvatars employeeAvatars={reviewingContributorsExceptManager} />
        </Stack>
    );
};
