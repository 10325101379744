import { Button, Divider, Link, Paper, Skeleton, Stack, StackProps, Typography } from '@mui/material';
import { EmptyState } from '@/Components/empty-state/EmptyState';
import { ReviewEmptyStateIcon } from '@/Components/empty-state/EmptyStateIcons';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { canCreateOneShotReviews } from '@/domain/permission/Permission.service';
import { useAppSelector } from '@/stores/store';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { EmployeeReviewStatusButton } from '@/page/review/employee-review-status-button/EmployeeReviewStatusButton';
import { Employee, EmployeePolicy } from '@/domain/employee/Employee.model';
import {
    filterEmployeeReviewsByStatusAndEndDate,
    getOngoingReviews,
    getPastReviews,
    searchEmployeeReviews,
} from '@/domain/employee-review/EmployeeReview.service';
import { EmployeeReviewViewProgressDialog } from '@/page/review/employee-review-view-progress-dialog/EmployeeReviewViewProgressDialog';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { ReviewStatus } from '@/domain/review/Review.model';
import { handleError } from '@/utils/api.util';
import { OneShotReviewDialog } from '@/page/employee-profile/employee-profile-review/OneShotReviewDialog';

/**
 * Display employee reviews (not reviews)
 */
export const EmployeeProfileReviewsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const [employeeReviews, setEmployeeReviews] = useState<EmployeeReview[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    // State to open the dialog to create one shot review
    const [createReviewDialogOpen, setCreateReviewDialogOpen] = useState<boolean>(false);

    const params = useParams();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const employeeId = params?.employeeId ? Number(params?.employeeId) : currentEmployee?.id;

    const fetchEmployeeReviews = useCallback(async () => {
        if (employeeId) {
            const request = {
                employeeId: employeeId,
                reviewStatuses: [ReviewStatus.STARTED, ReviewStatus.CLOSED],
            };
            try {
                const employeeReviewsData = await searchEmployeeReviews(request);
                setEmployeeReviews(employeeReviewsData);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        }
    }, [employeeId]);

    useEffect(() => {
        fetchEmployeeReviews().catch(handleError);
    }, [fetchEmployeeReviews]);

    if (!currentEmployee) {
        throw new Error('Current Employee is not defined');
    }

    if (!employeeId) {
        throw new Error('Employee ID is not defined');
    }

    const handleCreateReviewSuccess = (employeeReviewId: number | undefined, managerId: number) => {
        setCreateReviewDialogOpen(false);
        if (employeeReviewId) {
            if (managerId === currentEmployee?.id) {
                navigate(`/reviews/${employeeReviewId}/manager-summary`);
            } else {
                fetchEmployeeReviews().catch(handleError);
            }
        }
    };

    // We split in the ui, the employee reviews in two categories: Ongoing reviews and not ongoing reviews
    const employeeReviewsFiltered = filterEmployeeReviewsByStatusAndEndDate(employeeReviews, currentEmployee);
    const ongoingEmployeeReviews = getOngoingReviews(employeeReviewsFiltered);
    const notOngoingEmployeeReviews = getPastReviews(employeeReviewsFiltered);

    const hasReviews = !!employeeReviews.length;
    const hasOngoingReviews = !!ongoingEmployeeReviews.length;
    const hasPastReviews = !!notOngoingEmployeeReviews.length;

    const canCreateReview = (policies: EmployeePolicy[], targetEmployeeId: number) =>
        canCreateOneShotReviews(policies, targetEmployeeId) && currentEmployee.id !== employeeId;

    const emptyState = (
        <EmptyState
            icon={<ReviewEmptyStateIcon />}
            flex={1}
            title={t('reviews.employee_reviews.no_reviews')}
            subTitle={!canCreateReview(policies, employeeId) ? t('reviews.employee_reviews.ask_your_manager_for_a_meeting') : undefined}
            action={
                canCreateReview(policies, employeeId) ? (
                    <Button onClick={() => setCreateReviewDialogOpen(true)}>{t('reviews.employee_reviews.new_one_shot_review')}</Button>
                ) : undefined
            }
        />
    );

    return (
        <>
            <StateHandler
                isLoading={isLoading}
                loadingComponent={<LoadingState />}
                isEmpty={!hasReviews}
                emptyStateComponent={emptyState}
                isError={!!error}
                error={error}
            >
                <Stack gap={2} flex={1}>
                    {hasOngoingReviews && (
                        <EmployeeReviewProfileTable
                            currentEmployee={currentEmployee}
                            flex={hasPastReviews ? 0 : 1}
                            employeeReviews={ongoingEmployeeReviews}
                            tableTitle={t('reviews.employee_reviews.ongoing')}
                        >
                            {canCreateReview(policies, employeeId) && (
                                <Button onClick={() => setCreateReviewDialogOpen(true)}>{t('reviews.employee_reviews.new_one_shot_review')}</Button>
                            )}
                        </EmployeeReviewProfileTable>
                    )}
                    {hasPastReviews && (
                        <EmployeeReviewProfileTable
                            currentEmployee={currentEmployee}
                            flex={1}
                            employeeReviews={notOngoingEmployeeReviews}
                            tableTitle={t('reviews.employee_reviews.past')}
                        >
                            {canCreateReview(policies, employeeId) && !hasOngoingReviews && (
                                <Button onClick={() => setCreateReviewDialogOpen(true)}>{t('reviews.employee_reviews.new_one_shot_review')}</Button>
                            )}
                        </EmployeeReviewProfileTable>
                    )}
                </Stack>
            </StateHandler>
            {createReviewDialogOpen && (
                <OneShotReviewDialog
                    open={true}
                    onClose={() => setCreateReviewDialogOpen(false)}
                    onSuccess={handleCreateReviewSuccess}
                    employeeId={employeeId}
                />
            )}
        </>
    );
};

type EmployeeReviewProfileTableProps = {
    employeeReviews: EmployeeReview[];
    tableTitle: string;
    currentEmployee: Employee;
} & StackProps;

export const EmployeeReviewProfileTable: FC<PropsWithChildren<EmployeeReviewProfileTableProps>> = ({
    employeeReviews,
    tableTitle,
    currentEmployee,
    children,
    ...rest
}) => {
    return (
        <Stack {...rest} gap={3} component={Paper} p={2.5} flexDirection={'column'}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='h1'>{tableTitle}</Typography>
                {children}
            </Stack>

            <Stack divider={<Divider />}>
                {employeeReviews?.map(employeeReview => (
                    <EmployeeReviewProfileRow currentEmployee={currentEmployee} key={employeeReview.id} employeeReview={employeeReview} />
                ))}
            </Stack>
        </Stack>
    );
};

type EmployeeReviewProfileRowProps = {
    employeeReview: EmployeeReview;
    currentEmployee: Employee;
};

export const EmployeeReviewProfileRow: FC<EmployeeReviewProfileRowProps> = ({ employeeReview, currentEmployee }) => {
    const { t } = useTranslation();
    const [isViewProgressDialogOpen, setIsViewProgressDialogOpen] = useState(false);

    return (
        <Stack key={employeeReview.id} direction={'row'} alignItems='center' justifyContent='space-between' py={1} flex={1} gap={1}>
            <Stack width={'320px'}>
                <Typography noWrap variant='body1bold'>
                    {employeeReview.review?.name}
                </Typography>
                <Link
                    onClick={() => setIsViewProgressDialogOpen(true)}
                    color={'primary'}
                    sx={{
                        cursor: 'pointer',
                    }}
                >
                    {t('reviews.employee_review.view_progress_link_label')}
                </Link>
            </Stack>

            <EmployeeReviewStatusButton
                width={'fit-content'}
                whiteSpace={'nowrap'}
                justifyContent={'flex-end'}
                employeeReview={employeeReview}
                currentEmployee={currentEmployee}
            />
            {isViewProgressDialogOpen && (
                <EmployeeReviewViewProgressDialog
                    onClose={() => setIsViewProgressDialogOpen(false)}
                    employeeReview={employeeReview}
                    currentEmployee={currentEmployee}
                />
            )}
        </Stack>
    );
};

export const LoadingState: FC = () => (
    <Stack gap={2}>
        <Stack p={2} gap={3} minHeight={200} flex={1 / 3} component={Paper}>
            <Skeleton width={250} variant='rounded' sx={{ bgcolor: 'grey.300' }} />

            <Stack gap={1} divider={<Divider />}>
                {Array.from({ length: 5 }, (_, itemPosition) => (
                    <Stack key={itemPosition} direction='row' justifyContent='space-between'>
                        <Skeleton width={100} variant='rounded' sx={{ bgcolor: 'grey.300' }} />
                        <Skeleton width={100} variant='rounded' sx={{ bgcolor: 'grey.300' }} />
                    </Stack>
                ))}
            </Stack>
        </Stack>
        <Stack p={2} gap={3} minHeight={200} flex={1 / 3} component={Paper}>
            <Skeleton width={250} variant='rounded' sx={{ bgcolor: 'grey.300' }} />

            <Stack gap={1} divider={<Divider />}>
                {Array.from({ length: 5 }, (_, itemPosition) => (
                    <Stack key={itemPosition} direction='row' justifyContent='space-between'>
                        <Skeleton width={100} variant='rounded' sx={{ bgcolor: 'grey.300' }} />
                        <Skeleton width={100} variant='rounded' sx={{ bgcolor: 'grey.300' }} />
                    </Stack>
                ))}
            </Stack>
        </Stack>
    </Stack>
);
