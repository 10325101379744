import { SearchField } from '@/Components/search-field/SearchField';
import { containsIgnoreCase } from '@/utils/strings.util';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { Divider, List, ListItem, Paper, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.model';

type LeaveRequestDialogLeaveTypeStepProps = {
    leaveTypeHistories: LeaveTypeHistory[];
    onLeaveTypeSelected: (leaveType: LeaveType) => void;
};

export const SelectLeaveType: FC<LeaveRequestDialogLeaveTypeStepProps> = ({ leaveTypeHistories, onLeaveTypeSelected }) => {
    const [filteredLeaveTypeHistories, setFilteredLeaveTypeHistories] = useState<LeaveTypeHistory[]>(leaveTypeHistories);
    const { t } = useTranslation();

    const handleLeaveTypeFilter = (event: ChangeEvent<HTMLInputElement>) => {
        const filteredLeaveTypeHistories = event.target.value
            ? leaveTypeHistories.filter(history => containsIgnoreCase(history?.leaveType?.title, event?.target?.value))
            : leaveTypeHistories;
        setFilteredLeaveTypeHistories(filteredLeaveTypeHistories);
    };

    const sortedLeaveTypeHistories = [...filteredLeaveTypeHistories];

    sortedLeaveTypeHistories?.sort((a, b) => {
        return a.leaveType.order - b.leaveType.order;
    });

    const working = sortedLeaveTypeHistories.filter(leaveTypeHistory => leaveTypeHistory.leaveType.leaveActivityType === 'WORKING');
    const nonWorking = sortedLeaveTypeHistories.filter(leaveTypeHistory => leaveTypeHistory.leaveType.leaveActivityType !== 'WORKING');

    return (
        <Stack spacing={1}>
            <SearchField onChange={handleLeaveTypeFilter} label={t('request_leave_dialog.leave_type')} />

            <DisplayLeaveTypesHistory
                leaveTypeHistories={working}
                onLeaveTypeSelected={onLeaveTypeSelected}
                title={t('request_leave_dialog.working_leave_types')}
            />
            <DisplayLeaveTypesHistory
                leaveTypeHistories={nonWorking}
                onLeaveTypeSelected={onLeaveTypeSelected}
                title={t('request_leave_dialog.non_working_leave_types')}
            />
        </Stack>
    );
};

type DisplayLeaveTypesHistoryProps = {
    leaveTypeHistories: LeaveTypeHistory[];
    onLeaveTypeSelected: (leaveType: LeaveType) => void;
    title: string;
};

const DisplayLeaveTypesHistory: FC<DisplayLeaveTypesHistoryProps> = ({ leaveTypeHistories, onLeaveTypeSelected, title }) => {
    if (!leaveTypeHistories || leaveTypeHistories.length === 0) {
        return;
    }

    return (
        <Stack paddingTop={1}>
            <Typography paddingBottom={1} variant='body2bold'>
                {title}
            </Typography>
            <Paper
                sx={theme => ({
                    borderRadius: 1,
                    border: `1px solid ${theme.palette.grey[300]}`,
                })}
            >
                <List sx={{ p: 0 }}>
                    {leaveTypeHistories?.map((leaveTypeHistory: LeaveTypeHistory) => {
                        return (
                            <Fragment key={leaveTypeHistory.leaveType.id}>
                                <ListItem
                                    dense
                                    onClick={() => {
                                        onLeaveTypeSelected(leaveTypeHistory.leaveType);
                                    }}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: theme => theme.palette.grey[50],
                                        },
                                        py: 2,
                                    }}
                                    aria-label={leaveTypeHistory.leaveType.title}
                                >
                                    <Typography variant='body1'>{leaveTypeHistory.leaveType.title}</Typography>
                                </ListItem>
                                <Divider />
                            </Fragment>
                        );
                    })}
                </List>
            </Paper>
        </Stack>
    );
};
