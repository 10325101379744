import { AdditionalActionPaper } from '@/Components/autocomplete-wrapper/AdditionalActionPaper';
import { DialogWrapper, DialogWrapperProps } from '@/Components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/Components/form/field-date/FieldDate';
import { FieldNumber } from '@/Components/form/field-number/FieldNumber';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { Calendar } from '@/domain/calendar/Calendar.model';
import { DayPeriod } from '@/domain/date/Date.model';
import { getDayOfWeekShortTranslationKey } from '@/domain/date/Date.service';
import {
    EmployeeWorkingPattern,
    EmployeeWorkingPatternTemplateCreationMutation,
    EmployeeWorkingPatternType,
    EmployeeWorkingPatternUpdateMutation,
} from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { createEmployeeWorkingPattern, mapWorkingDays, updateEmployeeWorkingPattern } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.service';
import { Employee } from '@/domain/employee/Employee.model';
import { getCurrentPrincipalEmployment } from '@/domain/employment/Employment.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { WeeklyWorkingTime } from '@/domain/weekly-working-time/WeeklyWorkingTime.model';
import { WorkingPatternTemplate } from '@/domain/working-pattern-template/WorkingPatternTemplate.model';
import { getHoursPerWeekLabel, getRate, searchWorkingPatternTemplates } from '@/domain/working-pattern-template/WorkingPatternTemplate.service';
import { useGetCalendars } from '@/hooks/calendar/Calendar.hook';
import { useSearchWeeklyWorkingTimes } from '@/page/employee-profile/employee-profile-info/EmpoyeeWorkPatternSection/Components/WeeklyWorkingTime.hook';
import {
    EmployeeWorkingPatternFormValues,
    getWorkPatternSchema,
    isTimesheetSettingIdRequired,
} from '@/page/employee-profile/employee-profile-info/EmpoyeeWorkPatternSection/EmployeeWorkPattern.schema';
import { useSearchTimesheetSettings } from '@/page/setting/time-management/TimesheetSettings.hook';
import { WorkingPatternFormDialog } from '@/page/setting/time-management/working-pattern/WorkingPatternFormDialog';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getSingleItemIfExists } from '@/utils/collections.util';
import { DayOfWeek, getCurrentLocalDate } from '@/utils/datetime.util';
import { defaultToNull, getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import '@mui/lab';
import {
    Autocomplete,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, FieldValues, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type EmployeeWorkingPatternDialogProps = {
    employee: Employee;
    selectedEmployeeWorkingPattern?: EmployeeWorkingPattern;
    onSave: () => void;
    /**
     * Start date could be disabled out of the dialog
     */
    canEditStartDate: boolean;
} & DialogWrapperProps;

export const EmployeeWorkingPatternDialog: FC<EmployeeWorkingPatternDialogProps> = ({
    open,
    employee,
    selectedEmployeeWorkingPattern,
    canEditStartDate,
    onClose,
    onSave,
}) => {
    const { t } = useTranslation();

    // modal is in edit mode if activeEmployeeWorkingPattern has an id
    const isEdit = !!selectedEmployeeWorkingPattern?.id;
    const title = isEdit ? t('employee.work_pattern.dialog.update.title') : t('employee.work_pattern.dialog.new.title');

    const {
        data: weeklyWorkingTimes = [],
        isLoading: isLoadingWeeklyWorkingTimes,
        isError: isErrorWeeklyWorkingTimes,
        error: weeklyWorkingTimesError,
    } = useSearchWeeklyWorkingTimes();

    const {
        data: timesheetSettings = [],
        isLoading: isLoadingTimesheetSettings,
        isError: isErrorTimesheetSettings,
        error: timesheetSettingsError,
    } = useSearchTimesheetSettings();

    const { data: calendars = [], isLoading: isLoadingCalendars, isError: isErrorCalendars, error: calendarsError } = useGetCalendars();

    const handleSave = (data: EmployeeWorkingPatternFormValues) => {
        const selectedWeeklyWorkingTime = weeklyWorkingTimes ? weeklyWorkingTimes.find(w => w.id === data.weeklyWorkingTimeId) : undefined;

        const payload: EmployeeWorkingPatternUpdateMutation = {
            type: data.type,
            startDate: data.startDate,
            rate: data.type === EmployeeWorkingPatternType.RATE ? data.rate : undefined,
            weeklyWorkingTimeId:
                data.type === EmployeeWorkingPatternType.FIXED || data.type === EmployeeWorkingPatternType.RATE ? data.weeklyWorkingTimeId : undefined,
            workingPatternTemplateId:
                data.type === EmployeeWorkingPatternType.TEMPLATE && data.workingPatternTemplate ? data.workingPatternTemplate.id : undefined,
            workingDays:
                data.type === EmployeeWorkingPatternType.FIXED && data.morningWorkingDays && data.afternoonWorkingDays
                    ? mapWorkingDays(data.morningWorkingDays, data.afternoonWorkingDays, selectedWeeklyWorkingTime)
                    : undefined,
            timesheetSettingId: data.timesheetSettingId,
            calendarId: data.calendarId,
        };

        if (selectedEmployeeWorkingPattern?.id) {
            handleEdit(selectedEmployeeWorkingPattern.id, payload);
        } else {
            handleCreation({
                ...payload,
                // For creation, we need to add the employeeId
                employeeId: employee.id,
            });
        }
    };

    const handleEdit = async (id: number, payload: EmployeeWorkingPatternUpdateMutation) => {
        try {
            await updateEmployeeWorkingPattern(id, payload);
            showSnackbar(t('employee.work_pattern.dialog.messages.updated'), 'success');
            onSave();
        } catch (error) {
            showSnackbar(t('employee.work_pattern.dialog.errors.update'), 'error');
            handleError(error);
        }
    };

    const handleCreation = async (payload: EmployeeWorkingPatternTemplateCreationMutation) => {
        try {
            await createEmployeeWorkingPattern(payload);
            showSnackbar(t('employee.work_pattern.dialog.messages.created'), 'success');
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const onCloseDialog = () => {
        onClose();
    };

    return (
        <DialogWrapper header={title} open={open} onClose={onCloseDialog}>
            <StateHandler
                isLoading={isLoadingTimesheetSettings || isLoadingWeeklyWorkingTimes || isLoadingCalendars}
                isError={isErrorTimesheetSettings || isErrorWeeklyWorkingTimes || isErrorCalendars}
                error={timesheetSettingsError ?? weeklyWorkingTimesError ?? calendarsError}
            >
                <EmployeeWorkingPatternForm
                    employee={employee}
                    selectedEmployeeWorkingPattern={selectedEmployeeWorkingPattern}
                    timesheetSettings={timesheetSettings}
                    weeklyWorkingTimes={weeklyWorkingTimes}
                    calendars={calendars}
                    isEdit={isEdit}
                    onSave={handleSave}
                    canEditStartDate={canEditStartDate}
                />
            </StateHandler>
        </DialogWrapper>
    );
};

type EmployeeWorkingPatternDialogForm = {
    weeklyWorkingTimes: WeeklyWorkingTime[];
    timesheetSettings: TimesheetSetting[];
    calendars: Calendar[];
    employee: Employee;
    selectedEmployeeWorkingPattern?: EmployeeWorkingPattern;
    onSave: (data: EmployeeWorkingPatternFormValues) => void;
    isEdit: boolean;
    canEditStartDate: boolean;
};

const EmployeeWorkingPatternForm: FC<EmployeeWorkingPatternDialogForm> = ({
    employee,
    weeklyWorkingTimes,
    timesheetSettings,
    selectedEmployeeWorkingPattern,
    calendars,
    onSave,
    isEdit,
    canEditStartDate,
}) => {
    const { t } = useTranslation();
    const realm = useAppSelector(state => state.ui.currentRealm);

    const hasTimesheetLeavesRealmFeatures =
        hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.TIMESHEET) || hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.LEAVES);

    const defaultEmployeeWorkingPatternType = hasTimesheetLeavesRealmFeatures ? EmployeeWorkingPatternType.FIXED : EmployeeWorkingPatternType.RATE;

    const getDefaultForExistingWorkingPattern = (selectedEmployeeWorkingPattern: EmployeeWorkingPattern): Partial<EmployeeWorkingPatternFormValues> => {
        return {
            startDate: selectedEmployeeWorkingPattern?.startDate ?? getCurrentLocalDate(),
            type: selectedEmployeeWorkingPattern?.type ?? defaultEmployeeWorkingPatternType,
            rate: selectedEmployeeWorkingPattern?.rate ?? 100,
            weeklyWorkingTimeId: selectedEmployeeWorkingPattern?.weeklyWorkingTime?.id ?? (weeklyWorkingTimes ? weeklyWorkingTimes[0]?.id : undefined),
            workingPatternTemplate: selectedEmployeeWorkingPattern?.workingPatternTemplate ?? undefined,
            morningWorkingDays:
                selectedEmployeeWorkingPattern?.workingDays.length !== 0
                    ? selectedEmployeeWorkingPattern?.workingDays?.filter(w => w.dayPeriod !== DayPeriod.AFTERNOON && w.amountWorked > 0).map(w => w.dayOfWeek)
                    : getDefaultDays(),
            afternoonWorkingDays:
                selectedEmployeeWorkingPattern?.workingDays.length !== 0
                    ? selectedEmployeeWorkingPattern?.workingDays?.filter(w => w.dayPeriod !== DayPeriod.MORNING && w.amountWorked > 0).map(w => w.dayOfWeek)
                    : getDefaultDays(),
            timesheetSettingId: selectedEmployeeWorkingPattern.timesheetSetting.id,
            calendarId: selectedEmployeeWorkingPattern.calendar.id,
        };
    };

    const getDefaultEmployeeWorkingPatternForm = (): Partial<EmployeeWorkingPatternFormValues> => {
        if (isEdit && selectedEmployeeWorkingPattern) {
            return getDefaultForExistingWorkingPattern(selectedEmployeeWorkingPattern);
        } else {
            const currentEmployment = getCurrentPrincipalEmployment(employee);
            return {
                startDate: getCurrentLocalDate(),
                workingPatternTemplate: selectedEmployeeWorkingPattern?.workingPatternTemplate,
                rate: 100,
                type: defaultEmployeeWorkingPatternType,
                morningWorkingDays: getDefaultDays(),
                afternoonWorkingDays: getDefaultDays(),
                // only set the timesheet setting if there is only one
                timesheetSettingId: timesheetSettings.length === 1 ? timesheetSettings[0].id : undefined,
                calendarId: getSingleItemIfExists(calendars)?.id ?? currentEmployment?.location.calendar?.id,
                weeklyWorkingTimeId: weeklyWorkingTimes[0].id,
            };
        }
    };

    const schema = getWorkPatternSchema(realm?.realmFeatures ?? []);

    const formMethods = useForm<EmployeeWorkingPatternFormValues>({
        resolver: yupResolver(schema),
        defaultValues: getDefaultEmployeeWorkingPatternForm(),
    });

    const { handleSubmit } = formMethods;

    return (
        <FormProvider {...formMethods}>
            <Stack component={DialogContent}>
                <EmployeeWorkingPatternFields
                    weeklyWorkingTimes={weeklyWorkingTimes}
                    timesheetSettings={timesheetSettings}
                    calendars={calendars}
                    canEditStartDate={canEditStartDate}
                />
            </Stack>
            <DialogActions>
                <Button onClick={() => handleSubmit(onSave, console.error)()} variant='contained'>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </FormProvider>
    );
};

type EmployeeWorkingPatternFieldsProps = {
    weeklyWorkingTimes: WeeklyWorkingTime[];
    timesheetSettings: TimesheetSetting[];
    calendars: Calendar[];
    canEditStartDate: boolean;
};
const EmployeeWorkingPatternFields: FC<EmployeeWorkingPatternFieldsProps> = ({ weeklyWorkingTimes, timesheetSettings, calendars, canEditStartDate }) => {
    const { t } = useTranslation();
    const realm = useAppSelector(state => state.ui.currentRealm);

    const { watch, control } = useFormContext<EmployeeWorkingPatternFormValues>();

    const selectedEmployeeWorkingPatternType = watch('type');

    return (
        <Stack direction='column' spacing={2}>
            <Stack gap={0.5}>
                <Typography variant='body1'>{t('employee.work_pattern.start_date')}</Typography>

                <FieldLocalDate control={control} name='startDate' disabled={!canEditStartDate} />
            </Stack>

            <EmployeeWorkingPatternCalendarField calendars={calendars} label={t('employee.work_pattern.calendar')} />

            <EmployeeWorkingPatternTypeField />

            {selectedEmployeeWorkingPatternType === EmployeeWorkingPatternType.TEMPLATE && (
                <EmployeeWorkingPatternField title={t('employee.work_pattern.title')} />
            )}

            {selectedEmployeeWorkingPatternType === EmployeeWorkingPatternType.RATE && (
                <EmployeeWorkingPatternRateType weeklyWorkingTimes={weeklyWorkingTimes} />
            )}

            {selectedEmployeeWorkingPatternType === EmployeeWorkingPatternType.FIXED && (
                <>
                    {weeklyWorkingTimes.length > 1 && (
                        <WeeklyWorkingTimeField weeklyWorkingTimes={weeklyWorkingTimes} label={t('employee.work_pattern.dialog.weekly_working_time')} />
                    )}
                    <EmployeeWorkingPatternWeeklyWorkingTimeField weeklyWorkingTimes={weeklyWorkingTimes} />
                </>
            )}
            {/*Hide the timesheet setting field if there is only one, it is selected by default*/}
            {isTimesheetSettingIdRequired(realm?.realmFeatures ?? []) && timesheetSettings.length > 1 && (
                <TimesheetSettingField timesheetSettings={timesheetSettings} />
            )}
        </Stack>
    );
};

type TimesheetSettingFieldProps = {
    timesheetSettings: TimesheetSetting[];
};

const TimesheetSettingField: FC<TimesheetSettingFieldProps> = ({ timesheetSettings }) => {
    const { t } = useTranslation();
    const { control } = useFormContext<EmployeeWorkingPatternFormValues>();

    return (
        <Stack gap={0.5}>
            <Typography variant='body1'>{t('employee.timesheet_setting')}</Typography>
            <Controller
                name={`timesheetSettingId`}
                control={control}
                render={({ field: { onChange, ...field }, fieldState }) => (
                    <Autocomplete
                        {...field}
                        onChange={(_, newValue) => onChange(defaultToNull(newValue?.id))}
                        disableClearable={true}
                        getOptionLabel={option => option.name}
                        value={timesheetSettings.find(option => option.id === field.value)}
                        options={timesheetSettings ?? []}
                        fullWidth
                        renderInput={params => (
                            <TextField
                                {...params}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                inputProps={{
                                    ...params.inputProps,
                                    'aria-label': 'timesheetSettings',
                                }}
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                )}
            />
        </Stack>
    );
};

export const EmployeeWorkingPatternCalendarField: FC<{ calendars: Calendar[]; label?: string }> = ({ calendars, label }) => {
    const { control } = useFormContext<EmployeeWorkingPatternFormValues>();

    const realm = useAppSelector(state => state.ui.currentRealm);

    if (calendars.length === 1 || !hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.LEAVES)) {
        return;
    }
    return (
        <Stack gap={0.5} flex={1}>
            {label && <Typography variant='body1'>{label}</Typography>}
            <Controller
                name='calendarId'
                control={control}
                render={({ field: { onChange, value, ...restField }, fieldState }) => (
                    <Autocomplete
                        value={calendars.find(calendar => calendar.id === value) ?? getNull()}
                        onChange={(_, data) => onChange(data?.id)}
                        getOptionLabel={calendar => calendar?.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={calendars ?? []}
                        fullWidth
                        renderInput={params => <TextField error={!!fieldState.error} helperText={fieldState.error?.message} {...params} />}
                        {...restField}
                    />
                )}
            />
        </Stack>
    );
};

export const EmployeeWorkingPatternTypeField: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<EmployeeWorkingPatternFormValues>();
    const realm = useAppSelector(state => state.ui.currentRealm);

    const canSeeAllEmployeeWorkingPatternTypes =
        hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.TIMESHEET) || hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.LEAVES);

    const employeeWorkingPatternTypesToDisplay = Object.keys(EmployeeWorkingPatternType).filter(
        type => canSeeAllEmployeeWorkingPatternTypes || (type !== EmployeeWorkingPatternType.TEMPLATE && type !== EmployeeWorkingPatternType.FIXED),
    );

    return (
        <Controller
            name='type'
            control={control}
            render={({ field }) => (
                <RadioGroup row {...field}>
                    {employeeWorkingPatternTypesToDisplay.map(type => {
                        return (
                            <FormControlLabel
                                labelPlacement={'end'}
                                style={{
                                    // TODO: remove once it's fixed https://github.com/mui/material-ui/issues/34039
                                    marginLeft: 0,
                                    marginRight: 0,
                                }}
                                key={type}
                                value={type}
                                control={<Radio />}
                                label={type ? t('employee.work_pattern.type.enum', { context: type }) : ''}
                            />
                        );
                    })}
                </RadioGroup>
            )}
        />
    );
};

type EmployeeWorkingPatternRateTypeProps = {
    weeklyWorkingTimes: WeeklyWorkingTime[];
};
const EmployeeWorkingPatternRateType: FC<EmployeeWorkingPatternRateTypeProps> = ({ weeklyWorkingTimes }) => {
    const { t } = useTranslation();

    return (
        <Stack gap={2}>
            {weeklyWorkingTimes.length > 1 && (
                <WeeklyWorkingTimeField weeklyWorkingTimes={weeklyWorkingTimes} label={t('employee.work_pattern.dialog.weekly_working_time')} />
            )}
            <EmployeeWorkingPatternRateField label={t('employee.work_pattern.dialog.rate')} />
        </Stack>
    );
};

type EmployeeWorkingPatternRateFieldProps = {
    label?: string;
};
export const EmployeeWorkingPatternRateField: FC<EmployeeWorkingPatternRateFieldProps> = ({ label }) => {
    const { control } = useFormContext();
    return (
        <FormControlLabel label={label} sx={{ flex: 1 }} control={<FieldNumber name='rate' control={control} fullWidth min={1} max={100} precision={2} />} />
    );
};

type EmployeeWorkingPatternFieldProps = {
    title?: string;
};
export const EmployeeWorkingPatternField: FC<EmployeeWorkingPatternFieldProps> = ({ title }) => {
    const [workingPatternTemplates, setWorkingPatternTemplates] = useState<WorkingPatternTemplate[]>([]);
    const { t } = useTranslation();
    const [openAdvancedWorkingPatternCreationModal, setOpenAdvancedWorkingPatternCreationModal] = useState(false);

    const callSearchWorkingPatternTemplates = useCallback(async () => {
        searchWorkingPatternTemplates()
            .then(data => setWorkingPatternTemplates(data))
            .catch(handleError);
    }, []);

    useEffect(() => {
        callSearchWorkingPatternTemplates().catch(handleError);
    }, [callSearchWorkingPatternTemplates]);

    const { control, register } = useFormContext();

    const onCloseAdvancedWorkingPatternCreationModal = () => {
        setOpenAdvancedWorkingPatternCreationModal(false);
        callSearchWorkingPatternTemplates().catch(handleError);
    };

    const getPaperComponent = (param: HTMLAttributes<HTMLElement>) => (
        <AdditionalActionPaper
            {...param}
            displayAdditionalAction={true}
            additionalActionLabel={t('general.manage_list')}
            onAdditionalActionClick={() => setOpenAdvancedWorkingPatternCreationModal(true)}
        />
    );

    return (
        <Stack flex={1}>
            <Typography style={{ paddingBottom: '0.5em' }} variant='body1'>
                {title}
            </Typography>
            <Stack direction='column' spacing={2} flex={1}>
                <Controller
                    {...register('workingPatternTemplate')}
                    control={control}
                    render={({ field, fieldState }) => (
                        <Autocomplete
                            PaperComponent={getPaperComponent}
                            onChange={(_, workingPatternTemplate) => field.onChange(workingPatternTemplate)}
                            getOptionLabel={workingPatternTemplate => workingPatternTemplate.name}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={field.value ?? getNull()}
                            options={workingPatternTemplates ?? []}
                            fullWidth
                            renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                        />
                    )}
                />
                {openAdvancedWorkingPatternCreationModal && <WorkingPatternFormDialog onClose={onCloseAdvancedWorkingPatternCreationModal} />}
            </Stack>
        </Stack>
    );
};

type EmployeeWorkingPatternWeeklyWorkingTimeFieldProps = {
    weeklyWorkingTimes: WeeklyWorkingTime[];
};

export const EmployeeWorkingPatternWeeklyWorkingTimeField: FC<EmployeeWorkingPatternWeeklyWorkingTimeFieldProps> = ({ weeklyWorkingTimes }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const { palette } = useTheme();
    const selectedWeeklyWorkingTimeId = watch('weeklyWorkingTimeId');

    return (
        selectedWeeklyWorkingTimeId && (
            <Stack direction='row' spacing={2}>
                <Stack flex={'none'} paddingTop={3.15} gap={1.5}>
                    <Typography flexWrap={'nowrap'}>{t('domain.day_period.morning')}</Typography>
                    <Typography flexWrap={'nowrap'}>{t('domain.day_period.afternoon')}</Typography>
                </Stack>
                <Stack flexGrow={1} direction='column' justifyContent={'space-evenly'}>
                    <Stack direction='row' display={'flex'}>
                        <MorningWorkingDayField />
                    </Stack>

                    <Stack direction='row' display={'flex'} marginTop={-2.5}>
                        <AfternoonWorkingDayField />
                    </Stack>
                </Stack>
                <Stack borderRadius={2} padding={1} bgcolor={palette.primary.light}>
                    <RateInfos />
                    <HoursPerWeek weeklyWorkingTimes={weeklyWorkingTimes} />
                </Stack>
            </Stack>
        )
    );
};

type WeeklyWorkingTimeFieldProps = {
    weeklyWorkingTimes: WeeklyWorkingTime[];
    label?: string;
};

export const WeeklyWorkingTimeField: FC<WeeklyWorkingTimeFieldProps> = ({ weeklyWorkingTimes, label }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <Controller
            defaultValue={weeklyWorkingTimes?.length === 1 ? weeklyWorkingTimes[0].id : ''}
            name='weeklyWorkingTimeId'
            control={control}
            render={({ field }) => (
                <FormControlLabel
                    label={label}
                    labelPlacement='top'
                    sx={{ flex: 1 }}
                    control={
                        <Select fullWidth error={!!errors.weeklyWorkingTimeId} {...field}>
                            {weeklyWorkingTimes?.map(weeklyWorkingTime => (
                                <MenuItem key={weeklyWorkingTime.id} value={weeklyWorkingTime.id}>
                                    {weeklyWorkingTime.name}
                                </MenuItem>
                            ))}
                        </Select>
                    }
                />
            )}
        />
    );
};

const getCheckbox = (
    field: ControllerRenderProps<FieldValues, 'morningWorkingDays'> | ControllerRenderProps<FieldValues, 'afternoonWorkingDays'>,
    dayOfWeek: DayOfWeek.WEDNESDAY | DayOfWeek.MONDAY | DayOfWeek.THURSDAY | DayOfWeek.SUNDAY | DayOfWeek.TUESDAY | DayOfWeek.FRIDAY | DayOfWeek.SATURDAY,
) => {
    return (
        <Checkbox
            checked={field.value.includes(dayOfWeek)}
            onChange={() => {
                if (!field.value.includes(dayOfWeek)) {
                    const newValues = [...field.value, dayOfWeek];
                    field.onChange(newValues);
                    return;
                }
                const newValues = field.value?.filter((d: DayOfWeek) => d !== dayOfWeek);
                field.onChange(newValues);
            }}
        />
    );
};

export const MorningWorkingDayField: FC = () => {
    const { t } = useTranslation();
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <Controller
            name='morningWorkingDays'
            control={control}
            render={({ field }) => {
                return (
                    <FormGroup row sx={{ flexWrap: 'nowrap' }}>
                        {Object.keys(DayOfWeek)
                            .map((k: string) => DayOfWeek[k as keyof typeof DayOfWeek])
                            .map(dayOfWeek => {
                                return (
                                    <FormControlLabel
                                        {...field}
                                        key={dayOfWeek}
                                        labelPlacement={'top'}
                                        sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minWidth: 28,
                                        }}
                                        label={t(getDayOfWeekShortTranslationKey(dayOfWeek))}
                                        control={getCheckbox(field, dayOfWeek)}
                                    />
                                );
                            })}

                        {!!errors.morningWorkingDays && (
                            <Typography variant='body1' color='error'>
                                {t('general.validations.required')}
                            </Typography>
                        )}
                    </FormGroup>
                );
            }}
        />
    );
};

export const AfternoonWorkingDayField: FC = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { t } = useTranslation();
    return (
        <Controller
            name='afternoonWorkingDays'
            control={control}
            render={({ field }) => {
                return (
                    <FormGroup row>
                        {Object.keys(DayOfWeek)
                            .map((k: string) => DayOfWeek[k as keyof typeof DayOfWeek])
                            .map(dayOfWeek => {
                                return (
                                    <FormControlLabel
                                        {...field}
                                        key={dayOfWeek}
                                        // we add a label and hide it, so everything is aligned
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                visibility: 'hidden',
                                            },
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minWidth: 28,
                                        }}
                                        label={t(getDayOfWeekShortTranslationKey(dayOfWeek))}
                                        control={getCheckbox(field, dayOfWeek)}
                                    />
                                );
                            })}
                        {!!errors.afternoonWorkingDays && (
                            <Typography variant='body1' color='error'>
                                {t('general.validations.required')}
                            </Typography>
                        )}
                    </FormGroup>
                );
            }}
        />
    );
};

type HoursPerWeekProps = {
    weeklyWorkingTimes: WeeklyWorkingTime[];
};

export const RateInfos: FC = () => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const { palette } = useTheme();

    const morningWorkingDays: DayOfWeek[] = watch('morningWorkingDays');
    const afternoonWorkingDays: DayOfWeek[] = watch('afternoonWorkingDays');

    return (
        <Typography color={palette.primary.main} variant='body1' mb={0.25}>{`${t('employee.work_pattern.dialog.rate')}  ${getRate(
            morningWorkingDays,
            afternoonWorkingDays,
        )}%`}</Typography>
    );
};

export const HoursPerWeek: FC<HoursPerWeekProps> = ({ weeklyWorkingTimes }) => {
    const { watch } = useFormContext();
    const { palette } = useTheme();

    const selectedWeeklyWorkingTimeId = watch('weeklyWorkingTimeId');
    const morningWorkingDays: DayOfWeek[] = watch('morningWorkingDays');
    const afternoonWorkingDays: DayOfWeek[] = watch('afternoonWorkingDays');

    return (
        <Typography color={palette.primary.main} maxWidth={'75px'} mb={0.25}>
            {getHoursPerWeekLabel(
                weeklyWorkingTimes.find(w => w.id === selectedWeeklyWorkingTimeId),
                getRate(morningWorkingDays, afternoonWorkingDays),
            )}
        </Typography>
    );
};
const getDefaultDays = () => {
    const defaultDays: DayOfWeek[] = Object.keys(DayOfWeek)
        .map((k: string) => DayOfWeek[k as keyof typeof DayOfWeek])
        .filter(dayOfWeek => dayOfWeek !== DayOfWeek.SATURDAY && dayOfWeek !== DayOfWeek.SUNDAY);
    return defaultDays;
};
