import { useGetCompanyFolders } from '@/hooks/document/Document.hook';
import { FC } from 'react';
import { CompanyDocumentContainer } from './company/CompanyDocumentContainer';
import { StateHandler } from '@/Components/state-handler/StateHandler';

export const CompanyDocumentsPage: FC = () => {
    const { data = [], isLoading, isError, error } = useGetCompanyFolders();
    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <CompanyDocumentContainer folders={data} />
        </StateHandler>
    );
};
