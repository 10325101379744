import { EmployeeLeaveRequestTable } from '@/page/leave/EmployeeLeaveRequestTable';
import { Stack, Typography } from '@mui/material';
import { compareAsc, compareDesc } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';

type UpcomingLeavesProps = {
    employeeId: number;
    leaveRequests: LeaveRequest[];
    onChange: () => void;
};

export const EmployeeLeaves: FC<UpcomingLeavesProps> = ({ employeeId, leaveRequests, onChange }) => {
    const { t } = useTranslation();

    const upcomingLeaves = leaveRequests.filter(i => {
        return i.endDate ? new Date(i.endDate) > new Date() || new Date(i.endDate).toLocaleDateString() === new Date().toLocaleDateString() : true;
    });

    const pastLeaves = leaveRequests.filter(i =>
        i.endDate ? new Date(i.endDate) < new Date() && new Date(i.endDate).toLocaleDateString() !== new Date().toLocaleDateString() : false,
    );

    return (
        <Stack direction='column' gap={2}>
            {upcomingLeaves?.length > 0 && (
                <Stack gap={2}>
                    <Typography variant='h1'>{t('my_leaves.upcoming_leaves')}</Typography>
                    <EmployeeLeaveRequestTable leaveRequests={upcomingLeaves} employeeId={employeeId} compareDatesByAge={compareAsc} onChange={onChange} />
                </Stack>
            )}
            {pastLeaves?.length > 0 && (
                <Stack gap={2}>
                    <Typography variant='h1'>{t('my_leaves.past_leaves')}</Typography>
                    <EmployeeLeaveRequestTable leaveRequests={pastLeaves} employeeId={employeeId} compareDatesByAge={compareDesc} onChange={onChange} />
                </Stack>
            )}
        </Stack>
    );
};
