import { MoreVerticalIcon } from '@/assets/Icons/Icons';
import { BasicMenu } from '@/Components/basic-menu/BasicMenu';
import { EmptyStateIcon } from '@/Components/empty-state/icons/EmptyStateIcon';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { EmployeeRole, EmployeeRoleCreationMutation, EmployeeRoleUpdateMutation } from '@/domain/employee-role/EmployeeRole.model';
import { createEmployeeRole, deleteEmployeeRole, updateEmployeeRole } from '@/domain/employee-role/EmployeeRole.service';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { canManageEmployeePersonalInfos } from '@/domain/permission/Permission.service';
import { useGetEmployeeRoles } from '@/hooks/employee-role/EmployeeRole.hook';
import { useGetRoles } from '@/hooks/role/Role.hook';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Divider, List, ListItem, ListItemButton, Paper, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RoleDialog, RoleFormValues } from './employee-profile-role/RoleDialog';

export const EmployeeProfileRolesPage: FC = () => {
    const params = useParams();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const employeeId = params?.employeeId ? Number(params?.employeeId) : currentEmployee?.id;
    const policies: EmployeePolicy[] = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const { data: roles = [], isLoading: isRolesLoading, isError: isRolesError, error: rolesError } = useGetRoles();
    const {
        data: employeeRoles = [],
        isLoading: isEmployeeRolesLoading,
        isError: isEmployeeRolesError,
        error: employeeRolesError,
        refetch: refetchEmployeeRoles,
    } = useGetEmployeeRoles({ employeeIds: employeeId ? [employeeId] : [] });
    const dispatch = useAppDispatch();
    const [selectedEmployeeRole, setSelectedEmployeeRole] = useState<EmployeeRole>();
    const [isRoleDialogOpen, setIsRoleDialogOpen] = useState<boolean>(false);
    const isLoading = isRolesLoading && isEmployeeRolesLoading;
    const isError = isRolesError && isEmployeeRolesError;
    const { t } = useTranslation();

    if (!employeeId) {
        throw new Error('Employee is not defined');
    }
    const isModificationEnabled = canManageEmployeePersonalInfos(policies, employeeId);

    const handleUpdate = async (roleFormValues: RoleFormValues, selectedEmployeeRoleId: number) => {
        if (!roleFormValues.role) {
            return;
        }
        const employeeRoleUpdateMutation: EmployeeRoleUpdateMutation = {
            roleId: roleFormValues.role.id,
            startDate: roleFormValues.startDate,
        };

        try {
            await updateEmployeeRole(selectedEmployeeRoleId, employeeRoleUpdateMutation);
        } catch (e) {
            handleError(e);
        } finally {
            onClose();
            dispatch({
                type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
                refetchEmployee: true,
            });
        }
    };

    const handleSave = async (roleFormValues: RoleFormValues) => {
        if (!roleFormValues.role) {
            return;
        }
        const employeeRoleCreationMutation: EmployeeRoleCreationMutation = {
            employeeId: employeeId,
            roleId: roleFormValues.role.id,
            startDate: roleFormValues.startDate,
        };

        try {
            await createEmployeeRole(employeeRoleCreationMutation);
        } catch (e) {
            handleError(e);
        } finally {
            onClose();
            dispatch({
                type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
                refetchEmployee: true,
            });
        }
    };

    const onSave = (roleFormValues: RoleFormValues) => {
        if (roleFormValues?.selectedEmployeeRoleId) {
            handleUpdate(roleFormValues, roleFormValues?.selectedEmployeeRoleId).catch(handleError);
        } else {
            handleSave(roleFormValues).catch(handleError);
        }
    };

    const onDeleteRole = async (employeeRole: EmployeeRole) => {
        const employeeRoleId = employeeRole.id;

        try {
            await deleteEmployeeRole(employeeRoleId);
        } catch (e) {
            handleError(e);
        } finally {
            onClose();
            dispatch({
                type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
                refetchEmployee: true,
            });
        }
    };

    const onClose = () => {
        refetchEmployeeRoles().catch(handleError);
        setSelectedEmployeeRole(undefined);
        setIsRoleDialogOpen(false);
    };

    const handleRowClick = (employeeRole: EmployeeRole) => {
        setSelectedEmployeeRole(employeeRole);
        setIsRoleDialogOpen(true);
    };

    const addRoleButton = isModificationEnabled && (
        <Button variant='contained' onClick={() => setIsRoleDialogOpen(true)}>
            {t('role.role_tab.role_add_action')}
        </Button>
    );

    const emptyStateComponent = (
        <Stack flex={1} justifyContent={'center'} alignItems={'center'} component={Paper}>
            <Stack gap={2} alignItems={'center'}>
                <EmptyStateIcon />
                <Typography variant={'h1'}>{t('role.role_tab.role_empty_state')}</Typography>
                {addRoleButton}
            </Stack>
        </Stack>
    );

    return (
        <Stack flex={1}>
            <StateHandler
                isLoading={isLoading}
                isError={isError}
                isEmpty={!employeeRoles?.length}
                emptyStateComponent={emptyStateComponent}
                error={employeeRolesError || rolesError}
            >
                <Stack flex={1} component={Paper} gap={3} p={2.5}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant='h1' pl={1}>
                            {t('role.role_tab.role_title')}
                        </Typography>

                        {addRoleButton}
                    </Stack>
                    <Stack component={List} flex={1} divider={<Divider />} disablePadding>
                        {employeeRoles?.map(employeeRole => (
                            <EmployeeRoleRow
                                key={employeeRole.id}
                                employeeRole={employeeRole}
                                onDeleteRole={onDeleteRole}
                                handleRowClick={handleRowClick}
                                isModificationEnabled={isModificationEnabled}
                            />
                        ))}
                    </Stack>
                </Stack>
            </StateHandler>
            {isRoleDialogOpen && (
                <RoleDialog availableRoles={roles} employeeId={employeeId} onSave={onSave} selectedEmployeeRole={selectedEmployeeRole} onClose={onClose} />
            )}
        </Stack>
    );
};

type EmployeeRoleRowProps = {
    employeeRole: EmployeeRole;
    onDeleteRole: (employeeRole: EmployeeRole) => void;
    handleRowClick: (employeeRole: EmployeeRole) => void;
    isModificationEnabled: boolean;
};

export const EmployeeRoleRow: FC<EmployeeRoleRowProps> = ({ employeeRole, onDeleteRole, handleRowClick, isModificationEnabled }) => {
    const { t } = useTranslation();

    const getActionMenuItems = (employeeRole: EmployeeRole) => {
        return [
            {
                title: t('role.role_tab.role_action_menu_delete'),
                onClick: () => onDeleteRole(employeeRole),
            },
        ];
    };
    return (
        <ListItem key={employeeRole.id} disablePadding>
            <Stack
                component={isModificationEnabled ? ListItemButton : ListItem}
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                alignSelf='stretch'
                py={2}
                onClick={() => {
                    if (!isModificationEnabled) {
                        return;
                    }
                    handleRowClick(employeeRole);
                }}
                flexWrap='wrap'
                columnGap={3}
                borderRadius={1}
                rowGap={1}
            >
                <Stack>
                    <Typography variant={'body1bold'}>{employeeRole.role.name}</Typography>
                    <Typography variant={'body1'}>
                        {t('role.role_tab.role_start_date', {
                            startDate: employeeRole.startDate,
                        })}
                    </Typography>
                </Stack>
            </Stack>
            {isModificationEnabled && <BasicMenu items={getActionMenuItems(employeeRole)} endIcon={<MoreVerticalIcon />} />}
        </ListItem>
    );
};
