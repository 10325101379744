import { canApproveRejectOtherEmployeeLeaveRequests, canManageOtherEmployeeLeaves, canSeeOtherEmployeeLeaves } from '@/domain/permission/Permission.service';
import { useAppSelector } from '@/stores/store';
import { Paper, Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

type LeavesTabSection = 'pending' | 'all' | 'balances' | 'calendar';

export const ManageLeavesHeader: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const location = useLocation();

    const tabOptions: { name: string; value: `/manage-leaves/${LeavesTabSection}` }[] = [];

    if (!currentEmployee) {
        throw new Error('Current employee is not defined');
    }

    if (canSeeOtherEmployeeLeaves(realm?.realmFeatures, grantedPolicies, currentEmployee?.id)) {
        tabOptions.push({
            name: t('leaves_page.tabs.calendar'),
            value: '/manage-leaves/calendar',
        });
    }
    if (canApproveRejectOtherEmployeeLeaveRequests(grantedPolicies, currentEmployee?.id)) {
        tabOptions.push({
            name: t('leaves_page.tabs.pending_my_approval'),
            value: '/manage-leaves/pending',
        });
    }
    if (canManageOtherEmployeeLeaves(realm?.realmFeatures, grantedPolicies, currentEmployee?.id)) {
        tabOptions.push({
            name: t('leaves_page.tabs.all_leaves'),
            value: '/manage-leaves/all',
        });
    }
    if (canApproveRejectOtherEmployeeLeaveRequests(grantedPolicies, currentEmployee?.id)) {
        tabOptions.push({
            name: t('leaves_page.tabs.balances'),
            value: '/manage-leaves/balances',
        });
    }

    return (
        <Paper
            sx={{
                borderRadius: 1,
                display: { xs: 'none', md: 'block' },
            }}
        >
            <Tabs value={location.pathname} onChange={(_e, value) => navigate(value)} aria-label='Manage Leaves Tabs Control'>
                {tabOptions.map(item => (
                    <Tab key={item.name} label={item.name} value={item.value} />
                ))}
            </Tabs>
        </Paper>
    );
};
