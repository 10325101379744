import { useAgGridWrapper } from '@/Components/ag-grid-wrapper/useAgGridWrapper';
import { AsyncSelectFilter, FiltersBar, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { getNestedValueByPath, getSelectFilterNumberValues } from '@/Components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/Components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { EmployeeReview, UpdateEmployeeReviewMutation } from '@/domain/employee-review/EmployeeReview.model';
import { updateEmployeeReview } from '@/domain/employee-review/EmployeeReview.service';
import { isValidEmployeeFilterType, doesEmployeeMatchFilter } from '@/domain/employee/Employee.service';
import { useEmployeeReviewFilters } from '@/hooks/employee-review/EmployeeReviewFilters.hook';
import { EmployeeFilter } from '@/hooks/employee/EmployeeFilters.hook';
import { useGetReview } from '@/hooks/review/Review.hook';
import { EmployeeReviewCycleList } from '@/page/review/employee-review-cycle-list/EmployeeReviewCycleList';
import { ManageEmployeeReviewsCyclePageHeader } from '@/page/review/employee-review-cycle-page/ManageEmployeeReviewsCyclePageHeader';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Paper, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const ManageEmployeeReviewsCyclePage: FC = () => {
    const { t } = useTranslation();
    const { reviewId } = useParams();
    const reviewIdNumber = reviewId ? Number(reviewId) : undefined;

    const { filters: availableFilters } = useEmployeeReviewFilters();

    const [filters, setFilters] = useFiltersStorage('employee-review-filters', availableFilters);

    const handleFilterChange = (filters: EmployeeFilter[]) => {
        setFilters(filters);
    };

    const { data: review, isError: isReviewError, isLoading: isReviewLoading, refetch: refetchReview, error: reviewError } = useGetReview(reviewIdNumber);

    const onUpdateReviewers = (request: UpdateEmployeeReviewMutation, employeeReviewId: number) => {
        updateEmployeeReview(employeeReviewId, request)
            .then(() => {
                refetchReview().catch(handleError);
            })
            .then(() => showSnackbar(t('reviews.messages.reviewers_updated'), 'success'))
            .catch(handleError);
    };

    const getFilteredEmployeeReviews = (employeeReviews: EmployeeReview[], filters: (SelectFilter | AsyncSelectFilter)[]): EmployeeReview[] => {
        // if one of the filters is filled, we have to apply a client side filtering
        const filtersFilled = filters?.filter(filter => !!filter.value?.length);

        return !filtersFilled?.length
            ? employeeReviews
            : employeeReviews.filter(row =>
                  // if one of the filter is not matching, we don't want to display the row
                  filtersFilled.every(filter => isFilterMatched(filter, row)),
              );
    };

    const isFilterMatched = (filter: SelectFilter | AsyncSelectFilter, er: EmployeeReview): boolean => {
        const key = filter.key;

        if (isValidEmployeeFilterType(key)) {
            const ids = getSelectFilterNumberValues(filter);
            return doesEmployeeMatchFilter(er.employee, ids, key);
        } else {
            const nestedValue = getNestedValueByPath(er, key);
            return !!filter.value?.find(option => option.value === nestedValue);
        }
    };

    // agGridWrapper is initialized outside because we need to get the selected rows from the grid and pass it to ReviewCyclePageHeader
    const agGridWrapper = useAgGridWrapper<EmployeeReview>();

    const employeeReviewsFiltered = getFilteredEmployeeReviews(review?.employeeReviews ?? [], filters).map(employeeReview => {
        return {
            ...employeeReview,
            review: review,
        };
    });
    return (
        <StateHandler isLoading={isReviewLoading} isError={isReviewError} error={reviewError}>
            <Stack flex={1} gap={2}>
                {review && <ManageEmployeeReviewsCyclePageHeader review={review} employeeReviewsSelected={agGridWrapper.selectedRows} />}
                <Stack direction='row' component={Paper} p={1} justifyContent='space-between'>
                    <FiltersBar filters={filters} onFiltersChange={handleFilterChange} />
                </Stack>

                <EmployeeReviewCycleList
                    employeeReviews={employeeReviewsFiltered ?? []}
                    onUpdate={() => {
                        refetchReview().catch(handleError);
                    }}
                    onUpdateReviewers={onUpdateReviewers}
                    agGridWrapper={agGridWrapper}
                    showContextMenu
                />
            </Stack>
        </StateHandler>
    );
};
