import { FC } from 'react';
import { Stack } from '@mui/system';
import { Paper } from '@mui/material';
import { FiltersBar, FilterType } from '@/Components/filters-bar/FiltersBar';
import { useFiltersStorage } from '@/Components/filters-bar/useFiltersStorage';
import { useGetEmployeeWeeklyTimeBalances } from '@/hooks/timesheet-insight/TimesheetInsight.hook';
import { AgGridWrapper } from '@/Components/ag-grid-wrapper/AgGridWrapper';
import { ColDef, NestedFieldPaths } from '@ag-grid-community/core';
import { EmployeeYearlyTimeBalance, TimesheetInsightSearch } from '@/domain/timesheet-insight/TimesheetInsight.model';
import { useTranslation } from 'react-i18next';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { getFilterValueIdsByKey, getSelectFilterStringValuesByKey, getTimeRangeFilterValueByKey } from '@/Components/filters-bar/FiltersBar.util';
import { useAgGridWrapper } from '@/Components/ag-grid-wrapper/useAgGridWrapper';
import { DurationUnit } from '@/I18n/i18n';
import { DateRangePicker } from '@/Components/date-range-picker/DateRangePicker';
import { useDateRangeStorage } from '@/Components/date-range-picker/DateRangePicker.hook';
import { useTimesheetWeeklyInsightsTableFilters } from '@/hooks/timesheet/TimesheetWeeklyInsightsTableFilters.hook';
import { DayOfWeek, formatToLocalDate } from '@/utils/datetime.util';
import { DatatableAdditionalAction } from '@/Components/datatable-additional-action/DatatableAdditionalAction';

export const TimesheetsWeeklyInsightPage: FC = () => {
    const { t } = useTranslation();
    const { filters: availableFilters } = useTimesheetWeeklyInsightsTableFilters();
    const [filters, setFilters] = useFiltersStorage('timesheets-weekly-insights-filters', availableFilters);
    const { dateRangeViewType, dateRange, onDateRangeChange } = useDateRangeStorage({
        storageKey: 'timesheets-weekly-insights-date-range',
        initialViewType: 'YEAR',
    });

    const { gridRef, setGridRef, quickFilter } = useAgGridWrapper<EmployeeYearlyTimeBalance>();

    const {
        data: employeeWeeklyTimeBalances = [],
        isLoading: isEmployeeTimeBalancesLoading,
        error: employeeTimeBalancesError,
        isError: isEmployeeTimeBalancesError,
        isFetching: isFetchingEmployeeTimeBalances,
    } = useGetEmployeeWeeklyTimeBalances(mapFiltersToSearch(filters, dateRange));

    // There will be no duplicated week days 1 - 52 OR 53
    const weeklyTimes = new Set(employeeWeeklyTimeBalances.flatMap(v => v.weeklyWorkedTime).map(v => v.weekNumber));

    const weeklyColumns: ColDef<EmployeeYearlyTimeBalance>[] = [...weeklyTimes].map((weekNumber, index) => {
        return {
            headerName: weekNumber.toString(),
            // ColId is used to identify the column for cell editing
            colId: weekNumber.toString(),
            // AG grid seems not handle double nested field paths
            // Its ok to do `thirdPartyIdentifiers.${index}` but not `thirdPartyIdentifiers.${index}.value`
            field: `weeklyWorkedTime.${index}.workedTime` as NestedFieldPaths<EmployeeYearlyTimeBalance>,
            cellDataType: false,
            valueGetter: ({ data }) => (data?.weeklyWorkedTime?.[index]?.workedTime ?? 0) / 60,
            valueFormatter: ({ value }) =>
                value
                    ? t('duration.formatDuration', {
                          duration: value,
                          unit: DurationUnit.HOURS,
                      })
                    : '-',
            cellStyle: ({ data }) => {
                const workedTime = data?.weeklyWorkedTime?.[index]?.workedTime ?? 0;
                const maxDuration = data?.weeklyWorkedTime?.[index]?.maxWorkedTime ?? 0;
                if (workedTime > maxDuration) {
                    return { color: 'red' };
                }
            },
        };
    });

    const columnDefs: ColDef<EmployeeYearlyTimeBalance>[] = [
        {
            field: 'employee',
            type: 'employee',
            headerName: t('general.employee'),
            pinned: 'left',
        },
        ...weeklyColumns,
        {
            field: 'yearlyOvertimeWorkedTime',
            headerName: t('timesheets.weekly_insights_page.yearly_overtime'),
            valueGetter: ({ data: employeeWeeklyTimeBalance }) => (employeeWeeklyTimeBalance?.yearlyOvertimeWorkedTime ?? 0) / 60,
            valueFormatter: ({ value }) =>
                value
                    ? t('duration.formatDuration', {
                          duration: value,
                          unit: DurationUnit.HOURS,
                      })
                    : '-',
            pinned: 'right',
            cellStyle: { textAlign: 'center' },
        },
    ];

    const handleDownloadData = () => {
        gridRef.current?.api?.exportDataAsExcel();
    };

    return (
        <Stack gap={2} flex={1}>
            <Stack component={Paper} direction={'row'} alignItems={'center'} justifyContent={'space-between'} p={1}>
                <Stack direction={'row'} alignItems={'flex-start'} gap={1} flexWrap={'wrap'}>
                    <DateRangePicker dates={dateRange} onDatesChanged={onDateRangeChange} defaultViewType={dateRangeViewType} availableViews={['YEAR']} />
                    <FiltersBar filters={filters} onFiltersChange={setFilters} flex={1} />
                </Stack>

                <DatatableAdditionalAction
                    quickFilter={quickFilter}
                    onBtnExport={handleDownloadData}
                    disabled={!employeeWeeklyTimeBalances.length || isFetchingEmployeeTimeBalances}
                />
            </Stack>

            <Stack flex={1}>
                <StateHandler
                    isLoading={isEmployeeTimeBalancesLoading}
                    isError={isEmployeeTimeBalancesError}
                    error={employeeTimeBalancesError}
                    isEmpty={!employeeWeeklyTimeBalances?.length}
                >
                    <AgGridWrapper columnDefs={columnDefs} rowData={employeeWeeklyTimeBalances} initRef={setGridRef} rowSelection={'multiple'} compact />
                </StateHandler>
            </Stack>
        </Stack>
    );
};

const mapFiltersToSearch = (filters: FilterType[], dateRange: [Date, Date]): TimesheetInsightSearch => {
    const [startTime, endTime] = getTimeRangeFilterValueByKey(filters, 'timeRange') ?? [];

    return {
        startDate: formatToLocalDate(dateRange[0]),
        endDate: formatToLocalDate(dateRange[1]),
        dayOfWeek: getSelectFilterStringValuesByKey(filters, 'dayOfWeek')?.[0] as DayOfWeek,
        startTime,
        endTime,
        locationIds: getFilterValueIdsByKey(filters, 'locations'),
        departmentIds: getFilterValueIdsByKey(filters, 'departments'),
        jobIds: getFilterValueIdsByKey(filters, 'jobs'),
        managerIds: getFilterValueIdsByKey(filters, 'managers'),
    };
};
