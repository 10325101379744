import * as yup from 'yup';
import { leaveTypeSchema } from '@/domain/leave-type/LeaveType.schema';
import { hoursMinutesSchema } from '@/utils/datetime.schema';
import i18next from 'i18next';
import { getLocalDateTestConfig, getMinutesFromHoursMinutes, HoursMinutes, LocalDate } from '@/utils/datetime.util';

export enum TimesheetAdjustmentAction {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
    TRANSFER = 'TRANSFER',
}

export const paymentAdjustmentFormSchema = yup
    .object()
    .shape({
        leaveType: leaveTypeSchema.nullable(),
        actionType: yup.string().required().oneOf(Object.values(TimesheetAdjustmentAction)),
        hoursMinutes: hoursMinutesSchema.required().test({
            message: i18next.t('general.validations.required'),
            test: (value: HoursMinutes) => getMinutesFromHoursMinutes(value) > 0,
        }),
        comment: yup.string().default(''),
        requestDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
    })
    .required();

export type PaymentAdjustmentFormSchema = yup.InferType<typeof paymentAdjustmentFormSchema>;
