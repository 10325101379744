import { Alert, Button, DialogActions, DialogContent, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/stores/store';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { hasAtLeastOneMissingPeerOrUpwardFeedback, managerReviewSubmitted, selfReviewSubmitted } from '@/domain/employee-review/EmployeeReview.service';
import { DialogWrapper } from '@/Components/dialog-wrapper/DialogWrapper';
import { EmployeeReviewProgress } from '@/page/review/employee-review-progress/EmployeeReviewProgress';

type StartDiscussionDialogProps = {
    employeeReview: EmployeeReview;
    onClose: () => void;
    onStartDiscussion: () => void;
};

export const EmployeeReviewStartDiscussionDialog: FC<StartDiscussionDialogProps> = ({ employeeReview, onClose, onStartDiscussion }) => {
    const { t } = useTranslation();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getDialogTitle = (employeeReview: EmployeeReview): string => {
        if (!selfReviewSubmitted(employeeReview) && !managerReviewSubmitted(employeeReview)) {
            return t('reviews.start_discussion_dialog.title_missing_feedbacks');
        } else {
            return t('reviews.start_discussion_dialog.title');
        }
    };

    const showWarningMessage = (employeeReview: EmployeeReview): boolean => {
        return hasAtLeastOneMissingPeerOrUpwardFeedback(employeeReview) && selfReviewSubmitted(employeeReview) && managerReviewSubmitted(employeeReview);
    };

    return (
        <DialogWrapper open={true} onClose={onClose} header={getDialogTitle(employeeReview)} maxWidth={'sm'}>
            <DialogContent
                sx={{
                    px: 3,
                    pb: isMobile ? 0 : 2,
                }}
            >
                <Stack gap={2}>
                    {currentEmployee && <EmployeeReviewProgress employeeReview={employeeReview} currentEmployee={currentEmployee} />}

                    {showWarningMessage(employeeReview) && (
                        <Alert severity='warning' elevation={0}>
                            <Typography variant='body2'>{t('reviews.start_discussion_dialog.warning_message')}</Typography>
                        </Alert>
                    )}
                </Stack>
            </DialogContent>

            <DialogActions sx={{ paddingTop: isMobile ? '' : '0' }}>
                <Button
                    onClick={onStartDiscussion}
                    color='primary'
                    disabled={
                        currentEmployee?.id === employeeReview?.employee.id || !selfReviewSubmitted(employeeReview) || !managerReviewSubmitted(employeeReview)
                    }
                >
                    {t('reviews.start_discussion_dialog.save_button')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
