import { ChangeEventHandler, FC } from 'react';
import { TextFilter as TextFilterType } from '@/Components/filters-bar/FiltersBar';
import useDebounce from '@/hooks/Debounce.hook';
import { Stack, TextField } from '@mui/material';

export const TextFilter: FC<{ filter: TextFilterType; onFilterUpdated: (filter: TextFilterType) => void }> = ({ filter, onFilterUpdated }) => {
    const debounce = useDebounce();
    const handleTextChange: ChangeEventHandler<HTMLInputElement> = e => {
        debounce(() => {
            const updatedFilter = { ...filter, value: e.target.value };
            onFilterUpdated(updatedFilter);
        });
    };
    return (
        <Stack p={1}>
            <TextField fullWidth autoFocus defaultValue={filter.value ?? ''} onChange={handleTextChange} />
        </Stack>
    );
};
