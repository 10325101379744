import { timesheetSettingApi } from '@/api/timesheet-setting/TimesheetSetting.api';
import { TimesheetSetting, TimesheetSettingMutation } from './TimesheetSetting.model';

export function getTimesheetSettingById(timesheetSettingId: number): Promise<TimesheetSetting> {
    return timesheetSettingApi.getTimesheetSettingById(timesheetSettingId);
}

export function searchTimesheetSettings(): Promise<TimesheetSetting[]> {
    return timesheetSettingApi.searchTimesheetSettings();
}

export function postTimesheetSettings(mutation: TimesheetSettingMutation): Promise<TimesheetSetting[]> {
    return timesheetSettingApi.postTimesheetSettings(mutation);
}

export function putTimesheetSettings(mutation: TimesheetSettingMutation, timesheetSettingId: number): Promise<TimesheetSetting[]> {
    return timesheetSettingApi.putTimesheetSettings(mutation, timesheetSettingId);
}

export function deleteTimesheetSettings(timesheetSettingId: number): Promise<void> {
    return timesheetSettingApi.deleteTimesheetSettings(timesheetSettingId);
}
