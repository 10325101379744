import { API_BASE_URL, client } from '@/api/common';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { reviewRatingScaleApi, ReviewRatingScaleDTO } from '@/api/review-rating-scale/ReviewRatingScale.api';
import {
    EmployeeReviewFeedback,
    EmployeeReviewFeedbackItem,
    EmployeeReviewFeedbackItemSearch,
    EmployeeReviewFeedbackMutation,
    EmployeeReviewFeedbackObjective,
    EmployeeReviewFeedbackQuestion,
    EmployeeReviewFeedbackResult,
    EmployeeReviewFeedbackSection,
    EmployeeReviewFeedbackSkill,
    EmployeeReviewFeedbackSkillQuestion,
    EmployeeReviewFeedbackSkills,
} from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeReviewSearch } from '@/domain/employee-review/EmployeeReview.model';
import { ReviewFeedback } from '@/domain/review/Review.model';
import { AxiosResponse } from 'axios';
import { mapObjectiveDTO, ObjectiveDTO } from '@/api/objective/Objective.api';
import { SkillDTO } from '@/api/skill/Skill.api';

type EmployeeReviewFeedbackResultDTO = EmployeeReviewFeedbackResult;
type EmployeeReviewFeedbackItemSearchDTO = EmployeeReviewFeedbackItemSearch;

export type EmployeeReviewFeedbackDTO = Omit<EmployeeReviewFeedback, 'feedbackDeadlineDate' | 'employee' | 'feedbacks'> & {
    feedbackDeadlineDate: string;
    employee: EmployeeDTO;
    feedbacks: EmployeeReviewFeedbackItemDTO[];
};

export type EmployeeReviewFeedbackRequest = EmployeeReviewFeedbackMutation;

export type EmployeeReviewFeedbackItemDTO = Omit<
    EmployeeReviewFeedbackItem,
    | 'employeeReviewFeedbackQuestion'
    | 'employeeReviewFeedbackSkillQuestion'
    | 'employeeReviewFeedbackSection'
    | 'reviewItem'
    | 'employeeReviewFeedbackObjectives'
    | 'employeeReviewFeedbackSkills'
    | 'rating'
> & {
    question: EmployeeReviewFeedbackQuestionDTO | undefined;
    section: EmployeeReviewFeedbackSectionDTO | undefined;
    objectives: EmployeeReviewFeedbackObjectiveDTO[] | undefined;
    skills: EmployeeReviewFeedbackSkillsDTO | undefined;
    skillQuestion: EmployeeReviewFeedbackSkillQuestionDTO | undefined;
    rating: ReviewRatingScaleDTO | undefined;
    reviewItem: EmployeeReviewFeedbackItemDTO | undefined;
};

export type EmployeeReviewFeedbackSkillDTO = Omit<EmployeeReviewFeedbackSkill, 'skill'> & {
    skill: SkillDTO;
};

export type EmployeeReviewFeedbackSkillsDTO = Omit<EmployeeReviewFeedbackSkills, 'skills'> & {
    skills: EmployeeReviewFeedbackSkillDTO[];
};

export type EmployeeReviewFeedbackSkillQuestionDTO = Omit<EmployeeReviewFeedbackSkillQuestion, 'skill'> & {
    skill: SkillDTO;
};

export type EmployeeReviewFeedbackObjectiveDTO = Omit<EmployeeReviewFeedbackObjective, 'objective'> & {
    objective: ObjectiveDTO | undefined;
};

export const mapEmployeeReviewFeedbackObjectiveDTO = (employeeReviewFeedbackObjective: EmployeeReviewFeedbackObjectiveDTO): EmployeeReviewFeedbackObjective => {
    return {
        ...employeeReviewFeedbackObjective,
        objective: employeeReviewFeedbackObjective.objective ? mapObjectiveDTO(employeeReviewFeedbackObjective.objective) : undefined,
    };
};

export const mapEmployeeReviewFeedbackItemDTO = (item: EmployeeReviewFeedbackItemDTO): EmployeeReviewFeedbackItem => {
    return {
        ...item,
        employeeReviewFeedbackQuestion: item.question,
        employeeReviewFeedbackSection: item.section,
        employeeReviewFeedbackObjectives: (item.objectives ?? []).map(objective => mapEmployeeReviewFeedbackObjectiveDTO(objective)),
        employeeReviewFeedbackSkills: item.skills,
        employeeReviewFeedbackSkillQuestion: item.skillQuestion,
        rating: item.rating ? reviewRatingScaleApi.mapToReviewRatingScale(item.rating) : undefined,
    };
};

export type EmployeeReviewFeedbackQuestionDTO = EmployeeReviewFeedbackQuestion;
export type EmployeeReviewFeedbackSectionDTO = EmployeeReviewFeedbackSection;

export type ReviewFeedbackDTO = Omit<ReviewFeedback, 'reviewer'> & {
    reviewer: EmployeeDTO;
};

export const mapReviewFeedbackDTO = (feedback: ReviewFeedbackDTO): ReviewFeedback => {
    return {
        ...feedback,
        reviewer: feedback.reviewer ? mapEmployeeDTO(feedback.reviewer) : undefined,
    };
};

export const mapEmployeeReviewFeedbackDto = (feedback: EmployeeReviewFeedbackDTO): EmployeeReviewFeedback => {
    return {
        ...feedback,
        feedbackDeadlineDate: feedback.feedbackDeadlineDate ? new Date(feedback.feedbackDeadlineDate) : undefined,
        employee: mapEmployeeDTO(feedback.employee),
        feedbacks: feedback.feedbacks.map(item => mapEmployeeReviewFeedbackItemDTO(item)),
    };
};

const getEmployeeReviewFeedbacksResultsItems = async (filters: EmployeeReviewFeedbackItemSearch = {}): Promise<EmployeeReviewFeedbackResult[]> => {
    return (
        await client.post<EmployeeReviewFeedbackResultDTO, AxiosResponse<EmployeeReviewFeedbackResultDTO[]>, EmployeeReviewFeedbackItemSearchDTO>(
            API_BASE_URL + `/employee-review-feedback/results/items/search`,

            filters,
        )
    ).data;
};

export async function getEmployeeReviewFeedback(employeeReviewId: number): Promise<EmployeeReviewFeedback> {
    const { data } = await client.get<EmployeeReviewFeedbackDTO>(API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}`);
    return {
        ...data,
        feedbacks: data.feedbacks.map(feedback => mapEmployeeReviewFeedbackItemDTO(feedback)),
        employee: mapEmployeeDTO(data.employee),
        feedbackDeadlineDate: new Date(data.feedbackDeadlineDate),
    };
}

export async function submitEmployeeReviewFeedback(employeeReviewId: number, request: EmployeeReviewFeedbackMutation[]): Promise<EmployeeReviewFeedbackItem> {
    const { data } = await client.post<EmployeeReviewFeedbackItemDTO, AxiosResponse<EmployeeReviewFeedbackItemDTO, EmployeeReviewFeedbackMutation>>(
        API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}`,
        request,
    );
    return mapEmployeeReviewFeedbackItemDTO(data);
}

export async function updateEmployeeReviewFeedbacksItem(
    employeeReviewId: number,
    request: EmployeeReviewFeedbackMutation,
): Promise<EmployeeReviewFeedbackItem> {
    const { data } = await client.post<EmployeeReviewFeedbackItemDTO, AxiosResponse<EmployeeReviewFeedbackItemDTO, EmployeeReviewFeedbackRequest>>(
        API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/item`,
        request,
    );
    return mapEmployeeReviewFeedbackItemDTO(data);
}

export async function searchEmployeeReviewFeedbacks(request: EmployeeReviewSearch): Promise<EmployeeReviewFeedback[]> {
    const { data } = await client.post<EmployeeReviewFeedbackDTO[]>(API_BASE_URL + `/employee-review-feedbacks/search`, request);

    return data.map(feedback => mapEmployeeReviewFeedbackDto(feedback));
}

export const employeeReviewFeedbackApi = {
    getEmployeeReviewFeedback,
    submitEmployeeReviewFeedback,
    updateEmployeeReviewFeedbacksItem,
    searchEmployeeReviewFeedbacks,
    getEmployeeReviewFeedbacksResultsItems,
};
