import { LanguageDropdown } from '@/Components/langage-dropdown/LanguageDropdown';
import { Survey, SurveyAnswer, SurveyQuestion } from '@/domain/survey/Survey.model';
import { UserLanguage } from '@/utils/language.util';
import { toLowerCase } from '@/utils/strings.util';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import { makeStyles } from '@mui/styles';
import { ArrowLeft01Icon, ArrowRight01Icon } from 'hugeicons-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
    activeStep: number;
    selectedLanguage: UserLanguage;
    isPreviewMode: boolean;
    token: string;
    onSubmit: (skipped: boolean) => void;
    survey?: Survey;
    onBack: () => void;
    questions?: SurveyQuestion[];
    answers: SurveyAnswer[];
    questionCount: number;
};

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 640,
        flexGrow: 1,
        background: '#F8F8F7',
        borderRadius: 10,
        [theme.breakpoints.down('lg')]: {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
    },
    stepper: {
        maxWidth: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        display: 'flex',
        padding: '12px 24px',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        background: '#fff',
        '& .MuiMobileStepper-progress': {
            width: '100%',
            height: 16,
            borderRadius: 10,
            background: 'transparent',
            '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: '#ACE588',
            },
        },
        [theme.breakpoints.down('lg')]: {
            position: 'fixed',
        },
    },
    language: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
    },
    stepperNumber: {
        display: 'flex',
        width: '100%',
        maxWidth: 640,
        alignItems: 'center',
    },
    questionNumber: {
        marginRight: 8,
        fontSize: 12,
        color: '#000A1D',
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
    actionButton: {
        fontSize: theme.spacing(2),
        '& svg': {
            height: 20,
        },
    },
    actionButtonName: {
        fontSize: 14,
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
}));

export const EmployeeSurveyFooter: FC<Props> = ({
    activeStep,
    onBack,
    selectedLanguage,
    token,
    isPreviewMode,
    onSubmit,
    questions,
    survey,
    answers,
    questionCount,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isAnswerAdded = (answer: SurveyAnswer | undefined) => {
        return !!answer?.textAnswer || !!answer?.items?.length;
    };

    const handleNext = () => {
        onSubmit(!isAnswerAdded(answers[activeStep - 1]));
    };

    const handleBack = () => {
        onBack();
    };

    if (!questions) {
        return <></>;
    }

    return (
        <div className={classes.stepper}>
            {activeStep === 0 ? (
                <Grid item className={classes.language}>
                    <Typography>{selectedLanguage}</Typography>
                    <LanguageDropdown
                        languages={survey?.languages ?? []}
                        onLanguageSelected={(language: UserLanguage) => {
                            navigate({
                                pathname: '/anonymous-survey',
                                search: `token=${token}&language=${toLowerCase(language)}`,
                            });
                        }}
                    />
                </Grid>
            ) : (
                <Button onClick={handleBack} disabled={activeStep === 0} className={classes.actionButton}>
                    <ArrowLeft01Icon />
                    <span className={classes.actionButtonName}>{t('anonymous_surveys.previous')}</span>
                </Button>
            )}
            <div className={classes.stepperNumber}>
                <Grid item>
                    <Typography className={classes.questionNumber}>
                        {' '}
                        {questionCount - questions.length + activeStep} / {questionCount}{' '}
                    </Typography>
                </Grid>
                <MobileStepper
                    variant='progress'
                    steps={questions ? questions.length + 1 : 0}
                    position='static'
                    activeStep={activeStep}
                    className={classes.root}
                    nextButton={<></>}
                    backButton={<></>}
                />
            </div>

            <Button
                size='small'
                variant='contained'
                color='primary'
                className={classes.actionButton}
                onClick={handleNext}
                disabled={isPreviewMode && activeStep === answers.length}
            >
                <span className={classes.actionButtonName}>
                    {activeStep === 0 || isAnswerAdded(answers[activeStep - 1]) ? <>{t('anonymous_surveys.next')}</> : <>{t('anonymous_surveys.skip')}</>}
                </span>
                <ArrowRight01Icon />
            </Button>
        </div>
    );
};
