import { Location } from '@/domain/location/Location.model';
import { Planning } from '@/domain/planning/Planning.model';
import { searchPlannings } from '@/domain/planning/Planning.service';
import { handleError } from '@/utils/api.util';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CompanySettingsLayout } from '../CompanySettingsLayout';
import { ConfigType } from '../types';

export const PlanningConfigurations: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const route = useLocation();
    const [plannings, setPlannings] = useState<Planning[]>([]);

    useEffect(() => {
        searchPlannings()
            .then(plannings => setPlannings(plannings))
            .catch(handleError);
    }, []);

    const config: ConfigType = {
        type: 'table',
        isOnRowClickActive: true,
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => navigate(`${route.pathname}/new`),
        },
        table: {
            columns: [
                {
                    field: 'name',
                    headerName: t('planning_setting_page.planning_name'),
                },
                {
                    field: 'locations',
                    headerName: t('planning_setting_page.site'),
                    valueFormatter: ({ value }: { value: Location[] }) => value?.map(location => location.name).join(', ') ?? '',
                },
                {
                    field: 'managers',
                    headerName: t('planning_setting_page.managers'),
                    type: 'stackedAvatars',
                },
                {
                    field: 'members',
                    headerName: t('planning_setting_page.members'),
                    type: 'stackedAvatars',
                },
                {
                    field: 'viewers',
                    headerName: t('planning_setting_page.viewers'),
                    type: 'stackedAvatars',
                },
            ],
        },
    };

    return <CompanySettingsLayout options={config} data={plannings} />;
};
