import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { EmployeeAvatar } from '@/Components/employee-avatar/EmployeeAvatar';

import { Employee } from '@/domain/employee/Employee.model';

const useStyles = makeStyles(() => ({
    cellInfo: {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            fontSize: 13,
            '> b': {
                fontSize: 14,
            },
        },
    },
}));

type Props = {
    employee: Employee;
};

export const EmployeeDialogHeader: FC<Props> = ({ employee }) => {
    const classes = useStyles();
    const name = `${employee?.firstName} ${employee?.lastName}`;

    return (
        <Grid container className={classes.cellInfo}>
            {employee && (
                <>
                    <Grid item sx={{ mr: 1 }}>
                        <EmployeeAvatar employeeAvatar={employee} />
                    </Grid>
                    <Grid item>
                        {name}
                        <b>{employee.planningPositions?.[0]?.name}</b>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
