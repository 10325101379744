import { API_BASE_URL, client } from '@/api/common';
import { EmployeeAvatarDTO } from '@/api/employee/Employee.api';
import { LeaveRequestDTO, mapLeaveRequestDTO } from '@/api/leave-request/LeaveRequest.api';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import {
    EmployeePayrollProfileChange,
    PayrollFieldChangeValue,
    PayrollProfileChangeSearch,
    PayrollSectionDefinition,
    PayrollSectionValues,
} from '@/domain/payroll/Payroll.model';
import { formatInApiDate } from '@/utils/datetime.util';
import { AxiosResponse } from 'axios';

type PayrollSectionDefinitionDTO = PayrollSectionDefinition;

type EmployeePayrollChangeValueFieldDTO = PayrollFieldChangeValue;

type EmployeePayrollChangeValueDTO = {
    id: number;
    fields: EmployeePayrollChangeValueFieldDTO[];
    updatedAt: string;
    updatedBy: EmployeeAvatarDTO;
};

type PayrollSectionValuesDTO = {
    previousValue?: EmployeePayrollChangeValueDTO;
    currentValue: EmployeePayrollChangeValueDTO;
}[];

type PayrollSectionDTO = {
    sectionDefinition: PayrollSectionDefinitionDTO;
    values: PayrollSectionValuesDTO;
};

type EmployeePayrollProfileChangeDTO = {
    employee: EmployeeAvatarDTO;
    sections: PayrollSectionDTO[];
};

type LeaveTransactionDTO = Overwrite<LeaveTransaction, { leaveRequest?: LeaveRequestDTO }>;

const mapLeaveTransactionDTO = (dto: LeaveTransactionDTO): LeaveTransaction => {
    return {
        ...dto,
        leaveRequest: dto.leaveRequest ? mapLeaveRequestDTO(dto.leaveRequest) : undefined,
    };
};

const getPayrollTransactions = async ({ startDate }: { startDate: Date }): Promise<LeaveTransaction[]> => {
    return (
        await client.get<LeaveTransactionDTO[], AxiosResponse<LeaveTransactionDTO[]>, { startDate: string }>(API_BASE_URL + `/payroll/transactions`, {
            params: {
                startDate: formatInApiDate(startDate),
            },
        })
    ).data.map(mapLeaveTransactionDTO);
};

type EmployeePayrollProfileChangeSearchRequestDTO = {
    startDate: string;
    endDate: string;
    locationIds: number[];
    employeeIds: number[];
    departmentIds: number[];
};

const getPayrollEmployeeProfileChanges = async ({ startDate, endDate, ...restSearch }: PayrollProfileChangeSearch): Promise<EmployeePayrollProfileChange[]> => {
    const params: EmployeePayrollProfileChangeSearchRequestDTO = {
        startDate: formatInApiDate(startDate),
        endDate: formatInApiDate(endDate),
        ...restSearch,
    };
    const { data } = await client.post<
        EmployeePayrollProfileChangeDTO[],
        AxiosResponse<EmployeePayrollProfileChangeDTO[]>,
        EmployeePayrollProfileChangeSearchRequestDTO
    >(API_BASE_URL + `/payroll/profile-changes`, params);
    return data.map(mapToEmployeePayrollProfileChange);
};

const mapToEmployeePayrollProfileChange = (change: EmployeePayrollProfileChangeDTO): EmployeePayrollProfileChange => {
    const mapToValue = (value: PayrollSectionValuesDTO[0]): PayrollSectionValues[0] => {
        return {
            currentValue: {
                ...value.currentValue,
                updatedAt: new Date(value.currentValue.updatedAt),
            },
            previousValue: value?.previousValue
                ? {
                      ...value.previousValue,
                      updatedAt: new Date(value?.previousValue.updatedAt),
                  }
                : undefined,
        };
    };

    return {
        employee: change.employee,
        sections: change.sections.map(section => ({
            ...section,
            values: section.values.map(mapToValue),
        })),
    };
};

export const payrollAPI = {
    getPayrollTransactions,
    getPayrollEmployeeProfileChanges,
};
