import { roleApi } from '@/api/role/Role.api';
import { Role, RoleCreationMutation, RoleSearch, RoleUpdateMutation } from '@/domain/role/Role.model';

export const searchRoles = (roleSearchMutation: RoleSearch = {}): Promise<Role[]> => {
    return roleApi.searchRoles(roleSearchMutation);
};

export const createRole = (mutation: RoleCreationMutation): Promise<Role> => {
    return roleApi.createRole(mutation);
};

export const updateRole = (id: number, mutation: RoleUpdateMutation): Promise<Role> => {
    return roleApi.updateRole(id, mutation);
};

export const deleteRole = (id: number): Promise<void> => {
    return roleApi.deleteRole(id);
};
