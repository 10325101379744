import { Employment, EmploymentCreateReason } from '@/domain/employment/Employment.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, FormControlLabel, FormHelperText, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DepartmentAutocomplete, JobsAutocomplete, LocationAutocomplete, ManagerAutocomplete } from './EmploymentDialogCommonFields';

import { Department } from '@/domain/department/Department.model';
import { mapDepartmentToTreeNode } from '@/domain/department/Department.utils';
import { CostCenterAssignmentListField } from '@/page/cost-center/CostCenterAssignmentListField';
import { EmploymentDialogFormValues, getEmploymentDialogSchema } from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';

import { DialogWrapper } from '@/Components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/Components/form/field-date/FieldDate';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { useAppSelector } from '@/stores/store';
import { getCurrentLocalDate } from '@/utils/datetime.util';
import { Add01Icon, RemoveCircleIcon } from 'hugeicons-react';

type EmploymentDialogProps = {
    open: boolean;
    employeeId: number;
    employments: Employment[];
    onSave: (employmentFormValues: EmploymentDialogFormValues) => void;
    onClose: () => void;
};

const nestedEmploymentsFieldName = 'items';
type EmploymentFieldFormValues = EmploymentDialogFormValues[typeof nestedEmploymentsFieldName][0];

export const EmploymentDialog: FC<EmploymentDialogProps> = ({ open, employeeId, onClose, onSave, employments }) => {
    const { t } = useTranslation();
    const title = employments.length ? t('employee.employment.update_employment') : t('employee.employment.add_employment');

    const schema = getEmploymentDialogSchema();

    const realm = useAppSelector(state => state.ui.currentRealm);

    // Common fields
    const { startDate, employmentCreateReason } = employments[0] ?? {};

    const formMethods = useForm<EmploymentDialogFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            startDate: startDate ?? getCurrentLocalDate(),
            employmentUpdateReason: employmentCreateReason,

            [nestedEmploymentsFieldName]: employments?.length
                ? employments.map(mapToDefaultItem)
                : [
                      {
                          employmentCostCenters: [],
                          managers: [],
                      },
                  ],
        },
    });

    const {
        fields: items,
        append: appendEmployment,
        remove: removeEmployment,
        update: updateEmployment,
    } = useFieldArray({
        control: formMethods.control,
        name: 'items',
    });

    const onCloseDialog = () => {
        formMethods.reset();
        onClose();
    };

    const isNewEmployeeOrRehired = (): boolean => {
        return employmentCreateReason === EmploymentCreateReason.NEW_EMPLOYEE || employmentCreateReason === EmploymentCreateReason.REHIRED;
    };

    // erase cost centers and manager fields when department changes
    const handleDepartmentChange = (index: number) => (department: Department | null) => {
        // Get current item in state
        const item = formMethods.getValues().items[index];

        const managers = department?.managers ?? [];

        const employmentCostCenters = hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.COST_CENTERS)
            ? (department?.departmentCostCenters ?? [])
            : [];

        updateEmployment(index, { ...item, managers, employmentCostCenters });
    };

    const handleAddSecondaryEmployment = () => {
        const emptyEmployment: Partial<EmploymentFieldFormValues> = {
            location: undefined,
            job: undefined,
            managers: [],
            employmentCostCenters: [],
            department: undefined,
        };
        // We are forced to cast because we are prefilling the form, user still needs to fill the fields with valid values before submitting
        appendEmployment(emptyEmployment as EmploymentFieldFormValues);
    };
    const handleRemoveSecondaryEmployment = (index: number) => {
        removeEmployment(index);
    };

    return (
        <DialogWrapper header={title} open={open} onClose={onCloseDialog}>
            <FormProvider {...formMethods}>
                <Stack component={DialogContent} gap={3}>
                    <Stack gap={2}>
                        <FormControlLabel
                            label={t('employee.employment.start_date') + '*'}
                            control={<FieldLocalDate control={formMethods.control} name={'startDate'} />}
                        />
                        {!isNewEmployeeOrRehired() && <EmploymentCreateReasonField />}
                    </Stack>

                    {items.map((employment, index) => (
                        <Stack key={employment.id} direction='column' spacing={2}>
                            <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
                                <Typography variant='body1bold'>
                                    {t(index === 0 ? 'employee.employment.employment_principal' : 'employee.employment.employment_secondary')}
                                </Typography>

                                {index > 0 && (
                                    <IconButton size='small' onClick={() => handleRemoveSecondaryEmployment(index)} aria-label={'remove-secondary-employment'}>
                                        <RemoveCircleIcon />
                                    </IconButton>
                                )}
                            </Stack>
                            <LocationAutocomplete name={`${nestedEmploymentsFieldName}.${index}.location`} />
                            <DepartmentAutocomplete
                                name={`${nestedEmploymentsFieldName}.${index}.department`}
                                onDepartmentChange={handleDepartmentChange(index)}
                            />
                            <JobsAutocomplete name={`${nestedEmploymentsFieldName}.${index}.job`} />
                            <ManagerAutocomplete name={`${nestedEmploymentsFieldName}.${index}.managers`} excludeEmployeeIds={[employeeId]} />
                            <CostCenterAssignmentListField fieldName={`${nestedEmploymentsFieldName}.${index}.employmentCostCenters`} />
                        </Stack>
                    ))}
                    <Typography variant='body1bold'>{t('employee.employment.employment_secondary')}</Typography>
                    <Button
                        variant='text'
                        sx={{ alignSelf: 'flex-start' }}
                        startIcon={<Add01Icon width={20} height={20} />}
                        onClick={handleAddSecondaryEmployment}
                    >
                        {t('employee.employment.employment_add')}
                    </Button>
                </Stack>
            </FormProvider>
            <DialogActions>
                <Button onClick={formMethods.handleSubmit(onSave, console.error)} variant='contained'>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
const EmploymentCreateReasonField: FC = () => {
    const { t } = useTranslation();
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const formValueName = 'employmentUpdateReason';

    const getNewEmploymentCreateReason = (): string[] => {
        const newEmploymentCreateReasonNotWanted: EmploymentCreateReason[] = [EmploymentCreateReason.NEW_EMPLOYEE, EmploymentCreateReason.REHIRED];
        return Object.keys(EmploymentCreateReason).filter(key => !newEmploymentCreateReasonNotWanted.includes(key as EmploymentCreateReason));
    };

    return (
        <Stack direction='column'>
            <Typography variant='body1'>
                {t('employee.employment.employment_update_reason')}
                {'*'}
            </Typography>
            <Controller
                render={({ field: { ref, ...rest } }) => (
                    <Select {...rest} inputRef={ref} error={!!errors[formValueName]} autoFocus={false}>
                        {getNewEmploymentCreateReason().map(employmentCreateReason => {
                            return (
                                <MenuItem key={employmentCreateReason} value={employmentCreateReason ?? ''}>
                                    {t('employee.employment.employment_create_reason', { context: employmentCreateReason })}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
                name={formValueName}
                control={control}
            />
            {!!errors[formValueName] && <FormHelperText error={!!errors[formValueName]}>{errors[formValueName].message?.toString()}</FormHelperText>}
        </Stack>
    );
};

const mapToDefaultItem = (employment: Employment): Partial<EmploymentFieldFormValues> => {
    return {
        id: employment.id,
        location: employment.location,
        job: employment.job,
        managers: employment.managers,
        employmentCostCenters: employment.employmentCostCenters,
        department: mapDepartmentToTreeNode(employment.department),
    };
};
