import { DialogContainer, DialogContainerProps } from '@/Components/dialog-container/DialogContainer';
import { Document } from '@/domain/document/Document.model';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type DocumentLinkDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    document: Document;
    onSave: (values: { name: string }) => void;
};

const schema = yup.object().shape({
    name: yup.string().trim().required(),
});

export const DocumentNameDialog: FC<DocumentLinkDialogProps> = ({ onSave, document, ...rest }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{ name: string }>({
        defaultValues: {
            name: document?.name,
        },
        resolver: yupResolver(schema),
    });

    const handleSave = (values: { name: string }) => {
        onSave({ ...values });
    };

    return (
        <DialogContainer title={t('folders.edit_document_dialog.title')} onSave={handleSubmit(handleSave, console.error)} {...rest}>
            <Stack gap={1}>
                <FormControlLabel
                    label={t('folders.document_form.name')}
                    labelPlacement='top'
                    control={<TextField fullWidth error={!!errors.name} helperText={errors.name?.message} {...register('name')} />}
                />
            </Stack>
        </DialogContainer>
    );
};
