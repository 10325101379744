import { LogoRoger } from '@/Components/logo-roger/LogoRoger';
import { getCurrentEmployee } from '@/domain/employee/Employee.service';
import { employeeAuthenticated } from '@/stores/reducers/currentEmployeeSlice';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { setUserLanguage } from '@/utils/language.util';
import { Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Auth } from 'aws-amplify';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleError } from '@/utils/api.util';
import { ErrorMessage } from '@/auth/Components/ErrorMessage';
import { PasswordForm } from '@/auth/Components/PasswordForm';

export const ResetPassword: FC = () => {
    const realm = useAppSelector(state => state.ui.currentRealm);
    const dispatch = useAppDispatch();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const username = searchParams.get('user_name');
    const code = searchParams.get('confirmation_code');
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const signIn = (username: string, newPassword: string) => {
        Auth.signIn(username, newPassword)
            .then(() => {
                // at this time the user is logged in! \o/
                getCurrentEmployee()
                    .then(data => {
                        if (data?.employee?.language) {
                            setUserLanguage(data?.employee?.language).catch(handleError);
                        }
                        dispatch(employeeAuthenticated(data));
                        navigate('/profile/personal-info');
                    })
                    .catch(error => {
                        console.error(error);
                        setErrorMessage(error.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch(error => {
                setErrorMessage(error.message);
                console.error(error);
            });
    };

    const onResetPasswordClicked = (username: string, code: string, newPassword: string) => {
        setLoading(true);
        Auth.forgotPasswordSubmit(username, code, newPassword)
            .then(() => {
                signIn(username, newPassword);
            })
            .catch(error => {
                setErrorMessage(error.message);
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Stack
            direction='column'
            gap={3}
            component={Paper}
            width={isMobile ? '80vw' : 440}
            p={isMobile ? 3 : 5}
            left='50%'
            top='50%'
            position='absolute'
            sx={{
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Stack direction='row' alignContent='center'>
                <LogoRoger variant='light' />
            </Stack>
            {realm && username && code && (
                <>
                    <PasswordForm
                        title={t('reset_password_form.enter_your_new_password')}
                        buttonLabel={t('reset_password_form.confirm_new_password')}
                        loading={loading}
                        onSubmitPassword={password => onResetPasswordClicked(username, code, password)}
                    />
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </>
            )}

            {!realm ? <ErrorMessage message={t('login_form.realm_not_found')} /> : <></>}

            {!username || !code ? <ErrorMessage message={t('reset_password_form.invalid_link')} /> : <></>}
        </Stack>
    );
};
