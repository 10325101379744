import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '../common';
import {
    PlanningPosition,
    PlanningPositionCreationMutation,
    PlanningPositionSearch,
    PlanningPositionUpdateMutation,
} from '@/domain/planning-position/PlanningPosition.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import { OrderMutation } from '@/domain/common';

type PlanningPositionDTO = PlanningPosition;
type PlanningPositionSearchRequest = PlanningPositionSearch;
type PlanningPositionCreationRequest = PlanningPositionCreationMutation;
type PlanningPositionUpdateRequest = PlanningPositionUpdateMutation;
type OrderRequest = OrderMutation;

const BASE_URL = API_BASE_URL + '/planning-positions';

const searchPlanningPositions = async (request: PlanningPositionSearch = {}): Promise<PlanningPosition[]> => {
    return (await client.post<PlanningPositionDTO[], AxiosResponse<PlanningPositionDTO[]>, PlanningPositionSearchRequest>(BASE_URL + '/search', request)).data;
};

const createPlanningPosition = async (request: PlanningPositionCreationMutation): Promise<PlanningPosition> => {
    return (await client.post<PlanningPositionDTO, AxiosResponse<PlanningPositionDTO>, PlanningPositionCreationRequest>(BASE_URL, request)).data;
};

const updatePlanningPosition = async (id: number, request: PlanningPositionUpdateMutation): Promise<PlanningPosition> => {
    return (await client.put<PlanningPositionDTO, AxiosResponse<PlanningPositionDTO>, PlanningPositionUpdateRequest>(BASE_URL + `/${id}`, request)).data;
};

const updatePlanningPositionOrders = async (request: OrderMutation[]): Promise<PlanningPosition> => {
    return (await client.put<PlanningPositionDTO, AxiosResponse<PlanningPositionDTO>, OrderRequest[]>(BASE_URL + `/order`, request)).data;
};

const deletePlanningPosition = async (id: number): Promise<void> => {
    return client.delete(BASE_URL + `/${id}`);
};

const importPlanningPositions = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/planning-positions/import`, request)).data;
};

export const planningPositionApi = {
    searchPlanningPositions,
    createPlanningPosition,
    updatePlanningPosition,
    updatePlanningPositionOrders,
    deletePlanningPosition,
    importPlanningPositions,
};
