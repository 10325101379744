import { ImportRequest, ImportResult, ImportType } from '@/domain/import/Import.model';
import { employeeAPI } from '@/api/employee/Employee.api';
import { leaveRequestApi } from '@/api/leave-request/LeaveRequest.api';
import { leaveCorrectionApi } from '@/api/leave-correction/LeaveCorrection.api';
import { timesheetAPI } from '@/api/timesheet/Timesheet.api';
import { timesheetAdjustmentAPI } from '@/api/timesheet-adjustment/TimesheetAdjustment.api';
import { employeeBasicInfoAPI } from '@/api/employee/EmployeeBasicInfo.api';
import { employeeAddressAPI } from '@/api/employee/EmployeeAddress.api';
import { jobAPI } from '@/api/job/Job.api';
import { jobFamilyAPI } from '@/api/job-family/JobFamily.api';
import { costCenterAPI } from '@/api/cost-center/CostCenter.api';
import { departmentAPI } from '@/api/department/Department.api';
import { planningPositionApi } from '@/api/planning-position/PlanningPosition.api';
import { objectiveApi } from '@/api/objective/Objective.api';
import { roleApi } from '@/api/role/Role.api';
import { employeeSectionApi } from '@/api/employee-section/EmployeeSection.api';
import { EmployeeSectionImportRequest } from '@/domain/employee-section/EmployeeSection.model';

export const getImportRequestWithImportType = (importType: ImportType, importRequest: ImportRequest): Promise<ImportResult> => {
    switch (importType) {
        case ImportType.EMPLOYEE:
            return employeeAPI.importEmployees(importRequest);
        case ImportType.LEAVE:
            return leaveRequestApi.importLeaveRequests(importRequest);
        case ImportType.LEAVE_CORRECTION:
            return leaveCorrectionApi.importLeavesCorrections(importRequest);
        case ImportType.TIMESHEET:
            return timesheetAPI.importTimesheets(importRequest);
        case ImportType.TIMESHEET_ADJUSTMENT:
            return timesheetAdjustmentAPI.importTimesheetsAdjustments(importRequest);
        case ImportType.BASIC_INFORMATION:
            return employeeBasicInfoAPI.importBasicInformation(importRequest);
        case ImportType.ADDRESS:
            return employeeAddressAPI.importAddresses(importRequest);
        case ImportType.JOB:
            return jobAPI.importJobs(importRequest);
        case ImportType.JOB_FAMILY:
            return jobFamilyAPI.importJobFamilies(importRequest);
        case ImportType.COST_CENTER:
            return costCenterAPI.importCostCenters(importRequest);
        case ImportType.DEPARTMENT:
            return departmentAPI.importDepartments(importRequest);
        case ImportType.PLANNING_POSITION:
            return planningPositionApi.importPlanningPositions(importRequest);
        case ImportType.OBJECTIVE:
            return objectiveApi.importObjectives(importRequest);
        case ImportType.ROLE:
            return roleApi.importRoles(importRequest);
        case ImportType.EMPLOYEE_ROLE:
            return employeeAPI.importEmployeeRoles(importRequest);
    }

    throw new Error('Unknown import type');
};

export const importEmployeeSection = (employeeSectionImportRequest: EmployeeSectionImportRequest, sectionDefinitionId: number): Promise<ImportResult> => {
    return employeeSectionApi.importEmployeeSection(employeeSectionImportRequest, sectionDefinitionId);
};
