import { DialogWrapper, DialogWrapperProps } from '@/Components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/Components/form/field-date/FieldDate';
import { Department } from '@/domain/department/Department.model';
import { ContractType } from '@/domain/employment/Employment.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { CostCenterAssignmentListField } from '@/page/cost-center/CostCenterAssignmentListField';
import { getEmploymentItemSchema, getEmploymentSchema } from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';
import { useAppSelector } from '@/stores/store';
import { getCurrentLocalDate } from '@/utils/datetime.util';
import { defaultToNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DepartmentAutocomplete, JobsAutocomplete, LocationAutocomplete, ManagerAutocomplete } from './EmploymentDialogCommonFields';

type NewContractDialogProps = {
    employeeId: number;
    onSave: (contractFormValues: ContractFormValues) => void;
} & DialogWrapperProps;

export const NewContractDialog: FC<NewContractDialogProps> = ({ open, employeeId, onClose, onSave }) => {
    const realm = useAppSelector(state => state.ui.currentRealm);
    const { t } = useTranslation();
    const title = t('employee.employment.new_contract');

    const schema = getSchema();
    const formMethods = useForm<ContractFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            startDate: getCurrentLocalDate(),
            managers: [],
            employmentCostCenters: [],
        },
    });

    const { handleSubmit } = formMethods;

    // erase cost centers and manager fields when department changes
    const handleDepartmentChange = (department: Department | null) => {
        const { setValue } = formMethods;
        setValue('managers', department?.managers ?? []);

        if (hasRealmFeatureEnabled(realm?.realmFeatures, RealmFeaturesType.COST_CENTERS)) {
            setValue('employmentCostCenters', department?.departmentCostCenters ?? []);
        }
    };

    return (
        <DialogWrapper open={open} header={title} onClose={onClose}>
            <FormProvider {...formMethods}>
                <Stack component={DialogContent} gap={2}>
                    <FormControlLabel label={t('employee.employment.start_date')} control={<FieldLocalDate name='startDate' control={formMethods.control} />} />

                    {/* theses 2 fields are specifics of EmploymentDialog */}
                    <ContractTypeAutocomplete />
                    <EmploymentEndDateField />

                    <LocationAutocomplete name='location' />
                    <DepartmentAutocomplete name='department' onDepartmentChange={handleDepartmentChange} />
                    <JobsAutocomplete name='job' />
                    <ManagerAutocomplete name='managers' excludeEmployeeIds={[employeeId]} />
                    <CostCenterAssignmentListField fieldName={'employmentCostCenters'} />
                </Stack>
                <DialogActions>
                    <Button onClick={handleSubmit(onSave, console.error)} variant='contained'>
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </FormProvider>
        </DialogWrapper>
    );
};

const EmploymentEndDateField: FC = () => {
    const { control } = useFormContext();
    const { watch } = useFormContext();
    const contractType = watch('contractType');
    const { t } = useTranslation();

    if (!contractType || contractType === ContractType.PERMANENT) {
        return <></>;
    }
    return (
        <Stack direction='column'>
            <Typography variant='body1'>{t('employee.employment.end_date')}</Typography>

            <FieldLocalDate control={control} name={'endDate'} />
        </Stack>
    );
};

const ContractTypeAutocomplete: FC = () => {
    const { control } = useFormContext();
    const { t } = useTranslation();
    return (
        <Stack>
            <Typography variant='body1'>
                {t('employee.employment.contract_type_title')}
                {'*'}
            </Typography>
            <Controller
                name='contractType'
                control={control}
                render={({ field: { onChange, ...field }, fieldState }) => (
                    <Autocomplete
                        onChange={(_, data) => onChange(data)}
                        getOptionLabel={contractType => t('employee.employment.contract_type', { context: contractType })}
                        options={Object.values(ContractType) ?? []}
                        value={defaultToNull(field.value)}
                        fullWidth
                        renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                    />
                )}
            />
        </Stack>
    );
};

const getSchema = () => getEmploymentSchema().omit(['employmentUpdateReason', 'probationEndDate', 'items']).concat(getEmploymentItemSchema());

export type ContractFormValues = yup.InferType<ReturnType<typeof getSchema>>;
