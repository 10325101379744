import { FC } from 'react';
import { CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
    sectionTitle?: string;
};

export const SectionLoading: FC<Props> = ({ sectionTitle }) => {
    const { t } = useTranslation();

    return (
        <Paper elevation={0} sx={{ p: 2 }}>
            {sectionTitle && (
                <Stack direction='row' justifyContent='space-between' alignItems='flex-start' spacing={1} sx={{ width: '100%' }}>
                    <Typography variant='h3bold'>{t(sectionTitle)}</Typography>
                </Stack>
            )}
            <Stack alignItems='center'>
                <CircularProgress />
            </Stack>
        </Paper>
    );
};
