import { LeavesBalancePage } from '@/page/leave/LeavesBalancePage';
import { ManageLeavesHeader } from '@/page/leave/manage-leaves-header/ManageLeavesHeader';
import { PageContainer } from '@/routes/PageContainer';
import { RoutesConfig } from '@/routes/Routes.type';
import { Stack } from '@mui/material';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { PendingLeaveRequestsPage } from './PendingLeaveRequestsPage';
import { AllLeaveRequestsPage } from './AllLeaveRequestsPage';
import { lazy, Suspense } from 'react';
import { RouterBoundary } from '@/routes/RouterBoundary';

// eslint-disable-next-line react-refresh/only-export-components
const LeavePlanningPage = lazy(() => import('./LeavePlanningPage'));

const config: RoutesConfig[] = [
    {
        path: 'calendar',
        breadCrumb: [{ nameKey: 'pages.leaves_calendar' }],
        component: (
            <Suspense>
                <LeavePlanningPage />
            </Suspense>
        ),
    },
    {
        path: 'pending',
        breadCrumb: [{ nameKey: 'pages.pending_leaves' }],
        component: <PendingLeaveRequestsPage />,
    },
    {
        path: 'all',
        breadCrumb: [{ nameKey: 'pages.all_leaves' }],
        component: <AllLeaveRequestsPage />,
    },
    {
        path: 'balances',
        breadCrumb: [{ nameKey: 'pages.leaves_balance' }],
        component: <LeavesBalancePage />,
    },
];

export const leavesRoutes: RouteObject[] = [
    {
        path: 'manage-leaves',
        element: (
            <Stack flex='1' gap={2}>
                <ManageLeavesHeader />
                <Outlet />
            </Stack>
        ),
        errorElement: <RouterBoundary />,
        children: [
            {
                path: '',
                element: <Navigate to='/manage-leaves/calendar' replace />,
            },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}> {config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
