import { Employee } from '@/domain/employee/Employee.model';
import { ReviewRatingScale } from '@/domain/review-rating-scale/ReviewRatingScale.model';
import { ContributorType, ReviewType } from '@/domain/review/Review.model';
import { Skill } from '@/domain/skill/Skill.model';
import { Label } from '@/domain/label/Label.model';

export const ReviewTemplateItemArray = [
    'OBJECTIVES',
    'SKILLS',
    'SKILL',
    'SECTION',
    'QUESTION',
    'PRIVATE_EMPLOYEE_QUESTION',
    'PRIVATE_MANAGER_QUESTION',
] as const;

export type ReviewTemplateItemType = (typeof ReviewTemplateItemArray)[number];

export type ReviewTemplate = {
    id: number;
    name: Label | undefined;
    items: ReviewTemplateItem[];
    createdBy: Employee | undefined;
    reviewType: ReviewType;
    availableContributorTypes: ContributorType[];
    includePreparationStep: boolean;
    managerPreparationAccess: boolean;
    includeValidationStep: boolean;
    includePrivateEmployeeQuestions: boolean;
    includePrivateManagerQuestions: boolean;
    emailTemplates: ReviewEmailTemplate[];
};
export type ReviewTemplateItem = {
    id: number;
    type: ReviewTemplateItemType;
    order: number;
    title: Label;
    instruction: Label | undefined;
    rating: ReviewRatingScale;
    skill: Skill;
    required: boolean;
    availableContributorTypes: ContributorType[];
    allSkillsRequired: boolean;
    minSkills: number | undefined;
    maxSkills: number | undefined;
};

export type ReviewEmailTemplate = {
    id: number;
    subject: Label;
    content: Label;
    contributorType: ContributorType;
    createdBy: Employee | undefined;
    updatedBy: Employee | undefined;
    createdAt: Date | undefined;
    updatedAt: Date | undefined;
};

export type ReviewTemplateItemMutation = ReviewBlockFormItemMutation | ReviewTemplatePrivateItemMutation;

export type ReviewEmailMutation = {
    subject: Label;
    content: Label;
    contributorType: ContributorType;
};

export type ReviewBlockFormItemMutation =
    | ReviewTemplateQuestionItemMutation
    | ReviewTemplateSectionItemMutation
    | ReviewTemplateSkillsItemMutation
    | ReviewTemplateObjectivesItemMutation;

export type ReviewTemplateQuestionItemMutation = {
    type: 'QUESTION';
    order: number;
    title: Label;
    instruction: Label | undefined;
    ratingId?: number;
    required: boolean;
    availableContributorTypes: ContributorType[];
};

export type ReviewTemplateSectionItemMutation = {
    type: 'SECTION';
    order: number;
    title: Label;
    availableContributorTypes: ContributorType[];
};

export type ReviewTemplateSkillsItemMutation = {
    type: 'SKILLS';
    order: number;
    allSkillsRequired: boolean;
    availableContributorTypes: ContributorType[];
    minSkills: number | undefined;
    maxSkills: number | undefined;
    rangeSkillsRequired: boolean;
};

export type ReviewTemplateObjectivesItemMutation = {
    type: 'OBJECTIVES';
    availableContributorTypes: ContributorType[];
    order: number;
};

export type ReviewTemplatePrivateItemMutation = ReviewTemplatePrivateEmployeeQuestionItemMutation | ReviewTemplatePrivateManagerQuestionItemMutation;

export type ReviewTemplatePrivateEmployeeQuestionItemMutation = {
    type: 'PRIVATE_EMPLOYEE_QUESTION';
    order: number;
    title: Label;
    instruction: Label | undefined;
    required: boolean;
    ratingId?: number;
};

export type ReviewTemplatePrivateManagerQuestionItemMutation = {
    type: 'PRIVATE_MANAGER_QUESTION';
    order: number;
    title: Label;
    instruction: Label | undefined;
    required: boolean;
    ratingId?: number;
};

export type ReviewTemplateMutation = {
    name: Label;
    items: ReviewTemplateItemMutation[];
    reviewType: ReviewType;
    availableContributorTypes: ContributorType[];
    includePreparationStep: boolean;
    managerPreparationAccess: boolean;
    includeValidationStep: boolean;
    includePrivateEmployeeQuestions: boolean;
    includePrivateManagerQuestions: boolean;
    emailTemplates: ReviewEmailMutation[];
};

export type SearchReviewTemplate = {
    name?: string;
    reviewType?: ReviewType;
};
