import { DialogContainer, DialogContainerProps } from '@/Components/dialog-container/DialogContainer';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { getAppConfig } from '@/config/config';
import { LeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { LeaveActivityType, LeaveType } from '@/domain/leave-type/LeaveType.model';
import { MonthlyTimesheetReport, TimesheetCycle } from '@/domain/timesheet/Timesheet.model';
import { useGetLeaveTypeHistories } from '@/hooks/leave-type-history/LeaveTypeHistory.hook';
import { formatInDefaultDate, formatMinutesToHours, formatToLocalDate, MONTHS } from '@/utils/datetime.util';
import { Button, Paper, Stack, Typography, TypographyProps } from '@mui/material';
import { format, subDays } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type OvertimeCardField = {
    title: string;
    value?: number;
    variant: TypographyProps['variant'];
};

type OvertimeFieldProps = {
    timesheetsMonthly: MonthlyTimesheetReport;
    currentTimesheetCycle: TimesheetCycle;
    selectedTimesheetCycle: TimesheetCycle;
    employeeId: number;
};

const timesheetsMonthlyContainsValues = (timesheetsMonthly: MonthlyTimesheetReport): boolean => {
    return (
        timesheetsMonthly.previousCarryover !== 0 ||
        timesheetsMonthly.totalAdjustmentCount !== 0 ||
        timesheetsMonthly.totalForecastedAdjustmentCount !== 0 ||
        timesheetsMonthly.totalBonusCount !== 0 ||
        timesheetsMonthly.totalForecastedBonusCount !== 0
    );
};

const leaveTypeHistoriesContainsValues = (leaveTypeHistories: LeaveTypeHistory[]): boolean => {
    return leaveTypeHistories.some(history => history.usedAmountInMinutes !== 0);
};

const detailsDialogContainsValues = (
    timesheetsMonthly: MonthlyTimesheetReport,
    leaveTypeHistoriesToday: LeaveTypeHistory[],
    leaveTypeHistoriesForecasted: LeaveTypeHistory[],
): boolean => {
    return (
        timesheetsMonthlyContainsValues(timesheetsMonthly) ||
        timesheetsMonthly.totalPaymentCount !== 0 ||
        timesheetsMonthly.totalForecastedPaymentCount !== 0 ||
        leaveTypeHistoriesContainsValues(leaveTypeHistoriesToday) ||
        leaveTypeHistoriesContainsValues(leaveTypeHistoriesForecasted)
    );
};

export const TotalOvertimeCard: FC<OvertimeFieldProps> = ({ timesheetsMonthly, selectedTimesheetCycle, currentTimesheetCycle, employeeId }) => {
    const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
    const { t } = useTranslation();

    const balanceEndDate = formatToLocalDate(new Date());
    const filtersToday = {
        leaveActivityTypes: [LeaveActivityType.TIMESHEET_COMPENSATION],
        endDate: balanceEndDate,
    };
    const filtersForecasted = {
        leaveActivityTypes: [LeaveActivityType.TIMESHEET_COMPENSATION],
        //no need to send the endDate for forecasted leave type histories because the BE will calculate until the end of the year
    };

    const {
        data: leaveTypeHistoriesToday,
        isLoading: isLoadingToday,
        isError: isErrorToday,
        error: leaveTypesError,
    } = useGetLeaveTypeHistories(employeeId, filtersToday);
    const {
        data: leaveTypeHistoriesForecasted = [],
        isLoading: isLoadingForecasted,
        isError: isErrorForecasted,
        error: forecastedLeaveTypesError,
    } = useGetLeaveTypeHistories(employeeId, filtersForecasted);

    const getOvertimeCardFields = (timesheetsMonthly: MonthlyTimesheetReport, selectedTimesheetCycle: TimesheetCycle): OvertimeCardField[] => {
        return [
            {
                title: t('timesheets.overtime_balance_card.difference_field'),
                value: timesheetsMonthly?.balance,
                variant: 'body1bold',
            },
            {
                title: t('timesheets.overtime_balance_card.forecasted_field', { forecastedDate: formatInDefaultDate(selectedTimesheetCycle.cycleEndDate) }),
                value: timesheetsMonthly?.forecastedBalance,
                variant: 'body1',
            },
        ];
    };

    const handleClose = () => {
        setShowDetailsDialog(false);
    };

    const isSelectedCycleTheCurrent = selectedTimesheetCycle.year === currentTimesheetCycle.year;
    const shouldDisplayDetailsButton =
        isSelectedCycleTheCurrent && detailsDialogContainsValues(timesheetsMonthly, leaveTypeHistoriesToday ?? [], leaveTypeHistoriesForecasted ?? []);

    return (
        <Paper elevation={0}>
            <StateHandler
                isError={isErrorToday || isErrorForecasted}
                isLoading={isLoadingToday || isLoadingForecasted}
                error={leaveTypesError || forecastedLeaveTypesError}
            >
                <Stack padding={2} spacing={1} minWidth={'280px'}>
                    <Typography variant='h2'>{t('timesheets.overtime_balance_card.title')}</Typography>
                    {getOvertimeCardFields(timesheetsMonthly, selectedTimesheetCycle).map(overtimeCardField => {
                        return OvertimeField(overtimeCardField);
                    })}
                </Stack>

                {shouldDisplayDetailsButton && (
                    <Stack direction={'row'} justifyContent='center' pb={1}>
                        <Button
                            variant='text'
                            color='primary'
                            onClick={() => {
                                setShowDetailsDialog(true);
                            }}
                        >
                            {t('timesheets.overtime_balance_card.view_details')}
                        </Button>
                    </Stack>
                )}

                {showDetailsDialog && (
                    <OvertimeCardDetailsDialog
                        selectedTimesheetCycle={selectedTimesheetCycle}
                        timesheetsMonthly={timesheetsMonthly}
                        open={showDetailsDialog}
                        onClose={handleClose}
                        leaveTypeHistoriesToday={leaveTypeHistoriesToday ?? []}
                        leaveTypeHistoriesForecasted={leaveTypeHistoriesForecasted ?? []}
                    />
                )}
            </StateHandler>
        </Paper>
    );
};

const OvertimeField = (overtimeCardField: OvertimeCardField) => {
    return (
        <Stack key={overtimeCardField?.title} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={overtimeCardField.variant}>{overtimeCardField?.title}</Typography>
            <Typography variant={overtimeCardField.variant}>
                {overtimeCardField?.value !== undefined ? formatMinutesToHours(overtimeCardField?.value) : '-'}
            </Typography>
        </Stack>
    );
};

type OvertimeCardDetailsDialogProps = {
    timesheetsMonthly: MonthlyTimesheetReport;
    selectedTimesheetCycle: TimesheetCycle;
    leaveTypeHistoriesToday: LeaveTypeHistory[];
    leaveTypeHistoriesForecasted: LeaveTypeHistory[];
} & DialogContainerProps;

type AggregatedLeaveTypeHistory = {
    today: LeaveTypeHistory | undefined;
    forecasted: LeaveTypeHistory | undefined;
};

const getLeavesTypeHistories = (
    leaveTypeHistoriesToday: LeaveTypeHistory[],
    leaveTypeHistoriesForecasted: LeaveTypeHistory[],
): Map<LeaveType, AggregatedLeaveTypeHistory>[] => {
    const leaveTypesToday = leaveTypeHistoriesToday.map(history => history.leaveType);
    const leaveTypesForecasted = leaveTypeHistoriesForecasted.map(history => history.leaveType);

    const leaveTypes: LeaveType[] = [...leaveTypesToday, ...leaveTypesForecasted].reduce<LeaveType[]>((acc: LeaveType[], item: LeaveType) => {
        if (!acc.some(existingItem => existingItem.id === item.id)) {
            acc.push(item);
        }
        return acc;
    }, []);

    return leaveTypes.map(leaveType => {
        const leavesTypeHistoriesMap = new Map<LeaveType, AggregatedLeaveTypeHistory>();
        const today = leaveTypeHistoriesToday.find(history => history.leaveType.id === leaveType.id);
        const forecasted = leaveTypeHistoriesForecasted.find(history => history.leaveType.id === leaveType.id);
        leavesTypeHistoriesMap.set(leaveType, { today, forecasted });
        return leavesTypeHistoriesMap;
    });
};
const config = getAppConfig();

const OvertimeCardDetailsDialog: FC<OvertimeCardDetailsDialogProps> = ({
    timesheetsMonthly,
    selectedTimesheetCycle,
    leaveTypeHistoriesToday,
    leaveTypeHistoriesForecasted,
    ...rest
}) => {
    const { t } = useTranslation();

    const leavesTypeHistories = getLeavesTypeHistories(leaveTypeHistoriesToday, leaveTypeHistoriesForecasted);
    const previousCarryoverDate = format(
        subDays(selectedTimesheetCycle.cycleStartDate, 1),
        selectedTimesheetCycle.startMonth === MONTHS.JANUARY ? 'yyyy' : 'MMM yyyy',
    );

    const forecastedDate = format(
        selectedTimesheetCycle.cycleEndDate,
        selectedTimesheetCycle.startMonth === MONTHS.JANUARY ? 'dd.MM' : config.DEFAULT_DATE_FORMAT,
    );

    const shouldDisplayCompensations =
        leaveTypeHistoriesContainsValues(leaveTypeHistoriesToday) ||
        leaveTypeHistoriesContainsValues(leaveTypeHistoriesForecasted) ||
        timesheetsMonthly.totalPaymentCount !== 0 ||
        timesheetsMonthly.totalForecastedPaymentCount !== 0;

    return (
        <DialogContainer
            title={t('timesheets.overtime_balance_details_card.title')}
            isActionButtonDisabled={true}
            primaryActionDisabled={true}
            showPrimaryActionButton={false}
            {...rest}
        >
            <Stack display={'grid'} gridTemplateColumns={'auto 100px 120px'}>
                {/*empty cell*/}
                <Stack />
                <Typography align={'center'} variant='body1bold'>
                    {t('timesheets.overtime_balance_details_card.today')}
                </Typography>
                <Typography align={'center'} variant='body1bold'>
                    {t('timesheets.overtime_balance_details_card.forecasted', { date: forecastedDate })}
                </Typography>

                <>
                    <Typography variant='body1bold'>{t('timesheets.overtime_balance_details_card.accrued')}</Typography>
                    {/*empty cell*/}
                    <Stack />
                    {/*empty cell*/}
                    <Stack />
                </>

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.carryover', { year: previousCarryoverDate })}
                    todayValueInMinutes={timesheetsMonthly.previousCarryover}
                    forecastedValueInMinutes={timesheetsMonthly.previousCarryover}
                />

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.adjustments')}
                    todayValueInMinutes={timesheetsMonthly.totalAdjustmentCount}
                    forecastedValueInMinutes={timesheetsMonthly.totalForecastedAdjustmentCount}
                />

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.daily_difference')}
                    todayValueInMinutes={timesheetsMonthly.totalDifference}
                    forecastedValueInMinutes={timesheetsMonthly.totalForecastedDifference}
                    alwaysDisplay={true}
                />

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.bonus_time')}
                    todayValueInMinutes={timesheetsMonthly.totalBonusCount}
                    forecastedValueInMinutes={timesheetsMonthly.totalForecastedBonusCount}
                />

                {shouldDisplayCompensations && (
                    <>
                        <Typography pt={1} variant='body1bold'>
                            {t('timesheets.overtime_balance_details_card.compensations')}
                        </Typography>
                        {/*empty cell*/}
                        <Stack />
                        {/*empty cell*/}
                        <Stack />
                    </>
                )}

                {leavesTypeHistories.map(leaveTypeHistoryMap => {
                    const leaveType = leaveTypeHistoryMap.keys().next().value;
                    if (leaveType === undefined) {
                        return;
                    }
                    const leaveTypeHistory = leaveTypeHistoryMap.get(leaveType);
                    return (
                        <OvertimeCardDetailField
                            key={leaveType.id}
                            title={leaveType.title}
                            todayValueInMinutes={-(leaveTypeHistory?.today?.usedAmountInMinutes ?? 0)}
                            forecastedValueInMinutes={-(leaveTypeHistory?.forecasted?.usedAmountInMinutes ?? 0)}
                        />
                    );
                })}

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.payments')}
                    todayValueInMinutes={-timesheetsMonthly.totalPaymentCount}
                    forecastedValueInMinutes={-timesheetsMonthly.totalForecastedPaymentCount}
                />

                <OvertimeCardDetailField
                    title={t('timesheets.overtime_balance_details_card.time_balance')}
                    todayValueInMinutes={timesheetsMonthly.balance}
                    forecastedValueInMinutes={timesheetsMonthly.forecastedBalance}
                    variant={'body1bold'}
                    bgcolor={'grey.100'}
                    pt={1}
                    pb={1}
                    mt={1}
                    alwaysDisplay={true}
                />
            </Stack>
        </DialogContainer>
    );
};

type OvertimeCardDetailFieldProps = {
    title: string;
    todayValueInMinutes: number;
    forecastedValueInMinutes: number;
    alwaysDisplay?: boolean;
} & TypographyProps;

const OvertimeCardDetailField: FC<OvertimeCardDetailFieldProps> = ({
    title,
    todayValueInMinutes = 0,
    forecastedValueInMinutes = 0,
    alwaysDisplay = false,
    ...rest
}) => {
    if (!alwaysDisplay && todayValueInMinutes === 0 && forecastedValueInMinutes === 0) {
        return undefined;
    }

    return (
        <>
            <Typography py={0.5} pl={1} variant='body1' borderBottom={'solid 1px'} borderColor={'grey.300'} {...rest}>
                {title}
            </Typography>
            <Typography py={0.5} align={'center'} variant='body1' borderBottom={'solid 1px'} borderColor={'grey.300'} {...rest}>
                {todayValueInMinutes ? formatMinutesToHours(todayValueInMinutes) : '0h'}
            </Typography>
            <Typography py={0.5} align={'center'} variant='body1' borderBottom={'solid 1px'} borderColor={'grey.300'} {...rest}>
                {forecastedValueInMinutes ? formatMinutesToHours(forecastedValueInMinutes) : '0h'}
            </Typography>
        </>
    );
};
