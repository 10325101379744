import { EmployeeAvatar } from '@/Components/employee-avatar/EmployeeAvatar';
import { DialogContainerProps } from '@/Components/dialog-container/DialogContainer';
import { handleError } from '@/utils/api.util';
import { createObjectiveStatus, deleteObjectiveStatus, getParentsObjective, updateObjectiveStatus } from '@/domain/objective/Objective.service';
import { BadProgressIcon, GoodProgressIcon, ModerateProgressIcon, MoreVerticalIcon } from '@/assets/Icons/Icons';
import { canUpdateObjectiveStatus } from '@/domain/permission/Permission.service';
import { useAppSelector } from '@/stores/store';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContent, Divider, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Objective, ObjectiveStatus, ObjectiveStatusCreateMutation, ObjectiveStatusUpdate } from '@/domain/objective/Objective.model';
import { ArrowMoveDownRightIcon, SentIcon, Tick02Icon } from 'hugeicons-react';
import { BasicMenu, BasicMenuItem } from '@/Components/basic-menu/BasicMenu';
import { DialogWrapper } from '@/Components/dialog-wrapper/DialogWrapper';
import { addHours, format, isBefore } from 'date-fns';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { useGetObjective } from '@/hooks/objective/Objective.hook';
import { getAppConfig } from '@/config/config';
import { EmployeeAvatarWithDetails } from '@/Components/employee-avatar/EmployeeAvatarWithDetails';
import { getNull } from '@/utils/object.util';
import i18next from 'i18next';

const { DEFAULT_DATE_FORMAT } = getAppConfig();

const schema = yup.object().shape({
    status: yup.string().oneOf(Object.values(ObjectiveStatus), i18next.t('general.validation.required')).nullable(),
    comment: yup.string().required(),
});

type FormValues = yup.InferType<typeof schema>;

type ObjectiveHistoryDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    objectiveId: number;
    displayWeight: boolean;
};

export const ObjectiveHistoryDialog: FC<ObjectiveHistoryDialogProps> = ({ open, onClose, objectiveId, displayWeight }) => {
    const { t } = useTranslation();
    const {
        data: objective,
        isError: isObjectiveError,
        error: objectiveError,
        isLoading: isObjectiveLoading,
        refetch: refetchObjective,
    } = useGetObjective(objectiveId);

    return (
        <DialogWrapper header={t('objectives.history_dialog.title')} open={open} onClose={onClose} maxWidth='lg'>
            <DialogContent>
                <StateHandler isLoading={isObjectiveLoading} error={objectiveError} isError={isObjectiveError}>
                    {objective && (
                        <Stack pb={2} gap={2} direction={'row'} justifyContent={'space-between'} divider={<Divider orientation='vertical' flexItem />}>
                            <ObjectiveHistoryDialogForm displayWeight={displayWeight} objective={objective} onStatusUpdated={() => refetchObjective()} />
                            <ObjectiveHistoryDetails objective={objective} />
                        </Stack>
                    )}
                </StateHandler>
            </DialogContent>
        </DialogWrapper>
    );
};

type ObjectiveStatusProgressIconProps = {
    objectiveStatus: ObjectiveStatus;
};

export const ObjectiveStatusProgressIcon: FC<ObjectiveStatusProgressIconProps> = ({ objectiveStatus: objectiveStatus }) => {
    switch (objectiveStatus) {
        case 'DELAYED':
            return <BadProgressIcon />;
        case 'AT_RISK':
            return <ModerateProgressIcon />;
        case 'ON_TRACK':
            return <GoodProgressIcon />;
        default:
            return <></>;
    }
};

type ObjectiveHistoryDialogFormProps = {
    objective: Objective;
    displayWeight: boolean;
    onStatusUpdated: () => void;
};

const ObjectiveHistoryDialogForm: FC<ObjectiveHistoryDialogFormProps> = ({ objective, displayWeight, onStatusUpdated }) => {
    const { t } = useTranslation();
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);

    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const readOnly = (objective?.assignee?.id && !canUpdateObjectiveStatus(policies, objective.assignee.id)) || objective.completionStatus;

    const { handleSubmit, control, setValue } = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            // by default, the last status update is the current status
            status: objective.lastStatusUpdate?.status ?? getNull(),
            comment: '',
        },
    });

    const handleSave = async (values: FormValues) => {
        if (!objective.id) {
            return;
        }
        try {
            const objectiveStatusUpdate = { ...values, status: values.status ?? undefined };

            await createObjectiveStatus(objective.id, objectiveStatusUpdate);
            onStatusUpdated();
            setValue('comment', '');
        } catch (e) {
            handleError(e);
        }
    };

    const handleUpdateObjectiveStatusComment = async (objectiveStatusUpdate: ObjectiveStatusUpdate, comment: string) => {
        const mutation: ObjectiveStatusCreateMutation = {
            status: objectiveStatusUpdate.status,
            comment,
        };
        try {
            await updateObjectiveStatus(objective.id, objectiveStatusUpdate.id, mutation);
            onStatusUpdated();
        } catch (e) {
            handleError(e);
        }
    };

    const handleDeleteObjectiveStatus = async (objectiveId: number, objectiveStatusUpdate: ObjectiveStatusUpdate) => {
        try {
            await deleteObjectiveStatus(objectiveId, objectiveStatusUpdate.id);
            onStatusUpdated();
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <Stack direction='column' gap={2} pr={3} overflow='auto' width={'100%'}>
            <Stack gap={0.5}>
                <Stack direction='row' gap={1} alignItems='center'>
                    {objective.lastStatusUpdate?.status && <ObjectiveStatusProgressIcon objectiveStatus={objective.lastStatusUpdate.status} />}

                    <Typography variant='h2'>{objective.name + (objective.weight && displayWeight ? ' (' + objective.weight + 'x)' : '')}</Typography>
                </Stack>

                {objective.description && (
                    <Typography
                        variant='body1'
                        sx={{
                            // apply break lines from the description
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {objective.description}
                    </Typography>
                )}
            </Stack>

            <HistoryDialogParentObjectives objective={objective} />

            {!readOnly && (
                <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'space-between'}>
                    <Stack flex={1}>
                        <Stack direction='row' gap={1} alignItems='center'>
                            <Typography variant={'body1bold'}>{t('objectives.history_dialog.modify_status')}</Typography>

                            <Controller
                                name='status'
                                control={control}
                                render={({ field: { value, onChange, ...restField } }) => {
                                    return (
                                        <ObjectiveStatusSelect
                                            {...restField}
                                            value={value ?? getNull()}
                                            onChange={(value: ObjectiveStatus | null) => {
                                                onChange(value);
                                            }}
                                        />
                                    );
                                }}
                            />
                        </Stack>
                        <Stack direction='row' gap={1} alignItems='center'>
                            {currentEmployee && <EmployeeAvatar employeeAvatar={currentEmployee} size={'small'} />}
                            <Controller
                                name='comment'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        multiline
                                        {...field}
                                        error={!!error}
                                        helperText={error?.message}
                                        fullWidth
                                        placeholder={t('objectives.history_dialog.add_comment')}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>

                    <IconButton onClick={handleSubmit(handleSave, console.error)} size='medium' edge='end'>
                        <SentIcon fontSize='small' />
                    </IconButton>
                </Stack>
            )}
            {!!objective.statusUpdates?.length && (
                <Stack maxHeight='500px' gap={2}>
                    {[...objective.statusUpdates]
                        ?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
                        .map(statusUpdate => (
                            <ObjectiveStatusUpdateRow
                                key={statusUpdate.id}
                                statusUpdate={statusUpdate}
                                onEdit={comment => handleUpdateObjectiveStatusComment(statusUpdate, comment)}
                                onDelete={() => handleDeleteObjectiveStatus(objective.id, statusUpdate)}
                            />
                        ))}
                </Stack>
            )}
        </Stack>
    );
};
type ObjectiveStatusSelectProps = {
    value: ObjectiveStatus | null;
    onChange: (value: ObjectiveStatus | null) => void;
};

const ObjectiveStatusSelect: FC<ObjectiveStatusSelectProps> = ({ value, onChange }) => {
    const { t } = useTranslation();

    const handleOnChange = (newValue: ObjectiveStatus) => {
        onChange(newValue === value ? getNull() : newValue);
    };

    return (
        <Stack direction='row' alignItems='center'>
            <Tooltip title={t('objectives.status.off_track')}>
                <IconButton>
                    <BadProgressIcon opacity={value === 'DELAYED' ? 1 : 0.3} onClick={() => handleOnChange(ObjectiveStatus.DELAYED)} />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('objectives.status.at_risk')}>
                <IconButton>
                    <ModerateProgressIcon opacity={value === 'AT_RISK' ? 1 : 0.3} onClick={() => handleOnChange(ObjectiveStatus.AT_RISK)} />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('objectives.status.on_track')}>
                <IconButton>
                    <GoodProgressIcon opacity={value === 'ON_TRACK' ? 1 : 0.3} onClick={() => handleOnChange(ObjectiveStatus.ON_TRACK)} />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

type HistoryDialogParentObjectivesProps = {
    objective: Objective;
};

const HistoryDialogParentObjectives: FC<HistoryDialogParentObjectivesProps> = ({ objective }) => {
    const { t } = useTranslation();

    if (!objective.parent) {
        return <></>;
    }

    const parentObjectives = getParentsObjective(objective);

    return (
        <Stack gap={0.5}>
            <Typography variant='body1bold'>{t('objectives.history_dialog.parent_objectives')}</Typography>
            <Stack gap={0.25}>
                {parentObjectives.map((objective, index) => {
                    return (
                        <Stack key={objective.id} direction='row' alignItems='flex-end' gap={0.5} pl={index * 2}>
                            {index !== 0 && <ArrowMoveDownRightIcon />}

                            <Typography variant='body1'>{objective.name}</Typography>
                        </Stack>
                    );
                })}
            </Stack>
            <Stack direction='row' alignItems='flex-end' gap={0.5} pl={getParentsObjective(objective).length * 2}>
                <ArrowMoveDownRightIcon />
                <Typography variant='body1bold'>{objective.name}</Typography>
            </Stack>
        </Stack>
    );
};

type ObjectiveStatusUpdateRowProps = {
    statusUpdate: ObjectiveStatusUpdate;
    onEdit: (comment: string) => void;
    onDelete: () => void;
};
const ObjectiveStatusUpdateRow: FC<ObjectiveStatusUpdateRowProps> = props => {
    const { statusUpdate, onEdit, onDelete } = props;
    const [editMode, setEditMode] = useState(false);
    const [comment, setComment] = useState(statusUpdate.comment);
    const { t } = useTranslation();

    const canManageComment = (statusUpdate: ObjectiveStatusUpdate) => {
        // user can edit the comment within 1 hour
        const MAX_EDIT_TIME = 1;
        return isBefore(new Date(), addHours(statusUpdate.createdAt, MAX_EDIT_TIME));
    };

    const handleEditComment = (comment: string) => {
        onEdit(comment);
        setEditMode(false);
    };

    const handleDeleteComment = async () => {
        onDelete();
    };

    const items: BasicMenuItem[] = [
        {
            title: t('general.edit'),
            onClick: () => {
                setEditMode(true);
            },
        },
        {
            title: t('general.delete'),
            onClick: () => {
                handleDeleteComment().catch(handleError);
            },
        },
    ];

    return (
        <Stack gap={0.5}>
            <Stack direction='row' gap={1} alignItems='center'>
                {statusUpdate.createdBy && <EmployeeAvatar employeeAvatar={statusUpdate.createdBy} size={'small'} />}

                {statusUpdate.createdBy && (
                    <Typography color='text.disabled'>
                        {t('objectives.status_update.create_by', {
                            displayName: statusUpdate.createdBy.displayName,
                            date: new Date(statusUpdate.updatedAt ?? statusUpdate.createdAt),
                        })}
                    </Typography>
                )}
                {statusUpdate.status && <ObjectiveStatusProgressIcon objectiveStatus={statusUpdate.status} />}
            </Stack>
            <Stack direction='row' gap={1} alignItems='center'>
                <TextField
                    fullWidth
                    disabled={!editMode}
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    multiline
                    InputProps={
                        editMode
                            ? {
                                  endAdornment: (
                                      <InputAdornment position='end'>
                                          <IconButton onClick={() => handleEditComment(comment)} size='small' edge='end' aria-label={'save-comment'}>
                                              <Tick02Icon fontSize={'small'} />
                                          </IconButton>
                                      </InputAdornment>
                                  ),
                              }
                            : {}
                    }
                    inputProps={{
                        'aria-label': 'comment',
                    }}
                />
                {canManageComment(statusUpdate) && <BasicMenu items={items} endIcon={<MoreVerticalIcon />} />}
            </Stack>
        </Stack>
    );
};

type ObjectiveHistoryDetailsProps = {
    objective: Objective;
};

const ObjectiveHistoryDetails: FC<ObjectiveHistoryDetailsProps> = ({ objective }) => {
    const { t } = useTranslation();

    return (
        <Stack width={'300px'} alignItems={'flex-start'} direction={'row'} gap={2}>
            <Stack gap={2}>
                {objective.assignee && (
                    <Stack gap={0.5}>
                        <Typography variant={'body1'}>{t('objectives.history_dialog.assignee')}</Typography>
                        <EmployeeAvatarWithDetails employee={objective.assignee} />
                    </Stack>
                )}
                {objective.category && (
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Typography variant={'body1'}>{t('objectives.history_dialog.category')}</Typography>
                        <Typography variant={'body1bold'}>{objective.category.name}</Typography>
                    </Stack>
                )}
                <Typography variant={'body1'}>
                    {t('objectives.history_dialog.created_on')} <Typography variant={'body1bold'}>{format(objective.dueDate, DEFAULT_DATE_FORMAT)}</Typography>
                </Typography>
                {objective.lastStatusUpdate && (
                    <Typography variant={'body1'}>
                        {t('objectives.history_dialog.last_update')}{' '}
                        <Typography variant={'body1bold'}>{format(new Date(objective.lastStatusUpdate.updatedAt), DEFAULT_DATE_FORMAT)}</Typography>
                    </Typography>
                )}
                {objective.dueDate && (
                    <Typography variant={'body1'}>
                        {t('objectives.history_dialog.due_date')}{' '}
                        <Typography variant={'body1bold'}>{format(objective.dueDate, DEFAULT_DATE_FORMAT)}</Typography>
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};
