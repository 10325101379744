import { legalUnitApi } from '@/api/legal-unit/LegalUnit.api';
import { LegalUnit, LegalUnitCreateMutation, LegalUnitUpdateMutation } from '@/domain/legal-unit/LegalUnit.model';

export const getLegalUnits = async (): Promise<LegalUnit[]> => {
    return legalUnitApi.getLegalUnits();
};

export const getLegalUnit = async (id: number): Promise<LegalUnit> => {
    return legalUnitApi.getLegalUnit(id);
};

export const createLegalUnit = async (legalUnit: LegalUnitCreateMutation): Promise<LegalUnit> => {
    return legalUnitApi.createLegalUnit(legalUnit);
};

export const updateLegalUnit = async (id: number, legalUnit: LegalUnitUpdateMutation): Promise<LegalUnit> => {
    return legalUnitApi.updateLegalUnit(id, legalUnit);
};

export const updateLegalUnitLogo = async (id: number, logoKey: string): Promise<LegalUnit> => {
    return legalUnitApi.updateLegalUnitLogo(id, logoKey);
};

export const deleteLegalUnitLogo = (id: number): Promise<void> => {
    return legalUnitApi.deleteLegalUnitLogo(id);
};
