import { leavesReducer } from '@/stores/reducers/leavesSlice';
import { planningReducer } from '@/stores/reducers/planningSlice';
import { uiReducer } from '@/stores/reducers/uiSlice';
import { currentEmployeeReducer } from '@/stores/reducers/currentEmployeeSlice';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { employeeProfileReducer } from '@/stores/reducers/employeeProfileSlice';

export const store = configureStore({
    reducer: {
        currentEmployee: currentEmployeeReducer,
        ui: uiReducer,
        employeeProfile: employeeProfileReducer,
        planning: planningReducer,
        leaves: leavesReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
