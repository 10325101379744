import { FilterType } from '@/Components/filters-bar/FiltersBar';
import { DAY_OF_WEEK_SORT } from '@/utils/datetime.util';
import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material';
import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { getLocations } from '@/domain/location/Location.service';
import { getDepartments } from '@/domain/department/Department.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getJobs } from '@/domain/job/Job.service';

export const useTimesheetInsightsTableFilters = (): { filters: FilterType[] } => {
    const { t } = useTranslation();

    const filters: FilterType[] = [
        {
            filterName: capitalize(t('general.days_plural')),
            type: 'select',
            key: 'dayOfWeek',
            options: DAY_OF_WEEK_SORT.map(day => ({
                value: day,
                label: day,
            })),
            selectMode: 'SYNC',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: t('general.time'),
            type: 'time',
            key: 'timeRange',
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const locations = await getLocations();
                return locations?.map(location => ({
                    label: location.name,
                    value: location.id,
                }));
            },
            key: 'locations',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const departments = await getDepartments();
                return departments?.map(department => ({
                    label: getLabelTranslation(department.name),
                    value: department.id,
                }));
            },
            key: 'departments',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const jobs = await getJobs();
                return jobs?.map(job => ({
                    label: getLabelTranslation(job.name),
                    value: job.id,
                }));
            },
            key: 'jobs',
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
