export enum ScheduleColorType {
    POSITION_BASED = 'POSITION_BASED',
    SHIFT_BASED = 'SHIFT_BASED',
    AREA_BASED = 'AREA_BASED',
}

export interface PlanningSetting {
    id: number;
    shiftColor: ScheduleColorType;
    includeShiftsIntoTimesheets: boolean;
    maximumWorkingTimePerDay: number;
    deleteConflictOnEmployeeRelease: boolean;
    recurringShiftsNonWorkingDays: boolean;
}

export interface PlanningSettingMutation {
    shiftColor: ScheduleColorType;
    includeShiftsIntoTimesheets: boolean;
    maximumWorkingTimePerDay: number;
    deleteConflictOnEmployeeRelease: boolean;
    recurringShiftsNonWorkingDays: boolean;
}
