import * as yup from 'yup';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { isValidDate } from '@/utils/datetime.util';
import I18n from '@/I18n/i18n';
import { UserLanguage } from '@/utils/language.util';
import { isFuture } from 'date-fns';

export const surveySetupStartDateValidation = yup.date().when('sendNotificationNow', {
    is: (sendNotificationNow: boolean) => !sendNotificationNow,
    then: schema =>
        schema
            .required()
            .test({
                message: I18n.t('general.validations.valid_date'),
                test: function (date) {
                    return isValidDate(date);
                },
            })
            .test({
                message: I18n.t('general.validations.future_date'),
                test: function (date) {
                    return date && isFuture(date);
                },
            }),
    otherwise: schema => schema.nullable(),
});

export const surveySetupEndDateValidation = yup
    .date()
    .required()
    .test({
        message: I18n.t('general.validations.valid_date'),
        test: function (date) {
            return isValidDate(date);
        },
    })
    .test({
        message: I18n.t('general.validations.future_date'),
        test: function (date) {
            return date && isFuture(date);
        },
    });

export const surveySetupTimeValidation = yup.date().when('sendNotificationNow', {
    is: (sendNotificationNow: boolean) => !sendNotificationNow,
    then: schema =>
        schema.required().test({
            message: I18n.t('general.validations.valid_date'),
            test: function (date) {
                return isValidDate(date);
            },
        }),
    otherwise: schema => schema.nullable(),
});

// we cannot type the getSurveySetupFormSchema return type function with ObjectSchema<SurveySetupFormType> because SurveySetupFormType is inferring from the function
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSurveySetupFormSchema = (translationLanguage: UserLanguage) => {
    return yup.object().shape({
        surveyTemplateId: yup.number().required(),
        recipientIds: yup
            .array()
            .of(yup.number().defined())
            .required()
            .min(
                1,
                I18n.t('general.validations.min_length', {
                    length: 'one',
                    item: I18n.t('general.employee').toLowerCase(),
                }),
            ),
        explanation: getLabelFormSchema(translationLanguage),
        sendNotificationNow: yup.boolean().required(),
        startDate: surveySetupStartDateValidation,
        endDate: surveySetupEndDateValidation,
        startTime: surveySetupTimeValidation,
        endTime: surveySetupTimeValidation,
    });
};

type getSurveySetupFormSchemaType = ReturnType<typeof getSurveySetupFormSchema>;
export type SurveySetupFormSchemaType = yup.InferType<getSurveySetupFormSchemaType>;
