import { Paper, Radio, Stack, StackProps, TextField, Typography } from '@mui/material';
import useDebounce from '@/hooks/Debounce.hook';
import { FC, Ref } from 'react';
import { FieldError } from 'react-hook-form';
import { RadioLineGroup, TooltipFormControlLabel } from '@/Components/radio-line-group/RadioLineGroup';
import {
    EmployeeReviewFeedbackQuestionFormType,
    EmployeeReviewFeedbackSkillQuestionFormType,
} from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';
import { getLabelTranslation } from '@/utils/language.util';

type EmployeeReviewFeedbackQuestionProps<T extends EmployeeReviewFeedbackQuestionFormType | EmployeeReviewFeedbackSkillQuestionFormType> = {
    questionItem: T;
    onUpdate: (item: T) => void;
    innerRef: Ref<HTMLDivElement>;
    disabled?: boolean;
    error: {
        comment?: FieldError;
        score?: FieldError;
    };
    isSummary: boolean;
} & StackProps;

export const EmployeeReviewFeedbackQuestion: FC<
    EmployeeReviewFeedbackQuestionProps<EmployeeReviewFeedbackQuestionFormType | EmployeeReviewFeedbackSkillQuestionFormType>
> = ({ innerRef, questionItem, onUpdate, disabled = false, error, isSummary, ...rest }) => {
    const debounce = useDebounce();
    const handleScoreChange = (score: number) => {
        onUpdate({ ...questionItem, score });
    };
    const handleCommentChange = (comment: string) => {
        debounce(() => {
            onUpdate({ ...questionItem, comment });
        });
    };

    const PDF_DISPLAY_LENGTH_LIMIT = 7500;

    const ratingItemsOrderByScore = (questionItem.rating?.items ?? []).sort((a, b) => (a.score && b.score ? a.score - b.score : 0));
    return (
        <Stack component={Paper} ref={innerRef} gap={1} {...rest}>
            {!isSummary && (
                <Typography variant='h2' sx={{ whiteSpace: 'pre-wrap' }}>
                    {/*string for skill question, label for question*/}
                    {typeof questionItem.title === 'string' ? questionItem.title : getLabelTranslation(questionItem.title)} {questionItem.required && '*'}
                </Typography>
            )}

            {questionItem.description && !isSummary && (
                <Typography
                    variant='body2'
                    className={'rich_text'}
                    dangerouslySetInnerHTML={{
                        __html: questionItem.description ?? '',
                    }}
                />
            )}

            {questionItem.instruction && !isSummary && (
                <Typography
                    variant='body2'
                    className={'rich_text'}
                    dangerouslySetInnerHTML={{
                        __html: typeof questionItem.instruction === 'string' ? questionItem.instruction : getLabelTranslation(questionItem.instruction),
                    }}
                />
            )}

            {questionItem.rating && (
                <RadioLineGroup
                    error={!!error?.score}
                    defaultValue={questionItem.score}
                    onChange={e => handleScoreChange(Number(e.target.value))}
                    data-testid={'question_' + questionItem.id + '_rating'}
                >
                    {ratingItemsOrderByScore.map(item => (
                        <TooltipFormControlLabel
                            key={(item.label ?? '') + item.score || '' + (item?.description ?? '')}
                            value={item.score}
                            control={<Radio />}
                            disabled={disabled}
                            label={<Typography className={item.description ? 'rp-underline' : ''}>{item.label}</Typography>}
                            description={item.description}
                        />
                    ))}
                </RadioLineGroup>
            )}
            <TextField
                fullWidth
                InputProps={{ multiline: true, minRows: 2 }}
                disabled={disabled}
                inputProps={{ maxLength: PDF_DISPLAY_LENGTH_LIMIT }}
                defaultValue={questionItem?.comment ?? ''}
                onChange={e => handleCommentChange(e.target.value)}
                error={!!error?.comment}
                helperText={error?.comment?.message}
            />
        </Stack>
    );
};
