import { Label } from '@/domain/label/Label.model';
import { AnswerResult, SurveyResultFilterType } from '@/domain/survey/Survey.model';
import { renderGroupByWithFilters } from '@/domain/survey/Survey.service';
import { getLabelTranslation } from '@/utils/language.util';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Comment } from './Comment';
import { ScaleGroupBy } from './ScaleGroupBy';

type Props = {
    title: Label;
    answer: AnswerResult;
    groupBy: SurveyResultFilterType;
    activeGroupByFilters: string[];
};

const useStyles = makeStyles(theme => ({
    paperSpace: {
        padding: theme.spacing(3),
        '& .singleGroup': {
            display: 'flex',
            flexGrow: 0,
        },
    },
    charts: {
        maxWidth: 635,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    pieBar: {
        position: 'relative',
    },
    commentsContainer: {
        maxHeight: 300,
        overflow: 'auto',
    },
    progressStatus: {
        position: 'absolute',
        display: 'flex',
        width: 80,
        height: 80,
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'space-around',
        fontSize: 30,
        textAlign: 'center',
        right: 0,
        bottom: 0,
        margin: 'auto',
    },
    commentsButton: {
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
    },
    totalPercent: {
        fontSize: 34,
        fontWeight: 'bold',
    },
    average: {
        fontSize: 12,
        color: '#585869',
    },
    commentsSpace: {
        paddingBottom: 0,
        width: '100%',
    },
    mainGroupAll: {
        boxShadow: '0px 4px 4px rgb(0 0 0 / 10%)',
        padding: '4px 0px',
    },
}));

export const ScaleResult: FC<Props> = ({ title, activeGroupByFilters, answer, groupBy }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!answer?.details || !answer?.completions) {
        return <></>;
    }

    return (
        <Paper elevation={1} className={classes.paperSpace}>
            <Grid container direction={'row'} justifyContent={'space-between'}>
                <Grid item>
                    <Typography variant='body1bold'>{getLabelTranslation(title)}</Typography>
                </Grid>
                <Grid item>
                    <Typography component={'span'} variant='body1'>
                        {answer.completions?.ALL[0].answered} {t('survey_results.answered')} - {answer.completions?.ALL[0].skipped}{' '}
                        {t('survey_results.skipped')}
                    </Typography>
                </Grid>
            </Grid>
            <div className={classNames(groupBy !== SurveyResultFilterType.ALL ? classes.mainGroupAll : '')}>
                <ScaleGroupBy question={answer.question} answerDetails={answer.details.ALL[0]} groupBy={SurveyResultFilterType.ALL} />
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid className={classes.commentsContainer}>
                        {answer.details.ALL[0].comments?.map((comment, index: number) => <Comment key={index} text={comment.comment} user={comment.user} />)}
                    </Grid>
                </Grid>
            </div>
            {groupBy !== SurveyResultFilterType.ALL &&
                answer.details[groupBy] &&
                renderGroupByWithFilters(activeGroupByFilters, answer.details[groupBy]).map((answerDetails, index) =>
                    answerDetails.items.length ? (
                        <div key={index}>
                            <ScaleGroupBy question={answer.question} answerDetails={answerDetails} groupBy={groupBy} />
                            <Grid container direction={'row'} justifyContent={'space-between'}>
                                <Grid className={classes.commentsContainer}>
                                    {answerDetails.comments?.map((comment, i: number) => <Comment key={i} text={comment.comment} user={comment.user} />)}
                                </Grid>
                            </Grid>
                        </div>
                    ) : undefined,
                )}
        </Paper>
    );
};
