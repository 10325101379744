import { MONTHS } from '@/utils/datetime.util';
import { addYears, format, subDays } from 'date-fns';
import { getAppConfig } from '@/config/config';
import { TimesheetSetting } from '@/domain/timesheet-setting/TimesheetSetting.model';

export const getCycleDates = (cycleStartMonth: MONTHS, year: number): Date[] => {
    const monthIndex = Object.keys(MONTHS).indexOf(cycleStartMonth);
    const cycleStart = new Date(year, monthIndex, 1);
    const cycleEnd = subDays(addYears(cycleStart, 1), 1);
    return [cycleStart, cycleEnd];
};
export const isDifferentLifeCycleStartMonth = (timesheetSettings: TimesheetSetting[]): boolean =>
    timesheetSettings?.some(timesheetSetting => timesheetSetting?.cycleStartMonth !== timesheetSettings[0]?.cycleStartMonth);
export const getCycleDatesToDisplay = (cycleStartMonth: MONTHS, year: number): string => {
    if (cycleStartMonth === MONTHS.JANUARY) {
        return year?.toString();
    }
    const [cycleStart, cycleEnd] = getCycleDates(cycleStartMonth, year);

    return `${format(cycleStart, getAppConfig().DEFAULT_DATE_FORMAT)} - ${format(cycleEnd, getAppConfig().DEFAULT_DATE_FORMAT)}`;
};
