import {
    canApproveRejectOtherEmployeeTimesheets,
    canManageOtherEmployeeTimesheets,
    canViewTimesheetInsightBalance,
} from '@/domain/permission/Permission.service';
import { useAppSelector } from '@/stores/store';
import { Paper, PaperProps, Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

type TimesheetsTabSection = 'monthly-summary' | 'pending' | 'all' | 'balances' | 'insights' | 'weekly-insights';

export const ManageTimesheetHeader: FC<PaperProps> = ({ sx, ...restProps }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const grantedPolicies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const location = useLocation();

    if (!realm) {
        throw new Error('Realm is not defined');
    }

    if (!currentEmployee) {
        throw new Error('Current employee is not defined');
    }

    const tabOptions: { name: string; value: `/timesheets/${TimesheetsTabSection}` }[] = [];

    if (canManageOtherEmployeeTimesheets(realm.realmFeatures, grantedPolicies, currentEmployee?.id)) {
        tabOptions.push({
            name: t('timesheets.tabs.monthly_summary'),
            value: '/timesheets/monthly-summary',
        });
        if (canApproveRejectOtherEmployeeTimesheets(grantedPolicies, currentEmployee?.id)) {
            tabOptions.push({
                name: t('timesheets.tabs.pending_timesheets'),
                value: '/timesheets/pending',
            });
        }
        tabOptions.push({
            name: t('timesheets.tabs.all'),
            value: '/timesheets/all',
        });
        tabOptions.push({
            name: t('timesheets.tabs.balances'),
            value: '/timesheets/balances',
        });
        if (canViewTimesheetInsightBalance(grantedPolicies, currentEmployee?.id)) {
            tabOptions.push(
                {
                    name: t('timesheets.tabs.insights'),
                    value: '/timesheets/insights',
                },
                {
                    name: t('timesheets.tabs.weekly_insights'),
                    value: '/timesheets/weekly-insights',
                },
            );
        }
    }

    return (
        <Paper
            sx={{
                borderRadius: 1,
                display: { xs: 'none', md: 'block' },
                ...sx,
            }}
            {...restProps}
        >
            <Tabs value={location.pathname} onChange={(_e, value) => navigate(value)} aria-label='Timesheets Tabs Control'>
                {tabOptions.map(item => (
                    <Tab key={item.name} label={item.name} value={item.value} />
                ))}
            </Tabs>
        </Paper>
    );
};
