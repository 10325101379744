import { DialogWrapper } from '@/Components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/Components/form/field-date/FieldDate';
import { InputNumber } from '@/Components/form/field-number/InputNumber';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { removeNullsFromLeaveType } from '@/domain/leave-type/LeaveType.service';
import { Timesheet } from '@/domain/timesheet/Timesheet.model';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import {
    paymentAdjustmentFormSchema,
    PaymentAdjustmentFormSchema,
    TimesheetAdjustmentAction,
} from '@/page/employee-profile/employee-profile-overtime/AddPaymentAdjustmentForm.schema';
import { formatToLocalDate, getCurrentLocalDate, getHoursMinutesFromMinutes, getMinutesFromHoursMinutes, HoursMinutes, LocalDate } from '@/utils/datetime.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, capitalize, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type PaymentAdjustmentDialogData = {
    paymentId?: number;
    amountInMinutes: number;
    requestDate: LocalDate;
    comment: string;
    leaveTypeId?: number;
};

type AddPaymentAdjustmentDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (paymentAdjustmentDialogData: PaymentAdjustmentDialogData) => void;
    isAdjustment: boolean;
    paymentAdjustmentData?: Timesheet;
};

export const AddPaymentAdjustmentDialog: FC<AddPaymentAdjustmentDialogProps> = props => {
    const { t } = useTranslation();

    const { open, onClose, ...restProps } = props;

    const {
        data: leaveTypes = [],
        isLoading: isLeaveTypesLoading,
        isError: isLeaveTypesError,
        error: leaveTypesError,
    } = useGetLeaveTypes(restProps.isAdjustment);

    const handleClose = () => {
        onClose();
    };

    const filterLeaveTypes = (leaveTypes: LeaveType[]): LeaveType[] => {
        return leaveTypes.filter(leaveType => leaveType.allowanceType === 'NOT_UNLIMITED');
    };

    return (
        <DialogWrapper
            open={open}
            onClose={handleClose}
            header={restProps.isAdjustment ? t('timesheets.add_adjustment') : t('add_overtime_payment_dialog.title')}
        >
            <StateHandler isLoading={isLeaveTypesLoading} isError={isLeaveTypesError} error={leaveTypesError}>
                <AddPaymentAdjustmentDialogForm {...restProps} leaveTypes={filterLeaveTypes(leaveTypes)} />
            </StateHandler>
        </DialogWrapper>
    );
};

type AddPaymentAdjustmentFormProps = {
    onSave: (paymentAdjustmentDialogData: PaymentAdjustmentDialogData) => void;
    isAdjustment: boolean;
    paymentAdjustmentData?: Timesheet;
    leaveTypes: LeaveType[];
};

const AddPaymentAdjustmentDialogForm: FC<AddPaymentAdjustmentFormProps> = ({ onSave, isAdjustment, paymentAdjustmentData, leaveTypes }) => {
    const { t } = useTranslation();

    const { control, watch, handleSubmit } = useForm<PaymentAdjustmentFormSchema>({
        resolver: yupResolver(paymentAdjustmentFormSchema),
        defaultValues: {
            leaveType: leaveTypes[0] ? removeNullsFromLeaveType(leaveTypes[0]) : getNull(),
            actionType: paymentAdjustmentData && paymentAdjustmentData.adjustmentCount < 0 ? TimesheetAdjustmentAction.REMOVE : TimesheetAdjustmentAction.ADD,
            hoursMinutes: getHoursMinutesFromTotalMinutes(isAdjustment, paymentAdjustmentData),
            requestDate: formatToLocalDate(paymentAdjustmentData?.startAt) ?? getCurrentLocalDate(),
            comment: paymentAdjustmentData?.comment ?? '',
        },
    });

    const hoursMinutes = watch('hoursMinutes');
    const actionType = watch('actionType');

    const handleSave = (data: PaymentAdjustmentFormSchema) => {
        onSave({
            requestDate: data.requestDate,
            comment: data.comment,
            amountInMinutes:
                (data.actionType === TimesheetAdjustmentAction.REMOVE || data.actionType === TimesheetAdjustmentAction.TRANSFER) && isAdjustment
                    ? -getMinutesFromHoursMinutes(data.hoursMinutes)
                    : getMinutesFromHoursMinutes(data.hoursMinutes),
            leaveTypeId: data.actionType === TimesheetAdjustmentAction.TRANSFER ? data.leaveType?.id : undefined,
        });
    };

    const handleHoursChange = (hours: number, onHoursMinutesChange: (data: HoursMinutes) => void) => {
        const newHoursMinutes = {
            ...hoursMinutes,
            hours,
        };
        onHoursMinutesChange(newHoursMinutes);
    };

    const handleMinutesChange = (minutes: number, onHoursMinutesChange: (data: HoursMinutes) => void) => {
        // extract the supplement hours if user set minutes greater than 60
        const suppHours = Math.trunc(minutes / 60);
        const restMinutes = minutes % 60;
        const newHoursMinutes = {
            hours: hoursMinutes.hours + suppHours,
            minutes: restMinutes,
        };
        onHoursMinutesChange(newHoursMinutes);
    };

    const actionOptions = [TimesheetAdjustmentAction.ADD, TimesheetAdjustmentAction.REMOVE];
    if (isAdjustment && !paymentAdjustmentData && leaveTypes.length > 0) {
        actionOptions.push(TimesheetAdjustmentAction.TRANSFER);
    }
    const shouldDisplayLeaveType = isAdjustment && actionType === TimesheetAdjustmentAction.TRANSFER;

    return (
        <>
            <DialogContent>
                <Stack gap={2}>
                    <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
                        {isAdjustment ? (
                            <Controller
                                name={'actionType'}
                                control={control}
                                render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                    <FormControlLabel
                                        sx={{ width: '100%', flexGrow: 1 }}
                                        label={t('timesheets.action')}
                                        labelPlacement='top'
                                        control={
                                            <Autocomplete
                                                disableClearable={true}
                                                value={value}
                                                onChange={(_, value) => {
                                                    onChange(value);
                                                }}
                                                options={actionOptions}
                                                getOptionLabel={actionType => t(getTranslationKeyForAction(actionType))}
                                                renderInput={params => <TextField {...restField} {...params} error={!!error} helperText={error?.message} />}
                                                fullWidth
                                            />
                                        }
                                    />
                                )}
                            />
                        ) : undefined}
                        <Controller
                            name={'hoursMinutes'}
                            control={control}
                            render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                <>
                                    <Stack flexGrow={1} width={'100%'}>
                                        <FormControlLabel
                                            label={capitalize(t('domain.unit_type.hours'))}
                                            labelPlacement='top'
                                            sx={{ flex: 1 }}
                                            control={
                                                <InputNumber
                                                    {...restField}
                                                    value={value.hours}
                                                    onChange={hours => {
                                                        handleHoursChange(hours ?? 0, onChange);
                                                    }}
                                                    onFocus={event => {
                                                        event.target.select();
                                                    }}
                                                    precision={0}
                                                    fullWidth
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            }
                                        />
                                    </Stack>
                                    <Stack flexGrow={1} width={'100%'}>
                                        <FormControlLabel
                                            label={capitalize(t('domain.unit_type.minutes'))}
                                            labelPlacement='top'
                                            sx={{ flex: 1 }}
                                            control={
                                                <InputNumber
                                                    {...restField}
                                                    value={value.minutes}
                                                    onChange={minutes => {
                                                        handleMinutesChange(minutes ?? 0, onChange);
                                                    }}
                                                    onFocus={event => {
                                                        event.target.select();
                                                    }}
                                                    precision={0}
                                                    fullWidth
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            }
                                        />
                                    </Stack>
                                </>
                            )}
                        />
                    </Stack>
                    {shouldDisplayLeaveType && (
                        <Controller
                            name={`leaveType`}
                            control={control}
                            render={({ field: { value, onChange }, fieldState }) => (
                                <FormControlLabel
                                    label={t('add_overtime_payment_dialog.transfer_to')}
                                    labelPlacement='top'
                                    control={
                                        <Autocomplete
                                            fullWidth
                                            value={value ?? getNull()}
                                            options={leaveTypes}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            getOptionLabel={leaveTypeHistory => leaveTypeHistory.title}
                                            getOptionKey={leaveTypeHistory => leaveTypeHistory.id}
                                            onChange={(_, selectedOption) => {
                                                onChange(selectedOption ? removeNullsFromLeaveType(selectedOption) : selectedOption);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message ? t('general.validations.required') : undefined}
                                                />
                                            )}
                                        />
                                    }
                                />
                            )}
                        />
                    )}
                    <FormControlLabel
                        label={t('add_overtime_payment_dialog.effective_date')}
                        control={<FieldLocalDate control={control} name={'requestDate'} />}
                    />

                    <Controller
                        name={'comment'}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControlLabel
                                label={t('add_overtime_payment_dialog.comment')}
                                control={
                                    <TextField
                                        {...field}
                                        fullWidth
                                        InputProps={{ multiline: true, minRows: 2 }}
                                        placeholder={t('request_overtime_dialog.comment_placeholder')}
                                        error={!!error}
                                        helperText={error?.message}
                                    />
                                }
                            />
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleSubmit(handleSave, console.error)()} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </>
    );
};

const getHoursMinutesFromTotalMinutes = (isAdjustment: boolean, paymentAdjustmentData?: Timesheet) => {
    if (isAdjustment) {
        return paymentAdjustmentData?.adjustmentCount
            ? getHoursMinutesFromMinutes(Math.abs(paymentAdjustmentData?.adjustmentCount))
            : {
                  hours: 0,
                  minutes: 0,
              };
    } else {
        return paymentAdjustmentData?.paymentCount
            ? getHoursMinutesFromMinutes(Math.abs(paymentAdjustmentData?.paymentCount))
            : {
                  hours: 0,
                  minutes: 0,
              };
    }
};

const getTranslationKeyForAction = (action: TimesheetAdjustmentAction): string => {
    switch (action) {
        case TimesheetAdjustmentAction.ADD:
            return 'general.add';
        case TimesheetAdjustmentAction.REMOVE:
            return 'general.remove';
        case TimesheetAdjustmentAction.TRANSFER:
            return 'general.transfer';
    }
};
