import { FileMetadata, FilePickerWrapper } from '@/Components/file-picker-wrapper/FilePickerWrapper';
import { EmployeeSectionFieldDocument } from '@/domain/employee-section/EmployeeSection.model';
import { FormHelperText, Stack } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getEmployeeSectionFieldDocumentUrl } from '@/domain/employee-section/EmployeeSection.service';

type EditableDocumentFieldProps = {
    // TODO: Maybe we can improve this to be more generic and not only for section fields
    documentsValue: EmployeeSectionFieldDocument[];
    formValueName: string;
};

export const EditableDocumentField: FC<EditableDocumentFieldProps> = ({ formValueName, documentsValue }) => {
    const { setValue, resetField, getValues, control } = useFormContext<Record<string, FileMetadata[]>>();

    // Set documentsValue to the form state
    useDeepCompareEffect(() => {
        // If the documentsValue is already in the form state, we don't need to set it again
        if (getValues(formValueName)?.length) {
            return;
        }
        setValue(formValueName, mapFieldDocumentsToFilesMetadata(documentsValue));
    }, [documentsValue, formValueName, setValue]);

    const onCancel = () => {
        resetField(formValueName);
    };

    const onFileUploaded = (fileMetadata: FileMetadata, onChange: (documents: FileMetadata[]) => void) => {
        const documentsFromFormState = getValues(formValueName);
        const updatedFieldDocuments = addFileToDocuments(fileMetadata, documentsFromFormState);

        onChange(updatedFieldDocuments);
    };

    const onFileRenamed = (fileMetadata: FileMetadata, onChange: (documents: FileMetadata[]) => void) => {
        const documentsFromFormState = getValues(formValueName);
        const updatedFieldDocuments = replaceFileFromDocuments(fileMetadata, documentsFromFormState);

        onChange(updatedFieldDocuments);
    };
    const onFileRemoved = (fileMetadata: FileMetadata, onChange: (documents: FileMetadata[]) => void) => {
        const documentsFromFormState = getValues(formValueName);
        const updatedFieldDocuments = removedFileFromDocuments(fileMetadata, documentsFromFormState);

        onChange(updatedFieldDocuments);
    };

    return (
        <Stack width={'100%'}>
            <Controller
                name={formValueName}
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <>
                        <FilePickerWrapper
                            width={'100%'}
                            onCancel={onCancel}
                            onFileRenamed={data => onFileRenamed(data, onChange)}
                            onFileUploaded={data => onFileUploaded(data, onChange)}
                            onFileRemoved={data => onFileRemoved(data, onChange)}
                            filesMetadata={value}
                            fetchDocumentUrl={id => getEmployeeSectionFieldDocumentUrl(id, 'ATTACHMENT').then(documentDownloadUrl => documentDownloadUrl)}
                            containerId={formValueName}
                        />
                        {!!error && (
                            <FormHelperText sx={{ marginLeft: 1.75 }} error={!!error}>
                                {error?.message}
                            </FormHelperText>
                        )}
                    </>
                )}
            />
        </Stack>
    );
};

const addFileToDocuments = (fileMetadata: FileMetadata, currentDocuments: FileMetadata[]): FileMetadata[] => {
    if (!currentDocuments) {
        return [];
    }
    return [...currentDocuments, fileMetadata];
};

const replaceFileFromDocuments = (fileMetadata: FileMetadata, currentDocuments: FileMetadata[]): FileMetadata[] => {
    if (!currentDocuments) {
        return [];
    }
    return currentDocuments?.map(document => (isSameDocument(document, fileMetadata) ? fileMetadata : document));
};
const isSameDocument = (document1: FileMetadata, document2: FileMetadata): boolean => {
    const itemId1 = document1?.itemId;
    const itemId2 = document2?.itemId;
    const key1 = document1?.key;
    const key2 = document2?.key;

    const isSameExistingDocument = !!itemId1 && !!itemId2 && itemId1 === itemId2;
    const isSameNewlyUploadedDocument = !!key1 && !!key2 && key1 === key2;
    return isSameExistingDocument || isSameNewlyUploadedDocument;
};

/**
 * Remove file from the list of documents
 * File could be an existing document or a newly uploaded document
 * if the file is an existing document, it will have an itemId
 * if the file is a newly uploaded document, it will not have an itemId
 * @param fileMetadata
 * @param currentDocuments
 * @returns
 */
const removedFileFromDocuments = (fileMetadata: FileMetadata, currentDocuments: FileMetadata[]): FileMetadata[] => {
    if (!currentDocuments) {
        return [];
    }
    return currentDocuments?.filter(document => !isSameDocument(document, fileMetadata));
};

const mapFieldDocumentsToFilesMetadata = (fieldDocuments: EmployeeSectionFieldDocument[]): FileMetadata[] => {
    return fieldDocuments.map(document => ({
        filename: document.name,
        key: document.s3Key, // not sure if this is the correct key
        mimetype: document.mimeType,
        url: undefined,
        itemId: document.id,
    }));
};
