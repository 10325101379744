import { API_BASE_URL, client } from '@/api/common';
import { DocumentDTO } from '@/api/document/Document.api';
import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { Document } from '@/domain/document/Document.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import {
    ConflictLeaveSearchRequest,
    LeaveCreationMutation,
    LeaveRequest,
    LeaveRequestPreview,
    LeaveRequestSearchRequest,
    LeaveUpdateMutation,
    OverlappingLeaveSearchRequest,
} from '@/domain/leave-request/LeaveRequest.model';
import { AxiosResponse } from 'axios';

export const mapLeaveRequestDTO = (dto: LeaveRequestDTO): LeaveRequest => {
    return {
        ...dto,
        employee: mapEmployeeDTO(dto.employee),
    };
};

const createLeaveRequest = async (mutation: LeaveCreationMutation): Promise<LeaveRequest[]> => {
    const url = API_BASE_URL + `/leave-requests`;

    const { data } = await client.post<LeaveRequestDTO[], AxiosResponse<LeaveRequestDTO[]>, LeaveCreationRequest>(url, mutation);
    return data.map(mapLeaveRequestDTO);
};

const previewLeaveRequest = async (mutation: LeaveCreationMutation): Promise<LeaveRequestPreview> => {
    const url = API_BASE_URL + `/leave-requests/preview`;

    return (await client.post<LeaveRequestPreviewDTO, AxiosResponse<LeaveRequestPreviewDTO>, LeaveCreationRequest>(url, mutation)).data;
};

const editLeaveRequest = async (leaveRequestId: number, mutation: LeaveUpdateMutation): Promise<LeaveRequest> => {
    const url = API_BASE_URL + `/leave-requests/${leaveRequestId}`;

    return mapLeaveRequestDTO((await client.put<LeaveRequestDTO, AxiosResponse<LeaveRequestDTO>, LeaveUpdateRequest>(url, mutation)).data);
};

const editPendingLeaveRequest = async (leaveRequestId: number, mutation: LeaveUpdateMutation): Promise<LeaveRequest> => {
    const url = API_BASE_URL + `/leave-requests/${leaveRequestId}/pending`;

    return mapLeaveRequestDTO((await client.put<LeaveRequestDTO, AxiosResponse<LeaveRequestDTO>, LeaveUpdateRequest>(url, mutation)).data);
};

const cancelApprovedLeaveRequest = async (leaveRequestId: number): Promise<LeaveRequest> => {
    const url = API_BASE_URL + `/leave-requests/${leaveRequestId}/cancel`;
    return mapLeaveRequestDTO((await client.post<LeaveRequestDTO, AxiosResponse<LeaveRequestDTO>>(url, {})).data);
};

const approvePendingLeaveRequest = async (leaveRequestId: number): Promise<LeaveRequest> => {
    const url = API_BASE_URL + `/leave-requests/${leaveRequestId}/pending/approve`;
    return mapLeaveRequestDTO((await client.post<LeaveRequestDTO, AxiosResponse<LeaveRequestDTO>>(url, {})).data);
};

const declinePendingLeaveRequest = async (leaveRequestId: number, declineComment: string): Promise<LeaveRequest> => {
    const mutation = {
        declineComment: declineComment,
    }; //BE type: LeaveDeclineRequestDTO

    const url = API_BASE_URL + `/leave-requests/${leaveRequestId}/pending/decline`;
    return mapLeaveRequestDTO((await client.post<LeaveRequestDTO, AxiosResponse<LeaveRequestDTO>>(url, mutation)).data);
};

const cancelPendingLeaveRequest = async (leaveRequestId: number): Promise<LeaveRequest> => {
    const url = API_BASE_URL + `/leave-requests/${leaveRequestId}/pending/cancel`;
    return mapLeaveRequestDTO((await client.post<LeaveRequestDTO, AxiosResponse<LeaveRequestDTO>>(url, {})).data);
};

const getPendingLeaveRequests = async (): Promise<LeaveRequest[]> => {
    const url = API_BASE_URL + '/leave-requests/pending';
    return (await client.get<LeaveRequestDTO[]>(url)).data.map(mapLeaveRequestDTO);
};

const getLeaveRequestById = async (leaveRequestId: number): Promise<LeaveRequest> => {
    const url = API_BASE_URL + `/leave-requests/${leaveRequestId}`;
    return mapLeaveRequestDTO((await client.get<LeaveRequestDTO>(url)).data);
};

const searchLeaveRequests = async (searchRequest: LeaveRequestSearchRequest): Promise<LeaveRequest[]> => {
    const url = API_BASE_URL + '/leave-requests/search';
    return (await client.post<LeaveRequestDTO[], AxiosResponse<LeaveRequestDTO[]>, LeaveRequestSearchRequest>(url, searchRequest)).data.map(mapLeaveRequestDTO);
};

const searchConflictingRequests = async (searchRequest: ConflictLeaveSearchRequest): Promise<LeaveRequest[]> => {
    const url = API_BASE_URL + '/leave-requests/search/conflicts';
    return (await client.post<LeaveRequestDTO[], AxiosResponse<LeaveRequestDTO[]>, ConflictLeaveSearchRequest>(url, searchRequest)).data.map(
        mapLeaveRequestDTO,
    );
};

const searchOverlappingRequests = async (searchRequest: OverlappingLeaveSearchRequest): Promise<LeaveRequest[]> => {
    const url = API_BASE_URL + '/leave-requests/search/overlapping';
    return (await client.post<LeaveRequestDTO[], AxiosResponse<LeaveRequestDTO[]>, OverlappingLeaveSearchRequest>(url, searchRequest)).data.map(
        mapLeaveRequestDTO,
    );
};

const getUserLeaveRequests = async (employeeId: number): Promise<LeaveRequest[]> => {
    const url = API_BASE_URL + `/leave-requests/employee/${employeeId}`;
    return (await client.get<LeaveRequestDTO[]>(url)).data.map(mapLeaveRequestDTO);
};

const deleteLeaveRequest = (id: number): Promise<void> => {
    const url = API_BASE_URL + `/leave-requests/${id}`;
    return client.delete(url);
};

const approvePendingLeaveRequestBatch = async (leaveRequestIds: number[]): Promise<Document> => {
    const searchRequest = {
        leaveRequestIds: leaveRequestIds,
    }; //BE type: BatchApproveLeaveRequestDTO

    const url = API_BASE_URL + `/leave-requests/batch/approve`;
    return (await client.post<DocumentDTO, AxiosResponse<DocumentDTO>>(url, searchRequest)).data;
};

const importLeaveRequests = async (request: { key: string }): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/leave-requests/import`, request)).data;
};

export const leaveRequestApi = {
    createLeaveRequest,
    previewLeaveRequest,
    editLeaveRequest,
    editPendingLeaveRequest,
    cancelApprovedLeaveRequest,
    approvePendingLeaveRequest,
    declinePendingLeaveRequest,
    cancelPendingLeaveRequest,
    getPendingLeaveRequests,
    getLeaveRequestById,
    searchLeaveRequests,
    searchConflictingRequests,
    searchOverlappingRequests,
    getUserLeaveRequests,
    deleteLeaveRequest,
    approvePendingLeaveRequestBatch,
    importLeaveRequests,
};

export type LeaveRequestDTO = Overwrite<LeaveRequest, { employee: EmployeeDTO }>;
export type LeaveRequestPreviewDTO = LeaveRequestPreview;

type LeaveUpdateRequest = DateToString<LeaveUpdateMutation>;

type LeaveCreationRequest = DateToString<LeaveCreationMutation>;
