import { EmployeePolicy } from '@/domain/employee/Employee.model';
import {
    canAccessEmployeeReviews,
    canManageLeaves,
    canViewEmployeeDocuments,
    canViewObjectives,
    canViewProfileJobInfoTab,
    canViewProfilePersonalInfoTab,
    canViewShifts,
    canViewTimesheets,
} from '@/domain/permission/Permission.service';
import { RealmFeature } from '@/domain/realm/Realm.model';
import { SectionType } from '@/domain/section-setting/Section.model';
import { Location } from 'react-router-dom';

export type PROFILE_TABS = 'personal-info' | 'job-info' | 'leaves' | 'documents' | 'history' | 'planning' | 'reviews' | 'objectives' | 'roles' | 'timesheets';

export type ProfileTabItems = {
    label: string;
    path: PROFILE_TABS;
    condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => boolean;
};

export const getProfileTabItems = (employeeId: number, realmFeatures: RealmFeature[], policies: EmployeePolicy[]): ProfileTabItems[] => {
    const allTabs: ProfileTabItems[] = [
        {
            label: 'my_profile.tabs.personal_info',
            path: 'personal-info',
            condition: (_, policies: EmployeePolicy[]) => canViewProfilePersonalInfoTab(policies, employeeId),
        },
        {
            label: 'my_profile.tabs.job_info',
            path: 'job-info',
            condition: (_, policies: EmployeePolicy[]) => canViewProfileJobInfoTab(policies, employeeId),
        },
        {
            label: 'my_profile.tabs.leaves',
            path: 'leaves',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canManageLeaves(realmFeatures, policies, employeeId),
        },
        {
            label: 'my_profile.tabs.timesheets',
            path: 'timesheets',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewTimesheets(realmFeatures, policies, employeeId),
        },
        {
            label: 'my_profile.tabs.planning',
            path: 'planning',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewShifts(realmFeatures, policies),
        },
        {
            label: 'my_profile.tabs.reviews',
            path: 'reviews',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canAccessEmployeeReviews(realmFeatures, policies, employeeId),
        },
        {
            label: 'my_profile.tabs.objectives',
            path: 'objectives',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewObjectives(realmFeatures, policies, employeeId),
        },
        {
            label: 'my_profile.tabs.roles',
            path: 'roles',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canAccessEmployeeReviews(realmFeatures, policies, employeeId),
        },
        {
            label: 'my_profile.tabs.documents',
            path: 'documents',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewEmployeeDocuments(realmFeatures, policies, employeeId),
        },
    ];
    return allTabs.filter(tab => tab.condition(realmFeatures, policies));
};

export const getProfileTabByLocation = (location: Location): PROFILE_TABS => {
    return location.pathname.split('/').pop() as PROFILE_TABS;
};

export const PERSONAL_SECTIONS = [SectionType.BASIC_INFO, SectionType.PERSONAL_INFO, SectionType.ADDRESS];
