import { payrollAPI } from '@/api/payroll/Payroll.api';
import { LeaveTransaction } from '@/domain/leave-transaction/LeaveTransaction.model';
import { EmployeePayrollProfileChange, PayrollProfileChangeSearch } from '@/domain/payroll/Payroll.model';

export const getPayrollTransactions = (filters: { startDate: Date }): Promise<LeaveTransaction[]> => {
    return payrollAPI.getPayrollTransactions(filters);
};

export const getPayrollEmployeeProfileChanges = (search: PayrollProfileChangeSearch): Promise<EmployeePayrollProfileChange[]> => {
    return payrollAPI.getPayrollEmployeeProfileChanges(search);
};
