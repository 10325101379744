export const isValidAvs = (avsNumber: string): boolean => {
    // BE uses the same regex pattern
    const AVS_REGEX_PATTERN = '\\b756\\.?\\d{4}\\.?\\d{4}\\.?\\d{2}\\b';
    return !!RegExp(AVS_REGEX_PATTERN).exec(avsNumber)?.length && checkDigitEAN13(avsNumber);
};

// algorithm to check the validity of an AVS number
// /!\ if you change something here, make sure to update also the BE that uses the same algorithm
const checkDigitEAN13 = (avsNumber: string): boolean => {
    const sanitizedAvs = avsNumber.replace(/\D/g, '');

    // Check if the AVS number has exactly 13 digits
    if (sanitizedAvs.length !== 13 || !/^\d+$/.test(sanitizedAvs)) {
        return false;
    }

    const digits = sanitizedAvs.split('').map(Number);
    let sum = 0;
    for (let i = 0; i < digits.length - 1; i++) {
        sum += digits[i] * (i % 2 === 0 ? 1 : 3);
    }

    const checkDigit = (10 - (sum % 10)) % 10;
    const lastDigit = digits[digits.length - 1];

    return checkDigit === lastDigit;
};
