import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { FC, useState } from 'react';
import { formatInDefaultDate, formatToLocalDate, LocalDate } from '@/utils/datetime.util';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay';
import { Button, DialogActions, IconButton, InputAdornment, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogWrapper } from '@/Components/dialog-wrapper/DialogWrapper';
import { Calendar03Icon, Cancel01Icon } from 'hugeicons-react';

/*
 Component inspired by:
 https://stackoverflow.com/questions/76576451/highlight-the-days-in-mui-staticdatepicker-calender
 AND
 https://stackoverflow.com/questions/46762199/material-ui-select-multiple-dates-with-calendar
 */

type MultipleDatePickerProps = {
    minDate?: Date;
    maxDate?: Date;
    selectedDays: LocalDate[];
    onChange?(selectedDays: LocalDate[]): void;
    onClose?(selectedDays: LocalDate[]): void;
};

type DatesDisplayProps = {
    selectedDays: LocalDate[];
    onChange(selectedDays: LocalDate[]): void;
};

export const MultipleDatePicker: FC<MultipleDatePickerProps> = ({ selectedDays: defaultValues, onChange = () => {}, onClose = () => {}, ...rest }) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState<boolean>(false);
    const [selectedDays, setSelectedDays] = useState<LocalDate[]>(defaultValues);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const onChangeDates = (dates: LocalDate[]) => {
        setSelectedDays(dates);
        onChange(dates);
    };

    const handleClose = () => {
        setOpen(false);
        onClose(selectedDays);
    };

    return (
        <>
            <TextField
                placeholder={t('multiple_date_picker.place_holder', { defaultValue: 'Select Dates' })}
                onClick={() => setOpen(true)}
                inputProps={{ readOnly: true }}
                value={selectedDays.map(date => formatInDefaultDate(new Date(date))).join(', ')}
                aria-label={'selectedDates'}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='start'>
                            <Calendar03Icon />
                        </InputAdornment>
                    ),
                }}
            />
            <DialogWrapper header={t('multiple_date_picker.title', { defaultValue: 'Title' })} open={open} onClose={() => handleClose()} maxWidth={'sm'}>
                <Stack direction={isMobile ? 'column' : 'row'}>
                    <MultipleDatePickerCalendar selectedDays={selectedDays} onChange={onChangeDates} {...rest} />
                    <DatesDisplay selectedDays={selectedDays} onChange={onChangeDates} />
                </Stack>
                <DialogActions sx={{ paddingTop: isMobile ? '' : '0' }}>
                    <Button onClick={handleClose} color='primary'>
                        {t('multiple_date_picker.confirm')}
                    </Button>
                </DialogActions>
            </DialogWrapper>
        </>
    );
};

const MultipleDatePickerCalendar: FC<
    Omit<MultipleDatePickerProps, 'onChange'> & {
        onChange(selectedDays: LocalDate[]): void;
    }
> = props => {
    const [keyToRefresh, setKeyToRefresh] = useState<number>(0);
    const { minDate, maxDate, selectedDays, onChange } = props;

    const onChangeDate = (newValue: Date | undefined) => {
        const copySelectedDays = [...selectedDays];
        const date = newValue ? formatToLocalDate(new Date(newValue)) : undefined;
        const index = selectedDays.findIndex(item => item === date);
        if (index === undefined) {
            return;
        }
        if (index >= 0) {
            //remove the date from the array
            copySelectedDays.splice(index, 1);
        } else if (date) {
            //add the date to the array
            copySelectedDays.push(date);
        }
        onChange(copySelectedDays);
        //after changing the array we need to refresh the calendar so that the selected day stops being selected (to be possible to unselect the selected day)
        setKeyToRefresh(keyToRefresh + 1);
    };

    const selectedDayComponent = (props: PickersDayProps<Date>) => <SelectedDay {...props} selectedDays={selectedDays} />;

    const transformedSelectedDays = selectedDays.map(date => new Date(date));

    return (
        <StaticDatePicker<Date>
            sx={{ marginTop: 0 }}
            key={keyToRefresh} //This is used to be able to unselect the day after selecting it
            referenceDate={transformedSelectedDays[transformedSelectedDays.length - 1]} // this is used to keep the same month after there is a change - we might be able to improve this with the last selected date
            minDate={minDate}
            maxDate={maxDate}
            value={minDate}
            onChange={newValue => {
                //The newValue it's actually a Date object
                const newDate = newValue ? (newValue as unknown as Date) : undefined;
                onChangeDate(newDate);
            }}
            slotProps={{
                calendarHeader: { sx: { marginTop: 0 } },
            }}
            slots={{
                day: selectedDayComponent,
                toolbar: () => undefined, //remove the toolbar
                actionBar: () => undefined, //remove the action bar
            }}
        />
    );
};

type CustomPickersDayProps = PickersDayProps<Date> & {
    selectedDays: LocalDate[];
};

//highlight the dates in selectedDays array
const SelectedDay: FC<CustomPickersDayProps> = props => {
    const { selectedDays = [], ...rest } = props;

    const isSelected = !props?.outsideCurrentMonth && props.day && selectedDays.includes(formatToLocalDate(props.day));

    return <PickersDay {...rest} selected={isSelected} />;
};

const DatesDisplay: FC<DatesDisplayProps> = ({ selectedDays, onChange }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const onDelete = (index: number) => {
        const copySelectedDays = [...selectedDays];
        copySelectedDays.splice(index, 1);
        onChange(copySelectedDays);
    };

    return (
        <Stack direction={'column'} maxHeight={'270px'} minWidth={'250px'} mr={isMobile ? 1.2 : 0} ml={isMobile ? 1.2 : 0}>
            <Stack direction={'row'} justifyContent='space-between'>
                <Typography variant='h3bold'>{t('multiple_date_picker.selected_dates', { defaultValue: 'Selected Dates' })}</Typography>
                {selectedDays.length !== 0 && (
                    <Typography variant='h3bold' pr={1} aria-label={'numberOfSelectedDates'}>
                        {selectedDays.length}
                    </Typography>
                )}
            </Stack>
            <Stack
                overflow={'hidden'}
                pt={1}
                sx={{
                    overflowY: 'auto',
                }}
                width={`calc(100% + 8px)`} //To align the items when the scrollbar is displayed
            >
                {selectedDays.map((date, index) => (
                    <Stack key={date} justifyContent={'space-between'} direction='row' alignItems={'center'}>
                        <Typography variant='body1'>{formatInDefaultDate(new Date(date))}</Typography>
                        <IconButton name={`${index}_delete`} onClick={() => onDelete(index)} aria-label={'removeDateIcon'}>
                            <Cancel01Icon color={'red'} />
                        </IconButton>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};
