import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { FC } from 'react';
import { ArrowRight01Icon } from 'hugeicons-react';

const useStyles = makeStyles(theme => ({
    disabledBreadcrumb: {
        color: '#B8B8BF',
    },
    breadcrumb: {
        color: 'inherit',
    },
    activeBreadcrumb: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
}));

type Props = {
    activeStep: number;
    steps: string[];
};

export const WizardBreadcrumbs: FC<Props> = ({ activeStep, steps }) => {
    const classes = useStyles();

    return (
        <Breadcrumbs aria-label='breadcrumb' separator={<ArrowRight01Icon fontSize='small' />}>
            {steps.map((step, index) => (
                <Typography key={`wizard_${index}`} color='textPrimary' className={`${activeStep === index ? classes.activeBreadcrumb : ''}`}>
                    {step}
                </Typography>
            ))}
        </Breadcrumbs>
    );
};
