import { FC } from 'react';
import { TimeFilterType, TimeRangeType } from '@/Components/filters-bar/FiltersBar';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { getDateFromTimeFormat, getTimeFormatFromDate, isValidDate } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControlLabel, Stack } from '@mui/material';
import { TimeFieldWrapper } from '@/Components/time-field-wrapper/TimeFieldWrapper';
import * as yup from 'yup';
import i18next from 'i18next';

export const TimeFilter: FC<{ filter: TimeFilterType; onFilterUpdated: (filter: TimeFilterType) => void }> = ({ filter, onFilterUpdated }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<TimeFilterValues>({
        defaultValues: {
            start: getDateFromTimeFormat(filter.value?.[0]) ?? undefined,
            end: getDateFromTimeFormat(filter.value?.[1]) ?? undefined,
        },
        resolver: yupResolver(getTimeFilterSchema()),
    });

    const onSubmit = (values: TimeFilterValues) => {
        const startTime = getTimeFormatFromDate(values.start);
        const endTime = getTimeFormatFromDate(values.end);

        const timeRange: TimeRangeType | undefined = startTime || endTime ? [startTime, endTime] : undefined;
        const updatedFilter = { ...filter, value: timeRange };
        onFilterUpdated(updatedFilter);
    };

    return (
        <Stack gap={2} p={1}>
            <Stack gap={2} direction={'row'} alignItems={'flex-start'}>
                <Controller
                    name={'start'}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControlLabel
                            label={t('general.start')}
                            control={
                                <TimeFieldWrapper
                                    {...field}
                                    slotProps={{
                                        textField: {
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            }
                            sx={{ flex: 1 }}
                        />
                    )}
                />

                <Controller
                    name={'end'}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControlLabel
                            label={t('general.end')}
                            control={
                                <TimeFieldWrapper
                                    {...field}
                                    slotProps={{
                                        textField: {
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            }
                            sx={{ flex: 1 }}
                        />
                    )}
                />
            </Stack>
            <Button onClick={handleSubmit(onSubmit, console.error)} variant={'text'} sx={{ alignSelf: 'end' }}>
                {t('general.submit')}
            </Button>
        </Stack>
    );
};

const getTimeFilterSchema = () => {
    const dateSchema = yup
        .date()
        .test({
            message: i18next.t('general.validations.valid_date'),
            test: date => {
                return date ? isValidDate(date) : true;
            },
        })
        .required();

    return yup.object().shape({
        start: dateSchema,
        end: dateSchema.min(yup.ref('start'), i18next.t('general.validations.end_after_start')),
    });
};
type TimeFilterValues = yup.InferType<ReturnType<typeof getTimeFilterSchema>>;
