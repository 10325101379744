import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { DocumentType } from '@/domain/document/Document.model';
import { MoreVerticalIcon } from '@/assets/Icons/Icons';

type Props = {
    documentType: DocumentType;
    onDeleteClicked?: () => void;
    onDownloadClicked: () => void;
    onPreviewClicked: () => void;
    onEditClicked?: () => void;
};

export const DocumentMoreButton: FC<Props> = ({ documentType, onDeleteClicked, onDownloadClicked, onPreviewClicked, onEditClicked }) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const { t } = useTranslation();

    return (
        <>
            <Menu
                open={popupVisible}
                id='user-menu'
                anchorEl={anchorEl}
                keepMounted
                onClose={(event: Event) => {
                    event.stopPropagation();
                    setPopupVisible(false);
                }}
                anchorOrigin={{ vertical: 30, horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {documentType === 'DOCUMENT' && (
                    <MenuItem
                        dense={true}
                        onClick={event => {
                            event.stopPropagation();
                            setPopupVisible(false);
                            onPreviewClicked();
                        }}
                    >
                        {t('general.preview')}
                    </MenuItem>
                )}
                {onEditClicked && (
                    <MenuItem
                        dense={true}
                        onClick={event => {
                            event.stopPropagation();
                            setPopupVisible(false);
                            onEditClicked();
                        }}
                    >
                        {t('general.edit')}
                    </MenuItem>
                )}
                <MenuItem
                    dense={true}
                    onClick={event => {
                        event.stopPropagation();
                        setPopupVisible(false);
                        onDownloadClicked();
                    }}
                >
                    {documentType === 'LINK' ? t('general.open_in_new_tab') : t('general.download')}
                </MenuItem>
                {onDeleteClicked && (
                    <MenuItem
                        dense={true}
                        onClick={event => {
                            event.stopPropagation();
                            setPopupVisible(false);
                            onDeleteClicked();
                        }}
                    >
                        {t('general.delete')}
                    </MenuItem>
                )}
            </Menu>
            <IconButton
                size='small'
                onClick={event => {
                    event.preventDefault();
                    setAnchorEl(event.currentTarget);
                    event.stopPropagation();
                    setPopupVisible(true);
                }}
            >
                <MoreVerticalIcon />
            </IconButton>
        </>
    );
};
