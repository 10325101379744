import { Employee } from '@/domain/employee/Employee.model';
import { SectionContainer } from '@/page/people/on-boarding-form/SectionContainer';
import { getNull } from '@/utils/object.util';
import { Autocomplete, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FieldDate } from '@/Components/form/field-date/FieldDate';
import { FieldSwitch } from '@/Components/form/field-switch/FieldSwitch';
import { ReviewTemplate } from '@/domain/review-template/ReviewTemplate.model';
import { OffboardingAllStepFormValues } from '@/page/people/OffboardingPage';
import { getLabelTranslation } from '@/utils/language.util';

type ReviewFormProps = {
    employeeId?: number;
    templates: ReviewTemplate[];
    allEmployees: Employee[];
};
export const ReviewStepForm: FC<ReviewFormProps> = ({ employeeId, templates, allEmployees }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<OffboardingAllStepFormValues>();

    const employeeOptions = allEmployees.filter(e => e.id !== employeeId);
    const organizeMeetingValue = watch('organizeMeeting');

    return (
        <SectionContainer title={t('offboarding_form.review_step.section_title')}>
            <Stack gap={2}>
                <FormControlLabel
                    label={t('offboarding_form.review_step.organize_meeting_label')}
                    labelPlacement='end'
                    control={<FieldSwitch name='organizeMeeting' control={control} />}
                />
                {organizeMeetingValue && (
                    <>
                        <Stack direction={'row'} alignItems={'center'} gap={3}>
                            <Typography variant='body1bold' width={'200px'} noWrap>
                                {t('offboarding_form.review_step.review_template_label')}
                            </Typography>
                            <Controller
                                name={`review.reviewTemplate`}
                                control={control}
                                render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                    <Autocomplete
                                        value={value ?? getNull()}
                                        fullWidth
                                        disabled={!organizeMeetingValue}
                                        onChange={(_, options) => {
                                            onChange(options);
                                        }}
                                        options={templates.map(t => ({ id: t.id, name: getLabelTranslation(t.name) }))}
                                        getOptionLabel={option => option.name ?? ''}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={params => <TextField {...restField} {...params} error={!!error} helperText={error?.message} />}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} gap={3}>
                            <Typography variant='body1bold' width={'200px'} noWrap>
                                {t('offboarding_form.review_step.trigger_on_label')}
                            </Typography>
                            <FieldDate name='review.startDate' control={control} disabled={!organizeMeetingValue} />
                        </Stack>
                        <Stack direction={'row'} gap={3}>
                            <Typography variant='body1bold' width={'200px'} noWrap>
                                {t('offboarding_form.review_step.due_date_label')}
                            </Typography>

                            <FieldDate name='review.endDate' control={control} disabled={!organizeMeetingValue} />
                        </Stack>
                        <Stack direction={'row'} gap={3}>
                            <Typography variant='body1bold' width={'200px'} noWrap>
                                {t('offboarding_form.review_step.reviewer_label')}
                            </Typography>
                            <Controller
                                name={`review.manager`}
                                control={control}
                                render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                    <Autocomplete
                                        value={value ?? getNull()}
                                        fullWidth
                                        disabled={!organizeMeetingValue}
                                        onChange={(_, value) => {
                                            onChange(value);
                                        }}
                                        options={employeeOptions}
                                        getOptionLabel={option => option?.displayName ?? ''}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={params => <TextField {...restField} {...params} error={!!error} helperText={error?.message} />}
                                    />
                                )}
                            />
                        </Stack>
                    </>
                )}
            </Stack>
        </SectionContainer>
    );
};
