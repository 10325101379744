import { StateHandler } from '@/Components/state-handler/StateHandler';
import {
    IntegrationType,
    ThirdParty,
    ThirdPartyIntegration,
    ThirdPartyMutation,
    ThirdPartyPublicApiIntegration,
    ThirdPartyPublicApiIntegrationScope,
} from '@/domain/third-party/ThirdParty.model';
import { createThirdParty, updateThirdParty } from '@/domain/third-party/ThirdParty.service';
import { useGetThirdParty } from '@/hooks/third-party/ThirdParty.hook';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer } from '@/page/layout/Footer';
import { ThirdPartyApiKeyDialog } from '@/page/setting/third-party/third-party-api-key-dialog/ThirdPartyApiKeyDialog';
import { ThirdPartyConnectorDialog, ThirdPartyConnectorFormValues } from '@/page/setting/third-party/third-party-connector-dialog/ThirdPartyConnectorDialog';
import { ThirdPartyConnectorLogDialog } from '@/page/setting/third-party/third-party-connector-log-dialog/ThirdPartyConnectorLogDialog';
import { ThirdPartyConnectorsGrid } from '@/page/setting/third-party/third-party-connectors-grid/ThirdPartyConnectorsGrid';
import { handleError } from '@/utils/api.util';
import { getLocalDateTestConfig, LocalDate, toDate } from '@/utils/datetime.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, FormControlLabel, Paper, Stack, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

export const ThirdPartySettingPage: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const thirdPartyId = params.thirdPartyId ? Number(params.thirdPartyId) : undefined;

    const { data: thirdParty, isLoading, isError, error } = useGetThirdParty(thirdPartyId);

    const goToDetail = (id: number) => {
        navigate(`/settings/third-parties/${id}`);
    };

    const handleSave = async ({ publicApiIntegration, integrations, ...restData }: ThirdPartyFormValues): Promise<ThirdParty | undefined> => {
        // Convert in mutation format and remove null values
        const data: ThirdPartyMutation = {
            ...restData,
            integrations: integrations.map(i => ({ ...i, id: i.id ?? undefined })),
            publicApiIntegration: publicApiIntegration
                ? {
                      ...publicApiIntegration,
                      expireAt: toDate(publicApiIntegration.expireAt),
                  }
                : undefined,
        };

        try {
            if (thirdParty) {
                // We are returning the updated third party to get the id to generate the api key
                return await updateThirdParty(thirdParty.id, data);
            } else {
                const thirdPartyCreated = await createThirdParty(data);
                goToDetail(thirdPartyCreated.id);
                return thirdPartyCreated;
            }
        } catch (e) {
            handleError(e);
        }
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <ThirdPartySettingForm thirdParty={thirdParty} onSave={handleSave} />
        </StateHandler>
    );
};

const scopeSchema = yup.string().required().oneOf(Object.values(ThirdPartyPublicApiIntegrationScope));

const thirdPartySchema = yup.object().shape({
    name: yup.string().trim().required(),
    publicApiIntegration: yup
        .object()
        .default(getNull())
        .shape({
            id: yup.number().nullable().default(getNull()),
            scopes: yup.array().of(scopeSchema).required(),
            expireAt: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        })
        .nullable(),
    integrations: yup
        .array()
        .default([])
        .required()
        .of(
            yup.object().shape({
                id: yup.number().nullable().default(getNull()),
                configuration: yup.string().required(),
                integrationType: yup.string().required().oneOf(Object.values(IntegrationType)),
            }),
        ),
});

export type ThirdPartyFormValues = yup.InferType<typeof thirdPartySchema>;

export type ThirdPartyConnector =
    | (Pick<ThirdPartyPublicApiIntegration, 'scopes' | 'expireAt'> & { id: number | null })
    | (Pick<ThirdPartyIntegration, 'configuration' | 'integrationType'> & { id: number | null });

export const ThirdPartySettingForm: FC<{
    thirdParty: ThirdParty | undefined;
    onSave: (data: ThirdPartyFormValues) => Promise<ThirdParty | undefined>;
}> = ({ thirdParty, onSave }) => {
    const { t } = useTranslation();

    const [createConnectorDialog, setCreateConnectorDialog] = useState(false);
    const [updateConnectorDialog, setUpdateConnectorDialog] = useState<ThirdPartyConnector>();
    const [logConnectorDialog, setLogConnectorDialog] = useState<ThirdPartyConnector>();
    const [generateApiKeyDialog, setGenerateApiKeyDialog] = useState(false);

    const { control, handleSubmit, setValue, watch } = useForm<ThirdPartyFormValues>({
        resolver: yupResolver(thirdPartySchema),
        defaultValues: {
            name: thirdParty?.name ?? '',
            publicApiIntegration: thirdParty?.publicApiIntegration?.id
                ? {
                      id: thirdParty.publicApiIntegration.id ?? getNull(),
                      scopes: thirdParty.publicApiIntegration?.scopes ?? [],
                      expireAt: thirdParty.publicApiIntegration?.expireAt,
                  }
                : getNull(),
            integrations: thirdParty?.integrations ?? [],
        },
    });

    const publicApiIntegration = watch('publicApiIntegration');

    const integrations = watch('integrations');

    // Merge public api integration with integrations to have a single array
    const connectors: ThirdPartyConnector[] = [publicApiIntegration, ...integrations].filter(connector => connector !== getNull());

    const publicApiIntegrationExisting = !!publicApiIntegration;

    const handleApiIntegrationSave = async (connector: ThirdPartyConnectorFormValues) => {
        if (!connector.expireAt) {
            throw new Error('Expire date is required');
        }
        const newPublicApiIntegration: ThirdPartyFormValues['publicApiIntegration'] = {
            id: publicApiIntegration?.id ?? getNull(),
            scopes: connector.scopes,
            expireAt: connector.expireAt,
        };

        // It's weird behavior but after touching a connector we want to automatically save the connector in the third party
        const response = await onSave({
            name: thirdParty?.name ?? '',
            integrations: thirdParty?.integrations ?? [],
            publicApiIntegration: newPublicApiIntegration,
        });

        // It's a new public api integration and we want to generate the api key
        if (!publicApiIntegration?.id && response?.publicApiIntegration?.id) {
            setGenerateApiKeyDialog(true);
            // We update the public api integration with the id to avoid double key generation after an other save
            setValue('publicApiIntegration', { ...newPublicApiIntegration, id: response?.publicApiIntegration?.id });
        } else {
            setValue('publicApiIntegration', newPublicApiIntegration);
        }
    };

    const handleConnectorSave = async (connector: ThirdPartyConnectorFormValues) => {
        if (connector.type === 'PUBLIC_API') {
            handleApiIntegrationSave(connector);
        } else {
            // For now we are using connector type as unique identifier
            // If we have a connector with the same type we update it
            // Otherwise we add a new one
            const previousConnector = integrations.find(c => c.integrationType === connector.type);

            // Could be an update or a new connector
            const newConnector: ThirdPartyFormValues['integrations'][0] = {
                id: previousConnector?.id ?? getNull(),
                configuration: connector.configuration ?? '',
                integrationType: connector.type,
            };

            // If we have a previous connector we update it
            // Otherwise we add a new one at the end
            const newIntegrations = previousConnector ? integrations.map(c => (c === previousConnector ? newConnector : c)) : [...integrations, newConnector];

            setValue('integrations', newIntegrations);

            // It's weird behavior but after touching a connector we want to automatically save the connector in the third party
            await onSave({
                name: thirdParty?.name ?? '',
                integrations: newIntegrations,
                publicApiIntegration: publicApiIntegration,
            });
        }
    };

    const handleCreate = (connector: ThirdPartyConnectorFormValues) => {
        handleConnectorSave(connector);
        setCreateConnectorDialog(false);
    };

    const handleUpdate = (connector: ThirdPartyConnectorFormValues) => {
        handleConnectorSave(connector);
        setUpdateConnectorDialog(undefined);
    };

    const handleDeleteConnector = (connector: ThirdPartyConnector) => {
        if ('scopes' in connector) {
            setValue('publicApiIntegration', getNull());
        } else {
            setValue(
                'integrations',
                integrations.filter(c => c.integrationType !== connector.integrationType),
            );
        }
    };

    return (
        <>
            <Stack component={ContentContainer} flex={1} gap={2}>
                <Stack component={Paper} gap={2} p={3}>
                    <Typography variant='body1bold'>{t('third_party_setting_page.dialog.title')}</Typography>
                    <Controller
                        name='name'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FormControlLabel
                                aria-label={'third-party-name'}
                                label={t('third_party_setting_page.dialog.name_input')}
                                labelPlacement='top'
                                control={<TextField fullWidth {...field} error={!!error} helperText={error?.message} />}
                            />
                        )}
                    />
                </Stack>

                {/* We want to handle connectors only if third party is existing */}
                {thirdParty && (
                    <Stack component={Paper} flex={1} gap={2} p={3}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Typography variant='body1bold'>{t('third_party_setting_page.dialog.connectors')}</Typography>
                            <Button onClick={() => setCreateConnectorDialog(true)} size='small'>
                                {t('third_party_setting_page.dialog.add_connector')}
                            </Button>
                            {createConnectorDialog && (
                                <ThirdPartyConnectorDialog
                                    onSave={handleCreate}
                                    open
                                    onClose={() => setCreateConnectorDialog(false)}
                                    publicApiOptionDisabled={publicApiIntegrationExisting}
                                />
                            )}
                        </Stack>

                        {!!connectors?.length && (
                            <ThirdPartyConnectorsGrid
                                connectors={connectors}
                                onDelete={handleDeleteConnector}
                                onClickShowLog={setLogConnectorDialog}
                                onRowClicked={setUpdateConnectorDialog}
                            />
                        )}
                        {updateConnectorDialog && (
                            <ThirdPartyConnectorDialog
                                onSave={handleUpdate}
                                connector={updateConnectorDialog}
                                open
                                onClose={() => setUpdateConnectorDialog(undefined)}
                                onGenerateApiKey={() => setGenerateApiKeyDialog(true)}
                            />
                        )}

                        {logConnectorDialog && (
                            <ThirdPartyConnectorLogDialog connector={logConnectorDialog} open onClose={() => setLogConnectorDialog(undefined)} />
                        )}

                        {generateApiKeyDialog && <ThirdPartyApiKeyDialog thirdPartyId={thirdParty.id} open onClose={() => setGenerateApiKeyDialog(false)} />}
                    </Stack>
                )}
            </Stack>
            <Footer>
                <LoadingButton onClick={handleSubmit(onSave, console.error)} variant='contained' color='primary'>
                    {t(thirdParty ? 'general.update' : 'general.create')}
                </LoadingButton>
            </Footer>
        </>
    );
};
