import {
    DatePicker as MuiDatePicker,
    DatePickerProps as MuiDatePickerProps,
    LocalizationProvider,
    MobileDatePicker as MuiMobileDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ForwardedRef, forwardRef } from 'react';

import { getLocale, getUserLanguage } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { Calendar03Icon } from 'hugeicons-react';
import { MAX_DATE, MIN_DATE } from '@/utils/datetime.util';

export type DatePickerProps = MuiDatePickerProps<Date> & {
    mobileOnly?: boolean;
    name?: string;
};

const defaultProps: DatePickerProps = {
    format: 'dd.MM.yyyy',
    closeOnSelect: true,
    minDate: MIN_DATE,
    maxDate: MAX_DATE,
};

const defaultSlotProps: DatePickerProps['slotProps'] = {
    toolbar: {
        hidden: true,
    },
    actionBar: {
        actions: [],
    },
    dialog: {
        maxWidth: false,
        fullWidth: false,
        // all dialogs are displayed in the top of the screen on mobile
        // but we want the date picker to be centered
        sx: {
            '& .MuiDialog-container': {
                alignItems: 'center',
            },
        },
    },
};

export const DatePickerWrapper = forwardRef<ForwardedRef<HTMLDivElement>, DatePickerProps>(function DatePicker(
    { mobileOnly = false, value, slotProps, slots, ...rest },
    ref,
) {
    const userLanguage = getUserLanguage();
    const locale = getLocale(userLanguage);

    const currentValue = value ? new Date(value) : getNull();
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            {mobileOnly ? (
                <MuiMobileDatePicker
                    value={currentValue}
                    {...defaultProps}
                    slots={slots}
                    slotProps={{
                        ...defaultSlotProps,
                        ...slotProps,
                        textField: {
                            inputRef: ref,
                            fullWidth: true,
                            ...slotProps?.textField,
                            inputProps: {
                                'aria-label': rest.name,
                            },
                        },
                    }}
                    {...rest}
                />
            ) : (
                <MuiDatePicker
                    value={currentValue}
                    {...defaultProps}
                    slots={{
                        openPickerIcon: Calendar03Icon,
                        ...slots,
                    }}
                    slotProps={{
                        ...defaultSlotProps,
                        ...slotProps,
                        textField: {
                            inputRef: ref,
                            fullWidth: true,
                            ...slotProps?.textField,
                            inputProps: {
                                'aria-label': rest.name,
                            },
                        },
                    }}
                    {...rest}
                />
            )}
        </LocalizationProvider>
    );
});
