import { FlatTreeNode } from '@/Components/autocomplete-wrapper/TreeAutoComplete/useTreeAutoComplete';
import { TranslatableLabelInput } from '@/Components/translatable-label-input/TranslatableLabelInput';
import { Department, DepartmentCreationMutation, DepartmentNode } from '@/domain/department/Department.model';
import { createDepartment, deleteDepartment, updateDepartment } from '@/domain/department/Department.service';
import { mapDepartmentNodesListToDepartments, mapDepartmentNodesListToTreeNodes, mapDepartmentNodeToTreeNodes } from '@/domain/department/Department.utils';
import { Label } from '@/domain/label/Label.model';
import { isLabelUnique } from '@/domain/label/Label.service';
import { useGetDepartmentNodes } from '@/hooks/department/Department.hook';
import { DepartmentDialog } from '@/page/setting/organization/department/department-dialog/DepartmentDialog';
import { DepartmentFormValues, getDepartmentFormSchema } from '@/page/setting/organization/department/department-dialog/DepartmentDialog.schema';
import { CustomTreeViewBaseItem, DepartmentTreeItem } from '@/page/setting/organization/department/department-tree/DepartmentTreeItem';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation, getLocalizedErrorMessage, UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { RichTreeView } from '@mui/x-tree-view';
import { AddSquareIcon, RemoveSquareIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const DepartmentsTree: FC<{ translationLanguage: UserLanguage }> = ({ translationLanguage }) => {
    const [parentIdToAdd, setParentIdToAdd] = useState<number>();
    const [departmentToUpdate, setDepartmentToUpdate] = useState<DepartmentNode>();
    const { t } = useTranslation();

    // Fetching data
    const { data: departmentNodes = [], refetch: refetchDepartmentsNodes } = useGetDepartmentNodes();
    const flatDepartments = mapDepartmentNodesListToDepartments(departmentNodes);
    const items = mapHierarchyToTreeItems(departmentNodes, translationLanguage);
    const departmentFlatTreeNodes = mapDepartmentNodesListToTreeNodes(departmentNodes, translationLanguage);

    // Handle actions click
    const handleAddNodeClick = (department: DepartmentNode) => {
        setParentIdToAdd(department.id);
    };

    const handleEditNodeClick = (department: DepartmentNode) => {
        setDepartmentToUpdate(department);
    };

    const handleDeleteNodeClick = (department: DepartmentNode) => {
        handleSubmitDelete(department.id).catch(handleError);
    };

    // Submitting data
    const handleSubmitAdd = async (departmentFormValue: DepartmentFormValues) => {
        const mutation = { ...mapDepartmentFormValuesToMutation(departmentFormValue), order: 0 };

        const filteredLabels = getLabelsByDepartmentsAndParent(flatDepartments, mutation.parentId);

        if (!isLabelUnique(mutation.name, filteredLabels)) {
            showSnackbar(t('settings_organization.departments.unique_name_error'), 'error');
            return;
        }
        try {
            await createDepartment(mutation);
            setParentIdToAdd(undefined);
            refetchDepartmentsNodes().catch(handleError);
            reset();
        } catch (error) {
            handleError(error);
        }
    };

    const handleSubmitUpdate = async (department: DepartmentNode, departmentFormValue: DepartmentFormValues) => {
        const mutation = { ...mapDepartmentFormValuesToMutation(departmentFormValue), order: department.order };

        const filteredLabels = getLabelsByDepartmentsAndParent(
            flatDepartments.filter(d => department.id !== d.id),
            mutation.parentId,
        );

        if (!isLabelUnique(departmentFormValue.name, filteredLabels)) {
            showSnackbar(t('settings_organization.departments.unique_name_error'), 'error');
            return;
        }
        try {
            await updateDepartment(department.id, mutation);
            setDepartmentToUpdate(undefined);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const handleSubmitDelete = async (departmentId: DepartmentNode['id']) => {
        try {
            await deleteDepartment(departmentId);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    // Form to add department at the root
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(getDepartmentFormSchema(translationLanguage)),
    });

    // In options list, disable the department provided in the second argument and its children
    // to avoid choosing them as parent
    const disableOptions = (options: readonly FlatTreeNode[], toFilter: DepartmentNode) => {
        const flatten = mapDepartmentNodeToTreeNodes(toFilter, undefined, translationLanguage);
        return options.map(option =>
            flatten.map(d => d.id).includes(option.id)
                ? {
                      ...option,
                      disabled: true,
                  }
                : option,
        );
    };

    return (
        <Stack gap={3} mt={2}>
            {/* FORM TO ADD DEPARTMENT */}
            <Stack direction={'row'} alignItems={'flex-start'} gap={2}>
                <Controller
                    name={'name'}
                    control={control}
                    render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                        <Stack flex='1'>
                            <TranslatableLabelInput
                                fullWidth
                                translationLanguage={translationLanguage}
                                onChange={onChange}
                                value={value}
                                InputProps={{
                                    placeholder: t('settings_organization.departments.add_dialog.name_field'),
                                }}
                                error={!!error}
                                helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                {...restField}
                            />
                        </Stack>
                    )}
                />
                <Button
                    onClick={() => {
                        handleSubmit((data: DepartmentFormValues) => {
                            handleSubmitAdd(data).catch(handleError);
                        }, console.error)();
                    }}
                >
                    {t('general.submit')}
                </Button>
            </Stack>

            {/* DEPARTMENT TREE */}
            <RichTreeView
                items={items}
                slots={{
                    expandIcon: AddSquareIcon,
                    collapseIcon: RemoveSquareIcon,
                    item: DepartmentTreeItem,
                }}
                slotProps={{
                    expandIcon: { width: 16, height: 16 },
                    collapseIcon: { width: 16, height: 16 },
                    item: ownerState => {
                        return {
                            ...ownerState,
                            onAddClick: handleAddNodeClick,
                            onEditClick: handleEditNodeClick,
                            onDeleteClick: handleDeleteNodeClick,
                        };
                    },
                }}
            />

            {/* DIALOGS */}
            {parentIdToAdd && (
                <DepartmentDialog
                    open={true}
                    onClose={() => setParentIdToAdd(undefined)}
                    departmentOptions={departmentFlatTreeNodes}
                    defaultParentId={parentIdToAdd}
                    translationLanguage={translationLanguage}
                    onSubmitDepartment={handleSubmitAdd}
                />
            )}
            {departmentToUpdate && (
                <DepartmentDialog
                    open={true}
                    onClose={() => setDepartmentToUpdate(undefined)}
                    departmentOptions={disableOptions(departmentFlatTreeNodes, departmentToUpdate)}
                    defaultDepartment={departmentToUpdate}
                    translationLanguage={translationLanguage}
                    onSubmitDepartment={(data: DepartmentFormValues) => handleSubmitUpdate(departmentToUpdate, data)}
                />
            )}
        </Stack>
    );
};

const mapDepartmentFormValuesToMutation = (departmentFormValue: DepartmentFormValues): Omit<DepartmentCreationMutation, 'order'> => {
    const { name, parent, managers, departmentCostCenters } = departmentFormValue;
    return {
        name,
        parentId: parent?.id,
        managerIds: managers.map(m => m.id),
        departmentCostCenters: departmentCostCenters.map(dcc => ({
            costCenterId: dcc.costCenter.id,
            percentage: dcc.percentage,
        })),
    };
};

const mapHierarchyToTreeItems = (departments: DepartmentNode[], translationLanguage: UserLanguage): CustomTreeViewBaseItem[] => {
    return [...departments]
        .sort((d1, d2) => (d1.order >= d2.order ? 1 : -1))
        .map(department => {
            return {
                id: department.id.toString(),
                label: getLabelTranslation(department.name, translationLanguage),
                children: mapHierarchyToTreeItems(department.children, translationLanguage),
                originalData: department,
            };
        });
};

const getLabelsByDepartmentsAndParent = (departments: Department[], parentId: number | undefined): Label[] => {
    return departments.filter(d => (!d.parentId ? !parentId : parentId === d.parentId)).map(d => d.name);
};
