import './I18n/i18n';
import './index.css';

import { Environment, getAppConfig, getAppEnv, getCurrentAppVersion } from '@/config/config';
import { createRoot } from 'react-dom/client';

import Bugsnag, { BreadcrumbType } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { AppEntryPoint } from '@/app-entry-point/AppEntryPoint';
import { Button, Stack, Typography } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import React from 'react';

const config = getAppConfig();
const appEnv = getAppEnv();

const bugsnagApiKey = appEnv === Environment.PROD ? config.BUGSNAG.API_KEY : config.BUGSNAG.DEV_API_KEY;
const appVersion = getCurrentAppVersion();
const enabledReleaseStages = [Environment.PROD, Environment.PREPROD, Environment.STAGING, Environment.TEST];
const prodBreadcrumbTypes: BreadcrumbType[] = ['error', 'manual', 'navigation', 'process', 'request', 'state', 'user'];
const otherBreadcrumbTypes: BreadcrumbType[] = [...prodBreadcrumbTypes, 'log'];

// eslint-disable-next-line react-refresh/only-export-components
export const bugsnagOptions = {
    appType: 'client',
    apiKey: bugsnagApiKey,
    releaseStage: appEnv,
    enabledReleaseStages,
    appVersion,
    enabledBreadcrumbTypes: appEnv === Environment.PROD ? prodBreadcrumbTypes : otherBreadcrumbTypes,
};

// configure Bugsnag
Bugsnag.start({
    ...bugsnagOptions,
    plugins: [new BugsnagPluginReact()],
});

// eslint-disable-next-line react-refresh/only-export-components
export const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment;

export const ErrorView = ({ clearError }: { clearError: () => void }): JSX.Element => {
    return (
        <Stack gap={2} alignItems='center' justifyContent='center'>
            <Typography color='white'>An error occurred</Typography>
            <Button onClick={clearError} variant='outlined'>
                Retry
            </Button>
        </Stack>
    );
};

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = createRoot(rootElement);
    // Use to catch errors in the app throws before the router is mounted
    // Otherwise, the error will be caught by the router error boundary
    root.render(
        <ErrorBoundary FallbackComponent={ErrorView}>
            <AppEntryPoint />
        </ErrorBoundary>,
    );
}

// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', event => {
    console.error(event);
    window.location.reload();
});
