import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { handleError } from '@/utils/api.util';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { BasicMenu } from '@/Components/basic-menu/BasicMenu';

import { useAppSelector } from '@/stores/store';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { MoreVerticalIcon } from '@/assets/Icons/Icons';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { JobFamily, JobFamilyCreateMutation } from '@/domain/job-family/JobFamily.model';
import { useGetJobFamilies } from '@/hooks/job-family/JobFamily.hook';
import { createJobFamily, deleteJobFamily, updateJobFamily } from '@/domain/job-family/JobFamily.service';
import { ConfigType } from '../types';
import { JobFamilySettingsDialog } from '@/page/setting/job-family/job-family-dialog/JobFamilySettingsDialog';

export const JobFamilySettingsPage: FC = () => {
    const { t } = useTranslation();

    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [jobFamilyToEdit, setJobFamilyToEdit] = useState<JobFamily>();

    const { data: jobFamilies = [], isLoading, isError, error: errorJobFamilies, refetch: refetchJobFamilies } = useGetJobFamilies();
    const realm = useAppSelector(state => state.ui.currentRealm);

    if (!realm || !hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.JOB_FAMILIES)) {
        return;
    }

    const handleDelete = async (id: number) => {
        try {
            await deleteJobFamily(id);
            await refetchJobFamilies();
        } catch (e) {
            handleError(e);
        }
    };

    const handleOnClose = () => {
        setOpenCreateDialog(false);
        setJobFamilyToEdit(undefined);
    };

    const handleOnSave = async (data: JobFamilyCreateMutation) => {
        try {
            if (jobFamilyToEdit?.id) {
                await updateJobFamily(jobFamilyToEdit.id, data);
            } else {
                await createJobFamily(data);
            }
            await refetchJobFamilies();
            handleOnClose();
        } catch (e) {
            handleError(e);
        }
    };

    const getMenuItems = (params: ICellRendererParams<JobFamily>) => {
        return [
            {
                title: t('general.edit'),
                onClick: () => {
                    setJobFamilyToEdit(params.data);
                },
            },
            {
                title: t('general.delete'),
                onClick: () => params.data?.id && handleDelete(params.data.id),
            },
        ];
    };
    const cellActionRenderer = (params: ICellRendererParams<JobFamily>) => {
        return <BasicMenu items={getMenuItems(params)} endIcon={<MoreVerticalIcon />} />;
    };

    const config: ConfigType<JobFamily> = {
        type: 'table',
        header: {
            primaryButtonCaption: t('general.create'),
            primaryButtonAction: () => {
                setOpenCreateDialog(true);
            },
        },
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: (params: RowClickedEvent<JobFamily>) => {
                    setJobFamilyToEdit(params.data);
                },
            },
            columns: [
                {
                    field: 'name',
                    headerName: t('job_family_setting_page.table_headers.name'),
                    width: 300,
                },
                {
                    type: 'actionMenu',
                    cellRenderer: cellActionRenderer,
                },
            ],
        },
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={errorJobFamilies}>
            <CompanySettingsLayout options={config} data={jobFamilies} />
            {openCreateDialog && <JobFamilySettingsDialog open={openCreateDialog} onSave={handleOnSave} closeDialog={handleOnClose} jobFamily={undefined} />}
            {jobFamilyToEdit && (
                <JobFamilySettingsDialog open={!!jobFamilyToEdit} onSave={handleOnSave} closeDialog={handleOnClose} jobFamily={jobFamilyToEdit} />
            )}
        </StateHandler>
    );
};
