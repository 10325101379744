import { DialogContainerProps } from '@/Components/dialog-container/DialogContainer';
import { DialogWrapper } from '@/Components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/Components/form/field-date/FieldDate';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { NUMBER_DAYS_BEFORE_EOT_REVIEW, ReviewType } from '@/domain/review/Review.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { useSearchReviewTemplates } from '@/hooks/review-template/ReviewTemplate.hook';
import { formatToLocalDate, getLocalDateTestConfig, LocalDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, DialogActions, DialogContent, FormControlLabel, Stack, TextField } from '@mui/material';
import { add } from 'date-fns';
import { t } from 'i18next';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

type PlannedReviewDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    title: string;
    onSuccess: SubmitHandler<PlannedReviewFormValues>;
    reviewType: ReviewType;
    currentEmployeeReview: EmployeeReview | undefined;
};

const schema = yup.object().shape({
    name: yup.string().required(),
    reviewTemplate: yup
        .object()
        .shape({
            label: yup.string().required(),
            value: yup.number().required(),
        })
        .required(),
    managers: yup
        .array()
        .of(
            yup.object().shape({
                label: yup.string().required(),
                value: yup.number().required(),
            }),
        )
        .required()
        .min(1),
    employee: yup
        .object()
        .shape({
            label: yup.string().required(),
            value: yup.number().required(),
        })
        .required(),
    reviewNotificationDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
    reviewEndDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
});

export type PlannedReviewFormValues = yup.InferType<typeof schema>;

export const PlannedReviewDialog: FC<PlannedReviewDialogProps> = ({ title, onSuccess, reviewType, currentEmployeeReview, ...rest }) => {
    const isEdit = !!currentEmployeeReview;

    const {
        data: reviewTemplates = [],
        isError: isReviewTemplatesError,
        isLoading: isReviewTemplatesLoading,
        error: reviewTemplatesError,
    } = useSearchReviewTemplates({
        reviewType: reviewType,
    });

    const { data: employees = [], error: employeesError, isError: isEmployeesError, isLoading: isEmployeesLoading } = useGetEmployees();
    const isError = isReviewTemplatesError || isEmployeesError;
    const isLoading = isReviewTemplatesLoading || isEmployeesLoading;
    const error = reviewTemplatesError || employeesError;

    const getDefaultValues = (currentReview: Partial<EmployeeReview> | undefined): Partial<PlannedReviewFormValues> => {
        if (!currentReview) {
            return {};
        }

        return {
            name: currentReview.review?.name,
            reviewTemplate: currentReview.review?.reviewTemplate?.name
                ? {
                      label: getLabelTranslation(currentReview.review.reviewTemplate.name),
                      value: currentReview.review.reviewTemplate.id,
                  }
                : undefined,
            managers: currentReview.managers?.length
                ? currentReview.managers.map(manager => ({
                      label: manager.reviewer.displayName,
                      value: manager.reviewer.id,
                  }))
                : [],
            employee: currentReview.employee
                ? {
                      label: currentReview.employee.displayName,
                      value: currentReview.employee.id,
                  }
                : undefined,
            reviewNotificationDate: currentReview.review?.startDate,
            reviewEndDate: currentReview.review?.endDate,
        };
    };

    const { handleSubmit, control, watch, setValue } = useForm<PlannedReviewFormValues>({
        resolver: yupResolver(schema),
        defaultValues: getDefaultValues(currentEmployeeReview),
    });

    const watchEmployee = watch('employee');
    const watchManagers = watch('managers') ?? [];

    // Use the selected employee to set the default value (notification date, etc.)
    useEffect(() => {
        if (watchEmployee) {
            const selectedEmployee = employees.find(employee => employee.id === watchEmployee.value);
            const probationEndDate = selectedEmployee?.currentEmployments?.find(employment => employment.principal)?.probationEndDate;
            if (probationEndDate) {
                const reviewNotificationDate = formatToLocalDate(add(probationEndDate, { days: NUMBER_DAYS_BEFORE_EOT_REVIEW }));
                setValue('reviewNotificationDate', reviewNotificationDate);
                setValue('reviewEndDate', probationEndDate);
            }
        }
    }, [employees, setValue, watchEmployee]);

    const reviewTemplateOptions = reviewTemplates.map(template => ({
        label: getLabelTranslation(template.name) ?? '',
        value: template.id,
    }));
    const employeeOptions = employees
        .map(employee => ({
            label: employee.displayName,
            value: employee.id,
        }))
        .filter(employee => employee.value !== watchEmployee?.value)
        .filter(employee => !watchManagers.some(manager => manager.value === employee.value));

    return (
        <DialogWrapper {...rest} header={title}>
            <DialogContent>
                <StateHandler isLoading={isLoading} isError={isError} error={error}>
                    <Stack gap={1}>
                        <Controller
                            name='employee'
                            control={control}
                            defaultValue={getNull() as unknown as { label: string; value: number }}
                            render={({ field: { onChange, ...restField }, fieldState }) => (
                                <FormControlLabel
                                    label={t('general.employee')}
                                    control={
                                        <Autocomplete
                                            disabled={isEdit}
                                            {...restField}
                                            onChange={(_, value) => onChange(value)}
                                            fullWidth
                                            options={employeeOptions}
                                            getOptionLabel={option => option.label}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                                        />
                                    }
                                />
                            )}
                        />
                        <Controller
                            name='name'
                            control={control}
                            defaultValue=''
                            render={({ field, fieldState }) => (
                                <FormControlLabel
                                    label={t('general.name')}
                                    control={
                                        <TextField disabled={isEdit} {...field} fullWidth error={!!fieldState.error} helperText={fieldState.error?.message} />
                                    }
                                />
                            )}
                        />
                        <Controller
                            name='reviewTemplate'
                            control={control}
                            defaultValue={getNull() as unknown as { label: string; value: number }}
                            render={({ field: { onChange, ...restField }, fieldState }) => (
                                <FormControlLabel
                                    label={t('general.template')}
                                    control={
                                        <Autocomplete
                                            {...restField}
                                            onChange={(_, value) => onChange(value)}
                                            fullWidth
                                            disabled={isEdit}
                                            options={reviewTemplateOptions}
                                            getOptionLabel={option => option.label}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                                        />
                                    }
                                />
                            )}
                        />

                        <FormControlLabel
                            label={t('reviews.new_review.trigger_on')}
                            control={<FieldLocalDate name='reviewNotificationDate' control={control} />}
                        />

                        <FormControlLabel label={t('reviews.new_review.end_date')} control={<FieldLocalDate name='reviewEndDate' control={control} />} />

                        <Controller
                            name={'managers'}
                            control={control}
                            render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                <FormControlLabel
                                    sx={{ width: '100%' }}
                                    label={t('reviews.new_review.managers')}
                                    control={
                                        <Autocomplete
                                            multiple
                                            fullWidth
                                            disableCloseOnSelect
                                            value={value ?? []}
                                            options={employeeOptions}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            getOptionLabel={option => option.label}
                                            getOptionKey={option => option.value}
                                            onChange={(_, selectedOption) => {
                                                onChange(selectedOption);
                                            }}
                                            renderInput={params => <TextField error={!!error} helperText={error?.message} {...restField} {...params} />}
                                        />
                                    }
                                />
                            )}
                        />
                    </Stack>
                </StateHandler>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(onSuccess, console.error)} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
