import { FC } from 'react';

import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { canManageOtherEmployeePayrollLock, hasConfigurePayrollPolicy } from '@/domain/permission/Permission.service';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { useAppSelector } from '@/stores/store';
import { Paper, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const getLocationPathname = (pathname: string) => {
    if (pathname.includes('locks')) {
        return '/payroll/locks';
    }
    return pathname;
};

type PAYROLL_TABS = 'leaves' | 'profile-changes' | 'timesheet-payments' | 'locks';

export const PayrollHeader: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const currentEmployee = useAppSelector(state => state.currentEmployee.employee);
    const realm = useAppSelector(state => state.ui.currentRealm);
    const location = useLocation();

    if (!realm) {
        throw new Error('Realm is not defined');
    }

    if (!currentEmployee) {
        throw new Error('Current employee is not defined');
    }

    const tabOptions: { name: string; value: `/payroll/${PAYROLL_TABS}` }[] = [];

    if (hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.LEAVES) && hasConfigurePayrollPolicy(policies)) {
        tabOptions.push({
            name: t('payroll.tabs.leaves'),
            value: '/payroll/leaves',
        });
    }

    if (hasConfigurePayrollPolicy(policies)) {
        tabOptions.push({
            name: t('payroll.tabs.profile_changes'),
            value: '/payroll/profile-changes',
        });
    }

    if (hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.TIMESHEET)) {
        tabOptions.push({
            name: t('payroll.tabs.timesheet_payments'),
            value: '/payroll/timesheet-payments',
        });
    }

    const locksTab: { name: string; value: `/payroll/${PAYROLL_TABS}` } = {
        name: t('payroll.tabs.employees_locks'),
        value: '/payroll/locks',
    };
    const shouldDisplayLocksTab = canManageOtherEmployeePayrollLock(realm.realmFeatures, policies, currentEmployee.id);

    const onLockClick = () => {
        //TODO: when we create a lock page in the employee profile we dont need this hack and we can remove it
        //when the user is watching the locks of an employee, the tab should redirect to the locks page (like a go back action)
        if (location.pathname.includes('locks/')) {
            navigate('/payroll/locks');
        }
    };

    return (
        <Paper
            sx={{
                borderRadius: 1,
                display: { xs: 'none', md: 'block' },
            }}
        >
            <Tabs
                value={getLocationPathname(location.pathname)}
                onChange={(_e, value) => {
                    navigate(value);
                }}
                aria-label='Payroll Tabs Control'
            >
                {tabOptions.map(item => (
                    <Tab key={item.name} label={item.name} value={item.value} />
                ))}
                {shouldDisplayLocksTab && <Tab key={locksTab.name} label={locksTab.name} value={locksTab.value} onClick={onLockClick} />}
            </Tabs>
        </Paper>
    );
};
