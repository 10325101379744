import { EmployeeAvatar as EmployeeAvatarType } from '@/domain/employee/Employee.model';
import { Avatar, AvatarProps, Link, LinkProps, Tooltip } from '@mui/material';
import { forwardRef } from 'react';
import { getAvatarSizeProps } from '@/Components/employee-avatar/EmployeeAvatar.util';
import { Link as RouterLink } from 'react-router-dom';

// todo : too much variant, need to be refactored, maybe let the parent component decide the size (width, height, fontsize). Also be aligned with the size on the figma.
export type EmployeeAvatarProps = AvatarProps & {
    size?: 'small' | 'medium' | 'large' | 'xl' | 'xxl' | 'xxxl';
    employeeAvatar: EmployeeAvatarType;
    profileLink?: boolean;
    disableTooltip?: boolean;
};

export const EmployeeAvatar = forwardRef<HTMLDivElement, EmployeeAvatarProps>(
    ({ employeeAvatar, size = 'medium', profileLink, disableTooltip, sx, ...rest }, ref) => {
        if (!employeeAvatar) {
            return <></>;
        }
        const display = `${employeeAvatar.lastName.charAt(0).toUpperCase()}${employeeAvatar.firstName.charAt(0).toUpperCase()}`;
        const alt = employeeAvatar.displayName;
        const sizeProps = getAvatarSizeProps(size);
        const tooltipTitle = disableTooltip ? '' : employeeAvatar.displayName;

        const avatarProps = {
            ref,
            alt,
            src: employeeAvatar.avatarImageUrl,
            sx: {
                ...sizeProps,
                ...sx,
            },
            ...rest,
            children: display,
        };

        // when profileLink is enabled, Tooltip is inside the Link, otherwise, Tooltip does not work
        if (profileLink) {
            return (
                <Avatar component={ProfileLink} employeeId={employeeAvatar.id} tooltipTitle={tooltipTitle} {...avatarProps}>
                    {display}
                </Avatar>
            );
        }

        return (
            <Tooltip title={tooltipTitle}>
                <Avatar {...avatarProps} />
            </Tooltip>
        );
    },
);
EmployeeAvatar.displayName = 'EmployeeAvatar';

type ProfileLinkProps = LinkProps & { employeeId: number; tooltipTitle: string };
const ProfileLink = forwardRef<HTMLAnchorElement, ProfileLinkProps>(props => {
    const { employeeId, tooltipTitle, children, ...rest } = props;
    const PROFILE_LINK = `/profile/${employeeId}`;

    return (
        <Link component={RouterLink} {...rest} to={PROFILE_LINK} underline={'none'}>
            <Tooltip title={tooltipTitle}>
                <span>{children}</span>
            </Tooltip>
        </Link>
    );
});

ProfileLink.displayName = 'ProfileLink';
