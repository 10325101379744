import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVerticalIcon } from '@/assets/Icons/Icons';
import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '@/Components/confirmation-dialog/ConfirmDialog';

type Props = {
    onEditClicked: () => void;
    onDeleteClicked: () => void;
    onSendReminderClicked: () => void;
    editDisabled?: boolean;
    deleteDisabled?: boolean;
};

export const SurveyActionButtons: FC<Props> = ({ onEditClicked, onDeleteClicked, onSendReminderClicked, editDisabled = false, deleteDisabled = false }) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const { t } = useTranslation();

    return (
        <>
            <Menu
                open={popupVisible}
                id='user-menu'
                anchorEl={anchorEl}
                keepMounted
                onClose={(event: Event) => {
                    event.stopPropagation();
                    setPopupVisible(false);
                }}
                anchorOrigin={{ vertical: 30, horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {!editDisabled && (
                    <MenuItem
                        dense={true}
                        onClick={event => {
                            event.stopPropagation();
                            setPopupVisible(false);
                            onEditClicked();
                        }}
                    >
                        {t('general.edit')}
                    </MenuItem>
                )}
                <MenuItem
                    dense={true}
                    onClick={event => {
                        event.stopPropagation();
                        setPopupVisible(false);
                        onDeleteClicked();
                    }}
                    disabled={deleteDisabled}
                >
                    {t('general.delete')}
                </MenuItem>

                <ConfirmDialog title={t('survey_templates_page.cycles.send_reminder_now')} onConfirm={onSendReminderClicked}>
                    <MenuItem
                        dense={true}
                        onClick={event => {
                            event.stopPropagation();
                            setPopupVisible(false);
                            onSendReminderClicked();
                        }}
                    >
                        {t('general.send_reminder')}
                    </MenuItem>
                </ConfirmDialog>
            </Menu>
            <IconButton
                size='small'
                onClick={event => {
                    setAnchorEl(event.currentTarget);
                    event.stopPropagation();
                    setPopupVisible(true);
                }}
            >
                <MoreVerticalIcon />
            </IconButton>
        </>
    );
};
