import { ErrorMessage } from '@/auth/Components/ErrorMessage';
import { PasswordForm } from '@/auth/Components/PasswordForm';
import { LogoRoger } from '@/Components/logo-roger/LogoRoger';
import { setUserLanguage } from '@/utils/language.util';
import { getCurrentEmployee } from '@/domain/employee/Employee.service';
import { employeeAuthenticated } from '@/stores/reducers/currentEmployeeSlice';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { Link, Paper, Stack } from '@mui/material';
import { Auth } from 'aws-amplify';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { handleError } from '@/utils/api.util';

export const ActivateAccountPage: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const username = searchParams.get('username');
    const code = searchParams.get('activation_code');

    const dispatch = useAppDispatch();
    const realm = useAppSelector(state => state.ui.currentRealm);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            if (username && code) {
                try {
                    const response = await Auth.signIn(username, code);
                    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        return;
                    }
                } catch (error) {
                    //This error happens when the user already defined a password
                    console.error(error);
                    navigate('/login');
                }
            }
        }
        fetchData();
    }, [username, code, navigate]);

    const completeNewPassword = (user: unknown, newPassword: string) => {
        Auth.completeNewPassword(user, newPassword)
            .then(() => {
                // at this time the user is logged in! \o/
                getCurrentEmployee()
                    .then(data => {
                        if (data?.employee?.language) {
                            setUserLanguage(data?.employee.language).catch(handleError);
                        }
                        dispatch(employeeAuthenticated(data));
                        navigate('/profile/personal-info');
                    })
                    .catch(error => {
                        console.error(error);
                        setErrorMessage(error.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch(error => {
                console.error(error);
                setErrorMessage(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = (username: string, code: string, newPassword: string) => {
        if (!username || !code) {
            return;
        }
        setLoading(true);

        Auth.signIn(username, code)
            .then(response => {
                if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    completeNewPassword(response, newPassword);
                } else {
                    throw new Error('Unsupported cognito response ' + response.challengeName + ' on account activation');
                }
            })
            .catch(error => {
                console.error(error);
                setErrorMessage(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Stack height='100vh'>
            <Stack component={Paper} direction='column' gap={3} alignItems='stretch' margin='auto' p={2} width='440px'>
                <LogoRoger variant='light' />

                {!realm && <ErrorMessage message={t('login_form.realm_not_found')} />}

                {(!username || !code) && (
                    <>
                        <ErrorMessage message={t('activate_account_form.invalid_link')} />
                        <Link to='/' component={RouterLink} color='primary'>
                            {t('activate_account_form.back_to_login')}
                        </Link>
                    </>
                )}

                {realm && username && code && (
                    <Stack>
                        <PasswordForm
                            title={t('activate_account_form.required_to_change_password')}
                            buttonLabel={t('login_form.login')}
                            loading={loading}
                            onSubmitPassword={password => handleSubmit(username, code, password)}
                        />
                        {errorMessage && <ErrorMessage message={errorMessage} />}
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
