import { DialogContainer } from '@/Components/dialog-container/DialogContainer';
import { FieldLocalDate } from '@/Components/form/field-date/FieldDate';
import { EmployeePayrollLockUnlockMutation } from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { unlockEmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.service';
import { handleError } from '@/utils/api.util';
import { formatInDefaultDate, getLocalDateTestConfig, isValidDate, LocalDate } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, FormControlLabel, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type UnlockEmployeePayrollLocksDialogProps = {
    onCancel: () => void;
    onSave: () => void;
    employeeIds: number[];
};

export const UnlockEmployeePayrollLocksDialog: FC<UnlockEmployeePayrollLocksDialogProps> = ({ onCancel, onSave, employeeIds }) => {
    const { t } = useTranslation();

    const unlockEmployeePayrollLocksSchema = yup.object().shape({
        employeeIds: yup.array().required(),
        unlockAfter: yup.string<LocalDate>().test(getLocalDateTestConfig()).required(),
    });

    const formMethods = useForm<EmployeePayrollLockUnlockMutation>({
        resolver: yupResolver(unlockEmployeePayrollLocksSchema),
        defaultValues: {
            employeeIds: employeeIds,
        },
    });

    const { handleSubmit, watch, control } = formMethods;

    const handleUnlockEmployeePayrollLock = async (employeePayrollLockUnlock: EmployeePayrollLockUnlockMutation) => {
        try {
            await unlockEmployeePayrollLock(employeePayrollLockUnlock);
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const unlockAfter = watch('unlockAfter');

    return (
        <DialogContainer
            open={true}
            title={t('unlock_timesheets_dialog.title')}
            saveButtonText={t('unlock_timesheets_dialog.save_button')}
            onSave={handleSubmit(handleUnlockEmployeePayrollLock, console.error)}
            onClose={onCancel}
        >
            <Stack direction='column' gap={2} alignItems={'center'}>
                <FormProvider {...formMethods}>
                    <FormControlLabel
                        label={t('unlock_timesheets_dialog.unlock_after_field')}
                        style={{ width: '100%' }}
                        control={<FieldLocalDate name='unlockAfter' control={control} />}
                    />
                </FormProvider>
                {!!unlockAfter && isValidDate(unlockAfter) && (
                    <Alert severity='warning' elevation={0} sx={{ alignItems: 'center' }}>
                        <Typography variant='body2bold'>
                            {t('unlock_timesheets_dialog.warning_message', {
                                numberOfEmployees: employeeIds.length,
                                unlockAfterDate: formatInDefaultDate(unlockAfter),
                            })}
                        </Typography>
                    </Alert>
                )}
            </Stack>
        </DialogContainer>
    );
};
