import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
    },
    paperBackground: {
        padding: theme.spacing(6),
        textAlign: 'center',
        maxWidth: 450,
        margin: 'auto',
    },
}));

export const SurveyTokenError: FC<unknown> = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paperBackground}>
                <Typography variant='body1'>{t('anonymous_surveys.this_page_does_not_exist')}</Typography>
            </Paper>
        </div>
    );
};
