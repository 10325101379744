import { TableSection } from '@/Components/section/TableSection/TableSection';
import { SectionColumn, SectionRow } from '@/Components/section/types';
import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { LongLeave } from '@/domain/long-leave/LongLeave.model';
import { canManageEmployeeContracts } from '@/domain/permission/Permission.service';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { useAppSelector } from '@/stores/store';
import { ICellRendererParams } from '@ag-grid-community/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeFieldMoreButton } from '../EmployeeFieldMoreButton/EmployeeFieldMoreButton';

type EmployeeLongLeaveSectionProps = {
    employeeId: number;
    longLeaves: LongLeave[];
    handleLongLeaveEditClick: (sectionRow: SectionRow) => void;
    handleLongLeaveCancelClick: (sectionRow: SectionRow) => void;
};

export const EmployeeLongLeaveSection: FC<EmployeeLongLeaveSectionProps> = ({
    employeeId,
    longLeaves,
    handleLongLeaveEditClick,
    handleLongLeaveCancelClick,
}) => {
    const { t } = useTranslation();

    const policies: EmployeePolicy[] = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const columns: SectionColumn[] = [
        {
            title: t('employee.long_leave.start_date'),
            type: SectionFieldType.DATE,
        },
        {
            title: t('employee.long_leave.end_date'),
            type: SectionFieldType.DATE,
        },
        {
            title: t('employee.long_leave.comment'),
            type: SectionFieldType.STRING,
        },
    ];

    const rows: SectionRow[] = longLeaves?.map(longLeave => {
        return {
            id: longLeave.id,
            isPending: false,
            fields: [
                {
                    title: t('employee.long_leave.start_date'),
                    type: SectionFieldType.DATE,
                    dateValue: longLeave.startDate,
                    required: true,
                    fieldType: 'LONG_LEAVE_START_DATE',
                },
                {
                    title: t('employee.long_leave.end_date'),
                    type: SectionFieldType.DATE,
                    dateValue: longLeave.endDate,
                    required: true,
                    fieldType: 'LONG_LEAVE_END_DATE',
                },
                {
                    title: t('employee.long_leave.comment'),
                    type: SectionFieldType.STRING,
                    stringValue: longLeave.comment,
                    required: false,
                    fieldType: 'LONG_LEAVE_COMMENT',
                },
            ],
        };
    });

    const handleEditClicked = (sectionRow: SectionRow) => () => {
        handleLongLeaveEditClick(sectionRow);
    };

    const handleDeleteClicked = (sectionRow: SectionRow) => () => {
        handleLongLeaveCancelClick(sectionRow);
    };

    const cellActionRenderer = (params: ICellRendererParams<SectionRow>) => {
        const data = params.data;
        if (!data) {
            return;
        }
        return (
            <EmployeeFieldMoreButton
                onEditClicked={handleEditClicked(data)}
                onDeleteClicked={handleDeleteClicked(data)}
                disabled={false}
                cancelEnabled={false}
            />
        );
    };

    return (
        longLeaves?.length !== 0 && (
            <TableSection
                sectionTitle={t('employee.sections.long_leave')}
                columns={columns}
                rows={rows}
                actionMenuCellRenderer={canManageEmployeeContracts(policies, employeeId) ? cellActionRenderer : undefined}
            />
        )
    );
};
