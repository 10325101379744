import { FileMetadata, FilePickerWrapper } from '@/Components/file-picker-wrapper/FilePickerWrapper';
import { Footer } from '@/page/layout/Footer';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { LegalUnit } from '@/domain/legal-unit/LegalUnit.model';
import { createLegalUnit, deleteLegalUnitLogo, updateLegalUnit, updateLegalUnitLogo } from '@/domain/legal-unit/LegalUnit.service';
import { useGetLegalUnit } from '@/hooks/legal-unit/LegalUnit.hook';
import { FORM_COMPANY_SETTINGS_CONTAINER_CLASS } from '@/page/setting/CompanySettings.constants';
import { LocationGrid } from '@/page/setting/location/LocationGrid';
import { handleError } from '@/utils/api.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormControlLabel, FormHelperText, Paper, Stack, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { showSnackbar } from '@/utils/snackbar.util';
import { FC } from 'react';

export const LegalUnitSettingPage: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const legalUnitId = Number(params.legalUnitId) || undefined;

    const {
        data: legalUnit,
        isError: isLegalUnitError,
        isLoading: isLegalUnitLoading,
        error: legalUnitError,
        refetch: refetchLegalUnit,
    } = useGetLegalUnit(legalUnitId);

    const goToDetail = (id: number) => {
        // Redirect to the legal unit detail page
        navigate(`/settings/organization/legal-units/${id}`);
    };

    const handleSave = async ({ logoImage, ...data }: LegalUnitSettingForm) => {
        try {
            if (legalUnit) {
                await updateLegalUnit(legalUnit.id, { ...data, locationIds: legalUnit.locations.map(l => l.id) });
                if (logoImage?.key) {
                    await updateLegalUnitLogo(legalUnit.id, logoImage.key);
                }
                showSnackbar(t('legal_units_settings_page.legal_unit_saved'), 'success');
            } else {
                const { id } = await createLegalUnit({ ...data, logoImageUrl: logoImage?.key });
                goToDetail(id);
            }
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <StateHandler isLoading={isLegalUnitLoading} isError={isLegalUnitError} error={legalUnitError}>
            <LegalUnitSettingForm legalUnit={legalUnit} onSave={handleSave} onRefetch={refetchLegalUnit} />
        </StateHandler>
    );
};

const LegalUnitSettingForm: React.FC<{
    legalUnit?: LegalUnit;
    onSave: (data: LegalUnitSettingForm) => void;
    onRefetch: () => void;
}> = ({ legalUnit, onSave, onRefetch }) => {
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm<LegalUnitSettingForm>({
        resolver: yupResolver(legalUnitFormSchema),
        defaultValues: {
            legalName: legalUnit?.legalName ?? '',
            displayName: legalUnit?.displayName ?? '',
            logoImage: legalUnit?.logoImageUrl
                ? {
                      url: legalUnit?.logoImageUrl,
                      filename: legalUnit?.legalName ?? '',
                      key: undefined,
                      mimetype: 'image/*',
                  }
                : undefined,
        },
    });

    const handleRemoveSavedLogo = async (legalUnitId: number) => {
        try {
            await deleteLegalUnitLogo(legalUnitId);
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <>
            <Stack component={ContentContainer} flex={1} gap={2}>
                <Stack component={Paper} p={3} gap={2} className={FORM_COMPANY_SETTINGS_CONTAINER_CLASS}>
                    <FormControlLabel
                        label={t('legal_units_settings_page.display_name')}
                        control={<Controller name='displayName' control={control} render={({ field }) => <TextField fullWidth {...field} />} />}
                    />
                    <FormControlLabel
                        label={t('legal_units_settings_page.legal_name')}
                        control={<Controller name='legalName' control={control} render={({ field }) => <TextField fullWidth {...field} />} />}
                    />

                    <Controller
                        name='logoImage'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                <FilePickerWrapper
                                    containerId='legal-unit-logo'
                                    accept={'image/*'}
                                    maxFiles={1}
                                    filesMetadata={field.value ? [field.value] : []}
                                    onFileUploaded={fileToAdd => {
                                        field.onChange(fileToAdd);
                                    }}
                                    onFileRemoved={() =>
                                        legalUnit?.id && legalUnit.logoImageUrl ? handleRemoveSavedLogo(legalUnit.id) : field.onChange(getNull())
                                    }
                                    onFileRenamed={undefined}
                                    fetchDocumentUrl={undefined}
                                />
                                {!!error && (
                                    <FormHelperText sx={{ marginLeft: 2 }} error={!!error}>
                                        {error?.message}
                                    </FormHelperText>
                                )}
                            </>
                        )}
                    />
                </Stack>

                {legalUnit && (
                    <Stack component={Paper} p={3} gap={2} flex={1}>
                        <LocationGrid legalUnitId={legalUnit.id} locations={legalUnit.locations} onRefetch={onRefetch} />
                    </Stack>
                )}
            </Stack>
            <Footer>
                <LoadingButton onClick={handleSubmit(onSave, console.error)} variant='contained'>
                    {t(legalUnit?.id ? 'general.update' : 'general.create')}
                </LoadingButton>
            </Footer>
        </>
    );
};
const imageSchema = yup
    .object<FileMetadata>()
    .shape({
        key: yup.string(),
        url: yup.string(),
        filename: yup.string().required(),
        itemId: yup.number(),
        mimetype: yup.string().required(),
    })
    .nullable()
    .default(getNull());

const legalUnitFormSchema = yup.object().shape({
    legalName: yup.string().trim().required(),
    displayName: yup.string().trim().required(),
    logoImage: imageSchema,
});

export type LegalUnitSettingForm = yup.InferType<typeof legalUnitFormSchema>;
