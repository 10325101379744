import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { showSnackbar } from '@/utils/snackbar.util';

import { cancelApprovedLeaveRequest, cancelPendingLeaveRequest } from '@/domain/leave-request/LeaveRequest.service';
import { LeaveRequest } from '@/domain/leave-request/LeaveRequest.model';
import { ConfirmDialog } from '@/Components/confirmation-dialog/ConfirmDialog';

type Props = {
    dialogOpen?: boolean;
    leaveRequest: LeaveRequest | undefined;
    onSuccess: () => void;
    onClose: () => void;
};

export const LeaveCancellationConfirmationDialog: FC<Props> = ({ leaveRequest, onSuccess, onClose }) => {
    const { t } = useTranslation();

    const leaveRequestCancelled = () => {
        showSnackbar(t('request_leave_dialog.messages.leave_request_cancelled'), 'success');
    };

    const couldNotCancelLeaveRequest = () => {
        showSnackbar(t('request_leave_dialog.messages.could_not_cancel_leave_request'), 'error');
    };

    const handleCancelLeaveRequest = () => {
        if (!leaveRequest?.id) {
            return;
        }
        if (leaveRequest.requestStatus === 'APPROVED') {
            cancelApprovedLeaveRequest(leaveRequest?.id)
                .then(() => {
                    leaveRequestCancelled();
                    onSuccess();
                })
                .catch(() => {
                    couldNotCancelLeaveRequest();
                });
        } else {
            cancelPendingLeaveRequest(leaveRequest?.id)
                .then(() => {
                    leaveRequestCancelled();
                    onSuccess();
                })
                .catch(() => {
                    couldNotCancelLeaveRequest();
                });
        }
    };

    return (
        <ConfirmDialog
            open={!!leaveRequest}
            title={t('request_leave_dialog.messages.cancel_leave_request_confirmation')}
            onConfirm={handleCancelLeaveRequest}
            onClose={onClose}
        />
    );
};
