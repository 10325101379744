import { Button, ButtonProps, IconButton, IconButtonProps, Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material';
import { cloneElement, FC, MouseEvent, ReactElement, ReactNode, useState } from 'react';

export type BasicMenuItem = MenuItemProps & {
    title: string;
    disabled?: boolean;
    selected?: boolean;
};

type BasicMenuProps = Omit<MenuProps, 'open'> & {
    items: BasicMenuItem[];
    endIcon?: ReactNode;
    dropdownTitle?: string;
    button?: ReactElement<ButtonProps | IconButtonProps>;
};

export const BasicMenu: FC<BasicMenuProps> = ({ items, endIcon, dropdownTitle, button, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(undefined);
    };

    const defaultButton = dropdownTitle ? <Button variant='text'>{dropdownTitle}</Button> : <IconButton aria-label='menu-icon-button'>{endIcon}</IconButton>;

    const renderedButton = cloneElement(button ?? defaultButton, {
        id: 'basic-button',
        'aria-controls': open ? 'basic-menu' : undefined,
        'aria-haspopup': 'true',
        'aria-expanded': open ? 'true' : undefined,
        onClick: handleClick,
        // EndIcon is not allowed in IconButton
        ...(endIcon ? { endIcon: endIcon } : {}),
    });

    return (
        <div>
            {renderedButton}
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                {...rest}
            >
                {items?.map(({ title, onClick, children, ...rest }) => {
                    return (
                        <MenuItem
                            key={title}
                            onClick={event => {
                                event.stopPropagation();
                                onClick?.(event);
                                setAnchorEl(undefined);
                            }}
                            {...rest}
                        >
                            {children ?? title}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};
