import { FieldNumber } from '@/Components/form/field-number/FieldNumber';
import { FieldSwitch } from '@/Components/form/field-switch/FieldSwitch';
import { TimeFieldWrapper } from '@/Components/time-field-wrapper/TimeFieldWrapper';
import { TimesheetSettingForm } from '@/page/setting/time-management/time-management-setting-form/TimeManagementSettingForm';
import { getDateFromTimeFormat, getTimeFormatFromDate } from '@/utils/datetime.util';
import { Collapse, FormControlLabel, formHelperTextClasses, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const TimeManagementTimeClock: FC = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext<TimesheetSettingForm>();

    const forceSmallBreakDurationInMinutes = watch('forceSmallBreakDurationInMinutes');
    const forceBigBreakDurationInMinutes = watch('forceBigBreakDurationInMinutes');
    const isForceBreakToBeTakenEnabled = watch('isForceBreakToBeTakenEnabled');
    const isPaidBreaksSundayPublicHolidays = watch('paidBreaksSundayPublicHolidays');
    const isPaidBreaksSaturday = watch('paidBreaksSaturday');
    const isPaidBreaksEnabled = watch('isPaidBreaksEnabled');
    const disableForceBreakRule1 = forceSmallBreakDurationInMinutes === 0;
    const disableForceBreakRule2 = forceBigBreakDurationInMinutes === 0;

    return (
        <Stack component={Paper} p={2} gap={2}>
            <Typography variant={'h1'}>{t('time_management_settings_page.time_management_configuration.timeclock')}</Typography>

            <Stack gap={2}>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FormControlLabel
                        label={t('time_management_settings_page.time_management_configuration.mobile_clock_in_out')}
                        labelPlacement='end'
                        control={<FieldSwitch name='mobileClockInOut' control={control} />}
                        sx={{ mr: 0 }}
                    />
                </Stack>

                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FormControlLabel
                        label={t('time_management_settings_page.time_management_configuration.allow_clock_in_out_outside_work_hours')}
                        labelPlacement='end'
                        control={<FieldSwitch name='allowClockInOutOutsideWorkHours' control={control} />}
                        sx={{ mr: 0 }}
                    />
                </Stack>

                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FormControlLabel
                        label={t('time_management_settings_page.time_management_configuration.allow_clock_in_out_on_sunday_and_public_holidays')}
                        labelPlacement='end'
                        control={<FieldSwitch name='allowClockInOutOnSundayAndPublicHolidays' control={control} />}
                        sx={{ mr: 0 }}
                    />
                </Stack>

                <Stack direction={'column'}>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <FormControlLabel
                            label={t('time_management_settings_page.time_management_configuration.force_break_clock_in_out')}
                            labelPlacement='end'
                            control={<FieldSwitch name='forceBreakClockInOut' control={control} />}
                            sx={{ mr: 0 }}
                        />
                        {watch('forceBreakClockInOut') && (
                            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.of')}</Typography>
                        )}
                    </Stack>

                    <Collapse in={watch('forceBreakClockInOut')}>
                        <Stack gap={2} paddingTop={2}>
                            <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                                <Typography width={120} variant={'body1bold'}>
                                    {t('time_management_settings_page.time_management_configuration.rule', { ruleNumber: 1 })}
                                </Typography>

                                <FieldNumber name='forceSmallBreakDurationInMinutes' control={control} min={0} step={5} sx={fieldNumberSX} />

                                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.minutes_after')}</Typography>

                                <FieldNumber
                                    name='forceSmallBreakAfterXHours'
                                    control={control}
                                    min={0}
                                    step={0.5}
                                    sx={fieldNumberSX}
                                    disabled={disableForceBreakRule1}
                                    precision={2}
                                />

                                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.hours_work')}</Typography>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems={'baseline'}>
                                <Typography width={120} variant={'body1bold'}>
                                    {t('time_management_settings_page.time_management_configuration.rule', { ruleNumber: 2 })}
                                </Typography>

                                <FieldNumber name='forceBigBreakDurationInMinutes' control={control} min={0} step={5} sx={fieldNumberSX} />

                                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.minutes_after')}</Typography>

                                <FieldNumber
                                    name='forceBigBreakAfterXHours'
                                    control={control}
                                    min={0}
                                    step={0.5}
                                    sx={fieldNumberSX}
                                    disabled={disableForceBreakRule2}
                                    precision={2}
                                />

                                <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.hours_work')}</Typography>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                <FormControlLabel
                                    label={
                                        isForceBreakToBeTakenEnabled
                                            ? t('time_management_settings_page.time_management_configuration.force_break_to_be_taken_at_on')
                                            : t('time_management_settings_page.time_management_configuration.force_break_to_be_taken_at_off')
                                    }
                                    labelPlacement='end'
                                    control={<FieldSwitch name='isForceBreakToBeTakenEnabled' control={control} />}
                                    sx={{ mr: 0, pl: '125px' }}
                                />
                                <Collapse in={isForceBreakToBeTakenEnabled}>
                                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                                        <Typography variant={'body1'}>{t('general.from')}</Typography>

                                        <ControllerTimeFieldWrapper name={'forceBreakToBeTakenFrom'} />

                                        <Typography variant={'body1'}>{t('general.to')}</Typography>

                                        <ControllerTimeFieldWrapper name={'forceBreakToBeTakenTo'} />
                                    </Stack>
                                </Collapse>
                            </Stack>
                        </Stack>
                    </Collapse>
                </Stack>

                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FormControlLabel
                        label={
                            isPaidBreaksSundayPublicHolidays
                                ? t('time_management_settings_page.time_management_configuration.paid_breaks_sunday_public_holidays_on')
                                : t('time_management_settings_page.time_management_configuration.paid_breaks_sunday_public_holidays_off')
                        }
                        labelPlacement='end'
                        control={<FieldSwitch name='paidBreaksSundayPublicHolidays' control={control} />}
                        sx={{ mr: 0 }}
                    />
                    {isPaidBreaksSundayPublicHolidays && (
                        <TimeManagementMaximumDuration maxDurationName={'paidBreaksSundayPublicHolidaysMaximumDurationInMinutes'} />
                    )}
                </Stack>

                <Stack direction={'row'} gap={1} alignItems={'center'}>
                    <FormControlLabel
                        label={
                            isPaidBreaksSaturday
                                ? t('time_management_settings_page.time_management_configuration.paid_breaks_saturday_on')
                                : t('time_management_settings_page.time_management_configuration.paid_breaks_saturday_off')
                        }
                        labelPlacement='end'
                        control={<FieldSwitch name='paidBreaksSaturday' control={control} />}
                        sx={{ mr: 0 }}
                    />
                    {isPaidBreaksSaturday && <TimeManagementMaximumDuration maxDurationName={'paidBreaksSaturdayMaximumDurationInMinutes'} />}
                </Stack>

                <Stack direction={'column'} gap={1}>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <FormControlLabel
                            label={
                                isPaidBreaksEnabled
                                    ? t('time_management_settings_page.time_management_configuration.paid_breaks_on')
                                    : t('time_management_settings_page.time_management_configuration.paid_breaks_off')
                            }
                            labelPlacement='end'
                            control={<FieldSwitch name='isPaidBreaksEnabled' control={control} />}
                            sx={{ mr: 0 }}
                        />
                    </Stack>

                    <Collapse in={isPaidBreaksEnabled}>
                        <Stack direction={'column'} gap={1} paddingTop={1}>
                            <TimeManagementTimeClockForceBreakRules
                                ruleName={t('time_management_settings_page.time_management_configuration.rule_force_break', { ruleNumber: 1 })}
                                ruleFromName={'rule1PaidBreaksFrom'}
                                ruleToName={'rule1PaidBreaksTo'}
                                ruleMaxDurationName={'rule1PaidBreaksMaximumDurationInMinutes'}
                            />
                            <TimeManagementTimeClockForceBreakRules
                                ruleName={t('time_management_settings_page.time_management_configuration.rule_force_break', { ruleNumber: 2 })}
                                ruleFromName={'rule2PaidBreaksFrom'}
                                ruleToName={'rule2PaidBreaksTo'}
                                ruleMaxDurationName={'rule2PaidBreaksMaximumDurationInMinutes'}
                            />
                        </Stack>
                    </Collapse>
                </Stack>
            </Stack>
        </Stack>
    );
};

type TimeManagementTimeClockForceBreakRulesProps = {
    ruleName: string;
    ruleFromName: 'rule1PaidBreaksFrom' | 'rule2PaidBreaksFrom';
    ruleToName: 'rule1PaidBreaksTo' | 'rule2PaidBreaksTo';
    ruleMaxDurationName: 'rule1PaidBreaksMaximumDurationInMinutes' | 'rule2PaidBreaksMaximumDurationInMinutes';
};

const TimeManagementTimeClockForceBreakRules: FC<TimeManagementTimeClockForceBreakRulesProps> = ({
    ruleFromName,
    ruleName,
    ruleToName,
    ruleMaxDurationName,
}) => {
    const { t } = useTranslation();
    const { watch } = useFormContext<TimesheetSettingForm>();

    const isRuleActive = watch(ruleMaxDurationName) > 0;

    return (
        <Stack direction={'row'} gap={1} alignItems={'baseline'}>
            <Typography width={120} variant={'body1bold'}>
                {ruleName}
            </Typography>

            <Typography variant={'body1'}>{t('general.from')}</Typography>

            <ControllerTimeFieldWrapper name={ruleFromName} disabled={!isRuleActive} />

            <Typography variant={'body1'}>{t('general.to')}</Typography>

            <ControllerTimeFieldWrapper name={ruleToName} disabled={!isRuleActive} />

            <TimeManagementMaximumDuration maxDurationName={ruleMaxDurationName} />
        </Stack>
    );
};

type TimeManagementMaximumDurationProps = {
    maxDurationName:
        | 'rule1PaidBreaksMaximumDurationInMinutes'
        | 'rule2PaidBreaksMaximumDurationInMinutes'
        | 'paidBreaksSundayPublicHolidaysMaximumDurationInMinutes'
        | 'paidBreaksSaturdayMaximumDurationInMinutes';
};

const TimeManagementMaximumDuration: FC<TimeManagementMaximumDurationProps> = ({ maxDurationName }) => {
    const { t } = useTranslation();
    const { control } = useFormContext<TimesheetSettingForm>();

    return (
        <>
            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.maximum')}</Typography>

            <FieldNumber name={maxDurationName} control={control} min={0} step={5} sx={fieldNumberSX} defaultValue={0} precision={2} />

            <Typography variant={'body1'}>{t('time_management_settings_page.time_management_configuration.minutes')}</Typography>
        </>
    );
};
type ControllerTimeFieldWrapperProps = {
    name: 'rule1PaidBreaksTo' | 'rule2PaidBreaksTo' | 'forceBreakToBeTakenFrom' | 'forceBreakToBeTakenTo' | 'rule1PaidBreaksFrom' | 'rule2PaidBreaksFrom';
    disabled?: boolean;
};

const ControllerTimeFieldWrapper: FC<ControllerTimeFieldWrapperProps> = ({ name, disabled = false }) => {
    const { control } = useFormContext<TimesheetSettingForm>();
    return (
        <Controller
            name={name}
            disabled={disabled}
            control={control}
            render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                <TimeFieldWrapper
                    {...restField}
                    value={getDateFromTimeFormat(value)}
                    onChange={newValue => {
                        if (newValue) {
                            onChange(getTimeFormatFromDate(newValue));
                        }
                    }}
                    sx={{
                        width: error ? 'fit-content' : '8em',
                    }}
                    slotProps={{
                        textField: {
                            variant: 'outlined',
                            error: !!error,
                            helperText: error?.message,
                        },
                    }}
                />
            )}
        />
    );
};

const fieldNumberSX = {
    width: '8em',
    [`& .${formHelperTextClasses.root}`]: {
        whiteSpace: 'nowrap',
    },
};
