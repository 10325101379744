import { RichTextEditor, RichTextEditorProps } from '@/Components/rich-text-editor/RichTextEditor';
import { getHelperText, getLabelInUserLanguage, getLabelPropertyName, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { Label } from '@/domain/label/Label.model';
import { FormHelperText } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useRichTextEditorWrapper } from '@/Components/rich-text-editor/RichTextEditor.hook';

export type TranslatableRichTextEditorProps = {
    value: Label | undefined;
    onUpdate: (value: Label) => void;
    translationLanguage: UserLanguage;
    errorMessage: string;
} & Omit<RichTextEditorProps, 'defaultValue' | 'onUpdate' | 'errorMessage'>;

export const TranslatableRichTextEditor: FC<TranslatableRichTextEditorProps> = props => {
    const { value, onUpdate, translationLanguage, ...rest } = props;
    const defaultLanguage = getRealmLanguage();
    const [currentTranslationLanguage, setCurrentTranslationLanguage] = useState<UserLanguage>(translationLanguage);
    const editorWrapper = useRichTextEditorWrapper(getLabelInUserLanguage(value, translationLanguage), false);

    useEffect(() => {
        if (currentTranslationLanguage !== translationLanguage) {
            editorWrapper?.customCommands.setContent(getLabelInUserLanguage(value, translationLanguage));
            setCurrentTranslationLanguage(translationLanguage);
        }
    }, [currentTranslationLanguage, editorWrapper?.customCommands, translationLanguage, value]);

    const handleUpdate = (richText: string, translationLanguage: UserLanguage) => {
        const property = getLabelPropertyName(translationLanguage);
        if (value) {
            onUpdate({ ...value, [property]: richText });
        }
    };

    return (
        <>
            <RichTextEditor
                {...rest}
                editorWrapper={editorWrapper}
                name={props.name}
                errorMessage={props.errorMessage}
                onUpdate={richText => handleUpdate(richText, translationLanguage)}
                enabledEditorDependency={true}
            />
            {value && (
                <FormHelperText
                    color={'text.secondary'}
                    dangerouslySetInnerHTML={{
                        __html: getHelperText(translationLanguage, value, defaultLanguage) ?? '',
                    }}
                    className={'rich_text'}
                />
            )}
        </>
    );
};
