import { Autocomplete, Paper, Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { handleError } from '@/utils/api.util';
import { updateLeaveType } from '@/domain/leave-type/LeaveType.service';
import { LeaveType, LeaveTypeUpdateMutation } from '@/domain/leave-type/LeaveType.model';
import { CheckboxAutocompleteOption } from '@/Components/autocomplete-wrapper/CheckboxAutocompleteOption';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { useGetSectionDefinitions } from '@/page/setting/section/SectionDefinitions.hook';
import { getLabelTranslation } from '@/utils/language.util';
import { SectionDefinition, SectionDefinitionIncludeInPayrollMutation } from '@/domain/section-setting/Section.model';
import { updateIncludeInPayrollSectionDefinition } from '@/domain/section-setting/Section.service';
import { StateHandler } from '@/Components/state-handler/StateHandler';

export const PayrollSettings: FC = () => {
    const { t } = useTranslation();

    const {
        data: leaveTypes = [],
        isLoading: isLeaveTypesLoading,
        isError: isLeaveTypesError,
        refetch: refetchLeaveTypes,
        error: leaveTypesError,
    } = useGetLeaveTypes();
    const {
        data: sectionDefinitions,
        isLoading: isSectionDefinitionsLoading,
        isError: isSectionDefinitionsError,
        error: sectionDefinitionsError,
        refetch: refetchSectionDefinitions,
    } = useGetSectionDefinitions();

    const updateLeaveTypes = (selectedItems: LeaveType[]) => {
        leaveTypes?.forEach(async lt => {
            const isLeaveTypeSelected = selectedItems.some(item => item.id === lt.id);
            if (lt.includeInPayroll !== isLeaveTypeSelected) {
                const request: LeaveTypeUpdateMutation = {
                    ...lt,
                    includeInPayroll: isLeaveTypeSelected,
                };

                try {
                    await updateLeaveType(request, lt.id);
                    refetchLeaveTypes().catch(handleError);
                } catch (error) {
                    handleError(error);
                }
            }
        });
    };

    const updateSectionDefinitions = (selectedItems: SectionDefinition[]) => {
        sectionDefinitions?.forEach(async sd => {
            const isSectionDefinitionSelected = selectedItems.some(item => item.id === sd.id);
            if (sd.includeInPayroll !== isSectionDefinitionSelected) {
                const request: SectionDefinitionIncludeInPayrollMutation = {
                    includeInPayroll: isSectionDefinitionSelected,
                };

                try {
                    await updateIncludeInPayrollSectionDefinition(sd.id, request);
                    refetchSectionDefinitions().catch(handleError);
                } catch (error) {
                    handleError(error);
                }
            }
        });
    };

    return (
        <StateHandler
            isError={isLeaveTypesError || isSectionDefinitionsError}
            isLoading={isLeaveTypesLoading || isSectionDefinitionsLoading}
            error={leaveTypesError || sectionDefinitionsError}
        >
            <Paper sx={{ p: 2 }}>
                <Stack direction='column' style={{ height: '100%' }} gap={3}>
                    <Typography variant='h1'>{t('payroll_settings_page.export')}</Typography>
                    <Stack direction='column' gap={2}>
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <Typography variant='body2bold'>{t('payroll_settings_page.leave_type')}:</Typography>
                            <Typography variant='body2'>{t('payroll_settings_page.leave_type_desc')}</Typography>
                        </Stack>

                        {leaveTypes && (
                            <Autocomplete
                                sx={{ width: '100%' }}
                                value={leaveTypes.filter(lt => lt.includeInPayroll)}
                                multiple
                                disableCloseOnSelect
                                options={leaveTypes}
                                fullWidth
                                onChange={(_, selectedItems) => {
                                    updateLeaveTypes(selectedItems);
                                }}
                                getOptionLabel={option => option.title}
                                renderInput={params => <TextField {...params} />}
                                autoFocus
                                isOptionEqualToValue={(option, anotherOption) => option.id === anotherOption.id}
                                renderOption={(props, option, { selected }) => (
                                    <CheckboxAutocompleteOption {...props} selected={selected} label={option?.title} />
                                )}
                            />
                        )}
                    </Stack>

                    <Stack direction='column' gap={2}>
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <Typography variant='body2bold'>{t('payroll_settings_page.section_definition')}:</Typography>
                            <Typography variant='body2'>{t('payroll_settings_page.section_definition_desc')}</Typography>
                        </Stack>
                        <Stack>
                            {sectionDefinitions && (
                                <Autocomplete
                                    sx={{ width: '100%' }}
                                    value={sectionDefinitions.filter(sd => sd.includeInPayroll)}
                                    multiple
                                    disableCloseOnSelect
                                    options={sectionDefinitions}
                                    fullWidth
                                    onChange={(_, selectedItems) => {
                                        updateSectionDefinitions(selectedItems);
                                    }}
                                    getOptionLabel={option => getLabelTranslation(option.name)}
                                    renderInput={params => <TextField {...params} />}
                                    autoFocus
                                    isOptionEqualToValue={(option, anotherOption) => option.id === anotherOption.id}
                                    renderOption={(props, option, { selected }) => (
                                        <CheckboxAutocompleteOption {...props} key={option.id} selected={selected} label={getLabelTranslation(option?.name)} />
                                    )}
                                />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </StateHandler>
    );
};
