import { EmployeeRole, EmployeeRoleSearch } from '@/domain/employee-role/EmployeeRole.model';
import { searchEmployeeRoles } from '@/domain/employee-role/EmployeeRole.service';
import { UseQueryResult } from '@/page/Query.type';
import { handleError } from '@/utils/api.util';
import { useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const useGetEmployeeRoles = (employeeRoleSearch: EmployeeRoleSearch | undefined): UseQueryResult<EmployeeRole[]> => {
    const [employeeRoles, setEmployeeRoles] = useState<EmployeeRole[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchEmployeeRoles = useCallback(async (employeeRoleSearch: EmployeeRoleSearch | undefined) => {
        if (!employeeRoleSearch) {
            setIsLoading(false);
            return;
        }

        try {
            const employeeRolesData = await searchEmployeeRoles(employeeRoleSearch);
            setEmployeeRoles(employeeRolesData);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useDeepCompareEffect(() => {
        fetchEmployeeRoles(employeeRoleSearch).catch(handleError);
    }, [employeeRoleSearch, fetchEmployeeRoles]);

    return {
        data: employeeRoles,
        setData: setEmployeeRoles,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchEmployeeRoles(employeeRoleSearch),
    };
};
