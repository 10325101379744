import { AnswerResultDetails, AnswerTextResult, AnswerWordCount } from '@/domain/survey/Survey.model';
import { getLabelTranslation, useUserLanguage } from '@/utils/language.util';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useEffect, useState } from 'react';
import { Comment } from './Comment';

type Props = {
    details: AnswerResultDetails;
    selectedWord?: AnswerWordCount;
};

const useStyles = makeStyles(theme => ({
    imageSmall: {
        width: 24,
        height: 24,
        fontSize: 16,
        borderRadius: 64,
        '& svg': {
            fill: theme.palette.secondary.main,
        },
    },
    comment: {
        marginBottom: theme.spacing(2),
    },
    user: {
        width: 34,
    },
    commentText: {},
    groupByKey: {
        marginBottom: theme.spacing(1),
        display: 'block',
    },
    userInfo: {
        display: '-webkit-box',
        overflow: 'hidden',
        maxWidth: 105,
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
    },
}));

export const TextAnswers: FC<Props> = ({ details, selectedWord = undefined }) => {
    const classes = useStyles();
    const userLanguage = useUserLanguage();
    const [textAnswers, setTextAnswers] = useState<AnswerTextResult[]>([]);

    useEffect(() => {
        const filteredTextAnswers = details?.textAnswers?.filter(answer => !selectedWord || answer.text.toLowerCase().includes(selectedWord.value));
        setTextAnswers(filteredTextAnswers);
    }, [details, selectedWord]);

    if (!details) {
        return <></>;
    }

    return (
        <>
            {details.key !== 'ALL' && textAnswers?.length ? (
                <Typography variant='body1bold' className={classes.groupByKey}>
                    {getLabelTranslation(details.label, userLanguage)}
                </Typography>
            ) : undefined}

            {textAnswers?.map((answer, index: number) => <Comment key={index} text={answer.text} selectedWord={selectedWord?.value} user={answer.user} />)}
        </>
    );
};
