import * as yup from 'yup';
import { getNull } from '@/utils/object.util';
import i18next from 'i18next';

const getCostCenterAssignmentObjectSchema = () =>
    yup.object().shape({
        costCenter: yup
            .object()
            .default(getNull())
            .shape({
                id: yup.number().required(),
                name: yup.string().required(),
            })
            .required(),
        percentage: yup.number().min(0).max(100).default(0).required(),
    });

export type CostCenterAssignmentFormValue = yup.InferType<ReturnType<typeof getCostCenterAssignmentObjectSchema>>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCostCenterAssignmentListObjectSchema = () =>
    yup
        .array()
        .of(getCostCenterAssignmentObjectSchema())
        .test({
            test: departmentCostCenters => {
                if (!departmentCostCenters || departmentCostCenters.length === 0) {
                    return true;
                }
                const totalPercentage = departmentCostCenters.reduce((acc, item) => acc + item.percentage, 0);
                return totalPercentage === 100;
            },
            message: i18next.t('cost_centers.assignment_form.total_percentage_error'),
        })
        .default([]);
