import { ReorderItem, ReorderModal } from '@/Components/reorder-modal/ReorderModal';
import { PlanningPosition, PlanningPositionCreationMutation } from '@/domain/planning-position/PlanningPosition.model';
import {
    createPlanningPosition,
    deletePlanningPosition,
    searchPlanningPosition,
    updatePlanningPosition,
    updatePlanningPositionOrders,
} from '@/domain/planning-position/PlanningPosition.service';
import { EmployeeFieldMoreButton } from '@/page/employee-profile/employee-profile-info/EmployeeFieldMoreButton/EmployeeFieldMoreButton';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { ICellRendererParams, RowClickedEvent } from '@ag-grid-community/core';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanySettingsLayout } from '../CompanySettingsLayout';
import { ConfigType } from '../types';
import { PlanningPositionSettingsDialog } from './position/PlanningPositionSettingsDialog';
import { Box } from '@mui/material';
import { mapToReorderRequest } from '@/domain/common';

export const PlanningPositionSettings: FC = () => {
    const { t } = useTranslation();
    const [planningPositions, setPlanningPositions] = useState<PlanningPosition[]>([]);
    const [sectionDialogMode, setSectionDialogMode] = useState<'create' | 'edit'>('create');
    const [positionDialogOpen, setPositionDialogOpen] = useState<boolean>(false);
    const [activePositionData, setActivePositionData] = useState<PlanningPosition>();
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);

    const fetchPositions = () => {
        searchPlanningPosition()
            .then(planningPositions => setPlanningPositions(planningPositions))
            .catch(handleError);
    };

    useEffect(() => {
        fetchPositions();
    }, []);

    const onClickPositionRow = (row: RowClickedEvent<PlanningPosition>) => {
        setSectionDialogMode('edit');
        setPositionDialogOpen(true);
        setActivePositionData(row.data);
    };

    const handleDelete = async (params: ICellRendererParams<PlanningPosition>) => {
        if (!params.data?.id) {
            return;
        }
        try {
            await deletePlanningPosition(params.data.id);
            fetchPositions();
        } catch (e) {
            handleError(e);
        }
    };

    const getEmployeeFieldMoreButton = (params: ICellRendererParams<PlanningPosition>) => (
        <EmployeeFieldMoreButton
            approveEnabled={false}
            declineEnabled={false}
            disabled={false}
            deleteEnabled={true}
            editDisabled={true}
            cancelEnabled={false}
            onDeleteClicked={() => handleDelete(params)}
        />
    );

    const getPositionColor = (params: ICellRendererParams<PlanningPosition>) => (
        <Box
            style={{ background: params.data?.color }}
            sx={{
                width: 20,
                height: 20,
                borderRadius: '50%',
            }}
        />
    );
    const config: ConfigType<PlanningPosition> = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('permissions_setting_page.position')}`,
            primaryButtonAction: () => addPosition(),
            defaultButtonCaption: t('permissions_setting_page.reorder'),
            defaultButtonAction: () => handleOrderModal(),
        },
        isOnRowClickActive: true,
        table: {
            agGridProps: {
                onRowClicked: onClickPositionRow,
            },
            columns: [
                {
                    field: 'name',
                    headerName: 'Name',
                },
                {
                    field: 'color',
                    headerName: 'Color',
                    cellRenderer: getPositionColor,
                    cellClass: ['display-flex'],
                },
                {
                    type: 'actionMenu',
                    cellRenderer: getEmployeeFieldMoreButton,
                },
            ],
        },
    };

    const addPosition = () => {
        setSectionDialogMode('create');
        setPositionDialogOpen(true);
        setActivePositionData(undefined);
    };

    const handleOrderModal = () => {
        setIsOrderModalOpen(true);
    };

    const onSavePosition = (data: PlanningPositionCreationMutation, id?: number) => {
        if (sectionDialogMode === 'create') {
            createPlanningPosition(data)
                .then(data => {
                    const positions = [...planningPositions];
                    positions.push(data);
                    setPlanningPositions(positions);
                    showSnackbar(t('planning_setting_page.position_added'), 'success');
                    setPositionDialogOpen(false);
                    setActivePositionData(undefined);
                })
                .catch(error => {
                    handleError(error);
                });
        } else if (id) {
            updatePlanningPosition(id, data)
                .then(data => {
                    const positions = [...planningPositions];
                    const findPositionIndex = positions.findIndex(position => position.id === data.id);
                    positions[findPositionIndex] = data;
                    setPlanningPositions(positions);
                    setPositionDialogOpen(false);
                    setActivePositionData(undefined);
                    showSnackbar(t('planning_setting_page.position_edited'), 'success');
                })
                .catch(error => {
                    handleError(error);
                });
        }
    };

    const onClosePositionDialog = () => {
        setPositionDialogOpen(false);
        setActivePositionData(undefined);
    };

    const getReorderItems = (positions: PlanningPosition[]) => {
        return positions.map((position, index) => ({
            id: position.id,
            name: position.name,
            order: index,
        }));
    };

    const handleReorderSave = (reorderItems: ReorderItem[]) => {
        const reorderRequest = mapToReorderRequest(reorderItems);

        updatePlanningPositionOrders(reorderRequest)
            .then(() => {
                searchPlanningPosition({})
                    .then(planningPositions => {
                        setPlanningPositions(planningPositions);
                        setIsOrderModalOpen(false);
                    })
                    .catch(error => {
                        handleError(error);
                    });
            })
            .catch(error => {
                handleError(error);
            });
    };
    return (
        <>
            <CompanySettingsLayout
                options={config}
                data={[...planningPositions].sort((sectionA, sectionB) => {
                    return (sectionA.order ?? 0) - (sectionB.order ?? 0);
                })}
            />
            <PlanningPositionSettingsDialog
                open={positionDialogOpen}
                handleSave={onSavePosition}
                closeDialog={onClosePositionDialog}
                activePositionData={activePositionData}
            />
            {isOrderModalOpen && (
                <ReorderModal
                    initialReorderItems={getReorderItems(planningPositions)}
                    open={true}
                    onSave={handleReorderSave}
                    onClose={() => {
                        setIsOrderModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
