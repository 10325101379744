import { DialogContainer } from '@/Components/dialog-container/DialogContainer';
import { declinePendingLeaveRequest } from '@/domain/leave-request/LeaveRequest.service';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type Props = {
    leaveRequestId: number | undefined;
    onCancel: () => void;
    onDeclineRequest: () => void;
};

export const DeclineLeaveRequestDialog: FC<Props> = ({ leaveRequestId, onCancel, onDeclineRequest }) => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        declineComment: yup.string().trim().required(),
    });

    const { handleSubmit, control, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            declineComment: '',
        },
    });

    type FormValues = yup.InferType<typeof schema>;

    const declineRequest = (values: FormValues) => {
        if (!leaveRequestId) {
            return;
        }
        declinePendingLeaveRequest(leaveRequestId, values.declineComment)
            .then(() => {
                showSnackbar(t('leaves_page.messages.leave_request_declined'), 'success');
                reset();
                onDeclineRequest();
            })
            .catch(handleError);
    };

    return (
        <DialogContainer
            open={!!leaveRequestId}
            onClose={() => onCancel()}
            title={t('leaves_page.decline_request')}
            saveButtonText={t('leaves_page.decline')}
            onSave={handleSubmit(declineRequest, console.error)}
        >
            <Stack>
                <Controller
                    name='declineComment'
                    control={control}
                    render={({ field, fieldState }) => (
                        <FormControlLabel
                            label={t('leaves_page.comment')}
                            labelPlacement='top'
                            name={'declineComment'}
                            control={
                                <TextField
                                    fullWidth
                                    placeholder={t('leaves_page.place_provide_decline_reason')}
                                    InputProps={{ multiline: true, minRows: 4 }}
                                    value={field.value}
                                    onChange={field.onChange}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                />
                            }
                        />
                    )}
                />
            </Stack>
        </DialogContainer>
    );
};
