export type Page = {
    title: string;
    pathname: string;
    root: string;
};

export const pages = {
    home: {
        title: 'pages.home',
        pathname: '/',
        root: '/',
    },
    myProfile: {
        title: 'pages.my_profile',
        pathname: '/profile/personal-info',
        root: '/profile/personal-info',
    },
    myTasks: {
        title: 'pages.my_tasks',
        pathname: '/tasks',
        root: '/tasks',
    },
    addressBook: {
        title: 'pages.address_book',
        pathname: '/address-book',
        root: '/address-book',
    },
    people: {
        title: 'pages.manage_people',
        pathname: '/people',
        root: '/people',
    },
    planning: {
        title: 'pages.planning',
        pathname: '/planning',
        root: '/planning',
    },
    timesheets: {
        title: 'pages.timesheets',
        pathname: '/timesheets/monthly-summary',
        root: '/timesheets',
    },
    settings: {
        title: 'pages.settings',
        pathname: '/settings',
        root: '/settings',
    },
    reports: {
        title: 'pages.reports',
        pathname: '/reports',
        root: '/reports',
    },
    manageLeaves: {
        title: 'pages.manage_leaves',
        pathname: '/manage-leaves',
        root: '/manage-leaves',
    },
    myLeaves: {
        title: 'pages.my_leaves',
        pathname: '/profile/leaves',
        root: '/profile',
    },
    myReviews: {
        title: 'pages.my_reviews',
        pathname: '/profile/reviews',
        root: '/profile',
    },
    myObjectives: {
        title: 'pages.objectives',
        pathname: '/profile/objectives',
        root: '/profile',
    },
    user: {
        title: 'pages.my_settings',
        pathname: '/user',
        root: '/user',
    },
    payroll: {
        title: 'pages.payroll',
        pathname: '/payroll',
        root: '/payroll',
    },
    surveyTemplates: {
        title: 'pages.surveys',
        pathname: '/manage-surveys/surveys',
        root: '/manage-surveys',
    },
    reviews: {
        title: 'pages.reviews',
        pathname: '/reviews',
        root: '/reviews',
    },
    objectives: {
        title: 'pages.objectives',
        pathname: '/objectives',
        root: '/objectives',
    },
    companyDocuments: {
        title: 'pages.company_documents',
        pathname: '/documents/company-documents',
        root: '/documents',
    },
    profileDocuments: {
        title: 'pages.profile_documents',
        pathname: '/profile/documents',
        root: '/profile',
    },
};
