import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2Content, TreeItem2GroupTransition, TreeItem2IconContainer, TreeItem2Root } from '@mui/x-tree-view/TreeItem2';
import { unstable_useTreeItem2 as useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import { forwardRef, HTMLAttributes, Ref } from 'react';
import { DepartmentTreeItemLabel, ItemLabelProps } from '@/page/setting/organization/department/department-tree/DepartmentTreeItemLabel';
import { TreeViewBaseItem } from '@mui/x-tree-view';
import { DepartmentNode } from '@/domain/department/Department.model';
import { alpha } from '@mui/material';

export type CustomTreeViewBaseItem = TreeViewBaseItem<{ originalData: DepartmentNode }>;

export type CustomTreeItemProps = Omit<UseTreeItem2Parameters, 'rootRef'> &
    Omit<HTMLAttributes<HTMLLIElement>, 'onFocus'> & {
        onAddClick?: ItemLabelProps['onAddClick'];
        onEditClick?: ItemLabelProps['onEditClick'];
        onDeleteClick?: ItemLabelProps['onDeleteClick'];
    };

export const DepartmentTreeItem = forwardRef<HTMLLIElement, CustomTreeItemProps>((props: CustomTreeItemProps, ref: Ref<HTMLLIElement>) => {
    const { id, itemId, label, disabled, children, onAddClick = () => {}, onEditClick = () => {}, onDeleteClick = () => {}, ...other } = props;

    const useTreeItem2Value = useTreeItem2({
        id,
        itemId,
        children,
        label,
        disabled,
        rootRef: ref,
    });
    const { getRootProps, getContentProps, getIconContainerProps, getLabelProps, getGroupTransitionProps, status, publicAPI } = useTreeItem2Value;

    // get the department data to use it for adding, updating ..
    // The RichTreeView component use its own data structure, so we need to get the original data
    // from publicAPI returned by the useTreeItem2 hook
    const department = (publicAPI?.getItem(itemId) as CustomTreeViewBaseItem).originalData;

    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <TreeItem2Content
                    {...getContentProps()}
                    sx={{
                        minHeight: '44px',
                        ':hover :not(ul) .display-on-hover': {
                            display: 'flex',
                        },
                        ':hover :not(ul) .more-actions': {
                            display: 'none',
                        },
                        gap: 0.5,
                    }}
                >
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <DepartmentTreeItemLabel
                        {...getLabelProps()}
                        item={department}
                        label={label}
                        onAddClick={onAddClick}
                        onEditClick={onEditClick}
                        onDeleteClick={onDeleteClick}
                    />
                </TreeItem2Content>
                {children && (
                    <TreeItem2GroupTransition
                        {...getGroupTransitionProps()}
                        sx={theme => ({ marginLeft: '15px', borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}` })}
                    />
                )}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});

DepartmentTreeItem.displayName = 'DepartmentTreeItem';
