import { flatTreeNodeSchema } from '@/Components/autocomplete-wrapper/TreeAutoComplete/useTreeAutoComplete.schema';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { ContractType } from '@/domain/employment/Employment.model';
import { Job } from '@/domain/job/Job.model';
import { LocationWithoutCircularReference } from '@/domain/location/Location.model';
import { getCostCenterAssignmentListObjectSchema } from '@/page/cost-center/CostCentersAssignment.schema';
import { getLocalDateTestConfig, LocalDate } from '@/utils/datetime.util';
import { getNull } from '@/utils/object.util';
import i18next from 'i18next';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEmploymentSchema = () => {
    return yup.object().shape({
        startDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        employmentUpdateReason: yup.string().required(),
        contractType: yup.string().required().oneOf(Object.values(ContractType), i18next.t('general.validations.required')),
        probationEndDate: yup.string<LocalDate>().default(getNull()).nullable().test(getLocalDateTestConfig()),
        items: yup.array().of(getEmploymentItemSchema()).required(),
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEmploymentItemSchema = () => {
    return yup.object().shape({
        id: yup.number().nullable(),
        department: flatTreeNodeSchema.required(),
        managers: yup
            .array()
            .of(yup.object() as yup.ObjectSchema<EmployeeAvatar>)
            .default([])
            .required(),
        employmentCostCenters: getCostCenterAssignmentListObjectSchema(),
        // Fix the type of location, department, job,
        location: yup.object().required() as unknown as yup.Schema<LocationWithoutCircularReference>,
        job: yup.object().required() as unknown as yup.Schema<Job>,
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEmploymentDialogSchema = () => getEmploymentSchema().omit(['contractType', 'probationEndDate']);

export type EmploymentDialogFormValues = yup.InferType<ReturnType<typeof getEmploymentDialogSchema>>;
