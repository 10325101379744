import { FC, useState } from 'react';
import { showSnackbar } from '@/utils/snackbar.util';
import { useTranslation } from 'react-i18next';
import { handleError } from '@/utils/api.util';
import { updateEmployeeReview } from '@/domain/employee-review/EmployeeReview.service';
import { EmployeeReview, UpdateEmployeeReviewMutation } from '@/domain/employee-review/EmployeeReview.model';
import { FilterReviewers, ReviewStatus } from '@/domain/review/Review.model';
import { useGetEmployeeReviewAsContributor } from '@/hooks/employee-review/EmployeeReview.hook';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { EmployeeReviewCycleList } from '@/page/review/employee-review-cycle-list/EmployeeReviewCycleList';
import { EmployeeReviewFilter } from '@/page/review/employee-review-filter/EmployeeReviewFilter';
import { useAgGridWrapper } from '@/Components/ag-grid-wrapper/useAgGridWrapper';

export const EmployeeReviewsMeetingPage: FC = () => {
    const { t } = useTranslation();
    const [reviewsFilter, setReviewsFilter] = useState<FilterReviewers>(FilterReviewers.ONGOING_REVIEWS);

    const request = {
        reviewStatuses: reviewsFilter === FilterReviewers.ALL_REVIEWS ? [ReviewStatus.STARTED, ReviewStatus.CLOSED] : [ReviewStatus.STARTED],
    };

    const {
        data: employeeReviews = [],
        refetch: refetchEmployeeReviews,
        error: employeeReviewError,
        isLoading: isEmployeeReviewLoading,
        isError: isEmployeeReviewError,
    } = useGetEmployeeReviewAsContributor({
        employeeReviewSearch: request,
    });

    const onUpdateReviewers = (request: UpdateEmployeeReviewMutation, employeeReviewId: number) => {
        updateEmployeeReview(employeeReviewId, request)
            .then(() => {
                refetchEmployeeReviews().catch(handleError);
            })
            .then(() => showSnackbar(t('reviews.messages.reviewers_updated'), 'success'))
            .catch(handleError);
    };

    // agGridWrapper is initialized outside because we need to get the selected rows from the grid and pass it in other components
    // see ReviewCyclePage
    const agGridWrapper = useAgGridWrapper<EmployeeReview>();

    return (
        <StateHandler isLoading={isEmployeeReviewLoading} isError={isEmployeeReviewError} error={employeeReviewError}>
            <EmployeeReviewCycleList
                employeeReviews={employeeReviews}
                filterControls={<EmployeeReviewFilter value={reviewsFilter} onChange={value => setReviewsFilter(value)} />}
                isSearchAvailable
                onUpdate={() => refetchEmployeeReviews().catch(handleError)}
                onUpdateReviewers={onUpdateReviewers}
                agGridWrapper={agGridWrapper}
                showReviewNameColumn
                disableSelection
            />
        </StateHandler>
    );
};
