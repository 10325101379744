import {
    FieldDefinition,
    SectionDefinition,
    SectionDefinitionCreateRequest,
    SectionDefinitionIncludeInPayrollMutation,
    SectionDefinitionUpdateRequest,
    SectionFieldType,
    SectionType,
} from '@/domain/section-setting/Section.model';
import { sectionDefinitionApi } from '@/api/section-definition/SectionDefinition.api';
import { ReportFieldType } from '@/domain/report/Report.model';
import {
    getContractTypeTranslationKey,
    getGenderTranslationKey,
    getMaritalStatusTranslationKey,
    getUserEmploymentStatusTranslationKey,
    getUserStatusTranslationKey,
} from '@/domain/employee/Employee.service';
import { getTerminationTypeTranslationKey } from '@/domain/employment/Employment.service';
import { TerminationType } from '@/domain/employment/Employment.model';
import i18next from 'i18next';

import { OrderMutation } from '@/domain/common';

export function getSectionDefinitions(): Promise<SectionDefinition[]> {
    return sectionDefinitionApi.getSectionDefinitions();
}

export const getSectionDefinitionById = (id: number): Promise<SectionDefinition> => {
    return sectionDefinitionApi.getSectionDefinitionById(id);
};

export const createSectionDefinition = (mutation: SectionDefinitionCreateRequest): Promise<SectionDefinition> => {
    return sectionDefinitionApi.createSectionDefinition(mutation);
};

export const updateSectionDefinition = (id: number, mutation: SectionDefinitionUpdateRequest): Promise<SectionDefinition> => {
    return sectionDefinitionApi.updateSectionDefinition(id, mutation);
};

export const deleteSectionDefinition = (id: number): Promise<void> => {
    return sectionDefinitionApi.deleteSectionDefinition(id);
};

export const updateSectionDefinitionsOrder = (mutation: OrderMutation[]): Promise<void> => {
    return sectionDefinitionApi.updateSectionDefinitionsOrder(mutation);
};

/**
 * Update the includeInPayroll field of a section definition
 * @param id
 * @param mutation
 * @returns the updated section definition
 */
export const updateIncludeInPayrollSectionDefinition = async (
    id: SectionDefinition['id'],
    mutation: SectionDefinitionIncludeInPayrollMutation,
): Promise<SectionDefinition> => {
    return sectionDefinitionApi.updateIncludeInPayrollSectionDefinition(id, mutation);
};

/**
 * Check if the given type is a valid SectionType
 * @param type
 * @returns true if the given type is a valid SectionType
 */
export const isSectionType = (type: string): type is SectionType => {
    return type in SectionType;
};
/**
 * Check if the given type is a valid SectionFieldType
 * @param type
 * @returns true if the given type is a valid SectionFieldType
 */
export const isSectionFieldType = (type: string): type is SectionFieldType => {
    return type in SectionFieldType;
};

export const isSameFieldDefinition = (a: Pick<FieldDefinition, 'id' | 'fieldType'>, b: Pick<FieldDefinition, 'id' | 'fieldType'>): boolean => {
    const isCustomField = (fieldType: ReportFieldType) => fieldType === 'EMPLOYEE_CUSTOM_FIELD';

    const isSameNotCustomField = (a: { fieldType: ReportFieldType }, b: { fieldType: ReportFieldType }) =>
        a.fieldType === b.fieldType && !isCustomField(a.fieldType) && !isCustomField(b.fieldType);

    const isSameCustomField = (a: Pick<FieldDefinition, 'id' | 'fieldType'>, b: Pick<FieldDefinition, 'id' | 'fieldType'>) =>
        isCustomField(a.fieldType) && isCustomField(b.fieldType) && a.id === b.id;

    return isSameNotCustomField(a, b) || isSameCustomField(a, b);
};

export const getEnumTranslation = (fieldType: ReportFieldType, enumValue: string): string => {
    // TODO : remove this when we have a better way to handle enums
    if (fieldType === 'CURRENT_WORKING_PATTERN_TYPE' || fieldType === 'WORKING_PATTERN_TYPE') {
        return enumValue ? i18next.t('employee.work_pattern.type.enum', { context: enumValue }) : '';
    } else if (fieldType === 'CURRENT_EMPLOYMENT_TERMINATION_REASON' || fieldType === 'EMPLOYMENT_TERMINATION_REASON') {
        return enumValue ? i18next.t('employee.employment.termination_reason.enum', { context: enumValue }) : '';
    } else if (fieldType === 'CURRENT_EMPLOYMENT_TERMINATION_TYPE' || fieldType === 'EMPLOYMENT_TERMINATION_TYPE') {
        return i18next.t(getTerminationTypeTranslationKey(enumValue as TerminationType));
    }

    return enumValue ? i18next.t(getEnumTranslationKey(fieldType), { context: enumValue }) : '';
};

const getEnumTranslationKey = (fieldType: ReportFieldType): string => {
    switch (fieldType) {
        case 'EMPLOYEE_GENDER':
            return getGenderTranslationKey();
        case 'EMPLOYEE_MARITAL_STATUS':
            return getMaritalStatusTranslationKey();
        case 'EMPLOYEE_STATUS':
            return getUserStatusTranslationKey();
        case 'CURRENT_EMPLOYMENT_CONTRACT_TYPE':
        case 'EMPLOYMENT_CONTRACT_TYPE':
            return getContractTypeTranslationKey();
        case 'CURRENT_EMPLOYMENT_CREATION_REASON':
        case 'EMPLOYMENT_CREATION_REASON':
            return 'employee.employment.employment_create_reason';
        case 'REVIEW_FEEDBACK_CONTRIBUTOR_TYPE':
            return 'reviews.enums.contributor_types.enum';
        case 'REVIEW_FEEDBACK_TYPE':
            return 'reviews.enums.feedback_types.enum';
        case 'REVIEW_FEEDBACK_ITEM_TYPE':
            return 'reviews.enums.item_types.enum';
        case 'CURRENT_EMPLOYMENT_STATUS':
        case 'EMPLOYMENT_STATUS':
            return getUserEmploymentStatusTranslationKey();
        case 'LEAVE_TYPE_POLICY_LEAVE_TYPE_ALLOWANCE_TYPE':
            return 'leaves.leave_types.enums.allowance_types.enum';
        case 'TIMESHEET_PAYMENT_STATUS':
            return 'timesheets.enums.payments_status.enum';
        case 'EMPLOYEE_SECTION_ROW_STATUS':
        case 'ADDRESS_STATUS':
            return 'employee.sections.enums.status.enum';
        default:
            return fieldType;
    }
};
