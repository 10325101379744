import * as yup from 'yup';
import { surveySetupEndDateValidation, surveySetupStartDateValidation, surveySetupTimeValidation } from '@/page/survey/survey-setup/SurveySetupFormSchema';

// we cannot type the getSurveySetupFormSchema return type function with ObjectSchema<SurveySetupFormType> because SurveySetupFormType is inferring from the function
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEditSurveySetupFormSchema = () => {
    return yup.object().shape({
        sendNotificationNow: yup.boolean().required(),
        startDate: surveySetupStartDateValidation,
        endDate: surveySetupEndDateValidation,
        startTime: surveySetupTimeValidation,
        endTime: surveySetupTimeValidation,
    });
};

type getEditSurveySetupFormSchemaType = ReturnType<typeof getEditSurveySetupFormSchema>;
export type EditSurveySetupFormSchemaType = yup.InferType<getEditSurveySetupFormSchemaType>;
