import { FC } from 'react';
import { Autocomplete, FormControlLabel, TextField } from '@mui/material';
// todo remove link with planning / scheduler components
import { SelectOption } from '@/page/planning/scheduler-calendar/types';
import { defaultToNull } from '@/utils/object.util';

type SelectResourceProps = {
    label?: string;
    value?: number;
    onUpdate?: (val: number | undefined) => void;
    options: SelectOption[];
    clearButton?: boolean;
    disabled?: boolean;
    isFocusEnabled?: boolean;
    placeHolder?: string;
    isError?: boolean;
};

export const SelectResource: FC<SelectResourceProps> = ({
    label,
    placeHolder,
    value,
    onUpdate,
    options,
    clearButton = false,
    disabled = false,
    isFocusEnabled = false,
    isError,
}) => {
    return (
        <FormControlLabel
            label={label}
            aria-label={label ?? placeHolder ?? 'Select Resource'}
            labelPlacement='top'
            sx={{ width: '100%' }}
            control={
                <Autocomplete
                    // Todo remove this functional id
                    id='area-select'
                    value={defaultToNull(options.find(option => option.id === value))}
                    options={options ?? []}
                    disabled={disabled}
                    disableClearable={!clearButton}
                    disableListWrap={true}
                    fullWidth
                    getOptionLabel={option => option.name}
                    onChange={(_, newValue) => {
                        onUpdate?.(newValue?.id !== undefined ? Number(newValue.id) : undefined);
                    }}
                    renderInput={params => <TextField error={isError} {...params} placeholder={placeHolder} autoFocus={isFocusEnabled} />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
            }
        />
    );
};
