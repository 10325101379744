import { FieldSwitch } from '@/Components/form/field-switch/FieldSwitch';
import { RichTextEditor } from '@/Components/rich-text-editor/RichTextEditor';
import { Skill } from '@/domain/skill/Skill.model';
import { useGetReviewsCategories } from '@/hooks/review-category/ReviewCategory.hook';
import { useGetRoles } from '@/hooks/role/Role.hook';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer, FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import { reviewSkillSettingFormSchema, ReviewSkillSettingFormValues } from '@/page/setting/review/skill/ReviewSkillSettingForm.schema';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, FormControlLabel, Paper, Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const ReviewSkillSettingForm: FC<{
    reviewSkill?: Skill;
    onDelete: (id: number) => void;
    onSave: (data: ReviewSkillSettingFormValues) => void;
}> = ({ reviewSkill, onDelete, onSave }) => {
    const { t } = useTranslation();
    const formMethods = useForm<ReviewSkillSettingFormValues>({
        resolver: yupResolver(reviewSkillSettingFormSchema),
        defaultValues: {
            name: reviewSkill?.name ?? '',
            description: reviewSkill?.description ?? '',
            categoryId: reviewSkill?.category.id,
            allRolesSelected: reviewSkill?.allRolesSelected ?? true,
            availableForRoles: reviewSkill?.roles.map(role => role.id) ?? [],
        },
    });

    const { data: reviewCategories, isLoading: isLoadingReviewCategoriesOptions } = useGetReviewsCategories();
    const { data: roles, isLoading: isLoadingRoles } = useGetRoles();

    const {
        handleSubmit,
        control,
        watch,
        formState: { isDirty },
    } = formMethods;

    const footerButtons: FooterActionsProps['actions'] = [];

    if (reviewSkill) {
        footerButtons.push({
            key: 'delete',
            name: 'delete',
            onClick: () => onDelete(reviewSkill.id),
            children: t('general.delete'),
            color: 'error',
            variant: 'contained',
        });
    }

    footerButtons.push({
        key: 'save',
        name: 'save',
        onClick: () => {
            handleSubmit(onSave, console.error)();
        },
        disabled: !isDirty,
        children: reviewSkill ? t('general.update') : t('general.create'),
        variant: 'contained',
    });

    const allRolesSelected = watch('allRolesSelected');

    return (
        <FormProvider {...formMethods}>
            <ContentContainer>
                <Stack component={Paper} p={2} direction='column' flex='1'>
                    <Stack sx={{ width: '40%' }} gap={2} direction='column'>
                        <Stack>
                            <Typography variant='body1bold'>{t('reviews_settings_page.skill_form.about')}</Typography>
                        </Stack>
                        <Stack gap={1}>
                            <Controller
                                name='name'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControlLabel
                                        label={t('reviews_settings_page.skill_form.skill_name')}
                                        control={<TextField {...field} fullWidth error={!!error} helperText={error?.message} />}
                                    />
                                )}
                            />

                            <Typography variant='body1bold'>{t('reviews_settings_page.skill_form.about')}</Typography>
                            <Controller
                                name='description'
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Box>
                                        <RichTextEditor defaultValue={reviewSkill?.description ?? ''} onUpdate={onChange} minHeight='100px' />
                                    </Box>
                                )}
                            />

                            <Controller
                                name='categoryId'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControlLabel
                                        label={t('reviews_settings_page.skill_form.category')}
                                        labelPlacement='top'
                                        control={
                                            <Autocomplete
                                                fullWidth
                                                options={reviewCategories ?? []}
                                                loading={isLoadingReviewCategoriesOptions}
                                                getOptionLabel={option => getLabelTranslation(option.name)}
                                                value={reviewCategories?.find(category => category.id === field.value) ?? getNull()}
                                                onChange={(_event, newValue) => {
                                                    field.onChange(newValue?.id ?? getNull());
                                                }}
                                                renderInput={params => <TextField {...params} error={!!error} helperText={error?.message} />}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Stack>
                        <Stack>
                            <Typography variant='body1bold'>{t('reviews_settings_page.skill_form.availability')}</Typography>
                        </Stack>
                        <Stack>
                            <FormControlLabel
                                sx={{ mr: 0 }}
                                label={t('reviews_settings_page.skill_form.all_roles_selected')}
                                labelPlacement='end'
                                control={<FieldSwitch control={control} name='allRolesSelected' />}
                            />

                            {!allRolesSelected && (
                                <Controller
                                    name='availableForRoles'
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <FormControlLabel
                                            label={t('reviews_settings_page.skill_form.available_for')}
                                            control={
                                                <Autocomplete
                                                    fullWidth
                                                    multiple
                                                    loading={isLoadingRoles}
                                                    options={roles ?? []}
                                                    getOptionLabel={option => option.name}
                                                    value={roles?.filter(role => field.value.includes(role.id)) ?? []}
                                                    onChange={(_event, newValue) => {
                                                        field.onChange(newValue.map(role => role.id));
                                                    }}
                                                    renderInput={params => <TextField {...params} error={!!error} helperText={error?.message} />}
                                                />
                                            }
                                        />
                                    )}
                                />
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </ContentContainer>

            <Footer>
                <FooterActions actions={footerButtons} />
            </Footer>
        </FormProvider>
    );
};
