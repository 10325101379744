import { MyColleaguesPage } from '@/page/people/MyColleaguesPage';
import { OffboardingPage } from '@/page/people/OffboardingPage';
import { OnBoardingPage } from '@/page/people/OnBoardingPage';
import { PeopleHeader } from '@/page/people/PeopleHeader';
import { PageContainer } from '@/routes/PageContainer';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RoutesConfig } from '@/routes/Routes.type';
import { CircularProgress, Stack } from '@mui/material';
import { lazy, Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { ManageEmployeeProfilePendingRequestPage } from './ManageEmployeeProfilePendingRequestPage';

// This page is lazy loaded to avoid to add react-flow to the main bundle since it's only used in this page
// eslint-disable-next-line react-refresh/only-export-components
const OrganizationChartPage = lazy(() => import('@/page/people/organization-chart-page/OrganizationChartPage'));

const config: RoutesConfig[] = [
    {
        path: '',
        breadCrumb: [{ nameKey: 'pages.people' }],
        component: <MyColleaguesPage />,
    },
    {
        path: 'organization-chart',
        breadCrumb: [],
        component: (
            <Suspense fallback={<CircularProgress />}>
                <OrganizationChartPage />
            </Suspense>
        ),
    },
    {
        path: 'onboarding-form',
        breadCrumb: [{ nameKey: 'pages.onboarding' }],
        component: <OnBoardingPage />,
    },
    {
        path: 'offboarding-form/:employeeId',
        breadCrumb: [{ nameKey: 'pages.offboarding' }],
        component: <OffboardingPage />,
    },
    {
        path: 'employee-requests',
        breadCrumb: [{ nameKey: 'pages.profile_update_pending_request' }],
        component: <ManageEmployeeProfilePendingRequestPage />,
    },
];

export const peopleRoutes: RouteObject[] = [
    {
        path: 'people',
        element: (
            <Stack flex='1' gap={2}>
                <PeopleHeader />
                <Outlet />
            </Stack>
        ),
        errorElement: <RouterBoundary />,
        children: [
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}>{config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
