import { FC, ReactNode } from 'react';
import { Stack } from '@mui/system';
import { Link, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DepartmentNode } from '@/domain/department/Department.model';
import { StackedAvatars } from '@/Components/stacked-avatar/StackedAvatars';
import { Add01Icon } from 'hugeicons-react';

export type ItemLabelProps = {
    item: DepartmentNode;
    label: ReactNode;
    onAddClick: (department: DepartmentNode) => void;
    onEditClick: (department: DepartmentNode) => void;
    onDeleteClick: (department: DepartmentNode) => void;
};
export const DepartmentTreeItemLabel: FC<ItemLabelProps> = props => {
    const { item, label, onAddClick, onEditClick, onDeleteClick } = props;
    const { t } = useTranslation();

    const disableDelete = item.children.length > 0;

    return (
        <Stack direction={'row'} alignItems={'center'} flex={1} gap={3}>
            <Typography>{label}</Typography>

            <Stack direction={'row'} display={'none'} className={'display-on-hover'} justifyContent={'space-between'} flex={1}>
                <Stack direction={'row'} gap={3}>
                    {/*ADD*/}
                    <Link
                        component='button'
                        color='primary'
                        sx={{ display: 'flex', alignItems: 'center' }}
                        onClick={e => {
                            e.stopPropagation();
                            onAddClick(item);
                        }}
                    >
                        <Add01Icon /> {t('settings_organization.departments.sub_departments')}
                    </Link>

                    {/*EDIT*/}
                    <Link
                        component='button'
                        color='primary'
                        onClick={e => {
                            e.stopPropagation();
                            onEditClick(item);
                        }}
                    >
                        {t('general.edit')}
                    </Link>

                    {/*DELETE. SHOWS A TOOLTIP IF HAS CHILDREN*/}
                    <Tooltip title={disableDelete ? t('settings_organization.departments.unable_to_delete_parent') : ''}>
                        <Link
                            component={'button'}
                            color={disableDelete ? 'text.disabled' : 'primary'}
                            underline={disableDelete ? 'none' : 'hover'}
                            onClick={e => {
                                e.stopPropagation();
                                if (disableDelete) {
                                    return;
                                }
                                onDeleteClick(item);
                            }}
                            sx={{
                                ':hover': {
                                    cursor: disableDelete ? 'initial' : 'pointer',
                                },
                            }}
                        >
                            {t('general.delete')}
                        </Link>
                    </Tooltip>
                </Stack>

                {!!item.managers.length && (
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Typography variant={'body1'}>{t('settings_organization.departments.managers')}</Typography>
                        <StackedAvatars employeeAvatars={item.managers} />
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
