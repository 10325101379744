import { FC } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/stores/store';
import { canContributeEmployeeReview, canManageReview } from '@/domain/permission/Permission.service';
import { Paper } from '@mui/material';

type ReviewsTabSection =
    | 'tasks'
    | 'manage-reviews'
    | 'onboarding-employee-reviews'
    | 'offboarding-employee-reviews'
    | 'team-reviews'
    | 'one-shot-employee-reviews';

export const ReviewsHeader: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const location = useLocation();

    const tabOptions: { name: string; value: `/reviews/${ReviewsTabSection}` }[] = [];

    if (canContributeEmployeeReview(policies)) {
        tabOptions.push({
            name: t('reviews.tabs.tasks'),
            value: '/reviews/tasks',
        });
        tabOptions.push({
            name: t('reviews.tabs.team_reviews'),
            value: '/reviews/team-reviews',
        });
    }
    if (canManageReview(policies)) {
        tabOptions.push({
            name: t('reviews.tabs.manage_reviews'),
            value: '/reviews/manage-reviews',
        });
        tabOptions.push({
            name: t('reviews.tabs.onboarding_reviews'),
            value: '/reviews/onboarding-employee-reviews',
        });
        tabOptions.push({
            name: t('reviews.tabs.offboarding_reviews'),
            value: '/reviews/offboarding-employee-reviews',
        });
        tabOptions.push({
            name: t('reviews.tabs.one_shot_reviews'),
            value: '/reviews/one-shot-employee-reviews',
        });
    }

    const currentTab = tabOptions.find(option => option.value === location.pathname)?.value ?? tabOptions[0].value;

    return (
        <Paper
            sx={{
                borderRadius: 1,
            }}
        >
            <Tabs value={currentTab} onChange={(_e, value) => navigate(value)} aria-label='Review Tabs Control'>
                {tabOptions.map(item => (
                    <Tab key={item.name} label={item.name} value={item.value} />
                ))}
            </Tabs>
        </Paper>
    );
};
