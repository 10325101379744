import { AvailableReportGroupedField, Report, ReportCategory, ReportCreateMutation, ReportPreview, ReportRow, ReportType } from '@/domain/report/Report.model';
import {
    convertReportColumnToFieldDefinitionMutation,
    convertReportFilterToReportFilterMutation,
    createReport,
    getAvailableReportGroupedFields,
    getReport,
    getReportRows,
    getReportRowsPreview,
    searchReports,
} from '@/domain/report/Report.service';
import { UseMutationResult, UseQueryResult } from '@/page/Query.type';
import { ReportColumn } from '@/page/report/report-columns-selector/ReportColumnsSelector';
import { ReportFilterItemBar } from '@/page/report/report-editor-bar/ReportEditorBar';
import { handleError } from '@/utils/api.util';
import { useCallback, useEffect, useState } from 'react';

export const useGetReport = ({ reportId, reportType }: { reportId: number; reportType: ReportType }): UseQueryResult<Report> => {
    const [report, setReport] = useState<Report>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchReport = useCallback(async () => {
        if (reportId) {
            try {
                const reportData = await getReport(reportId, reportType);
                setReport(reportData);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        }
    }, [reportId, reportType]);

    useEffect(() => {
        fetchReport().catch(handleError);
    }, [fetchReport]);

    return {
        data: report,
        setData: setReport,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchReport(),
    };
};

export const useCreateReport = (): UseMutationResult<Report, ReportCreateMutation> => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const mutateReport = useCallback(async (mutationVariables: ReportCreateMutation) => {
        setIsPending(true);
        try {
            const result = await createReport(mutationVariables);
            setIsPending(false);
            return result;
        } catch (error) {
            setError(error);
            throw error;
        }
    }, []);

    return {
        mutate: mutateReport as (variables: ReportCreateMutation) => Promise<Report>,
        isPending,
        isError: !!error,
        error,
    };
};

export const useGetReportRows = ({ reportId, reportType }: { reportId: number; reportType: ReportType }): UseQueryResult<ReportRow[]> => {
    const [rows, setRows] = useState<ReportRow[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchReportRows = useCallback(async () => {
        if (!reportId || !reportType) {
            return;
        }
        try {
            const rowsData = await getReportRows(reportId, reportType);
            setRows(rowsData);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [reportId, reportType]);

    useEffect(() => {
        fetchReportRows().catch(handleError);
    }, [fetchReportRows]);

    return {
        data: rows,
        setData: setRows,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchReportRows(),
    };
};

export const useGetReportRowsPreview = ({
    reportType,
    sectionDefinitionId,
}: {
    reportType: ReportType | undefined;
    sectionDefinitionId: number | undefined;
}): UseQueryResult<ReportRow[]> & {
    refetchReportRowsPreview: (filters: ReportFilterItemBar[], columns: ReportColumn[], abortController?: AbortController) => Promise<void>;
} => {
    const [rows, setRows] = useState<ReportRow[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchReportRowsPreview = useCallback(
        async (filters: ReportFilterItemBar[], columns: ReportColumn[], abortController?: AbortController) => {
            if (!reportType) {
                return;
            }
            try {
                const previewParams = convertReportToPreviewParams(reportType, filters, columns, sectionDefinitionId);
                const rowsData = await getReportRowsPreview(previewParams, { signal: abortController?.signal });
                setRows(rowsData);
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        },
        [reportType, sectionDefinitionId],
    );

    return {
        data: rows,
        setData: setRows,
        isLoading,
        isError: !!error,
        error,
        refetchReportRowsPreview: fetchReportRowsPreview,
        refetch: () => fetchReportRowsPreview([], []),
    };
};

const convertReportToPreviewParams = (
    reportType: ReportType,
    filters: ReportFilterItemBar[],
    columns: ReportColumn[],
    reportItemId?: number,
): ReportPreview => {
    const params = {
        reportType,
        fieldDefinitions: columns?.filter(c => c.visible).map(convertReportColumnToFieldDefinitionMutation) ?? [],
        filters: filters?.filter(f => !!f.value).map(convertReportFilterToReportFilterMutation) ?? [],
    };

    if ('EMPLOYEE_SECTION_REPORT' === reportType || 'REVIEW_FEEDBACK_REPORT' === reportType) {
        return { ...params, reportType, reportItemId: reportItemId };
    }

    return params;
};

export const useGetReports = (
    { reportCategory }: { reportCategory: ReportCategory },
    { enabled }: { enabled: boolean } = { enabled: true },
): UseQueryResult<Report[]> => {
    const [reports, setReports] = useState<Report[] | undefined>([]);
    const [isLoading, setIsLoading] = useState<boolean>(enabled);
    const [error, setError] = useState<unknown>();

    const fetchReports = useCallback(async () => {
        if (!enabled) {
            return;
        }
        try {
            const reportsData = await searchReports(reportCategory);

            setReports(reportsData);
            setError(undefined);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, [enabled, reportCategory]);

    useEffect(() => {
        fetchReports().catch(handleError);
    }, [fetchReports]);

    return {
        data: reports,
        setData: setReports,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchReports(),
    };
};

type useGetAvailableReportFieldsProps =
    | { reportType: ReportType | undefined }
    | {
          reportType: 'EMPLOYEE_SECTION_REPORT';
          sectionDefinitionId: number;
      };

/**
 * Hook to get the list of filter for a reportType
 *
 * Load custom fields and add them to the list
 *
 */
export const useGetAvailableReportGroupedFields = (props: useGetAvailableReportFieldsProps): UseQueryResult<AvailableReportGroupedField[]> => {
    const [groups, setGroups] = useState<AvailableReportGroupedField[] | undefined>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const sectionDefinitionId = 'sectionDefinitionId' in props ? props.sectionDefinitionId : undefined;
    const reportType = props.reportType;

    const fetchReportFilters = useCallback(async () => {
        if (reportType) {
            try {
                const data = await getAvailableReportGroupedFields(reportType, sectionDefinitionId);
                setGroups(data);
            } catch (error) {
                console.error({ error });
                setError('An error occurred while fetching reports');
            }
            setIsLoading(false);
        }
    }, [reportType, sectionDefinitionId]);

    useEffect(() => {
        fetchReportFilters().catch(handleError);
    }, [fetchReportFilters]);

    return {
        data: groups,
        setData: setGroups,
        isLoading,
        isError: !!error,
        error,
        refetch: () => fetchReportFilters(),
    };
};
