import { EmptyState } from '@/Components/empty-state/EmptyState';
import { ClipboardEmptyStateIcon } from '@/Components/empty-state/EmptyStateIcons';
import { StackedAvatars } from '@/Components/stacked-avatar/StackedAvatars';
import { StateHandler } from '@/Components/state-handler/StateHandler';
import { FiltersBar, FilterType, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { EmployeeReviewFeedbackResult } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { flatEmployeeReviewFeedbacksItems, getRatingQuestionsFromFeedbacksItems } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { ReviewReportMenu } from '@/page/review/review-report/ReviewReportMenu';
import { Paper, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getPropsForMatrixMode } from '@/domain/report/Report.service';
import { Matrix } from '@/Components/matrix/Matrix';
import { getLabelTranslation } from '@/utils/language.util';

type ReviewReportMatrixViewProps = {
    feedbacksItemsGroupByEmployee: EmployeeReviewFeedbackResult[];
    filters: FilterType[];
    onFiltersChange: (filters: FilterType[]) => void;
};

export const ReviewReportMatrixView: FC<ReviewReportMatrixViewProps> = ({ feedbacksItemsGroupByEmployee, filters, onFiltersChange }) => {
    const { t } = useTranslation();

    const [groupingFilters, setGroupingFilters] = useState<[SelectFilter, SelectFilter]>([
        {
            key: 'xAxis',
            filterName: t('reports.group_by_x_label'),
            type: 'select',
            availableRules: [],
            rule: 'EQUALS',
            selectMode: 'SYNC',
            defaultVisibility: 'visible',
            options: [],
        },
        {
            key: 'yAxis',
            filterName: t('reports.group_by_y_label'),
            type: 'select',
            availableRules: [],
            rule: 'EQUALS',
            selectMode: 'SYNC',
            defaultVisibility: 'visible',
            options: [],
        },
    ]);

    const [xAxis, yAxis] = groupingFilters;

    useDeepCompareEffect(() => {
        // todo add service to flatten feedbacksItems
        const flattenFeedbacksItems = flatEmployeeReviewFeedbacksItems(feedbacksItemsGroupByEmployee);
        const questions = getRatingQuestionsFromFeedbacksItems(flattenFeedbacksItems);
        const questionsOptions = questions.map(q => ({ label: q, value: q }));
        setGroupingFilters([
            {
                ...xAxis,
                options: questionsOptions,
            },
            {
                ...yAxis,
                options: questionsOptions,
            },
        ]);
    }, [feedbacksItemsGroupByEmployee]);

    const xAxisValue = xAxis.value?.[0]?.value?.toString();
    const yAxisValue = yAxis.value?.[0]?.value?.toString();

    const getEmployeeRatingByQuestion = (items: EmployeeReviewFeedbackResult['items'], question: string) => {
        const item = items.find(item => getLabelTranslation(item.reviewItem.title) === question);
        if (!item) {
            return;
        }
        return item.reviewItem.rating?.items.find(ratingItem => item.score === ratingItem.score);
    };

    const matrixRows: {
        employee: EmployeeAvatar;
        questionX: string;
        questionY: string;
    }[] = feedbacksItemsGroupByEmployee.map(({ employee, items }) => ({
        employee,

        questionX: xAxisValue
            ? `score_${getEmployeeRatingByQuestion(items, xAxisValue)?.score}_${getLabelTranslation(getEmployeeRatingByQuestion(items, xAxisValue)?.label) ?? ''}`
            : '',
        questionY: yAxisValue
            ? `score_${getEmployeeRatingByQuestion(items, yAxisValue)?.score}_${getLabelTranslation(getEmployeeRatingByQuestion(items, yAxisValue)?.label) ?? ''}`
            : '',
    }));

    const groupingFilterBars = (
        <FiltersBar filters={[xAxis, yAxis]} onFiltersChange={([x, y]) => setGroupingFilters([x, y])} wrapperProps={{ color: 'secondary' }} clearable={false} />
    );

    const noMatrixState = (
        <EmptyState
            icon={<ClipboardEmptyStateIcon />}
            flex={1}
            title={t('reviews.report.no_matrix')}
            subTitle={t('reviews.report.no_matrix_onboarding_text')}
            action={groupingFilterBars}
        />
    );

    return (
        <>
            <Stack component={Paper} direction='row' p={1} justifyContent='space-between'>
                <Stack direction='row' gap={2}>
                    <ReviewReportMenu />
                    <FiltersBar filters={filters} onFiltersChange={onFiltersChange} />
                </Stack>
                {xAxisValue && groupingFilterBars}
            </Stack>

            <Stack component={Paper} p={2} flex={1} overflow='auto'>
                <StateHandler
                    isError={false}
                    isLoading={false}
                    error={undefined}
                    isEmpty={!feedbacksItemsGroupByEmployee?.length || !xAxisValue}
                    emptyStateComponent={feedbacksItemsGroupByEmployee.length ? noMatrixState : undefined}
                >
                    <Matrix
                        rows={matrixRows ?? []}
                        groupBy={{
                            xAxis: it => it.questionX,
                            yAxis: yAxisValue ? it => it.questionY : undefined,
                        }}
                        // Matrix apply an alphabetic sort, so score is prepend to order the matrix based on it
                        // We remove it to display the label
                        getLabel={it => it.split('_')[2]}
                    >
                        {({ items, mode }) => (
                            <Stack {...getPropsForMatrixMode(mode)}>
                                <StackedAvatars
                                    employeeAvatars={items.map(it => it.employee)}
                                    max={mode === 'STACK' ? 10 : undefined}
                                    orientation={mode === 'STACK' ? 'vertical' : undefined}
                                    spacing={mode === 'STACK' ? 1 : undefined}
                                    size='medium'
                                    grid={mode === 'MATRIX'}
                                />
                            </Stack>
                        )}
                    </Matrix>
                </StateHandler>
            </Stack>
        </>
    );
};
