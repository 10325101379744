import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageTranslationKey, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { Globe02Icon } from 'hugeicons-react';

type TranslationLanguageSelectorProps = {
    disabled?: boolean;
    translationLanguage: UserLanguage;
    handleLanguageChange: (language: UserLanguage) => void;
};

export const TranslationLanguageSelector: FC<TranslationLanguageSelectorProps> = ({ disabled = false, handleLanguageChange, translationLanguage }) => {
    const { t } = useTranslation();
    const availableLanguages = Object.keys(UserLanguage).map(k => k as UserLanguage);

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const open = Boolean(anchorEl);

    const realmLanguage = getRealmLanguage();

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleMenuItemClick = (language: UserLanguage) => {
        handleLanguageChange(language);
        handleClose();
    };

    return (
        <Box>
            <Stack direction='row-reverse' alignItems='center'>
                <IconButton
                    aria-label='language'
                    id='language-selector-button'
                    aria-controls={open ? 'language-selector-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup='true'
                    onClick={handleClick}
                    disabled={disabled}
                >
                    <Globe02Icon />
                </IconButton>
                <Typography variant='body1bold'>{translationLanguage}</Typography>
            </Stack>

            <Menu
                id='language-selector-menu'
                MenuListProps={{
                    'aria-labelledby': 'language-selector-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 30, horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {availableLanguages.map(userLanguage => (
                    <MenuItem
                        key={userLanguage.toString()}
                        dense={true}
                        onClick={() => handleMenuItemClick(userLanguage)}
                        selected={userLanguage === translationLanguage}
                        sx={userLanguage === realmLanguage ? { fontWeight: 700 } : { fontStyle: 'italic' }}
                    >
                        {t(getLanguageTranslationKey(userLanguage))}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};
