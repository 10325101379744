import { formatInDefaultDate } from '@/utils/datetime.util';
import { Autocomplete, Box, Button, Chip, ChipProps, ClickAwayListener, Divider, Fade, IconButton, Paper, Popper, Stack, StackProps } from '@mui/material';
import { ArrowLeft01Icon, Cancel01Icon } from 'hugeicons-react';
import PopupState, { bindPopper, bindToggle, Props as PopupStateProps } from 'material-ui-popup-state';
import { FC, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopperlessAutocomplete } from '@/Components/autocomplete-wrapper/usePopperlessAutocomplete';
import { CheckboxAutocompleteOption } from '@/Components/autocomplete-wrapper/CheckboxAutocompleteOption';
import { SelectFilter as SelectFilterComponent } from '@/Components/filters-bar/filter-bar-components/SelectFilter';
import { DateFilter as DateFilterComponent } from '@/Components/filters-bar/filter-bar-components/DateFilter';
import { TextFilter as TextFilterComponent } from '@/Components/filters-bar/filter-bar-components/TextFieldFilter';
import { TimeFilter } from '@/Components/filters-bar/filter-bar-components/TimeFilter';
import { FILTER_HEIGHT } from '@/Components/filters-bar/FiltersBar.util';

export type FilterCommonType = {
    key: string;
    filterName: string;
    /** hide the filter in more filters button */
    hide?: boolean;
    defaultVisibility?: 'visible' | 'hidden';
    clearable?: boolean;
};

export type SelectFilterOption = {
    label: string;
    value: string | number;
};

export type SelectFilter = FilterCommonType & {
    type: 'select' | 'multi-select';
    options?: SelectFilterOption[];
    value?: SelectFilterOption[];
    selectMode: 'SYNC';
    rule: 'EQUALS' | 'NOT_EQUALS';
    availableRules: ('EQUALS' | 'NOT_EQUALS')[];
};

/**
 * A select filter that fetches its options asynchronously
 */
export type AsyncSelectFilter = Omit<SelectFilter, 'options' | 'selectMode'> & {
    fetchOptions: () => Promise<SelectFilterOption[]>;
    selectMode: 'ASYNC';
};

export type DateRangeType = [Date, Date];
// TODO rename this by rule to be more consistent with select filter
export type DateFilterRule = 'WITHIN_THE_LAST' | 'MORE_THAN' | 'BETWEEN';
export type DateFilterCommon = FilterCommonType & {
    type: 'date';
    availableRules: DateFilterRule[];
};

export type DateSimpleFilterType = DateFilterCommon & {
    value?: Date;
    dateType?: 'WITHIN_THE_LAST' | 'MORE_THAN';
};

export type DateRangeFilterType = DateFilterCommon & {
    value?: DateRangeType;
    dateType: 'BETWEEN';
};

export type DateFilter = DateSimpleFilterType | DateRangeFilterType;

export type TextFilter = FilterCommonType & {
    // TODO improve filter bar to manage number
    // THIS is a hack to accept number and document even if they are not supported
    type: 'text' | 'number' | 'document';
    value?: string;
};

export type TimeRangeType = [string, string];
export type TimeFilterType = FilterCommonType & {
    type: 'time';
    value?: TimeRangeType;
};

export type FilterType = SelectFilter | AsyncSelectFilter | DateFilter | TextFilter | TimeFilterType;

export type FiltersBarProps<T extends FilterType> = StackProps & {
    filters: T[];
    onFiltersChange: (filters: T[]) => void;
    readOnly?: boolean;
    wrapperProps?: Partial<FilterWrapperProps>;
    clearable?: boolean;
};
export const FiltersBar = <T extends FilterType>({
    filters,
    onFiltersChange = () => {},
    readOnly,
    wrapperProps,
    clearable = true,
    ...rest
}: FiltersBarProps<T>): JSX.Element => {
    const visibleFilters = filters.filter(filter => !filter.hide);
    const hiddenFilters = filters.filter(filter => filter.defaultVisibility === 'hidden');

    const getPopupId = (filter: T) => {
        return `filter-popup-id-${filter.key}`;
    };

    /**
     * Add or remove a filter from the visible filters
     */
    const handleFilterRemoved = (filter: T) => {
        const updatedFilters = filters.map(prevFilter => {
            if (prevFilter.key !== filter.key) {
                return prevFilter;
            }

            // if the filter is hidden by default, we reset the value and hide it
            if (filter.defaultVisibility === 'hidden' && !filter.hide) {
                return {
                    ...prevFilter,
                    hide: true,
                    // when hiding a filter, reset its value
                    value: undefined,
                };
            }

            // otherwise we just reset the value
            return clearFilter(prevFilter);
        });

        onFiltersChange(updatedFilters);
    };

    /**
     * Update the value of a filter
     */
    const handleFilterUpdated = (filter: T) => {
        const updatedFilters = filters.map(prevFilter => {
            if (prevFilter.key !== filter.key) {
                return prevFilter;
            }

            return filter;
        });
        onFiltersChange(updatedFilters);
    };

    const isClearable =
        clearable && (visibleFilters.some(f => !!f.value && f.clearable !== false) || visibleFilters.some(f => f.defaultVisibility === 'hidden' && !f.hide));

    const clearFilter = (filter: T) => {
        if (filter.clearable === false) {
            return filter;
        }
        return {
            ...filter,
            value: undefined,
            hide: filter.defaultVisibility === 'hidden',
        };
    };
    const handleClearAll = () => {
        onFiltersChange(filters.map(clearFilter));
    };

    return (
        <Stack direction='row' gap={1} alignItems='center' flexWrap='wrap' {...rest}>
            {!readOnly && !!hiddenFilters.length && (
                <MoreFiltersButton filters={hiddenFilters} onFilterRemoved={handleFilterRemoved} onFilterUpdated={handleFilterUpdated} />
            )}
            {visibleFilters.map(filter => (
                <FilterWrapper
                    key={filter.key}
                    popupId={getPopupId(filter)}
                    label={<FilterLabel filter={filter} />}
                    color={filter.value ? 'primary' : undefined}
                    clickable={!readOnly}
                    onFilterRemoved={!readOnly && filter.clearable !== false && filter.value ? () => handleFilterRemoved(filter) : undefined}
                    {...wrapperProps}
                >
                    <Filter filter={filter} onFilterUpdated={handleFilterUpdated} />
                </FilterWrapper>
            ))}

            {!readOnly && isClearable && (
                <>
                    <Divider orientation='vertical' flexItem sx={{ height: '20px', my: 'auto' }} />
                    <IconButton onClick={handleClearAll} size='small' aria-label='clearAll'>
                        <Cancel01Icon fontSize='small' size={18} />
                    </IconButton>
                </>
            )}
        </Stack>
    );
};

type FilterProps<T extends FilterType> = {
    filter: T;
    onFilterUpdated: (filter: T) => void;
};

const Filter = <T extends FilterType = FilterType>({ filter, onFilterUpdated }: FilterProps<T>) => {
    const handleFilterUpdated = (filter: FilterType) => {
        onFilterUpdated(filter as T);
    };

    switch (filter.type) {
        case 'select':
        case 'multi-select':
            return <SelectFilterComponent filter={filter} onFilterUpdated={handleFilterUpdated} />;
        case 'date':
            return <DateFilterComponent filter={filter} onFilterUpdated={handleFilterUpdated} />;
        case 'text':
            return <TextFilterComponent filter={filter} onFilterUpdated={handleFilterUpdated} />;
        case 'time':
            return <TimeFilter filter={filter} onFilterUpdated={handleFilterUpdated} />;
    }
};

type FilterWrapperProps = Omit<PopupStateProps, 'children' | 'variant'> & {
    label: ChipProps['label'];
    color?: ChipProps['color'];
    onFilterRemoved?: () => void;
    onClickAway?: () => void;
    clickable?: boolean;
};

const FilterWrapper: FC<PropsWithChildren<FilterWrapperProps>> = ({ children, label, clickable, onFilterRemoved, color, ...rest }) => {
    return (
        <PopupState variant='popper' {...rest}>
            {popupState => {
                return (
                    <>
                        <Chip
                            label={label}
                            clickable={clickable}
                            onDelete={onFilterRemoved}
                            color={color}
                            {...bindToggle(popupState)}
                            sx={{ height: FILTER_HEIGHT }}
                        />
                        <Popper placement='bottom-start' {...bindPopper(popupState)} transition>
                            {({ TransitionProps }) => (
                                <ClickAwayListener
                                    onClickAway={() => {
                                        rest.onClickAway?.();
                                        popupState.close();
                                    }}
                                >
                                    <Fade {...TransitionProps}>
                                        <Paper variant='outlined' sx={{ width: 320, marginY: 0.5 }}>
                                            {children}
                                        </Paper>
                                    </Fade>
                                </ClickAwayListener>
                            )}
                        </Popper>
                    </>
                );
            }}
        </PopupState>
    );
};

const FilterLabel = <T extends FilterType>({ filter, ...rest }: { filter: T } & StackProps) => {
    const formatDateFilterValue = (filter: DateFilter): string => {
        if (filter.dateType === 'WITHIN_THE_LAST' || filter.dateType === 'MORE_THAN') {
            return filter.value ? formatInDefaultDate(filter.value) : '';
        }
        if (filter.dateType === 'BETWEEN') {
            const formatLeftRange = formatInDefaultDate(filter.value?.[0]);
            const formatRightRange = formatInDefaultDate(filter.value?.[1]);

            return formatLeftRange || formatRightRange ? `${formatLeftRange ?? '-'} -> ${formatRightRange ?? '-'}` : '';
        }
        return '';
    };

    const getFilterValueDisplay = (filter: FilterType): string => {
        switch (filter.type) {
            case 'select':
            case 'multi-select':
                return filter.value?.[0]?.label ?? '';
            case 'date': {
                return formatDateFilterValue(filter);
            }
            case 'text':
                return filter.value ?? '';
            case 'time': {
                const leftRange = filter.value?.[0];
                const rightRange = filter.value?.[1];
                const hasValue = !!leftRange || !!rightRange;

                return hasValue ? `${leftRange ?? '-'} - ${rightRange ?? '-'}` : '';
            }
        }
        return '';
    };

    return (
        <Stack direction='row' gap={0.5} sx={{ fontWeight: filter.value ? 'bold' : undefined }} {...rest}>
            {/* We can't use typography, because we don't want to override the font style from the parent (chip) */}
            <Box>
                {filter.filterName}

                {filter.value && (
                    <>
                        {/* TODO create a filter label component */}
                        {['select', 'multi-select'].includes(filter.type) && Array.isArray(filter.value) && !!filter.value?.length && ':'}
                        {(filter.type === 'text' || filter.type === 'time' || (filter.type === 'date' && filter.dateType === 'BETWEEN')) && ':'}
                        {filter.type === 'date' && filter.dateType === 'MORE_THAN' && ' <'}
                        {filter.type === 'date' && filter.dateType === 'WITHIN_THE_LAST' && ' >'}
                    </>
                )}
            </Box>
            {filter.value && (
                <Box maxWidth={200} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {getFilterValueDisplay(filter)}
                </Box>
            )}
            {filter.type === 'multi-select' && filter?.value?.length && filter?.value?.length > 1 && <Box minWidth={28}>(+{filter.value?.length - 1})</Box>}
        </Stack>
    );
};

const MoreFiltersButton = <T extends FilterType>({
    filters,
    onFilterRemoved,
    onFilterUpdated,
}: {
    filters: T[];
    onFilterRemoved: (filter: T) => void;
    onFilterUpdated: (filter: T) => void;
}): JSX.Element => {
    const { t } = useTranslation();

    const autocompleteProps = usePopperlessAutocomplete();
    const [currentFilterKey, setCurrentFilterKey] = useState<string>();

    // it's very important to get the current filter from the filters props and not from the state
    const currentFilter = filters.find(filter => filter.key === currentFilterKey);

    const id = 'more-filters';

    const handleFilterToggled = (filter: T) => {
        if (!filter.hide) {
            onFilterRemoved(filter);
        } else {
            setCurrentFilterKey(filter.key);
        }
    };

    const handleFilterUpdated = (filter: T) => {
        onFilterUpdated({ ...filter, hide: false });
    };

    const resetCurrentFilter = () => {
        setCurrentFilterKey(undefined);
    };

    return (
        <FilterWrapper popupId={id} label={t('general.more_filters')} onClickAway={resetCurrentFilter}>
            {!currentFilterKey && (
                <Stack gap={0.5} p={1}>
                    <Autocomplete
                        getOptionKey={option => option.key}
                        value={filters.filter(f => !f.hide)}
                        multiple
                        options={filters}
                        getOptionLabel={option => option.filterName}
                        onChange={(_event, _option, _reason, detail) => {
                            if (detail?.option) {
                                handleFilterToggled(detail.option);
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        fullWidth
                        renderOption={(props, option, { selected }) => <CheckboxAutocompleteOption {...props} selected={selected} label={option.filterName} />}
                        {...autocompleteProps}
                    />
                </Stack>
            )}

            {/* Shortcut to apply a filter */}
            {currentFilter && (
                <Stack>
                    <Button
                        variant='text'
                        color='inherit'
                        size='small'
                        onClick={() => setCurrentFilterKey(undefined)}
                        startIcon={<ArrowLeft01Icon />}
                        sx={{ justifyContent: 'flex-start' }}
                    >
                        {currentFilter.filterName}
                    </Button>
                    {currentFilterKey && <Filter filter={currentFilter} onFilterUpdated={handleFilterUpdated} />}
                </Stack>
            )}
        </FilterWrapper>
    );
};
