import { API_BASE_URL, client } from '@/api/common';
import { LegalUnit, LegalUnitCreateMutation, LegalUnitUpdateMutation } from '@/domain/legal-unit/LegalUnit.model';
import { AxiosResponse } from 'axios';

type LegalUnitDTO = LegalUnit;
type LegalUnitCreateMutationDTO = LegalUnitCreateMutation;
type LegalUnitUpdateMutationDTO = LegalUnitUpdateMutation;

const getLegalUnits = async (): Promise<LegalUnit[]> => {
    return (await client.get<LegalUnitDTO[]>(API_BASE_URL + '/legal-units')).data;
};

const getLegalUnit = async (id: number): Promise<LegalUnit> => {
    return (await client.get<LegalUnitDTO>(API_BASE_URL + `/legal-units/${id}`)).data;
};

const createLegalUnit = async (legalUnit: LegalUnitCreateMutation): Promise<LegalUnit> => {
    return (await client.post<LegalUnitDTO, AxiosResponse<LegalUnitDTO>, LegalUnitCreateMutationDTO>(API_BASE_URL + '/legal-units', legalUnit)).data;
};

const updateLegalUnit = async (id: number, legalUnit: LegalUnitUpdateMutation): Promise<LegalUnit> => {
    return (await client.put<LegalUnitDTO, AxiosResponse<LegalUnitDTO>, LegalUnitUpdateMutationDTO>(API_BASE_URL + `/legal-units/${id}`, legalUnit)).data;
};

const updateLegalUnitLogo = async (id: number, logoKey: string): Promise<LegalUnit> => {
    return (await client.put<LegalUnitDTO, AxiosResponse<LegalUnitDTO>>(API_BASE_URL + `/legal-units/${id}/logo?key=${logoKey}`)).data;
};

const deleteLegalUnitLogo = async (id: number): Promise<void> => {
    return client.delete(API_BASE_URL + `/legal-units/${id}/logo`);
};

export const legalUnitApi = {
    getLegalUnits,
    getLegalUnit,
    createLegalUnit,
    updateLegalUnit,
    updateLegalUnitLogo,
    deleteLegalUnitLogo,
};
