import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { AsyncSelectFilter, DateFilter, SelectFilter } from '@/Components/filters-bar/FiltersBar';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { getLocations } from '@/domain/location/Location.service';
import { getLabelTranslation } from '@/utils/language.util';
import { getDepartments } from '@/domain/department/Department.service';
import { getJobs } from '@/domain/job/Job.service';
import { useTranslation } from 'react-i18next';
import { endOfYear } from 'date-fns';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';

export type LeaveBalanceFilter = SelectFilter | AsyncSelectFilter | DateFilter;

export const useLeaveBalancePageFilters = (
    leaveTypes: LeaveType[],
    defaultLeaveType: LeaveType,
): {
    filters: LeaveBalanceFilter[];
} => {
    const { t } = useTranslation();

    const filters: LeaveBalanceFilter[] = [
        {
            key: 'endCycle',
            filterName: t('leave_balance_page.filters.cycle'),
            type: 'date',
            value: endOfYear(new Date()),
            dateType: 'MORE_THAN',
            availableRules: ['MORE_THAN'],
            clearable: false,
        },
        {
            filterName: t('balance_page.filters.Leave_type'),
            type: 'select',
            selectMode: 'SYNC',
            options: leaveTypes?.map(leaveType => ({
                label: leaveType.title,
                value: leaveType.id,
            })),
            value: [{ label: defaultLeaveType.title, value: defaultLeaveType.id }],
            key: 'leaveTypeId',
            rule: 'EQUALS',
            availableRules: [],
            clearable: false,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_LOCATION' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getLocations().then(locations => {
                    return locations?.map(location => ({
                        label: location.name,
                        value: location.id,
                    }));
                });
            },
            key: 'locationIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_DEPARTMENT' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getDepartments().then(departments => {
                    return departments?.map(department => ({
                        label: getLabelTranslation(department.name),
                        value: department.id,
                    }));
                });
            },
            key: 'departmentIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'CURRENT_EMPLOYMENT_JOB' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                return getJobs().then(jobs => {
                    return jobs?.map(job => ({
                        label: getLabelTranslation(job.name),
                        value: job.id,
                    }));
                });
            },
            key: 'jobIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            key: 'managerIds',
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYMENT_MANAGER' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employees = await searchEmployees();
                return employees?.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return {
        filters,
    };
};
