import { Duration, format, intervalToDuration, sub } from 'date-fns';
import { formatMinutesToHours, getDateFromTimeFormat } from '@/utils/datetime.util';
import { getAppConfig } from '@/config/config';
import { Timesheet, TimesheetRequestDisplayStatus, TimesheetsRequestStatus, TimesheetType } from '@/domain/timesheet/Timesheet.model';

const config = getAppConfig();

export const TIMESHEET_DEFAULT_START_TIME_HOUR = 8;
export const TIMESHEET_DEFAULT_END_TIME_HOUR = 12;

export const calculateDurationWithBreakFromHoursMinutes = (startTime: string, endTime: string | undefined, breakDuration: number): string => {
    const start = getDateFromTimeFormat(startTime);
    const endDate = endTime ? getDateFromTimeFormat(endTime) : undefined;
    const endMinusBreak = endDate ? sub(endDate, { minutes: breakDuration }) : endDate;
    return endTime && start && endMinusBreak
        ? formatDurationWithBreak(
              intervalToDuration({
                  start,
                  end: endMinusBreak,
              }),
          )
        : '--';
};
export const formatDurationWithBreak = (duration: Duration): string => {
    return format(new Date().setHours(duration.hours ?? 0, duration?.minutes ?? 0), config.DEFAULT_HOURS_FORMAT);
};

export const getStatus = (status: TimesheetsRequestStatus, timesheetsGroupedByType: Timesheet[]): boolean =>
    timesheetsGroupedByType.every(timesheet => timesheet.status === status);

export const isMissingTimesheetNoAutofill = (item: Timesheet): boolean => {
    return item.type === TimesheetType.MISSING && !item.timesheetSetting?.autofillTimesheet;
};

export const getTimesheetStatus = (timesheet: Timesheet, timesheetsGroupedByType: Timesheet[]): TimesheetRequestDisplayStatus => {
    if (timesheet?.type === TimesheetType.SHIFT_TIMESHEET) {
        return TimesheetRequestDisplayStatus.SHIFT_TIMESHEET;
    }
    if (timesheet?.type === TimesheetType.MISSING) {
        return TimesheetRequestDisplayStatus.MISSING;
    }
    if (timesheet?.type === TimesheetType.AUTOFILL) {
        return TimesheetRequestDisplayStatus.AUTO_FILLED;
    }
    if (timesheet?.type === TimesheetType.NON_WORKING_DAY) {
        return TimesheetRequestDisplayStatus.NON_WORKING_DAY;
    }
    if (timesheet?.type === TimesheetType.FUTURE_MISSING) {
        return TimesheetRequestDisplayStatus.FUTURE_MISSING;
    }
    if (timesheet?.type === TimesheetType.TIMESHEET) {
        if (getStatus(TimesheetsRequestStatus.PENDING, timesheetsGroupedByType)) {
            return TimesheetRequestDisplayStatus.PENDING;
        }
        if (getStatus(TimesheetsRequestStatus.APPROVED, timesheetsGroupedByType)) {
            return TimesheetRequestDisplayStatus.APPROVED;
        }
        if (getStatus(TimesheetsRequestStatus.CANCELLED, timesheetsGroupedByType)) {
            return TimesheetRequestDisplayStatus.CANCELLED;
        }
        if (getStatus(TimesheetsRequestStatus.DECLINED, timesheetsGroupedByType)) {
            return TimesheetRequestDisplayStatus.DECLINED;
        }
        return TimesheetRequestDisplayStatus.PENDING;
    }
    return TimesheetRequestDisplayStatus.PENDING;
};

export const getTotalWorkedCount = (totalWorkedCount: number | undefined): string => {
    if (totalWorkedCount === undefined) {
        return '-';
    }
    return formatMinutesToHours(totalWorkedCount);
};
