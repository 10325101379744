import { BasicMenu } from '@/Components/basic-menu/BasicMenu';
import { ReorderItem, ReorderModal } from '@/Components/reorder-modal/ReorderModal';
import { Skill } from '@/domain/skill/Skill.model';
import { deleteSkill, reorderSkillsRequest } from '@/domain/skill/Skill.service';
import { useGetReviewSkills } from '@/hooks/review-skill/ReviewSkill.hook';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLabelTranslation } from '@/utils/language.util';
import { mapToReorderRequest } from '@/domain/common';
import { htmlToPlainText } from '@/utils/strings.util';

export const ReviewSkillSettingPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const route = useLocation();
    const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);

    const { data: skills, refetch: refetchSkills } = useGetReviewSkills();

    const onClickSkillRow = (skill: Skill) => {
        navigate(`${route.pathname}/${skill.id}`);
    };

    const onDeleteSkill = async (skill: Skill) => {
        try {
            await deleteSkill(skill.id);
            showSnackbar(t('reviews_settings_page.messages.skill_deleted'), 'success');
            refetchSkills();
        } catch (error) {
            handleError(error);
        }
    };

    const handleOrderModal = () => {
        setIsOrderModalOpen(true);
    };

    const getReorderItems = (skills: Skill[]) => {
        return skills.map((skill, index) => ({
            id: skill.id,
            name: skill.name,
            order: index,
        }));
    };

    const handleReorderSave = async (reorderItems: ReorderItem[]) => {
        const reorderRequest = mapToReorderRequest(reorderItems);
        await reorderSkillsRequest(reorderRequest);
        refetchSkills().catch(handleError);
        setIsOrderModalOpen(false);
    };

    const actionMenuRenderer = (params: ICellRendererParams<Skill>) => {
        if (!params.data) {
            return <></>;
        }

        const skill = params.data;

        return (
            <BasicMenu
                items={[
                    {
                        title: t('common.edit'),
                        onClick: () => onClickSkillRow(skill),
                    },
                    {
                        title: t('common.delete'),
                        onClick: () => {
                            onDeleteSkill(skill).catch(handleError);
                        },
                    },
                ]}
            />
        );
    };

    const options = getSkillsSettingOptions({
        actionMenuRenderer,
        defaultButtonAction: handleOrderModal,
    });

    return (
        <>
            <CompanySettingsLayout options={options} data={skills} isSearchAvailable />
            {isOrderModalOpen && (
                <ReorderModal
                    initialReorderItems={getReorderItems(skills ?? [])}
                    open={true}
                    onSave={handleReorderSave}
                    onClose={() => {
                        setIsOrderModalOpen(false);
                    }}
                />
            )}
        </>
    );
};

const getSkillsSettingOptions = ({
    actionMenuRenderer,
    defaultButtonAction,
}: {
    actionMenuRenderer: (params: ICellRendererParams<Skill>) => JSX.Element;
    defaultButtonAction: () => void;
}): ConfigType => {
    return {
        tableHeightCalc: 370,
        type: 'table',
        header: {
            primaryButtonCaption: i18next.t('reviews_settings_page.skill'),
            defaultButtonCaption: i18next.t('permissions_setting_page.reorder'),
            defaultButtonAction,
        },
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    field: 'name',
                    headerName: i18next.t('reviews_settings_page.measure'),
                    cellRenderer: (skill: ICellRendererParams<Skill>) => (
                        <Stack>
                            <Typography variant='body2bold'>{skill.data?.name}</Typography>
                            <Typography variant='body2'>{getLabelTranslation(skill.data?.category?.name)}</Typography>
                        </Stack>
                    ),
                    autoHeight: true,
                    cellClass: ['display-flex'],
                },
                {
                    field: 'description',
                    headerName: i18next.t('reviews_settings_page.default'),
                    valueFormatter: ({ value }) => htmlToPlainText(value),
                    cellRenderer: (skill: ICellRendererParams<Skill>) => {
                        return (
                            <Typography
                                variant='body2'
                                className={'rich_text'}
                                dangerouslySetInnerHTML={{
                                    __html: skill.data?.description ?? '',
                                }}
                            />
                        );
                    },
                    maxWidth: 500,
                    autoHeight: true,
                    cellClass: ['display-flex'],
                },
                {
                    type: 'actionMenu',
                    cellRenderer: actionMenuRenderer,
                },
            ],
        },
    };
};
