import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { Label } from '@/domain/label/Label.model';
import { SurveyQuestion, SurveyQuestionItem, SurveyQuestionType } from '@/domain/survey/Survey.model';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyUploadFile } from './SurveyUploadFile';
import { TranslatableLabelInput } from '@/Components/translatable-label-input/TranslatableLabelInput';
import { UserLanguage } from '@/utils/language.util';
import { Delete02Icon } from 'hugeicons-react';

type LickertScaleProps = {
    question: SurveyQuestion;
    isTranslationMode: boolean;
    onQuestionScaleItemAdded: (option: SurveyQuestionItem, scaleOptionsLength: number) => void;
    onChangeQuestionItemTitle: (title: Label, index: number) => void;
    onChangeQuestionItemImage: (imageUrl: string, index: number) => void;
    onDeleteQuestionItem: (index: number, questionType: SurveyQuestionType) => void;
    translationLanguage: UserLanguage;
};

const MIN_LICKERT_SCALE_OPTIONS = 3;

export const SurveyLickertScale: FC<LickertScaleProps> = ({
    question,
    isTranslationMode,
    onQuestionScaleItemAdded,
    onChangeQuestionItemTitle,
    onChangeQuestionItemImage,
    onDeleteQuestionItem,
    translationLanguage,
}) => {
    const { t } = useTranslation();
    const choiceOption = (itemsCount: number) => {
        return {
            id: 0,
            order: itemsCount,
            score: itemsCount,
            label: {
                id: 0,
                translationDe: '',
                translationFr: '',
                translationIt: '',
                translationEn: '',
            },
            imageUrl: '',
        };
    };

    useEffect(() => {
        if (question.items.length < 3) {
            onQuestionScaleItemAdded(choiceOption(question.items.length), question.items.length + 1);
        }
    }, [onQuestionScaleItemAdded, question]);

    return (
        <Stack gap={2}>
            <Stack gap={2}>
                {question.items.map((item, index) => (
                    <Stack direction='row' key={item?.id ?? index} alignItems='center' gap={1}>
                        {/*todo: implement the error helper text message*/}
                        <TranslatableLabelInput
                            fullWidth
                            value={item.label}
                            onChange={(value: Label) => {
                                onChangeQuestionItemTitle(value, index);
                            }}
                            translationLanguage={translationLanguage}
                        />
                        <Stack direction={'row'} sx={{ minWidth: '240px' }}>
                            {!isTranslationMode && (
                                // Display a tooltip when the button is disabled to explain why it is disabled
                                <Tooltip
                                    title={
                                        question.items.length <= MIN_LICKERT_SCALE_OPTIONS
                                            ? t('survey_templates_page.delete_choice_condition', { count: MIN_LICKERT_SCALE_OPTIONS })
                                            : undefined
                                    }
                                >
                                    {/*Hack to display the tooltip when the button is disabled*/}
                                    <span>
                                        <IconButton
                                            disabled={question.items.length <= MIN_LICKERT_SCALE_OPTIONS}
                                            onClick={() => onDeleteQuestionItem(index, question.type)}
                                        >
                                            <Delete02Icon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                            <SurveyUploadFile
                                defaultImageUrl={item.imageUrl}
                                onDelete={() => {
                                    onChangeQuestionItemImage('', index);
                                }}
                                onUpload={upload => {
                                    onChangeQuestionItemImage(upload.key, index);
                                }}
                            />
                        </Stack>
                    </Stack>
                ))}
            </Stack>
            {!isTranslationMode && question.items?.length > 0 && question.items?.length < 5 && (
                <Button
                    sx={{ justifyContent: 'flex-start', width: 100 }}
                    variant='text'
                    onClick={() => onQuestionScaleItemAdded(choiceOption(question.items.length), question.items.length + 1)}
                >
                    {t('survey_templates_page.add_choice')}
                </Button>
            )}
        </Stack>
    );
};
