import { FC } from 'react';
import { List, ListItem, Paper, Typography } from '@mui/material';

export const PrivacyPolicyPage: FC = () => {
    return (
        <Paper sx={{ p: 2, margin: 'auto', maxWidth: 1000 }}>
            <Typography variant='h1' component='div' gutterBottom>
                Privacy Policy
            </Typography>
            <Typography variant='h2bold' gutterBottom component='div'>
                WITH THE FOLLOWING STATEMENT, WE COMPLY WITH OUR STATUTORY DUTY TO INFORM AND INFORM YOU ABOUT THE STORAGE OF DATA, THE TYPE OF DATA, THEIR
                PURPOSE AND OUR IDENTITY. WE ALSO NOTIFY YOU OF THE INITIAL REGISTRATION AND THE TYPE OF DATA SUBMITTED.
            </Typography>
            <Typography variant='h2bold' gutterBottom component='div'>
                YOU CAN FIND OUR COMPLETE CONTACT DETAILS AT THE END OF THIS PRIVACY POLICY.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                Based on Article 13 of the Swiss Federal Constitution and the data protection regulations of the Federal Government (Data Protection Act, DSG)
                and in accordance with GDPR, every person is entitled to protection of their privacy and to protection against misuse of their personal data. We
                comply with these terms. Personal data will be kept strictly confidential and will not be sold or passed on to third parties. The details can be
                found in the following explanations.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                We look forward to your visit to our website and your interest in our offers. For external links to third-party content we accept no liability
                despite careful content control, since we have not initiated the transmission of this information, have not selected or changed the addressee of
                the transmitted information and the transmitted information itself.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                The protection of your personal data during the collection, processing and use during your visit to our website is very important to us and
                takes place within the framework of the legal regulations, which you can refer to e.g. under the link listed at the end of this Privacy Policy.
                As far as on our sides personal data (for example name, address or E-Mail address) are raised, this takes place, as far as possible, always on
                freiwilliger basis. These data will not be disclosed to third parties without your explicit consent. We point out in this context that the
                transmission of data on the Internet (for example in the case of communication by e-mail) may have security gaps. A complete protection of the
                data from access by third parties is not possible.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                In the following, we will explain what information we collect during your visit to our website and how it is used:
            </Typography>
            <Typography variant='h3bold' gutterBottom component='div'>
                1. COLLECTION AND STORAGE OF PERSONAL DATA AND THE NATURE AND PURPOSE OF THEIR USE
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                a) When visiting the website: Every time a customer (or other visitor) accesses our website, the internet browser used on your device (computer,
                laptop, tablet, smartphone, etc.) automatically sends information to the server of our website , This information is temporarily stored in a
                so-called logfile (log file) on the server side (web hosting provider).
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                The following data will be collected without your intervention and stored until automated deletion:
            </Typography>
            <List>
                <ListItem>if necessary IP address of the requesting computer, as well as device ID or individual device identifier and device type,</ListItem>
                <ListItem>name of the retrieved file and amount of data transferred, as well as date and time of retrieval,</ListItem>
                <ListItem>Message about successful call,</ListItem>
                <ListItem>requesting domain (origin of your request, from which you came to our website(s)),</ListItem>
                <ListItem>
                    Description of the type of internet browser used and, if applicable, the operating system of your terminal and the name of your access
                    provider,
                </ListItem>
                <ListItem>
                    Location information, including location data from your mobile device. Please note that on most mobile devices, you can control or disable
                    the use of location services in the mobile device settings menu.
                </ListItem>
            </List>
            <Typography variant='body1' gutterBottom paragraph>
                Our legitimate interest in accordance. Art. 6 para. 1 sentence 1 lit. f DSGVO for the collection of data is based on the following purposes:
            </Typography>
            <List>
                <ListItem>Ensuring a smooth connection setup and comfortable use of the website,</ListItem>
                <ListItem>Evaluation of system security and stability as well</ListItem>
                <ListItem>for further administrative purposes.</ListItem>
            </List>
            <Typography variant='body1' gutterBottom paragraph>
                In no case we use the collected data for the purpose of drawing conclusions about you.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                b) When concluding a contractual relationship of any kind: When concluding any contractual relationship of any kind on our website, we ask you
                for the following personal data:
            </Typography>
            <List>
                <ListItem>Data that personally identifies you, such as your natural name, phone number, address and and e-mail address</ListItem>
                <ListItem>
                    other personal data which we are legally obliged or authorized to collect and process and which we need for your authentication,
                    identification or verification of the data we collect.
                </ListItem>
            </List>
            <Typography variant='body1' gutterBottom paragraph>
                The mentioned data will be processed to process the contractual relationship. The processing of data is based on Art. 6 para. 1 lit. b DSGVO.
                The retention period is limited to the purpose of the contract and, if available, statutory and contractual retention requirements.
            </Typography>
            <Typography variant='h3bold' gutterBottom component='div'>
                2. TRANSFER OF PERSONAL DATA
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                A transfer of your data to third parties for purposes other than those listed below does not take place. We only pass on your data to third
                parties if:
            </Typography>
            <List>
                <ListItem>You have given your express consent to this (Art. 6 (1) (1) (a) GDPR),</ListItem>
                <ListItem>this is necessary for the settlement of contractual relationships with you (Art. 6 (1) (b) GDPR),</ListItem>
                <ListItem>there is a legal obligation to pass it on (Art. 6 (1) (c) GDPR),</ListItem>
                <ListItem>
                    disclosure is required for asserting, exercising or defending legal claims and there is no reason to assume that you have a predominantly
                    legitimate interest in not disclosing your data (Article 6 (1) (1) (f) GDPR).
                </ListItem>
            </List>
            <Typography variant='body1' gutterBottom paragraph>
                In these cases, however, the amount of data transmitted is limited to the minimum required. Our privacy policy is consistent with applicable
                data protection laws and the data is processed only in the European Union. A transfer to third countries does not take place and is not
                intended.
            </Typography>
            <Typography variant='h3bold' gutterBottom component='div'>
                3. AFFECTED RIGHTS
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                On request, we will be pleased to inform you whether and which clearly personal data about you are stored (Article 15 GDPR), in particular the
                processing purposes, the category of personal data, the categories of recipients to whom your data has been disclosed or will , the planned
                storage period, the right of rectification, deletion, limitation of processing or opposition, the existence of a right of appeal, the origin of
                their data, if they were not collected from us, as well as the existence of automated decision-making including profiling.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                You also have the right to correct any incorrectly collected personal data or to complete incomplete data collected (Art. 16 GDPR). Furthermore,
                you have the right to demand that we restrict the processing of your data, provided that the legal conditions for doing so are met (Art. 18
                GDPR). You have the right to receive the personal data relating to you in a structured, common and machine-readable format or to request the
                transfer to another person responsible (Art. 20 GDPR). In addition, you are entitled to the so-called &quot;right to be forgotten&quot;. H. You
                can request the deletion of your personal data from us, provided that the legal prerequisites exist (Art. 17 GDPR). Regardless of this, your
                personal data will be automatically deleted by us if the purpose of the data collection has been omitted or the data processing has been
                unlawful.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                IN ACCORDANCE WITH ART. 7 (3) GDPR, YOU HAVE THE RIGHT TO REVOKE YOUR ONCE GIVEN CONSENT TO US AT ANY TIME. AS A RESULT, WE ARE NO LONGER
                ALLOWED TO CONTINUE THE DATA PROCESSING BASED ON THIS CONSENT FOR THE FUTURE.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                You also have the right to object to the processing of your personal data at any time, provided that a right of objection is provided for by
                law. In the case of an effective revocation, your personal data will also be automatically deleted by us (Article 21 GDPR). If you would like to
                exercise your right of revocation or objection, it is sufficient to send an e-mail to the e-mail address listed at the end of this privacy
                policy. In case of violations of the data protection regulations you have acc. Art. 77 DSGVO the possibility to file a complaint with a
                supervisory authority.
            </Typography>
            <Typography variant='h3bold' gutterBottom component='div'>
                4. DURATION OF DATA STORAGE
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                The data collected will be stored by us as long as necessary for the performance of the contracts we have entered into, or you have not
                exercised your right to cancellation or your right to transfer data to another company.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                For example, we use cookies to statistically record and evaluate the use of our website and to optimize our offer for you. These cookies allow
                us to automatically recognize when you visit our website again that you have already been with us. These cookies are automatically deleted after
                a defined time. The data processed by cookies are for the purposes mentioned in order to safeguard our legitimate interests as well as third
                parties according to Art. 6 para. 1 sentence 1 lit. f DSGVO required. Most browsers accept cookies automatically. If you do not want us to
                recognize information about your computer, please set your Internet browser to delete cookies from your computer hard drive, block all cookies,
                or warn you before a cookie is stored. You can find out how to delete or block cookies in the Help and Support section of your Internet browser.
                There you will find instructions for searching the file or directory where cookies are stored. Please note in any case that the complete
                deactivation of cookies may mean that you can not use all functions of our website.
            </Typography>
            <Typography variant='h3bold' gutterBottom component='div'>
                5. EXTERNAL APPLICATIONS AS INTEGRATION ON OUR WEBSITE
            </Typography>
            <Typography variant='h2bold' gutterBottom component='div'>
                Google Maps
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                Our website sometimes uses the Google Maps product of Google Inc. By using our website, you consent to the collection, processing and use of the
                automated data collected by Google Inc, its agents and third parties. You can find the terms of use of Google Maps under{' '}
                <a href='https://www.google.com/intl/en/help/terms_maps/'>&quot;Terms of Use of Google Maps&quot;.</a>
            </Typography>
            <Typography variant='h2bold' gutterBottom component='div'>
                Google Social Login
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                Our website sometimes uses social plugins (&quot;plugins&quot;) from Google operated by Google Inc. If you open our website with the plugin, it
                connects directly to the Google servers via your browser. This will transfer to Google the information that you have accessed the page. If you
                are logged in with your Google account, by clicking on the plugin, the visit to our website can be assigned directly to your profile. Even if
                you do not have a profile, it can not be ruled out that your IP address will be saved by Google. The purpose and scope of the data collection
                and the further processing and use of the data by Google, as well as your related rights and settings options for the protection of your
                privacy, please refer to the privacy policy (https://policies.google.com/privacy) of Google. If you are a member of Google and do not want
                Google to collect data about you via our online offer and link it to your member data stored on Google, you must log out of our website before
                visiting Google and delete corresponding Google cookies. It is also possible to block Google social plug-ins with add-ons for the browser.
            </Typography>
            <Typography variant='h2bold' gutterBottom component='div'>
                Bugsnag (Web Debug Tool)
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                Bugsnag Inc. 110 Sutter St, San Francisco, CA 94104, United States. provides a software solution that monitors errors in web, mobile and server
                applications and programs under the direction of its Customers, to provide session, error and crash data. In order to provide this service, your
                browser transmits crash data directly to the Bugsnag API where it is further analysed by our engineers. The purpose and scope of the data
                collection and the further processing and use of the data by Google, as well as your related rights and settings options for the protection of
                your privacy, please refer to the privacy policy (https://docs.bugsnag.com/legal/privacy-policy/) of Bugsnag.
            </Typography>
            <Typography variant='h2bold' gutterBottom component='div'>
                Newsletter
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                When registering for our possibly offered newsletter: If, according to Art. 6 para. 1 p. 1 lit. a DSGVO have expressly consented, we use your
                e-mail address to send you, possibly, and indefinitely our newsletter. The deregistration is possible at any time, for example via a link at the
                end of a newsletter. Alternatively, you can send your unsubscribe request at any time by e-mail (preferably with the subject: &quot;unsubscribe
                newsletter&quot;).
            </Typography>
            <Typography variant='h3bold' gutterBottom component='div'>
                6. DATA SECURITY
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                We take all necessary technical and organizational security measures to store your personal data so that it is not accessible to third parties
                or the public. If you would like to contact us by e-mail, we would like to point out that the confidentiality of the transmitted information can
                not be completely guaranteed with this communication method. We therefore recommend that you send us confidential information only by telephone
                or by post. For e-mail communication, we recommend the best possible encryption of your outbox via SSL technology. For more information, please
                contact your e-mail service provider.
            </Typography>
            <Typography variant='h3bold' gutterBottom component='div'>
                7. UP-TO-DATENESS AND CHANGE OF THIS DATA PROTECTION EXPLANATION
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                This privacy policy is currently valid.
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                Due to the further development of our website and offers or due to changed legal or regulatory requirements, it may be necessary to change this
                privacy policy. The current privacy policy can be viewed and printed on our website at any time using the link below.
            </Typography>
            <Typography variant='h3bold' gutterBottom component='div'>
                7. NAME AND CONTACT DETAILS OF THE CONTROLLER
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                We are pleased to help all concerned in connection with the handling of personal data and exercising their rights. For assistance please contact
                info@rogerhr.ch. We are committed to dealing with all questions promptly.
            </Typography>
            <Typography variant='h3bold' gutterBottom component='div'>
                This privacy policy applies to data processing by:
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                RogerHR SA
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                Rue de Hesse 7,
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                1204 Genève, Switzerland
            </Typography>
            <Typography variant='body1' gutterBottom paragraph>
                info@rogerhr.ch
            </Typography>
        </Paper>
    );
};
