import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import {
    EmployeeReviewFeedbackObjectiveSummary,
    EmployeeReviewFeedbackQuestionSummary,
    EmployeeReviewFeedbackSectionSummary,
    EmployeeReviewFeedbackSkillsSummary,
    EmployeeReviewFeedbackSkillSummary,
    EmployeeReviewFeedbackSummary,
    EmployeeReviewManagerSummary,
    EmployeeReviewSelfSummary,
    EmployeeReviewSummaryItem,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { ContributorType } from '@/domain/review/Review.model';
import { mapToReviewRatingScale, ReviewRatingScaleDTO } from '@/api/review-rating-scale/ReviewRatingScale.api';
import { EmployeeReviewFeedbackRequest, mapReviewFeedbackDTO, ReviewFeedbackDTO } from '@/api/employee-review-feedback/EmployeeReviewFeedback.api';
import { SkillDTO } from '@/api/skill/Skill.api';
import { mapObjectiveDTO, ObjectiveDTO } from '@/api/objective/Objective.api';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { EmployeeReviewFeedbackMutation } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { ReviewItemDTO } from '@/api/review/Review.api';
import { ReviewTemplateItemType } from '@/domain/review-template/ReviewTemplate.model';

const mapFeedbackQuestionSummaryDTO = (feedbackQuestionSummary: EmployeeReviewFeedbackQuestionSummaryDTO): EmployeeReviewFeedbackQuestionSummary => {
    return {
        ...feedbackQuestionSummary,
        self: feedbackQuestionSummary.self ? mapReviewFeedbackDTO(feedbackQuestionSummary.self) : undefined,
        managers: feedbackQuestionSummary.managers ? feedbackQuestionSummary.managers.map(manager => mapReviewFeedbackDTO(manager)) : undefined,
        peers: feedbackQuestionSummary.peers ? feedbackQuestionSummary.peers.map(peer => mapReviewFeedbackDTO(peer)) : undefined,
        upwards: feedbackQuestionSummary.upwards ? feedbackQuestionSummary.upwards.map(upward => mapReviewFeedbackDTO(upward)) : undefined,
    };
};

const mapFeedbackSkillSummaryDTO = (feedbackSkillSummary: EmployeeReviewFeedbackSkillSummaryDTO): EmployeeReviewFeedbackSkillSummary => {
    return {
        ...feedbackSkillSummary,
        self: feedbackSkillSummary.self ? mapReviewFeedbackDTO(feedbackSkillSummary.self) : undefined,
        managers: feedbackSkillSummary.managers ? feedbackSkillSummary.managers.map(manager => mapReviewFeedbackDTO(manager)) : undefined,
        peers: feedbackSkillSummary.peers ? feedbackSkillSummary.peers.map(peer => mapReviewFeedbackDTO(peer)) : undefined,
        upwards: feedbackSkillSummary.upwards ? feedbackSkillSummary.upwards.map(upward => mapReviewFeedbackDTO(upward)) : undefined,
    };
};

const mapFeedbackObjectiveSummaryDTO = (feedbackObjectiveSummary: FeedbackObjectiveSummaryDTO): EmployeeReviewFeedbackObjectiveSummary => {
    return {
        ...feedbackObjectiveSummary,
        objective: mapObjectiveDTO(feedbackObjectiveSummary.objective),
    };
};

const mapFeedbackSkillsSummaryDTO = (feedbackSkillsSummary: FeedbackSkillsSummaryDTO): EmployeeReviewFeedbackSkillsSummary => {
    return {
        ...feedbackSkillsSummary,
        skills: feedbackSkillsSummary.skills.map(skill => mapFeedbackSkillSummaryDTO(skill)),
    };
};

const mapEmployeeReviewSummaryItemDTO = (employeeReviewSummaryItem: EmployeeReviewSummaryItemDTO): EmployeeReviewSummaryItem => {
    return {
        ...employeeReviewSummaryItem,
        employeeReviewFeedbackQuestionSummary: employeeReviewSummaryItem.question
            ? mapFeedbackQuestionSummaryDTO(employeeReviewSummaryItem.question)
            : undefined,
        employeeReviewFeedbackSkillQuestionSummary: employeeReviewSummaryItem.skillQuestion
            ? mapFeedbackSkillSummaryDTO(employeeReviewSummaryItem.skillQuestion)
            : undefined,
        employeeReviewFeedbackSectionSummary: employeeReviewSummaryItem.section ? employeeReviewSummaryItem.section : undefined,
        employeeReviewFeedbackObjectivesSummary: employeeReviewSummaryItem.objectives
            ? employeeReviewSummaryItem.objectives.map(objective => mapFeedbackObjectiveSummaryDTO(objective))
            : undefined,
        rating: employeeReviewSummaryItem.rating ? mapToReviewRatingScale(employeeReviewSummaryItem.rating) : undefined,
        employeeReviewFeedbackSkillsSummary: employeeReviewSummaryItem.skills ? mapFeedbackSkillsSummaryDTO(employeeReviewSummaryItem.skills) : undefined,
        id: employeeReviewSummaryItem.reviewItemId,
        type: employeeReviewSummaryItem.itemType,
    };
};

export type EmployeeReviewFeedbackSummaryDTO = Omit<EmployeeReviewFeedbackSummary, 'reviewItem' | 'objective'> & {
    reviewItem: ReviewItemDTO;
    objective: ObjectiveDTO | undefined;
};

export type EmployeeReviewSelfSummaryDTO = Omit<EmployeeReviewSelfSummary, 'items' | 'employee' | 'managers' | 'approvedAt' | 'closedAt' | 'closedBy'> & {
    items: EmployeeReviewSummaryItemDTO[];
    employee: EmployeeDTO;
    managers: EmployeeDTO[];
    approvedAt: string;
    closedAt: string;
    closedBy: EmployeeDTO | undefined;
};

const mapEmployeeReviewManagerSummaryDTO = (employeeReviewManagerSummary: EmployeeReviewManagerSummaryDTO): EmployeeReviewManagerSummary => {
    return {
        ...employeeReviewManagerSummary,
        employee: mapEmployeeDTO(employeeReviewManagerSummary.employee),
        managers: employeeReviewManagerSummary.managers.map(manager => mapEmployeeDTO(manager)),
        upwardReviewers: employeeReviewManagerSummary.upwardReviewers.map(upwardReviewer => mapEmployeeDTO(upwardReviewer)),
        peerReviewers: employeeReviewManagerSummary.peerReviewers.map(peerReviewer => mapEmployeeDTO(peerReviewer)),
        items: employeeReviewManagerSummary.items.map(item => mapEmployeeReviewSummaryItemDTO(item)),
        approvedAt: new Date(employeeReviewManagerSummary.approvedAt),
        closedAt: new Date(employeeReviewManagerSummary.closedAt),
        closedBy: employeeReviewManagerSummary.closedBy ? mapEmployeeDTO(employeeReviewManagerSummary.closedBy) : undefined,
    };
};

export const mapEmployeeReviewFeedbackSummaryDTO = (employeeReviewFeedbackSummary: EmployeeReviewFeedbackSummaryDTO): EmployeeReviewFeedbackSummary => {
    return {
        ...employeeReviewFeedbackSummary,
        reviewItem: {
            ...employeeReviewFeedbackSummary.reviewItem,
            rating: employeeReviewFeedbackSummary.reviewItem.rating ? mapToReviewRatingScale(employeeReviewFeedbackSummary.reviewItem.rating) : undefined,
        },
        objective: employeeReviewFeedbackSummary.objective ? mapObjectiveDTO(employeeReviewFeedbackSummary.objective) : undefined,
    };
};

const mapEmployeeReviewSelfSummaryDTO = (employeeReviewSelfSummary: EmployeeReviewSelfSummaryDTO): EmployeeReviewSelfSummary => {
    return {
        ...employeeReviewSelfSummary,
        employee: mapEmployeeDTO(employeeReviewSelfSummary.employee),
        managers: employeeReviewSelfSummary.managers.map(manager => mapEmployeeDTO(manager)),
        items: employeeReviewSelfSummary.items.map(item => mapEmployeeReviewSummaryItemDTO(item)),
        approvedAt: new Date(employeeReviewSelfSummary.approvedAt),
        closedAt: new Date(employeeReviewSelfSummary.closedAt),
        closedBy: employeeReviewSelfSummary.closedBy ? mapEmployeeDTO(employeeReviewSelfSummary.closedBy) : undefined,
    };
};

export async function getEmployeeReviewFeedbacksManagerSummary(employeeReviewId: number): Promise<EmployeeReviewManagerSummary> {
    const { data } = await client.get<EmployeeReviewManagerSummaryDTO>(API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/manager/summary`);

    return mapEmployeeReviewManagerSummaryDTO(data);
}

export async function createEmployeeReviewFeedbacksManagerSummary(
    employeeReviewId: number,
    employeeReviewFeedbackSummaryMutations: EmployeeReviewFeedbackMutation[],
): Promise<EmployeeReviewFeedbackSummary[]> {
    const { data } = await client.post<EmployeeReviewFeedbackSummaryDTO[], AxiosResponse<EmployeeReviewFeedbackSummaryDTO[], EmployeeReviewFeedbackRequest[]>>(
        API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/manager/summary`,
        employeeReviewFeedbackSummaryMutations,
    );

    return data.map(mapEmployeeReviewFeedbackSummaryDTO);
}

export async function createEmployeeReviewFeedbacksManagerSummaryItem(
    employeeReviewId: number,
    employeeReviewFeedbackSummaryMutation: EmployeeReviewFeedbackMutation,
): Promise<EmployeeReviewFeedbackSummary> {
    const { data } = await client.post<EmployeeReviewFeedbackSummaryDTO, AxiosResponse<EmployeeReviewFeedbackSummaryDTO, EmployeeReviewFeedbackRequest>>(
        API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/manager/summary/item`,
        employeeReviewFeedbackSummaryMutation,
    );

    return mapEmployeeReviewFeedbackSummaryDTO(data);
}

export async function getEmployeeReviewFeedbacksSummary(employeeReviewId: number): Promise<EmployeeReviewManagerSummary> {
    const { data } = await client.get<EmployeeReviewManagerSummaryDTO>(API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/summary`);

    return mapEmployeeReviewManagerSummaryDTO(data);
}

export async function getEmployeeReviewFeedbacksSelfSummary(employeeReviewId: number): Promise<EmployeeReviewSelfSummary> {
    const { data } = await client.get<EmployeeReviewSelfSummaryDTO>(API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/self/summary`);

    return mapEmployeeReviewSelfSummaryDTO(data);
}

export type EmployeeReviewManagerSummaryDTO = Omit<
    EmployeeReviewManagerSummary,
    'items' | 'employee' | 'managers' | 'upwardReviewers' | 'peerReviewers' | 'approvedAt' | 'closedAt' | 'closedBy'
> & {
    items: EmployeeReviewSummaryItemDTO[];
    employee: EmployeeDTO;
    managers: EmployeeDTO[];
    upwardReviewers: EmployeeDTO[];
    peerReviewers: EmployeeDTO[];
    approvedAt: string;
    closedAt: string;
    closedBy: EmployeeDTO | undefined;
};

export type EmployeeReviewFeedbackQuestionSummaryDTO = Omit<EmployeeReviewFeedbackQuestionSummary, 'self' | 'managers' | 'peers' | 'upwards'> & {
    self: ReviewFeedbackDTO | undefined;
    managers: ReviewFeedbackDTO[] | undefined;
    peers: ReviewFeedbackDTO[] | undefined;
    upwards: ReviewFeedbackDTO[] | undefined;
};

type EmployeeReviewFeedbackSkillSummaryDTO = Omit<EmployeeReviewFeedbackSkillSummary, 'self' | 'managers' | 'peers' | 'upwards' | 'skill'> & {
    self: ReviewFeedbackDTO | undefined;
    managers: ReviewFeedbackDTO[] | undefined;
    peers: ReviewFeedbackDTO[] | undefined;
    upwards: ReviewFeedbackDTO[] | undefined;
    skill: SkillDTO;
};

type FeedbackSectionSummaryDTO = EmployeeReviewFeedbackSectionSummary;

type FeedbackObjectiveSummaryDTO = Omit<EmployeeReviewFeedbackObjectiveSummary, 'objective'> & {
    objective: ObjectiveDTO;
};

type FeedbackSkillsSummaryDTO = Omit<EmployeeReviewFeedbackSkillsSummary, 'skills'> & {
    skills: EmployeeReviewFeedbackSkillSummaryDTO[];
};

export type EmployeeReviewSummaryItemDTO = Omit<
    EmployeeReviewSummaryItem,
    | 'employeeReviewFeedbackQuestionSummary'
    | 'employeeReviewFeedbackSkillQuestionSummary'
    | 'employeeReviewFeedbackSectionSummary'
    | 'employeeReviewFeedbackObjectivesSummary'
    | 'employeeReviewFeedbackSkillsSummary'
    | 'rating'
    | 'contributorType'
    | 'id'
    | 'type'
> & {
    question: EmployeeReviewFeedbackQuestionSummaryDTO | undefined;
    skillQuestion: EmployeeReviewFeedbackSkillSummaryDTO | undefined;
    section: FeedbackSectionSummaryDTO | undefined;
    objectives: FeedbackObjectiveSummaryDTO[] | undefined;
    skills: FeedbackSkillsSummaryDTO | undefined;
    rating: ReviewRatingScaleDTO | undefined;
    contributorTypes: ContributorType;
    itemType: ReviewTemplateItemType;
    reviewItemId: number;
};

export const employeeReviewFeedbackSummaryApi = {
    getEmployeeReviewFeedbacksManagerSummary,
    createEmployeeReviewFeedbacksManagerSummary,
    createEmployeeReviewFeedbacksManagerSummaryItem,
    getEmployeeReviewFeedbacksSummary,
    getEmployeeReviewFeedbacksSelfSummary,
};
