import Popover, { PopoverProps } from '@mui/material/Popover';
import { FC } from 'react';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { EmployeeAvatar as EmployeeAvatarComponent } from '@/Components/employee-avatar/EmployeeAvatar';
import { Stack } from '@mui/system';

type Props = {
    open: boolean;
    id?: string;
    anchorEl: PopoverProps['anchorEl'];
    onCloseUserPopover: () => void;
    employeeAvatars: EmployeeAvatar[];
    profileLink?: boolean;
};

export const EmployeeAvatarPopover: FC<Props> = ({ open, id, anchorEl, onCloseUserPopover, employeeAvatars, profileLink }) => {
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onCloseUserPopover}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            elevation={4}
            sx={{ m: -0.5 }}
        >
            <Stack direction={'row'} gap={1} flexWrap={'wrap'} maxWidth={'192px'} p={1}>
                {employeeAvatars?.map(employee => {
                    return <EmployeeAvatarComponent employeeAvatar={employee} profileLink={profileLink} key={employee.id} />;
                })}
            </Stack>
        </Popover>
    );
};
