import { DialogContainer, DialogContainerProps } from '@/Components/dialog-container/DialogContainer';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, FormControlLabel, Stack, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type ShareReportDialogProps = Omit<DialogContainerProps, 'onSave'> & {
    onSave: ({ viewers }: { viewers: EmployeeAvatar[] }) => void;
    viewers: EmployeeAvatar[];
};

type ShareReportFormValues = {
    viewers: EmployeeAvatar[];
};

const schema = yup.object().shape({
    viewers: yup.array().required().min(1),
});

export const ShareReportDialog: FC<ShareReportDialogProps> = ({ onSave, viewers, ...rest }) => {
    const { t } = useTranslation();
    const { handleSubmit, control } = useForm<ShareReportFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            viewers,
        },
    });

    const handleSave = (values: ShareReportFormValues) => {
        onSave(values);
    };

    const { data: employeesOptions = [], isLoading: isLoadingEmployees } = useGetEmployees();

    const getEmployeeOptionLabel = (option: ShareReportFormValues['viewers'][0]) => {
        return option.displayName;
    };

    return (
        <DialogContainer onSave={handleSubmit(handleSave, console.error)} title={t('reports.share_report_dialog.title')} {...rest}>
            <Stack gap={2}>
                <Controller
                    name='viewers'
                    control={control}
                    render={({ field: { onChange, value, ...restField }, fieldState }) => (
                        <FormControlLabel
                            label={t('reports.share_report_dialog.viewers')}
                            control={
                                <Autocomplete
                                    {...restField}
                                    value={value ?? []}
                                    onChange={(_, value) => onChange(value)}
                                    options={employeesOptions}
                                    loading={isLoadingEmployees}
                                    multiple={true}
                                    fullWidth
                                    getOptionLabel={getEmployeeOptionLabel}
                                    getOptionKey={option => option.id}
                                    isOptionEqualToValue={(option, current) => option.id === current.id}
                                    renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                                />
                            }
                        />
                    )}
                />
            </Stack>
        </DialogContainer>
    );
};
