import { CountrySelect } from '@/Components/country-select/CountrySelect';
import { FieldLocalDate } from '@/Components/form/field-date/FieldDate';
import { EditableDocumentField } from '@/Components/section/SectionFieldComponent/EditableDocumentField/EditableDocumentField';
import { SectionField } from '@/Components/section/types';
import { CustomList, CustomListItem } from '@/domain/custom-list/CustomList.model';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { LocalDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { Autocomplete, TextField } from '@mui/material';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export type EditableSectionFieldComponentProps = {
    field: SectionField;
};

export const EditableSectionFieldComponent: FC<EditableSectionFieldComponentProps> = ({ field }) => {
    if (!field?.formValueName) {
        return;
    }

    const commonProps = {
        formValueName: field.formValueName,
        disabled: field.disabled,
    };

    switch (field?.type) {
        case SectionFieldType.CUSTOM_LIST_ITEM:
        case SectionFieldType.CUSTOM_MULTI_LIST_ITEM:
            return (
                <EditableCustomListItem
                    values={field?.customListItemReferences?.map(item => item.id) ?? []}
                    customList={field?.customList}
                    multiple={field?.type === SectionFieldType.CUSTOM_MULTI_LIST_ITEM}
                    {...commonProps}
                />
            );
        case SectionFieldType.ENUM_LIST_ITEM:
            return <EditableEnumListItem value={field?.value ? field.value : getNull()} enumList={field?.enumList} {...commonProps} />;
        case SectionFieldType.DATE:
        case SectionFieldType.BIRTHDAY:
            return <EditableDateField value={field?.dateValue ? field.dateValue : getNull()} {...commonProps} />;
        case SectionFieldType.NUMBER:
            return <EditableNumberField value={field?.numberValue?.toString()} {...commonProps} />;
        case SectionFieldType.STRING:
        case SectionFieldType.PHONE_NUMBER:
        case SectionFieldType.IBAN_NUMBER:
        case SectionFieldType.AVS_NUMBER:
            return <EditableTextField value={field?.stringValue ?? ''} {...commonProps} />;
        case SectionFieldType.COUNTRY:
            return <CountrySelect countryValue={field?.countryValue ? field.countryValue : getNull()} {...commonProps} />;
        case SectionFieldType.SECTION_FIELD_DOCUMENT:
            return <EditableDocumentField documentsValue={field?.documents ?? []} {...commonProps} />;
        default:
            return undefined;
    }
};

type EditableDateFieldProps = {
    value: LocalDate | null;
    formValueName: string;
    disabled?: boolean;
};

const EditableDateField: FC<EditableDateFieldProps> = ({ value, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableDateFieldProps['value']>>();

    return <FieldLocalDate control={control} name={formValueName} disabled={disabled} defaultValue={value} />;
};

type EditableTextFieldProps = {
    value: string | null;
    formValueName: string;
    disabled?: boolean;
};

const EditableTextField: FC<EditableTextFieldProps> = ({ value, formValueName, disabled }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext<Record<string, EditableTextFieldProps['value']>>();

    return (
        <TextField
            fullWidth
            defaultValue={value ?? ''}
            error={!!errors[formValueName]}
            helperText={errors[formValueName]?.message?.toString() ?? ''}
            variant='outlined'
            disabled={disabled}
            inputProps={{ 'aria-label': formValueName }}
            {...register(formValueName)}
            id={formValueName}
        />
    );
};

type EditableNumberFieldProps = {
    value: string | undefined;
    formValueName: string;
    disabled?: boolean;
};
const EditableNumberField: FC<EditableNumberFieldProps> = ({ value, formValueName, disabled }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext<Record<string, EditableNumberFieldProps['value']>>();
    return (
        <TextField
            {...register(formValueName)}
            id={formValueName}
            error={!!errors[formValueName]}
            helperText={errors[formValueName]?.message?.toString() ?? ''}
            fullWidth
            defaultValue={value ?? ''}
            variant='outlined'
            inputProps={{ 'aria-label': formValueName }}
            disabled={disabled}
        />
    );
};

type EditableCustomListItemProps = {
    customList: CustomList | undefined;
    values: number[];
    formValueName: string;
    disabled?: boolean;
    multiple?: boolean;
};
const EditableCustomListItem: FC<EditableCustomListItemProps> = ({ customList, values: defaultValues, formValueName, disabled = false, multiple = false }) => {
    const { control } = useFormContext<Record<string, number[]>>();

    const options = customList?.items.map(item => item.id) ?? [];

    const getCustomListItem = (option: number): CustomListItem | undefined => {
        return customList?.items.find(item => item.id === option);
    };

    return (
        <Controller
            name={formValueName}
            control={control}
            defaultValue={defaultValues ?? []}
            render={({ field: { onChange, value, ...restField }, fieldState }) => {
                if (multiple) {
                    return (
                        <Autocomplete
                            {...restField}
                            id={formValueName}
                            multiple={true}
                            value={value ?? []}
                            fullWidth
                            disabled={disabled}
                            options={options}
                            onChange={(_, value) => {
                                onChange(value);
                            }}
                            getOptionLabel={option => getLabelTranslation(getCustomListItem(option)?.label)}
                            renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                        />
                    );
                }

                return (
                    <Autocomplete
                        {...restField}
                        id={formValueName}
                        value={value?.[0] ?? getNull()}
                        fullWidth
                        disabled={disabled}
                        options={options}
                        onChange={(_, value) => {
                            onChange([value]);
                        }}
                        getOptionLabel={option => getLabelTranslation(getCustomListItem(option)?.label)}
                        renderInput={params => <TextField {...params} error={!!fieldState.error} helperText={fieldState.error?.message} />}
                    />
                );
            }}
        />
    );
};
type EditableEnumListItemProps = {
    enumList: Map<string, string> | undefined;
    value: string | null;
    formValueName: string;
    disabled?: boolean;
};
const EditableEnumListItem: FC<EditableEnumListItemProps> = ({ enumList, value: defaultValue, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableEnumListItemProps['value']>>();

    const options = Array.from(enumList?.keys() ?? []);

    return (
        <Controller
            name={formValueName}
            control={control}
            // Empty string is not a valid value for enum fields
            defaultValue={defaultValue ?? getNull()}
            render={({ field: { onChange, value, name, ...restField }, fieldState }) => (
                <Autocomplete
                    {...restField}
                    value={value ?? getNull()}
                    fullWidth
                    disabled={disabled}
                    options={options}
                    getOptionLabel={option => enumList?.get(option) ?? ''}
                    onChange={(_, value) => {
                        onChange(value);
                    }}
                    renderInput={({ inputProps, ...params }) => (
                        <TextField
                            {...params}
                            id={formValueName}
                            inputProps={{ ...inputProps, 'aria-label': name }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            )}
        />
    );
};
