import { FieldSwitch } from '@/Components/form/field-switch/FieldSwitch';
import { PlanningSettingMutation, ScheduleColorType } from '@/domain/planning-setting/PlanningSetting.model';
import { getPlanningSettings, updatePlanningSettings } from '@/domain/planning-setting/PlanningSetting.service';
import { handleError } from '@/utils/api.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, FormGroup, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const PlanningSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [timeManagementId, setTimeManagementId] = useState<number>();

    const schema = yup.object().shape({
        shiftColor: yup
            .string()
            .required()
            .oneOf(Object.keys(ScheduleColorType) as ScheduleColorType[]),
        includeShiftsIntoTimesheets: yup.boolean().default(false),
        maximumWorkingTimePerDay: yup.number().required(),
        deleteConflictOnEmployeeRelease: yup.boolean().default(false),
        recurringShiftsNonWorkingDays: yup.boolean().default(false),
    });

    const {
        reset,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<PlanningSettingMutation>({
        resolver: yupResolver(schema),
        defaultValues: {
            shiftColor: ScheduleColorType.POSITION_BASED,
            includeShiftsIntoTimesheets: false,
            maximumWorkingTimePerDay: 0,
            deleteConflictOnEmployeeRelease: false,
            recurringShiftsNonWorkingDays: false,
        },
    });

    useEffect(() => {
        getPlanningSettings()
            .then(data => {
                const formData = {
                    shiftColor: data.shiftColor,
                    includeShiftsIntoTimesheets: data.includeShiftsIntoTimesheets,
                    maximumWorkingTimePerDay: data.maximumWorkingTimePerDay,
                    deleteConflictOnEmployeeRelease: data.deleteConflictOnEmployeeRelease,
                    recurringShiftsNonWorkingDays: data.recurringShiftsNonWorkingDays,
                };
                reset(formData);
                setTimeManagementId(data.id);
            })
            .catch(error => handleError(error));
    }, [reset]);

    const updateTimeManagement = () => {
        if (!timeManagementId) {
            return;
        }
        updatePlanningSettings(timeManagementId, watch())
            .then(() => {
                // do nothing
            })
            .catch(error => handleError(error));
    };

    return (
        <Paper elevation={1} sx={{ padding: 2 }}>
            <Stack gap={2}>
                <Typography variant='body1bold'>{t('time_management.planning_settings')}</Typography>
                <Controller
                    name='shiftColor'
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            label={t('time_management.color_based_on')}
                            labelPlacement='top'
                            control={
                                <Select
                                    id='colorType'
                                    error={!!errors.shiftColor}
                                    {...field}
                                    fullWidth
                                    onChange={e => {
                                        setValue('shiftColor', ScheduleColorType[e.target.value as keyof typeof ScheduleColorType]);
                                        updateTimeManagement();
                                    }}
                                >
                                    {Object.keys(ScheduleColorType).map(item => (
                                        <MenuItem key={item} value={item}>
                                            {t('time_management.color_based_on', { context: item })}
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                        />
                    )}
                />

                <Controller
                    name='deleteConflictOnEmployeeRelease'
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            label={t('time_management.behavior_when_overriding_conflicts')}
                            labelPlacement='top'
                            control={
                                <Select
                                    id='deleteConflictOnEmployeeRelease'
                                    error={!!errors.shiftColor}
                                    {...field}
                                    fullWidth
                                    onChange={e => {
                                        setValue('deleteConflictOnEmployeeRelease', e.target.value === 'true');
                                        updateTimeManagement();
                                    }}
                                >
                                    <MenuItem value='true'>{t('time_management.delete_conflict')}</MenuItem>
                                    <MenuItem value='false'>{t('time_management.keep_conflict')}</MenuItem>
                                </Select>
                            }
                        />
                    )}
                />
                <FormGroup row>
                    <FormControlLabel
                        control={<FieldSwitch name='includeShiftsIntoTimesheets' control={control} onChange={updateTimeManagement} />}
                        label={t('time_management.enable_copying_planning_shifts_to_timesheets')}
                        labelPlacement='end'
                    />
                </FormGroup>
                <FormGroup row>
                    <FormControlLabel
                        control={<FieldSwitch control={control} name='recurringShiftsNonWorkingDays' onChange={updateTimeManagement} />}
                        label={t('time_management.recurring_shifts_on_non_working_days')}
                        labelPlacement='end'
                    />
                </FormGroup>
            </Stack>
        </Paper>
    );
};
