import * as yup from 'yup';
import { getLocalDateTestConfig, LocalDate } from '@/utils/datetime.util';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { UserLanguage } from '@/utils/language.util';
import { Label } from '@/domain/label/Label.model';

export type InvitationForm = {
    endDate: LocalDate;
    feedbackDeadlineDate: LocalDate;
    managerSubject: Label;
    managerBody: Label;
    selfSubject?: Label;
    selfBody?: Label;
    peerSubject?: Label;
    peerBody?: Label;
    upwardSubject?: Label;
    upwardBody?: Label;
};

export const getInvitationFormSchema = (
    {
        showSelfInvitationEmail,
        showPeerInvitationEmail,
        showUpwardInvitationEmail,
    }: { showSelfInvitationEmail: boolean; showPeerInvitationEmail: boolean; showUpwardInvitationEmail: boolean },
    translationLanguage?: UserLanguage,
): yup.ObjectSchema<InvitationForm> => {
    return yup.object().shape({
        endDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        feedbackDeadlineDate: yup.string<LocalDate>().required().test(getLocalDateTestConfig()),
        managerSubject: getLabelFormSchema(translationLanguage),
        managerBody: getLabelFormSchema(translationLanguage),
        selfSubject: showSelfInvitationEmail ? getLabelFormSchema(translationLanguage) : getLabelFormSchema(),
        selfBody: showSelfInvitationEmail ? getLabelFormSchema(translationLanguage) : getLabelFormSchema(),
        peerSubject: showPeerInvitationEmail ? getLabelFormSchema(translationLanguage) : getLabelFormSchema(),
        peerBody: showPeerInvitationEmail ? getLabelFormSchema(translationLanguage) : getLabelFormSchema(),
        upwardSubject: showUpwardInvitationEmail ? getLabelFormSchema(translationLanguage) : getLabelFormSchema(),
        upwardBody: showUpwardInvitationEmail ? getLabelFormSchema(translationLanguage) : getLabelFormSchema(),
    });
};
