import { LocalizationProvider, TimeField as MuiTimeField, TimeFieldProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ForwardedRef, forwardRef } from 'react';
import { getLocale, getUserLanguage } from '@/utils/language.util';

export const TimeFieldWrapper = forwardRef<ForwardedRef<HTMLDivElement>, TimeFieldProps<Date>>(({ ...rest }) => {
    const userLanguage = getUserLanguage();
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(userLanguage)}>
            <MuiTimeField ampm={false} {...rest} />
        </LocalizationProvider>
    );
});
