import { API_BASE_URL, client } from '../common';
import { AxiosResponse } from 'axios';
import {
    SectionDefinition,
    SectionDefinitionCreateRequest,
    SectionDefinitionIncludeInPayrollRequest,
    SectionDefinitionUpdateRequest,
} from '@/domain/section-setting/Section.model';
import { OrderMutation } from '@/domain/common';

export type SectionDefinitionDTO = SectionDefinition;
type SectionDefinitionCreateRequestDTO = SectionDefinitionCreateRequest;
type SectionDefinitionIncludeInPayrollRequestDTO = SectionDefinitionIncludeInPayrollRequest;
type SectionDefinitionUpdateRequestDTO = SectionDefinitionUpdateRequest;

const getSectionDefinitions = async (): Promise<SectionDefinition[]> => {
    return (await client.get<SectionDefinitionDTO[]>(API_BASE_URL + '/section-definitions')).data;
};

const getSectionDefinitionById = async (id: number): Promise<SectionDefinition> => {
    return (await client.get<SectionDefinitionDTO>(API_BASE_URL + `/section-definitions/${id}`)).data;
};

const createSectionDefinition = async (request: SectionDefinitionCreateRequest): Promise<SectionDefinition> => {
    return (
        await client.post<SectionDefinitionDTO, AxiosResponse<SectionDefinitionDTO>, SectionDefinitionCreateRequestDTO>(
            API_BASE_URL + `/section-definitions`,
            request,
        )
    ).data;
};

const updateIncludeInPayrollSectionDefinition = async (id: number, request: SectionDefinitionIncludeInPayrollRequest): Promise<SectionDefinition> => {
    return (
        await client.patch<SectionDefinitionDTO, AxiosResponse<SectionDefinitionDTO>, SectionDefinitionIncludeInPayrollRequestDTO>(
            API_BASE_URL + `/section-definitions/${id}/include-in-payroll`,
            request,
        )
    ).data;
};

const updateSectionDefinition = async (id: number, request: SectionDefinitionUpdateRequest): Promise<SectionDefinition> => {
    return (
        await client.put<SectionDefinitionDTO, AxiosResponse<SectionDefinitionDTO>, SectionDefinitionUpdateRequestDTO>(
            API_BASE_URL + `/section-definitions/${id}`,
            request,
        )
    ).data;
};

const deleteSectionDefinition = async (id: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/section-definitions/${id}`);
};

const updateSectionDefinitionsOrder = async (request: OrderMutation[]): Promise<void> => {
    await client.put(API_BASE_URL + `/section-definitions/order`, request);
};

export const sectionDefinitionApi = {
    getSectionDefinitions,
    getSectionDefinitionById,
    createSectionDefinition,
    updateIncludeInPayrollSectionDefinition,
    updateSectionDefinition,
    deleteSectionDefinition,
    updateSectionDefinitionsOrder,
};
