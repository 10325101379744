import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import {
    ApproveTimesheetPaymentsMutation,
    CancelTimesheetPaymentMutation,
    DeclineTimesheetPaymentsMutation,
    TimesheetPayment,
    TimesheetPaymentCreateMutation,
    TimesheetPaymentSearchRequest,
    TimesheetPaymentUpdateMutation,
} from '@/domain/timesheet-payment/TimesheetPayment.model';

const timesheetPaymentsSearch = async (searchRequest: TimesheetPaymentSearchRequest): Promise<TimesheetPayment[]> => {
    const url = API_BASE_URL + `/timesheet-payments/search`;
    return (await client.post<TimesheetPaymentDTO[], AxiosResponse<TimesheetPaymentDTO[]>, TimesheetPaymentSearchRequest>(url, searchRequest)).data;
};

export const createTimesheetPayment = async (mutation: TimesheetPaymentCreateMutation): Promise<TimesheetPayment> => {
    const url = API_BASE_URL + '/timesheet-payments';
    return (await client.post<TimesheetPaymentDTO, AxiosResponse<TimesheetPaymentDTO>, TimesheetPaymentCreateMutation>(url, mutation)).data;
};

const approveTimesheetPayments = async (mutation: ApproveTimesheetPaymentsMutation): Promise<TimesheetPayment[]> => {
    const url = API_BASE_URL + `/timesheet-payments/approve`;
    return (await client.post<TimesheetPaymentDTO[], AxiosResponse<TimesheetPaymentDTO[]>, ApproveTimesheetPaymentsMutation>(url, mutation)).data;
};

const declineTimesheetPayments = async (mutation: DeclineTimesheetPaymentsMutation): Promise<TimesheetPayment[]> => {
    const url = API_BASE_URL + `/timesheet-payments/decline`;
    return (await client.post<TimesheetPaymentDTO[], AxiosResponse<TimesheetPaymentDTO[]>, DeclineTimesheetPaymentsMutation>(url, mutation)).data;
};

const cancelTimesheetPayments = async (mutation: CancelTimesheetPaymentMutation): Promise<TimesheetPayment[]> => {
    const url = API_BASE_URL + `/timesheet-payments/cancel`;
    return (await client.post<TimesheetPaymentDTO[], AxiosResponse<TimesheetPaymentDTO[]>, CancelTimesheetPaymentMutation>(url, mutation)).data;
};

const updateTimesheetPayment = async (request: TimesheetPaymentUpdateMutation, id: number): Promise<TimesheetPayment> => {
    const url = API_BASE_URL + `/timesheet-payments/${id}`;
    return (await client.put<TimesheetPaymentDTO, AxiosResponse<TimesheetPaymentDTO>, TimesheetPaymentUpdateMutation>(url, request)).data;
};

const deleteTimesheetPayment = async (id: number): Promise<void> => {
    const url = API_BASE_URL + `/timesheet-payments/${id}`;
    await client.delete(url);
};

export const timesheetPaymentAPI = {
    timesheetPaymentsSearch,
    createTimesheetPayment,
    approveTimesheetPayments,
    declineTimesheetPayments,
    cancelTimesheetPayments,
    updateTimesheetPayment,
    deleteTimesheetPayment,
};

export type TimesheetPaymentDTO = TimesheetPayment;
