import { timesheetPaymentAPI } from '@/api/timesheet-payment/TimesheetPayment.api';
import {
    ApproveTimesheetPaymentsMutation,
    CancelTimesheetPaymentMutation,
    DeclineTimesheetPaymentsMutation,
    TimesheetPayment,
    TimesheetPaymentCreateMutation,
    TimesheetPaymentSearchRequest,
    TimesheetPaymentUpdateMutation,
} from '@/domain/timesheet-payment/TimesheetPayment.model';

export const timesheetPaymentsSearch = (searchRequest: TimesheetPaymentSearchRequest): Promise<TimesheetPayment[]> => {
    return timesheetPaymentAPI.timesheetPaymentsSearch(searchRequest);
};

export const createTimesheetPayment = (mutation: TimesheetPaymentCreateMutation): Promise<TimesheetPayment> => {
    return timesheetPaymentAPI.createTimesheetPayment(mutation);
};

export const approveTimesheetPayments = (mutation: ApproveTimesheetPaymentsMutation): Promise<TimesheetPayment[]> => {
    return timesheetPaymentAPI.approveTimesheetPayments(mutation);
};

export const declineTimesheetPayments = (mutation: DeclineTimesheetPaymentsMutation): Promise<TimesheetPayment[]> => {
    return timesheetPaymentAPI.declineTimesheetPayments(mutation);
};

export const cancelTimesheetPayments = (mutation: CancelTimesheetPaymentMutation): Promise<TimesheetPayment[]> => {
    return timesheetPaymentAPI.cancelTimesheetPayments(mutation);
};

export const updateTimesheetPayment = (mutation: TimesheetPaymentUpdateMutation, id: number): Promise<TimesheetPayment> => {
    return timesheetPaymentAPI.updateTimesheetPayment(mutation, id);
};

export const deleteTimesheetPayment = (id: number): Promise<void> => {
    return timesheetPaymentAPI.deleteTimesheetPayment(id);
};
