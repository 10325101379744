import { Grid, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Props = {
    description: string;
    title: string;
};

export const OptionsBlock: FC<PropsWithChildren<Props>> = ({ title, description, children }) => {
    return (
        <Grid container direction='row' spacing={2}>
            <Grid item xs={12} sm={3} lg={3}>
                <Stack gap={1}>
                    <Typography variant='body2' color='000A1D'>
                        {title}
                    </Typography>

                    {description && <Typography variant='body2'>{description}</Typography>}
                </Stack>
            </Grid>
            <Grid item xs={12} sm={9} lg={9}>
                {children}
            </Grid>
        </Grid>
    );
};
