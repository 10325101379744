import { API_BASE_URL, client } from '@/api/common';
import { EmployeeRole, EmployeeRoleCreationMutation, EmployeeRoleSearch, EmployeeRoleUpdateMutation } from '@/domain/employee-role/EmployeeRole.model';
import { AxiosResponse } from 'axios';

type EmployeeRoleDTO = EmployeeRole;
type EmployeeRoleSearchRequestDTO = EmployeeRoleSearch;
type EmployeeRoleCreationRequestDTO = EmployeeRoleCreationMutation;
type EmployeeRoleUpdateRequestDTO = EmployeeRoleUpdateMutation;

const searchEmployeeRoles = async (request: EmployeeRoleSearch = { employeeIds: [] }): Promise<EmployeeRole[]> => {
    const data = (
        await client.post<EmployeeRoleDTO[], AxiosResponse<EmployeeRoleDTO[]>, EmployeeRoleSearchRequestDTO>(API_BASE_URL + '/employee-roles/search', request)
    ).data;
    return data.map(roleDTO => mapEmployeeRoleDTO(roleDTO));
};

const createEmployeeRole = async (employeeRoleCreationMutation: EmployeeRoleCreationMutation): Promise<EmployeeRole> => {
    const data = (
        await client.post<EmployeeRoleDTO, AxiosResponse<EmployeeRoleDTO>, EmployeeRoleCreationRequestDTO>(
            API_BASE_URL + '/employee-roles',
            employeeRoleCreationMutation,
        )
    ).data;
    return mapEmployeeRoleDTO(data);
};

const updateEmployeeRole = async (id: number, employeeRoleUpdateMutation: EmployeeRoleUpdateMutation): Promise<EmployeeRole> => {
    const data = (
        await client.put<EmployeeRoleDTO, AxiosResponse<EmployeeRoleDTO>, EmployeeRoleUpdateRequestDTO>(
            API_BASE_URL + `/employee-roles/${id}`,
            employeeRoleUpdateMutation,
        )
    ).data;
    return mapEmployeeRoleDTO(data);
};

const deleteEmployeeRole = async (id: number): Promise<void> => {
    await client.delete(API_BASE_URL + `/employee-roles/${id}`);
};

const mapEmployeeRoleDTO = (roleDTO: EmployeeRoleDTO): EmployeeRole => {
    return roleDTO;
};

export const employeeRoleApi = {
    searchEmployeeRoles,
    createEmployeeRole,
    updateEmployeeRole,
    deleteEmployeeRole,
};
