import { EmployeeWorkingPattern } from '@/domain/employee-working-pattern/EmployeeWorkingPattern.model';
import { Employment, EmploymentStatus } from '@/domain/employment/Employment.model';
import { PermissionGroupType } from '@/domain/permission-group/PermissionGroup.model';
import { PolicyPermission, PolicyType } from '@/domain/permission/Permission.model';
import { PlanningPosition } from '@/domain/planning-position/PlanningPosition.model';
import { PlanningTag } from '@/domain/planning-tag/PlanningTag.model';
import { LoginMethod, LoginMethodType } from '@/domain/realm/Realm.model';
import { Role } from '@/domain/role/Role.model';
import { LocalDate } from '@/utils/datetime.util';
import { UserLanguage } from '@/utils/language.util';

export type Employee = {
    id: number;
    employeeCode?: string;
    firstName: string;
    lastName: string;
    maidenName: string;
    displayName: string;
    email: string;
    phoneNumber?: string;
    status: EmployeeStatus;
    language: UserLanguage;
    leaveNotificationsEnabled: boolean;
    leaveAttachmentNotificationsEnabled: boolean;
    planningUpdatesNotificationsEnabled: boolean;
    documentNotificationsEnabled: boolean;
    profileChangeNotificationsEnabled: boolean;
    timesheetUpdatesNotificationsEnabled: boolean;
    announcementNotificationsEnabled: boolean;
    avatarImageUrl?: string;
    employmentStatus?: EmploymentStatus;
    /**
     * Employee can have multiple current employments
     * One of them is the main employment
     *
     * Warning: Current employment could be empty
     */
    currentEmployments: Employment[];
    currentWorkingPattern?: EmployeeWorkingPattern;
    planningTags?: PlanningTag[];
    planningPositions?: PlanningPosition[];
    contractStartDate?: LocalDate;
    contractProbationEndDate?: LocalDate;
    contractEndDate?: LocalDate;
    subordinates?: Employee[];
    roles?: Role[];
    seniorityDate?: LocalDate;
};

export type EmployeeAuthentication = {
    employee: Employee;
    loginMethod: LoginMethod;
    permissionGroups: EmployeePermissionGroup[];
    grantedPolicies: EmployeePolicy[];
};

export type EmployeePermissionGroup = {
    id: number;
    groupName: string;
    description: string;
    groupType: PermissionGroupType;
};

export type EmployeePolicy = {
    policy: PolicyPermission;
    policyType: PolicyType;
    targetEmployeeIds: number[];
    targetResourceId?: number;
};

export type EmployeeAvatar = Pick<Employee, 'id' | 'firstName' | 'lastName' | 'displayName' | 'avatarImageUrl' | 'email'>;

export type EmployeeSearch = {
    email?: string;
    employeeCode?: string;
    employeeIds?: number[];
    planningPositionIds?: number[];
    planningTagIds?: number[];
    locationIds?: number[];
    jobIds?: number[];
    departmentIds?: number[];
    managerIds?: number[];
    statuses?: EmploymentStatus[];
    quickSearch?: string;
};

export type EmployeeAnniversary = Omit<EmployeeAvatar, 'id'> & {
    employeeId: Employee['id'];
    anniversaryDate: LocalDate;
};

export type EmployeeAnniversaryRequest = {
    employeeIds: number[];
    startDate: LocalDate;
    endDate: LocalDate;
};

export const EMPLOYEE_FIELD_TYPES = {
    EMPLOYEE: 'EMPLOYEE',
    EMPLOYEE_FIRSTNAME: 'EMPLOYEE_FIRSTNAME',
    EMPLOYEE_LASTNAME: 'EMPLOYEE_LASTNAME',
    EMPLOYEE_MAIDEN_NAME: 'EMPLOYEE_MAIDEN_NAME',
    EMPLOYEE_DISPLAY_NAME: 'EMPLOYEE_DISPLAY_NAME',
    EMPLOYEE_EMAIL: 'EMPLOYEE_EMAIL',
    EMPLOYEE_PHONE_NUMBER: 'EMPLOYEE_PHONE_NUMBER',
    EMPLOYEE_CODE: 'EMPLOYEE_CODE',
    EMPLOYEE_GENDER: 'EMPLOYEE_GENDER',
    EMPLOYEE_MARITAL_STATUS: 'EMPLOYEE_MARITAL_STATUS',
    EMPLOYEE_MARITAL_STATUS_SINCE: 'EMPLOYEE_MARITAL_STATUS_SINCE',
    EMPLOYEE_BIRTH_DATE: 'EMPLOYEE_BIRTH_DATE',
    EMPLOYEE_NATIONALITY: 'EMPLOYEE_NATIONALITY',
    EMPLOYEE_STATUS: 'EMPLOYEE_STATUS',
    EMPLOYEE_SENIORITY_DATE: 'EMPLOYEE_SENIORITY_DATE',
    CONTRACT_START_DATE: 'CONTRACT_START_DATE',
    EMPLOYEE_CUSTOM_FIELD: 'EMPLOYEE_CUSTOM_FIELD',
    EMPLOYEE_SECTION_ROW_UPDATED_BY: 'EMPLOYEE_SECTION_ROW_UPDATED_BY',
    EMPLOYEE_SECTION_ROW_UPDATED_AT: 'EMPLOYEE_SECTION_ROW_UPDATED_AT',
    EMPLOYEE_AVS: 'EMPLOYEE_AVS',
    EMPLOYEE_PERSONAL_EMAIL: 'EMPLOYEE_PERSONAL_EMAIL',
    EMPLOYEE_PERSONAL_PHONE_NUMBER: 'EMPLOYEE_PERSONAL_PHONE_NUMBER',

    // Employment
    CURRENT_EMPLOYMENT_STATUS: 'CURRENT_EMPLOYMENT_STATUS',
    CURRENT_EMPLOYMENT_CONTRACT_TYPE: 'CURRENT_EMPLOYMENT_CONTRACT_TYPE',
    CURRENT_EMPLOYMENT_START_DATE: 'CURRENT_EMPLOYMENT_START_DATE',
    CURRENT_EMPLOYMENT_LOCATION: 'CURRENT_EMPLOYMENT_LOCATION',
    CURRENT_EMPLOYMENT_DEPARTMENT: 'CURRENT_EMPLOYMENT_DEPARTMENT',
    CURRENT_EMPLOYMENT_JOB: 'CURRENT_EMPLOYMENT_JOB',
    CURRENT_EMPLOYMENT_MANAGER: 'CURRENT_EMPLOYMENT_MANAGER',
    CURRENT_EMPLOYMENT_CREATION_REASON: 'CURRENT_EMPLOYMENT_CREATION_REASON',
    CURRENT_EMPLOYMENT_TERMINATION_REASON: 'CURRENT_EMPLOYMENT_TERMINATION_REASON',
    CURRENT_EMPLOYMENT_TERMINATION_TYPE: 'CURRENT_EMPLOYMENT_TERMINATION_TYPE',
    CURRENT_EMPLOYMENT_TERMINATION_COMMENT: 'CURRENT_EMPLOYMENT_TERMINATION_COMMENT',
    CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY: 'CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY',
    CURRENT_EMPLOYMENT_SUBORDINATES: 'CURRENT_EMPLOYMENT_SUBORDINATES',
    CURRENT_EMPLOYMENT_PROBATION_END_DATE: 'CURRENT_EMPLOYMENT_PROBATION_END_DATE',
    CURRENT_EMPLOYMENT_CONTRACT_END_DATE: 'CURRENT_EMPLOYMENT_CONTRACT_END_DATE',
    CURRENT_EMPLOYMENT_TERMINATION_NOTICE_DATE: 'CURRENT_EMPLOYMENT_TERMINATION_NOTICE_DATE',
    CURRENT_EMPLOYMENT_COST_CENTERS: 'CURRENT_EMPLOYMENT_COST_CENTERS',

    // Address
    CURRENT_ADDRESS_START_DATE: 'CURRENT_ADDRESS_START_DATE',
    CURRENT_ADDRESS_END_DATE: 'CURRENT_ADDRESS_END_DATE',
    CURRENT_ADDRESS_COUNTRY: 'CURRENT_ADDRESS_COUNTRY',
    CURRENT_ADDRESS_REGION: 'CURRENT_ADDRESS_REGION',
    CURRENT_ADDRESS_POST_CODE: 'CURRENT_ADDRESS_POST_CODE',
    CURRENT_ADDRESS_CITY: 'CURRENT_ADDRESS_CITY',
    CURRENT_ADDRESS_STREET: 'CURRENT_ADDRESS_STREET',
    CURRENT_ADDRESS_ADDITIONAL_ADDRESS: 'CURRENT_ADDRESS_ADDITIONAL_ADDRESS',

    // Working pattern
    CURRENT_WORKING_PATTERN_NAME: 'WORKING_PATTERN_NAME',
    CURRENT_WORKING_PATTERN_TYPE: 'WORKING_PATTERN_TYPE',
    CURRENT_WORKING_PATTERN_START_DATE: 'WORKING_PATTERN_START_DATE',
    CURRENT_WORKING_PATTERN_END_DATE: 'WORKING_PATTERN_END_DATE',
    CURRENT_WORKING_PATTERN_WEEKLY_AMOUNT_WORKED: 'WORKING_PATTERN_WEEKLY_AMOUNT_WORKED',
    CURRENT_WORKING_PATTERN_TOTAL_WEEKLY_WORKING_TIME: 'WORKING_PATTERN_TOTAL_WEEKLY_WORKING_TIME',
    CURRENT_WORKING_PATTERN_TOTAL_WORK_RATE: 'WORKING_PATTERN_TOTAL_WORK_RATE',
    CURRENT_WORKING_PATTERN_TIMESHEET_SETTING_NAME: 'CURRENT_WORKING_PATTERN_TIMESHEET_SETTING_NAME',
    CURRENT_WORKING_PATTERN_CALENDAR: 'CURRENT_WORKING_PATTERN_CALENDAR',

    // Long leave
    LONG_LEAVE_START_DATE: 'LONG_LEAVE_START_DATE',
    LONG_LEAVE_END_DATE: 'LONG_LEAVE_END_DATE',
    LONG_LEAVE_COMMENT: 'LONG_LEAVE_COMMENT',
};

export type EmployeeFieldType = keyof typeof EMPLOYEE_FIELD_TYPES;

export const FIELD_VALUE_TYPES = [
    'STRING',
    'NUMBER',
    'DATE',
    'REFERENCE',
    'CUSTOM_LIST_ITEM',
    'CUSTOM_MULTI_LIST_ITEM',
    'DOCUMENT',
    'SECTION_FIELD_DOCUMENT',
    'ENUM',
    'LABEL',
    'EMPLOYEE',
    'COUNTRY',
    'PHONE_NUMBER',
    'IBAN_NUMBER',
    'AVS_NUMBER',
] as const;

export type FieldValueType = (typeof FIELD_VALUE_TYPES)[number];

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NB = 'NB',
}

export enum EmployeeStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    WAITING_FOR_ACTIVATION = 'WAITING_FOR_ACTIVATION',
}

export enum MaritalStatus {
    SINGLE = 'SINGLE',
    MARRIED = 'MARRIED',
    WIDOWED = 'WIDOWED',
    DIVORCED = 'DIVORCED',
    SEPARATED = 'SEPARATED',
    REGISTERED_PARTNERSHIP = 'REGISTERED_PARTNERSHIP',
}

export type EmployeeSearchLoginMethodRequest = {
    email: string;
    tenantId: string;
};

export type EmployeeLoginMethod = {
    loginMethodType: LoginMethodType;
};

export type EmployeeLoginMethodUpdateMutation = {
    loginMethodId: number;
};

export type EmployeeLoginMutation = {
    username: string;
};

export type EmployeeBasicInfo = {
    employeeId: number;
    firstName: string;
    lastName: string;
    maidenName: string;
    displayName: string;
    email: string;
    phoneNumber: string;
    employeeCode: string;
};

export type EmployeeBasicInfoUpdateMutation = Omit<EmployeeBasicInfo, 'employeeId'>;

export type EmployeeEmailUpdateMutation = {
    email: string;
};

export type EmployeeShiftSettings = {
    employeeId: number;
    planningTagIds: number[];
    planningPositionIds: number[];
};

export type EmployeeShiftSettingsUpdateMutation = Omit<EmployeeShiftSettings, 'employeeId'>;

export type EmployeeSettings = {
    employeeId: number;
    language: UserLanguage;
    leaveNotificationsEnabled: boolean;
    leaveAttachmentNotificationsEnabled: boolean;
    planningUpdatesNotificationsEnabled: boolean;
    documentNotificationsEnabled: boolean;
    profileChangeNotificationsEnabled: boolean;
    timesheetUpdatesNotificationsEnabled: boolean;
    announcementNotificationsEnabled: boolean;
};

export type EmployeeSettingsUpdateMutation = Omit<EmployeeSettings, 'employeeId'>;
