import { Button, FormControlLabel, Stack, StackProps } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DatePickerWrapper } from '@/Components/date-picker/DatePickerWrapper';
import { FC } from 'react';
import * as yup from 'yup';
import i18next from 'i18next';
import { isValidDate } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

type DateRangeFormProps = {
    dates: [Date | undefined, Date | undefined];
    onDatesChanged: (dates: [Date, Date]) => void;
} & StackProps;
export const DateRangeForm: FC<DateRangeFormProps> = ({ dates, onDatesChanged, ...restRoot }) => {
    const { t } = useTranslation();
    const [start, end] = dates;
    const { control, handleSubmit } = useForm<DateRangeFilterValues>({
        defaultValues: {
            start,
            end,
        },
        resolver: yupResolver(getDateRangeFilterSchema()),
    });

    return (
        <Stack gap={1} {...restRoot}>
            <Stack direction={'row'} gap={2} alignItems={'flex-start'}>
                <Controller
                    name={'start'}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControlLabel
                            label={t('general.start')}
                            control={
                                <DatePickerWrapper
                                    {...field}
                                    slotProps={{
                                        textField: {
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            }
                            sx={{ flex: 1 }}
                        />
                    )}
                />
                <Controller
                    name={'end'}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <FormControlLabel
                            label={t('general.end')}
                            control={
                                <DatePickerWrapper
                                    {...field}
                                    slotProps={{
                                        textField: {
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            }
                            sx={{ flex: 1 }}
                        />
                    )}
                />
            </Stack>
            <Button onClick={handleSubmit(({ start, end }) => onDatesChanged([start, end]), console.error)} variant={'text'} sx={{ alignSelf: 'end' }}>
                {t('general.submit')}
            </Button>
        </Stack>
    );
};

const getDateRangeFilterSchema = () => {
    const dateSchema = yup
        .date()
        .test({
            message: i18next.t('general.validations.valid_date'),
            test: date => {
                return date ? isValidDate(date) : true;
            },
        })
        .required();

    return yup.object().shape({
        start: dateSchema,
        end: dateSchema.min(yup.ref('start'), i18next.t('general.validations.end_after_start')),
    });
};
type DateRangeFilterValues = yup.InferType<ReturnType<typeof getDateRangeFilterSchema>>;
