import { DialogWrapper, DialogWrapperProps } from '@/Components/dialog-wrapper/DialogWrapper';
import { FieldSwitch } from '@/Components/form/field-switch/FieldSwitch';
import { StackedAvatars } from '@/Components/stacked-avatar/StackedAvatars';
import { TranslatableLabelInput } from '@/Components/translatable-label-input/TranslatableLabelInput';
import { TranslatableRichTextEditor } from '@/Components/translatable-rich-text-editor/TranslatableRichTextEditor';
import { TranslationLanguageSelector } from '@/Components/translation-language-selector/TranslationLanguageSelector';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { getLabelFormSchema } from '@/domain/label/Label.schema';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { ContributorArray, ContributorType, Review, ReviewNotificationType, ReviewSendReminderMutation } from '@/domain/review/Review.model';
import { sendReminder } from '@/domain/review/Review.service';
import { handleError } from '@/utils/api.util';
import { getSingleItemIfExists } from '@/utils/collections.util';
import { getLocalizedErrorMessage, getRealmLanguage, UserLanguage } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Autocomplete, Button, DialogActions, FormControlLabel, FormLabel, TextField, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import { Stack } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, DeepPartial, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type SendEmployeeReviewReminderDialogProps = DialogWrapperProps & {
    review: Review;
    employeeReviews: EmployeeReview[];
};

export const SendEmployeeReviewReminderDialog: FC<SendEmployeeReviewReminderDialogProps> = props => {
    const { review, employeeReviews, ...restDialog } = props;
    const { onClose } = restDialog;
    const [translationLanguage, setTranslationLanguage] = useState(getRealmLanguage());

    const recipients = employeeReviews.map(er => er.employee) ?? [];
    const isEmployeeReviewsEmpty = !employeeReviews.length;

    const { t } = useTranslation();

    const notificationTypesOptions = getNotificationTypeOptions(employeeReviews);

    const { control, handleSubmit, watch } = useForm<ReminderDialogFormValues>({
        resolver: yupResolver(getReminderDialogSchema(translationLanguage)),
        defaultValues: {
            notificationType: getSingleItemIfExists(notificationTypesOptions),
            defaultEmail: true,
            emails: [],
        },
    });

    const { fields: contributorEmailFields, replace: replaceContributorEmails } = useFieldArray({
        control,
        name: 'emails',
    });

    const refreshContributorEmails = useCallback(
        (values: DeepPartial<ReminderDialogFormValues>) => {
            // values is typed as DeepPartial<ReminderDialogFormValues>
            // because we are not sure that all required fields are filled during the watch
            const { defaultEmail, notificationType, emails } = values;

            const changeContributorEmailsList = (newContributors: ContributorType[]) => {
                const newContributorEmailsData = getNewContributorEmailsData(review, emails, newContributors);
                replaceContributorEmails(newContributorEmailsData);
            };

            // if defaultEmail is selected, clear the list
            if (defaultEmail) {
                changeContributorEmailsList([]);
                return;
            }

            // set the contributor emails based on the notification type
            switch (notificationType) {
                case ReviewNotificationType.SUBMIT_PREPARATION_REMINDER:
                    changeContributorEmailsList(['SELF', 'MANAGER', 'PEER', 'UPWARD']);
                    break;
                case ReviewNotificationType.SUBMIT_DISCUSSION_REMINDER:
                    changeContributorEmailsList(['MANAGER']);
                    break;
                case ReviewNotificationType.EMPLOYEE_VALIDATION_REMINDER:
                    changeContributorEmailsList(['SELF']);
                    break;
                default:
                    changeContributorEmailsList([]);
            }
        },
        [replaceContributorEmails, review],
    );

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'defaultEmail' || name === 'notificationType') {
                // trigger the refresh of the contributor emails when the default email or notification type changes
                refreshContributorEmails(value);
            }
        });
        return () => subscription.unsubscribe();
    }, [refreshContributorEmails, watch]);

    const [defaultEmail, notificationType] = watch(['defaultEmail', 'notificationType']);

    const onSubmit = async (values: ReminderDialogFormValues) => {
        const mutation: ReviewSendReminderMutation = {
            type: values.notificationType,
            employeeReviewIds: employeeReviews.map(er => er.id),
            emails: !defaultEmail && values.emails ? values.emails : [],
        };

        try {
            await sendReminder(review.id, mutation);
            showSnackbar(t('reviews.reminder.success'), 'success');
            onClose();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <DialogWrapper header={t('reviews.reminder.send_reminders_dialog.title')} maxWidth={'sm'} {...restDialog}>
            <DialogContent>
                <Stack gap={2}>
                    {/* NOTIFICATION TYPE */}
                    <Controller
                        name={'notificationType'}
                        control={control}
                        render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                            <FormControlLabel
                                label={t('reviews.reminder.send_reminders_dialog.notification_type_label')}
                                labelPlacement='top'
                                control={
                                    <Autocomplete
                                        {...restField}
                                        value={value ?? getNull()}
                                        options={notificationTypesOptions}
                                        getOptionLabel={option => t('reviews.reminder.type_value', { context: option })}
                                        onChange={(_, value) => onChange(value)}
                                        renderInput={params => <TextField {...params} error={!!error} helperText={error?.message} />}
                                        fullWidth
                                    />
                                }
                            />
                        )}
                    />

                    {/* LIST OF EMPLOYEE REVIEWS */}
                    {!isEmployeeReviewsEmpty && (
                        <Stack>
                            <FormLabel sx={{ color: 'text.primary' }}>{t('reviews.reminder.send_reminders_dialog.sent_to_label')}</FormLabel>
                            <StackedAvatars employeeAvatars={recipients} />
                        </Stack>
                    )}

                    {/* SWITCH TO USE DEFAULT EMAIL */}

                    <FormControlLabel
                        label={t('reviews.reminder.send_reminders_dialog.use_default_email_label')}
                        control={<FieldSwitch name='defaultEmail' control={control} />}
                    />

                    {/* CUSTOM EMAIL CONTENTS */}
                    {!defaultEmail && notificationType && (
                        <Stack gap={1}>
                            <TranslationLanguageSelector translationLanguage={translationLanguage} handleLanguageChange={setTranslationLanguage} />

                            {contributorEmailFields.map((contributorEmailField, index) => (
                                <Stack gap={1} key={contributorEmailField.id}>
                                    <Controller
                                        name={`emails.${index}.subject`}
                                        control={control}
                                        render={({ field: { ...restField }, fieldState: { error } }) => (
                                            <TranslatableLabelInput
                                                {...restField}
                                                label={t('reviews.reminder.send_reminders_dialog.email_subject_for', {
                                                    context: contributorEmailField.contributorType,
                                                })}
                                                error={!!error}
                                                helperText={getLocalizedErrorMessage(error, translationLanguage)}
                                                translationLanguage={translationLanguage}
                                                fullWidth
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`emails.${index}.content`}
                                        control={control}
                                        render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                                            <>
                                                <FormLabel sx={{ color: 'text.primary' }}>
                                                    {t('reviews.reminder.send_reminders_dialog.email_content_to', {
                                                        context: contributorEmailField.contributorType,
                                                    })}
                                                </FormLabel>
                                                <TranslatableRichTextEditor
                                                    name={name}
                                                    value={value}
                                                    onUpdate={onChange}
                                                    errorMessage={getLocalizedErrorMessage(error, translationLanguage)}
                                                    translationLanguage={translationLanguage}
                                                    sx={{ width: '100%' }}
                                                />
                                            </>
                                        )}
                                    />
                                </Stack>
                            ))}
                        </Stack>
                    )}

                    {isEmployeeReviewsEmpty && (
                        <Alert
                            severity={'error'}
                            elevation={0}
                            sx={{ alignItems: 'center' }}
                            aria-label={t('reviews.reminder.send_reminders_dialog.no_employee_review_selected')}
                        >
                            <Typography>{t('reviews.reminder.send_reminders_dialog.no_employee_review_selected')}</Typography>
                        </Alert>
                    )}
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => handleSubmit(onSubmit, console.error)()} disabled={isEmployeeReviewsEmpty} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

/**
 * Get the available notification types based on the employee reviews statuses
 * @param employeeReviews
 */
const getNotificationTypeOptions = (employeeReviews: EmployeeReview[]) => {
    const availableNotificationTypes = [];
    const allStatuses = new Set(employeeReviews.map(er => er.status));

    if (allStatuses.has(EmployeeReviewStatus.INPUT_NEEDED)) {
        const missingReviewed = employeeReviews
            .flatMap(er => [...er.managers, ...er.upwardReviewers, ...er.peerReviewers])
            .some(reviewer => !reviewer.reviewed);
        // if at least one reviewer has not reviewed, we need suggest also the reminder for preparation
        if (missingReviewed) {
            availableNotificationTypes.push(ReviewNotificationType.SUBMIT_PREPARATION_REMINDER, ReviewNotificationType.SUBMIT_DISCUSSION_REMINDER);
        } else {
            availableNotificationTypes.push(ReviewNotificationType.SUBMIT_DISCUSSION_REMINDER);
        }
    }

    if (allStatuses.has(EmployeeReviewStatus.DISCUSSION_STARTED)) {
        availableNotificationTypes.push(ReviewNotificationType.SUBMIT_DISCUSSION_REMINDER);
    }

    if (allStatuses.has(EmployeeReviewStatus.DISCUSSION_SUBMITTED)) {
        availableNotificationTypes.push(ReviewNotificationType.EMPLOYEE_VALIDATION_REMINDER);
    }

    return availableNotificationTypes;
};

/**
 * Get the list of subject/content for each contributor
 * @param review
 * @param currentEmailsData
 * @param newContributors
 */
const getNewContributorEmailsData = (
    review: Review,
    currentEmailsData: DeepPartial<ReminderDialogFormValues>['emails'],
    newContributors: ContributorType[],
) => {
    // contributor types could be empty. We always have SELF and MANAGER by default
    const defaultContributorTypes: ContributorType[] = ['SELF', 'MANAGER'];
    // Merge default contributor types with the ones from the review
    const reviewContributorTypes = new Set([...defaultContributorTypes, ...review.contributorTypes]);

    // get the list of new emails data and keep previous value if it's the same contributor type
    return [...reviewContributorTypes]
        .filter(ct => newContributors.includes(ct))
        .map(contributorType => {
            const blankEmailData = {
                subject: createDefaultLabel(),
                content: createDefaultLabel(),
                contributorType,
            };
            const existingContributorEmailData = currentEmailsData?.find(ce => ce?.contributorType === contributorType);
            if (existingContributorEmailData) {
                // we need to spread blankEmailConfig because replace function from useFieldArray
                // does not accept partial objects
                return { ...blankEmailData, ...existingContributorEmailData };
            }
            return blankEmailData;
        });
};

const getReminderDialogSchema = (translationLanguage: UserLanguage) => {
    return yup
        .object()
        .shape({
            notificationType: yup.string().oneOf(Object.values(ReviewNotificationType)).required(),
            defaultEmail: yup.boolean().default(true).required(),
            emails: yup.array().of(
                yup
                    .object()
                    .shape({
                        subject: getLabelFormSchema(translationLanguage).required(),
                        content: getLabelFormSchema(translationLanguage).required(),
                        contributorType: yup.string().oneOf(ContributorArray).required(),
                    })
                    .required(),
            ),
        })
        .required();
};

type ReminderDialogFormValues = yup.InferType<ReturnType<typeof getReminderDialogSchema>>;
