import { BasicMenuItem } from '@/Components/basic-menu/BasicMenu';
import { DialogContainer } from '@/Components/dialog-container/DialogContainer';
import { SectionLoading } from '@/Components/section/SectionLoading';
import { StackSection } from '@/Components/section/StackSectionComponent/StackSection';
import { SectionActionButton, SectionField } from '@/Components/section/types';
import { Employee } from '@/domain/employee/Employee.model';
import {
    ContractType,
    Employment,
    EmploymentCreateReason,
    EmploymentCreationMutation,
    EmploymentStatus,
    EmploymentUpdateMutation,
} from '@/domain/employment/Employment.model';
import {
    cancelTermination,
    createEmployment,
    formatDurationForDates,
    getCurrentContract,
    getCurrentPrincipalEmployment,
    getTerminationTypeTranslationKey,
    updateEmployment,
} from '@/domain/employment/Employment.service';
import { canManageEmployeeContracts } from '@/domain/permission/Permission.service';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { ContractFormValues, NewContractDialog } from '@/page/employee-profile/employee-profile-info/EmploymentSection/Components/NewContractDialog';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { formatInDefaultDate, formatRelativeDate, toDate } from '@/utils/datetime.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { capitalize } from '@mui/material';
import { subDays } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ModifyContractDialog, ModifyContractDialogFormValue } from './Components/ModifyContractDialog';

type EmployeeContractSectionProps = {
    employee: Employee;
    onUpdateEmployeeContract: () => void;
    handleLongLeaveButton: () => void;
    employments: Employment[];
};

export const EmployeeContractSection: FC<EmployeeContractSectionProps> = ({ employee, onUpdateEmployeeContract, handleLongLeaveButton, employments }) => {
    const { t } = useTranslation();
    const [cancelDepartureDialogOpen, setCancelDepartureDialogOpen] = useState(false);
    const [modifyContractDialogOpen, setModifyContractDialogOpen] = useState(false);
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const [newContractDialogOpen, setNewContractDialogOpen] = useState<boolean>(false);
    const currentContract = getCurrentContract(employments);

    const navigate = useNavigate();

    const cancelDepartureButton: BasicMenuItem = {
        title: t('employee.employment.departure.cancel_departure'),
        onClick: () => {
            setCancelDepartureDialogOpen(!cancelDepartureDialogOpen);
        },
    };

    const modifyDepartureButton: BasicMenuItem = {
        title: t('employee.employment.departure.modify_departure'),
        onClick: () => {
            navigate(`/people/offboarding-form/${employee.id}`, { state: { employee } });
        },
    };

    const modifyContractButton: BasicMenuItem = {
        title: t('employee.employment.contract.modify_contract'),
        onClick: () => {
            setModifyContractDialogOpen(!modifyContractDialogOpen);
        },
    };

    const terminateContractButton: BasicMenuItem = {
        title: t('offboarding_form.offboarding'),
        onClick: () => {
            navigate(`/people/offboarding-form/${employee.id}`);
        },
    };

    const setEmploymentButton: BasicMenuItem = {
        title: t('employee.employment.new_contract'),
        onClick: () => {
            setNewContractDialogOpen(true);
        },
    };

    const addLongLeaveButton: SectionActionButton = {
        title: t('employee.employment.long_leave.add_long_leave'),
        onClick: () => {
            handleLongLeaveButton();
        },
    };

    const getDropdownActionButtons = (): BasicMenuItem[] => {
        if (!canManageEmployeeContracts(policies, employee?.id)) {
            return [];
        }

        if (!currentContract) {
            return [];
        }

        if (currentContract.endDate && currentContract.terminationType) {
            return [cancelDepartureButton, modifyDepartureButton, setEmploymentButton, addLongLeaveButton];
        }
        return [modifyContractButton, terminateContractButton, addLongLeaveButton];
    };

    const handleCancelDeparture = () => {
        const employment = getCurrentPrincipalEmployment(employee);
        if (!employment) {
            return;
        }
        cancelTermination(employment.id)
            .then(() => {
                onUpdateEmployeeContract();
                setCancelDepartureDialogOpen(false);
            })
            .catch(error => {
                showSnackbar(error.response?.data?.message, 'error');
            });
    };

    const handleUpdateContract = (employeeContractSectionForm: ModifyContractDialogFormValue) => {
        if (!currentContract?.id) {
            throw new Error('No contract found');
        }

        const endDate = employeeContractSectionForm.contractType !== ContractType.PERMANENT ? employeeContractSectionForm.endDate : undefined;
        const employmentUpdateRequest: EmploymentUpdateMutation = {
            contractType: employeeContractSectionForm.contractType,
            employmentCreateReason: undefined,
            startDate: employeeContractSectionForm.startDate,
            probationEndDate: employeeContractSectionForm?.probationEndDate ?? undefined,
            endDate: endDate ?? undefined,
            // For now new contract dialog does not handled multiple employments
            items: [
                {
                    id: currentContract.id,
                    principal: true,
                    locationId: currentContract.location?.id,
                    jobId: currentContract.job?.id,
                    departmentId: currentContract.department?.id,
                    managerIds: currentContract.managers.flatMap(manager => manager.id),
                    employmentCostCenters:
                        currentContract.employmentCostCenters.map(ecc => ({
                            costCenterId: ecc.costCenter.id,
                            percentage: ecc.percentage,
                        })) ?? [],
                },
            ],
        };
        updateEmployment(currentContract.id, employmentUpdateRequest)
            .then(() => {
                onUpdateEmployeeContract();
                setModifyContractDialogOpen(false);
            })
            .catch(handleError);
    };

    const handleEmploymentCreation = (contractFormValues: ContractFormValues) => {
        const employmentCreateRequest: EmploymentCreationMutation = {
            employeeId: employee.id,
            startDate: contractFormValues.startDate,
            contractType: contractFormValues.contractType,
            employmentCreateReason: EmploymentCreateReason?.REHIRED,
            items: [
                {
                    principal: true,
                    locationId: contractFormValues.location?.id,
                    departmentId: contractFormValues.department?.id,
                    jobId: contractFormValues.job?.id,
                    managerIds: contractFormValues.managers.flatMap(manager => manager.id),
                    employmentCostCenters:
                        contractFormValues.employmentCostCenters.map(ecc => ({
                            costCenterId: ecc.costCenter.id,
                            percentage: ecc.percentage,
                        })) ?? [],
                },
            ],
        };
        createEmployment(employmentCreateRequest)
            .then(() => {
                onUpdateEmployeeContract();
            })
            .catch(error => {
                showSnackbar(error.response?.data?.message, 'error');
            })
            .finally(() => {
                setNewContractDialogOpen(false);
            });
    };

    const getStartOrSeniorityDateFieldValue = (date: string | Date | undefined, employmentStatus: EmploymentStatus | undefined) => {
        if (!date) {
            return undefined;
        }

        const startDateFormatted = formatInDefaultDate(new Date(date));
        const duration = formatDurationForDates(subDays(new Date(date), 1), new Date());
        const shouldDisplayDuration = !!duration && employmentStatus !== EmploymentStatus.TERMINATED && employmentStatus !== EmploymentStatus.HIRED;

        return shouldDisplayDuration ? `${startDateFormatted} (${duration})` : startDateFormatted;
    };

    const getFields = (employee: Employee): SectionField[] => {
        const startDate = currentContract?.startDate ?? employee.contractStartDate;
        const fields: SectionField[] = [
            {
                title: t('employee.employment.contract_type_title'),
                type: SectionFieldType.STRING,
                stringValue: t('employee.employment.contract_type', { context: currentContract?.contractType }),
                fieldType: 'CURRENT_EMPLOYMENT_CONTRACT_TYPE',
            },
            {
                title: t('employee.employment.start_date'),
                stringValue: getStartOrSeniorityDateFieldValue(startDate, employee.employmentStatus),
                type: SectionFieldType.STRING,
                fieldType: 'CURRENT_EMPLOYMENT_START_DATE',
            },
        ];

        const shouldDisplaySeniorityDate =
            employee?.seniorityDate &&
            currentContract?.startDate !== employee?.seniorityDate &&
            employee.employmentStatus !== EmploymentStatus.TERMINATED &&
            employee.employmentStatus !== EmploymentStatus.HIRED;

        if (shouldDisplaySeniorityDate) {
            fields.push({
                title: t('employee.employment.seniority_date'),
                stringValue: getStartOrSeniorityDateFieldValue(employee.seniorityDate, employee.employmentStatus),
                type: SectionFieldType.STRING,
                fieldType: 'EMPLOYEE_SENIORITY_DATE',
            });
        }

        fields.push({
            title: t('employee.employment.probation_end_date'),
            dateValue: currentContract?.probationEndDate ?? undefined,
            type: SectionFieldType.DATE,
            fieldType: 'CURRENT_EMPLOYMENT_PROBATION_END_DATE',
        });

        if (currentContract?.endDate) {
            if (!!currentContract?.terminationType || currentContract.contractType !== ContractType.PERMANENT) {
                fields.push({
                    title: t('employee.employment.end_date'),
                    dateValue: currentContract?.endDate,
                    type: SectionFieldType.DATE,
                    fieldType: 'CURRENT_EMPLOYMENT_CONTRACT_END_DATE',
                });
            }

            if (currentContract?.terminationType) {
                fields.push({
                    title: t('employee.employment.termination.notice_date'),
                    dateValue: currentContract.terminationNoticeDate,
                    type: SectionFieldType.DATE,
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_NOTICE_DATE',
                });
                fields.push({
                    title: t('employee.employment.termination.last_day_at_work'),
                    stringValue: capitalize(formatRelativeDate(toDate(currentContract.terminationLastDayAtWork))),
                    type: SectionFieldType.STRING,
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_LAST_DAY',
                });
                fields.push({
                    title: t('domain.employee_field_type.termination_type'),
                    stringValue: t(getTerminationTypeTranslationKey(currentContract.terminationType)),
                    type: SectionFieldType.STRING,
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_TYPE',
                });
                fields.push({
                    title: t('domain.employee_field_type.termination_reason'),
                    stringValue: t('employee.employment.termination_reason.enum', { context: currentContract.terminationReason }),
                    type: SectionFieldType.STRING,
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_REASON',
                });
                fields.push({
                    title: t('employee.employment.termination.end_comment'),
                    stringValue: currentContract.terminationComment,
                    type: SectionFieldType.STRING,
                    fieldType: 'CURRENT_EMPLOYMENT_TERMINATION_COMMENT',
                });
            }
        }

        return fields;
    };
    if (!employee) {
        return <SectionLoading sectionTitle={'employee.sections.contract'} />;
    }

    return (
        <>
            <StackSection
                sectionTitle={t('employee.sections.contract')}
                fields={getFields(employee)}
                dropdownActionButtons={getDropdownActionButtons()}
                dropdownTitle={t('my_profile.actions')}
            />
            <DialogContainer
                open={cancelDepartureDialogOpen}
                onClose={() => setCancelDepartureDialogOpen(false)}
                onSave={() => handleCancelDeparture()}
                title={t('employee.employment.are_you_sure')}
                saveButtonText={t('general.yes')}
                cancelButtonText={t('general.no')}
            />

            {modifyContractDialogOpen && currentContract && (
                <ModifyContractDialog
                    setContractDialogOpen={setModifyContractDialogOpen}
                    onSaveContract={contract => handleUpdateContract(contract)}
                    contract={currentContract}
                />
            )}

            {newContractDialogOpen && (
                <NewContractDialog
                    open={newContractDialogOpen}
                    employeeId={employee.id}
                    onSave={handleEmploymentCreation}
                    onClose={() => setNewContractDialogOpen(false)}
                />
            )}
        </>
    );
};
