import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { StateHandler } from '@/Components/state-handler/StateHandler';
import { useGetReviewSkill } from '@/hooks/review-skill/ReviewSkill.hook';
import { ReviewSkillSettingForm } from '@/page/setting/review/skill/ReviewSkillSettingForm';
import { createSkill, deleteSkill, updateSkill } from '@/domain/skill/Skill.service';
import { showSnackbar } from '@/utils/snackbar.util';
import { useTranslation } from 'react-i18next';
import { handleError } from '@/utils/api.util';
import { ReviewSkillSettingFormValues } from '@/page/setting/review/skill/ReviewSkillSettingForm.schema';
import { SkillMutation } from '@/domain/skill/Skill.model';

export const ReviewSkillSettingsFormPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const skillId = Number(params.skillId) || undefined;

    const { data: reviewSkill, isLoading, isError, error } = useGetReviewSkill(skillId);

    const goToReviewSkills = () => {
        navigate('/settings/reviews/skills');
    };

    const handleSave = async (data: ReviewSkillSettingFormValues) => {
        const payload: SkillMutation = {
            name: data.name,
            description: data.description,
            skillCategoryId: data.categoryId,
            allRolesSelected: data.allRolesSelected,
            roleIds: data.availableForRoles ?? [],
        };
        try {
            if (reviewSkill) {
                await updateSkill(reviewSkill.id, payload);
                showSnackbar(t('reviews_settings_page.messages.skill_updated'), 'success');
            } else {
                await createSkill(payload);
                showSnackbar(t('reviews_settings_page.messages.skill_added'), 'success');
            }
            goToReviewSkills();
        } catch (error) {
            handleError(error);
        }
    };

    const handleDelete = async (skillId: number) => {
        try {
            await deleteSkill(skillId);
            showSnackbar(t('reviews_settings_page.messages.skill_deleted'), 'success');
            goToReviewSkills();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <ReviewSkillSettingForm reviewSkill={reviewSkill} onSave={handleSave} onDelete={handleDelete} />
        </StateHandler>
    );
};
