import { useCallback, useEffect, useState } from 'react';
import { UseQueryResult } from '@/page/Query.type';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { handleError } from '@/utils/api.util';

export const useGetLeaveTypes = (enabled = true): UseQueryResult<LeaveType[]> => {
    const [leaveTypes, setLeaveTypes] = useState<LeaveType[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<unknown>();

    const fetchLeaveTypes = useCallback(async () => {
        try {
            const leaveTypes = await getLeaveTypes();
            setLeaveTypes(leaveTypes);
        } catch (error) {
            setError(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (!enabled) {
            setIsLoading(false);
            return;
        }
        fetchLeaveTypes().catch(handleError);
    }, [enabled, fetchLeaveTypes]);

    return {
        data: leaveTypes,
        setData: setLeaveTypes,
        isLoading,
        isError: !!error,
        error,
        refetch: fetchLeaveTypes,
    };
};
