import { getFieldDefinitionTranslation } from '@/Components/ag-grid-wrapper/column-types/columnTypes';
import { BasicMenu } from '@/Components/basic-menu/BasicMenu';
import { SectionFieldComponent } from '@/Components/section/SectionFieldComponent/SectionFieldComponent';
import { SectionLoading } from '@/Components/section/SectionLoading';
import { SectionField } from '@/Components/section/types';
import { Employee, EmployeeFieldType } from '@/domain/employee/Employee.model';
import { Employment, EmploymentCreationMutation, EmploymentUpdateMutation } from '@/domain/employment/Employment.model';
import { createEmployment, formatEmploymentCostCenters, getCurrentPrincipalEmployment, updateEmployment } from '@/domain/employment/Employment.service';
import { canManageEmployeeContracts } from '@/domain/permission/Permission.service';
import { RealmFeature, RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { SectionFieldType } from '@/domain/section-setting/Section.model';
import { EmploymentDialogFormValues } from '@/page/employee-profile/employee-profile-info/EmploymentSection/employment.schema';
import { EmploymentsHistoryDialog } from '@/page/employee-profile/employee-profile-info/EmploymentSection/EmploymentsHistoryDialog';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { formatInDefaultDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { ArrowDown01Icon, Clock02Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmploymentDialog } from './Components/EmploymentDialog';

type Props = {
    employee: Employee;
    onUpdateEmployments: () => void;
    employments: Employment[];
};

export const EmploymentSection: FC<Props> = ({ employee, onUpdateEmployments, employments }) => {
    const { t } = useTranslation();

    const realm = useAppSelector(state => state.ui.currentRealm);
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);

    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [dialogToCreateEmploymentsIsOpen, setDialogToCreateEmploymentsIsOpen] = useState<boolean>(false);
    const [dialogToUpdateEmploymentsIsOpen, setDialogToUpdateEmploymentsIsOpen] = useState<boolean>(false);

    const principalEmployment = getCurrentPrincipalEmployment(employee);

    const isContractTerminated = !!principalEmployment?.endDate && !!principalEmployment?.terminationType;

    const addActionButton = {
        title: t('employee.employment.add_employment'),
        onClick: () => {
            setDialogToCreateEmploymentsIsOpen(true);
        },
        disabled: isContractTerminated,
        tooltipTitle: isContractTerminated ? t('employee.employment.add_employment_disabled_tooltip_title') : undefined,
    };

    const editActionButton = {
        title: t('employee.employment.edit_employment'),
        onClick: () => {
            setDialogToUpdateEmploymentsIsOpen(true);
        },
    };

    const getDropdownActionButtons = () => {
        if (canManageEmployeeContracts(policies, employee?.id)) {
            return [addActionButton, editActionButton];
        }
    };

    const handleUpdate = async (employmentFormValues: EmploymentDialogFormValues) => {
        if (!principalEmployment) {
            return;
        }
        try {
            const employmentUpdateRequest: EmploymentUpdateMutation = {
                // Contract type is not editable in the dialog so we can use the principal employment's contract type
                contractType: principalEmployment.contractType,
                startDate: employmentFormValues?.startDate,
                endDate: principalEmployment.endDate,
                employmentCreateReason: employmentFormValues?.employmentUpdateReason,
                items: employmentFormValues.items.map((item, index) => ({
                    // The backend expects id for existing item
                    id: item.id ?? undefined,
                    principal: index === 0,
                    locationId: item.location?.id,
                    departmentId: item?.department?.id,
                    jobId: item?.job?.id,
                    managerIds: item.managers.flatMap(manager => manager.id),
                    employmentCostCenters: item.employmentCostCenters.map(ecc => ({ costCenterId: ecc.costCenter.id, percentage: ecc.percentage })) ?? [],
                })),
            };

            await updateEmployment(principalEmployment.id, employmentUpdateRequest);
            onUpdateEmployments();
            setDialogToUpdateEmploymentsIsOpen(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleCreate = (employmentFormValues: EmploymentDialogFormValues) => {
        if (!principalEmployment) {
            return;
        }
        const employmentCreateRequest: EmploymentCreationMutation = {
            // Contract type is not editable in the dialog so we can use the principal employment's contract type
            contractType: principalEmployment?.contractType,
            employeeId: employee?.id,
            startDate: employmentFormValues?.startDate,
            employmentCreateReason: employmentFormValues?.employmentUpdateReason,
            items: employmentFormValues.items.map((employment, index) => ({
                // First item is defined as principal employment
                principal: index === 0,
                locationId: employment.location?.id,
                departmentId: employment?.department?.id,
                jobId: employment?.job?.id,
                managerIds: employment.managers.flatMap(manager => manager.id),
                employmentCostCenters: employment.employmentCostCenters.map(ecc => ({ costCenterId: ecc.costCenter.id, percentage: ecc.percentage })) ?? [],
            })),
        };
        createEmployment(employmentCreateRequest)
            .then(() => {
                onUpdateEmployments();
                setDialogToCreateEmploymentsIsOpen(false);
            })
            .catch(error => {
                showSnackbar(error.response?.data?.message, 'error');
            });
    };

    const onClose = () => {
        setDialogToCreateEmploymentsIsOpen(false);
    };

    if (!employments) {
        return <SectionLoading sectionTitle={'employee.sections.employment'} />;
    }

    const dropdownActionButtons = getDropdownActionButtons();

    const currentEmployments = [...employee.currentEmployments].sort(a => (a.principal ? -1 : 1));

    const commonFieldTypes: EmploymentFieldType[] = ['CURRENT_EMPLOYMENT_START_DATE'] as const;

    return (
        <Stack component={Paper} p={2} justifyContent='center' alignItems='flex-start' spacing={1} pl={3}>
            <Stack direction='row' alignItems='flex-start' spacing={1} sx={{ width: '100%' }}>
                <Typography variant='h1' sx={{ flex: 1 }}>
                    {t('employee.sections.employment')}
                </Typography>

                <Button variant='text' onClick={() => setShowHistory(true)} endIcon={<Clock02Icon size={20} />}>
                    {t('employee.employment.view_history')}
                </Button>

                {showHistory && <EmploymentsHistoryDialog open={true} onClose={() => setShowHistory(false)} employeeId={employee.id} />}

                {!!dropdownActionButtons?.length && (
                    <BasicMenu items={dropdownActionButtons} dropdownTitle={t('my_profile.actions')} endIcon={<ArrowDown01Icon />} />
                )}
            </Stack>
            <Stack gap={1.5} divider={<Divider flexItem />} width='100%'>
                {getFieldTypes(realm?.realmFeatures ?? []).map(fieldType => {
                    return (
                        <Stack key={fieldType} gap={1} justifyContent='flex-start' direction='row'>
                            <Typography variant='body1bold' width={170}>
                                {getFieldDefinitionTranslation({ fieldType })}
                            </Typography>
                            {currentEmployments.map(employments => (
                                <Stack key={employments.id} width={currentEmployments.length > 1 ? 180 : undefined}>
                                    {(employments.principal || !commonFieldTypes.includes(fieldType)) && (
                                        <SectionFieldComponent field={getFieldsByType(employments, fieldType)} />
                                    )}
                                </Stack>
                            ))}
                        </Stack>
                    );
                })}
            </Stack>
            {dialogToCreateEmploymentsIsOpen && (
                <EmploymentDialog open={dialogToCreateEmploymentsIsOpen} employeeId={employee?.id} employments={[]} onSave={handleCreate} onClose={onClose} />
            )}

            {dialogToUpdateEmploymentsIsOpen && (
                <EmploymentDialog
                    open={dialogToUpdateEmploymentsIsOpen}
                    employeeId={employee?.id}
                    employments={currentEmployments}
                    onSave={handleUpdate}
                    onClose={() => setDialogToUpdateEmploymentsIsOpen(false)}
                />
            )}
        </Stack>
    );
};

const formatEmploymentStartDate = (employment: Employment) => {
    return formatInDefaultDate(employment.startDate) + (employment.endDate ? ' -> ' + formatInDefaultDate(employment.endDate) : '');
};

const getFieldTypes = (realmFeatures: RealmFeature[]) => {
    const defaultTypes = [
        'CURRENT_EMPLOYMENT_START_DATE',
        'CURRENT_EMPLOYMENT_JOB',
        'CURRENT_EMPLOYMENT_DEPARTMENT',
        'CURRENT_EMPLOYMENT_MANAGER',
        'CURRENT_EMPLOYMENT_LOCATION',
    ] as const;

    if (hasRealmFeatureEnabled(realmFeatures, RealmFeaturesType.COST_CENTERS)) {
        return [...defaultTypes, 'CURRENT_EMPLOYMENT_COST_CENTERS'] satisfies EmployeeFieldType[];
    }

    return defaultTypes;
};

type EmploymentFieldType = ReturnType<typeof getFieldTypes>[number];

const getFieldsByType = (employment: Employment, fieldType: EmploymentFieldType): SectionField => {
    const title = '';
    switch (fieldType) {
        case 'CURRENT_EMPLOYMENT_LOCATION':
            return {
                title,
                stringValue: employment.location?.name,
                type: SectionFieldType.STRING,
                fieldType,
            };
        case 'CURRENT_EMPLOYMENT_DEPARTMENT':
            return {
                title,
                stringValue: getLabelTranslation(employment.department?.name),
                type: SectionFieldType.STRING,
                fieldType,
            };
        case 'CURRENT_EMPLOYMENT_JOB':
            return {
                title,
                stringValue: getLabelTranslation(employment.job?.name),
                type: SectionFieldType.STRING,
                fieldType,
            };
        case 'CURRENT_EMPLOYMENT_MANAGER':
            return {
                title,
                fieldType,
                stringValue: employment.managers?.flatMap(m => m.displayName).join(', '),
                type: SectionFieldType.STRING,
            };

        case 'CURRENT_EMPLOYMENT_START_DATE':
            return {
                title,
                fieldType,
                type: SectionFieldType.STRING,
                stringValue: formatEmploymentStartDate(employment),
            };
        case 'CURRENT_EMPLOYMENT_COST_CENTERS':
            return {
                title,
                fieldType,
                type: SectionFieldType.STRING,
                stringValue: formatEmploymentCostCenters(employment?.employmentCostCenters),
            };
    }
};
