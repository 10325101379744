import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '../common';
import { Role, RoleCreationMutation, RoleSearch, RoleUpdateMutation } from '@/domain/role/Role.model';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';

export type RoleDTO = Role;
type RoleSearchRequestDTO = RoleSearch;
type RoleCreationRequestDTO = RoleCreationMutation;
type RoleUpdateRequestDTO = RoleUpdateMutation;

export const searchRoles = async (request: RoleSearch = {}): Promise<Role[]> => {
    return (await client.post<RoleDTO[], AxiosResponse<RoleDTO[]>, RoleSearchRequestDTO>(API_BASE_URL + '/roles/search', request ?? {})).data;
};

const createRole = async (request: RoleCreationMutation): Promise<Role> => {
    return (await client.post<RoleDTO, AxiosResponse<RoleDTO>, RoleCreationRequestDTO>(API_BASE_URL + '/roles', request)).data;
};

const updateRole = async (id: number, request: RoleUpdateMutation): Promise<Role> => {
    return (await client.put<RoleDTO, AxiosResponse<RoleDTO>, RoleUpdateRequestDTO>(API_BASE_URL + `/roles/${id}`, request)).data;
};

const deleteRole = async (id: number): Promise<void> => {
    return client.delete(API_BASE_URL + `/roles/${id}`);
};

const importRoles = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/roles/import`, request)).data;
};

export const roleApi = {
    searchRoles,
    createRole,
    updateRole,
    deleteRole,
    importRoles,
};
