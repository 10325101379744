import { API_BASE_URL, client } from '@/api/common';
import { AxiosResponse } from 'axios';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';

const importBasicInformation = async (request: ImportRequest): Promise<ImportResult> => {
    return (await client.post<ImportResult, AxiosResponse<ImportResult>, ImportRequest>(API_BASE_URL + `/employees/basic-info/import`, request)).data;
};

export const employeeBasicInfoAPI = {
    importBasicInformation,
};
