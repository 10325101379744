import { CompanySettingsPage } from '@/page/setting/CompanySettingsPage';
import { CalendarsSettingsPage } from '@/page/setting/calendar/CalendarsSettingsPage';
import { LeavesSettingsPage } from '@/page/setting/time-management/LeavesSettingsPage';
import { ObjectivesCategoriesSettingsPage } from '@/page/setting/objective/ObjectivesCategoriesSettingsPage';
import { PayrollSettings } from '@/page/setting/payroll/PayrollSettingsPage';
import { PermissionsSettings } from '@/page/setting/permission/PermissionsSettingsPage';
import { PermissionsSettingPage } from '@/page/setting/permission/PermissionsSettingPage';
import { ReviewSettingPage } from '@/page/setting/review/ReviewSettingPage';
import { CompanySettingsSectionDefinitionsPage } from '@/page/setting/section/CompanySettingsSectionDefinitionsPage';
import { CompanySettingsDepartmentsPage } from '@/page/setting/organization/department/CompanySettingsDepartmentsPage';
import { PlanningConfigurationFormPage } from '@/page/setting/planning/PlanningConfigurationFormPage';
import { PlanningConfigurations } from '@/page/setting/planning/PlanningConfigurations';
import { PageContainer } from '@/routes/PageContainer';
import { RoutesConfig } from '@/routes/Routes.type';
import { LeaveTypeSettingPage } from '@/page/setting/time-management/LeaveTypeSettingPage';
import { RouteObject } from 'react-router-dom';
import { CalendarSettingPage } from '@/page/setting/calendar/CalendarSettingPage';
import { FoldersSettingsPage } from '@/page/setting/folder/FoldersSettingsPage';
import { FolderSettingPage } from '@/page/setting/folder/FolderSettingPage';
import { ScaleSettingsPage } from '@/page/setting/objective/ScaleSettingsPage';
import { ImportPage } from './import/ImportPage';
import {
    CostCentersResultsPage,
    CustomSectionResultsPage,
    DepartmentsResultsPage,
    EmployeeRolesResultsPage,
    EmployeesAddressesResultsPage,
    EmployeesBasicInformationResultsPage,
    EmployeesResultsPage,
    JobFamiliesResultsPage,
    JobsResultsPage,
    LeaveCorrectionResultsPage,
    LeavesResultsPage,
    ObjectiveResultsPage,
    PlanningPositionsResultsPage,
    RolesResultsPage,
    TimesheetsAdjustmentsResultsPage,
    TimesheetsResultsPage,
} from './import/ResultsPage';
import { ShiftTypeSettingsPage } from '@/page/setting/planning/ShiftTypeSettingsPage';
import { TimeManagementAllSettingsPage } from '@/page/setting/time-management/TimeManagementAllSettingsPage';
import { TimeManagementSettingPage } from '@/page/setting/time-management/TimeManagementSettingPage';
import { PlanningTagSettingsPage } from '@/page/setting/planning/PlanningTagSettingsPage';
import { ImportDocumentsPage } from './import/ImportDocumentsPage';
import { ReviewTemplateFormPage } from '@/page/setting/review/template/ReviewTemplateFormPage';
import { RoleSettingsPage } from '@/page/setting/role/RoleSettingsPage';
import { PlanningPositionSettings } from '@/page/setting/planning/PlanningPositionSettingsPage';
import { PlanningSettingsPage } from './planning/PlanningSettingsPage';
import { WorkingPatternFormPage } from './time-management/WorkingPatternFormPage';
import { WorkingPatternsPage } from './time-management/WorkingPatternsPage';
import { WeeklyWorkingTimesPage } from './time-management/WeeklyWorkingTimesPage';
import { ImportPageType } from '@/domain/import/Import.model';
import { ReviewRatingScaleSettingFormPage } from '@/page/setting/review/rating-scale/ReviewRatingScaleSettingFormPage';
import { AnnouncementSettingsPage } from '@/page/setting/announcement/AnnouncementSettingsPage';
import { CostCenterSettingsPage } from '@/page/setting/organization/cost-center/CostCenterSettingsPage';
import { ReviewSkillSettingsFormPage } from '@/page/setting/review/skill/ReviewSkillSettingsFormPage';
import { ReviewSkillCategorySettingFormPage } from '@/page/setting/review/skill-category/ReviewSkillCategorySettingFormPage';
import { ThirdPartySettingsPage } from '@/page/setting/third-party/ThirdPartySettingsPage';
import { ThirdPartyMappingSettingsPage } from '@/page/setting/third-party-mapping/ThirdPartyMappingSettingsPage';
import { ThirdPartyMappingSettingPage } from '@/page/setting/third-party-mapping/ThirdPartyMappingSettingPage';
import { RouterBoundary } from '@/routes/RouterBoundary';
import { RealmSettingsPage } from '@/page/setting/realm/RealmSettingsPage';
import { ThirdPartySettingPage } from '@/page/setting/third-party/third-party-setting-page/ThirdPartySettingPage';
import { LegalUnitsSettingsPage } from '@/page/setting/legal-unit/LegalUnitsSettingsPage';
import { LegalUnitSettingPage } from '@/page/setting/legal-unit/LegalUnitSettingPage';
import { LocationSettingPage } from '@/page/setting/location/LocationSettingPage';
import { ObjectiveSettingsPage } from '@/page/setting/objective/ObjectiveSettingsPage';
import { OrganizationBillingSettingsPage } from '@/page/setting/organization_billing/OrganizationBillingSettingsPage';
import { JobFamilySettingsPage } from '@/page/setting/job-family/JobFamilySettingsPage';
import { JobSettingsPage } from '@/page/setting/job/JobSettingsPage';
import { JobSettingPage } from '@/page/setting/job/JobSettingPage';

const config: RoutesConfig[] = [
    {
        path: 'organization/legal-units',
        breadCrumb: [{ nameKey: 'pages.legal_units_settings' }],
        component: <LegalUnitsSettingsPage />,
    },
    {
        path: 'organization/legal-units/:legalUnitId',
        breadCrumb: [{ nameKey: 'pages.legal_units_settings' }],
        component: <LegalUnitSettingPage />,
    },
    {
        path: 'organization/legal-units/:legalUnitId/locations/:locationId',
        breadCrumb: [{ nameKey: 'pages.location_settings' }],
        component: <LocationSettingPage />,
    },
    {
        path: 'organization/departments',
        breadCrumb: [{ nameKey: 'pages.department_settings' }],
        component: <CompanySettingsDepartmentsPage />,
    },
    {
        path: 'organization/jobs',
        breadCrumb: [{ nameKey: 'pages.job_settings' }],
        component: <JobSettingsPage />,
    },
    {
        path: 'organization/jobs/:jobId',
        breadCrumb: [{ nameKey: 'pages.job_settings' }],
        component: <JobSettingPage />,
    },
    {
        path: 'organization/job-families',
        breadCrumb: [{ nameKey: 'pages.job_families_settings' }],
        component: <JobFamilySettingsPage />,
    },
    {
        path: 'organization/roles',
        breadCrumb: [{ nameKey: 'pages.roles_settings' }],
        component: <RoleSettingsPage />,
    },
    {
        path: 'organization/cost-centers',
        breadCrumb: [{ nameKey: 'pages.cost_centers' }],
        component: <CostCenterSettingsPage />,
    },
    {
        path: 'time-management/calendars',
        breadCrumb: [{ nameKey: 'pages.calendar_settings' }],
        component: <CalendarsSettingsPage />,
    },
    {
        path: 'time-management/calendars/:calendarId',
        breadCrumb: [{ nameKey: 'pages.calendar_settings' }],
        component: <CalendarSettingPage />,
    },
    {
        path: 'time-management/leaves',
        breadCrumb: [{ nameKey: 'pages.leaves_settings' }],
        component: <LeavesSettingsPage />,
    },
    {
        path: 'time-management/leaves/:leaveTypeId',
        breadCrumb: [{ nameKey: 'pages.leave_types_settings' }],
        component: <LeaveTypeSettingPage />,
    },
    {
        path: 'time-management/base-working-time',
        breadCrumb: [{ nameKey: 'pages.base_working_time_settings' }],
        component: <WeeklyWorkingTimesPage />,
    },
    {
        path: 'time-management/working-patterns',
        breadCrumb: [{ nameKey: 'pages.working_patterns_settings' }],
        component: <WorkingPatternsPage />,
    },
    {
        path: 'time-management/settings',
        breadCrumb: [{ nameKey: 'pages.time_management_settings' }],
        component: <TimeManagementAllSettingsPage />,
    },
    {
        path: 'time-management/settings/:timeManagementId',
        breadCrumb: [{ nameKey: 'pages.time_management_settings' }],
        component: <TimeManagementSettingPage />,
    },
    {
        path: 'time-management/working-patterns/:workingPatternId',
        breadCrumb: [{ nameKey: 'pages.time_management_settings' }],
        component: <WorkingPatternFormPage />,
    },
    {
        path: 'documents/folders',
        breadCrumb: [{ nameKey: 'pages.documents_settings' }],
        component: <FoldersSettingsPage />,
    },
    {
        path: 'documents/folders/:folderId',
        breadCrumb: [{ nameKey: 'pages.documents_settings' }],
        component: <FolderSettingPage />,
    },
    {
        path: 'employees/fields',
        breadCrumb: [{ nameKey: 'pages.sections_settings' }],
        component: <CompanySettingsSectionDefinitionsPage />,
    },
    {
        path: 'planning/options',
        breadCrumb: [{ nameKey: 'pages.planning_settings' }],
        component: <PlanningSettingsPage />,
    },
    {
        path: 'planning/planningTags',
        breadCrumb: [{ nameKey: 'pages.tags_settings' }],
        component: <PlanningTagSettingsPage />,
    },
    {
        path: 'planning/planningPositions',
        breadCrumb: [{ nameKey: 'pages.position_settings' }],
        component: <PlanningPositionSettings />,
    },
    {
        path: 'planning/shift-type',
        breadCrumb: [{ nameKey: 'pages.shift_types_settings' }],
        component: <ShiftTypeSettingsPage />,
    },
    {
        path: 'reviews',
        breadCrumb: [{ nameKey: 'pages.reviews_settings' }],
        component: <ReviewSettingPage />,
    },
    {
        path: 'reviews/:tab',
        breadCrumb: [{ nameKey: 'pages.reviews_settings' }],
        component: <ReviewSettingPage />,
    },
    {
        path: 'reviews/templates/:templateId',
        breadCrumb: [{ nameKey: 'pages.reviews_templates_settings' }],
        component: <ReviewTemplateFormPage />,
    },
    {
        path: 'reviews/skills/:skillId',
        breadCrumb: [{ nameKey: 'pages.skill_settings' }],
        component: <ReviewSkillSettingsFormPage />,
    },
    {
        path: 'reviews/scales/:scaleId',
        breadCrumb: [{ nameKey: 'pages.reviews_scale_settings' }],
        component: <ReviewRatingScaleSettingFormPage />,
    },
    {
        path: 'reviews/categories/:skillCategoryId',
        breadCrumb: [{ nameKey: 'pages.skill_category_settings' }],
        component: <ReviewSkillCategorySettingFormPage />,
    },
    {
        path: 'objectives/settings',
        breadCrumb: [{ nameKey: 'pages.objectives_settings' }],
        component: <ObjectiveSettingsPage />,
    },
    {
        path: 'objectives/categories',
        breadCrumb: [{ nameKey: 'pages.objectives_categories_settings' }],
        component: <ObjectivesCategoriesSettingsPage />,
    },
    {
        path: 'objectives/completion_scale',
        breadCrumb: [{ nameKey: 'pages.completion_scale_settings' }],
        component: <ScaleSettingsPage />,
    },
    {
        path: 'permissions',
        breadCrumb: [{ nameKey: 'pages.permissions_settings' }],
        component: <PermissionsSettings />,
    },
    {
        path: 'permissions/:permissionId',
        breadCrumb: [{ nameKey: 'pages.permissions_settings' }],
        component: <PermissionsSettingPage />,
    },
    {
        path: 'permissions/planning',
        breadCrumb: [{ nameKey: 'pages.planning_permissions_settings' }],
        component: <PlanningConfigurations />,
    },
    {
        path: 'permissions/planning/:planningIdParam',
        breadCrumb: [{ nameKey: 'pages.planning_permissions_settings' }],
        component: <PlanningConfigurationFormPage />,
    },
    {
        path: 'payroll',
        breadCrumb: [{ nameKey: 'pages.payroll_settings' }],
        component: <PayrollSettings />,
    },
    {
        path: 'import/employees',
        breadCrumb: [{ nameKey: 'pages.import_employee' }],
        component: <ImportPage importPageType={ImportPageType.EMPLOYEE} />,
    },
    {
        path: 'results/employees',
        breadCrumb: [{ nameKey: 'pages.import_employee_result' }],
        component: <EmployeesResultsPage />,
    },
    {
        path: 'import/organization',
        breadCrumb: [{ nameKey: 'pages.import_organization' }],
        component: <ImportPage importPageType={ImportPageType.ORGANIZATION} />,
    },
    {
        path: 'results/jobs',
        breadCrumb: [{ nameKey: 'pages.import_job_result' }],
        component: <JobsResultsPage />,
    },
    {
        path: 'results/job-families',
        breadCrumb: [{ nameKey: 'pages.import_job_family_result' }],
        component: <JobFamiliesResultsPage />,
    },
    {
        path: 'results/basic_information',
        breadCrumb: [{ nameKey: 'pages.import_basic_info_result' }],
        component: <EmployeesBasicInformationResultsPage />,
    },
    {
        path: 'results/cost-centers',
        breadCrumb: [{ nameKey: 'pages.import_cost_center_result' }],
        component: <CostCentersResultsPage />,
    },
    {
        path: 'results/departments',
        breadCrumb: [{ nameKey: 'pages.import_department_result' }],
        component: <DepartmentsResultsPage />,
    },
    {
        path: 'results/planning-positions',
        breadCrumb: [{ nameKey: 'pages.import_position_result' }],
        component: <PlanningPositionsResultsPage />,
    },
    {
        path: 'results/basic_information',
        breadCrumb: [{ nameKey: 'pages.import_basic_info_result' }],
        component: <EmployeesBasicInformationResultsPage />,
    },
    {
        path: 'results/roles',
        breadCrumb: [{ nameKey: 'pages.import_role_result' }],
        component: <RolesResultsPage />,
    },
    {
        path: 'results/employee-roles',
        breadCrumb: [{ nameKey: 'pages.import_employee_role_result' }],
        component: <EmployeeRolesResultsPage />,
    },
    {
        path: 'results/addresses',
        breadCrumb: [{ nameKey: 'pages.import_addresses_result' }],
        component: <EmployeesAddressesResultsPage />,
    },
    {
        path: 'results/custom-sections/:sectionId',
        breadCrumb: [{ nameKey: 'pages.import_custom_section_result' }],
        component: <CustomSectionResultsPage />,
    },
    {
        path: 'import/leaves',
        breadCrumb: [{ nameKey: 'pages.import_leaves' }],
        component: <ImportPage importPageType={ImportPageType.LEAVES} />,
    },
    {
        path: 'results/leaves',
        breadCrumb: [{ nameKey: 'pages.import_leaves_result' }],
        component: <LeavesResultsPage />,
    },
    {
        path: 'results/leave_balance_adjustments',
        breadCrumb: [{ nameKey: 'pages.import_leave_balance_adjustment_result' }],
        component: <LeaveCorrectionResultsPage />,
    },
    {
        path: 'import/timesheets',
        breadCrumb: [{ nameKey: 'pages.import_timesheets' }],
        component: <ImportPage importPageType={ImportPageType.TIMESHEET} />,
    },
    {
        path: 'results/timesheets',
        breadCrumb: [{ nameKey: 'pages.import_timesheets_result' }],
        component: <TimesheetsResultsPage />,
    },
    {
        path: 'results/timesheets_adjustments',
        breadCrumb: [{ nameKey: 'pages.import_timesheets_adjustments_result' }],
        component: <TimesheetsAdjustmentsResultsPage />,
    },
    {
        path: 'import/objectives',
        breadCrumb: [{ nameKey: 'pages.import_objective' }],
        component: <ImportPage importPageType={ImportPageType.OBJECTIVE} />,
    },
    {
        path: 'results/objectives',
        breadCrumb: [{ nameKey: 'pages.import_objective_result' }],
        component: <ObjectiveResultsPage />,
    },
    {
        path: 'import/documents',
        breadCrumb: [{ nameKey: 'pages.import_documents' }],
        component: <ImportDocumentsPage />,
    },
    {
        path: 'announcements',
        breadCrumb: [{ nameKey: 'pages.announcements_settings' }],
        component: <AnnouncementSettingsPage />,
    },
    {
        path: 'third-parties',
        breadCrumb: [{ nameKey: 'pages.third_parties_settings' }],
        component: <ThirdPartySettingsPage />,
    },
    {
        path: 'third-parties/:thirdPartyId',
        breadCrumb: [{ nameKey: 'pages.third_parties_settings' }],
        component: <ThirdPartySettingPage />,
    },
    {
        path: 'third-party-mappings',
        breadCrumb: [{ nameKey: 'pages.third_party_mapping_settings' }],
        component: <ThirdPartyMappingSettingsPage />,
    },
    {
        path: 'third-party-mappings/:mappingId',
        breadCrumb: [{ nameKey: 'pages.third_party_mapping_settings' }],
        component: <ThirdPartyMappingSettingPage />,
    },
    // These page below are not available by navigation, only by direct link
    {
        path: 'realm',
        breadCrumb: [{ nameKey: 'pages.realm_settings' }],
        component: <RealmSettingsPage />,
    },
    {
        path: 'billing',
        breadCrumb: [{ nameKey: 'pages.organization_billing_settings' }],
        component: <OrganizationBillingSettingsPage />,
    },
];

export const companySettingRoutes: RouteObject[] = [
    {
        path: 'settings',
        children: [
            {
                path: '',
                element: (
                    <PageContainer
                        breadCrumb={[
                            {
                                nameKey: 'pages.settings',
                            },
                        ]}
                    >
                        <CompanySettingsPage />
                    </PageContainer>
                ),
                errorElement: <RouterBoundary />,
            },
            ...config.map(config => ({
                path: config.path,
                element: <PageContainer breadCrumb={config.breadCrumb}>{config.component}</PageContainer>,
                errorElement: <RouterBoundary />,
            })),
        ],
    },
];
